import moment, { Moment } from 'moment';

export const getDefaultStartEndTime = (): { initialStartTime: Moment; initialEndTime: Moment } => {
    const timeNow = moment();
    const rangeMax = timeNow.subtract(timeNow.seconds() % 10, 's');

    const getDefaultEndTime = () => moment(rangeMax);
    const getDefaultStartTime = () => moment(rangeMax).subtract(1, 'h');

    const initialStartTime = getDefaultStartTime();
    const initialEndTime = getDefaultEndTime();

    return { initialStartTime, initialEndTime };
};
