import { AdaptedMetricType } from './monitoringTypes';

export const getMemoizedChartKey = (chartProps: AdaptedMetricType) => {
  if (chartProps?.metricValues) {
    return `${chartProps?.title}`;
  }
  const { id, title, unit } = chartProps;
  const memoizedKey = `${id}_${title}_${unit}`;
  return memoizedKey;
};
