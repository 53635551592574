export const labelFormatter = (val, opts) => {
  return `${opts.w.config.labels[opts.seriesIndex]}: ${
    opts.w.config.series[opts.seriesIndex]
  }
   (${val.toFixed(0)}%)`;
};

export const SAMPLE_CHART_OPTION = {
  chart: {
    width: 380,
    type: 'pie' as any,
    id: '',
    group: '',
    labels: [],
    dataLabels: {
      formatter: labelFormatter,
    },
  },
};

export const CHART_FAMILIES = [
  'alerts',
  'tickets',
  'auditlogs',
  'genieLogs',
  'tenants',
  'dbservices',
  'subscriptions',
  'vpcs',
  'dbbackups',
  'snapshots',
  'nativeBackup',
  'cloudAccounts',
];

export const CHART_TYPES = {
  tenants: ['status', 'deploymentType'],
  subscriptions: ['cloudType', 'managedType'],
  vpcs: ['cloudType'],
  dbservices: ['status', 'engineType', 'cloudType', 'topology', 'computeType'],
  dbbackups: ['type'],
  snapshots: ['type', 'AutomateType', 'cloudType'],
  nativeBackup: ['type', 'AutomateType', 'cloudType'],
  alerts: ['type'],
  tickets: ['type'],
  cloudAccounts: ['cloudType'],
  auditlogs: ['type'],
  genieLogs: ['Tenant based genie logs'],
};

export const DISPLAY_NAME = {
  tenants: 'Tenants',
  dbservices: 'DB Services',
  subscriptions: 'Subscriptions',
  vpcs: 'VPCs',
  dbbackups: 'DB Backups',
  snapshots: 'Snapshots',
  nativeBackup: 'Native Backups',
  alerts: 'Alerts',
  cloudAccounts: 'Cloud Accounts',
  tickets: 'Tickets',
  DEPLOYMENTTYPE: 'Deployment Type',
  ENGINETYPE: 'Engine TYPE',
  STATUS: 'Status',
  CLOUDTYPE: 'Cloud Type',
  COMPUTETYPE: 'Compute Type',
  TOPOLOGY: 'Topology',
  MANAGEDTYPE: 'Managed Type',
  TYPE: 'Type',
  AUTOMATETYPE: 'Automation Type',
  auditlogs: 'Audit Logs (Last 7 days)',
  genieLogs: 'Tenant based genie logs',
};

export const CHART_FAMILIES_WITH_HALF_WIDTH = [
  'tickets',
  'alerts',
  'dbbackups',
  'vpcs',
  'genieLogs',
];
