export default function NotificationBellIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6 7.18862C15.6 4.09583 13.0928 1.58862 10 1.58862V1.58862C6.90721 1.58862 4.4 4.09583 4.4 7.18862V10.1557C4.4 10.6613 4.2973 11.1616 4.09814 11.6263L3 14.1886H17L15.9019 11.6263C15.7027 11.1616 15.6 10.6613 15.6 10.1557V7.18862Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.1333 16.522C8.24856 16.9647 8.50744 17.3566 8.86937 17.6364C9.23131 17.9162 9.67584 18.068 10.1333 18.068C10.5908 18.068 11.0353 17.9162 11.3972 17.6364C11.7592 17.3566 12.018 16.9647 12.1333 16.522"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
