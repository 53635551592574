import { Genie } from 'common/Genie/GenieOverview/GenieOverview';
import OtpModal from 'common/Modals/OtpModal';
import { OTP_TYPE } from 'constants/otpType';
import { useVMContainer } from './useVMContainer';

type VMContainerProps = {
  serviceId?: string;
  computeId?: string;
  tenantId: string;
  tenantDomain: string;
  tenantName: string;
  instances?: any;
};

const VMContainer = ({
  serviceId,
  computeId,
  tenantId,
  tenantDomain,
  tenantName,
  instances = [],
}: VMContainerProps) => {
  const {
    handleRequest,
    refreshGenieStatus,
    genieStatusLoading,
    genieStatus,
    handleWithdraw,
    handleExtendDuration,
    downloadGenieConfig,
    downloadGenieConfigForACompute,
    approveRequest,
    declineRequest,
    openOtpModal,
    setOpenOtpModal,
    genieOtpStep,
    onGenieRequest,
    onGenieOtpSubmit,
    otpErrorMsg,
    isLoadingGenieRequest,
    isLoadingGenieEnable,
    setOtpErrorMsg,
    handleForceRevoke,
    handleAddComputes,
  } = useVMContainer({
    serviceId,
    tenantId,
    tenantDomain,
    tenantName,
    computeId,
  });

  return (
    <>
      <Genie
        instances={instances
          ?.filter((_i) => _i?.status !== 'DELETED')
          ?.map((_c) => ({
            label: _c?.name,
            value: _c?.computeResourceId,
          }))}
        handleRequest={handleRequest}
        refreshGenieStatus={refreshGenieStatus}
        genieStatusLoading={genieStatusLoading}
        genieStatus={genieStatus}
        handleWithdraw={handleWithdraw}
        handleExtendDuration={handleExtendDuration}
        downloadGenieConfig={downloadGenieConfig}
        downloadGenieConfigForACompute={downloadGenieConfigForACompute}
        approveRequest={approveRequest}
        declineRequest={declineRequest}
        hideInstanceSelection={Boolean(computeId)}
        handleForceRevoke={handleForceRevoke}
        handleAddComputes={handleAddComputes}
        serviceId={serviceId}
      />
      {openOtpModal && (
        <OtpModal
          otpFormat={'string'}
          open={openOtpModal}
          setOpen={setOpenOtpModal}
          onSubmit={(val) => {
            if (genieOtpStep === 1) {
              onGenieRequest(val);
            } else {
              onGenieOtpSubmit(val);
            }
          }}
          otpErrorMsg={otpErrorMsg}
          isSubmitting={
            genieOtpStep === 1 ? isLoadingGenieRequest : isLoadingGenieEnable
          }
          setOtpErrorMsg={setOtpErrorMsg}
          otpUser="Genie admin"
          step={genieOtpStep}
          reasonSubmitBtnText="Request Genie"
          reasonHeading="Enter Reason for Genie Access (with JIRA link)"
          otpType={OTP_TYPE.REQUEST_GENIE}
        />
      )}
    </>
  );
};

export default VMContainer;
