import { Stack } from '@mui/material';
import LoginHeader from 'assets/LoginPage/LoginHeader';
import { LoginPageImage } from 'assets/LoginPage/LoginPageImage';

export default function LoginContainer(props) {
  return (
    <Stack
      height={'100vh'}
      width={'100%'}
      sx={{
        background: 'linear-gradient(55deg, #132A3D 0%, #438AC9 100%)',
      }}
    >
      <LoginHeader />
      <Stack height="100%" direction="row">
        <Stack width="40%" alignItems="center" justifyContent="center">
          <Stack
            p={2}
            width="364px"
            sx={{
              backgroundColor: '#fff',
              borderRadius: '8px',
            }}
          >
            <Stack p={2}>{props.children}</Stack>
          </Stack>
        </Stack>
        <Stack width="60%" alignItems="center" justifyContent="center">
          <LoginPageImage />
        </Stack>
      </Stack>
    </Stack>
  );
}
