import { Grid, Paper, Stack } from '@mui/material';
import { Grid as VirtualizedGrid } from 'react-virtualized';
import _merge from 'lodash/merge';
import { ThreeDotsLoader } from '../../../../common/custom-components/lib/components/ThreeDotsLoader/ThreeDotsLoader';
import { VirtualizedChartGridProps } from '../DbMonitoringSchema';
import { getMemoizedChartKey } from '../getMemoizedChartKey';
import { getResponsiveGridProps } from '../getResponsiveGridProps';

import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { useStyles } from '../monitoringCustomStyles';

export const VirtualizedChartGrid = (props: VirtualizedChartGridProps) => {
  const {
    width,
    filteredMonitoringMetrics,
    memoizedCharts,
    isLoadingCharts,
    height,
    scrollTop,
    isScrolling,
    onChildScroll,
  } = props;

  const { rowCount, columnCount, columnWidth } = getResponsiveGridProps(
    width,
    filteredMonitoringMetrics?.length,
  );

  const classes = useStyles();

  const VirtualizedChartCellRenderer = (args) => {
    const { isVisible, key, style, columnIndex, rowIndex } = args;

    const index = rowIndex * columnCount + columnIndex;
    if (index >= filteredMonitoringMetrics?.length) return <></>;

    const memoizedKey = getMemoizedChartKey(filteredMonitoringMetrics[index]);
    return (
      <Stack style={style} key={key}>
        <Grid
          item
          sx={{
            margin: '16px',
            display: 'flex',
            flex: '1 1 auto',
          }}
        >
          <Paper elevation={0} sx={classes.monTile}>
            {isVisible && !isLoadingCharts ? (
              memoizedCharts[memoizedKey]
            ) : (
              <Stack
                sx={{
                  height: '480px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ThreeDotsLoader />
              </Stack>
            )}
          </Paper>
        </Grid>
      </Stack>
    );
  };

  return (
    <VirtualizedGrid
      id="service-monitoring-virtualized-list"
      width={width} // Width provided by AutoSizer
      rowHeight={500} // Height of each Chart/Row
      columnWidth={columnWidth} // Width of each column
      cellRenderer={VirtualizedChartCellRenderer} // Function to render each cell
      rowCount={rowCount} // Number of rows in the grid
      columnCount={columnCount} // Number of columns in the grid
      overscanRowCount={100} // Overscan the upcoming rows count
      scrollingResetTimeInterval={0}
      autoHeight
      height={height} // Height provided by AutoSizer
      isScrolling={isScrolling}
      onScroll={(onScrollProps): void => {
        onChildScroll(onScrollProps);
      }}
      scrollTop={scrollTop}
    />
  );
};
