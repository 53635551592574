import { styles } from 'assets/scss/style-templates';

export const dashboardStyles = {
  body: { mt: 10, height: 'calc(100% - 80px)', overflowY: 'hidden' },
  chartFamilyContainer: {
    p: 3,
    mb: 3,
    borderRadius: '10px',
    border: `1px solid rgba(30, 126, 161, 0.5)`,
  },
  mapAndSummaryContainer: {
    p: 4,
    flexDirection: 'row',
    alignItems: 'center',
    mb: 5,
    height: '90vh',
  },
  mapContainer: {
    alignItems: 'center',
    width: '65%',
  },
  mapTitle: {
    fontSize: '28px',
    color: styles.color.darkBorder,
    fontWeight: 600,
  },
  chartFamilyTitle: {
    fontSize: '24px',
    color: styles.color.darkBorder,
    fontWeight: 600,
    pb: 3,
  },
  chartsContainer: {
    px: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  individualChartContainer: {
    mb: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  fullWidthHeightLoadingOverlay: {
    width: '100%',
    height: '100vh',
  },
};
