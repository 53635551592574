import { useGetCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { useEffect, useMemo } from 'react';
import { dapResponseAdapter } from './dapResponseAdapter';

type DAPProps = {
  id?: String;
  tenantId?: String;
  pgSource?: String;
};

export const useDAP = (props: DAPProps) => {
  const { id, tenantId, pgSource } = props;
  const { response: DAPList, isLoading: dapIsLoading } = useGetCall(
    `${URLS.fetchAvailabilityMachineDaps}availability-machine-id=${id}&tenant-id=${tenantId}&tenant-domain=${pgSource}`,
    dapResponseAdapter,
  );

  const dapList = useMemo(() => {
    return DAPList || [];
  }, [DAPList]);
  return { dapList, dapIsLoading };
};
