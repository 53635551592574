import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { JiraPriorities } from '../../tenants-detailed-view/lib/utils/constants';
import { TICKETS_BASE_URL, URLS } from 'constants/URL';
import { useMemo, useState } from 'react';
import { adaptAssignableUsersResponse } from 'customers/ticket/responseAdapter/adaptAssignableUsersResponse';
import { adaptComponentResponse } from 'customers/ticket/responseAdapter/adaptComponentResponse';
import useAddApiNotification from 'common/NotificationContainer/useAddApiNotification';
import { useUserLoginInfoContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { useGetCall, useLazyPostCall } from 'common/api/useApiCall';
import { adaptIssueTypesResponse } from 'customers/ticket/responseAdapter/adaptIssueTypesResponse';
import moment from 'moment';

const initialTicketState = {
  priority: 3,
  jiraAssignee: '',
  jiraReporter: '61d62c4c68926d0068670468',
  jiraComponent: 'tessell-ops',
  issuetype: 'Bug',
  subject: '',
  summary: '',
  status: 2,
};

export const useCreateTicket = ({
  tenant,
  onSuccess,
}: {
  tenant: TenantType;
  onSuccess: () => void;
}) => {
  const [state, setState] = useState<any>(initialTicketState);

  const { response: jiraComponents } = useGetCall(
    URLS.getJiraComponents,
    adaptComponentResponse,
  );
  const { response: jiraUsers } = useGetCall(
    URLS.getJiraAssignableUsers,
    adaptAssignableUsersResponse,
  );
  const { response: jiraIssues } = useGetCall(
    URLS.getJiraIssueTypes,
    adaptIssueTypesResponse,
  );

  const createTicketRes = useLazyPostCall(`${TICKETS_BASE_URL}/`);
  const loginInfo = useUserLoginInfoContext();

  const updateState = (obj) => {
    setState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  const createTicket = () => {
    const jiraMetadata = {
      fields: {
        project: {
          key: 'SRE',
        },
        issuetype: {
          name: state?.issuetype,
        },
        summary: state?.subject,
        components: [
          {
            name: state?.jiraComponent,
          },
        ],
        description: state?.summary,
        environment: tenant?.domain,
        priority: {
          name: JiraPriorities[state.priority],
        },
        assignee: {
          id: state?.jiraAssignee,
        },
        reporter: {
          id: state?.jiraReporter,
        },
        labels: ['SRE-observability'],
        duedate: moment(state?.duedate).format('YYYY-MM-DD'),
      },
    };

    createTicketRes.postData(
      JSON.stringify({
        name: loginInfo?.attributes?.name || '',
        email: loginInfo?.attributes?.email || '',
        subject: state?.subject,
        type: 'Incident',
        status: state?.status,
        priority: state.priority,
        description: state?.summary,
        source: 2,
        tags: ['dev'],
        jiraMetadata,
        tenant_id: tenant?._id,
      }),
    );
  };

  const resetState = () => {
    setState(initialTicketState);
  };

  useAddApiNotification({
    successMsg: 'Successfully created the ticket',
    errorMsg: 'Something went wrong. Please try again.',
    apiResponse: createTicketRes,
    onSuccess: onSuccess,
  });

  const isSubmitBtnDisabled = useMemo(() => {
    if (
      !state?.subject?.trim() ||
      !state?.summary?.trim() ||
      !state?.jiraComponent ||
      !state?.priority ||
      !state?.jiraAssignee ||
      !state?.jiraReporter ||
      !state?.issuetype ||
      !moment(state?.duedate).isValid()
    ) {
      return true;
    }
    return false;
  }, [state]);

  return {
    createTicket,
    state,
    updateState,
    isSubmitting: createTicketRes?.isLoading,
    jiraComponents,
    jiraUsers,
    jiraIssues,
    resetState,
    isSubmitBtnDisabled,
  };
};
