import { Search } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

const LogsListAndSearch = (props: any) => {
  const {
    fileSearch,
    setFileSearch,
    filesToDisplay,
    selectedLogs,
    handleFileSelect,
    classes,
    filesHeading,
  } = props;

  return (
    <Stack
      alignItems="flex-start"
      direction="column"
      spacing={2}
      sx={classes.filesDisplayBox}
      p={2}
    >
      <TextField
        label="Search"
        name="FileSearch"
        value={fileSearch || ''}
        size="small"
        variant="standard"
        onChange={(e) => setFileSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <Box>
        {filesHeading && <Typography variant="h6">{filesHeading}</Typography>}
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {filesToDisplay?.map((f, i) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLogs?.includes(f)}
                    onChange={handleFileSelect}
                    name={f}
                  />
                }
                label={f}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
    </Stack>
  );
};

export default LogsListAndSearch;
