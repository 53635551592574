/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-plusplus */
/* eslint-disable no-duplicate-case */
/* eslint-disable no-nested-ternary */

import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  InfoOutlined,
  FiberManualRecord,
  ArrowUpward,
  ArrowDownward,
  Build,
  ExpandMore,
} from '@mui/icons-material';
import {
  StartingIcon,
  MonitoringIcon,
  LogsIcon,
  SharingIcon,
  IntegrationsIcon,
  CloudWatchIcon,
  DatadogIcon,
  ElasticsearchIcon,
  GCPLoggingIcon,
  KafkaIcon,
  PrometheusIcon,
  DeleteIcon,
  OracleSmallIcon,
  PostgresSmallIcon,
  MySQLSmallIcon,
  InstancesIcon,
  StoppingIcon,
  FailedIcon,
  SwitchOverIcon,
  DatabaseIcon,
  OverviewIcon,
  SQLServerIconSmall,
  BackingUpIcon,
  CircularWarningIcon,
  // TagIcon,
} from 'assets/icons-v2';
import STATUS, { StatusString, SubStatus } from './Status';
import { shouldEnableInstanceResizeFeature } from './featureFlags';
import moment from 'moment';
// import SQLServerIconMicro from 'icons-v2/SQLServerIconMicro';
import { getDatabaseDRCount } from './instances/utils/getDatabaseDRCount';
import { getDatabaseDCCount } from './instances/utils/getDatabaseDCCount';
import { getDatabaseHANodeCount } from './instances/utils/getDatabaseHANodeCount';
import { styles } from 'assets/scss/style-templates';

interface LabelValueProps {
  label: string;
  value: string;
  boldVal?: Boolean;
  labelValueStyles?: any;
}
export function LabelValue(props: LabelValueProps) {
  const { label, value, boldVal = false, labelValueStyles } = props;
  const classes = useStyles();
  return (
    <Stack direction="row" alignItems="center" p={0.25}>
      {label && (
        <Typography
          pr={1}
          sx={{
            ...labelValueStyles?.labelStyles,
            ...(!boldVal && classes.boldText),
          }}
          width="fit-content"
        >
          {label}:
        </Typography>
      )}
      <Typography
        width="fit-content"
        sx={{
          ...labelValueStyles?.valueStyles,
          ...(boldVal && classes.boldText),
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
}

export const useStyles = () => {
  const theme = useTheme();
  return {
    intgTile: {
      width: 400,
      height: 200,
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      border: `1px solid ${styles.color.lighterShade}`,
      borderRadius: '10px',
      position: 'relative',
    },
    tagIcon: {
      backgroundColor: styles.color.greyBackground,
      color: styles.color.darkShade,
    },
    // icon: { color: styles.color.darkShade },
    intgSkeleton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      background: 'rgba(151, 151, 151, 0.25)',
    },
    intgButton: {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    monSkeleton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderRadius: '10px',
      background: 'rgba(151, 151, 151, 0.25)',
    },
    monTile: {
      width: '100%',
      minWidth: 400,
      maxWidth: 800,
      // marginTop: theme.spacing(2),
      // marginRight: theme.spacing(2),
      // paddingRight: theme.spacing(1),
      borderRadius: '10px',
      border: `1px solid ${styles.color.lighterShade}`,
    },
    toggleButton: {
      '& .MuiToggleButton-root': {
        backgroundColor: '#FFFFFFAA !important',
        color: `${styles.color.textBoxLabel}`,
        width: '120px',
      },
      '& .MuiToggleButton-root.Mui-selected': {
        // backgroundColor: '#E8F5FF !important',
        color: `${styles.color.baseBackground}`,
        backgroundColor: `${styles.color.darkShade} !important`,
      },
    },
    mainButton: {
      border: 'none',
      // borderRadius: '10px',
      minWidth: '150px',
      '&:hover': {
        border: 'none',
        // borderRadius: '10px',
        minWidth: '150px',
        cursor: 'pointer',
      },
    },
    resetOverviewBtn: {
      border: 'none',
      // borderRadius: '10px',
      width: '100%',
      '&:hover': {
        border: 'none',
        // borderRadius: '10px',
        width: '100%',
        cursor: 'pointer',
      },
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    headingText: {
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: 2,
      fontSize: '16px',
    },
    boldTextLight: {
      fontWeight: theme.typography.fontWeightMedium,
      color: styles.color.monotoneDarker,
    },
    filter: {
      width: 250,
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
    timePicker: {
      width: 430,
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
    contentBox: {
      boxSizing: 'border-box',
      borderRadius: '8px',
      boxShadow:
        ' 0px 1px 4px rgba(31, 41, 55, 0.06), 0px 1px 6px rgba(31, 41, 55, 0.1); !important',
      background: 'white',
      marginBottom: '20px',
    },
    accordionBox: {
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow:
        '0px 1px 4px rgba(31, 41, 55, 0.06), 0px 1px 6px rgba(31, 41, 55, 0.1) !important',
      marginBottom: '20px',
    },
    accordionSummaryBox: {
      backgroundColor: '#F7F7F7',
      // boxShadow: '0px 1px 4px rgba(31, 41, 55, 0.06), 0px 1px 6px rgba(31, 41, 55, 0.1) !important',
      borderRadius: '4px',
    },
    progress: {
      color: 'white',
    },
    innerContentBox: {
      borderRadius: '10px',
      border: `1px solid ${styles.color.lighterShade}`,
      background: 'white',
    },
    filesDisplayBox: {
      background: styles.color.greyBackground,
      height: '100%',
      minWidth: 250,
    },
    logsTab: {
      paddingLeft: '16px',
      paddingTop: '0px',
      paddingBottom: '0px',
      '&.Mui-selected': {
        backgroundColor: styles.color.greyBackground,
      },
    },
    nwInfoBox: {
      width: '50%',
      minWidth: '680px',
    },
    computeInfoBox: {
      width: '50%',
      minWidth: 400,
      maxWidth: 500,
      minHeight: 150,
      padding: theme.spacing(2),
      boxSizing: 'border-box',
      borderRadius: '10px',
    },
    instanceNwInfoBox: {
      minWidth: 600,
      maxWidth: 600,
      minHeight: 200,
      padding: theme.spacing(2),
      // marginTop: theme.spacing(2),
      boxSizing: 'border-box',
      borderRadius: '10px',
      // border: `1px solid ${styles.color.lighterShade}`,
    },
    compactBox: {
      padding: '5px 10px',
      fontSize: '14px',
    },
    chipsBox: {
      padding: '5px 10px',
      fontSize: '14px',
      border: '1px solid red',
    },

    dbInfo: {
      background: '#EEEEEE',
      borderRadius: '10px',
    },
    connectionInfoEdit: { width: '30%', justifyContent: 'flex-end' },
    lightText: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    engineLogo: {
      width: 100,
      height: 32,
    },
    backButton: {
      alignSelf: 'self-start',
    },
    linkIcon: {
      width: 48,
      marginRight: theme.spacing(1),
    },
    button: {
      marginRight: theme.spacing(1),
    },
    skeleton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    deleteBox: {
      border: '1px solid #E1E5EA',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'border 200ms linear',
    },
    input: {
      marginTop: theme.spacing(2),
      width: 350,
    },
    deleteIcon: {
      '--icon-color': styles.color.red,
    },
    liveLogs: {
      color: styles.color.green,
    },
    icon: {
      color: styles.color.darkShade,
      cursor: 'pointer',
    },
    UpInstanceDot: {
      height: '12px',
      width: '12px',
      color: styles.color.green,
    },
    DownInstanceDot: {
      height: '12px',
      width: '12px',
      color: styles.color.red,
    },
    StoppedInstanceDot: {
      height: '12px',
      width: '12px',
      color: styles.color.warn,
    },
    statusBox: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '3px',
      paddingBottom: '3px',
      borderRadius: '4px',
    },
  };
};
