import { JiraPriorities } from '../../tenants-detailed-view/lib/utils/constants';
import { TICKETS_INTERNAL_BASE_URL, URLS } from 'constants/URL';
import { useEffect, useMemo, useState } from 'react';
import { adaptAssignableUsersResponse } from 'customers/ticket/responseAdapter/adaptAssignableUsersResponse';
import { adaptComponentResponse } from 'customers/ticket/responseAdapter/adaptComponentResponse';
import useAddApiNotification from 'common/NotificationContainer/useAddApiNotification';
import { useGetCall, useLazyPostCall } from 'common/api/useApiCall';
import { adaptIssueTypesResponse } from 'customers/ticket/responseAdapter/adaptIssueTypesResponse';
import moment from 'moment';

const initialTicketState = {
  priority: 3,
  jiraAssignee: '',
  jiraReporter: '61d62c4c68926d0068670468',
  jiraComponent: 'tessell-ops',
  issuetype: 'Bug',
  subject: '',
  summary: '',
  status: 2,
  duedate: null,
};

export const useCreateAlertJira = ({
  alert,
  onSuccess,
}: {
  alert: any;
  onSuccess: () => void;
}) => {
  const [state, setState] = useState<any>(initialTicketState);
  const updateState = (obj) => {
    setState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  const formatKeyToString = (key) => {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  useEffect(() => {
    if (alert) {
      const { alertName, labels, id } = alert;
      const _summary = Object.keys(labels)?.reduce((acc, key) => {
        const _val = `${formatKeyToString(key)}: ${labels[key]}`;
        return acc ? acc + `\n${_val}` : `${_val}`;
      }, '');
      updateState({
        subject: alertName || '',
        summary: _summary,
      });
    }
  }, [alert]);

  const { response: jiraComponents } = useGetCall(
    URLS.getJiraComponents,
    adaptComponentResponse,
  );
  const { response: jiraUsers } = useGetCall(
    URLS.getJiraAssignableUsers,
    adaptAssignableUsersResponse,
  );
  const { response: jiraIssues } = useGetCall(
    URLS.getJiraIssueTypes,
    adaptIssueTypesResponse,
  );

  const createTicketRes = useLazyPostCall(TICKETS_INTERNAL_BASE_URL);

  const createTicket = () => {
    const jiraPayload = {
      fields: {
        project: {
          key: 'SRE',
        },
        issuetype: {
          name: state?.issuetype,
        },
        summary: state?.subject,
        components: [
          {
            name: state?.jiraComponent,
          },
        ],
        description: state?.summary,
        environment: alert?.labels?.eks_namespace,
        priority: {
          name: JiraPriorities[state?.priority],
        },
        assignee: {
          id: state?.jiraAssignee,
        },
        reporter: {
          id: state?.jiraReporter,
        },
        labels: ['SRE-observability'],
        duedate: moment(state?.duedate).format('YYYY-MM-DD'),
      },
    };

    createTicketRes.postData(
      JSON.stringify({
        alertId: alert?.alertId,
        jiraPayload,
        isAlertsJira: true,
      }),
    );
  };

  const resetState = () => {
    setState(initialTicketState);
  };

  useAddApiNotification({
    successMsg: 'Successfully created the ticket',
    errorMsg: 'Something went wrong. Please try again.',
    apiResponse: createTicketRes,
    onSuccess: onSuccess,
  });

  const isSubmitBtnDisabled = useMemo(() => {
    if (
      !state?.subject?.trim() ||
      !state?.summary?.trim() ||
      !state?.priority ||
      !state?.jiraAssignee ||
      !state?.jiraReporter ||
      !state?.issuetype ||
      !moment(state?.duedate).isValid()
    ) {
      return true;
    }
    return false;
  }, [state]);

  return {
    createTicket,
    state,
    updateState,
    isSubmitting: createTicketRes?.isLoading,
    jiraComponents,
    jiraUsers,
    jiraIssues,
    resetState,
    isSubmitBtnDisabled,
  };
};
