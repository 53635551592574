import React, { useState, useEffect, useContext, useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'chartjs-adapter-moment';
import {
  Typography,
  Box,
  Divider,
  Button,
  Chip,
  Stack,
  styled,
} from '@mui/material';
import { CopyClipboardIcon } from 'assets/icons-v2';
import { useStyles, MODAL, useIsFeatureEnabled } from '../utils';
import LabelValueAction from 'common/custom-components/lib/components/LabelValueAction';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { useServiceOverviewStyles } from '../utils/useServiceOverview.styles';
import UpdateInProgress from '../updateInProgress';
import { isNullOrUndefined } from 'helpers/isNullOrUndefined';
import ConnectStringGenerator from '../ConnectStringGenerator';
import { TenantContext } from 'common/TenantContainer/TenantContainer';
import { evaluateTenantName } from 'common/TenantContainer/evaluateTenantName';
import VMContainer from './VMContainer/VMContainer';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';

const StyledCircleBtn = styled(Button)({
  height: '30px !important',
  width: '30px !important',
  minWidth: '30px !important',
  minHeight: '30px !important',
  borderRadius: '4px !important',
});

export const ShowAttr = (
  title,
  value,
  actionText,
  actionIcon,
  action,
  customAction,
  customStyle,
  classNames = {
    containerStyles: {},
    titleStyles: {},
    valueStyles: {},
    buttonStyles: {},
  },
) => {
  const classes = useStyles();

  let button = customAction || <Box />;
  if (actionText) {
    button = (
      <Button
        color="primary"
        startIcon={actionIcon}
        onClick={(e) => {
          if (action) {
            action(e);
          }
        }}
      >
        {actionText}
      </Button>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      height={customStyle === 'compact' ? '' : 70}
      sx={
        customStyle === 'compact'
          ? { ...classes.compactBox, ...classNames?.containerStyles }
          : classNames?.containerStyles
      }
    >
      {title && (
        <Typography
          minWidth="150px"
          style={{ width: '30%', fontSize: 14 }}
          sx={
            customStyle === 'compact'
              ? { ...classes.boldTextLight, ...classNames?.titleStyles }
              : { ...classes.boldText, ...classNames?.titleStyles }
          }
        >
          {title}
        </Typography>
      )}
      <Stack justifyContent="flex-start" width="90%">
        {typeof value === 'string' || typeof value === 'number' ? (
          <Typography
            sx={
              customStyle === 'compact'
                ? classNames?.valueStyles
                : { ...classes.lightText, ...classNames?.valueStyles }
            }
          >
            {value}
          </Typography>
        ) : (
          value
        )}
      </Stack>
      <Stack sx={{ ...classes.connectionInfoEdit, ...classNames.buttonStyles }}>
        {button}
      </Stack>
    </Box>
  );
};

interface ServiceOverviewProps {
  service: DatabaseServiceType;
  canUpdateDb: boolean;
  canUpdateTags: boolean;
  poolsRes: any;
  isMultiAZ: boolean;
  fetchDBServiceId?: Function;
}

export const ServiceOverview = (props: ServiceOverviewProps) => {
  const classes = useServiceOverviewStyles();
  const { service: database, canUpdateDb, poolsRes } = props;

  const fetchEnabledFeatures = useIsFeatureEnabled();
  const { isAWSPrivateLinkEnabled } = fetchEnabledFeatures(database);
  const { hasPermission } = useContext(UserPermissionsContext);

  const { tenant, setCurrentTenantId } = useContext(TenantContext);

  useEffect(() => {
    if (database?.tenantId) setCurrentTenantId(database?.tenantId);
  }, [database?.tenantId]);

  const tenantName = useMemo(() => {
    return evaluateTenantName({
      tenant: tenant,
    });
  }, [tenant]);

  const refIPAddress = React.createRef<HTMLDivElement>();
  const refPrivateLink = React.createRef<HTMLDivElement>();
  const refTags = React.createRef<HTMLDivElement>();

  const [containerHeights, setContainerHeights] = useState({
    IpAddress: 0,
    PrivateLink: 0,
    Tags: 0,
  });

  useEffect(() => {
    const IPHeight: number = refIPAddress?.current?.clientHeight || 0;
    const PrivateLinkHeight: number =
      refPrivateLink?.current?.clientHeight || 0;
    const TagsHeight: number = refTags?.current?.clientHeight || 0;
    setContainerHeights({
      IpAddress: IPHeight,
      PrivateLink: PrivateLinkHeight,
      Tags: TagsHeight,
    });
  }, [database]);

  const getNetworkInfo = () => {
    if (!database) {
      return null;
    }
    const { provisionInfo, connectivityInfo } = database;

    const { serviceConnectivity } = provisionInfo;

    const { enablePublicAccess } = serviceConnectivity;

    const {
      updateInProgressInfo,
      privateLink,
      allowedIpAddresses = [],
    } = connectivityInfo.userView;

    if (!serviceConnectivity) {
      return null;
    }

    function getPrivateLink() {
      if (updateInProgressInfo?.privateLink?.servicePrincipals) {
        return (
          <UpdateInProgress
            updateInfo={
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography fontSize={12}>Service Principals:</Typography>
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  spacing={2}
                  maxWidth="450px"
                  height="45px"
                  pt={0.5}
                  sx={{ overflowX: 'auto' }}
                >
                  {updateInProgressInfo?.privateLink?.servicePrincipals.map(
                    (principal, index) => (
                      <Chip
                        label={principal}
                        variant="outlined"
                        key={`chip_${index}`}
                      />
                    ),
                  )}
                </Stack>
              </Stack>
            }
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
        );
      }

      if (
        privateLink?.endpointServiceName !== null &&
        privateLink?.servicePrincipals?.length === 0
      ) {
        return (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="hidden"
            maxHeight="80px"
            ref={refPrivateLink}
          >
            <Typography fontSize={14}>
              {privateLink?.endpointServiceName}
            </Typography>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="scroll"
              flexWrap="wrap"
              width="100%"
              minWidth="420px"
              maxWidth="100%"
              marginTop={0.7}
            >
              {[...privateLink.servicePrincipals].map((principal) => (
                <Chip
                  label={principal}
                  variant="outlined"
                  sx={{
                    marginRight: 0.7,
                    marginBottom: 0.7,
                  }}
                />
              ))}
            </Stack>
          </Stack>
        );
      }

      return <>No Private Link Configured</>;
    }

    function getPrivateLinkButton() {
      if (updateInProgressInfo?.privateLink?.servicePrincipals) {
        return null;
      }

      if (privateLink?.endpointServiceName) {
        return null;
      }

      return null;
    }

    function getPublicAccessButton() {
      if (updateInProgressInfo?.enablePublicAccess) {
        return (
          <UpdateInProgress
            updateInfo={
              <Stack
                direction="row"
                spacing={4}
                alignItems="center"
                width="100%"
              >
                <Typography style={{ fontSize: 14 }} sx={classes.boldText}>
                  Publicly Accessible
                </Typography>
                <Typography sx={classes.lightText}>
                  {updateInProgressInfo?.enablePublicAccess ? 'Yes' : 'No'}
                </Typography>
              </Stack>
            }
          />
        );
      }

      if (canUpdateDb) {
        return null;
      }

      return null;
    }

    const getIpAddressButton = () => {
      if (updateInProgressInfo?.allowedIpAddresses) {
        return (
          <UpdateInProgress
            updateInfo={
              <Stack
                direction="row"
                spacing={4}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  style={{ fontSize: 14 }}
                  sx={classes.boldText}
                  width="100%"
                >
                  Allowed IP Addresses
                </Typography>
                <Stack direction="row" spacing={1}>
                  {updateInProgressInfo?.allowedIpAddresses?.map((ip) => (
                    <Chip label={ip} />
                  ))}
                </Stack>
              </Stack>
            }
          />
        );
      }

      if (canUpdateDb) {
        return null;
      }

      return null;
    };

    return (
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="700px"
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          mb={2}
        >
          <Stack>
            <Typography sx={{ ...classes.headingText, paddingBottom: 0 }}>
              Connection Details
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{ ...classes.lightText, paddingBottom: 0, color: '#666666' }}
            >
              Generate Service URL for different instances, databases &
              connection pools
            </Typography>
          </Stack>
        </Stack>
        <Box display="flex" flexDirection="column">
          <ConnectStringGenerator
            service={database}
            pools={poolsRes}
            MODAL={MODAL}
            updateInProgressInfo={updateInProgressInfo}
          />
          {hasPermission(PERMISSIONS.GENIE) && (
            <Box display="flex" flexDirection="column" mt={2}>
              <VMContainer
                serviceId={database?._id}
                tenantId={database?.tenantId}
                tenantDomain={database?.pgSource}
                tenantName={tenantName}
                instances={database?.instances}
              />
              <LabelValueAction
                label="Publicly Accessible"
                value={enablePublicAccess ? 'Yes' : 'No'}
                action={getPublicAccessButton()}
              />
              <Divider />
              <LabelValueAction
                label="Allowed IP Addresses"
                value={
                  <Stack
                    ref={refIPAddress}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    overflow="scroll"
                    flexWrap="wrap"
                    width="100%"
                    minWidth="420px"
                    maxWidth="100%"
                    maxHeight="80px"
                  >
                    {!isNullOrUndefined(allowedIpAddresses) &&
                    allowedIpAddresses?.length !== 0
                      ? [...allowedIpAddresses]?.map((ip) => (
                          <Chip
                            label={ip}
                            sx={{
                              marginRight: 0.7,
                              marginBottom:
                                containerHeights.IpAddress > 32 ? 0.7 : 0,
                            }}
                          />
                        ))
                      : 'No allowed IP Address for public access'}
                  </Stack>
                }
                action={getIpAddressButton()}
                overrideStyles={{
                  labelContainer: {
                    alignItems:
                      containerHeights.IpAddress > 32 ? 'flex-start' : 'center',
                  },
                }}
              />
              <Divider />
              {isAWSPrivateLinkEnabled && (
                <LabelValueAction
                  label="AWS Private Link"
                  value={getPrivateLink()}
                  action={
                    privateLink?.endpointServiceName ? (
                      <CopyToClipboard text={privateLink?.endpointServiceName}>
                        <StyledCircleBtn color="primary">
                          <Stack>
                            <CopyClipboardIcon />
                          </Stack>
                        </StyledCircleBtn>
                      </CopyToClipboard>
                    ) : (
                      getPrivateLinkButton()
                    )
                  }
                  overrideStyles={{
                    labelContainer: {
                      alignItems:
                        privateLink?.servicePrincipals.length === 0
                          ? 'center'
                          : 'flex-start',
                    },
                  }}
                />
              )}
              <Divider />
            </Box>
          )}
        </Box>
      </Stack>
    );
  };

  return (
    <Stack
      direction="column"
      height="100%"
      alignItems="flex-start"
      mt={3}
      ml={3}
    >
      {getNetworkInfo()}
    </Stack>
  );
};
