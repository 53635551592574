import { Divider, LinearProgress, Stack, Typography } from '@mui/material';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { useSchedule } from './useSchedule';
import ScheduleDetails from './ScheduleDetails';
import { isEmpty } from 'lodash';
import { styles } from 'assets/scss/style-templates';

type ScheduleProps = {
  service?: DatabaseServiceType;
};

const Schedules = (props: ScheduleProps) => {
  const { service } = props;
  const { schedule, scheduleIsLoading } = useSchedule({
    dbServiceId: service?._id,
    pgSource: service?.pgSource,
  });
  const { deletionSchedule } = service;
  return (
    <Stack width={'50%'} spacing={3}>
      <Stack spacing={2}>
        <Typography fontSize="20" variant="h6">
          Start/Stop Schedule
        </Typography>

        {scheduleIsLoading ? (
          <LinearProgress />
        ) : (
          <>
            {!isEmpty(schedule) ? (
              <ScheduleDetails
                schedule={schedule}
                type="startStopSchedule"
                status={schedule?.status}
              />
            ) : (
              <Typography>No schedules available.</Typography>
            )}
          </>
        )}
      </Stack>
      <Divider
        sx={{
          borderBottomWidth: 1,
          borderColor: styles.color.monotoneDarkDark,
        }}
      />
      <Stack spacing={2}>
        <Typography fontSize="20" variant="h6">
          Delete Schedule
        </Typography>
        {!isEmpty(deletionSchedule) ? (
          <ScheduleDetails
            schedule={deletionSchedule}
            type="deletionSchedule"
          />
        ) : (
          <Typography>No schedules available.</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default Schedules;
