import { Stack, Typography } from '@mui/material';
import { BarChart } from '../ChartComponents';
import { barChartCardStyles } from './styles';

type BarChartCardProps = {
  labels?: any;
  data?: any;
  title?: String;
};

const calculateBarChartWidth = (data) => {
  if (data.length < 10) return '50%';
  else return '100%';
};

export const BarChartCard = (props: BarChartCardProps) => {
  const { labels, data, title } = props;
  const chartData = {
    labels: labels || [],
    data: data || [],
  };

  return (
    <Stack
      sx={{
        ...barChartCardStyles.container,
        width: `${calculateBarChartWidth(data)}`,
      }}
    >
      <Stack sx={barChartCardStyles.titleContainer}>
        <Typography sx={barChartCardStyles.titleText}>{title}</Typography>
      </Stack>
      <BarChart chartData={chartData} />
    </Stack>
  );
};
