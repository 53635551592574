import { useTheme } from '@mui/material/styles';
import { styles } from 'assets/scss/style-templates';

const useStyles = () => {
  const theme = useTheme();
  return {
    searchBox: {
      width: '25%',
      marginTop: theme.spacing(-1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    amCard: {
      width: '100%',
      overflow: 'visible',
      marginTop: theme.spacing(2),
    },
    policyCard: {
      width: '100%',
      overflow: 'visible',
      marginBottom: theme.spacing(2),
    },
    engineLogo: {
      width: 100,
      marginRight: theme.spacing(2),
    },
    AWS: {
      width: 40,
    },
    Azure: {
      width: 72,
    },
    backupCard: {
      width: 270,
      height: 260,
      marginTop: theme.spacing(0.5),
      marginBottom: 0,
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(1),
      borderRadius: '10px',
      border: `3px solid rgba(255, 255, 255, 0)`,
      boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25)',
      transition: 'border 200ms linear, background-color 200ms linear',
      '&:hover': {
        backgroundColor: styles.color.greyBackground,
        border: `3px solid ${styles.color.greyBackground}`,
      },
      zIndex: 9,
    },
    backupFirstCard: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginBottom: 0,
    },
    selectedBackupCard: {
      border: `3px solid ${styles.color.darkShade} !important`,
      transition: 'border 200ms linear, background-color 200ms linear',
    },
    backupActions: {
      // backgroundColor: '#EDF7FF',
    },
    contentBox: {
      boxSizing: 'border-box',
      borderRadius: '10px',
      background: 'white',
    },
    contentBox2: {
      borderRadius: '10px',
      border: `1px solid ${styles.color.lighterShade}`,
    },
    backupDetailCard: {
      width: '100%',
      minWidth: 600,
      minHeight: 250,
      borderRadius: '8px',
      border: `3px solid ${styles.color.darkShade}`,
    },
    backupIcon: {
      width: '32px',
      minWidth: 32,
    },
    backupIconLarge: {
      width: '64px',
      minWidth: 64,
    },
    smallIcon: {
      width: '12px',
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
    },
    clockIcon: {
      width: '16px',
      marginRight: theme.spacing(1),
    },
    cloneIcon: {
      width: '16px',
    },
    smallActionsIcon: {
      fontSize: '1rem',
    },
    filterOption: {
      marginLeft: theme.spacing(2),
      width: '50%',
      maxWidth: 250,
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
    boldText: {
      fontWeight: theme.typography.fontWeightBold,
    },
    backupCardTitle: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(0.4),
      // overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'word-wrap',
      display: 'inline-Block',
      width: '100%',
    },
    backupCardEntry: {
      fontWeight: theme.typography.fontWeightMedium,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-Block',
      width: '100%',
      marginY: theme.spacing(0.2),
    },
    filter: {
      width: 250,
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
    backupStatusText: {
      fontSize: theme.typography.pxToRem(15),
      color: styles.color.darkShade,
    },
  };
};

export default useStyles;
