import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserLoginInfoContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';

const AuthProtected = (props) => {
  /*
    redirect is un-auth access protected routes via url
  */

  const userLoginInfo = useUserLoginInfoContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      Object.keys(userLoginInfo)?.length === 0 ||
      !userLoginInfo?.signInUserSession?.accessToken?.jwtToken
    ) {
      navigate('/login', { replace: true });
    }
  });

  return <>{props.children}</>;
};

export { AuthProtected };
