import { Button, Stack, Typography } from '@mui/material';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { Close } from '@mui/icons-material';
import { BackupsChildrenTable } from './BackupsChildrenTable';

type BackupsChildrenModalProps = {
  open: boolean;
  setOpen: any;
  backupIdentifier?: string;
  availabilityMachineId: string;
  tenantId: string;
};

export const BackupChildrenModal = (props: BackupsChildrenModalProps) => {
  const { open, setOpen, backupIdentifier, availabilityMachineId, tenantId } =
    props;

  const DialogTitle = () => {
    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        width={'100%'}
        justifyContent={'space-between'}
      >
        <Typography variant="h5">Backup Details</Typography>
        <Button onClick={() => setOpen(false)}>
          <Close color="primary" fontSize="large" />
        </Button>
      </Stack>
    );
  };

  const DialogContent = () => {
    return (
      <Stack width={'99%'} padding={2}>
        <BackupsChildrenTable
          availabilityMachineId={availabilityMachineId}
          tenantId={tenantId}
          backupIdentifier={backupIdentifier?.length ? backupIdentifier : ''}
        />
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
  };

  const customStyles = {
    title: {},
    content: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth={'lg'}
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
    />
  );
};
