import { Stack } from '@mui/material';

import OverflowTooltip from 'common/custom-components/lib/components/OverflowTooltip';
import { GetEngineIcon } from 'customers/db-services-detailed-view/utils';

interface AvMachineHeaderProps {
  avMachine: any;
}

export const AMHeader = (props: AvMachineHeaderProps) => {
  const { avMachine } = props;

  if (!avMachine) {
    return null;
  }
  const { name, availabilityMachine } = avMachine;
  const { engineType } = availabilityMachine;

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      spacing={0.5}
      overflow="hidden"
      pr={3}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={2}
        overflow="hidden"
        width="100%"
      >
        <OverflowTooltip
          tooltip={name}
          text={name}
          variant="h5"
          placement="right"
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={2}
        sx={{ paddingLeft: 1 }}
        height="24px"
      >
        <GetEngineIcon size={24} engine={engineType?.toUpperCase()} />
      </Stack>
    </Stack>
  );
};
