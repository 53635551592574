export const adaptGroupsResponse = (response) => {
  return response?.groups?.length
    ? response?.groups?.map((_res) => {
        return {
          id: _res?._id,
          name: _res?.name,
          description: _res?.description,
          permissions: _res?.permissions || [],
          creationDate: _res?.creationDate,
          lastModifiedDate: _res?.lastModifiedDate,
        };
      })
    : [];
};
