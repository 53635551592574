import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import _includes from 'lodash/includes';
import { CloudInfrastructureTabView } from './CloudInfrastructureTabView';
import { styles } from 'Administration/PortalAdministration/styles';

const CloudInfrastructure = () => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (params['*'] === '') {
      navigate('infra-monitoring');
    }
  }, []);

  return (
    <Stack sx={styles.body}>
      <Stack sx={styles.title}>
        <Stack direction="row" alignItems="flex-start" spacing={2} width="50%">
          <Typography variant="h5">Cloud Infrastructure</Typography>
        </Stack>
      </Stack>
      <Routes>
        <Route path={':tabId/*'} element={<CloudInfrastructureTabView />} />
      </Routes>
    </Stack>
  );
};

export default CloudInfrastructure;
