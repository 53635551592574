import { isArray } from 'lodash';

export const createParams = (filters) => {
  return Object.keys(filters)
    ?.filter((_key) =>
      isArray(filters[_key])
        ? filters[_key]?.length
        : isNaN(filters[_key])
        ? filters[_key]?.length &&
          filters[_key]?.toString()?.toLowerCase() !== 'all'
        : true,
    )
    .reduce((acc, key) => `${acc}&${key}=${filters[key]}`, '');
};

export const createCommaSeparatedKeyValuesFromObject = (object) => {
  const _StringValues = Object?.keys(object)?.map((key) => {
    return `${key}=${object?.[key]}`;
  });

  return _StringValues?.join(',')?.toString() || '-';
};
