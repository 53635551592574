import { createContext, useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useLazyGetCall } from 'common/api/useApiCall';
import Cache from 'common/cache/lib/utils/Cache';
import { URLS, USERS_BASE_URL } from 'constants/URL';
import { REFRESH_TOKEN_AFTER } from 'constants/appConstants';
import useInterval from 'helpers/useInterval';
import { adaptUserPermissionResponse } from './adaptUserPrivilegeResponse';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';

interface UserLoginInfoProps {
  children: any;
}

export const UserLoginInfoContext = createContext({});
const SetUserLoginInfoContext = createContext((user: any) => {});
export const UserPermissionsContext = createContext<{
  hasPermission: (_permission: string) => boolean;
  isPermissionsLoading: boolean;
  getUserPrivileges: any;
}>(null);

export const useUserLoginInfoContext = (): any => {
  const userLoginInfo = useContext(UserLoginInfoContext);

  return Object.keys(userLoginInfo)?.length > 0
    ? userLoginInfo
    : JSON.parse(Cache.get('loginInfo') || '{}');
};
export const useSetUserLoginInfoContext = () => {
  const setUserLoginInfo = useContext(SetUserLoginInfoContext);
  return setUserLoginInfo;
};

const refresh = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (err, session) => {
        const { idToken, refreshToken, accessToken } = session;
        Cache.set('idToken', idToken?.jwtToken);
        Cache.set('accessToken', accessToken?.jwtToken);
      },
    );
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
};

export const UserLoginInfo = (props: UserLoginInfoProps) => {
  const { children } = props;
  const [loginInfo, setLoginInfo] = useState({});
  useInterval(() => {
    refresh();
  }, REFRESH_TOKEN_AFTER);
  const { setRefreshCount } = useContext(NotificationContext);

  const {
    response: userPermissions,
    isLoading: userPermissionsLoading,
    postData: getUserPermissions,
  } = useLazyGetCall(URLS.getUserPermissions, adaptUserPermissionResponse);

  useEffect(() => {
    if (Cache.get('idToken')) {
      getUserPermissions();
    }
  }, [loginInfo]);

  const setUserLoginInfo = (newLoginInfo?: any) => {
    setLoginInfo(newLoginInfo);
    Cache.set('loginInfo', JSON.stringify(newLoginInfo));
    Cache.set('userName', newLoginInfo?.username);
    if (newLoginInfo?.signInUserSession?.idToken?.jwtToken) {
      Cache.set('idToken', newLoginInfo?.signInUserSession?.idToken?.jwtToken);
      Cache.set(
        'accessToken',
        newLoginInfo?.signInUserSession?.accessToken?.jwtToken,
      );
      if (Cache.get('idToken') && Cache.get('userName') !== 'null') {
        getUserPermissions();
      }
    }
    setRefreshCount(1);
  };

  const hasPermission = (_permission) => {
    return userPermissions?.includes(_permission);
  };

  return (
    <UserPermissionsContext.Provider
      value={{
        hasPermission,
        isPermissionsLoading: userPermissionsLoading,
        getUserPrivileges: getUserPermissions,
      }}
    >
      <SetUserLoginInfoContext.Provider value={setUserLoginInfo}>
        <UserLoginInfoContext.Provider value={loginInfo}>
          {children}
        </UserLoginInfoContext.Provider>
      </SetUserLoginInfoContext.Provider>
    </UserPermissionsContext.Provider>
  );
};
