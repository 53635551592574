import { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { DF_HUMAN } from 'helpers/dateFormats';
import { useDownloads } from './useDownloads';
import { DownloadIcon } from 'assets/icons-v2';
import { downloadData } from 'common/api/downloadFile';
import { URLS } from 'constants/URL';

const Downloads = () => {
  const {
    page,
    downloads,
    downloadsCount,
    isLoading,
    pageCount,
    handlePaginationChange,
  } = useDownloads();

  const dbColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'fileName',
        headerName: 'file Name',
        width: 200,
      },
      {
        field: 'download',
        headerName: 'Download',
        width: 100,
        renderCell: (params) => {
          const { row: data } = params;
          const { fileName } = data;
          return (
            <Stack direction="row" alignItems="flex-start">
              <LoadingButton
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={(e) => {
                  const url = `${URLS.downloadExportedFile}${fileName}`;
                  downloadData(url, fileName)
                  // call download data here
                }}
              >
                Download
              </LoadingButton>
            </Stack>
          );
        },
      },
      {
        field: 'timestamp',
        headerName: 'Timestamp',
        width: 150,
        renderCell: (params) => {
          const { row: data } = params;
          const { timestamp } = data;
          const dateCreated = moment(timestamp).format(DF_HUMAN);
          if (!dateCreated) {
            return '';
          }
          return <Box>{dateCreated}</Box>;
        },
      },
    ],
    [],
  );

  return (
    <Stack sx={{ height: '100vh' }}>
      <DataGrid
        columns={dbColumns}
        rows={downloads}
        loading={isLoading}
        styleOverrides={{ hideShadow: true }}
        pageCount={pageCount}
        pageNo={page}
        handlePaginationChange={handlePaginationChange}
        totalCount={downloadsCount}
      />
    </Stack>
  );
};

export default Downloads;
