import React, { useEffect, useState } from 'react';
import { useLazyGetCall } from 'common/api/useApiCall';
import { TENANTS_BASE_URL } from 'constants/URL';
import { tenantByIdResponseAdapter } from './tenantByIdResponseAdapter';

export const TenantContext = React.createContext<{
  tenant: any;
  setCurrentTenantId: (tenantId: string) => void;
  fetchTenantByID: () => void;
  tenantIsLoading: boolean;
}>(null);

const TenantContainer = (props) => {
  const { children } = props;
  const [currentTenantId, setCurrentTenantId] = useState('');
  const [tenantData, setTenantData] = useState();

  const {
    response: tenantResponse,
    isLoading: tenantIsLoading,
    postData: fetchTenantByID,
  } = useLazyGetCall(
    `${TENANTS_BASE_URL}/${currentTenantId}`,
    tenantByIdResponseAdapter,
  );

  useEffect(() => {
    if (tenantResponse) {
      setTenantData(tenantResponse);
    }
  }, [tenantResponse]);

  useEffect(() => {
    setTenantData(null);
    if (currentTenantId) {
      fetchTenantByID();
    }
  }, [currentTenantId]);

  return (
    <TenantContext.Provider
      value={{
        tenant: tenantData,
        setCurrentTenantId,
        tenantIsLoading,
        fetchTenantByID,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContainer;
