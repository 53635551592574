export default function DownloadIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 14.3076V15.5384C2 16.1912 2.25934 16.8173 2.72097 17.279C3.18259 17.7406 3.8087 17.9999 4.46154 17.9999H15.5384C16.1913 17.9999 16.8174 17.7406 17.279 17.279C17.7406 16.8173 18 16.1912 18 15.5384V14.3076"
        stroke="#fff"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 9L9.99998 13.0769L14 9"
        stroke="#fff"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.0769V2"
        stroke="#fff"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
