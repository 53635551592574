import { TabView } from 'common/tab-view/lib/components/TabView';
import _isEmpty from 'lodash/isEmpty';
import { Stack } from '@mui/material';
import { useVNTabView } from './useVNTabView';

type VNTabViewProps = {
  tenant: any;
  vnDetails: any;
};

export const VNTabView = (props: VNTabViewProps) => {
  const { tabsProps, onTabClick, currentTab } = useVNTabView(props);

  return (
    <Stack mt={3}>
      <TabView
        tabs={tabsProps}
        onTabClick={onTabClick}
        currentTab={currentTab}
      />
    </Stack>
  );
};
