import { useMemo } from 'react';
import { Box, Link, Stack } from '@mui/material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { ExpandMore, ChevronRight, Edit, Add } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import {
  StatusBackgroundColor,
  StatusTextColor,
} from 'constants/statusConfigs';
import moment from 'moment';
import { DF_HUMAN } from 'helpers/dateFormats';
import { useExceptions } from './useExceptions';
import { statusFilter } from './constants';
import { useStyles } from 'customers/db-services-detailed-view/utils';
import { ExceptionStatusUpdateModal } from './UpdateModals/ExceptionStatusUpdateModal';
import { TicketFormModal } from 'customers/ticket/TicketFormModal';
import { JIRA_BASE_LINK } from 'constants/appConstants';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';

const Exceptions = () => {
  const {
    page,
    exceptions,
    typeFilter,
    filters,
    exceptionsCount,
    isLoading,
    pageCount,
    handlePaginationChange,
    handleFilterChange,
    selectedException,
    setSelectedException,
    openExceptionStatusUpdateModal,
    setOpenExceptionStatusUpdateModal,
    showCreateTicketModal,
    setShowCreateTicketModal,
    createTicketProps,
    createTicket,
    handleResetFilters,
    handleOnSuccessStatusUpdate,
  } = useExceptions();

  const classes = useStyles();

  const filterProps = useMemo(
    () =>
      [
        {
          name: 'Type',
          initialVal: filters?.type || 'All',
          options: typeFilter,
          onChange: (value) => handleFilterChange('type', value),
        },
        {
          name: 'Status',
          initialVal: filters?.status || 'All',
          options: statusFilter,
          onChange: (value) => handleFilterChange('status', value),
        },
      ].filter(Boolean),
    [filters, typeFilter],
  );

  const dbColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'exception',
        headerName: 'Exception',
        width: 200,
        renderCell: (params) => {
          const { row: data } = params;
          const { exception } = data;
          return (
            <Stack direction="row" alignItems="flex-start">
              <OverflowTooltip text={exception} variant="body" />
            </Stack>
          );
        },
      },
      {
        field: 'exceptionType',
        headerName: 'Type',
        width: 100,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
        renderCell: (params) => {
          const { row: data } = params;
          const { status } = data;
          return (
            <Stack direction="row" alignItems="flex-start">
              <StatusLabel
                backgroundColor={StatusBackgroundColor[status]}
                textColor={StatusTextColor[status]}
                text={status || 'NA'}
              />
            </Stack>
          );
        },
      },

      {
        field: 'timestamp',
        headerName: 'Timestamp',
        width: 150,
        renderCell: (params) => {
          const { row: data } = params;
          const { timestamp } = data;
          const dateCreated = moment(timestamp).format(DF_HUMAN);
          if (!dateCreated) {
            return '';
          }
          return <Box>{dateCreated}</Box>;
        },
      },
      {
        field: 'Jira',
        headerName: 'Jira',
        width: 50,
        renderCell: (params) => {
          const { row } = params;

          return row?.jiraId ? (
            <Link href={`${JIRA_BASE_LINK}${row?.jiraId}`} target="_blank">
              {row?.jiraId}
            </Link>
          ) : (
            '-'
          );
        },
      },
    ],
    [],
  );

  const menuItems = (_exception) => {
    return [
      {
        label: 'Update Status',
        icon: <Edit sx={classes.icon} />,
        onClick: () => {
          setSelectedException(_exception);
          setOpenExceptionStatusUpdateModal(true);
        },
        disabled: () => {
          return _exception?.exceptionId === '';
        },
      },
      {
        label: 'Create Jira',
        icon: <Add sx={classes.icon} />,
        onClick: () => {
          setSelectedException(_exception);
          setShowCreateTicketModal(true);
        },
        disabled: () => {
          return _exception?.exceptionId === '' || _exception?.jiraId;
        },
      },
    ];
  };

  return (
    <Stack sx={{ height: '100vh' }}>
      <DataGrid
        columns={dbColumns}
        rows={exceptions}
        loading={isLoading}
        styleOverrides={{ hideShadow: true }}
        pageCount={pageCount}
        pageNo={page}
        filters={filterProps}
        menuItems={menuItems}
        handlePaginationChange={handlePaginationChange}
        componentName="exceptions"
        onResetFilters={handleResetFilters}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        totalCount={exceptionsCount}
      />

      <ExceptionStatusUpdateModal
        show={openExceptionStatusUpdateModal}
        setShow={setOpenExceptionStatusUpdateModal}
        exception={selectedException}
        handleOnSuccess={handleOnSuccessStatusUpdate}
        initialStatus={selectedException?.status || ''}
      />
      <TicketFormModal
        show={showCreateTicketModal}
        onClose={() => setShowCreateTicketModal(false)}
        {...createTicketProps}
        onSubmit={createTicket}
        showDueDate={false}
      />
    </Stack>
  );
};

export default Exceptions;
