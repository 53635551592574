/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { IconPropType, useStyles } from './utils';

export default function MyDatabasesIcon(props: IconPropType) {
  const { className = '' } = props;
  const iconStyle = useStyles(props);

  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="8.25" cy="4" rx="6.75" ry="3" stroke="var(--icon-color)" />
      <path
        d="M8.25 11.25C4.52208 11.25 1.5 10.1307 1.5 8.75"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M1.5 4V13.0185C1.5 14.5532 4.12504 15.817 7.5 15.9818M15 4V7.25"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M13 13C13 13.3315 13.1317 13.6495 13.3661 13.8839C13.6006 14.1183 13.9185 14.25 14.25 14.25C14.5816 14.25 14.8995 14.1183 15.1339 13.8839C15.3683 13.6495 15.5 13.3315 15.5 13C15.5 12.6685 15.3683 12.3505 15.1339 12.1161C14.8995 11.8817 14.5816 11.75 14.25 11.75C13.9185 11.75 13.6006 11.8817 13.3661 12.1161C13.1317 12.3505 13 12.6685 13 13V13Z"
        stroke="var(--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 18C11.75 17.337 12.0134 16.7011 12.4823 16.2322C12.9511 15.7634 13.587 15.5 14.25 15.5C14.9131 15.5 15.549 15.7634 16.0178 16.2322C16.4866 16.7011 16.75 17.337 16.75 18"
        stroke="var(--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 19C16.8733 19 19 16.8734 19 14.25C19 11.6266 16.8733 9.5 14.25 9.5C11.6266 9.5 9.49997 11.6266 9.49997 14.25C9.49997 16.8734 11.6266 19 14.25 19Z"
        stroke="var(--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
