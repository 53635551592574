/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     12/20/2021     bakul.banthia         Created
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import OverflowTooltip from './OverflowTooltip';

export default function LabelValue(props) {
  const theme = useTheme();
  const {
    label, value, className, style, tooltipPlacement,
    width, maxWidth, mt, mb, mr, ml, mx, my,
  } = props;
  const styles = style || {};
  if (width) {
    styles.width = `${width}px`;
  }
  if (maxWidth) {
    styles.maxWidth = `${width}px`;
  }
  if (mt || my) {
    styles.marginTop = theme.spacing(mt || my);
  }
  if (mb || my) {
    styles.marginBottom = theme.spacing(mb || my);
  }
  if (mr || mx) {
    styles.marginRight = theme.spacing(mr || mx);
  }
  if (ml || mx) {
    styles.marginLeft = theme.spacing(ml || mx);
  }

  const text = (
    <>
      <strong>{label}</strong>
      {' '}
      : &nbsp;&nbsp;
      {value}
    </>
  );

  return (
    <OverflowTooltip
      text={text}
      tooltip={value}
      className={className}
      style={styles}
      placement={tooltipPlacement}
    />
  );
}

LabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.number,
  maxWidth: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  mt: PropTypes.number,
  mb: PropTypes.number,
  my: PropTypes.number,
  mx: PropTypes.number,
  className: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  style: PropTypes.any,
};
