import { MonitoringMetricTogglerType } from './constants';
import { AdaptedMetricType } from './monitoringTypes';

export const getFilteredMonitoringMetrics = (
  dbMetrics: AdaptedMetricType[],
  metricOption: MonitoringMetricTogglerType,
  searchText: Array<string>,
) => {
  const metricList =
    metricOption === ('ALL' as MonitoringMetricTogglerType)
      ? dbMetrics
      : dbMetrics?.filter((metric) => metric?.entityType === metricOption);

  if (searchText?.length === 0) return metricList;

  return metricList?.filter((metric) => {
    const { title, dataSets, unit } = metric;
    const legendNames = dataSets?.map((dataset) =>
      dataset?.displayName?.toLowerCase(),
    );
    const additionalMetricInfos = dataSets?.map((dataset) =>
      dataset?.additionalMetricInfo?.toLowerCase(),
    );
    const searchList = [
      title?.toLowerCase(),
      ...legendNames,
      ...additionalMetricInfos,
      unit?.toLowerCase(),
    ];

    const filterSearch = searchList?.find((searchValue: string) =>
      searchText?.find((searchtxt: string) =>
        searchValue?.includes(searchtxt?.toLowerCase()),
      ),
    );
    return !!filterSearch || false;
  });
};
