import { Navigate } from 'react-router-dom';

//login
import { ForgetPassword } from 'authentication/authentication-outer/ForgetPassword';

import { AppUnderConstruction } from 'common/error-screens/AppUnderConstruction';
import TenantsListView from 'customers/tenants-list-view/lib/components/TenantListView/TenantsListView';
import { TenantDetailedView } from 'customers/tenants-detailed-view/lib/components/TenantDetails/TenantDetailedView';
import Login from 'authentication/authentication-outer/Login';
import ServiceListView from 'customers/db-services-list-view/lib/components/ServiceListView';
import ServiceDetailedView from 'customers/db-services-detailed-view/components/ServiceDetailedView';
import { MultiFactorAuthentication } from 'authentication/authentication-outer/MultiFactorAuthentication';
import { ResetPassword } from 'authentication/authentication-outer/ResetPassword';
import { MfaSetup } from 'authentication/authentication-outer/MfaSetup';
import AMDetailedView from 'customers/availability-machine-view/components/DetailsHomepage/AMDetailedView';
import AuditLogs from 'global-components/AuditLogs/AuditLogs';
import { PortalAdministration } from 'Administration/PortalAdministration';
import { Dashboard } from 'global-components/Dashboard';
import { VNDetailedView } from 'customers/virtual-network/vn-detailed-view/VNDetailedView';
import { IAM } from 'Administration/IAM/IAM';
import OnboardTenant from 'customers/tenants-detailed-view/lib/components/onboarding/TenantOnboarding';
import Ticket from 'customers/ticket-detailed-view/TicketDetails';
import CloudInfrastructure from 'Infrastructure/CloudInfrastructure/CloudInfrastructure';
import DBServersDetailedView from 'customers/db-servers-detailed-view/DBServersDetailedView';
import { CostDashboard } from 'customers/cost/CostDashboard';
import { Tickets } from 'customers/tickets/Tickets';

const authProtectedRoutes = [
  { path: '/', component: <Navigate to="/dashboard" /> },

  { path: '/dashboard', component: <Dashboard /> },

  // Customers
  { path: '/customers', component: <Navigate to="/tenants" /> },

  // tenants
  { path: '/tenants', component: <TenantsListView /> },
  {
    path: '/tenants/onboarding',
    component: <OnboardTenant />,
  },
  {
    path: '/tenants/details/:tenantId/*',
    component: <TenantDetailedView />,
  },
  {
    path: '/tenants/details',
    exact: true,
    component: <Navigate to="/tenants" />,
  },

  {
    path: '/tenants/:tenantId/database-servers/:dbServerId/:tabId',
    component: <DBServersDetailedView />,
  },

  // Database Services
  { path: '/database-services', component: <ServiceListView /> },
  {
    path: '/database-services/details/:databaseServiceId/:tabId',
    component: <ServiceDetailedView />,
  },

  //Availability Machine
  {
    path: '/database-services/details/:databaseServiceId/availability-machine/:avMachineId/:tabId',
    component: <AMDetailedView />,
  },

  // VPC Peerings
  {
    path: '/tenants/details/:tenantId/virtual-network/:vnId/:tabId',
    component: <VNDetailedView />,
  },

  // Tickets
  { path: '/tickets', component: <Tickets /> },
  { path: '/ticket/:ticketId', component: <Ticket /> },

  { path: '/billing', component: <CostDashboard /> },

  // Infrastructure
  { path: '/cloud-accounts', component: <AppUnderConstruction /> },
  { path: '/cloud-infrastructure/*', component: <CloudInfrastructure /> },
  { path: '/platform-services', component: <AppUnderConstruction /> },

  // Audit Logs
  {
    path: '/audit-logs',
    exact: true,
    component: <AuditLogs />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: <Navigate to="/tenants" />,
  },

  // Administration routes

  { path: '/portal-administration/*', component: <PortalAdministration /> },
  { path: '/iam/*', component: <IAM /> },
  { path: '/sre-administration/*', component: <AppUnderConstruction /> },

  // { path: '*', component: <Navigate to="/lol" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: '/login', component: <Login /> },
  {
    path: '/multi-factor-authentication',
    component: <MultiFactorAuthentication />,
  },
  {
    path: '/mfa-setup',
    component: <MfaSetup />,
  },
  {
    path: '/forget-password',
    component: <ForgetPassword />,
  },
  {
    path: '/reset-password',
    component: <ResetPassword />,
  },
];

export { authProtectedRoutes, publicRoutes };
