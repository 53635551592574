/* eslint-disable no-nested-ternary */
import { Stack } from '@mui/material';
import {
  dailyRepeatsDetails,
  deletionScheduleDetails,
  doesNotRepeatDetails,
  weeklyRepeatDetails,
} from './HelperComponents';

export default function ScheduleDetails(props) {
  const { schedule, type, status } = props;
  let recurrence;

  switch (schedule?.recurrence) {
    case 'doesNotRepeat':
      recurrence = 'Does not repeat';
      break;
    case 'daily':
      recurrence = 'Daily';
      break;
    case 'weekly':
      recurrence = 'Weekly';
      break;
    default:
      recurrence = 'Does not repeat';
  }

  return (
    <Stack
      sx={{
        backgroundColor: '#F7F7F7',
        borderRadius: '8px',
      }}
      p={2}
    >
      {type === 'startStopSchedule' ? (
        schedule?.recurrence === 'doesNotRepeat' ? (
          doesNotRepeatDetails(schedule, status)
        ) : schedule?.recurrence === 'daily' ? (
          dailyRepeatsDetails(schedule, recurrence, status)
        ) : schedule?.recurrence === 'weekly' ? (
          weeklyRepeatDetails(schedule, recurrence, status)
        ) : (
          <></>
        )
      ) : (
        deletionScheduleDetails(schedule)
      )}
    </Stack>
  );
}
