import { Stack, Typography } from '@mui/material';
import _round from 'lodash/round';
import { colorsList } from './Constants';
import { legendStyles } from './styles';

interface Props {
  series: any;
  labels: {
    keyLabel: string;
    valueLabel: string;
  };
}

const VerticalLegend = (props: Props) => {
  const { series, labels } = props;

  const LegendRow = (props: any) => {
    const { item, idx, isLast } = props;
    return (
      <Stack
        sx={legendStyles.legendRow.mainContainer}
        borderBottom={isLast ? 'none' : '2px solid #F0F0F0'}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing="8px"
        >
          <Stack
            sx={{
              backgroundColor: colorsList[idx],
              ...legendStyles.legendRow.rowColorBox,
            }}
          />
          <Typography
            sx={legendStyles.legendRow.textStyleName}
            title={item?.name > 15 ? item?.name : ''}
          >
            {item?.name}
          </Typography>
        </Stack>
        <Typography sx={legendStyles.legendRow.textStyleValue}>
          {item?.value}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack sx={legendStyles.legend.mainContainer}>
      <Stack sx={legendStyles.legend.headerContainer}>
        <Typography sx={legendStyles.legend.keyLabel}>
          {labels?.keyLabel?.toUpperCase()}
        </Typography>
        <Typography sx={legendStyles.legend.valueLabel}>
          {labels?.valueLabel?.toUpperCase()}
        </Typography>
      </Stack>
      <Stack sx={legendStyles.legend.rowContainer}>
        {series?.map((item, idx) => (
          <LegendRow
            item={item}
            idx={idx}
            key={item?.name}
            isLast={idx === series?.length - 1}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default VerticalLegend;
