export const DURATION_RANGE = [
  {
    label: '2 Hours',
    value: 2 * 60,
  },
  {
    label: '4 Hours',
    value: 4 * 60,
  },
  {
    label: '8 Hours',
    value: 8 * 60,
  },
  {
    label: '12 Hours',
    value: 12 * 60,
  },
  {
    label: '24 Hours',
    value: 24 * 60,
  },
  {
    label: '2 Days',
    value: 2 * 24 * 60,
  },
  {
    label: '4 Days',
    value: 4 * 24 * 60,
  },
  {
    label: '6 Days',
    value: 6 * 24 * 60,
  },
  {
    label: '7 Days',
    value: 7 * 24 * 60,
  },
];
