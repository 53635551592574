const getStatusString = (status) => {
  switch (status) {
    case 'AVAILABLE':
      return 'Available';
    case 'CREATING':
      return 'Creating';
    case 'FAILED':
      return 'Failed';
    case 'PREPARING':
      return 'Preparing';
    case 'REPLICATING':
      return 'Replicating';
    case 'COPYING':
      return 'Copying';
    case 'PENDING':
      return 'Pending';
    case 'ENABLED':
      return 'Enabled';
    default:
      return status;
  }
};

export default getStatusString;
