import { Box, Button, Stack, Typography } from '@mui/material';
import { useStyles } from 'customers/db-services-detailed-view/utils';
import { Divider } from '@aws-amplify/ui-react';
import { FileCopyOutlined } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ShowAttr } from 'customers/db-services-detailed-view/components/ServiceOverview';
import { DEFAULT_NA } from 'constants/appConstants';

type ComputeDetailsProps = {
  row?: any;
};

export const ComputeDetails = (props: ComputeDetailsProps) => {
  const { row } = props;
  const { id, cloudResourceId, dbserverSystemId, deploymentId } = row;
  const classes = useStyles();

  const computeFields = [
    {
      label: 'Compute Resource Id',
      value: id,
    },
    {
      label: 'Cloud Resource Id',
      value: cloudResourceId,
    },
    {
      label: 'DB Server System Id',
      value: dbserverSystemId,
    },
    {
      label: 'Deployment Id',
      value: deploymentId,
    },
  ];

  return (
    <Box sx={classes.instanceNwInfoBox} p={2} mr={0}>
      <Typography sx={classes.headingText}>Details</Typography>
      {
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column" sx={classes.dbInfo}>
            {computeFields?.map((_property, index) => {
              return (
                <Stack>
                  {ShowAttr(
                    _property?.label,
                    _property?.value || DEFAULT_NA,
                    null,
                    null,
                    null,
                    <CopyToClipboard text={_property.value}>
                      <Button
                        color="primary"
                        startIcon={<FileCopyOutlined />}
                      />
                    </CopyToClipboard>,
                    'compact',
                  )}
                  {index !== computeFields.length - 1 ? <Divider /> : <></>}
                </Stack>
              );
            })}
          </Box>
        </Box>
      }
    </Box>
  );
};
