import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';

export const ResizeDrawerHeader = styled(Stack, {
  name: 'ResizeDrawerHeader',
})(() => ({
  height: '60px',
  width: '100%',
  padding: '16px 24px 16px 24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#F8F8F8',
  borderBottom: '1px solid #F0F0F0',
}));

export const ResizeDrawerFooter = styled(Stack, {
  name: 'ResizeDrawerFooter',
})(() => ({
  width: '100%',
  padding: '16px 24px 16px 24px',
  display: 'flex',
  borderTop: '1px solid #F0F0F0',
  position: 'absolute',
  bottom: '0',
}));

export const ResizeDrawerCostFooter = styled(Stack, {
  name: 'ResizeDrawerCostFooter',
})(() => ({
  height: '76px',
  width: '100%',
  padding: '12px 24px 12px 24px',
  borderTop: '1px solid #F0F0F0',
}));

export const ResizeDrawerBody = styled(Stack, {
  name: 'ResizeDrawerBody',
})(() => ({
  height: '100%',
  width: '100%',
  padding: '20px 24px 20px 24px',
}));
