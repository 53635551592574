import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Link, List, ListItem, Popover, Stack } from '@mui/material';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { SubscriptionStatusIcon } from 'common/custom-components/lib/components/SubscriptioStatusIcon';
import { CloudAccountDetailsModal } from 'common/Modals/CloudAccountDetailsModal/CloudAccountDetailsModal';
import { useSubscriptions } from './useSubscriptions';
import { subscriptionTableStyles } from './styles';

interface SubscriptionsTableProps {
  tenant: TenantType;
}

export const SubscriptionsTable = (props: SubscriptionsTableProps) => {
  const { tenant } = props;

  const {
    page,
    open,
    setOpen,
    tenantSubscriptions,
    subscriptionsIsLoading,
    subscriptionsCountIsLoading,
    subscriptionsCount,
    subscriptionsPageCount,
    cloudAccountID,
    setCloudAccountID,
    detailsAnchorEl,
    popoverData,
    showDetails,
    handleRowClick,
    handlePaginationChange,
  } = useSubscriptions({ tenantId: tenant?._id });

  const tenantSubscriptionsColumns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 120,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 60,
      renderCell: (params) => {
        const { row } = params;
        const { status } = row;
        return <SubscriptionStatusIcon status={status} />;
      },
    },
    {
      field: 'deploymentType',
      headerName: 'Subscription Type',
      minWidth: 60,
    },
    {
      field: 'users',
      headerName: 'Members',
      minWidth: 120,
    },
    {
      field: 'awsRegions',
      headerName: 'AWS',
      renderCell: (params) => {
        const { row } = params;
        return (
          <Stack direction="column">
            <Link
              underline="hover"
              onClick={(e) => showDetails(e, row.awsRegions)}
              sx={{ justifyContent: 'flex-start' }}
              variant="body2"
            >
              {row.awsRegionsLength}
            </Link>
            {row?.awsCloudAccountId && (
              <Stack width="50%">
                <Link
                  underline="hover"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setCloudAccountID(`${row.awsCloudAccountId}`);
                    setOpen(true);
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                  variant="body2"
                >
                  Cloud Account Details
                </Link>
              </Stack>
            )}
          </Stack>
        );
      },
      minWidth: 120,
    },
    {
      field: 'azureRegions',
      headerName: 'Azure',
      minWidth: 120,
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Link
              underline="hover"
              onClick={(e) => showDetails(e, row.azureRegions)}
              sx={{ justifyContent: 'flex-start' }}
              variant="body2"
            >
              {row.azureRegionsLength}
            </Link>

            {row?.azureCloudAccountId && (
              <Stack width="50%">
                <Link
                  underline="hover"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setCloudAccountID(`${row.azureCloudAccountId}`);
                    setOpen(true);
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                  variant="body2"
                >
                  Cloud Account Details
                </Link>
              </Stack>
            )}
          </>
        );
      },
    },
  ];

  const getDetails = () => (
    <List>
      {popoverData.map((r) => (
        <ListItem>{r}</ListItem>
      ))}
    </List>
  );

  return (
    <>
      <Stack sx={subscriptionTableStyles.container}>
        <DataGrid
          columns={tenantSubscriptionsColumns}
          rows={tenantSubscriptions}
          disableSelectionOnClick
          components={{
            Toolbar: CustomDataGridToolbar,
            DetailPanelCollapseIcon: ExpandMore,
            DetailPanelExpandIcon: ChevronRight,
          }}
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          styleOverrides={{ hideShadow: true }}
          loading={subscriptionsIsLoading || subscriptionsCountIsLoading}
          onRowClick={handleRowClick}
          pageNo={page}
          totalCount={subscriptionsCount || 0}
          handlePaginationChange={handlePaginationChange}
          pageCount={subscriptionsPageCount}
        />
        <Popover
          open={!!detailsAnchorEl}
          anchorEl={detailsAnchorEl}
          onClose={() => showDetails()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {getDetails()}
        </Popover>
        <CloudAccountDetailsModal
          open={open}
          setOpen={setOpen}
          title="Cloud Account Details"
          cloudAccountID={cloudAccountID}
        />
      </Stack>
    </>
  );
};
