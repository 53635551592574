import { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'common/withRouter';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';

const Layout = (props) => {
  const [headerClass, setHeaderClass] = useState('');
  const { isPermissionsLoading } = useContext(UserPermissionsContext);

  // class add remove in header
  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass('topbar-shadow');
    } else {
      setHeaderClass('');
    }
  }

  return (
    <div id="layout-wrapper">
      <Header headerClass={headerClass} />
      <Sidebar />
      {!isPermissionsLoading ? (
        <div className="main-content">{props.children}</div>
      ) : (
        ''
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default withRouter(Layout);
