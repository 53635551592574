import clsx from 'clsx';
import { IconPropType, useStyles } from './utils';

export default function ServerAppIcon(props: IconPropType) {
  const { className = '' } = props;
  const iconStyle = useStyles(props);
  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_610_1024)">
        <path
          d="M17.8571 1.42859H2.1428C1.76392 1.42859 1.40056 1.5791 1.13265 1.84701C0.864743 2.11492 0.714233 2.47828 0.714233 2.85716V8.57145C0.714233 8.95033 0.864743 9.31369 1.13265 9.5816C1.40056 9.84951 1.76392 10 2.1428 10H17.8571C18.236 10 18.5993 9.84951 18.8672 9.5816C19.1352 9.31369 19.2857 8.95033 19.2857 8.57145V2.85716C19.2857 2.47828 19.1352 2.11492 18.8672 1.84701C18.5993 1.5791 18.236 1.42859 17.8571 1.42859Z"
          stroke="var(--icon-color)"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.99993 6.42857C5.18937 6.42857 5.37105 6.35332 5.50501 6.21936C5.63896 6.08541 5.71422 5.90373 5.71422 5.71429C5.71422 5.52485 5.63896 5.34316 5.50501 5.20921C5.37105 5.07526 5.18937 5 4.99993 5C4.81049 5 4.62881 5.07526 4.49485 5.20921C4.3609 5.34316 4.28564 5.52485 4.28564 5.71429C4.28564 5.90373 4.3609 6.08541 4.49485 6.21936C4.62881 6.35332 4.81049 6.42857 4.99993 6.42857Z"
          stroke="var(--icon-color)"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7142 5.71436H15.7142"
          stroke="var(--icon-color)"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.1428 10C1.76392 10 1.40056 10.1505 1.13265 10.4184C0.864743 10.6863 0.714233 11.0497 0.714233 11.4286V17.1429C0.714233 17.5217 0.864743 17.8851 1.13265 18.153C1.40056 18.4209 1.76392 18.5714 2.1428 18.5714H17.8571C18.236 18.5714 18.5993 18.4209 18.8672 18.153C19.1352 17.8851 19.2857 17.5217 19.2857 17.1429V11.4286C19.2857 11.0497 19.1352 10.6863 18.8672 10.4184C18.5993 10.1505 18.236 10 17.8571 10"
          stroke="var(--icon-color)"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.99993 15C5.18937 15 5.37105 14.9247 5.50501 14.7908C5.63896 14.6568 5.71422 14.4751 5.71422 14.2857C5.71422 14.0963 5.63896 13.9146 5.50501 13.7806C5.37105 13.6467 5.18937 13.5714 4.99993 13.5714C4.81049 13.5714 4.62881 13.6467 4.49485 13.7806C4.3609 13.9146 4.28564 14.0963 4.28564 14.2857C4.28564 14.4751 4.3609 14.6568 4.49485 14.7908C4.62881 14.9247 4.81049 15 4.99993 15Z"
          stroke="var(--icon-color)"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7142 14.2858H15.7142"
          stroke="var(--icon-color)"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_610_1024">
          <rect width="20" height="20" fill="var(--icon-color)" />
        </clipPath>
      </defs>
    </svg>
  );
}
