import {
  TimelinePlayIcon,
  TimelineStopIcon,
  SwitchOverIcon,
  RestoreServiceIcon,
  CloningIcon,
  DeletedServiceIcon,
  EditServiceIcon,
  AccessIcon,
  ResizeFeatureIcon,
  DotIcon,
} from '../../../assets/icons-v2/index';

enum colors {
  lightBlue = '#1E7EA1',
  lightBlue2 = '#29B5E8',
  red = '#EC373C',
}

export const timelineEventTypesInfo = {
  CreateService: {
    value: 'Tessell Service - Create',
  },
  CloneService: {
    value: 'Tessell Service - Clone',
    Icon: <CloningIcon width={16} height={16} color={colors.lightBlue} />,
  },
  RestoreService: {
    value: 'Tessell Service - Restore',
    Icon: <RestoreServiceIcon width="18" height="18" darkBg={false} />,
  },
  DeleteService: {
    value: 'Tessell Service - Delete',
    Icon: <DeletedServiceIcon />,
  },
  UpdateService: {
    value: 'Tessell Service - Update',
    Icon: <EditServiceIcon />,
  },
  UpdateConnectivity: {
    value: 'Tessell Service - Update Connectivity',
    Icon: <EditServiceIcon />,
  },
  UpdateCredentials: {
    value: 'Tessell Service - Update Credentials',
    Icon: <EditServiceIcon />,
  },
  UpdateIntegration: {
    value: 'Tessell Service - Update Integration',
    Icon: <EditServiceIcon />,
  },
  StartService: {
    value: 'Tessell Service - Start',
    Icon: <TimelinePlayIcon />,
  },
  StopService: {
    value: 'Tessell Service - Stop',
    Icon: <TimelineStopIcon />,
  },
  ShareAccess: {
    value: 'Tessell Service - Share Access',
    Icon: <AccessIcon color={colors.lightBlue} />,
  },
  RevokeAccess: {
    value: 'Tessell Service - Revoke Access',
    Icon: <AccessIcon color={colors.red} />,
  },
  Switchover: {
    value: 'Tessell Service - Switchover',
    Icon: <SwitchOverIcon darkBg={false} zoom="0.65" />,
  },
  ResizeInstance: {
    value: 'Tessell Service - Resize Instance',
    Icon: <ResizeFeatureIcon color={colors.lightBlue} />,
  },
  AddInstance: {
    value: 'Tessell Service - Add Instance',
    Icon: <DotIcon color={colors.lightBlue2} width={8} height={8} />,
  },
  DeleteInstance: {
    value: 'Tessell Service - Delete Instance',
    Icon: <DeletedServiceIcon />,
  },
};
