export const getResponsiveGridProps = (
  monitoringTabWidth: number,
  totalChartsCount: number,
) => {
  let columnCount = 1;
  const responsiveProps = {
    rowCount: 0,
    columnCount: 0,
    columnWidth: 0,
  };
  if (monitoringTabWidth > 2400) {
    columnCount = 5;
  } else if (monitoringTabWidth > 2000) {
    columnCount = 4;
  } else if (monitoringTabWidth > 1200) {
    columnCount = 3;
  } else if (monitoringTabWidth > 900) {
    columnCount = 2;
  }
  responsiveProps.columnCount = columnCount;
  responsiveProps.rowCount = Math.ceil((totalChartsCount || 0) / columnCount);
  responsiveProps.columnWidth = monitoringTabWidth / columnCount;
  return responsiveProps;
};
