import { Stack } from '@mui/material';
import { DatabaseServiceType } from '../../tenants-common-view/lib/schema/DatabaseService.types';
import { ServiceHeaderInfo } from './ServiceHeaderInfo';

interface ServiceHeaderProps {
  service: DatabaseServiceType;
  serviceIsLoading: boolean;
  fetchDBServiceId: Function;
}

export const ServiceHeader = (props: ServiceHeaderProps) => {
  const { service, fetchDBServiceId, serviceIsLoading } = props;
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
    >
      <ServiceHeaderInfo
        service={service}
        serviceIsLoading={serviceIsLoading}
        fetchDBServiceId={fetchDBServiceId}
      />
    </Stack>
  );
};
