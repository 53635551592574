import { useContext, useEffect, useMemo, useState } from 'react';
import { useFetchMetadataUpdateList } from 'tools/useFetchUpdateMetadataList';
import { URLS, USERS_BASE_URL } from 'constants/URL';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import {
  useLazyGetCall,
  useLazyPostCall,
  useLazyPutCall,
} from 'common/api/useApiCall';
import {
  DEFAULT_PAYLOAD,
  UPDATE_METADATA_ENTITY_TYPE,
  UpdateMetaDataLabels,
} from './constants';
import { TenantContext } from 'common/TenantContainer/TenantContainer';
import { evaluateTenantName } from 'common/TenantContainer/evaluateTenantName';
import { replaceEmptyStringWithNull } from './utils';
import { OTPRequestType } from 'constants/URLEnums';
import { adaptUsersResponse } from './adapters';

interface UpdateMetadataType {
  open: boolean;
  setOpen: Function;
  entityType: string;
  entityName: string;
  tenantId: string;
  tenantDomain: string;
  entityId: string;
  parentEntityId?: string;
  handleSuccess?: any;
}

const parseJSON = (data: any): any => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

export default function useUpdateMetadata(props: UpdateMetadataType) {
  const {
    setOpen,
    entityType = 'dbServices',
    entityName,
    tenantId,
    entityId,
    tenantDomain,
    parentEntityId = '',
    handleSuccess,
  } = props;

  const urls = {
    dbServices: `${URLS.updateDbServicesMetadata}tenant-id=${tenantId}&tenant-domain=${tenantDomain}&dbservice-id=${entityId}`,
    databases: `${URLS.updateDatabaseMetadata}tenant-domain=${tenantDomain}&tenant-id=${tenantId}&dbservice-id=${parentEntityId}&id=${entityId}`,
    backups: `${URLS.updateBackupsMetadata}tenant-domain=${tenantDomain}&tenant-id=${tenantId}&dmm-id=${parentEntityId}&id=${entityId}`,
    instance: `${URLS.updateInstancesMetadata}tenant-domain=${tenantDomain}&tenant-id=${tenantId}&dbservice-id=${parentEntityId}&instance-id=${entityId}`,
    computeResource: `${URLS.updateComputeResourcesMetadata}tenant-domain=${tenantDomain}&tenant-id=${tenantId}&id=${entityId}`,
    vpc: `${URLS.fetchVPCDetailsURL}tenant-domain=${tenantDomain}&tenant-id=${tenantId}&vpcId=${entityId}`,
    subnet: `${URLS.updateSubnetsMetadata}tenant-domain=${tenantDomain}&tenant-id=${tenantId}&subnet-id=${entityId}`,
    tenant: `${URLS.updateTenantMetadata}tenant-id=${tenantId}&tenant-domain=${tenantDomain}`,
    tenantGenieConfig: `${URLS.updateTenantGenieConfig}tenant-id=${tenantId}&tenant-domain=${tenantDomain}`,
  };

  const optionsURLandAdapters = {
    tenant: {
      url: USERS_BASE_URL,
      adapter: adaptUsersResponse,
      for: UpdateMetaDataLabels.tenantPocOwnerEmails,
    },
  };

  const { response: optionsResponse, postData: fetchOptions } = useLazyGetCall(
    optionsURLandAdapters[entityType]?.url || '',
    optionsURLandAdapters[entityType]?.adapter,
  );

  const options = useMemo(() => {
    return { [optionsURLandAdapters[entityType]?.for]: optionsResponse || [] };
  }, [optionsResponse]);

  const [payload, setPayLoad] = useState(DEFAULT_PAYLOAD);
  const [step, setStep] = useState(0);
  const [uuid, setUuid] = useState('');
  const [openOTP, setOpenOTP] = useState(false);

  const { addNotification } = useContext(NotificationContext);
  const { tenant, setCurrentTenantId } = useContext(TenantContext);

  useEffect(() => {
    if (tenantId) setCurrentTenantId(tenantId);
  }, [tenantId]);

  const { response } = useFetchMetadataUpdateList();
  const {
    response: fetchMetadataRes,
    isLoading: fetchIsLoading,
    postData: fetchMetadata,
  } = useLazyGetCall(urls[entityType]);

  const {
    response: sendOTPRes,
    isLoading: sendOTPIsLoading,
    postData: sendOTP,
  } = useLazyPostCall(
    `${URLS.otp}purpose=${OTPRequestType.METADATA_UPDATE_OTP}`,
  );

  const {
    response: bulkResolveAlertResponse,
    postData: bulkResolveAlert,
    responseStatus: bulkResolveAlertResponseStatus,
  } = useLazyPostCall(URLS.bulkResolveAlertsURL);

  useEffect(() => {
    if (entityId) {
      fetchMetadata();
      if (optionsURLandAdapters[entityType]?.url) {
        fetchOptions();
      }
    }
  }, [entityId]);

  const {
    response: updateMetadataRes,
    isLoading: metadataUpdateIsLoading,
    postData: updateMetadata,
    responseStatus: updateMetadataResponseStatus,
  } = useLazyPutCall(urls[entityType]);
  const [data, setData] = useState({} as any);

  useEffect(() => {
    if (bulkResolveAlertResponse?.message?.length) {
      addNotification({
        severity: bulkResolveAlertResponseStatus !== 200 ? 'error' : 'success',
        message: bulkResolveAlertResponse?.message,
      });
    }
  }, [bulkResolveAlertResponse]);

  useEffect(() => {
    if (fetchMetadataRes?.message?.length) {
      addNotification({
        severity: 'error',
        message: fetchMetadataRes?.message,
      });
    } else {
      setData(fetchMetadataRes);
    }
  }, [fetchMetadataRes]);

  const getMetadataUpdateSuccessMessage = (updateMetadataRes) => {
    if (entityType === 'tenant') {
      return updateMetadataRes?.domain
        ? `Metadata for ${updateMetadataRes?.domain} updated.`
        : 'Metadata updated.';
    }

    if (entityType === UPDATE_METADATA_ENTITY_TYPE.TENANT_GENIE_CONFIG) {
      return `Genie config updated successfully.`;
    }
    return (
      updateMetadataRes?.message ||
      (updateMetadataRes?.name
        ? `Metadata for ${updateMetadataRes?.name} updated.`
        : 'Metadata updated.')
    );
  };

  useEffect(() => {
    setPayLoad(DEFAULT_PAYLOAD);
    if (
      updateMetadataResponseStatus === 200 ||
      updateMetadataRes?.id ||
      updateMetadataRes?.name?.length
    ) {
      addNotification({
        severity: 'success',
        message: getMetadataUpdateSuccessMessage(updateMetadataRes),
      });
      handleSuccess?.();
      setOpen(false);
      setOpenOTP(false);
      setStep(0);
    } else if (updateMetadataRes) {
      addNotification({
        severity: 'error',
        message: updateMetadataRes?.message || `Error in updating metadata.`,
      });
      setOpen(false);
      setOpenOTP(false);
      setStep(0);
    }
  }, [updateMetadataRes, updateMetadataResponseStatus]);

  useEffect(() => {
    if (sendOTPRes?.uuid) {
      // Add notification and error messages handling
      setUuid(sendOTPRes?.uuid);
      setStep(2);
    }
  }, [sendOTPRes]);

  const sendOTPCall = ({ reason, jiraId }) => {
    const payload = {
      entity: entityType,
      'entity-id': entityId,
      'entity-name': encodeURIComponent(entityName),
      'tenant-id': tenantId,
      'tenant-domain': tenantDomain,
      comment: reason,
      jiraId: jiraId,
      'tenant-name': evaluateTenantName({ tenant: tenant }),
    };
    sendOTP(JSON.stringify(payload, null));
  };
  const handleUpdateClick = () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      setOpenOTP(true);
    }
  };
  const onClose = () => {
    setOpen(false);
    setPayLoad(DEFAULT_PAYLOAD);
    setStep(0);
  };
  const handleFetchLastest = () => {
    fetchMetadata();
    setPayLoad(DEFAULT_PAYLOAD);
  };

  const updatePOCtoLive = () => {
    bulkResolveAlert(
      JSON.stringify({
        comment: 'POC to Live',
        filter: { 'tenant-id': tenantId },
      }),
    );
  };

  const modalSubmitHandler = (val) => {
    if (step === 1) {
      sendOTPCall(val);
    } else if (step === 2) {
      const finalPayload = {
        oldData: payload.oldData,
        newData: {},
        otp: val,
        'request-id': uuid,
      };
      Object.keys(payload.newData).forEach(
        (k) => (finalPayload.newData[k] = parseJSON(payload.newData[k])),
      );
      replaceEmptyStringWithNull(finalPayload?.newData);

      // resolve all alerts in case of POC -> Live tenant phase update
      if (
        (finalPayload?.newData as any)?.tenantPhase?.toLowerCase() === 'live' &&
        (finalPayload?.oldData as any)?.tenantPhase?.toLowerCase() === 'poc'
      ) {
        updatePOCtoLive();
      }
      updateMetadata(JSON.stringify(finalPayload));
    }
  };

  return {
    response,
    payload,
    openOTP,
    data,
    fetchIsLoading,
    sendOTPIsLoading,
    metadataUpdateIsLoading,
    step,
    onClose,
    setOpenOTP,
    setPayLoad,
    setStep,
    modalSubmitHandler,
    handleUpdateClick,
    handleFetchLastest,
    options,
  };
}
