import { Link, Stack, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import { Link as ReactLink } from 'react-router-dom';

export interface ShowLabelValueProps {
  label: string;
  labelColor?: string;
  value: any;
  linkName?: string;
  newTab?: Boolean;
  navigateTo?: string;
}
export const ShowLabelValue = (props: ShowLabelValueProps) => {
  const { label, labelColor, value, linkName, newTab, navigateTo } = props;

  return (
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <div className="d-flex fs-14">
        <Typography
          fontWeight="600"
          color={labelColor ? labelColor : styles.color.black}
        >{`${label}:`}</Typography>
      </div>
      <div className="d-flex fs-14">
        {navigateTo ? (
          <ReactLink
            to={navigateTo}
            className="d-flex align-items-center justify-content-end text-decoration-underline"
          >
            {value}
          </ReactLink>
        ) : typeof value === 'string' && value?.includes('http') ? (
          <Stack sx={{ textDecoration: 'underline' }}>
            <Link
              href={value}
              underline="always"
              target={newTab ? '_blank' : '_self'}
            >
              {linkName || value}
            </Link>
          </Stack>
        ) : (
          <>&nbsp;&nbsp;{value}</>
        )}
      </div>
    </li>
  );
};
