/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
} from '@mui/material';
// import Close from '@mui/icons-material/Close';

export interface ModalRenderFunctionsType {
  title?: () => JSX.Element;
  content?: () => JSX.Element;
  actions?: () => JSX.Element;
}
export interface TessellModalProps extends DialogProps {
  customStyles?: any;
  renderFunctions: ModalRenderFunctionsType;
}
const TessellModal = (props: TessellModalProps) => {
  const {
    customStyles = {},
    renderFunctions,
    maxWidth,
    open,
    onClose,
    ...restProps
  } = props;

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth={maxWidth}
          {...restProps}
        >
          <DialogTitle sx={customStyles?.title}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {renderFunctions.title?.()}
            </Stack>
          </DialogTitle>
          {renderFunctions.content ? (
            <DialogContent sx={customStyles?.content}>
              {renderFunctions.content()}
            </DialogContent>
          ) : (
            ''
          )}
          {renderFunctions.actions ? (
            <DialogActions sx={customStyles?.actions}>
              {renderFunctions.actions()}
            </DialogActions>
          ) : (
            ''
          )}
        </Dialog>
      )}
    </>
  );
};

export default TessellModal;
