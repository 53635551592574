/* eslint-disable react/react-in-jsx-scope */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function SQLServerIconSmall(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54288 4.144C7.72638 2.9195 7.54988 1.283 7.51138 0.6105C5.43738 1.059 3.37688 1.8635 3.49588 2.456C3.51088 2.5305 3.60488 2.62 3.76088 2.707L3.51688 3.1435C3.21888 2.9765 3.05138 2.784 3.00588 2.554C2.87138 1.8835 3.63088 1.277 5.32688 0.6995C6.48488 0.305 7.65288 0.0694999 7.70238 0.0599999L8.00088 0V0.3055C8.00088 0.3265 8.01388 2.4495 8.95888 3.867L8.54288 4.144Z"
        fill="#CFD8DC"
      />
      <path
        d="M9.37637 20.0056H9.25087C5.15487 20.0056 0.596375 18.7191 0.0578748 16.5661C-0.270625 15.2511 0.803875 13.7981 3.16488 12.3656L3.42487 12.7926C1.30037 14.0821 0.276875 15.3786 0.543375 16.4451C1.01087 18.3141 5.33087 19.4791 9.11987 19.5051C9.57038 18.8316 11.9909 14.8751 10.6094 9.56863L11.0929 9.44263C12.6674 15.4886 9.48387 19.8611 9.45187 19.9046L9.37637 20.0056Z"
        fill="#CFD8DC"
      />
      <path
        d="M1.96633 18.1585C1.69683 18.1585 1.56333 18.129 1.54133 18.1235C1.45333 18.102 1.38433 18.0345 1.36033 17.9475C1.33583 17.8605 1.36083 17.767 1.42483 17.7035C1.46083 17.6675 5.02333 14.0995 5.50433 11.2145L5.99733 11.2965C5.58384 13.7785 3.13983 16.608 2.16933 17.65C3.13883 17.5945 5.58683 17.1405 10.2863 14.51C6.61883 14.108 6.03883 11.172 6.03283 11.1405L6.52434 11.05C6.54784 11.173 7.13733 14.0555 11.1463 14.0555C11.1478 14.0555 11.1488 14.0555 11.1503 14.0555C11.2638 14.0555 11.3623 14.1315 11.3913 14.2405C11.4213 14.3495 11.3733 14.465 11.2758 14.522C5.65833 17.7765 2.93433 18.158 1.96633 18.1585Z"
        fill="#CFD8DC"
      />
      <path
        d="M4.26308 19.3556C4.15958 19.3556 4.06558 19.2916 4.02908 19.1931C3.98958 19.0876 4.02558 18.9681 4.11758 18.9021C4.13458 18.8896 5.02408 18.2331 5.97058 16.7881C5.60658 16.6271 5.23808 16.4391 4.87258 16.2196C4.42858 15.9531 4.09308 15.6671 3.84258 15.3741C2.55758 15.7131 1.37158 15.8471 0.330078 15.7586L0.372078 15.2606C1.31008 15.3401 2.37658 15.2291 3.53258 14.9406C2.74608 13.5966 3.46808 12.2626 3.47808 12.2446L3.91508 12.4881C3.88158 12.5491 3.28258 13.6731 4.03958 14.8046C5.14008 14.4886 6.31408 14.0211 7.52908 13.4136C7.80858 12.4961 8.02708 11.4526 8.14158 10.2756C8.14958 10.1951 8.19558 10.1236 8.26558 10.0831C8.33558 10.0426 8.42108 10.0391 8.49458 10.0726C8.51058 10.0801 10.1266 10.8181 11.2966 11.2996C11.3816 11.3346 11.4406 11.4136 11.4501 11.5051C11.4601 11.5966 11.4186 11.6856 11.3421 11.7376C10.1976 12.5176 9.06058 13.1941 7.95308 13.7586C7.60208 14.8711 7.16758 15.7971 6.72358 16.5541C8.57458 17.2456 10.1811 17.2561 10.2016 17.2561C10.3156 17.2561 10.4151 17.3331 10.4436 17.4436C10.4721 17.5541 10.4226 17.6696 10.3231 17.7251C8.05308 18.9861 4.43958 19.3411 4.28708 19.3556C4.27908 19.3551 4.27108 19.3556 4.26308 19.3556ZM6.45558 16.9891C5.93558 17.7961 5.42458 18.3756 5.04258 18.7541C6.04158 18.6071 7.79308 18.2851 9.24658 17.6846C8.55058 17.5991 7.54308 17.4091 6.45558 16.9891ZM4.38458 15.2211C4.58108 15.4171 4.82608 15.6086 5.12958 15.7911C5.49758 16.0121 5.87108 16.1996 6.23958 16.3586C6.61658 15.7266 6.99008 14.9681 7.31058 14.0746C6.30008 14.5556 5.31908 14.9396 4.38458 15.2211ZM8.60208 10.6701C8.49958 11.5541 8.34158 12.3606 8.14558 13.0951C8.97558 12.6526 9.82258 12.1471 10.6766 11.5821C9.90208 11.2541 9.05058 10.8726 8.60208 10.6701Z"
        fill="#CFD8DC"
      />
      <path
        d="M5.9628 3.80567C5.9043 3.80567 5.8463 3.78467 5.8003 3.74567C4.9953 3.05667 4.0478 1.65467 4.0078 1.59517C3.9433 1.49967 3.9533 1.37217 4.0308 1.28717C4.1078 1.20167 4.2348 1.18167 4.3348 1.23617C4.3403 1.23767 4.8038 1.42867 7.9433 1.95167C8.0338 1.96667 8.1083 2.02967 8.1383 2.11567C8.1688 2.20167 8.1493 2.29767 8.0883 2.36567C7.2093 3.34217 6.0988 3.77217 6.0518 3.78967C6.0228 3.80017 5.9923 3.80567 5.9628 3.80567ZM4.8243 1.87867C5.1398 2.30317 5.5943 2.87667 6.0103 3.26317C6.2658 3.14667 6.8388 2.85417 7.3823 2.36417C6.0908 2.14367 5.3028 1.98667 4.8243 1.87867Z"
        fill="#CFD8DC"
      />
      <path
        d="M7.92224 10.7056C7.88824 10.7056 7.85374 10.6986 7.82174 10.6846C7.72224 10.6406 7.66224 10.5376 7.67374 10.4296C7.81974 9.05508 5.71074 8.50358 5.68924 8.49858C5.60224 8.47658 5.53324 8.40908 5.50974 8.32258C5.48624 8.23608 5.51074 8.14308 5.57424 8.07958C5.67774 7.97608 8.14374 5.53058 11.2377 4.18758C11.3242 4.15008 11.4222 4.16408 11.4952 4.22258C11.5677 4.28158 11.6012 4.37608 11.5822 4.46708C10.9892 7.33908 8.22724 10.4891 8.11024 10.6216C8.06074 10.6761 7.99174 10.7056 7.92224 10.7056ZM6.22824 8.14808C6.82024 8.37058 7.85724 8.88558 8.11974 9.82608C8.84424 8.92208 10.3907 6.83958 10.9682 4.85908C8.77474 5.91408 6.92774 7.50508 6.22824 8.14808Z"
        fill="#B71C1C"
      />
      <path
        d="M3.54023 12.6856L3.30273 12.2456C4.24423 11.7381 5.32273 10.8041 6.20623 9.71864C5.45423 10.2336 5.02373 10.5861 5.01023 10.5976L4.69073 10.2126C4.71023 10.1966 5.57273 9.48914 7.00623 8.60264C7.39973 7.96964 7.70223 7.31864 7.85773 6.69464C7.88423 6.58864 7.90723 6.48614 7.92573 6.38714C6.96323 6.04364 6.07523 5.84014 5.46523 5.75264C5.37273 5.73964 5.29573 5.67614 5.26473 5.58864C5.23373 5.50114 5.25423 5.40314 5.31673 5.33514C5.35923 5.28914 6.37473 4.20114 7.64373 3.60364C7.74223 3.55714 7.86023 3.58014 7.93423 3.66064C7.96773 3.69714 8.65423 4.46814 8.47973 6.06314C9.05723 6.28814 9.65223 6.56164 10.2252 6.88714C11.6047 6.26714 13.1712 5.70914 14.8397 5.37214C14.9257 5.35514 15.0212 5.38614 15.0802 5.45614C15.1387 5.52614 15.1547 5.62264 15.1217 5.70764C14.4717 7.37364 12.7287 9.15314 12.6547 9.22814C12.6042 9.27914 12.5352 9.30464 12.4632 9.30214C12.3917 9.29814 12.3257 9.26414 12.2807 9.20814C11.7207 8.50414 10.9887 7.92114 10.1992 7.44664C9.11173 7.94864 8.14873 8.48564 7.35723 8.97114C6.34723 10.5476 4.82273 11.9951 3.54023 12.6856ZM10.7467 7.20364C11.3917 7.61964 11.9922 8.10864 12.4887 8.67764C12.9027 8.22864 13.8962 7.09364 14.4597 5.96664C13.1347 6.27164 11.8807 6.71314 10.7467 7.20364ZM8.40023 6.56664C8.38373 6.64814 8.36473 6.73164 8.34373 6.81664C8.23873 7.23614 8.07173 7.66714 7.85773 8.09714C8.40573 7.78414 9.01223 7.46114 9.66673 7.14814C9.24773 6.92614 8.82023 6.73314 8.40023 6.56664ZM6.02473 5.34164C6.58023 5.45064 7.26473 5.62864 7.99523 5.88464C8.07123 4.96314 7.82223 4.39864 7.67173 4.14864C6.98373 4.50764 6.38123 5.01264 6.02473 5.34164Z"
        fill="#B71C1C"
      />
      <path
        d="M6.0259 7.7555C6.0259 9.9455 4.2009 11.6855 2.8859 12.9555C2.6659 13.1705 1.9209 13.2055 1.9209 13.2055C2.1059 13.0155 2.3159 12.8155 2.5409 12.6005C3.7909 11.3905 5.5259 9.7355 5.5259 7.7555C5.5259 5.4105 4.5809 4.4855 3.8359 3.7455C3.5059 3.4105 3.2259 3.0905 3.0559 2.7005L3.2109 2.6355C3.3809 2.7105 3.5759 2.7955 3.7259 2.8605C3.8459 3.0355 4.0059 3.2055 4.1909 3.3905C4.9559 4.1555 6.0259 5.2055 6.0259 7.7555Z"
        fill="#B71C1C"
      />
      <path
        d="M18.4681 8.40267C18.4681 8.40267 18.1656 8.44567 18.0806 8.45567C13.7006 8.94067 9.18059 10.2007 6.59559 11.2357C5.66059 11.6107 4.69059 12.0657 3.80559 12.5757C3.80059 12.5807 3.79559 12.5807 3.78559 12.5857C3.26559 12.8857 2.00059 13.5057 0.975586 14.0507C2.47559 12.4557 5.28559 11.2257 6.40559 10.7757C8.94059 9.76067 13.2956 8.53567 17.5806 8.01067C17.0756 7.42067 15.8406 6.17067 13.4106 5.24067C11.9906 4.69067 9.83059 4.38067 7.92559 4.10567C4.89559 3.67067 3.17059 3.38067 3.00559 2.55567C2.97059 2.39067 2.99559 2.22567 3.07059 2.06567C3.23559 2.33567 3.47059 2.52567 3.62559 2.63567C3.70059 2.68567 3.75559 2.71567 3.77559 2.72567L3.78059 2.73067C4.49059 3.10567 6.40559 3.38067 8.00059 3.61067C9.93059 3.89067 12.1156 4.20567 13.5906 4.77067C17.0256 6.09567 18.2106 7.99067 18.2606 8.07067C18.3056 8.14567 18.4681 8.40267 18.4681 8.40267Z"
        fill="#B71C1C"
      />
    </svg>
  );
}
