import clsx from 'clsx';
import { useStyles } from './utils';

export default function PITRIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4375_5)">
        <path
          d="M0.999023 7.99951C0.999023 9.38408 1.40959 10.7375 2.17882 11.8888C2.94804 13.04 4.04137 13.9373 5.32054 14.4671C6.59971 14.997 8.00727 15.1356 9.36524 14.8655C10.7232 14.5954 11.9706 13.9286 12.9496 12.9496C13.9286 11.9706 14.5954 10.7232 14.8655 9.36524C15.1356 8.00728 14.997 6.59971 14.4671 5.32054C13.9373 4.04137 13.04 2.94805 11.8888 2.17882C10.7375 1.4096 9.38407 0.999028 7.99951 0.999028C5.99411 0.996809 4.06517 1.76838 2.61452 3.15302"
          stroke="var(--icon-color)"
          stroke-width="1.077"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.15227 0.999023L2.61377 3.15302L4.76776 3.69152"
          stroke="var(--icon-color)"
          stroke-width="1.077"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.99951 4.23047V8.53846L11.0005 10"
          stroke="var(--icon-color)"
          stroke-width="1.077"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4375_5">
          <rect width="16" height="16" fill="" />
        </clipPath>
      </defs>
    </svg>
  );
}
