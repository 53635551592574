import { Stack, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';

const BetaLabel = () => {
  return (
    <Stack>
      <Typography
        px={1}
        pb={0.3}
        color={styles.color.baseBackground}
        sx={{
          backgroundColor: styles.color.header,
          borderRadius: '3px',
          border: '0.2px solid primary',
        }}
      >
        βeta
      </Typography>
    </Stack>
  );
};

export default BetaLabel;
