import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { useEffect } from 'react';
import { adaptComputeResponse } from './adaptComputeResponse';

type ComputeDetailsProps = {
  computeId: string;
};

export const useComputeDetails = (props: ComputeDetailsProps) => {
  const { computeId } = props;
  const {
    response: computeResponse,
    isLoading: computeIsLoading,
    postData: fetchCompute,
  } = useLazyGetCallWithURL(adaptComputeResponse);

  useEffect(() => {
    if (computeId) {
      fetchCompute(`${URLS.getComputeDetails}${computeId}`);
    }
  }, [computeId]);

  return {
    computeIsLoading,
    ...computeResponse,
  };
};
