import { DEFAULT_NULL } from 'constants/appConstants';

export const getMultiServiceStatus = (computeSharingEnabled: string) => {
  if (computeSharingEnabled === 'null') {
    return DEFAULT_NULL;
  } else {
    return computeSharingEnabled === 'true' ? 'Enabled' : 'Disabled';
  }
};
export const getMultiDiskStatus = (multiDisk) => {
  if (multiDisk === null) {
    return DEFAULT_NULL;
  } else {
    return multiDisk ? 'Enabled' : 'Disabled';
  }
};
