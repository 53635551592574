/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */

import { useColors } from './utils';

export default function OCIIcon(props) {
  const colors = useColors(props.monotone, props.dark, props.width);

  return (
    <svg
      width="600"
      height="205"
      viewBox="0 0 600 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // className={className}
    >
      <path
        d="M250.2 48.9H288.4L268.2 16.4L231.2 75.2H214.3L259.4 4.60001C261.3 1.70001 264.6 0 268.2 0C271.7 0 275 1.7 276.9 4.5L322.1 75.2H305.2L297.2 62.1H258.6L250.2 48.9Z"
        fill="#ED1D24"
      />
      <path
        d="M425.4 62V0.700012H411V68C411 69.8 411.8 71.6 413.1 72.9C414.5 74.3 416.3 75.1 418.3 75.1H483.5L492 62H425.4"
        fill="#ED1D24"
      />
      <path
        d="M188.7 51C202.6 51 213.8 39.7 213.8 25.8C213.8 11.9 202.6 0.600006 188.7 0.600006H126.1V75H140.4V13.7H187.7C194.4 13.7 199.7 19.1 199.7 25.7C199.7 32.3 194.3 37.8 187.7 37.8H147.4L190.1 75H210.9L182.2 50.9H188.7"
        fill="#ED1D24"
      />
      <path
        d="M38 75.1C17.5 75.1 0.799988 58.5 0.799988 37.9C0.799988 17.3 17.5 0.700012 38 0.700012H81.3C101.9 0.700012 118.4 17.4 118.4 37.9C118.4 58.5 101.8 75.1 81.3 75.1H38ZM80.3 62C93.6 62 104.4 51.2 104.4 37.9C104.4 24.6 93.6 13.8 80.3 13.8H39C25.7 13.8 14.9 24.5 14.9 37.9C14.9 51.2 25.7 62 39 62H80.3Z"
        fill="#ED1D24"
      />
      <path
        d="M352 75.1C331.4 75.1 314.7 58.5 314.7 37.9C314.7 17.3 331.4 0.700012 352 0.700012H403.4L394.9 13.8H352.9C339.5 13.8 328.8 24.5 328.8 37.9C328.8 51.2 339.6 62 352.9 62H404.4L396 75.1H352"
        fill="#ED1D24"
      />
      <path
        d="M527 62C516 62 506.7 54.6 503.8 44.5H565L573.4 31.4H503.8C506.7 21.2 516 13.9 527 13.9H569L577.4 0.800003H526C505.5 0.800003 488.8 17.5 488.8 38C488.8 58.6 505.5 75.2 526 75.2H570.2L578.6 62.1H527"
        fill="#ED1D24"
      />
      <path
        d="M585.1 8.60001C585.1 5.00001 588 2 591.7 2C595.4 2 598.3 4.90001 598.3 8.60001C598.3 12.2 595.4 15.2 591.7 15.2C588 15.2 585.1 12.3 585.1 8.60001ZM591.7 17C596.3 17 600 13.3 600 8.70001C600 4.10001 596.3 0.300003 591.7 0.300003C587 0.300003 583.3 4.10001 583.3 8.70001C583.3 13.3 587 17 591.7 17ZM590.9 3.70001C592.1 3.70001 592.7 3.80001 593.4 3.90001C595 4.40001 595.2 6 595.2 6.5C595.2 6.6 595.2 6.9 595.1 7.3C595 7.7 594.8 8.40001 593.9 8.90001C593.8 9.00001 593.7 9.00001 593.5 9.10001L595.7 13H593.6L591.7 9.40001H590.4V13H588.5V3.70001H590.9V3.70001ZM591.5 7.90001C592.1 7.90001 592.7 7.9 593 7.3C593.3 7.1 593.3 6.8 593.3 6.5C593.3 6 593 5.60001 592.6 5.40001C592.1 5.20001 591.8 5.20001 590.9 5.20001H590.4V7.90001H591.5Z"
        fill="#ED1D24"
      />
      <path d="M580 112.9H0V117H580V112.9Z" fill="black" />
      <path
        d="M199.5 170.6C199.3 168.9 198.7 162.1 191.7 162.1C184 162.1 180.8 170.1 180.8 180.1C180.8 183 180.8 196.7 190.9 196.7C195.3 196.7 199.4 193.9 199.6 187.5H210.5C210.3 190.4 210 194.9 206.1 198.9C201.8 203.3 196.4 204.3 191.9 204.3C175.2 204.3 169.8 192 169.8 180C169.8 164.6 178.4 154.4 192 154.4C202.6 154.4 210 160.8 210 170.5H199.5V170.6Z"
        fill="black"
      />
      <path
        d="M220.2 154.9H230.8V195.1H251.6V203.3H220.2V154.9Z"
        fill="black"
      />
      <path
        d="M280.9 153.8C286.1 153.8 293.2 155 297.9 160.4C303.1 166.3 304.1 173.9 304.1 178.7C304.1 185.7 301.9 193.3 298 197.7C292.6 203.7 284.8 204.6 280.8 204.6C274.4 204.6 268.1 202.6 263.6 198C258.3 192.4 257.6 184.3 257.6 179.9C257.6 166.9 263.5 153.8 280.9 153.8ZM272.1 193C273.6 195 276.1 196.9 280.9 196.9C285.1 196.9 288 195.4 290 192.4C291.6 190 293.3 186.2 293.3 178.5C293.3 176.2 293.1 172.8 292.1 169.9C290.1 163.9 286 161.3 281 161.3C277.1 161.3 274 162.9 272.1 165.4C269.3 168.8 268.5 174 268.5 179.5C268.5 184.1 269.3 189.2 272.1 193Z"
        fill="black"
      />
      <path
        d="M324.8 154.9V184.4C324.8 189.7 325.2 196.3 335.3 196.3C337 196.3 342 196.2 344.7 192.1C345.7 190.6 346.2 188.7 346.2 183.8V154.8H356.3V185.1C356.3 199.5 344.8 204.2 335 204.2C330.2 204.2 321.3 203.1 316.8 195.8C314.4 192.1 314.3 188.4 314.3 183.9V154.9H324.8V154.9Z"
        fill="black"
      />
      <path
        d="M368.8 154.9L384.8 155C387.5 155 395.7 155.1 401.5 159.4C407.6 163.9 409.7 171.5 409.7 178.5C409.7 193.4 401.7 201.2 392.5 202.9C390.7 203.2 388.7 203.4 383.8 203.4H368.7V154.9H368.8ZM379.4 195.3C380.5 195.4 382.3 195.4 383.5 195.4C387.9 195.4 391.8 195.1 394.8 192C398.9 187.9 399.2 180.7 399.2 178.3C399.2 171.5 396.8 165.2 390.3 163.3C388.1 162.7 385.4 162.4 379.4 162.6V195.3Z"
        fill="black"
      />
    </svg>
  );
}
