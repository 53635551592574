export const styles = {
  buttonRow: {
    columnGap: 2,
    justifyContent: 'flex-end',
    mt: '10%',
    width: '100%',
  },
  title: {
    mt: 3,
    flexDirection: 'column',
    spacing: 0.5,
    alignItems: 'flex-start',
  },
  form: {
    alignItems: 'center',
    alignSelf: 'center',
    p: 5,
    borderRadius: 2,
    maxHeight: '50%',
    width: '700px',
  },
  timeSelectionFrom: {
    width: '100%',
    alignItems: 'center',
    minWidth: '350px',
    pt: 3,
  },
  formInput: { width: '100%' },
};
