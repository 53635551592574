export const DB_SERVICES_BASE = 'db-services';
export const DB_BACKUP_BASE = 'db-backups';
export const IAM_BASE = 'iam';
export const AUDIT_LOGS_BASE = 'audit-logs';
export const TICKETS_BASE = 'tickets';
export const INTERNAL_BASE = 'internal';
export const ALERTS_BASE = 'alerts';
export const GENIE_BASE = 'genie';
export const TENANTS_BASE = 'tenants';
export const EXCEPTIONS_BASE = 'exceptions';
export const AVAILABILITY_MACHINES_BASE = 'availability-machines';
export const DOWNLOADS_BASE = 'downloads';
