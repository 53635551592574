import { useContext, useEffect, useMemo, useState } from 'react';
import {
  DeploymentOptionsEnum,
  defaultDedicatedCloudAccount,
} from './constants';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import moment from 'moment';
import { useGetCall, useLazyPostCall } from 'common/api/useApiCall';
import { URLS, USERS_BASE_URL } from 'constants/URL';
import { SelectChangeEvent } from '@mui/material';
import { DF_METER } from 'Layouts/VerticalLayouts/AppUtil';
import { useOnboardingConfig } from './useOnboardingConfig';
import { OTPRequestType } from 'constants/URLEnums';

export const useOnboarding = () => {
  const [state, setState] = useState({
    emailId: '',
    domain: '',
    firstName: '',
    lastName: '',
    password: '',
    skipVerificationEmail: false,
    initialCredits: 300,
    supportPlan: 'standard',
    enabledClouds: ['AWS'],
    tenantPhase: '',
    customerType: 'EXTERNAL',
    company: '',
    jobTitle: '',
    country: '',
    deploymentConfig: {
      deploymentPlan: DeploymentOptionsEnum.Business,
      isSharedControlPlane: true,
      deploymentName: '',
    },
    opsMetadata: {
      tenantPocOwnerEmails: [],
      tenantCustomerEmailContacts: [],
    },
  });

  const [namespace, setNameSpace] = useState('');
  const navigate = useNavigate();
  const { addNotification } = useContext(NotificationContext);

  const isPe =
    state.deploymentConfig.deploymentPlan ===
    DeploymentOptionsEnum.Professional;

  const [submitting] = useState(false);
  const [addBillingConfig, setAddBillingConfig] = useState(false);
  const [addDedicatedCloudAccounts, setAddDedicatedCloudAccounts] =
    useState(false);

  const handlePOCOwnersChange = (updatedPOCOwners) => {
    setState((prev) => {
      return {
        ...prev,
        opsMetadata: {
          ...prev.opsMetadata,
          tenantPocOwnerEmails: updatedPOCOwners,
        },
      };
    });
  };

  const handleTenantCustomerEmailContactsChange = (updatedContacts) => {
    setState((prev) => {
      return {
        ...prev,
        opsMetadata: {
          ...prev.opsMetadata,
          tenantCustomerEmailContacts: updatedContacts,
        },
      };
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const disableAddingCustomerEmail = (str: string) => {
    return !(str.includes('@') && str.includes('.'));
  };

  const now = moment();

  const [discount, setDiscount] = useState({
    percentage: 0,
    effectiveStartDate: now.startOf('D').add(1, 'd').format(DF_METER),
    endDate: now.add(1, 'y').format(DF_METER),
  });

  const [billingConfig, setBillingConfig] = useState({
    skipInvoiceFinalize: false,
    fixedBillAmount: 10,
    disabled: false,
    hideBillingCreditsUnit: false,
  });

  const [dedicatedCloudAccountsArray, setDedicatedCloudAccountsArray] =
    useState<Array<object>>([defaultDedicatedCloudAccount]);

  const [openOTP, setOpenOTP] = useState(false);
  const [step, setStep] = useState(1);
  const [uuid, setUuid] = useState('');

  const onboardRes = useLazyPostCall(`${URLS.onboard}`);

  const {
    response: sendOTPRes,
    isLoading: sendOTPIsLoading,
    postData: sendOTP,
  } = useLazyPostCall(`${URLS.otp}purpose=${OTPRequestType.ONBOARD_OTP}`);

  const updateState = (key: string, value: any) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const { response: usersResponse } = useGetCall(USERS_BASE_URL);

  const showNameSpace = useMemo(() => {
    return isPe || (!isPe && state?.deploymentConfig?.isSharedControlPlane);
  }, [isPe, state]);

  const handleChangeEnabledClouds = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    if (value?.length) {
      updateState('enabledClouds', value);
    }
  };

  const updateForm = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    let { name, value } = e.target;
    switch (name) {
      case 'initialCredits':
        if (value < 0) {
          value = 0;
        }
        break;
      default:
        break;
    }
    updateFormValue(name, value);
  };

  const updateDeploymentConfig = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
    v?: any,
  ): void => {
    const name =
      typeof e === 'string' ? e : (e.target as HTMLInputElement).name;
    const value = typeof e === 'string' ? v : e?.target.value;
    if (name === 'deploymentPlan') {
      if (value === DeploymentOptionsEnum.Professional) {
        updateFormValue('initialCredits', 300);
        updateFormValue('domain', '');
        updateFormValue('supportPlan', 'standard');
      } else {
        updateFormValue('initialCredits', 0);
        updateFormValue('supportPlan', 'premium');
      }
    }
    setState((prev) => ({
      ...prev,
      deploymentConfig: { ...prev.deploymentConfig, [name]: value },
    }));
  };

  const { deploymentNames, availableNamespaces, nameSpacesIsLoading } =
    useOnboardingConfig({
      isPe: isPe,
      deploymentName: state?.deploymentConfig?.deploymentName,
    });

  const updateFormValue = (name: string, value: any) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const disableSubmit = () => {
    if (submitting) {
      return true;
    }
    if (!state.firstName || !state.lastName) {
      return true;
    }
    if (!state.emailId) {
      return true;
    }
    if (!isPe && !state.domain) {
      return true;
    }
    if (!state?.deploymentConfig?.deploymentName) {
      return true;
    }
    if (!state?.enabledClouds?.length) {
      return true;
    }
    return false;
  };

  const toggleBooleanBillingConfig = (key: string) =>
    setBillingConfig((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

  const deleteDedicatedCloudObject = (_index) => {
    setDedicatedCloudAccountsArray((prevdedicated) => {
      return prevdedicated.filter((obj, index) => {
        return index !== _index;
      });
    });
  };
  useEffect(() => {
    if (sendOTPRes?.uuid) {
      setUuid(sendOTPRes?.uuid);
      setStep(2);
    }
  }, [sendOTPRes]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      dedicatedCloudAccounts: dedicatedCloudAccountsArray,
    }));
  }, [dedicatedCloudAccountsArray]);

  useEffect(() => {
    if (addBillingConfig)
      setState((prev) => ({ ...prev, billingConfig: billingConfig }));
  }, [billingConfig]);

  useEffect(() => {
    setBillingConfig((prev) => ({ ...prev, discount: discount }));
    setState((prev) => ({ ...prev, discount: discount }));
  }, [discount]);

  useEffect(() => {
    if (addBillingConfig) {
      setState((prev) => ({
        ...prev,
        billingConfig: billingConfig,
      }));
    } else {
      const modifiedObject = { ...state };
      delete modifiedObject['billingConfig'];
      delete modifiedObject['discount'];
      setState(modifiedObject);
    }
  }, [addBillingConfig]);

  useEffect(() => {
    if (
      addDedicatedCloudAccounts &&
      state?.deploymentConfig?.deploymentPlan === DeploymentOptionsEnum.VPT
    ) {
      setState((prev) => ({
        ...prev,
        dedicatedCloudAccounts: dedicatedCloudAccountsArray,
      }));
    } else {
      const modifiedObject = { ...state };
      delete modifiedObject['dedicatedCloudAccounts'];
      setState(modifiedObject);
    }
  }, [addDedicatedCloudAccounts, state?.deploymentConfig?.deploymentPlan]);

  useEffect(() => {
    if (!state?.deploymentConfig?.isSharedControlPlane) {
      const copy = { ...state?.deploymentConfig };
      delete copy['namespace'];
      setState((prev) => ({ ...prev, deploymentConfig: copy }));
    } else {
      setState((prev) => ({
        ...prev,
        deploymentConfig: { ...prev?.deploymentConfig, namespace: '' },
      }));
    }
  }, [state?.deploymentConfig?.isSharedControlPlane]);

  useEffect(() => {
    if (namespace) {
      setState((prev) => ({
        ...prev,
        deploymentConfig: {
          ...prev.deploymentConfig,
          namespace: namespace,
        },
      }));
    } else {
      const deploymentConfigCopy = { ...state.deploymentConfig };
      delete deploymentConfigCopy['namespace'];
      setState({ ...state, deploymentConfig: deploymentConfigCopy });
    }
  }, [namespace]);

  useEffect(() => {
    if (onboardRes?.response) {
      if (onboardRes?.response?.id) {
        addNotification({
          severity: 'success',
          message: `Onboarding tenant with ID: ${onboardRes?.response?.id} started.`,
        });
        navigate('/tenants');
      } else {
        addNotification({
          severity: 'error',
          message: `${onboardRes?.response?.message}`,
        });
      }
    }
    if (onboardRes?.responseStatus > 201) {
      addNotification({
        severity: 'error',
        message:
          onboardRes?.error?.message ||
          `Something went wrong. Please try again.`,
      });
    }
    setOpenOTP(false);
    setStep(1);
  }, [onboardRes?.response, onboardRes?.error, onboardRes?.responseStatus]);

  return {
    state,
    updateForm,
    showPassword,
    setShowPassword,
    updateFormValue,
    isPe,
    setState,
    navigate,
    deploymentNames,
    namespace,
    setNameSpace,
    availableNamespaces,
    showNameSpace,
    nameSpacesIsLoading,
    updateDeploymentConfig,
    handleChangeEnabledClouds,
    addBillingConfig,
    setAddBillingConfig,
    billingConfig,
    toggleBooleanBillingConfig,
    setBillingConfig,
    discount,
    setDiscount,
    addDedicatedCloudAccounts,
    dedicatedCloudAccountsArray,
    submitting,
    onboardRes,
    disableSubmit,
    openOTP,
    setOpenOTP,
    step,
    setAddDedicatedCloudAccounts,
    setDedicatedCloudAccountsArray,
    defaultDedicatedCloudAccount,
    sendOTP,
    uuid,
    sendOTPIsLoading,
    deleteDedicatedCloudObject,
    updateState,
    disableAddingCustomerEmail,
    users: usersResponse?.response || [],
    handlePOCOwnersChange,
    handleTenantCustomerEmailContactsChange,
  };
};
