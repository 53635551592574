/* eslint-disable react/require-default-props */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     9/6/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';

export const TessellBox = (props: any) => {
  const { className, children, ...style } = props;

  const padding = {
    paddingTop:
      8 *
        (style.paddingTop ||
          style.pt ||
          style.paddingY ||
          style.py ||
          style.padding ||
          style.p) || '0px',
    paddingBottom:
      8 *
        (style.paddingBottom ||
          style.pb ||
          style.paddingY ||
          style.py ||
          style.padding ||
          style.p) || '0px',
    paddingRight:
      8 *
        (style.paddingRight ||
          style.pr ||
          style.paddingX ||
          style.px ||
          style.padding ||
          style.p) || '0px',
    paddingLeft:
      8 *
        (style.paddingLeft ||
          style.pl ||
          style.paddingX ||
          style.px ||
          style.padding ||
          style.p) || '0px',
  };

  const margin = {
    marginTop:
      8 *
        (style.marginTop ||
          style.mt ||
          style.marginY ||
          style.my ||
          style.margin ||
          style.m) || '0px',
    marginBottom:
      8 *
        (style.marginBottom ||
          style.mb ||
          style.marginY ||
          style.my ||
          style.margin ||
          style.m) || '0px',
    marginRight:
      8 *
        (style.marginRight ||
          style.mr ||
          style.marginX ||
          style.mx ||
          style.margin ||
          style.m) || '0px',
    marginLeft:
      8 *
        (style.marginLeft ||
          style.ml ||
          style.marginX ||
          style.mx ||
          style.margin ||
          style.m) || '0px',
  };

  return (
    <div
      className={clsx({
        [className]: !!className,
      })}
      style={{ ...style, ...padding, ...margin }}
    >
      {children}
    </div>
  );
};
