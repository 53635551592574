import { Stack, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import {
  StatusBackgroundColor,
  StatusBorderColor,
  StatusTextColor,
} from 'constants/statusConfigs';
import { StatusString } from 'customers/db-services-detailed-view/Status';
import { useStyles } from 'customers/db-services-detailed-view/utils';

type StatusLabelProps = {
  backgroundColor?: string;
  border?: string;
  textColor?: string;
  Icon?: any;
  text: string;
};

export default function StatusLabel({
  backgroundColor,
  border,
  textColor,
  Icon = null,
  text,
}: StatusLabelProps) {
  const classes = useStyles();
  backgroundColor = backgroundColor || StatusBackgroundColor[text] || '#555';
  border = border || StatusBorderColor[text] || '#fff';
  textColor = textColor || StatusTextColor[text] || styles.color.baseBackground;

  return text ? (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        ...classes.statusBox,
        backgroundColor: `${backgroundColor}`,
        border: `0.5px solid ${border}`,
        borderRadius: '4px',
      }}
    >
      <Typography color={textColor}>{StatusString[text] || text}</Typography>
      {Icon && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: '12px', width: '12px' }}
          ml={1}
        >
          <Icon sx={{ color: textColor }} />
        </Stack>
      )}
    </Stack>
  ) : (
    <Stack>-</Stack>
  );
}
