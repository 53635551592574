/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export const MODAL = {
  createStartStop: 'createStartStop',
  updateStartStop: 'updateStartStop',
  removeStartStop: 'removeStartStop',
  createDelete: 'createDelete',
  updateDelete: 'updateDelete',
  removeDelete: 'removeDelete',
  pause: 'pause',
  resume: 'resume',
};

export const scheduleStatusTypes = {
  active: 'active',
  paused: 'paused',
  expired: 'expired',
}

export const nextScheduleAction = { 
  stop: 'stop',
  start: 'start',
  delete: 'delete',
}

export const toUTCString = (inputDate) => {
  if (!inputDate) {
    return inputDate;
  }
  return moment(inputDate).toUTCString();
};

export const tolocalTime = (inputDate) => {
  if (!inputDate) {
    return inputDate;
  }
  return moment(inputDate).toLocaleString();
}