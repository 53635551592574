import {
  useLazyGetCallWithURL,
  useLazyPostCall,
  useLazyPutCall,
} from 'common/api/useApiCall';
import { UpdateDBEngineConfigProps } from './types';
import { useContext, useEffect, useState } from 'react';
import { URLS } from 'constants/URL';
import { OTPRequestType } from 'constants/URLEnums';
import { evaluateTenantName } from 'common/TenantContainer/evaluateTenantName';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';

export const useUpdateDBEngineConfig = (props: UpdateDBEngineConfigProps) => {
  const { setOpen, tenantId, tenantDomain, tenant } = props;

  const [data, setData] = useState('{}');
  const [engine, setEngine] = useState('ORACLE');
  const [payload, setPayLoad] = useState({});
  const [uuid, setUuid] = useState('');
  const [step, setStep] = useState(1);
  const [openOTP, setOpenOTP] = useState(false);

  const { addNotification } = useContext(NotificationContext);

  const {
    response,
    isLoading: fetchIsLoading,
    postData: fetchDBEngineConfig,
  } = useLazyGetCallWithURL();

  const {
    response: sendOTPRes,
    isLoading: sendOTPIsLoading,
    postData: sendOTP,
  } = useLazyPostCall(
    `${URLS.otp}purpose=${OTPRequestType.DB_ENGINE_CONFIG_OTP}`,
  );

  const {
    response: updateDBEngineConfigRes,
    isLoading: updateIsLoading,
    postData: updateDBEngineConfig,
    responseStatus: updateDBEngineConfigResponseStatus,
  } = useLazyPutCall(`${URLS.getDBEngineConfig}tenant-domain=${tenantDomain}`);

  useEffect(() => {
    fetchDBEngineConfig(
      `${URLS.getDBEngineConfig}default-only=true&tenant-domain=${tenantDomain}&engine=${engine}`,
    );
  }, [engine]);

  useEffect(() => {
    setData(JSON.stringify(response?.response, null, 2));
  }, [response]);

  useEffect(() => {
    if (sendOTPRes?.uuid) {
      setUuid(sendOTPRes?.uuid);
      setStep(2);
    }
  }, [sendOTPRes]);

  useEffect(() => {
    if (updateDBEngineConfigRes) {
      if (updateDBEngineConfigResponseStatus === 200) {
        addNotification({
          message: 'DB Engine Config updated successfully',
          severity: 'success',
        });
        fetchDBEngineConfig(
          `${URLS.getDBEngineConfig}&tenant-domain=${tenantDomain}`,
        );
      } else {
        addNotification({
          message:
            updateDBEngineConfigRes?.message ||
            'Failed to update DB Engine Config',
          severity: 'error',
        });
      }
      onClose();
    }
  }, [updateDBEngineConfigRes, updateDBEngineConfigResponseStatus]);

  const sendOTPCall = ({ reason, jiraId }) => {
    const payload = {
      'tenant-id': tenantId,
      'tenant-domain': tenantDomain,
      comment: reason,
      'jira-id': jiraId,
      engine,
      'tenant-name': evaluateTenantName({ tenant }),
    };
    sendOTP(JSON.stringify(payload, null));
  };
  const handleUpdateClick = () => {
    try {
      JSON.parse(data);
      setOpenOTP(true);
    } catch (error) {
      addNotification({
        message: 'Invalid JSON',
        severity: 'error',
      });
    }
  };
  const onClose = () => {
    setOpen(false);
    setPayLoad({});
    setOpenOTP(false);
    setStep(0);
    fetchDBEngineConfig(URLS.getDBEngineConfig);
  };

  const modalSubmitHandler = (otp) => {
    if (step === 1) {
      try {
        JSON.parse(data);
        sendOTPCall(otp);
      } catch (error) {
        addNotification({
          message: 'Invalid JSON',
          severity: 'error',
        });
      }
    } else if (step === 2) {
      try {
        const finalPayload = {
          'new-config': JSON.parse(data)?.[0],
          'tenant-id': tenantId,
          otp,
          'request-id': uuid,
        };
        updateDBEngineConfig(JSON.stringify(finalPayload));
      } catch (e) {
        addNotification({
          message: 'Invalid JSON',
          severity: 'error',
        });
        return;
      }
    }
  };

  return {
    response,
    payload,
    openOTP,
    data,
    fetchIsLoading,
    sendOTPIsLoading,
    updateIsLoading,
    step,
    engine,
    setEngine,
    setData,
    onClose,
    setOpenOTP,
    setPayLoad,
    setStep,
    modalSubmitHandler,
    handleUpdateClick,
  };
};
