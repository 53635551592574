import React, { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
//import logo

//Import Components
import { Container } from 'reactstrap';
import TessellAppBar from './VerticalLayouts/TessellAppBar';

interface SidebarProps {
  layoutType?: string;
}

const Sidebar = (props: SidebarProps) => {
  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName('vertical-overlay');
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener('click', function () {
        document.body.classList.remove('vertical-sidebar-enable');
      });
    }
  });

  return (
    <>
      <div className="app-menu navbar-menu">
        <SimpleBar id="scrollbar" className="h-100">
          <Container fluid>
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <TessellAppBar />
            </ul>
          </Container>
        </SimpleBar>
        <div className="sidebar-background"></div>
      </div>
      <div className="vertical-overlay"></div>
    </>
  );
};

export default Sidebar;
