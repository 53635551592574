import { Auth } from 'aws-amplify';
import { currentAwsConfig } from './awsConfigure';

export const mfaSetupAuth = async (
  loginInfo,
  otp,
  setUserLoginInfo,
  navigate,
  setVerifyLoading,
) => {
  const awsConfig = currentAwsConfig;
  setVerifyLoading(true);
  try {
    await Auth.verifyTotpToken(loginInfo, otp)
      .then((res) => {
        Auth.setPreferredMFA(loginInfo, 'TOTP');
      })
      .then((res) => {
        setUserLoginInfo({});
        setVerifyLoading(false);
        navigate('/login');
      })
      .catch((e) => {
        console.log('Error Signing in:', new Error());
        setVerifyLoading(false);
        navigate('/login');
        setUserLoginInfo({});
      });
  } catch (error) {
    console.log('Error Signing in', error);
    setUserLoginInfo({});

    return error;
  }
};
