/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function IAMIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} viewBox="0 0 25 13" fill="var(--icon-color)" xmlns="http://www.w3.org/2000/svg" style={iconStyle}>
      <mask id="path-1-inside-1_1289_323" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.16817 6.24733C8.93111 6.24733 10.3603 4.84882 10.3603 3.12367C10.3603 1.39851 8.93111 0 7.16817 0C5.40522 0 3.97607 1.39851 3.97607 3.12367C3.97607 4.84882 5.40522 6.24733 7.16817 6.24733Z" fill="white" />
      </mask>
      <path d="M9.66026 3.12367C9.66026 4.448 8.55889 5.54733 7.16817 5.54733V6.94733C9.30334 6.94733 11.0603 5.24964 11.0603 3.12367H9.66026ZM7.16817 0.7C8.55889 0.7 9.66026 1.79934 9.66026 3.12367H11.0603C11.0603 0.997691 9.30334 -0.7 7.16817 -0.7V0.7ZM4.67607 3.12367C4.67607 1.79934 5.77745 0.7 7.16817 0.7V-0.7C5.033 -0.7 3.27607 0.997691 3.27607 3.12367H4.67607ZM7.16817 5.54733C5.77745 5.54733 4.67607 4.448 4.67607 3.12367H3.27607C3.27607 5.24964 5.033 6.94733 7.16817 6.94733V5.54733Z" fill="var(--icon-color)" mask="url(#path-1-inside-1_1289_323)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.7583 7.68836C11.2293 7.2762 11.8065 6.98413 12.4063 6.98413H15.6013C15.9469 6.98413 16.3192 7.10006 16.6699 7.27472C17.0248 7.45144 17.38 7.69942 17.6964 7.99048C18.3147 8.55933 18.8505 9.35678 18.8505 10.1769V10.3919C18.8505 11.1375 18.2461 11.742 17.5005 11.742H13.0252C13.1643 11.5425 13.2484 11.3018 13.2553 11.042H17.5005C17.8595 11.042 18.1505 10.7509 18.1505 10.3919V10.1769C18.1505 9.6438 17.7814 9.01986 17.2225 8.50563C16.9502 8.25515 16.6487 8.04617 16.3579 7.90131C16.0629 7.7544 15.8004 7.68413 15.6013 7.68413H12.4063C12.0767 7.68413 11.7149 7.83078 11.3756 8.0873C11.1745 7.93922 10.9669 7.80517 10.7583 7.68836Z" fill="var(--icon-color)" />
      <ellipse cx="14.2157" cy="4.00792" rx="2.16248" ry="2.16417" fill="var(--icon-color)" />
      <path d="M4.62108 7.37451H9.14803C10.6915 7.37451 13.2557 9.37808 13.2557 11.1983C13.2557 11.9089 12.6796 12.4851 11.969 12.4851H2.28678C1.57611 12.4851 1 11.9089 1 11.1983C1 9.49184 2.85615 7.37451 4.62108 7.37451Z" stroke="var(--icon-color)" strokeWidth="1" strokeLinecap="round" fill="var(--icon-color)" />
    </svg>
  );
}
