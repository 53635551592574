/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function DeleteIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.7408 2.66671H18.8325C19.9175 2.66671 20.8 3.50415 20.8 4.53336C20.8 5.56256 19.9175 6.40001 18.8325 6.40001H18.7846L17.236 22.5441C17.1545 23.3733 16.4322 24 15.5551 24H5.2423C4.36256 24 3.64004 23.3733 3.56141 22.5441L2.01539 6.40001H1.96755C0.882709 6.40001 0 5.56276 0 4.53336C0 3.50415 0.882509 2.66671 1.96755 2.66671H5.05939V1.60012C5.05939 0.71725 5.81566 0 6.74601 0H14.0542C14.9848 0 15.7408 0.717488 15.7408 1.60012V2.66671ZM6.74616 1.06683C6.43697 1.06683 6.18404 1.30677 6.18404 1.60012L6.18393 2.66671H14.6165V1.60012C14.6165 1.30679 14.3636 1.06683 14.0544 1.06683H6.74616ZM16.1174 22.4481C16.0894 22.7254 15.8504 22.9334 15.558 22.9334H5.24215C4.94981 22.9334 4.70803 22.7254 4.68274 22.4481L3.14535 6.39972H17.6549L16.1174 22.4481ZM1.96765 5.33304H18.8328C19.2965 5.33304 19.6761 4.97309 19.6761 4.53298C19.6761 4.09286 19.2965 3.73292 18.8328 3.73292H1.96765C1.50374 3.73292 1.12434 4.09305 1.12434 4.53298C1.12434 4.9731 1.50394 5.33304 1.96765 5.33304Z" fill="var(--icon-color)" />
      <path d="M13.569 19.2H13.601C13.8811 19.2 14.116 18.9927 14.132 18.725L14.6656 10.1342C14.6842 9.8562 14.4575 9.61617 14.164 9.60101C13.8731 9.58339 13.6171 9.79806 13.6011 10.076L13.0675 18.6669C13.0515 18.9448 13.2755 19.1824 13.569 19.2H13.569Z" fill="var(--icon-color)" />
      <path d="M10.2224 18.9724L10.2544 18.9713C10.5343 18.9615 10.7618 18.7462 10.7685 18.4781L11.0018 9.87381C11.0107 9.59538 10.7757 9.36341 10.4819 9.35851C10.1906 9.35105 9.94214 9.57453 9.93585 9.85285L9.70256 18.4571C9.69627 18.7355 9.92847 18.9651 10.2224 18.9724Z" fill="var(--icon-color)" />
      <path d="M7.46553 19.2H7.49755C7.7911 19.1848 8.0152 18.9448 7.99919 18.6668L7.46552 10.0758C7.44951 9.7978 7.19599 9.5856 6.90245 9.60076C6.60892 9.61592 6.3848 9.85598 6.40081 10.1339L6.93448 18.725C6.95048 18.9929 7.1854 19.2 7.46557 19.2H7.46553Z" fill="var(--icon-color)" />
    </svg>
  );
}
