import clsx from 'clsx';
import { useStyles } from './utils';

export default function PortalAdministrationIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0923 0.799927L1.9076 0.799926C1.29582 0.799926 0.799902 1.29586 0.799902 1.90762L0.799902 14.0923C0.799902 14.7041 1.29582 15.2 1.9076 15.2L14.0923 15.2C14.704 15.2 15.2 14.7041 15.2 14.0923L15.2 1.90762C15.2 1.29586 14.704 0.799927 14.0923 0.799927Z"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.56919 5.23071L9.10767 5.23071"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.10758 5.23076C9.10758 6.14841 9.85147 6.8923 10.7691 6.8923C11.6868 6.8923 12.4307 6.14841 12.4307 5.23076C12.4307 4.31311 11.6868 3.56921 10.7691 3.56921C9.85147 3.56921 9.10758 4.31311 9.10758 5.23076Z"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4307 10.7692L9.10758 10.7692"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.78467 10.7692L3.56928 10.7692"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.78458 10.7692C5.78458 11.6868 6.52847 12.4308 7.44612 12.4308C8.36377 12.4308 9.10767 11.6868 9.10767 10.7692C9.10767 9.85156 8.36377 9.10767 7.44612 9.10767C6.52847 9.10767 5.78458 9.85156 5.78458 10.7692Z"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
