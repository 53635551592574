/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date          Author             Comment
 *   --------------------------------------------------
 *     7/22/2021     bakul.banthia         Created
 *
 */

import { useState, useEffect, useMemo, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  Link,
  Tooltip,
  Typography,
  Zoom,
  useTheme,
} from '@mui/material';
import { AllAppsList, BasicRDSApps, Families } from './AppList';
import AppIcon from './AppIcon';
import { styles } from 'assets/scss/style-templates';
import {
  AdministrationAppsLabel,
  CustomerAppsLabel,
  InfrastructureAppsLabel,
} from 'Layouts/AppLabels';
import Cache from 'common/cache/lib/utils/Cache';
import { DashboardApp } from 'Layouts/DashboardAppIcon';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';

export default function TessellAppBar() {
  const { hasPermission } = useContext(UserPermissionsContext);

  const classes = useStyles();
  const url = window.location.pathname;

  const selectedFamily = AllAppsList?.find((app) =>
    url?.includes(app?.url),
  )?.parentApp;

  const userApps = [
    CustomerAppsLabel.Tenants,
    CustomerAppsLabel.DatabaseServices,
    hasPermission(PERMISSIONS.TICKET_VIEW) && CustomerAppsLabel.Tickets,
    CustomerAppsLabel.Billing,
    InfrastructureAppsLabel.CloudAccounts,
    hasPermission(PERMISSIONS.INFRA_MONITORING) &&
      InfrastructureAppsLabel.CloudInfrastructure,
    InfrastructureAppsLabel.PlatformServices,
    hasPermission(PERMISSIONS.IAM) && AdministrationAppsLabel.IAM,
    AdministrationAppsLabel.SREAdministration,
    (hasPermission(PERMISSIONS.INFRA_MONITORING) ||
      hasPermission(PERMISSIONS.ALERTS_VIEW) ||
      hasPermission(PERMISSIONS.ALERTS_SILENCE)) &&
      AdministrationAppsLabel.PortalAdministration,
  ].filter((a) => a);

  const [showAppsFor, setShowAppsFor] = useState(selectedFamily);
  const [dropDownFamily, setDropDownFamily] = useState(selectedFamily);

  const filteredFamilies = useMemo(
    () =>
      Families?.filter?.((f) =>
        //@ts-ignore
        BasicRDSApps[f.name]?.find((app) =>
          userApps?.find((uApp) => uApp === app?.title),
        ),
      ) || [],
    [userApps],
  );

  const getAppsData = (f) =>
    BasicRDSApps[f]?.filter((app) => {
      return userApps?.indexOf(app.title) !== -1;
    }) || [];

  const selectedApp = useMemo(() => {
    const familyApps = getAppsData(selectedFamily) || [];
    setDropDownFamily(selectedFamily);
    return (
      familyApps.find((app) =>
        selectedFamily.toLowerCase() === 'governance' ||
        selectedFamily.toLowerCase() === 'cost'
          ? url.includes(app.selectedUrl)
          : url.includes(app.url),
      ) || {}
    );
  }, [url, selectedFamily]);

  const [app, setApp] = useState(selectedApp.title);

  useEffect(() => {
    setApp(selectedApp.title);
    Cache.set('currentApp', selectedApp.title);
  }, [selectedApp]);

  const oneLevel = () => {
    const handleFamilyClick = (e, name, appClick) => {
      setShowAppsFor(name);
      setDropDownFamily((prev) => (appClick ? name : prev));
    };

    const appPicker = () => {
      if (!showAppsFor) {
        return null;
      }

      const apps = getAppsData(showAppsFor);

      return (
        <Box display="flex" flexDirection="row">
          <Box
            sx={classes.appSelector}
            display="flex"
            flexDirection="column"
            alignItems="center"
            color="#FFF"
            maxWidth={apps.length > 4 ? 500 : 350}
            p={1}
          >
            <Typography color="inherit" variant="h6">
              {showAppsFor}
            </Typography>
            <Grid container>
              {apps.map((app) => {
                const { title, icon, url } = app;
                const Icon = icon;
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width={150}
                    height={100}
                    px={1}
                    pt={3}
                    m={0.5}
                    sx={classes.appHover}
                    textAlign="center"
                    color="#FFF"
                    component={RouterLink}
                    to={url}
                    onClick={() => handleFamilyClick(null, showAppsFor, true)}
                  >
                    <Box width={36} height={36}>
                      <Icon width="36" sx={{ color: '#ffffff' }} />
                    </Box>
                    <Typography color="inherit" variant="caption">
                      {title}
                    </Typography>
                  </Box>
                );
              })}
            </Grid>
          </Box>
        </Box>
      );
    };

    const expandedFamily = (family, icon) => {
      const Icon = icon;
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={classes.expandedFamily}
          py={1}
          width="100%"
        >
          <AppIcon
            icon={<Icon width="25" sx={{ color: '#ffffff' }} />}
            name={family}
            size={25}
            my={1}
            borderless
            selected
            tooltip={<Typography sx={classes.boldText}>{family}</Typography>}
          />
          <Divider sx={classes.divider} />
          {getAppsData(dropDownFamily)?.map((appItem) => {
            const { title, icon, url } = appItem;
            const Icon = icon;
            const selected = app === title;

            return (
              <Link
                component={RouterLink}
                to={url}
                className="animate__animated animate__slideInDown"
              >
                <AppIcon
                  icon={<Icon width="25" sx={{ color: '#ffffff' }} />}
                  name={title}
                  size={28}
                  my={0.3}
                  darkBg
                  borderless={!selected}
                  selected={selected}
                  hoverBg
                  tooltip={
                    <Typography sx={classes.boldText}>{title}</Typography>
                  }
                />
              </Link>
            );
          })}
        </Box>
      );
    };

    const renderFamily = (name, icon) => {
      const selected = dropDownFamily === name;
      if (selected) {
        return expandedFamily(name, icon);
      }

      const Icon = icon;
      return (
        <Tooltip
          title={appPicker()}
          placement="right-start"
          TransitionComponent={Zoom}
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#11567F',
                maxWidth: '500px',
              },
              '& .MuiTooltip-arrow': {
                color: '#11567F',
              },
            },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [8, 0],
                },
              },
            ],
          }}
          arrow
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onMouseOver={(e) => handleFamilyClick(e, name, false)}
            sx={classes.unselectedFamily}
          >
            <AppIcon
              icon={<Icon width="25" sx={{ color: '#ffffff' }} />}
              name=""
              size={25}
              my={1.5}
              borderless
            />
          </Box>
        </Tooltip>
      );
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        width={70}
        sx={classes.appBasicRDSApps}
        minWidth={64}
      >
        <DashboardApp />
        {filteredFamilies?.map((f) => renderFamily(f.name, f.icon))}
      </Box>
    );
  };

  return <>{oneLevel()}</>;
}

const useStyles = () => {
  const theme = useTheme();
  return {
    appBasicRDSApps: {
      backgroundColor: styles.color.header,
      zIndex: theme.zIndex.drawer + 3,
    },
    apps: {
      background: '#FFF',
      zIndex: theme.zIndex.drawer + 2,
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    noApps: {
      background: styles.color.baseBackground,
      position: 'absolute',
      left: 70,
      top: 0,
      bottom: 0,
      zIndex: theme.zIndex.drawer + 4,
    },
    expandedFamily: {
      background: styles.color.lighterShade2,
      cursor: 'default',
      transition: 'background-color 1000ms ease',
    },
    appSelector: {
      background: styles.color.darkShade,
      borderRadius: '5px',
      zIndex: theme.zIndex.drawer + 2,
    },
    caret: {
      width: 0,
      height: 0,
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderRight: '8px solid #11567F',
      marginTop: 20,
    },
    appHover: {
      borderRadius: '10px',
      cursor: 'pointer',
      transition: 'background-color 300ms ease-in-out',
      '&:hover': {
        background: styles.color.hover,
      },
      textDecoration: 'none',
    },
    selectedFamily: {
      background: '#FFF',
      cursor: 'default',
      transition: 'background-color 1000ms ease',
      position: 'relative',
    },
    unselectedFamily: {
      transition: 'background-color 1000ms ease',
      cursor: 'pointer',
      color: 'white',
      position: 'relative',
      '&:hover': {
        background: `${styles.color.lighterShade2}88`,
      },
    },
    muiFamilyIcon: {
      fontSize: '32px',
    },
    divider: {
      backgroundColor: styles.color.greyBackground2,
      width: 40,
      height: 2,
      margin: '4px 0px',
    },
    familyTitle: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 10,
      position: 'absolute',
      bottom: theme.spacing(0.5),
    },
    appTitle: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 9,
      lineHeight: '9px',
      position: 'absolute',
      top: 40,
      textAlign: 'center',
    },
    tooltip: {
      background: 'transparent',
    },
  };
};
