export default function BackingUpIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8896 6.00752C12.4992 5.11351 11.8565 4.3528 11.0403 3.8186C10.224 3.2844 9.26964 2.99991 8.29411 3C5.69511 3 3.55727 4.97845 3.30664 7.51128"
        stroke="#11567F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8008 6.00724H13.0063C13.0458 6.00724 13.0849 5.99946 13.1214 5.98435C13.1579 5.96923 13.1911 5.94708 13.219 5.91915C13.2469 5.89123 13.2691 5.85807 13.2842 5.82158C13.2993 5.78509 13.3071 5.74599 13.3071 5.70649V3.50098M3.72363 10.0173C4.49757 11.7877 6.26398 13.0248 8.31912 13.0248C10.9186 13.0248 13.056 11.0463 13.3071 8.51351"
        stroke="#11567F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.81291 10.0176H3.60739C3.52763 10.0176 3.45113 10.0493 3.39473 10.1057C3.33833 10.1621 3.30664 10.2386 3.30664 10.3183V12.5238"
        stroke="#11567F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
