import { Stack, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import { tenantPhases } from 'customers/tenants-detailed-view/lib/components/onboarding/constants';

type TenantStatusLabelProps = {
  status: any;
};

const TenantStatusLabel = (props: TenantStatusLabelProps) => {
  const { status } = props;
  return (
    <Stack px={0.5}>
      <Typography
        variant="subtitle2"
        fontSize={14}
        color={
          status === tenantPhases.LIVE
            ? styles.color.green
            : styles.color.darkShade
        }
      >
        {status}
      </Typography>
    </Stack>
  );
};

export default TenantStatusLabel;
