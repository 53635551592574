import { Stack, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

type TextWithInfoTooltipProps = {
  text: string;
  tooltipContent: any;
  tooltipPopperProps?: any;
  tooltipPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
};

export const TextWithInfoTooltip = (props: TextWithInfoTooltipProps) => {
  const {
    text,
    tooltipContent,
    tooltipPopperProps,
    tooltipPlacement = 'right',
  } = props;
  return (
    <Stack direction="row" spacing={0.7}>
      <Typography>{text}</Typography>
      <Tooltip
        arrow
        title={tooltipContent || '-'}
        placement={tooltipPlacement}
        PopperProps={tooltipPopperProps}
      >
        <InfoIcon color="primary" sx={{ fontSize: '14px' }} />
      </Tooltip>
    </Stack>
  );
};
