import { styles } from 'assets/scss/style-templates';

// export type IconPropType = {
//     className: string,
//     monotone: bool,
//     dark: bool,
//     width: number,
//     name: string,
// };

export function useColors(monotone, dark, width) {
  return {
    width,
    '--dark-shade': monotone
      ? dark
        ? styles.color.monotoneDarkDark
        : styles.color.monotoneLightDark
      : styles.color.darkShade,
    '--light-shade': monotone
      ? dark
        ? styles.color.monotoneDarkLight
        : styles.color.monotoneLightLight
      : styles.color.lightShade,
  };
}
