import { LinearProgress, Stack } from '@mui/material';
import { COST_MAX_RANGE, CostRangeType, CostResponseKeys } from '../constants';
import { costLayout } from '../styles';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import { adaptToAutocompleteOption } from 'Administration/IAM/Groups/EditGroup/groupResponseAdapter';
import DatePickerToggler from 'common/custom-components/lib/components/DatePickerToggler';
import { DF_HUMAN_WITHOUT_TIME } from 'helpers/dateFormats';
import { isEmpty } from 'lodash';
import TotalTableAndPieView from '../TotalTableAndPieView/TotalTableAndPieView';
import { EMPTY_STRING } from 'constants/appConstants';
import { adaptSingleElementToAutoCompleteOption } from '../adapters';
import { useCostData } from '../Hooks/useCostData';
import { CostEnums } from 'constants/URLEnums';
import DayWiseChartAndTable from '../DayWiseChartAndTable/DayWiseChartAndTable';
import NoData from '../NoData';

type ServicesCostProps = {
  servicesNames: string[];
  servicesNamesIsLoading: boolean;
};

const ServicesCost = (props: ServicesCostProps) => {
  const { servicesNames = [], servicesNamesIsLoading = [] } = props;

  const {
    startTime,
    endTime,
    selectedEntities: selectedServices,
    setSelectedEntities: setSelectedServices,
    costData: servicesCost,
    costDataisLoading: servicesCostIsLoading,
    updateTimeUsingNativePicker,
    handleDateToggle,
    range,
  } = useCostData({
    costType: CostEnums.COST_BY_SERVICE,
    costParamsKey: 'service-name',
  });

  return (
    <Stack sx={costLayout.contentContainer}>
      <Stack sx={costLayout.topBarContainer}>
        <Stack
          direction={'row'}
          sx={costLayout.topBarContentContainer}
          spacing={2}
        >
          <TessellAutocomplete
            label="Services"
            showDefaultStyleLabel
            value={selectedServices}
            variant="outlined"
            size="small"
            onChange={(_val) => {
              setSelectedServices(_val);
            }}
            fullWidth
            options={
              adaptToAutocompleteOption(
                servicesNames,
                adaptSingleElementToAutoCompleteOption,
              ) || []
            }
            multiple
            disabled={servicesNamesIsLoading}
            sx={{
              ...(!selectedServices?.length && { width: '250px' }),
            }}
          />
          <DatePickerToggler
            rangeType={CostRangeType}
            startTime={startTime}
            endTime={endTime}
            updateTimeUsingNativePicker={updateTimeUsingNativePicker}
            handleOnChange={handleDateToggle}
            range={range}
            customDateFormat={DF_HUMAN_WITHOUT_TIME}
            showTimePicker={false}
            maxSpanInDays={COST_MAX_RANGE}
          />
        </Stack>
      </Stack>
      <Stack sx={costLayout.graphAndTableContainer}>
        {servicesCostIsLoading ? (
          <Stack sx={costLayout.loaderContainer}>
            <Stack width="100%">
              <LinearProgress color="primary" />
            </Stack>
          </Stack>
        ) : !servicesCostIsLoading && !isEmpty(servicesCost) ? (
          <Stack direction={'row'} spacing={1} width="100%">
            <Stack sx={costLayout.chartContainer}>
              <DayWiseChartAndTable
                costArray={servicesCost}
                seriesAndHeadName={CostResponseKeys.SERVICE_NAME}
                firstColmnTitle="Service Name"
              />
            </Stack>
            <TotalTableAndPieView
              costArray={servicesCost}
              tableFirstColumnKey={CostResponseKeys.SERVICE_NAME}
            />
          </Stack>
        ) : (
          <Stack>{!servicesCost?.length ? <NoData /> : EMPTY_STRING}</Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default ServicesCost;
