/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function ScriptLibraryIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6.21739L12.9838 1.08559C13.1113 1.031 13.2557 1.03181 13.3826 1.08783L25 6.21739" stroke="var(--icon-color)" strokeLinecap="round" />
      <path d="M1 24.0036V10.3965C1 10.2286 1.08624 10.0698 1.22825 9.98017C6.24009 6.81664 10.7097 8.80437 12.6714 10.4663C12.8818 10.6445 13.1964 10.6426 13.4012 10.4581C17.2886 6.95634 22.4171 8.57878 24.768 9.9839C24.9141 10.0712 25 10.2305 25 10.4007V23.8381C25 24.277 24.4547 24.5219 24.084 24.2869C20.7351 22.164 15.8006 23.6346 13.3358 24.8492C13.1546 24.9384 12.9373 24.9137 12.7775 24.7901C8.79032 21.7044 4.25624 23.036 1.83089 24.4539C1.47576 24.6615 1 24.4149 1 24.0036Z" stroke="var(--icon-color)" strokeLinecap="round" />
      <path d="M8.30426 12.4783L5.45606 15.7343C5.29366 15.92 5.29092 16.1963 5.44959 16.3851L8.30426 19.7826" stroke="var(--icon-color)" strokeLinecap="round" />
      <path d="M17.6957 12.4783L20.5439 15.7343C20.7063 15.92 20.7091 16.1963 20.5504 16.3851L17.6957 19.7826" stroke="var(--icon-color)" strokeLinecap="round" />
      <path d="M13.522 12.4783L12.4785 19.7826" stroke="var(--icon-color)" strokeLinecap="round" />
    </svg>
  );
}
