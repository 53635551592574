import clsx from 'clsx';
import { useStyles } from './utils';


export default function DotIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} viewBox="0 0 4 4" style={iconStyle} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="2" r="2" fill="var(--icon-color)"/>
    </svg>
  );
}