import { Stack } from '@mui/material';
import { usePGSyncMonitoring } from './usePGSyncMonitoring';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { useMemo } from 'react';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { LoadingButton } from '@mui/lab';
import { pgSyncMonitoringStyles } from './styles';
import moment from 'moment';
import { DF_HUMAN } from 'helpers/dateFormats';
import RefreshIcon from '@mui/icons-material/Refresh';

const PGSyncMonitoring = () => {
  const { pgSyncMonitoringResponse, isLoading, triggerRefresh } =
    usePGSyncMonitoring();

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 100,
      },
      {
        field: 'pending',
        headerName: 'pending',
        width: 80,
      },
      {
        field: 'write',
        headerName: 'write',
        width: 80,
      },
      {
        field: 'replay',
        headerName: 'replay',
        width: 80,
      },
      {
        field: 'flush',
        headerName: 'flush',
        width: 80,
      },
      {
        field: 'total_lag',
        headerName: 'total_lag',
        width: 80,
      },
      {
        field: 'timestamp',
        headerName: 'Timestamp',
        width: 150,
        renderCell: (params) => {
          const { row: data } = params;
          const { timestamp } = data;
          const dateCreated = moment(timestamp).format(DF_HUMAN);
          if (!dateCreated) {
            return '';
          }
          return <Stack>{dateCreated}</Stack>;
        },
      },
    ],
    [],
  );

  const actionProps = useMemo(
    () => [
      {
        startIcon: <RefreshIcon />,
        label: 'Refresh',
        onClick: (e) => {
          triggerRefresh();
        },
      },
    ],
    [],
  );

  return (
    <Stack sx={pgSyncMonitoringStyles.container}>
      <DataGrid
        columns={columns}
        rows={pgSyncMonitoringResponse}
        loading={isLoading}
        styleOverrides={{ hideShadow: true }}
        actions={actionProps}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
    </Stack>
  );
};

export default PGSyncMonitoring;
