import { Stack, Typography } from '@mui/material';
import { useStyles } from '../utils';
import { styles } from 'assets/scss/style-templates';

export const scheduleStatusTypes = {
  active: 'active',
  paused: 'paused',
  expired: 'expired',
};

export function GetScheduleStatus({ status: upperCaseStatus }) {
  const status = upperCaseStatus?.toLowerCase();
  const classes = useStyles();

  switch (status) {
    case scheduleStatusTypes.active:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#F0FCF0',
            border: '0.5px solid #4DAC4A',
          }}
        >
          <Typography color={styles.color.green}>Active</Typography>
        </Stack>
      );
    case scheduleStatusTypes.expired:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FFEBEB',
            border: '0.5px solid #EC373C',
          }}
        >
          <Typography color={styles.color.red}>Expired</Typography>
        </Stack>
      );
    case scheduleStatusTypes.paused:
      return (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            ...classes.statusBox,
            backgroundColor: '#FEF2D8',
            border: '0.5px solid #F29132',
          }}
        >
          <Typography color={styles.color.warn}>Paused</Typography>
        </Stack>
      );
    default:
      return null;
  }
}
