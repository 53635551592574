import { headers } from 'constants/URL';
import moment from 'moment';
const fileDownload = require('js-file-download');

export const downloadData = (
  url,
  _filename = `report-${moment().format('YYYY_MM_DDD_HH_mm_ss')}.csv`,
) => {
  fetch(url, {
    method: 'GET',
    headers: headers(),
  })
    .then((response) => {
      return response.blob();
    })
    .then((v) => {
      fileDownload(v, _filename);
    })
    .catch((err) => console.log(err));
};
