import { Auth } from 'aws-amplify';
import { currentAwsConfig } from './awsConfigure';

export const forgetPasswordAuth = async (
  email,
  verificationCode,
  newPassword,
  setUserLoginInfo,
  navigate,
  setForgotPasswordLoading,
) => {
  setForgotPasswordLoading(true);
  const awsConfig = currentAwsConfig;
  try {
    const userSignIn = await Auth.forgotPasswordSubmit(
      email,
      verificationCode,
      newPassword,
    )
      .then((data) => {
        console.log({ data });
        setForgotPasswordLoading(false);
        navigate('/login');
      })
      .catch((err) => console.log(err));
    setForgotPasswordLoading(false);
  } catch (error) {
    console.log('Error Signing in', error);
    setForgotPasswordLoading(false);
    setUserLoginInfo({});
    return error;
  }
};

export const sendForgetPasswordVerificationCode = async (
  email,
  setSendVerificationCodeLoading,
) => {
  setSendVerificationCodeLoading(true);
  const awsConfig = currentAwsConfig;
  try {
    const userSignIn = Auth.forgotPassword(email)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
    setSendVerificationCodeLoading(false);
  } catch (error) {
    console.log('Error Signing in', error);
    setSendVerificationCodeLoading(false);
    return error;
  }
};
