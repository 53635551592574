import { useGetCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { adaptScheduleResponse } from './adaptScheduleResponse';

type ScheduleProps = {
  dbServiceId: string;
  pgSource: string;
};

export const useSchedule = (props: ScheduleProps) => {
  const { dbServiceId, pgSource } = props;
  const { response: schedule, isLoading: scheduleIsLoading } = useGetCall(
    `${URLS.fetchStartStop}${dbServiceId}/start-stop?&tenant-domain=${pgSource}`,
    adaptScheduleResponse,
  );

  return {
    schedule,
    scheduleIsLoading,
  };
};
