import { UPDATE_METADATA_ENTITY_TYPE } from 'common/UpdateMetadata/constants';

export const useFetchMetadataUpdateList = () => {
  return {
    response: {
      dbServices: [
        'numOfInstances',
        'status',
        'contextInfo',
        'ownerId',
        'connectivityInfo',
        'tessellGenieInfo',
        'provisionInfo.serviceConnectivity',
        'updatesInProgressInfo',
        'engineConfiguration.userView.oracleConfig.enableArchiveMode',
      ],
      instance: ['type', 'role', 'status', 'pluginStatus', 'connectionInfo'],
      computeResource: [
        'cloudStatus',
        'machineFqdnInfo',
        'ipAddressInfo',
        'status',
      ],
      databases: ['name', 'status'],
      backups: ['name', 'status', 'databaseStats'],
      vpc: [
        'name',
        'cloudId',
        'status',
        'internalStatus',
        'isDefault',
        'isTessellCreated',
        'isPrivate',
        'onlyForPrivateAccess',
      ],
      subnet: ['isPrivate', 'eligibleForPrivate', 'isOld'],
      tenant: [
        'tenantPhase',
        'metadata.opsMetadata.tenantPocOwnerEmails',
        'metadata.opsMetadata.tenantCustomerEmailContacts',
      ],
      [UPDATE_METADATA_ENTITY_TYPE.TENANT_GENIE_CONFIG]: [
        'approvalForGenieExternal',
        'approvalForGenieInternal',
        'installFrp',
        'vmAccessForCustomer',
      ],
    },
    isLoading: false,
  };
};
