import { DownloadSharp, Help, WatchLaterOutlined } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import { RequestGenieForCompute } from './GenieTypes';
import { GenieStatus } from '../constant';
import InfoIcon from '@mui/icons-material/Info';
import { genieStyles } from './styles';

type RequestAccessMetadata = {
  role: string;
  name: string;
  error?: string;
  activeRequests?: string[];
};

type GenieComputeDetailsAndActionProps = {
  metadata: RequestAccessMetadata;
  requestId: string;
  computeId: string;
  downloadGenieConfigForACompute: RequestGenieForCompute;
  currentStatus: string;
};

export const GenieComputeDetailsAndAction = (
  props: GenieComputeDetailsAndActionProps,
) => {
  const {
    metadata,
    requestId,
    computeId,
    downloadGenieConfigForACompute,
    currentStatus,
  } = props;

  const { role, name, error } = metadata;

  const componentForStatus = (status) => {
    switch (status) {
      case GenieStatus.ENABLING: {
        return (
          <Stack height={16} width={16}>
            <WatchLaterOutlined
              color="warning"
              sx={genieStyles.computeDetailsIcon}
            />
          </Stack>
        );
      }
      case GenieStatus.ENABLED: {
        return (
          <Tooltip
            arrow
            placement="left"
            title={<Typography>Click to download keys for {name}</Typography>}
          >
            <DownloadSharp
              color="primary"
              fontSize="medium"
              onClick={() => {
                downloadGenieConfigForACompute(requestId, computeId, name);
              }}
              sx={{ cursor: 'pointer' }}
            />
          </Tooltip>
        );
      }
      case GenieStatus.FAILED_ENABLE: {
        return (
          <Tooltip
            placement="left"
            arrow
            title={<Typography>{error || '-'}</Typography>}
          >
            <Stack>
              <Help color="error" sx={genieStyles.computeDetailsIcon} />
            </Stack>
          </Tooltip>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const activeRequestsTooltip = () => {
    return currentStatus === GenieStatus.ENABLED &&
      metadata?.activeRequests?.length >= 2 ? (
      <Tooltip
        title={
          <Typography>
            Compute Resource is requested at {metadata?.activeRequests?.length}{' '}
            places.(Please use force revoke)
          </Typography>
        }
        arrow
        placement="right"
      >
        <Stack
          height={22}
          width={22}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <InfoIcon color="primary" sx={genieStyles.computeDetailsIcon} />
        </Stack>
      </Tooltip>
    ) : (
      <></>
    );
  };

  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      justifyContent="space-between"
      width={'100%'}
    >
      <Stack direction={'row'} spacing={1}>
        <Typography>{name}</Typography>
        <Typography color={styles.color.monotoneDarkDark}>({role})</Typography>
        {activeRequestsTooltip()}
      </Stack>
      <Stack direction={'row'} spacing={1}>
        {componentForStatus(currentStatus)}
      </Stack>
    </Stack>
  );
};
