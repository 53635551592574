import { InstancesColumns } from './constants';
import OverflowTip from '../../../common/custom-components/lib/components/OverflowTooltip';
import { GetInstanceStatus, InstanceRole, useStyles } from '../utils';
import { Stack, Typography } from '@mui/material';
import { DEFAULT_NULL } from 'constants/appConstants';
import ComputeInstance from 'customers/db-services-common-view/components/ComputeInstance';

const InstancesColumnComponents = (column, row) => {
  const classes = useStyles();

  switch (column) {
    case InstancesColumns.Name: {
      const { name } = row;
      return (
        <OverflowTip
          sxClass={classes.boldText}
          text={name}
          placement="left"
          variant="body2"
        />
      );
    }
    case InstancesColumns.Status: {
      const { status } = row;
      return (
        <Stack direction="row" alignItems="center">
          <GetInstanceStatus status={status} />
        </Stack>
      );
    }
    case InstancesColumns.Role: {
      const { role } = row;
      return (
        <Typography color="textPrimary">
          {InstanceRole[role?.toLowerCase()] || ''}
        </Typography>
      );
    }
    case InstancesColumns.AvailabilityZone: {
      const { cloudLocationId } = row;
      const splitCloudLocationAvailability = cloudLocationId?.split('/');
      return (
        <>
          <Typography sx={classes.boldText} color="textPrimary">
            {splitCloudLocationAvailability?.length
              ? splitCloudLocationAvailability[1]
              : DEFAULT_NULL}
          </Typography>
          <Typography variant="body2" color="textPrimary">
            {splitCloudLocationAvailability?.length
              ? splitCloudLocationAvailability[2]
              : DEFAULT_NULL}
          </Typography>
        </>
      );
    }
    case InstancesColumns.Compute: {
      const { genericInfo, updatesInProgressInfo } = row;
      const { computeType } = genericInfo;
      const isUpdateInProgress = updatesInProgressInfo?.length;
      const updateInfo =
        isUpdateInProgress && updatesInProgressInfo[0]?.updateInfo;
      const expectedCompute = updateInfo?.expected?.computeType;
      return <ComputeInstance value={computeType} />;
    }
  }
};

export default InstancesColumnComponents;
