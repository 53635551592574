import { UserLoginInfoContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { useLazyGetCall } from 'common/api/useApiCall';
import Cache from 'common/cache/lib/utils/Cache';
import { TICKETS_BASE_URL, URLS } from 'constants/URL';
import { adaptFreshdeskStatusesResponse } from 'helpers/adaptFreshdeskStatusesResponse';
import { computesResponseAdapter } from 'helpers/computesResponseAdapter';
import { createContext, useContext, useEffect, useMemo } from 'react';

export const CommonDataContext = createContext<{
  findCompute: (_name) => any;
  freshdeskStatuses: any[];
  getStatusUsingValue: (value) => any;
}>(null);

type CommonDataProps = {
  children: any;
};

export const CommonData = (props: CommonDataProps) => {
  const { children } = props;
  const { response: computesResponse, postData: getAllComputes } =
    useLazyGetCall(URLS.getAllComputes, computesResponseAdapter);

  const { response: freshdeskStatuses, postData: getFreshdeskStatuses } =
    useLazyGetCall(
      `${TICKETS_BASE_URL}/status`,
      adaptFreshdeskStatusesResponse,
    );

  const getStatusUsingValue = (value) => {
    return (
      freshdeskStatuses?.find((_s) => {
        return _s?.value === value;
      })?.label || ''
    );
  };
  const userLoginInfo = useContext(UserLoginInfoContext);

  useEffect(() => {
    if (Cache.get('idToken')) {
      getAllComputes();
      getFreshdeskStatuses();
    }
  }, [userLoginInfo]);

  const computes = useMemo(() => {
    return computesResponse || [];
  }, [computesResponse]);

  const findCompute = (name) => {
    return computes?.find((compute) => compute?.name === name) || {};
  };

  return (
    <CommonDataContext.Provider
      value={{
        findCompute: findCompute,
        freshdeskStatuses: freshdeskStatuses,
        getStatusUsingValue: getStatusUsingValue,
      }}
    >
      {children}
    </CommonDataContext.Provider>
  );
};
