import { ApexOptions } from 'apexcharts';
import _merge from 'lodash/merge';

enum AvailabilityChartLabel {
  Available = 'Available',
  Unavailable = 'Not Available',
}

export const getCustomChartOptionForAvailabilityChart = (): ApexOptions => ({
  chart: {
    toolbar: {
      show: false,
    },
  },
  legend: {
    showForSingleSeries: true,
    onItemClick: {
      toggleDataSeries: true,
    },
    position: 'bottom',
    horizontalAlign: 'left',
    markers: {
      offsetX: -5,
      offsetY: 2,
    },
  },
  stroke: {
    show: true,
    width: 2,
  },
  yaxis: {
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: true,
    },
    crosshairs: {
      show: false,
    },
    showAlways: false,
    tickAmount: 1,
    labels: {
      formatter: (val: number) => '',
    },
  },
});
