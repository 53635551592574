import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import QRCodeCanvas from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import {
  useSetUserLoginInfoContext,
  useUserLoginInfoContext,
} from './UserLoginInfo/UserLoginInfoContext';
import { Auth } from 'aws-amplify';
import { mfaSetupAuth } from './mfaSetupAuth';
import LoginContainer from 'common/custom-components/LoginContainer';
import { LoadingButton } from '@mui/lab';

export const MfaSetup = () => {
  const [otp, setOtp] = useState('');

  const loginInfo = useUserLoginInfoContext();
  const setLoginInfo = useSetUserLoginInfoContext();
  const navigate = useNavigate();
  const [verifyLoading, setVerifyLoading] = useState(false);

  const onSubmit = () =>
    mfaSetupAuth(loginInfo, otp, setLoginInfo, navigate, setVerifyLoading);
  const [code, setCode] = useState('');

  useEffect(() => {
    if (Object.keys(loginInfo)?.length !== 0) {
      if (loginInfo?.signInUserSession?.accessToken?.jwtToken) {
        navigate('/dashboard');
      }
    } else {
      navigate('/login');
    }
  }, [loginInfo]);

  useEffect(() => {
    const x0 = async () => {
      const a = await Auth.setupTOTP(loginInfo).then((res) => {
        setCode(res);
        return res;
      });
    };
    x0();
  }, []);

  const username = loginInfo?.username;
  const issuer = 'TessellOps';
  const str = `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=${issuer}`;
  return (
    <div className="auth-page-content">
      <LoginContainer>
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: '24px',
          }}
        >
          <Typography variant="subtitle1">
            Please scan using authenticator app
          </Typography>
          <QRCodeCanvas value={str} />
          <Stack
            sx={{
              mt: '16px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {code}
          </Stack>
          <TessellInput
            label="Enter the OTP (Authenticator app)"
            value={otp}
            variant="outlined"
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                onSubmit();
                ev.preventDefault();
              }
            }}
            onChange={(e) => setOtp(e.target.value)}
            sx={{
              width: '300px',
              mb: '20px',
            }}
          />
          <LoadingButton
            sx={{ minWidth: '100px', borderRadius: '10px' }}
            variant="contained"
            onClick={onSubmit}
            loading={verifyLoading}
            disabled={!otp.length}
          >
            Verify
          </LoadingButton>
        </Stack>
      </LoginContainer>
    </div>
  );
};
