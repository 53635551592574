import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { useEffect } from 'react';
import { adaptEventsResponse } from './adaptEventsResponse';

type DBEventProps = {
  serviceId: string;
  tenantDomain: string;
};

export const useDBEvents = (props: DBEventProps) => {
  const { serviceId, tenantDomain } = props;

  const {
    response: events,
    isLoading: eventsIsLoading,
    postData: getEvents,
  } = useLazyGetCallWithURL(adaptEventsResponse);

  useEffect(() => {
    if (serviceId && tenantDomain)
      getEvents(
        `${URLS.getServiceEvents}${serviceId}/events?tenant-domain=${tenantDomain}`,
      );
  }, [serviceId, tenantDomain]);

  return {
    events,
    eventsIsLoading,
  };
};
