import { Stack, Tooltip, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
const STATUS_COLORS = {
  RED: styles.color.red,
  GREEN: styles.color.green,
  ORANGE: styles.color.orange,
  YELLOW: styles.color.yellow,
  BLUE: styles.color.lightShade,
};

const DEFAULT_VALUE = '-';

export const HealthStatus = (props) => {
  const { health } = props;
  return (
    <Stack direction="row" alignItems="center">
      <Tooltip
        title={
          <Stack direction="column">
            <Typography m={1}>{`Score: ${
              health?.score || DEFAULT_VALUE
            }`}</Typography>

            <Stack direction="row">
              <Stack direction="column" alignItems="flex-start" p={3}>
                <Typography m={1}>Alert Count</Typography>
                {health?.alertCount &&
                  Object.keys(health?.alertCount)?.map((key) => (
                    <Typography m={1}>
                      {`${key}: ${health?.alertCount[key] || DEFAULT_VALUE}`}
                    </Typography>
                  ))}
              </Stack>
              <Stack direction="column" alignItems="flex-start" p={3}>
                <Typography m={1}>Ticket Count</Typography>
                {health?.ticketCount &&
                  Object.keys(health?.ticketCount)?.map((key) => (
                    <Typography m={1}>
                      {`${key}: ${health?.ticketCount[key] || DEFAULT_VALUE}`}
                    </Typography>
                  ))}
              </Stack>
            </Stack>
          </Stack>
        }
      >
        <Stack
          mr={2}
          sx={{
            borderRadius: 5,
            width: 20,
            height: 20,
            backgroundColor:
              STATUS_COLORS[health?.status?.toUpperCase() || 'GREEN'],
          }}
        />
      </Tooltip>
    </Stack>
  );
};
