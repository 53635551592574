import { Stack, Typography } from '@mui/material';
import { useAdaptCostDataForChart } from '../Hooks/useAdaptCostDataForChart';
import { useAdaptCostArrayToDayWiseTableData } from '../adapters';
import { costLayout } from '../styles';
import CostBarChart from '../Charts/CostBarChart';
import CostLineChart from '../Charts/CostLineChart';
import { DAY_WISE_COST_TABLE_TITLE } from '../constants';
import DayWiseCostTable from '../Charts/DayWiseCostTable';

type DayWiseChartAndTableProps = {
  costArray: any[];
  seriesAndHeadName: string;
  firstColmnTitle?: string;
};

const DayWiseChartAndTable = (props: DayWiseChartAndTableProps) => {
  const { costArray = [], seriesAndHeadName, firstColmnTitle = 'Name' } = props;
  const { keys, series } = useAdaptCostDataForChart({
    costDataArray: costArray,
    seriesName: seriesAndHeadName,
  });

  const { tableColumnTitles, tableRows } = useAdaptCostArrayToDayWiseTableData({
    costArray: costArray,
    headName: seriesAndHeadName,
  });
  return (
    <Stack spacing={2} pb={1}>
      <Stack spacing={1} sx={costLayout.multiLineChartContainer}>
        <Typography variant="h6">Cost Breakup</Typography>
        {series?.length === 1 ? (
          <CostBarChart keys={keys} series={series} />
        ) : (
          <CostLineChart keys={keys} series={series} />
        )}
      </Stack>
      <Stack spacing={2} sx={costLayout.dayWiseTableContainer}>
        <Typography variant="h6">{DAY_WISE_COST_TABLE_TITLE}</Typography>
        <DayWiseCostTable
          keys={tableColumnTitles}
          rows={tableRows}
          firstColumnTitle={firstColmnTitle}
        />
      </Stack>
    </Stack>
  );
};

export default DayWiseChartAndTable;
