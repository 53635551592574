import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import LogsListAndSearch from './LogsListAndSearch';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';
import LogsView from './LogsView';
import moment from 'moment';
import { useStyles } from './useLogStyles';

const fileDownload = require('js-file-download');
const JSZip = require('jszip');

const Logs = ({
  logFiles,
  isFilesLoading,
  entities,
  entityLabel,
  selectedEntity,
  setSelectedEntity,
  HeaderComponent,
  FilteredComponent,
  logViewerRef,
  streamLogs,
  isLogsLoading,
  currentLog,
  setCurrentLog,
  selectedLogs,
  setSelectedLogs,
  logText,
  setLogText,
  filesHeading,
  goLive,
  onScroll,
  isPollingEnabled,
  isPolling,
}: any) => {
  const classes = useStyles();

  const [fileSearch, setFileSearch] = useState('');
  const [hasLineNumbers, setHasLineNumbers] = useState({});
  const [logSearch, setLogSearch] = useState({});

  const filesToDisplay = useMemo(
    () =>
      logFiles.filter(
        (logFile) =>
          logFile.toLowerCase().indexOf(fileSearch.toLowerCase()) !== -1,
      ),
    [logFiles, fileSearch],
  );

  const removeFileFromViewList = (name) => {
    const currentIndex = selectedLogs.indexOf(name);
    const newChecked = [...selectedLogs];
    newChecked.splice(currentIndex, 1);
    setSelectedLogs(newChecked);
    if (newChecked.length) {
      setCurrentLog(newChecked[currentIndex === 0 ? 0 : currentIndex - 1]);
    } else {
      setCurrentLog(null);
    }
  };

  const addFileToViewList = (name) => {
    setSelectedLogs((prev) => [...prev, name]);
    setCurrentLog(name);
    streamLogs(name);
    setHasLineNumbers((prev) => ({ ...prev, [name]: true }));
  };

  useEffect(() => {
    if (logFiles?.length) {
      setLogSearch({});
      setFileSearch('');
      setHasLineNumbers({});
      setLogText({});
      setSelectedLogs([]);
      addFileToViewList(logFiles[0]);
    }
  }, [logFiles]);

  const handleFileSelect = (event) => {
    const fileName = event.target.name;
    if (event.target.checked) {
      addFileToViewList(fileName);
    } else {
      removeFileFromViewList(fileName);
    }
  };

  const onChangeHandlerLogsSearching = useCallback((event, log) => {
    const searchText = event.target.value;
    setLogSearch((prev) => ({
      ...prev,
      [log]: searchText,
    }));
  }, []);

  const onClearHandlerLogsSearching = useCallback((log) => {
    setLogSearch((prev) => ({
      ...prev,
      [log]: '',
    }));
  }, []);

  const toggleLineNumbers = (name) => {
    setHasLineNumbers((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  async function downloadFile() {
    const filename = `tessell-logs-${moment().format(
      'YYYY_MM_DDD_HH_mm_ss',
    )}.zip`;

    const zip = new JSZip();
    Object.keys(logText).forEach((fn) => {
      if (selectedLogs.includes(fn)) {
        zip.file(fn, logText[fn].text);
      }
    });
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      fileDownload(blob, filename);
    });
  }

  return (
    <LoadingOverlay isLoading={isFilesLoading}>
      <Box display="flex" flexDirection="column" height="100%">
        <Stack direction="row" height="100%">
          <LogsListAndSearch
            fileSearch={fileSearch}
            setFileSearch={setFileSearch}
            filesToDisplay={filesToDisplay}
            selectedLogs={selectedLogs}
            handleFileSelect={handleFileSelect}
            classes={classes}
            filesHeading={filesHeading}
          />
          <LogsView
            selectedLogs={selectedLogs}
            logFiles={logFiles}
            currentLog={currentLog}
            setCurrentLog={setCurrentLog}
            removeFileFromViewList={removeFileFromViewList}
            isLogsLoading={isLogsLoading}
            logViewerRef={logViewerRef}
            logText={logText}
            hasLineNumbers={hasLineNumbers}
            search={logSearch}
            toggleLineNumbers={toggleLineNumbers}
            entities={entities}
            entityLabel={entityLabel}
            selectedEntity={selectedEntity}
            setSelectedEntity={setSelectedEntity}
            downloadFile={downloadFile}
            goLive={goLive}
            onScroll={onScroll}
            isPollingEnabled={isPollingEnabled}
            isPolling={isPolling}
            // * ---- components ---
            HeaderComponent={HeaderComponent}
            FilteredComponent={FilteredComponent}
            // * helper fns
            onChangeHandlerLogsSearching={onChangeHandlerLogsSearching}
            onClearHandlerLogsSearching={onClearHandlerLogsSearching}
            classes={classes}
          />
        </Stack>
      </Box>
    </LoadingOverlay>
  );
};

export default Logs;
