/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function OracleSmallIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} width="64" height="41" viewBox="0 0 64 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.1783 40.3568C8.96815 40.3568 0 31.3886 0 20.1784C0 8.96817 8.96815 0 20.1783 0H43.8217C55.0318 0 64 8.96817 64 20.1784C64 31.3886 55.0318 40.3568 43.8217 40.3568H20.1783ZM43.2102 33.223C50.5478 33.223 56.2548 27.3121 56.2548 20.1784C56.2548 12.8408 50.344 7.13377 43.2102 7.13377H20.7898C13.4522 7.13377 7.74522 13.0446 7.74522 20.1784C7.74522 27.3121 13.6561 33.223 20.7898 33.223H43.2102Z" fill="#C74634" />
    </svg>

  );
}
