import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Typography, Box, Stack } from '@mui/material';
import 'react-calendar-timeline/lib/Timeline.css';
import './pitr.scss';
import ContinuousView from './continuousView';
import AppUtil from 'tools/AppUtil';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';
import { useFetchCloudLocations } from 'common/cloud-region-location/lib/component/useFetchCloudLocations';
import { styles } from 'assets/scss/style-templates';
import { AvailabilityMachineRowType } from 'customers/tenants-common-view/lib/schema/AvailabilityMachine.types';
import Cache from 'common/cache/lib/utils/Cache';
import { URLS } from 'constants/URL';
import { GetInstanceStatus } from '../../../db-services-detailed-view/utils';
import { useLazyGetCall } from 'common/api/useApiCall';
import { DF_HUMAN } from 'helpers/dateFormats';

type DataFlixProptype = {
  avMachine?: AvailabilityMachineRowType;
};

export default function DataFlix(props: DataFlixProptype) {
  const { avMachine: avMachineData } = props;

  const { regionMap } = useFetchCloudLocations();

  const [avMachine, setAvMachine] = useState(
    avMachineData || JSON.parse(Cache.get('avMachine')),
  );
  const { pgSource, tenantId, id: dmmId } = avMachine;

  const url = `${URLS.PITR}${pgSource}&dmm-id=${dmmId}&tenant-id=${tenantId}`;
  const { response, postData, isLoading } = useLazyGetCall(url);

  const [pitr, setPitr] = useState({
    dataRegions: [],
    cloudRegions: [],
    selectedTime: null,
    selectedRegion: null,
    selectedDatabases: [],
  });

  useEffect(() => {
    if (response?.regions?.length) {
      adaptPitr(response);
    }
  }, [response]);

  const adaptPitr = (availabilityCatalog) => {
    let selectedRegion = null;
    let selectedTime = null;
    let dataRegions = [];
    const cloudRegions = [];
    const cloudRegionsMap = {};

    availabilityCatalog?.regions.forEach((region) => {
      availabilityCatalog?.availabilityCatalog[region]?.forEach((data) => {
        data?.pitr?.timeRanges?.forEach((timeRange) => {
          const { fromTime, toTime } = timeRange;
          const from = moment(fromTime);
          const to = moment(toTime);
          const cloudRegion = `${regionMap[region] || region}`;
          if (!cloudRegionsMap[cloudRegion]) {
            cloudRegions.push({
              id: cloudRegion,
              type: 'region',
              title: <Box color={styles.color.darkShade}>{cloudRegion}</Box>,
              itemProps: {
                style: {
                  background: 'red',
                  border: '1px solid red',
                },
              },
            });
            cloudRegionsMap[cloudRegion] = {
              id: cloudRegion,
              type: 'region',
              title: <Box color={styles.color.darkShade}>{cloudRegion}</Box>,
            };
          }
          dataRegions.push({
            id: `${from}-${to}-${cloudRegion}-Continuous`,
            group: cloudRegion,
            region,
            cloudRegion,
            start: from?.valueOf(),
            end: to?.valueOf(),
            title: `${from?.format(DF_HUMAN)} - ${to?.format(DF_HUMAN)}`,
            itemProps: {
              className: 'continuous-region',
            },
          });
        });
        data?.pitr?.residueTimeRange?.forEach((timeRange) => {
          const { fromTime, toTime } = timeRange;
          const from = moment(fromTime);
          const to = moment(toTime);

          const cloudRegion = `${regionMap[region] || region}`;
          if (!cloudRegionsMap[cloudRegion]) {
            cloudRegions.push({
              id: cloudRegion,
              type: 'region',
              title: (
                <Box color={styles.color.monotoneDarkLight}>{cloudRegion}</Box>
              ),
              itemProps: {
                style: {
                  background: 'red',
                  border: '1px solid red',
                },
              },
            });
            cloudRegionsMap[cloudRegion] = {
              id: cloudRegion,
              type: 'region',
              title: (
                <Box color={styles.color.monotoneDarkLight}>{cloudRegion}</Box>
              ),
            };
          }
          dataRegions.push({
            id: `${from}-${to}-${cloudRegion}-Continuous`,
            group: cloudRegion,
            region,
            cloudRegion,
            start: from?.valueOf(),
            end: to?.valueOf(),
            title: `${from?.format(DF_HUMAN)} - ${to?.format(DF_HUMAN)}`,
            itemProps: {
              className: 'continuous-region-log',
            },
          });
        });
      });
    });
    cloudRegions.push({
      id: 'extra',
      height: 35,
      title: '',
      itemProps: {
        style: {
          background: 'red',
          border: '1px solid red',
        },
      },
    });

    dataRegions = dataRegions?.sort((a, b) => {
      const keyA = new Date(a?.start);
      const keyB = new Date(b?.start);
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

    if (!selectedRegion && !selectedTime && dataRegions.length) {
      // eslint-disable-next-line prefer-destructuring
      selectedRegion = dataRegions[0];
      selectedTime = selectedRegion.end - 30 * 1000;
    }

    setPitr((prev) => ({
      ...prev,
      dataRegions,
      cloudRegions,
      selectedRegion,
      selectedTime,
    }));
  };

  useEffect(() => {
    postData();
  }, []);

  useEffect(() => {
    if (avMachineData) {
      setAvMachine({
        id: avMachineData?.id,
        name: avMachineData?.name,
        engine: avMachineData?.engineType,
      });
    }
  }, [avMachineData]);

  return (
    <LoadingOverlay isLoading={isLoading}>
      {!isLoading && (
        <Stack height="100%" p="">
          <Stack
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Stack>
              {avMachine.name && (
                <Typography
                  variant="h5"
                  gutterBottom
                  mb={0}
                  pb={0}
                  sx={{ fontSize: 30, fontWeight: 400 }}
                >
                  {response?.dmmName || avMachine.name}
                  {response?.dmmStatus && (
                    <GetInstanceStatus status={response?.dmmStatus} />
                  )}
                </Typography>
              )}
            </Stack>
            <Stack pr={10}>
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={1}
              >
                <Box
                  mr={1}
                  sx={{
                    borderRadius: '50%',
                    width: 14,
                    height: 14,
                    backgroundColor: '#e8f5ff',
                  }}
                />
                PITR Log
              </Stack>
              <Stack display="flex" flexDirection="row" alignItems="center">
                <Box
                  mr={1}
                  sx={{
                    borderRadius: '50%',
                    width: 14,
                    height: 14,
                    backgroundColor: styles.color.warn,
                  }}
                />
                Residue Log
              </Stack>
            </Stack>
          </Stack>

          <ContinuousView
            // isDataFlixRestore={isDataFlixRestore}
            avMachine={avMachine}
            state={pitr}
            setData={(obj) =>
              setPitr((prev) => ({
                ...prev,
                ...obj,
              }))
            }
            // onRestoreClickNext={onClickNextFromPITR}
            dmmId={avMachine?.id}
          />
        </Stack>
      )}
    </LoadingOverlay>
  );
}
