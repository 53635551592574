import { LoadingButton } from '@mui/lab';
import { Button, DialogContentText, MenuItem } from '@mui/material';
import { Stack } from '@mui/system';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import useStyles from 'common/useStyles';
import { DURATION_RANGE } from 'customers/db-services-detailed-view/constants/DurationList';
import { useState } from 'react';

type ExtendDurationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleConfirm: any;
};

const ExtendDurationModal = ({
  open,
  setOpen,
  handleConfirm,
}: ExtendDurationModalProps) => {
  const classes = useStyles();

  const [duration, setDuration] = useState(DURATION_RANGE[0].value);

  const DialogTitle = () => <>Extend duration of access</>;

  const DialogContent = () => (
    <DialogContentText id="alert-dialog-description">
      <TessellInput
        label="Duration"
        variant="outlined"
        size="small"
        name="duration"
        select
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
        options={DURATION_RANGE.map((v) => (
          <MenuItem key={v.label} value={v.value}>
            {v.label}
          </MenuItem>
        ))}
      />
    </DialogContentText>
  );

  const DialogActions = () => (
    <Stack width="100%" direction="row" justifyContent="flex-start" mt={2}>
      <Stack spacing={1} direction="row" justifyContent="flex-end" width="100%">
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
          size="small"
          sx={classes.roundedBtn}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={() => {
            handleConfirm(duration);
          }}
          color="primary"
          size="small"
          variant="contained"
          loading={false}
          disabled={!duration}
        >
          Extend
        </LoadingButton>
      </Stack>
    </Stack>
  );

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
    />
  );
};

export default ExtendDurationModal;
