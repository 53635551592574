import { Close, DownloadSharp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DialogContentText, Stack, Typography } from '@mui/material';
import { AlertIcon } from 'assets/icons-v2';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import useStyles from 'common/useStyles';

type VMConnectionDetailsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  downloadGenieConfig: any;
};

const VMConnectionDetailsModal = ({
  open,
  setOpen,
  downloadGenieConfig,
}: VMConnectionDetailsModalProps) => {
  const classes = useStyles();

  const DialogTitle = () => (
    <Stack
      direction={'row'}
      justifyContent="space-between"
      alignItems={'center'}
      width={'400px'}
    >
      Connection details for VM
      <Close
        sx={{ cursor: 'pointer', color: '#666666' }}
        onClick={() => setOpen(false)}
      />
    </Stack>
  );

  const DialogContent = () => (
    <DialogContentText width={'400px'}>
      <Typography pb={1}>
        Download the access key and follow the instructions to connect to the
        VM.
      </Typography>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
          backgroundColor: '#FEF2D8',
          p: 1,
          mb: 2,
          borderRadius: '4px',
        }}
      >
        <Stack>
          <AlertIcon />
        </Stack>
        <Stack>
          Accessing the service VM is a highly risky operation & can lead to
          serious consequences.
        </Stack>
      </Stack>
      <LoadingButton
        onClick={downloadGenieConfig}
        color="primary"
        size="small"
        style={{ marginRight: '10px' }}
        loading={false}
        fullWidth
        variant="outlined"
        sx={{ borderRadius: '4px' }}
      >
        <DownloadSharp />
        Download Genie Credentials
      </LoadingButton>
    </DialogContentText>
  );

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
    />
  );
};

export default VMConnectionDetailsModal;
