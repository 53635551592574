import {
  AdaptedMetricType,
  MetricValue,
  ServiceMonitoringType,
  ServiceMonitoringTypeWithEntity,
} from '../monitoringTypes';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
export const BYTES_TO_GB = 1024 * 1024 * 1024;
export const BYTES_TO_MB = 1024 * 1024;
export const BYTES_TO_KB = 1024;

const getRandomArrayElement = (list: any[]): any =>
  list?.[Math.floor(Math.random() * list?.length)];

export type AdaptedMetrics = {
  availability: AdaptedMetricType[];
  dbMetrics: AdaptedMetricType[];
};

export const fetchMonitoringAdapter = (
  metricsRes: ServiceMonitoringType[] | any,
) => {
  const getNumber = (num: string | number): number =>
    typeof num === 'number' ? num : parseInt(num, 10) || 0;

  const getDecimal = (num: number): number =>
    parseFloat((Math.round(num * 100) / 100).toFixed(2));

  const getDiscUsage = (usage: number, maxByteValue: number): number => {
    let finalUsage = usage;
    if (maxByteValue <= BYTES_TO_KB) {
      finalUsage = usage;
    }
    if (maxByteValue > BYTES_TO_KB && maxByteValue <= BYTES_TO_MB) {
      finalUsage = usage / BYTES_TO_KB;
    }
    if (maxByteValue > BYTES_TO_MB && maxByteValue <= BYTES_TO_GB) {
      finalUsage = usage / BYTES_TO_MB;
    }
    if (maxByteValue > BYTES_TO_GB) {
      finalUsage = usage / BYTES_TO_GB;
    }

    if (finalUsage > 0.01) {
      return parseFloat(finalUsage.toFixed(2));
    }
    return parseFloat(finalUsage.toFixed(3));
  };

  const getMemoryAllocUsage = (num: number): number =>
    parseFloat((Math.round(num * 100) / 100000).toFixed(2));

  const getTimeStamp = (epoc: number): number => epoc * 1000;

  const adaptMetrics = (rawMetrics: MetricValue[]): MetricValue[] =>
    rawMetrics
      .map((m) => [getTimeStamp(m[0]), m[1]] as MetricValue)
      .sort((a, b) => {
        if (a[0] < b[0]) return -1;
        return 1;
      });
  const metricMap = (
    metricRes: ServiceMonitoringTypeWithEntity,
  ): AdaptedMetricType => {
    const { metrics, title, unit: graphUnit, entityId, entityType } = metricRes;

    const isBytesUnit = graphUnit?.toLowerCase()?.includes('bytes');

    const isPercentageUnit =
      graphUnit?.toLowerCase() === 'percent' ||
      graphUnit?.toLowerCase() === '%';

    const isAvailabilityGraph = title === 'Availability';
    let finalMinVal = 0;
    let finalMaxVal = 0;
    metrics?.forEach((metric, metricIndex) => {
      const {
        metricValues: rawMetrics = [],
        displayName,
        metric: additionalMetricInfo,
      } = metric;
      const metricValues = adaptMetrics(rawMetrics);
      const dataSet = metricValues.map((metricValue) => metricValue[1]);

      const minVal = Math.min(0, ...dataSet);
      const maxVal = Math.max(...dataSet);
      finalMinVal = Math.min(finalMinVal, minVal);
      finalMaxVal = Math.max(finalMaxVal, maxVal);
    });
    const adaptedMetrics = metrics?.map((metric, metricIndex) => {
      const {
        metricValues: rawMetrics = [],
        displayName,
        metric: additionalMetricInfo,
      } = metric;
      const metricValues = adaptMetrics(rawMetrics);
      const labelSet = metricValues.map((metricValue) => metricValue[0]);
      const dataSet = metricValues.map((metricValue) =>
        isBytesUnit
          ? getDiscUsage(metricValue[1], finalMaxVal)
          : metricValue[1],
      );

      const maxVal = Math.max(...dataSet);
      const minVal = Math.min(0, ...dataSet);

      return {
        labelSet,
        dataSet,
        maxVal,
        minVal,
        displayName,
        additionalMetricInfo,
        entityType,
      };
    });

    const getDiscUsageUnit = (maxByteValue: number): string => {
      if (maxByteValue <= BYTES_TO_KB) {
        return 'Bytes';
      }
      if (maxByteValue > BYTES_TO_KB && maxByteValue <= BYTES_TO_MB) {
        return 'KiB';
      }
      if (maxByteValue > BYTES_TO_MB && maxByteValue <= BYTES_TO_GB) {
        return 'MiB';
      }
      if (maxByteValue > BYTES_TO_GB) {
        return 'GiB';
      }
      return 'GiB';
    };

    const getGraphUnit = (unit: string): string => {
      if (unit?.toLowerCase()?.includes('bytes')) {
        return unit
          ?.toLowerCase()
          ?.replace('bytes', getDiscUsageUnit(finalMaxVal));
      }
      switch (unit?.toLowerCase()) {
        case '%':
          return 'Percent';
        default:
          return capitaliseFirstLetterInEachWord(unit);
      }
    };

    const unit = `${getGraphUnit(metricRes?.unit)}`;
    finalMinVal = isBytesUnit
      ? getDiscUsage(finalMinVal, finalMaxVal)
      : finalMinVal;
    finalMaxVal = isBytesUnit
      ? getDiscUsage(finalMaxVal, finalMaxVal)
      : finalMaxVal;

    return {
      id: entityId || title,
      title,
      dataSets: adaptedMetrics,
      unit,
      minVal: isPercentageUnit ? 0 : finalMinVal,
      maxVal: isPercentageUnit ? 100 : finalMaxVal,
      entityType,
      // fill: false,
    };
  };

  const monData: AdaptedMetrics = {
    availability: [] as AdaptedMetricType[],
    dbMetrics: [] as AdaptedMetricType[],
  };

  (metricsRes || [])?.forEach?.((metricRes) => {
    const prop = metricMap(metricRes);

    if (prop.title === 'Availability') {
      monData.availability.push(prop);
    } else {
      monData.dbMetrics.push(prop);
    }
  });

  return monData;
};
