import { useNavigate, useParams } from 'react-router-dom';

enum IAMTabType {
  Users = 'Users',
  Personas = 'Personas',
  Permissions = 'Permissions',
}

// Snake to Camel case
const RouteToIAMTabMap = {
  users: 'Users',
  personas: 'Personas',
  permissions: 'Permissions',
};

// Camel case to Snake case
const IAMTabToRouteMap = {
  Users: 'users',
  Personas: 'personas',
  Permissions: 'permissions',
};

export const useIAMTabView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { tabId = 'users' } = params;
  const tabValue = RouteToIAMTabMap[tabId] as IAMTabType;

  const onTabClick = (event: any, newValue: string) => {
    navigate(`/iam/${IAMTabToRouteMap[newValue]}`, {
      replace: true,
    });
  };

  return {
    IAMTabType,
    tabValue,
    onTabClick,
  };
};
