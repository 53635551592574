/**
 * The format is fontStyle_fontSize_fontWeight_lineHeight.
 * Example: fontStyles.24_100_32
 */

const fontStyles = {
  fs12_fw400: {
    fontSize: '12px',
    fontWeight: 400,
  },
  fs12_fw500: {
    fontSize: '12px',
    fontWeight: 500,
  },
  fs12_fw600: {
    fontSize: '12px',
    fontWeight: 600,
  },
  fs14_fw400: {
    fontSize: '14px',
    fontWeight: 400,
  },
  fs14_fw500: {
    fontSize: '14px',
    fontWeight: 500,
  },
  fs14_fw600: {
    fontSize: '14px',
    fontWeight: 600,
  },
  fs14_fw700: {
    fontSize: '14px',
    fontWeight: 700,
  },
  fs16_fw400: {
    fontSize: '16px',
    fontWeight: 400,
  },
  fs16_fw600: {
    fontSize: '16px',
    fontWeight: 600,
  },
  fs16_fw700: {
    fontSize: '16px',
    fontWeight: 700,
  },
  fs18_fw400: {
    fontSize: '18px',
    fontWeight: 400,
  },
  fs18_fw600: {
    fontSize: '18px',
    fontWeight: 600,
  },
  fs18_fw700: {
    fontSize: '18px',
    fontWeight: 700,
  },
  fs20_fw400: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  fs20_fw600: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  fs20_fw700: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  fs24_fw400: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '30px',
  },
  fs24_fw700: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '30px',
  },
  fs30_fw400: {
    fontSize: '30px',
    fontWeight: 400,
    lineHeight: '45px',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
};

export default fontStyles;
