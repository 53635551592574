import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
} from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { JiraInput } from 'customers/ticket/JiraInput';
import { Priorities } from '../../utils/constants';
import { useUpdateTicket } from './useUpdateTicket';

export const TicketUpdateModal = ({
  show,
  onClose,
  onSuccess,
  state,
  isSubmitting,
  statusOptions,
  selectedTicket,
  ticketAgents = {},
}) => {
  const {
    priority,
    status,
    subject,
    responderId,
    updateData,
    setUpdateData,
    postData,
  } = useUpdateTicket({ selectedTicket, onClose, onSuccess });
  return (
    <>
      <Dialog open={show} maxWidth="md" disableEscapeKeyDown>
        <DialogTitle>Update Ticket</DialogTitle>
        <DialogContent>
          <Stack spacing={1} width={250} pt={2} key={state?.status}>
            <TessellInput
              label="Subject"
              width="100%"
              value={updateData?.subject || subject}
              onChange={(e) => {
                setUpdateData((prev) => ({ ...prev, subject: e.target.value }));
              }}
            />
            <TessellInput
              select
              label="Priority"
              width="100%"
              value={updateData?.priority || priority}
              onChange={(e) => {
                setUpdateData((prev) => ({
                  ...prev,
                  priority: e.target.value,
                }));
              }}
              options={Priorities?.map(({ value: v, label }) => (
                <MenuItem key={v} value={v}>
                  {label}
                </MenuItem>
              ))}
            />
            <JiraInput
              label="Status"
              key="status"
              values={statusOptions}
              value={state?.status}
              onChange={(_val) => {
                setUpdateData((prev) => ({
                  ...prev,
                  status: _val,
                }));
              }}
            />
            <TessellInput
              select
              label="Responder"
              width="100%"
              value={updateData?.responderId}
              onChange={(e) => {
                setUpdateData((prev) => ({
                  ...prev,
                  responderId: parseInt(e.target.value),
                }));
              }}
              options={Object.keys(ticketAgents)?.map((id) => (
                <MenuItem key={id} value={id}>
                  {ticketAgents?.[id]?.contact?.name || ''}
                </MenuItem>
              ))}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setUpdateData({});
              onClose();
            }}
            size="large"
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => postData(JSON.stringify(updateData))}
            size="large"
            loading={isSubmitting}
            disabled={!Object.keys(updateData)?.length}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
