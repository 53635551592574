import { useGetCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { createParams } from 'helpers/createParams';
import { useMemo, useState } from 'react';
import { adaptSLATimingResponse } from './adaptSLATimingResponse';

type AMSummaryProps = {
  amId: string;
  pgSource: string;
};

export const useAMSummary = (props: AMSummaryProps) => {
  const { amId, pgSource } = props;
  const [expanded, setExpanded] = useState(true);

  const getSLAParams = useMemo(() => {
    return {
      'availability-machine-id': amId,
      'tenant-domain': pgSource,
    };
  }, [pgSource, amId]);

  const { response: slaTimingResponse, isLoading: slaTimingIsLoading } =
    useGetCall(
      `${URLS.getSLATiming}${createParams(getSLAParams)}`,
      adaptSLATimingResponse,
    );
  return {
    expanded,
    setExpanded,
    slaTimingResponse,
    slaTimingIsLoading,
  };
};
