import { Chip, Grid } from '@mui/material';

type ChipGridProps = {
  array: any[];
  label: string;
  fallback?: string;
};

const ChipGrid = (props: ChipGridProps) => {
  const { array, label, fallback } = props;

  return array?.length ? (
    <Grid container rowSpacing={1} columnSpacing={1}>
      {array?.map((item) => (
        <Grid item>
          <Chip
            label={item?.[label] || item?.[fallback] || ''}
            color={item?.color}
          />
        </Grid>
      ))}
    </Grid>
  ) : (
    <></>
  );
};

export default ChipGrid;
