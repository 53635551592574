import { Checkbox, FormControlLabel, MenuItem, Stack } from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import {
  DEFAULT_ROWS_COUNT,
  ROWS_FOR_OBJECT_COUNT,
  nameLabelTypeMap,
} from './constants';
import { useUpdateForm } from './useUpdateForm';
import { getObjectFromString, getObjectValue } from './utils';
import { EMPTY_STRING } from 'constants/appConstants';
import { isBoolean } from 'lodash';
import { TextWithInfoTooltip } from '../custom-components/lib/components/TextWithInfoTooltip';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import { TessellMultiInputWithAdd } from '../custom-components/lib/components/TessellMultiInputWithAdd';

const updateFormStyles = {
  container: { width: '100%', justifyContent: 'flex-start', mt: 2 },
};

type UpdateFormType = {
  payload: any;
  setPayLoad: Function;
  updateList: string[];
  data: any;
  step: number;
  dropDownOptions?: any;
};

const showComponent = (value) => {
  return value === null || value === 'null' || value === '[]';
};

const UpdateForm = (props: UpdateFormType) => {
  const {
    data,
    updateList = [],
    setPayLoad,
    step,
    payload,
    dropDownOptions,
  } = props;
  const { oldData, handleChange } = useUpdateForm({
    data,
    updateList,
    setPayLoad,
    payload,
  });

  const TypeToComponent = (type, i) => {
    switch (type) {
      case 'boolean':
        return isBoolean(oldData[i][1]) ? (
          <FormControlLabel
            label={
              nameLabelTypeMap[oldData[i][0]]?.description ? (
                <TextWithInfoTooltip
                  text={nameLabelTypeMap[oldData[i][0]]?.label}
                  tooltipContent={nameLabelTypeMap[oldData[i][0]]?.description}
                />
              ) : (
                nameLabelTypeMap[oldData[i][0]]?.label || EMPTY_STRING
              )
            }
            control={
              <Checkbox
                color="primary"
                checked={oldData[i][1]}
                onChange={() => {
                  handleChange(!oldData[i][1], i);
                }}
                disabled={step === 0}
              />
            }
          />
        ) : (
          <></>
        );

      case 'select': {
        return (
          <Stack>
            {oldData[i][1]?.length ? (
              <TessellInput
                label={nameLabelTypeMap[oldData[i][0]]?.label || EMPTY_STRING}
                variant="outlined"
                size="small"
                select
                disabled={step === 0}
                value={oldData[i][1]}
                onChange={(e) => handleChange(e.target.value, i)}
                options={nameLabelTypeMap?.[oldData[i][0]]?.options?.map(
                  (v) => (
                    <MenuItem key={v.value} value={v.value}>
                      {v.name}
                    </MenuItem>
                  ),
                )}
              />
            ) : (
              <></>
            )}
          </Stack>
        );
      }

      case 'multiSelect': {
        return (
          (showComponent(oldData[i][1]) ||
            getObjectFromString(oldData[i][1] || '[]')) && (
            <Stack>
              <TessellAutocomplete
                label={nameLabelTypeMap[oldData[i][0]]?.label || EMPTY_STRING}
                variant="outlined"
                size="small"
                multiple
                disabled={step === 0}
                default={oldData[i][1]}
                value={oldData[i][1] || []}
                onChange={(val) => {
                  handleChange(val, i);
                }}
                options={
                  dropDownOptions?.[nameLabelTypeMap[oldData[i][0]]?.label] ||
                  []
                }
              />
            </Stack>
          )
        );
      }

      case 'multiInputWithAdd': {
        return (
          (showComponent(oldData[i][1]) ||
            getObjectFromString(oldData[i][1] || '[]')) && (
            <Stack>
              <TessellMultiInputWithAdd
                label={nameLabelTypeMap[oldData[i][0]]?.label || EMPTY_STRING}
                stringArray={getObjectFromString(oldData[i][1]) || []}
                onChange={(updatedArray) => {
                  if (updatedArray) {
                    handleChange(updatedArray, i);
                  }
                }}
                disabled={step === 0}
                disableAdding={(str) => {
                  return !(str.includes('@') && str.includes('.'));
                }}
              />
            </Stack>
          )
        );
      }

      default:
        return (
          <TessellInput
            value={oldData[i][1] || EMPTY_STRING}
            label={nameLabelTypeMap[oldData[i][0]]?.label || EMPTY_STRING}
            variant="outlined"
            multiline
            rows={
              typeof getObjectValue(
                data,
                updateList[i].split('.'),
                'object',
              ) === 'object'
                ? ROWS_FOR_OBJECT_COUNT
                : DEFAULT_ROWS_COUNT
            }
            width="100%"
            size="small"
            disabled={step === 0}
            onChange={(e) => {
              handleChange(e.target.value, i);
            }}
          />
        );
    }
  };

  return (
    <Stack direction="column" spacing={1} sx={updateFormStyles.container}>
      {oldData?.map((k, i) => {
        return nameLabelTypeMap[oldData[i][0]] ? (
          <Stack direction="column">
            {TypeToComponent(nameLabelTypeMap[oldData[i][0]].type, i)}
          </Stack>
        ) : (
          ''
        );
      })}
    </Stack>
  );
};

export default UpdateForm;
