import { styles } from 'assets/scss/style-templates';
import ReactApexChart from 'react-apexcharts';
import { formatValueToCost } from '../adapters';

type CostBarChartProps = {
  keys: string[];
  series: any[];
};

const CostBarChart = (props: CostBarChartProps) => {
  const { keys = [], series = [] } = props;
  return (
    <ReactApexChart
      options={{
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: ['#4BA6E3'],
        tooltip: {
          y: {
            formatter: function (val) {
              return ` $${val}`;
            },
          },
        },
        xaxis: {
          categories: keys || [],
          labels: {
            rotate: 0,
            formatter(val) {
              return val;
            },
          },
          tickAmount: 5,
        },
        yaxis: {
          labels: {
            formatter(val) {
              return formatValueToCost(val);
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'top',
          horizontalAlign: 'right',
        },
        grid: {
          show: true,
          borderColor: styles.color.greyBackground2,
          strokeDashArray: 5,

          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            borderRadiusApplication: 'end',
          },
        },
      }}
      series={series}
      type="bar"
    />
  );
};

export default CostBarChart;
