import { awsRegions, azureRegion } from './regions';

export const DeploymentOptionsFilter = [
  {
    name: 'All',
    value: 'All',
  },
  {
    name: 'Professional',
    value: 'PROFESSIONAL',
  },
  {
    name: 'Business',
    value: 'BUSINESS',
  },
  {
    name: 'VPT',
    value: 'VIRTUAL_PRIVATE_TESSELL',
  },
  {
    name: 'VPT @ Customer',
    value: 'VIRTUAL_PRIVATE_TESSELL_AT_CUSTOMER',
  },
];

export const BillingStatusFilter = [
  { name: 'All', value: 'All' },
  { name: 'Enabled', value: 'true' },
  { name: 'Disabled', value: 'false' },
];

export const GeolocationFilter = [
  { name: 'All', value: 'All' },
  ...awsRegions,
  ...azureRegion,
];

export const CloudsFilter = [
  { name: 'All', value: 'All' },
  { name: 'AWS', value: 'aws' },
  { name: 'AZURE', value: 'azure' },
];

export const TenantPhases = ['POC', 'LIVE'];

export const TenantPhaseFilter = [
  { name: 'All', value: 'All' },
  ...(TenantPhases?.map((_phase) => {
    return { name: _phase, value: _phase };
  }) || []),
];
