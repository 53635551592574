import { useGetCall } from 'common/api/useApiCall';
import { GROUPS_BASE_URL } from 'constants/URL';
import { adaptGroupsResponse } from '../../adaptGroupsResponse';

export const useGetGroups = () => {
  const {
    response: groups,
    isLoading: groupsIsLoading,
    postData: getGroups,
  } = useGetCall(GROUPS_BASE_URL, adaptGroupsResponse);

  return {
    groups,
    groupsIsLoading,
    getGroups,
  };
};
