import moment from 'moment';
import { getDefaultStartEndTime } from './getDefaultStartEndTime';

export const updateLatestTime = (
  rangeType,
  updateStartEndTime,
  retainDisplayDuration?: boolean,
) => {
  if (rangeType === 'Custom') {
    return;
  }

  const { initialStartTime, initialEndTime } = getDefaultStartEndTime();

  let st = initialStartTime.valueOf();
  let step = 30;
  const et = initialEndTime.valueOf();

  switch (rangeType) {
    case '1h':
      st = moment(initialEndTime).subtract(1, 'h').valueOf();
      step = 30;
      break;

    case '3h':
      st = moment(initialEndTime).subtract(3, 'h').valueOf();
      step = 90;
      break;

    case '6h':
      st = moment(initialEndTime).subtract(6, 'h').valueOf();
      step = 180;
      break;

    case '12h':
      st = moment(initialEndTime).subtract(12, 'h').valueOf();
      step = 360;
      break;

    case '24h':
      st = moment(initialEndTime).subtract(24, 'h').valueOf();
      step = 720;
      break;

    default:
  }

  updateStartEndTime(st, et, retainDisplayDuration, step);
};
