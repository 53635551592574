import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
export const adaptAM = (availabilityMachine) => {
  return {
    id: availabilityMachine?._id,
    dbID: availabilityMachine?.databaseSystemId,
    tenantId: availabilityMachine?.tenantId,
    name: availabilityMachine?.name,
    engineType: availabilityMachine?.engineType,
    cloud: availabilityMachine?.cloudAvailability?.[0]?.cloud,
    pgSource: availabilityMachine?.pgSource,
    region:
      availabilityMachine?.cloudAvailability?.[0]?.regions?.[0]?.region || '',
    sla:
      availabilityMachine?.rpoSla?.sla ||
      availabilityMachine?.metadata?.backupConfiguration?.backupSla,
    dataIngestionStatus: capitaliseFirstLetterInEachWord(
      availabilityMachine?.dataIngestionStatus,
    ),
    dateCreated:
      availabilityMachine?.dateCreated || availabilityMachine?.date_created,
    daps: availabilityMachine?.daps,
    availabilityMachine,
  };
};

export const adapterAM = (availabilityMachineArray) => {
  return availabilityMachineArray?.length
    ? availabilityMachineArray?.map((availabilityMachine) =>
        adaptAM(availabilityMachine),
      )
    : [];
};

export const adaptAvailabilityMachinesResponse = (response) => {
  return adapterAM(response?.response) || [];
};
