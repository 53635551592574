import { useLazyPostCall } from 'common/api/useApiCall';
import { GROUPS_BASE_URL, URLS } from 'constants/URL';
import { useContext, useEffect, useMemo, useState } from 'react';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useGetAllAndGroupUsers } from '../useGetAllAndGroupUsers';

type AddUsersProps = {
  group: any;
  open: boolean;
  setOpen: any;
};

export const useAddUsers = (props: AddUsersProps) => {
  const { group, open, setOpen } = props;
  const { addNotification } = useContext(NotificationContext);

  const [usersToAdd, setUsersToAdd] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const {
    allUsers,
    getAllUsersIsLoading,
    groupUsers,
    getPersonaUsersIsLoading,
  } = useGetAllAndGroupUsers({ group, open });

  const usersAvailableToAdd = useMemo(() => {
    return allUsers?.filter(
      (_user) =>
        !groupUsers?.find(
          (personaUser) => personaUser?.username === _user.username,
        ),
    );
  }, [allUsers, groupUsers]);

  const usersForAddConfirmation = useMemo(() => {
    return usersToAdd?.map((_username) => {
      return allUsers?.find((user) => {
        return user?.username === _username;
      });
    });
  }, [groupUsers, allUsers, usersToAdd]);

  const {
    response: addUserResponse,
    isLoading: addUserIsLoading,
    postData: addUserPostData,
    responseStatus: addUserResponseStatus,
  } = useLazyPostCall(`${GROUPS_BASE_URL}/${group?.name}/users`);

  const addUsers = () => {
    const payload = {
      groupName: group?.name,
      users: usersToAdd,
    };
    addUserPostData(JSON.stringify(payload, null, 2));
  };

  const isAnyLoading = useMemo(() => {
    return getAllUsersIsLoading || getPersonaUsersIsLoading || addUserIsLoading;
  }, [getAllUsersIsLoading, getPersonaUsersIsLoading, addUserIsLoading]);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (addUserResponse?.status === 'SUCCESSFUL') {
      addNotification({
        severity: 'success',
        message: `${addUserResponse?.message}`,
      });
      setUsersToAdd([]);
      onClose();
    } else if (addUserResponseStatus?.status) {
      addNotification({
        severity: 'error',
        message: addUserResponse?.message || 'Something went wrong',
      });
    }
  }, [addUserResponse]);

  return {
    onClose,
    usersToAdd,
    addUsers,
    groupUsers,
    setUsersToAdd,
    usersAvailableToAdd,
    isAnyLoading,
    confirmOpen,
    setConfirmOpen,
    usersForAddConfirmation
  };
};
