import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from 'common/custom-components/lib/components/BreadCrumb';
import { TenantCardsList } from '../TenantCardsList';
import {
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  BusinessRounded,
  GridViewRounded,
  TableRowsRounded,
} from '@mui/icons-material';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import { TenantsTableView } from 'customers/tenants-table-view/TenantsTableView';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import {
  BillingStatusFilter,
  GeolocationFilter,
  DeploymentOptionsFilter,
  CloudsFilter,
  TenantPhaseFilter,
} from 'customers/tenants-list-view/constants/tenantFilterConstants';
import { styles } from 'assets/scss/style-templates';
import Search from '@mui/icons-material/Search';
import { TenantListViewStyles } from './styles';
import { useTenantView } from './useTenantView';
import { FilterOptions, View } from './constants';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { useContext, useState } from 'react';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import TessellPagination from 'common/Pagination/TessellPagination';
import { useNavigate } from 'react-router-dom';
import ExportContainer from '../ExportData/ExportContainer';

const TenantsListView = () => {
  document.title = 'Tenants | Tessell - Ops App';

  const { hasPermission } = useContext(UserPermissionsContext);
  const navigate = useNavigate();
  const [downloadFileType, setDownloadFileType] = useState('csv');

  const {
    view,
    page,
    filters,
    searchQuery,
    tenantsRes,
    tenantsCount,
    tenantsPageCount,
    tenantsIsLoading,
    exportIsLoading,
    tenantsRegions,
    onSearchChange,
    handleViewChange,
    handleClickOnTenant,
    handleFilterChange,
    handlePaginationChange,
    handleExportClick,
  } = useTenantView();

  return (
    <>
      {
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Tenants" showBackButton={false} />
            <Row>
              <Col className="col-12">
                <Row>
                  <Col>
                    <Stack sx={TenantListViewStyles.toolBar}>
                      <Stack sx={TenantListViewStyles.filterContainer}>
                        <Stack sx={TenantListViewStyles.filterInnerBox}>
                          <TessellInput
                            filter
                            margin={'none'}
                            defaultValue={'All'}
                            label="Billing Status"
                            labelStyles={styles.filterLabel}
                            width="30%"
                            variant="standard"
                            size="small"
                            name="billingStatus"
                            select
                            mr={2}
                            value={filters?.[FilterOptions?.BillingStatus]}
                            onChange={(e) =>
                              handleFilterChange(
                                FilterOptions?.BillingStatus,
                                e.target.value,
                              )
                            }
                            options={BillingStatusFilter.map((v) => (
                              <MenuItem key={v.value} value={v.value}>
                                {v.name}
                              </MenuItem>
                            ))}
                          />
                          <TessellInput
                            filter
                            label="Cloud"
                            labelStyles={styles.filterLabel}
                            width="30%"
                            variant="standard"
                            size="small"
                            name="geoLocation"
                            select
                            mr={2}
                            value={filters?.[FilterOptions?.Cloud]}
                            onChange={(e) =>
                              handleFilterChange(
                                FilterOptions?.Cloud,
                                e.target.value,
                              )
                            }
                            options={CloudsFilter?.map((v) => (
                              <MenuItem key={v.name} value={v.value}>
                                {v.name}
                              </MenuItem>
                            ))}
                          />
                          <TessellInput
                            label="Geolocation"
                            labelStyles={styles.filterLabel}
                            width="30%"
                            variant="standard"
                            size="small"
                            name="geoLocation"
                            select
                            mr={2}
                            value={filters?.[FilterOptions?.Geolocation]}
                            onChange={(e) =>
                              handleFilterChange(
                                FilterOptions?.Geolocation,
                                e.target.value,
                              )
                            }
                            options={GeolocationFilter?.filter((r) =>
                              tenantsRegions?.includes(r.value),
                            ).map((v) => (
                              <MenuItem key={v.value} value={v.value}>
                                {v.name}
                              </MenuItem>
                            ))}
                          />
                          <TessellInput
                            filter
                            label="Deployment Option"
                            labelStyles={styles.filterLabel}
                            width="30%"
                            variant="standard"
                            size="small"
                            name="deploymentPlan"
                            select
                            mr={2}
                            value={filters?.[FilterOptions?.DeploymentType]}
                            onChange={(e) =>
                              handleFilterChange(
                                FilterOptions?.DeploymentType,
                                e.target.value,
                              )
                            }
                            options={DeploymentOptionsFilter.map((v) => (
                              <MenuItem key={v.value} value={v.value}>
                                {v.name}
                              </MenuItem>
                            ))}
                          />
                          <TessellInput
                            filter
                            label="Tenant Phase"
                            labelStyles={styles.filterLabel}
                            width="30%"
                            variant="standard"
                            size="small"
                            name="tenantPhase"
                            select
                            mr={2}
                            value={filters?.[FilterOptions?.TenantPhase]}
                            onChange={(e) =>
                              handleFilterChange(
                                FilterOptions?.TenantPhase,
                                e.target.value,
                              )
                            }
                            options={TenantPhaseFilter.map((v) => (
                              <MenuItem key={v.value} value={v.value}>
                                {v.name}
                              </MenuItem>
                            ))}
                          />
                          <TessellInput
                            spacing={0}
                            width="300px"
                            size="small"
                            label="Search"
                            labelStyles={styles.filterLabel}
                            variant="standard"
                            value={searchQuery}
                            onChange={(e) => {
                              onSearchChange(e?.target?.value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Search color="primary" fontSize="small" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </Stack>

                      <Stack direction={'row'} spacing={2} alignItems="center">
                        <ToggleButtonGroup
                          color="standard"
                          value={view}
                          exclusive
                          onChange={handleViewChange}
                          aria-label="Platform"
                          sx={TenantListViewStyles.toggleButtonGroup}
                        >
                          <ToggleButton
                            value={View.Card}
                            sx={TenantListViewStyles.toggleButtonContainer}
                          >
                            <>
                              <GridViewRounded
                                sx={TenantListViewStyles.toggleButtonIcon}
                              />
                              {capitaliseFirstLetterInEachWord(View.Card)}
                            </>
                          </ToggleButton>
                          <ToggleButton
                            value={View.Table}
                            sx={TenantListViewStyles.toggleButtonContainer}
                          >
                            <>
                              <TableRowsRounded
                                sx={TenantListViewStyles.toggleButtonIcon}
                              />
                              {capitaliseFirstLetterInEachWord(View.Table)}
                            </>
                          </ToggleButton>
                        </ToggleButtonGroup>
                        {hasPermission(PERMISSIONS?.ONBOARD_TENANT) && (
                          <Button
                            variant="contained"
                            sx={TenantListViewStyles.onBoardButton}
                            onClick={() => {
                              navigate('/tenants/onboarding');
                            }}
                          >
                            <Stack pr={1}>
                              <BusinessRounded />
                            </Stack>
                            Onboard Tenant
                          </Button>
                        )}
                        {hasPermission(PERMISSIONS.DOWNLOAD_TAB_ACCESS) && (
                          <ExportContainer
                            handleExportClick={handleExportClick}
                            isLoading={exportIsLoading}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Row>
                  {view === View.Card ? (
                    tenantsIsLoading ? (
                      <Stack sx={TenantListViewStyles.circularLoadingContainer}>
                        <CircularProgress variant="indeterminate" />
                      </Stack>
                    ) : tenantsRes?.length ? (
                      <TenantCardsList
                        tenants={tenantsRes}
                        handleClickOnTenant={handleClickOnTenant}
                      />
                    ) : (
                      <Stack sx={TenantListViewStyles.noDataContainer}>
                        No data
                      </Stack>
                    )
                  ) : (
                    <TenantsTableView
                      tenants={tenantsRes}
                      isLoading={tenantsIsLoading}
                      handleClickOnTenant={handleClickOnTenant}
                    />
                  )}
                </Row>
                <Row>
                  <TessellPagination
                    totalCount={tenantsCount}
                    pageCount={tenantsPageCount}
                    pageNo={page}
                    onChange={handlePaginationChange}
                  />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </>
  );
};

export default TenantsListView;
