import {
  MySQLSmallIcon,
  OracleSmallIcon,
  PostgresSmallIcon,
  SQLServerIconSmall,
} from 'assets/icons-v2';

interface GetEngineIconProps {
  engine: string;
  size?: number;
}
export const GetEngineIcon = (props: GetEngineIconProps) => {
  const { engine, size } = props;
  const customSize = size || 30;

  switch (engine) {
    case 'ORACLE':
      return <OracleSmallIcon width={customSize} height={customSize} />;
    case 'POSTGRESQL':
      return <PostgresSmallIcon width={customSize} height={customSize} />;
    case 'MYSQL':
      return <MySQLSmallIcon width={customSize} height={customSize} />;
    case 'SQLSERVER':
      // return  <SQLServerIconMicro height={40} />;
      return <SQLServerIconSmall zoom={1.5} />;
    default:
      return null;
  }
};
