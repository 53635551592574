import { Stack, Typography } from '@mui/material';
import { DISPLAY_NAME } from '../constants';
import { styles } from 'assets/scss/style-templates';
import { dashboardSummaryTableStyles } from './dashboardSummaryTableStyles';

type DashboardSummaryTableProps = {
  dataset: any;
};

const handleClick = (ref) => {
  var element = document.getElementById(ref);
  var headerOffset = 80;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

export const DashboardSummaryTable = (props: DashboardSummaryTableProps) => {
  const { dataset } = props;

  return (
    <Stack sx={dashboardSummaryTableStyles.container}>
      <Stack sx={dashboardSummaryTableStyles.listContainer}>
        <Typography variant="h4" color={'primary'}>
          Summary
        </Typography>
        {dataset?.total &&
          Object?.keys?.(dataset?.total)?.map((family) => {
            return dataset?.total[family] !== 0 ? (
              <Stack
                onClick={() => handleClick(family)}
                color={`${styles.color.monotoneDarkDark}`}
                sx={{
                  ...dashboardSummaryTableStyles.listItem,
                  '&:hover:not(.Mui-disabled)': {
                    cursor: 'pointer',
                    color: `${styles.color.darkBorder}`,
                  },
                }}
              >
                <Typography sx={dashboardSummaryTableStyles.listText}>
                  {DISPLAY_NAME[family] || family}
                </Typography>
                <Typography sx={dashboardSummaryTableStyles.listText}>
                  {dataset?.total?.[family] || '-'}
                </Typography>
              </Stack>
            ) : (
              <></>
            );
          })}
      </Stack>
    </Stack>
  );
};
