import { Stack } from '@mui/material';
import { colorsList } from 'global-components/Dashboard/Charts/Constants';
import ReactApexChart from 'react-apexcharts';
import { useServicesCost } from './Services/useServicesCost';
import { getRandomColor } from '../helpers';

type individualServiceCostObject = {
  data: [object];
  serviceName: string;
  totalCost: string;
};

export type servicesCostObject = {
  serviceCost: [individualServiceCostObject];
  tenantName: string;
};

export type ServicesCostChartProps = {
  servicesCost: servicesCostObject;
};

export const ServicesCostChart = (props: ServicesCostChartProps) => {
  const { servicesCost } = props;
  const { keys, series } = useServicesCost({ servicesCost });
  return (
    <Stack width={'1000px'}>
      <ReactApexChart
        options={{
          states: {
            active: {
              filter: {
                type: 'none',
              },
            },
          },
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
          },
          colors:
            series?.length > colorsList?.length
              ? series?.map(() => getRandomColor())
              : colorsList,
          tooltip: {
            y: {
              formatter: function (val) {
                return `$${val}`;
              },
            },
          },
          xaxis: {
            categories: keys || [],
            labels: {
              formatter(val) {
                return val;
              },
            },
            offsetX: 10,
            offsetY: 10,
          },
          yaxis: {
            title: {
              text: 'Cost in $',
              style: {
                fontSize: '14px',
              },
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            horizontalAlign: 'right',
            onItemClick: {
              toggleDataSeries: true,
            },
          },
        }}
        series={series}
        type="line"
      />
    </Stack>
  );
};
