/*
 * Copyright © Tessell Inc, 2021. All rights reserved.
 */

import React, { useMemo, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import APINotification from 'common/NotificationContainer/ApiNotification';
import Notification from 'common/NotificationContainer/Notification';
import { ENV_VARIABLES, ENV } from 'constants/env';
import WSConnect from 'tools/wsClient';
import Cache from 'common/cache/lib/utils/Cache';
import { useOnMessage } from './useOnMessage';

export type AddNotificationProps = {
  id?: string;
  successMsg?: string;
  errorMsg?: string;
  onSuccess?: any;
  onError?: any;
  apiResponse?: any;
  message?: string;
  severity?: string;
};

export const NotificationContext = React.createContext<{
  addNotification: (addNotificationProps: AddNotificationProps) => void;
  notificationCount: number;
  setRefreshCount: (notificationCount: number) => void;
  connection: any;
}>(null);

export const NotificationContainer = (props) => {
  const { children } = props;
  const [notification, setNotification] = useState<AddNotificationProps>();
  const [notificationCount, setNotificationCount] = useState<number>(
    Cache.get('notificationCount') !== undefined
      ? parseInt(Cache.get('notificationCount'))
      : 0,
  );
  const [refreshCount, setRefreshCount] = useState<number>(0);

  const addNotification = (_notification: AddNotificationProps) => {
    setNotification(_notification);
  };
  const onWSMessage = useOnMessage(setNotificationCount, addNotification);

  const onClose = () => {
    setNotification(null);
  };

  // websocket connection
  let connection;
  const handleLogin = (e) => {
    if (e.key === 'tessell-userName') {
      setRefreshCount(1);
    }
  };
  window.addEventListener('storage', handleLogin);

  useMemo(() => {
    if (
      refreshCount !== 0 &&
      Cache.get('userName')?.length &&
      Cache.get('userName') !== 'undefined'
    ) {
      connection?.close?.();
      connection = WSConnect(
        `${ENV_VARIABLES[ENV]?.WS_URL}?user-id=${Cache.get('userName')}`,
        onWSMessage,
      );
      setRefreshCount(0);
    }
  }, [refreshCount]);

  return (
    <NotificationContext.Provider
      value={{ addNotification, notificationCount, setRefreshCount, connection }}
    >
      <SnackbarProvider maxSnack={4}>
        {children}
        {notification?.apiResponse ? (
          <APINotification {...notification} onClose={onClose} />
        ) : (
          <Notification
            {...notification}
            notify={true}
            onClose={onClose}
            setNotify={(notify: boolean) => {
              if (notify === false) {
                onClose();
              }
            }}
          />
        )}
      </SnackbarProvider>
    </NotificationContext.Provider>
  );
};

export default NotificationContainer;
