import { MonitoringMetricType } from './monitoringTypes';

export const rangeType = {
  '1h': '1 hour',
  '3h': '3 hour',
  '6h': '6 hour',
  '12h': '12 hour',
  '24h': '24 hour',
  Custom: 'Custom',
};

export type MonitoringMetricTogglerType = MonitoringMetricType | 'ALL';

export type MetricToggleButtonOptionsType = {
  [key in MonitoringMetricTogglerType]: string;
};

export const MetricToggleButtonOptions: MetricToggleButtonOptionsType = {
  ALL: 'All',
  NODE: 'OS Metrics',
  DB: 'DB Metrics',
};
