import { useTheme } from '@mui/material/styles';
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@mui/material/colors';

const palettes = [
  blue,
  indigo,
  cyan,
  teal,
  lightBlue,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  red,
  pink,
  purple,
  deepPurple,
  brown,
  grey,
  blueGrey,
];

const tessellBlue = {
  dark: '#11567F',
  light: '#1E7EA1',
  hover: '#29B5E8',
};

export const graphColours = [
  '#11567F', // 1
  '#198754', // 4
  '#FFC107', // 7

  '#29B5E8', // 3
  '#20C997', // 6
  '#DC3545', // 9

  '#1E7EA1', // 2
  '#4DAC4A', // 5
  '#FD7E14', // 8
];

export const fontStyles = {
  fs12_fw400: {
    fontSize: '12px',
    fontWeight: 400,
  },
  fs12_fw500: {
    fontSize: '12px',
    fontWeight: 500,
  },
  fs12_fw600: {
    fontSize: '12px',
    fontWeight: 600,
  },
  fs14_fw400: {
    fontSize: '14px',
    fontWeight: 400,
  },
  fs14_fw500: {
    fontSize: '14px',
    fontWeight: 500,
  },
  fs14_fw600: {
    fontSize: '14px',
    fontWeight: 600,
  },
  fs14_fw700: {
    fontSize: '14px',
    fontWeight: 700,
  },
  fs16_fw400: {
    fontSize: '16px',
    fontWeight: 400,
  },
  fs16_fw600: {
    fontSize: '16px',
    fontWeight: 600,
  },
  fs16_fw700: {
    fontSize: '16px',
    fontWeight: 700,
  },
  fs18_fw400: {
    fontSize: '18px',
    fontWeight: 400,
  },
  fs18_fw600: {
    fontSize: '18px',
    fontWeight: 600,
  },
  fs18_fw700: {
    fontSize: '18px',
    fontWeight: 700,
  },
  fs20_fw400: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  fs20_fw600: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  fs20_fw700: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  fs24_fw400: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '30px',
  },
  fs24_fw700: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '30px',
  },
  fs30_fw400: {
    fontSize: '30px',
    fontWeight: 400,
    lineHeight: '45px',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
};

export const styles = {
  color: {
    darkShade: tessellBlue.dark,
    lightShade: tessellBlue.light,
    hover: tessellBlue.hover,
    darkBorder: '#004D73',
    lighterShade: 'rgba(30, 126, 161, 0.4)',
    lighterShade2: '#1E7EA1',
    header: '#11567F',
    baseBackground: '#FFFFFF',
    greyBackground4: '#F7F7F7',
    greyBackground: '#EEEEEE',
    greyBackground5: '#E6E6E6',
    greyBackground3: '#DDDDDD',
    greyBackground2: '#CCCCCC',
    greyBackground6: '#FAFAFA',
    primaryBackgroundLight: '#F4F9FA',
    monotoneLightLight: '#c0c0c0',
    monotoneLightDark: '#f0f0f0',
    monotoneDarkDark: '#666666',
    monotoneDarker: '#222222',
    monotoneDarkLight: '#999999',
    red: '#EC373C',
    warningBackground: '#FAE5E5',
    lighterRed: '#EC373CBD',
    green: '#4DAC4A',
    warn: '#F29132',
    textBoxLabel: '#08080D',
    lightBlueBackground: '#F2FAFE',
    oceanBlueSteel: '#21496B',
    winterSky: '#F4F9FA',
    black: '#000000',
  },
  contentBox: {
    boxSizing: 'border-box',
    borderRadius: '10px',
    boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25) !important',
    background: 'white',
  },
  textBoxLabel: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#08080D',
  },
  protection: [
    {
      name: 'Gold',
      darkShade: '#FFD900',
      lightShade: '#FAFAD2',
    },
    {
      name: 'Silver',
      darkShade: '#c0c0c0',
      lightShade: '#e8e8e8',
    },
    {
      name: 'Bronze',
      darkShade: '#cd7f32',
      lightShade: '#edbd97',
    },
    {
      name: 'Brass',
      darkShade: '#B5A642',
      lightShade: '#d9d19e',
    },
    {
      name: 'Default',
      darkShade: tessellBlue.dark,
      lightShade: tessellBlue.light,
    },
  ],
  palettes,
};

export const useStyles = () => {
  const theme = useTheme();
  return {
    intgTile: {
      width: 400,
      height: 200,
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      border: `1px solid ${styles.color.lighterShade}`,
      borderRadius: '10px',
      position: 'relative',
    },
    tagIcon: {
      backgroundColor: styles.color.greyBackground,
      color: styles.color.darkShade,
    },
    intgSkeleton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      background: 'rgba(151, 151, 151, 0.25)',
    },
    intgButton: {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    monSkeleton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderRadius: '10px',
      background: 'rgba(151, 151, 151, 0.25)',
    },
    monTile: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      minWidth: 400,
      maxWidth: 800,
      padding: '24px 24px 0 0',
      borderRadius: '8px',
      border: `1px solid ${styles.color.greyBackground5}`,
    },
    toggleButton: {
      '& .MuiToggleButton-root': {
        backgroundColor: '#FFFFFFAA !important',
        color: `${styles.color.textBoxLabel}`,
      },
      '& .MuiToggleButton-root.Mui-selected': {
        color: `${styles.color.baseBackground}`,
        backgroundColor: `${styles.color.darkShade} !important`,
      },
    },
    mainButton: {
      border: 'none',
      minWidth: '150px',
      '&:hover': {
        border: 'none',
        minWidth: '150px',
        cursor: 'pointer',
      },
    },
    resetOverviewBtn: {
      border: 'none',
      width: '100%',
      '&:hover': {
        border: 'none',
        width: '100%',
        cursor: 'pointer',
      },
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    headingText: {
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: 2,
      fontSize: '16px',
    },
    boldTextLight: {
      fontWeight: theme.typography.fontWeightMedium,
      color: styles.color.monotoneDarker,
    },
    filter: {
      width: 250,
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
    timePicker: {
      width: 430,
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
    contentBox: {
      boxSizing: 'border-box',
      borderRadius: '8px',
      boxShadow:
        ' 0px 1px 4px rgba(31, 41, 55, 0.06), 0px 1px 6px rgba(31, 41, 55, 0.1); !important',
      background: 'white',
      marginBottom: '20px',
    },
    accordionBox: {
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow:
        '0px 1px 4px rgba(31, 41, 55, 0.06), 0px 1px 6px rgba(31, 41, 55, 0.1) !important',
      marginBottom: '20px',
    },
    accordionSummaryBox: {
      backgroundColor: '#F7F7F7',
      borderRadius: '4px',
    },
    progress: {
      color: 'white',
    },
    innerContentBox: {
      borderRadius: '10px',
      border: `1px solid ${styles.color.lighterShade}`,
      background: 'white',
    },
    filesDisplayBox: {
      background: styles.color.greyBackground,
      height: '100%',
      minWidth: 250,
    },
    logsTab: {
      paddingLeft: '16px',
      paddingTop: '0px',
      paddingBottom: '0px',
      '&.Mui-selected': {
        backgroundColor: styles.color.greyBackground,
      },
    },
    nwInfoBox: {
      width: '50%',
      minWidth: '680px',
    },
    computeInfoBox: {
      width: '50%',
      minWidth: 400,
      maxWidth: 500,
      minHeight: 150,
      padding: theme.spacing(2),
      boxSizing: 'border-box',
      borderRadius: '10px',
    },
    instanceNwInfoBox: {
      minWidth: 600,
      maxWidth: 600,
      minHeight: 200,
      padding: theme.spacing(2),
      boxSizing: 'border-box',
      borderRadius: '10px',
    },
    compactBox: {
      padding: '5px 10px',
      fontSize: '14px',
    },
    chipsBox: {
      padding: '5px 10px',
      fontSize: '14px',
      border: '1px solid red',
    },

    dbInfo: {
      background: '#EEEEEE',
      borderRadius: '10px',
    },
    connectionInfoEdit: { width: '30%', justifyContent: 'flex-end' },
    lightText: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    engineLogo: {
      width: 100,
      height: 32,
    },
    backButton: {
      alignSelf: 'self-start',
    },
    linkIcon: {
      width: 48,
      marginRight: theme.spacing(1),
    },
    button: {
      marginRight: theme.spacing(1),
    },
    skeleton: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    deleteBox: {
      border: '1px solid #E1E5EA',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'border 200ms linear',
    },
    input: {
      marginTop: theme.spacing(2),
      width: 350,
    },
    deleteIcon: {
      '--icon-color': styles.color.red,
    },
    liveLogs: {
      color: styles.color.green,
    },
    icon: {
      color: styles.color.darkShade,
      cursor: 'pointer',
    },
    UpInstanceDot: {
      height: '12px',
      width: '12px',
      color: styles.color.green,
    },
    DownInstanceDot: {
      height: '12px',
      width: '12px',
      color: styles.color.red,
    },
    StoppedInstanceDot: {
      height: '12px',
      width: '12px',
      color: styles.color.warn,
    },
    statusBox: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '3px',
      paddingBottom: '3px',
      borderRadius: '4px',
      height: '24px',
    },
    engineQuickFilters: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '73px',
      height: '36px',
      background: '#FFFFFF',
      border: '1px solid #EEEEEE',
      borderRadius: '4px',
      marginLeft: 2,
      cursor: 'pointer',
    },
    statusQuickFilters: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '112px',
      height: '36px',
      background: '#FFFFFF',
      border: '1px solid #EEEEEE',
      borderRadius: '4px',
      marginLeft: 2,
      cursor: 'pointer',
    },
    cloudQuickFilters: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '73px',
      height: '36px',
      background: '#FFFFFF',
      border: '1px solid #EEEEEE',
      borderRadius: '4px',
      marginLeft: 2,
      cursor: 'pointer',
    },
  };
};

export const monitoringCustomStyles = {
  DbMonitoring: {
    mainComponent: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flex: '1 1 auto',
      height: '100% !important',
      overflow: 'scroll',
    },
    charts: {
      direction: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flex: '1 1 auto',
    },
    configuration: {
      p: 2,
      pb: 0,
      pt: 3,
      alignSelf: 'stretch',
    },
  },

  BasicAreaCharts: {
    showFullScreenChartIcon: {
      display: 'flex',
      position: 'absolute',
      right: '43px',
      top: '41px',
      zIndex: 10,
    },
    fullScreenRounded: {
      height: '24px',
      width: '24px',
      color: styles.color.monotoneDarkLight,
      cursor: 'pointer',
    },
    unitContainer: {
      justifyContent: 'center',
      width: '20px',
      textWrap: 'nowrap',
      ml: 1,
      mr: 0.5,
    },
    unitTextStyle: {
      transform: `rotate(-90deg)`,
      fontSize: '12px',
      fontWeight: 500,
      color: '#979797',
      whiteSpace: 'nowrap',
    },
    latestValues: {
      flexDirection: 'row',
      width: '100%',
      pt: '12px',
    },
    title: {
      ...fontStyles.fs16_fw600,
      color: styles.color.textBoxLabel,
    },
  },

  ZoomableLineChart: {
    memoChart: { flexDirection: 'row', flex: '1 1 auto' },
    showFullScreenChartButton: {
      display: 'flex',
      position: 'absolute',
      right: '43px',
      top: '41px',
      zIndex: 10,
    },
    fullScreenRounded: {
      height: '24px',
      width: '24px',
      color: styles.color.monotoneDarkLight,
      cursor: 'pointer',
    },
  },
};
