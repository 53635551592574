export const responseAdapter = (response) => {
  if (response) {
    return response?.response;
  }
  return [];
};

export const adaptToAutocompleteOption = (array, customFunction) => {
  return array?.length
    ? array?.map((item) => {
        return customFunction?.(item) || {};
      })
    : [];
};
