import { Paper, Stack, Typography } from '@mui/material';
import 'bootstrap-daterangepicker/daterangepicker.css';
import _debounce from 'lodash/debounce';
import { ApexOptions } from 'apexcharts';
import _merge from 'lodash/merge';
import { ZoomableLineChart } from '../../customers/db-services-detailed-view/db-monitoring/components/ZoomableLineChart';
import { useStyles } from '../../customers/db-services-detailed-view/db-monitoring/monitoringCustomStyles';
import TessellModal from 'common/custom-components/lib/components/TessellModal';

export const FullScreenChart = (props) => {
  const {
    fullScreenChart,
    setFullScreenChart,
    chartOptions: chartOptionsProps,
  } = props;

  const classes = useStyles();

  const DialogTitle = () => (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6">{fullScreenChart?.title}</Typography>
    </Stack>
  );

  const DialogContent = () => {
    const chartOptions: ApexOptions = {
      chart: {
        width: '100%',
        height: '500px',
        animations: {
          enabled: false,
          easing: 'easeinout',
          speed: 0,
          animateGradually: {
            enabled: false,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 300,
          },
        },
      },
      title: {
        text: '',
      },
      legend: {
        offsetX: 20,
        markers: {
          offsetX: -10,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 15,
        },
      },
    };

    const finalChartOptions = _merge(chartOptions, chartOptionsProps);

    return (
      <Stack
        sx={{ width: `calc(100vw - 300px)`, height: `calc(100vh - 200px)` }}
      >
        {' '}
        <Paper
          elevation={0}
          sx={{
            ...classes.monTile,
            maxWidth: 'none',
            marginTop: '16px',
            height: '100%',
            pb: 2,
            border: 'none',
          }}
        >
          <ZoomableLineChart
            chart={fullScreenChart}
            chartOptions={finalChartOptions}
            showFullScreenToolbar={false}
          />
        </Paper>
      </Stack>
    );
  };

  const renderFunctions = {
    title: () => DialogTitle(),
    content: () => DialogContent(),
  };

  return (
    <TessellModal
      renderFunctions={renderFunctions}
      maxWidth="xl"
      open={!!fullScreenChart}
      onClose={() => setFullScreenChart(null)}
    />
  );
};
