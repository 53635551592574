export const getGraphColors = () => [
  '#11567F', // 1
  '#198754', // 4
  '#FFC107', // 7

  '#29B5E8', // 3
  '#20C997', // 6
  '#DC3545', // 9

  '#1E7EA1', // 2
  '#4DAC4A', // 5
  '#FD7E14', // 8
];
