import { Stack, Typography } from '@mui/material';
import { TicketsTable } from 'customers/tenants-detailed-view/lib/components/tickets/TicketsTable';

export const Tickets = () => {
  return (
    <Stack mt={6.75} p={4}>
      <Typography variant="h4" color={'primary'}>
        Tickets
      </Typography>
      <TicketsTable />
    </Stack>
  );
};
