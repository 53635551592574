import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { useDBEngineConfig } from './useDBEngineConfig';
import { DBEngineConfigProps } from './types';
import { Stack, Typography } from '@mui/material';
import OverflowTooltip from 'common/custom-components/lib/components/OverflowTooltip';
import { useContext, useMemo } from 'react';
import { ENGINE_LIST } from 'customers/db-services-common-view/components/DBServices/constants';
import { Edit } from '@mui/icons-material';
import UpdateDBEngineConfig from './UpdateDBEngineConfig/UpdateDBEngineConfig';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';

export const DBEngineConfig = (props: DBEngineConfigProps): JSX.Element => {
  const {
    rows,
    filters,
    isLoading,
    updateDBEngineConfigOpen,
    setFilters,
    setUpdateDBEngineConfigOpen,
  } = useDBEngineConfig(props);

  const { hasPermission } = useContext(UserPermissionsContext);

  const filterProps = useMemo(
    () => [
      {
        name: 'Engine',
        initialVal: filters?.engine || 'ALL',
        options: ENGINE_LIST,
        onChange: (value) => setFilters({ ...filters, engine: value }),
      },
    ],
    [filters],
  );

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'engine',
        headerName: 'Engine',
        width: 80,
      },
      {
        field: 'name',
        headerName: 'Param',
        width: 200,
        renderCell: (params) => {
          const { row: data } = params;
          return (
            <Stack direction="row" alignItems="flex-start">
              <OverflowTooltip text={data.trace + data?.name} variant="body" />
            </Stack>
          );
        },
      },
      {
        field: 'tenantValue',
        headerName: 'For This Tenant',
        width: 300,
        renderCell: (params) => {
          const { row: data } = params;
          return (
            <Stack direction="row" alignItems="flex-start">
              <Typography variant={data?.isDifferent ? 'h6' : 'body2'}>
                {data?.tenantValue}{' '}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: 'defaultValue',
        headerName: 'Default',
        width: 300,
        renderCell: (params) => {
          const { row: data } = params;
          return (
            <Stack direction="row" alignItems="flex-start">
              <Typography variant={data?.isDifferent ? 'h6' : 'body2'}>
                {data?.defaultValue}{' '}
              </Typography>
            </Stack>
          );
        },
      },
    ],
    [filters],
  );

  const actionProps = useMemo(
    () =>
      [
        hasPermission(PERMISSIONS.UPDATE_TENANT) && {
          startIcon: <Edit />,
          label: 'Update',
          onClick: (e) => {
            setUpdateDBEngineConfigOpen(true);
          },
        },
      ].filter(Boolean),
    [],
  );

  return (
    <Stack sx={{ height: '80vh' }}>
      <DataGrid
        columns={columns}
        rows={rows || []}
        loading={isLoading}
        filters={filterProps}
        actions={actionProps}
      />
      <UpdateDBEngineConfig
        open={updateDBEngineConfigOpen}
        setOpen={setUpdateDBEngineConfigOpen}
        tenant={props.tenant}
        tenantDomain={props.tenantDomain}
        tenantId={props.tenantID}
        handleSuccess={() => setUpdateDBEngineConfigOpen(false)}
      />
    </Stack>
  );
};
