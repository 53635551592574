import { EMPTY_OBJECT } from 'constants/appConstants';

export const peeringResponseAdapter = (cloudType: string) => {
  return (response) => {
    let peerings = [];
    if (response) {
      const data = response;
      if (cloudType?.toLocaleLowerCase() === 'aws' && Array.isArray(data)) {
        peerings =
          data?.map((p) => ({
            id: p.name,
            name: p.name || '',
            status: p.status || '',
            cloudId: p.cloudId || '',
            clientVpcId: p?.clientVpcId || '',
            clientVpcRegion: p?.awsClientInfo?.clientVpcRegion || '',
            clientAccountId: p?.awsClientInfo?.clientAccountId || '',
            clientVpcInfo:
              p?.awsClientVpcInfo || p?.azureClientVpcInfo || EMPTY_OBJECT,
          })) || [];
      } else if (
        cloudType?.toLocaleLowerCase() === 'azure' &&
        Array.isArray(data)
      ) {
        peerings = data?.map((p) => ({
          id: p.name,
          name: p.name,
          status: p.status || '',
          cloudId: p.cloudId || '',
          clientVpcName: p?.azureClientInfo?.clientVpcName || '',
          clientResourceGroup: p?.azureClientInfo?.clientResourceGroup || '',
          clientSubscriptionId: p?.azureClientInfo?.clientSubscriptionId || '',
          metadata: p.metadata,
          clientVpcInfo: p?.clientVpcInfo || EMPTY_OBJECT,
        }));
      }
      return peerings;
    }
  };
};
