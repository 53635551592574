import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { DonutChartCard } from './Cards/DonutChartCard';

const getTenantDataInGenieLogs = (genieLogs, tenant) => {
  const tenantsObject = genieLogs?.tenant;
  const showTenant = tenant?.toString();
  const dataObject = tenantsObject?.[showTenant] || {};

  const data = Object.values(dataObject) as [number];
  const labels = Object.keys(dataObject) as [string];
  return { data, labels };
};

type genieLogs = {
  tenant: object;
  total: number;
};

type GenieChartProps = {
  genieLogs: genieLogs;
};

export const GenieCharts = (props: GenieChartProps) => {
  const { genieLogs } = props;
  const tenants = Object.keys(genieLogs?.tenant);
  const [showTenant, setShowTenant] = useState(tenants[0]);
  const { data, labels } = getTenantDataInGenieLogs(genieLogs, showTenant);

  const totalForTenant = data ? data.reduce((sum, a) => sum + a, 0) : 0;
  return (
    <Stack spacing={4}>
      <FormControl fullWidth>
        <InputLabel>Tenant</InputLabel>
        <Select
          value={showTenant}
          label="Tenant"
          onChange={(e) => setShowTenant(e?.target?.value)}
        >
          {tenants?.map((tenant) => (
            <MenuItem value={tenant}>{tenant}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack width="40vw">
        <DonutChartCard
          data={data || []}
          labels={labels || []}
          title={`${showTenant} (Total: ${totalForTenant})`}
        />
      </Stack>
    </Stack>
  );
};
