/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { IconPropType, useStyles } from './utils';

export default function AvailabilityMachinesIcon(props: IconPropType) {
  const { className = '' } = props;
  const iconStyle = useStyles(props);

  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00546 5.86277C2.97169 8.70329 2.95403 14.2104 6.50043 18.3879C7.40989 19.4592 8.00005 20.3919 10.9993 22.396"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M3 5.86277L1 8.7804"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M3.00069 5.86277L5.00069 8.7804"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M13.0927 23.2912L17.0907 22.7981"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M13.0928 23.2913L13.9955 19.6407"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M2.99931 3.5C4.1244 2.92619 7.77994 1 12.2091 1C16.1036 1 19.0914 2.05729 21.9993 3.5"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M21.9955 5.86277C22.0334 9.01599 21.919 14.2179 18.0541 19.0015C17.2395 19.951 16.4555 21.0466 13 23.3685"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M9.15698 9.512C8.65263 9.06768 8.14051 8.33317 7.40137 8.96721C6.89336 9.40311 6.7977 9.5631 7.52595 10.283L10.9417 13.2923C11.4461 13.7568 12.2714 13.7568 12.7757 13.3125L18.484 8.28357C18.9883 7.83925 19.224 7.46979 18.7196 7.02547C18.1924 6.58116 17.6055 6.99729 17.1012 7.44161L11.8774 11.9035L9.15698 9.512Z"
        fill="var(--icon-color)"
      />
    </svg>
  );
}
