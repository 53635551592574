import { chartObjectAdapter } from 'customers/tenants-detailed-view/lib/components/costSingleTenant/chartObjectAdapter';
import { useMemo } from 'react';

type CostDataProps = {
  costDataArray: any[];
  seriesName: string;
};
export const useAdaptCostDataForChart = (props: CostDataProps) => {
  const { costDataArray, seriesName } = props;
  const keys = useMemo(() => {
    return chartObjectAdapter(costDataArray?.[0] || {})?.keys || [];
  }, [costDataArray]);

  const series = useMemo(() => {
    return (
      costDataArray?.map((_costObj) => {
        return {
          name: _costObj?.[seriesName],
          data: chartObjectAdapter(_costObj)?.values,
        };
      }) || []
    );
  }, [costDataArray]);
  return { keys, series };
};
