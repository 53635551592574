import React from 'react';
import { Button, Chip, Popover, Stack } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type AlertsLabelsPopoverProps = {
  labels: any;
  updateSelectedLabels: any;
};

export const AlertsLabelsPopover = ({
  labels,
  updateSelectedLabels,
}: AlertsLabelsPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Stack m={0}>
      <Stack>
        <Button
          variant="text"
          onClick={handleClick}
          sx={{
            padding: '0px !important',
          }}
        >
          Show More
        </Button>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack direction="row" flexWrap="wrap" spacing={1} alignItems="center">
          {Object.keys(labels)?.map((_l) => (
            <Stack py={1}>
              <CopyToClipboard text={labels[_l]}>
                <Chip
                  label={`${_l}: ${labels[_l]}`}
                  onClick={() => {
                    updateSelectedLabels(_l, labels[_l]);
                  }}
                  clickable
                />
              </CopyToClipboard>
            </Stack>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
};
