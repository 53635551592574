/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     9/7/2021     bakul.banthia         Created
 *
 */

import { Button, Divider, Stack } from '@mui/material';
import _includes from 'lodash/includes';
import { GetServiceStatus, GetServiceSubstatus } from '../utils';

import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import { GetEngineIcon } from '../../tenants-detailed-view/lib/utils/GetEngineIcon';
import { DatabaseServiceType } from '../../tenants-common-view/lib/schema/DatabaseService.types';
import { LoadingButton } from '@mui/lab';
import { SilenceAlertsModal } from 'common/Modals/SilenceAlertsModal/SilenceAlertsModal';
import { useContext, useState } from 'react';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';

interface ServiceHeaderProps {
  service: DatabaseServiceType;
  serviceIsLoading: boolean;
  fetchDBServiceId: Function;
}
export const ServiceHeaderInfo = (props: ServiceHeaderProps): JSX.Element => {
  const { service: database, fetchDBServiceId, serviceIsLoading } = props;

  const { hasPermission } = useContext(UserPermissionsContext);

  const {
    _id,
    upcomingScheduledActions,
    name,
    status,
    engineType,
    instances,
    contextInfo,
  } = database;
  const [silenceAlertsOpen, setSilenceAlertsOpen] = useState(false);
  const handleOnSuccess = () => {
    setSilenceAlertsOpen(false);
  };

  const displaySubStatus = !!upcomingScheduledActions || contextInfo !== 'null';
  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      spacing={0.5}
      width="100%"
    >
      <Stack direction="row" alignItems="flex-start" spacing={2} width="50%">
        <OverflowTooltip
          tooltip={name?.length > 10 ? name : ''}
          text={name}
          variant="h5"
          placement="right"
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={2}
        sx={{ paddingLeft: 1 }}
      >
        <GetEngineIcon engine={engineType?.toUpperCase()} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <GetServiceStatus
            sx={{
              startingText: {
                borderBottom: '1px solid',
              },
            }}
            status={status}
            instances={instances}
            customStatus={undefined}
          />
          {displaySubStatus && (
            <GetServiceSubstatus
              contextInfo={contextInfo}
              upcomingScheduledActions={upcomingScheduledActions}
              showPillDetails
            />
          )}
          <LoadingButton
            loading={serviceIsLoading}
            onClick={() => {
              fetchDBServiceId();
            }}
            variant="contained"
          >
            Refresh
          </LoadingButton>
          {hasPermission(PERMISSIONS.ALERTS_SILENCE) && (
            <Button
              onClick={() => {
                setSilenceAlertsOpen(true);
              }}
              variant="contained"
              size="small"
              color="primary"
              sx={{ height: 'max-content' }}
            >
              Silence Alert for this service
            </Button>
          )}
        </Stack>
      </Stack>
      {silenceAlertsOpen && (
        <SilenceAlertsModal
          open={silenceAlertsOpen}
          setOpen={setSilenceAlertsOpen}
          selectedAlertLabels={{
            service_id: _id,
          }}
          hideInputs={true}
          handleOnSuccess={handleOnSuccess}
        />
      )}
    </Stack>
  );
};
