import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { useMemo } from 'react';

type CountAndPageCountProps = {
  response: any;
  noOfItemsPerPage?: number;
};

export const useCountAndPageCount = (props: CountAndPageCountProps) => {
  const { response, noOfItemsPerPage } = props;
  const count = useMemo(() => {
    return response?.response?.count;
  }, [response]);

  const pageCount = useMemo(
    () => Math.ceil(count / (noOfItemsPerPage || ITEMS_PER_PAGE)) || 0,
    [count],
  );
  return { count, pageCount };
};
