import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import OverflowTip from 'common/custom-components/lib/components/OverflowTooltip';
import { useStyles } from '../../utils/useStyles';
import { getLabelValue } from 'common/utils';
import { GetServiceStatus } from 'customers/db-services-detailed-view/utils';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { DF_HUMAN } from 'helpers/dateFormats';
import { useClones } from './useClones';

export default function Clones({ tenantId = '', availabilityMachineId = '' }) {
  const classes = useStyles();
  const {
    regionMap,
    clonesList,
    clonesIsLoading,
    clonesCountIsLoading,
    clonesPageCount,
    page,
    handlePaginationChange,
    clonesCount,
  } = useClones({
    tenantId: tenantId,
    availabilityMachineId: availabilityMachineId,
  });

  const dbColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        headerAlign: 'center',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        renderCell: (params) => {
          const { row: data } = params;
          const { name, owner } = data;
          return (
            <>
              <Typography sx={classes.boldText} color="textPrimary">
                {name}
              </Typography>
              <OverflowTip text={owner} variant="body2" placement="right" />
            </>
          );
        },
        width: 300,
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params) => {
          const { row: data } = params;
          const { status } = data;

          return (
            <Stack direction="row" alignItems="center">
              <GetServiceStatus
                status={status}
                instances={[]}
                sx={{}}
                customStatus={null}
              />
            </Stack>
          );
        },
        width: 150,
      },
      {
        field: 'availability',
        headerName: 'Cloud/Region',
        width: 200,
        renderCell: (params) => {
          const { row: data } = params;
          const { cloud, subscription, region } = data;
          if (!cloud) {
            return 'NA';
          }
          const regionText = `${regionMap[region]}`;

          return (
            <>
              <Typography sx={classes.boldText} color="textPrimary">
                {cloud.toUpperCase()}
              </Typography>
              <OverflowTip
                text={regionText}
                variant="body2"
                placement="right"
              />
              <OverflowTip
                text={subscription}
                variant="body2"
                placement="right"
              />
            </>
          );
        },
      },
      {
        field: 'cloneInfo',
        headerName: 'Source',
        width: 250,
        renderCell: (params) => {
          const { row: data } = params;
          const { cloneInfo } = data;
          if (!cloneInfo) {
            return 'NA';
          }
          const {
            createdFromAvailabilityMachine,
            createdFromPitr: discreteRecovery,
            createdFromBackupName: snapshotName,
            snapshotTime,
            createdFromDapName,
            createdFromPitr,
            pitrTimeInUtc,
            type,
            manualBackup,
          } = cloneInfo;
          const isDiscrete = discreteRecovery === 'true';

          return (
            <>
              <Typography sx={classes.boldText} color="textPrimary">
                {isDiscrete && 'Snapshot'}
                {createdFromPitr && 'Point in time'}
              </Typography>
              {snapshotName?.length &&
                getLabelValue('Snapshot name', snapshotName, 'body2')}
              {manualBackup && 'Manual Backup'}
              {snapshotTime &&
                getLabelValue(
                  'Snapshot Time',
                  moment(snapshotTime).format(DF_HUMAN),
                  'body2',
                )}
              {pitrTimeInUtc &&
                getLabelValue(
                  'Recovery time',
                  moment(pitrTimeInUtc).format(DF_HUMAN),
                  'body2',
                )}
              {createdFromDapName?.length &&
                getLabelValue('Access Policy', createdFromDapName, 'body2')}
              {type && getLabelValue('Content Type', `${type} Data`, 'body2')}
            </>
          );
        },
      },
      {
        field: 'computeType',
        headerName: 'Compute',
      },
      {
        field: 'dateCreated',
        headerName: 'Created',
        width: 150,
      },
    ],
    [regionMap],
  );

  return (
    <Stack sx={{ height: '50vh' }}>
      <DataGrid
        columns={dbColumns}
        rows={clonesList || []}
        styleOverrides={{ hideShadow: true }}
        clickable={false}
        loading={clonesIsLoading || clonesCountIsLoading}
        pageCount={clonesPageCount}
        pageNo={page}
        handlePaginationChange={handlePaginationChange}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        totalCount={clonesCount || 0}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
    </Stack>
  );
}
