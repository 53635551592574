export const typeFilter = [
  { name: 'All', id: 'All' },
  { name: 'Tenant', id: 'tenant' },
  { name: 'DB Backup', id: 'dbBackup' },
  { name: 'DB Service', id: 'dbService' },
  { name: 'DB Service Instance', id: 'dbServiceInstance' },
  { name: 'DB Service Database', id: 'dbServiceDatabase' },
];

export const status = [
  {
    name: 'Open',
    id: 'open',
    subText: 'Newly created exception awaiting investigation.',
  },
  {
    name: 'Assigned',
    id: 'assigned',
    subText: 'Assigned to a developer for resolution.',
  },
  {
    name: 'Resolved',
    id: 'resolved',
    subText: 'Resolved without code change (e.g., configuration fix).',
  },
];

export const statusFilter = [{ name: 'All', id: 'All' }, ...status];
