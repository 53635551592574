import { LinearProgress, Stack } from '@mui/material';
import { COST_MAX_RANGE, CostRangeType, CostResponseKeys } from '../constants';
import { costLayout } from '../styles';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import { EMPTY_STRING } from 'constants/appConstants';
import DatePickerToggler from 'common/custom-components/lib/components/DatePickerToggler';
import { DF_HUMAN_WITHOUT_TIME } from 'helpers/dateFormats';
import { isEmpty } from 'lodash';
import TotalTableAndPieView from '../TotalTableAndPieView/TotalTableAndPieView';
import {
  adaptToAutocompleteOptionWithEmptyString,
  envCostAdapter,
} from '../adapters';
import { useCostData } from '../Hooks/useCostData';
import { CostEnums } from 'constants/URLEnums';
import DayWiseChartAndTable from '../DayWiseChartAndTable/DayWiseChartAndTable';
import NoData from '../NoData';

type EnvironmentCostProps = {
  environmentsNames: string[];
  environmentsNamesIsLoading: boolean;
};

const EnvironmentsCost = (props: EnvironmentCostProps) => {
  const { environmentsNames = [], environmentsNamesIsLoading = false } = props;

  const {
    startTime,
    endTime,
    selectedEntities: selectedEnvironments,
    setSelectedEntities: setSelectedEnvironments,
    costData: environmentsCost,
    costDataisLoading: environmentsCostIsLoading,
    updateTimeUsingNativePicker,
    handleDateToggle,
    range,
  } = useCostData({
    customCostAdapter: envCostAdapter,
    costType: CostEnums.COST_BY_ENV,
    costParamsKey: 'env-name',
  });

  return (
    <Stack sx={costLayout.contentContainer}>
      <Stack sx={costLayout.topBarContainer}>
        <Stack
          direction={'row'}
          sx={costLayout.topBarContentContainer}
          spacing={2}
        >
          <TessellAutocomplete
            label="Environments"
            showDefaultStyleLabel
            value={selectedEnvironments}
            variant="outlined"
            size="small"
            onChange={(_val) => {
              setSelectedEnvironments(_val);
            }}
            fullWidth
            options={
              adaptToAutocompleteOptionWithEmptyString(environmentsNames) || []
            }
            multiple
            disabled={environmentsNamesIsLoading}
            sx={{
              ...(!selectedEnvironments?.length && { width: '150px' }),
            }}
          />
          <DatePickerToggler
            rangeType={CostRangeType}
            startTime={startTime}
            endTime={endTime}
            updateTimeUsingNativePicker={updateTimeUsingNativePicker}
            handleOnChange={handleDateToggle}
            range={range}
            customDateFormat={DF_HUMAN_WITHOUT_TIME}
            showTimePicker={false}
            maxSpanInDays={COST_MAX_RANGE}
          />
        </Stack>
      </Stack>
      <Stack sx={costLayout.graphAndTableContainer}>
        {environmentsCostIsLoading ? (
          <Stack sx={costLayout.loaderContainer}>
            <Stack width="100%">
              <LinearProgress color="primary" />
            </Stack>
          </Stack>
        ) : !environmentsCostIsLoading && !isEmpty(environmentsCost) ? (
          <Stack direction={'row'} spacing={1} width="100%">
            <Stack sx={costLayout.chartContainer}>
              <DayWiseChartAndTable
                costArray={environmentsCost}
                seriesAndHeadName={CostResponseKeys.ENV_NAME}
                firstColmnTitle="Environment Name"
              />
            </Stack>
            <TotalTableAndPieView
              costArray={environmentsCost}
              tableFirstColumnKey={CostResponseKeys.ENV_NAME}
            />
          </Stack>
        ) : (
          <Stack>{!environmentsCost?.length ? <NoData /> : EMPTY_STRING}</Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default EnvironmentsCost;
