import { styles } from 'assets/scss/style-templates';

import { useNavigate, useParams } from 'react-router-dom';
import { TabType } from 'common/tab-view/lib/schema/TabType';
import { TabView } from 'common/tab-view/lib/components/TabView';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import _isEmpty from 'lodash/isEmpty';
import Clones from '../clones/Clones';
import SnapshotsTable from '../snapshots/SnapshotsTable';
import {
  BackupIcon,
  CloningIcon,
  LogsIcon,
  MyDatabasesIcon,
  PITRIcon,
  PoliciesIcon,
} from 'assets/icons-v2';
import { AvailabilityMachineRowType } from 'customers/tenants-common-view/lib/schema/AvailabilityMachine.types';
import DataFlix from '../pitr/Pitr';
import { Stack } from '@mui/material';
import BackupsTable from '../backups/Backups';
import { DAP } from '../dap/DAP';

enum AMTabType {
  Clones = 'Clones',
  Snapshots = 'Snapshots',
  Backups = 'Backups',
  PITR = 'PITR',
  DAP = 'DAP',
}

// Snake to Camel case
const RouteToServiceTabMap = {
  clones: 'Clones',
  snapshots: 'Snapshots',
  backups: 'Backups',
  pitr: 'PITR',
  dap: 'DAP',
};

// Camel case to Snake case
const ServiceTabToRouteMap = {
  Clones: 'clones',
  Snapshots: 'snapshots',
  Backups: 'backups',
  PITR: 'pitr',
  DAP: 'dap',
};

interface AMTabViewProps {
  service: DatabaseServiceType;
  avMachine: AvailabilityMachineRowType;
}

export const AMTabView = (props: AMTabViewProps): JSX.Element => {
  const navigate = useNavigate();

  const { service, avMachine } = props;

  const params = useParams();

  const { tabId = 'pitr', databaseServiceId, avMachineId } = params;
  const onTabClick = (event: any, newValue: string) => {
    navigate(
      `/database-services/details/${databaseServiceId}/availability-machine/${avMachineId}/${ServiceTabToRouteMap[newValue]}`,
      {
        replace: true,
      },
    );
  };

  const tabValue = RouteToServiceTabMap[tabId] as AMTabType;
  const tabsProps: TabType[] = [
    {
      label: AMTabType.Snapshots,
      tabValue: 'Snapshots',
      render: () => (
        <SnapshotsTable
          availabilityMachineId={service.availabilityMachineId}
          tenantId={service?.tenantId}
        />
      ),
      icon: <LogsIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: AMTabType.Clones,
      tabValue: 'Clones',
      render: () => {
        return (
          <Clones
            tenantId={service?.tenantId}
            availabilityMachineId={service.availabilityMachineId}
          />
        );
      },
      icon: <CloningIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: AMTabType.Backups,
      tabValue: 'Backups',
      render: () => {
        return (
          <BackupsTable
            availabilityMachineId={service.availabilityMachineId}
            tenantId={service?.tenantId}
          />
        );
      },
      icon: <BackupIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: AMTabType.PITR,
      tabValue: AMTabType.PITR,
      render: () => <DataFlix avMachine={avMachine} />,
      icon: <PITRIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: AMTabType.DAP,
      tabValue: AMTabType.DAP,
      render: () => <DAP avMachine={avMachine} />,
      icon: <PoliciesIcon height={16} color={styles.color.darkShade} />,
    },
  ].filter(Boolean);

  const currentTab =
    tabsProps?.find((tab) => tab?.tabValue === tabValue) || tabsProps?.[0];

  return (
    <Stack mt={3}>
      {!_isEmpty(service) ? (
        <TabView
          tabs={tabsProps}
          onTabClick={onTabClick}
          currentTab={currentTab}
        />
      ) : null}
    </Stack>
  );
};
