import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { Priorities } from 'customers/tenants-detailed-view/lib/utils/constants';
import { JiraInput } from './JiraInput';
import { DatePicker } from '@mui/x-date-pickers';
import { styles } from 'assets/scss/style-templates';
import { useTicketStyles } from './useTicketStyles';

type TicketFormModalProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  state: any;
  updateState: any;
  isSubmitting: boolean;
  jiraComponents?: any;
  jiraUsers?: any;
  jiraIssues?: any;
  submitBtnText?: string;
  title?: string;
  isSubmitBtnDisabled?: boolean;
  showDueDate?: boolean;
};

export const TicketFormModal = ({
  show,
  onClose,
  onSubmit,
  state,
  updateState,
  isSubmitting,
  jiraComponents,
  jiraUsers,
  jiraIssues,
  title,
  submitBtnText,
  isSubmitBtnDisabled = false,
  showDueDate,
}: TicketFormModalProps) => {
  const classes = useTicketStyles();

  return (
    <>
      <Dialog open={show} maxWidth="md" disableEscapeKeyDown>
        <DialogTitle>{title || 'Create Ticket'}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={1} pt={2}>
            <TessellInput
              label="Ticket Subject"
              value={state?.subject}
              variant="outlined"
              size="small"
              onChange={(e) => updateState({ subject: e.target.value })}
              fullWidth
              mt={0}
              name="subject"
            />
            <Stack
              direction="row"
              justifyContent={'space-between'}
              sx={{ ...classes.ticketForm }}
            >
              {jiraIssues?.length > 0 && (
                <Stack direction="column" spacing={1} width={'49%'}>
                  <JiraInput
                    label="Issue type"
                    key="issuetype"
                    values={jiraIssues}
                    value={state?.issuetype}
                    onChange={(_val) => updateState({ issuetype: _val })}
                  />
                </Stack>
              )}
              <Stack direction="column" spacing={1} width={'49%'}>
                <JiraInput
                  label="Severity"
                  key="priority"
                  values={Priorities}
                  value={state?.priority}
                  onChange={(_val) => updateState({ priority: _val })}
                />
              </Stack>
              {jiraComponents?.length > 0 && (
                <Stack direction="column" spacing={1} width={'49%'}>
                  <JiraInput
                    label="Component"
                    key="jiraComponent"
                    values={jiraComponents}
                    value={state?.jiraComponent}
                    onChange={(_val) => updateState({ jiraComponent: _val })}
                  />
                </Stack>
              )}
              {jiraUsers?.length > 0 && (
                <Stack direction="column" spacing={1} width={'49%'}>
                  <JiraInput
                    label="Assignee"
                    key="jiraAssignee"
                    values={jiraUsers}
                    value={state?.jiraAssignee}
                    onChange={(_val) => updateState({ jiraAssignee: _val })}
                  />
                </Stack>
              )}

              {showDueDate && (
                <Stack direction="column" spacing={1} width={'49%'}>
                  <Typography
                    sx={{
                      ...styles.textBoxLabel,
                      width: 'fit-content',
                    }}
                  >
                    Due Date
                  </Typography>
                  <Stack sx={{ div: { height: '37.13px' } }}>
                    <DatePicker
                      key="duedate"
                      value={state?.duedate}
                      disablePast
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(_val) => updateState({ duedate: _val })}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>

            <TessellInput
              label="Comment"
              value={state?.summary || ''}
              variant="outlined"
              size="small"
              onChange={(e) => updateState({ summary: e.target.value })}
              fullWidth
              mt={0}
              rows={4}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              onClose();
            }}
            size="large"
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={onSubmit}
            size="large"
            loading={isSubmitting}
            disabled={isSubmitBtnDisabled}
          >
            {submitBtnText || 'Create'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
