export function LoginPageImage() {
  return (
    <svg
      width="619"
      height="422"
      viewBox="0 0 619 422"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_80_103)">
        <g clip-path="url(#clip0_80_103)">
          <rect
            x="70"
            y="82"
            width="465.668"
            height="314.185"
            rx="7.48061"
            fill="#21496B"
          />
          <rect x="70" y="82" width="43.0135" height="314.185" fill="#375B7A" />
          <rect
            x="80.2858"
            y="166.157"
            width="22.4418"
            height="22.4418"
            rx="3.7403"
            fill="#21496B"
          />
          <g filter="url(#filter1_dd_80_103)">
            <rect
              x="80.2858"
              y="199.82"
              width="22.4418"
              height="22.4418"
              rx="3.7403"
              fill="#417899"
            />
          </g>
          <rect
            x="80.2858"
            y="233.482"
            width="22.4418"
            height="22.4418"
            rx="3.7403"
            fill="#21496B"
          />
          <rect
            x="80.2858"
            y="267.145"
            width="22.4418"
            height="22.4418"
            rx="3.7403"
            fill="#21496B"
          />
          <rect
            x="141"
            y="107"
            width="142.357"
            height="130.34"
            rx="4"
            fill="white"
            fill-opacity="0.1"
          />
          <path
            d="M247.918 175.731C247.918 182.27 246.259 188.702 243.096 194.432C239.932 200.161 235.366 205.002 229.821 208.506C224.277 212.009 217.933 214.061 211.379 214.472C204.825 214.882 198.273 213.638 192.331 210.854C186.389 208.07 181.249 203.837 177.39 198.547C173.53 193.258 171.074 187.083 170.251 180.596C169.428 174.11 170.264 167.521 172.681 161.442C175.099 155.362 179.02 149.99 184.079 145.822L208.931 175.731H247.918Z"
            fill="#08080D"
            fill-opacity="0.4"
          />
          <path
            d="M229.035 126.998C238.3 130.358 246.31 136.462 251.987 144.488C257.665 152.515 260.738 162.08 260.794 171.898L212.508 172.17L229.035 126.998Z"
            fill="#08080D"
            fill-opacity="0.6"
          />
          <path
            d="M183.135 136.241C188.723 132.019 195.276 129.243 202.207 128.163C209.138 127.083 216.23 127.732 222.847 130.052L208.764 169.867L183.135 136.241Z"
            fill="#08080D"
            fill-opacity="0.5"
          />
          <rect
            x="290.753"
            y="107"
            width="216.309"
            height="130.34"
            rx="4"
            fill="white"
            fill-opacity="0.1"
          />
          <rect
            x="324.031"
            y="178.179"
            width="15.7148"
            height="32.354"
            rx="4"
            fill="#243A4E"
          />
          <rect
            x="357.309"
            y="164.313"
            width="15.7148"
            height="46.2199"
            rx="4"
            fill="#243A4E"
          />
          <rect
            x="389.663"
            y="152.296"
            width="16.6392"
            height="58.2371"
            rx="4"
            fill="#243A4E"
          />
          <rect
            x="457.144"
            y="131.959"
            width="16.6392"
            height="78.5739"
            rx="4"
            fill="#243A4E"
          />
          <rect
            x="422.942"
            y="143.052"
            width="16.6392"
            height="67.4811"
            rx="4"
            fill="#243A4E"
          />
          <rect
            x="141"
            y="245.66"
            width="366.062"
            height="130.34"
            rx="4"
            fill="white"
            fill-opacity="0.1"
          />
          <path
            d="M163.5 343L212 312L247.5 323L313.5 291.5L343.5 306.5L387 276.5L428 318.5L456 298.5L483 312"
            stroke="#243A4E"
            stroke-width="2"
          />
          <circle cx="163.5" cy="342.5" r="2.5" fill="#1B2939" />
          <circle cx="211.5" cy="311.5" r="2.5" fill="#1B2939" />
          <circle cx="247.5" cy="322.5" r="2.5" fill="#1B2939" />
          <circle cx="312.5" cy="291.5" r="2.5" fill="#1B2939" />
          <circle cx="343.5" cy="305.5" r="2.5" fill="#1B2939" />
          <circle cx="386.5" cy="276.5" r="2.5" fill="#1B2939" />
          <circle cx="427.5" cy="317.5" r="2.5" fill="#1B2939" />
          <circle cx="482.5" cy="311.5" r="2.5" fill="#1B2939" />
          <circle cx="455.5" cy="298.5" r="2.5" fill="#1B2939" />
        </g>
        <rect
          x="70.4675"
          y="82.4675"
          width="464.733"
          height="313.25"
          rx="7.01307"
          stroke="#2D6B9D"
          stroke-width="0.935076"
        />
      </g>
      <g filter="url(#filter2_d_80_103)">
        <mask
          id="path-29-outside-1_80_103"
          maskUnits="userSpaceOnUse"
          x="416"
          y="25"
          width="153"
          height="45"
          fill="black"
        >
          <rect fill="white" x="416" y="25" width="153" height="45" />
          <path d="M416 31C416 27.6863 418.686 25 422 25H562.202C565.516 25 568.202 27.6863 568.202 31V63C568.202 66.3137 565.516 69 562.202 69H422C418.686 69 416 66.3137 416 63V31Z" />
        </mask>
        <path
          d="M416 31C416 27.6863 418.686 25 422 25H562.202C565.516 25 568.202 27.6863 568.202 31V63C568.202 66.3137 565.516 69 562.202 69H422C418.686 69 416 66.3137 416 63V31Z"
          fill="#1B2939"
        />
        <path
          d="M442 44.9651V48.7682"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M443.128 39.4576C443.022 39.25 442.86 39.0757 442.661 38.954C442.462 38.8323 442.233 38.7679 442 38.7679C441.767 38.7679 441.538 38.8323 441.339 38.954C441.14 39.0757 440.978 39.25 440.872 39.4576L433.899 53.4025C433.802 53.5955 433.756 53.8101 433.765 54.026C433.774 54.2419 433.838 54.452 433.951 54.6361C434.064 54.8203 434.222 54.9725 434.411 55.0783C434.599 55.1841 434.811 55.24 435.028 55.2407H448.972C449.189 55.24 449.401 55.1841 449.589 55.0783C449.778 54.9725 449.936 54.8203 450.049 54.6361C450.162 54.452 450.226 54.2419 450.235 54.026C450.244 53.8101 450.198 53.5955 450.101 53.4025L443.128 39.4576Z"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="442" cy="51.8937" r="1" fill="white" />
        <rect
          opacity="0.4"
          x="464"
          y="38"
          width="88.2021"
          height="6"
          rx="3"
          fill="white"
        />
        <rect
          opacity="0.4"
          x="464"
          y="50"
          width="56.489"
          height="6"
          rx="3"
          fill="white"
        />
        <path
          d="M416 25H568.202H416ZM568.402 63C568.402 66.4242 565.626 69.2 562.202 69.2H422.2C418.776 69.2 416 66.4242 416 63C416 66.2033 418.686 68.8 422 68.8H562.202C565.405 68.8 568.002 66.2033 568.002 63H568.402ZM416 69V25V69ZM562.202 25C565.626 25 568.402 27.7758 568.402 31.2V63C568.402 66.4242 565.626 69.2 562.202 69.2V68.8C565.405 68.8 568.002 66.2033 568.002 63V31C568.002 27.6863 565.405 25 562.202 25Z"
          fill="url(#paint0_linear_80_103)"
          mask="url(#path-29-outside-1_80_103)"
        />
      </g>
      <g filter="url(#filter3_d_80_103)">
        <mask
          id="path-36-outside-2_80_103"
          maskUnits="userSpaceOnUse"
          x="404"
          y="75"
          width="153"
          height="45"
          fill="black"
        >
          <rect fill="white" x="404" y="75" width="153" height="45" />
          <path d="M404 81C404 77.6863 406.686 75 410 75H550.202C553.516 75 556.202 77.6863 556.202 81V113C556.202 116.314 553.516 119 550.202 119H410C406.686 119 404 116.314 404 113V81Z" />
        </mask>
        <path
          d="M404 81C404 77.6863 406.686 75 410 75H550.202C553.516 75 556.202 77.6863 556.202 81V113C556.202 116.314 553.516 119 550.202 119H410C406.686 119 404 116.314 404 113V81Z"
          fill="#1B2939"
        />
        <path
          d="M426.165 97.7658L428.421 100.079C428.481 100.143 428.555 100.193 428.637 100.224C428.719 100.256 428.807 100.269 428.894 100.262C428.982 100.257 429.068 100.232 429.146 100.191C429.224 100.149 429.292 100.091 429.346 100.02L434.1 93.7889"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M430 105C434.418 105 438 101.418 438 97C438 92.5817 434.418 89 430 89C425.582 89 422 92.5817 422 97C422 101.418 425.582 105 430 105Z"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          opacity="0.4"
          x="452"
          y="88"
          width="88.2021"
          height="6"
          rx="3"
          fill="white"
        />
        <rect
          opacity="0.4"
          x="452"
          y="100"
          width="56.489"
          height="6"
          rx="3"
          fill="white"
        />
        <path
          d="M404 75H556.202H404ZM556.402 113C556.402 116.424 553.626 119.2 550.202 119.2H410.2C406.776 119.2 404 116.424 404 113C404 116.203 406.686 118.8 410 118.8H550.202C553.405 118.8 556.002 116.203 556.002 113H556.402ZM404 119V75V119ZM550.202 75C553.626 75 556.402 77.7758 556.402 81.2V113C556.402 116.424 553.626 119.2 550.202 119.2V118.8C553.405 118.8 556.002 116.203 556.002 113V81C556.002 77.6863 553.405 75 550.202 75Z"
          fill="url(#paint1_linear_80_103)"
          mask="url(#path-36-outside-2_80_103)"
        />
      </g>
      <path
        d="M48 309.644V399.77C48 411.234 72.1754 420.539 102 420.539C131.825 420.539 156 411.234 156 399.77V309.644"
        fill="#192635"
      />
      <path
        d="M48.5 309.644C48.5 309.368 48.2761 309.144 48 309.144C47.7239 309.144 47.5 309.368 47.5 309.644H48.5ZM156.5 309.644C156.5 309.368 156.276 309.144 156 309.144C155.724 309.144 155.5 309.368 155.5 309.644H156.5ZM47.5 309.644V399.77H48.5V309.644H47.5ZM47.5 399.77C47.5 402.825 49.1127 405.681 51.907 408.221C54.7013 410.762 58.7167 413.028 63.6362 414.92C73.4787 418.706 87.0423 421.039 102 421.039V420.039C87.1331 420.039 73.6967 417.719 63.9953 413.987C59.1429 412.12 55.2505 409.91 52.5798 407.481C49.9092 405.053 48.5 402.446 48.5 399.77H47.5ZM102 421.039C116.958 421.039 130.521 418.706 140.364 414.92C145.283 413.028 149.299 410.762 152.093 408.221C154.887 405.681 156.5 402.825 156.5 399.77H155.5C155.5 402.446 154.091 405.053 151.42 407.481C148.75 409.91 144.857 412.12 140.005 413.987C130.303 417.719 116.867 420.039 102 420.039V421.039ZM156.5 399.77V309.644H155.5V399.77H156.5Z"
        fill="url(#paint2_linear_80_103)"
      />
      <path
        d="M102 330.538C131.823 330.538 156 321.24 156 309.769C156 298.299 131.823 289 102 289C72.1766 289 48 298.299 48 309.769C48 321.24 72.1766 330.538 102 330.538Z"
        fill="#192635"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.5 361H57.5L73.5 345L106.5 378L135 349.5L146.5 361H207"
        stroke="url(#paint3_linear_80_103)"
        stroke-width="2"
      />
      <defs>
        <filter
          id="filter0_dd_80_103"
          x="64.3895"
          y="77.3246"
          width="476.889"
          height="325.406"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.935076" />
          <feGaussianBlur stdDeviation="2.80523" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_80_103"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.935076" />
          <feGaussianBlur stdDeviation="1.87015" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_80_103"
            result="effect2_dropShadow_80_103"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_80_103"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_80_103"
          x="66.2597"
          y="191.404"
          width="50.4941"
          height="50.494"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.61045" />
          <feGaussianBlur stdDeviation="7.01307" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_80_103"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.80523" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_80_103"
            result="effect2_dropShadow_80_103"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_80_103"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_80_103"
          x="366"
          y="0"
          width="252.402"
          height="144.2"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="25" />
          <feGaussianBlur stdDeviation="25" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_80_103"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_80_103"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_80_103"
          x="354"
          y="50"
          width="252.402"
          height="144.2"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="25" />
          <feGaussianBlur stdDeviation="25" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.160784 0 0 0 0 0.215686 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_80_103"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_80_103"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_80_103"
          x1="568"
          y1="69"
          x2="556.5"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_80_103"
          x1="556"
          y1="119"
          x2="544.5"
          y2="81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_80_103"
          x1="102"
          y1="309.644"
          x2="102"
          y2="420.539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_80_103"
          x1="207"
          y1="362"
          x2="1.00001"
          y2="362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F5F5F5" stop-opacity="0" />
          <stop offset="0.473958" stop-color="#F5F5F5" />
          <stop offset="1" stop-color="#F5F5F5" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_80_103">
          <rect
            x="70"
            y="82"
            width="465.668"
            height="314.185"
            rx="7.48061"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
