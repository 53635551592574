export const dashboardSummaryTableStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '45%',
  },
  listContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    borderRadius: 2,
    py: 5,
    border: '1px solid rgba(30, 126, 161, 0.5)',
  },
  listItem: {
    width: '80%',
    mb: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  listText: {
    fontSize: '24px',
    fontWeight: '600',
  },
};
