/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     9/27/2021     bakul.banthia         Created
 *
 */

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';

export default function OverflowTooltip(props) {
  // Create Ref
  const textElementRef = useRef();
  const { text, textColor, variant, tooltip, placement, sxClass } = props;

  const compareSize = () => {
    const compare = textElementRef.current?.scrollWidth > textElementRef.current?.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    [],
  );

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip
      title={tooltip || text}
      interactive
      disableHoverListener={!hoverStatus}
      placement={placement}
      arrow
    >
      <Typography
        color={textColor}
        variant={variant}
        ref={textElementRef}
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        sx={sxClass}
      >
        {text}
      </Typography>
    </Tooltip>
  );
}

OverflowTooltip.defaultProps = {
  variant: 'body1',
  textColor: 'textPrimary',
  placement: 'top',
};

OverflowTooltip.propTypes = {
  variant: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string.isRequired | Element,
  tooltip: PropTypes.string,
  sxClass: PropTypes.object,
  placement: PropTypes.string,
};
