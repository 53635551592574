import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import { nCountString } from 'helpers/nCountString';

export const adaptSubscription = (subscription) => ({
  id: subscription?._id,
  name: subscription?.name,
  deploymentType: capitaliseFirstLetterInEachWord(subscription?.deploymentType),
  status: subscription?.status,
  users: nCountString(
    subscription?.sharedWith?.users?.length,
    '1 User',
    'User',
    'Users',
  ),
  awsRegionsLength: nCountString(
    subscription?.infraInfo?.associatedClouds?.aws?.filter(
      (cl) => cl?.associationStatus === 'ACTIVE',
    )[0]?.regions?.length || 0,
    'Not Available',
    'region',
    'regions',
  ),
  awsRegions: subscription?.infraInfo?.associatedClouds?.aws?.filter(
    (cl) => cl?.associationStatus === 'ACTIVE',
  )[0]?.regions,
  awsCloudAccountId: subscription?.infraInfo?.associatedClouds?.aws?.filter(
    (cl) => cl?.associationStatus === 'ACTIVE',
  )[0]?.cloudAccountId,

  azureRegionsLength: nCountString(
    subscription?.infraInfo?.associatedClouds?.azure?.filter(
      (cl) => cl?.associationStatus === 'ACTIVE',
    )[0]?.regions?.length || 0,
    'Not Available',
    'region',
    'regions',
  ),
  azureRegions: subscription?.infraInfo?.associatedClouds?.azure?.filter(
    (cl) => cl?.associationStatus === 'ACTIVE',
  )[0]?.regions,
  azureCloudAccountId: subscription?.infraInfo?.associatedClouds?.azure?.filter(
    (cl) => cl?.associationStatus === 'ACTIVE',
  )[0]?.cloudAccountId,
});
