import {
  Chip,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { useState } from 'react';
import { Add } from '@mui/icons-material';
import { styles } from 'assets/scss/style-templates';
import {
  addToArray,
  removeFromArray,
} from 'common/custom-components/helperFunctions';

type TessellArrayInput = {
  label: string;
  placeHolder?: string;
  disableAdding?: (string) => boolean;
  stringArray: string[];
  onChange: any;
  disabled?: any;
};

export const TessellMultiInputWithAdd = (props: TessellArrayInput) => {
  const { label, placeHolder, disableAdding, onChange, disabled, stringArray } =
    props;
  const [text, setText] = useState('');

  return (
    <TessellInput
      label={
        <Stack width="100%" spacing={0.5}>
          <Typography
            sx={{
              ...styles.textBoxLabel,
              ...(disabled ? { color: styles.color.monotoneDarkLight } : {}),
            }}
          >
            {label}
          </Typography>
          {stringArray?.map((_str) => {
            return (
              <Chip
                label={_str}
                onDelete={() => {
                  onChange(removeFromArray?.(stringArray, _str));
                }}
                disabled={disabled}
              />
            );
          })}
        </Stack>
      }
      value={text}
      onChange={(e) => {
        setText(e.target.value?.trim());
      }}
      placeholder={placeHolder}
      size="small"
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={disableAdding(text) || stringArray?.includes(text)}
              onClick={() => {
                onChange(addToArray(stringArray, text));
                setText('');
              }}
            >
              {!!text.length && (
                <Add
                  sx={{
                    color:
                      disableAdding(text) || stringArray?.includes(text)
                        ? styles.color.monotoneDarkDark
                        : styles.color.darkBorder,
                  }}
                  fontSize="small"
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
