import { Button } from '@mui/material';
import { NavigateBefore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import backButtonStyles from './backButton.styles';

interface BackButtonProps {
  text?: string;
  onClick?: (event: any) => void;
}

const BackButton = (props: BackButtonProps) => {
  const { text = '', onClick } = props;

  const navigate = useNavigate();
  return (
    <Button
      color="primary"
      startIcon={<NavigateBefore />}
      sx={backButtonStyles.backButton}
      onClick={(event) => {
        onClick?.(event);
        navigate(-1);
      }}
    >
      {`Back ${text}`}
    </Button>
  );
};

export default BackButton;
