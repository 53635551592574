import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';

import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enUS from 'date-fns/locale/en-US';

LicenseInfo.setLicenseKey(
  'fdd5f552651b2a312d00e54b466024f1Tz02MTA1MSxFPTE3MDk1MTAxODU2ODgsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </LocalizationProvider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
