import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Home } from '@mui/icons-material';

// Import Images
import error from 'assets/images/error.svg';

export const PageUnderConstruction = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center pt-4">
                  <div className="">
                    <img
                      src={error}
                      alt=""
                      className="error-basic-img move-animation"
                    />
                  </div>
                  <div className="mt-n4">
                    {/* <h1 className="display-1 fw-medium">404</h1> */}
                    <h3 className="text-uppercase pb-4">
                      App is under-construction
                    </h3>
                    {/* <p className="text-muted mb-4">
                        The page you are looking for not available!
                      </p> */}
                    <Link to="/" className="btn btn-primary ">
                      <div className="d-flex align-items-center justify-content-center">
                        <Home className="d-flex  me-1 fs-20" />
                        <div className="d-flex">Back to home</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
