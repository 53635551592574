import { useStyles } from '../utils';
import { Button, Stack } from '@mui/material';
import { FastRewind, FastForward } from '@mui/icons-material';
import Logs from 'common/LogViewer/Logs';
import { LoadingButton } from '@mui/lab';
import { useVMLogs } from './useVMLogs';
import { LOGS_PAGE_LIMIT } from './constants';

const VMLogs = ({ service }: any) => {
  const classes = useStyles();
  const { pgSource, tenantId, instances: instancesFromService } = service;

  const {
    logViewerRef,
    pageOffset,
    currentLog,
    setPageOffset,
    streamLogs,
    handleRefresh,
    logFiles,
    isLoading,
    instances,
    instance,
    setInstance,
    isLogsLoading,
    setCurrentLog,
    selectedLogs,
    setSelectedLogs,
    logText,
    setLogText,
  } = useVMLogs({
    pgSource: pgSource,
    tenantId: tenantId,
    service: service,
    instancesFromService: instancesFromService,
  });

  const HeaderComponent = () => {
    const currentOffset = pageOffset[currentLog];

    const handlePrevious = () => {
      (logViewerRef.current as any)?.scrollToItem(0);
      const _offset = pageOffset[currentLog]
        ? pageOffset[currentLog] + LOGS_PAGE_LIMIT
        : LOGS_PAGE_LIMIT;
      setPageOffset((prev) => ({
        ...prev,
        [currentLog]: _offset,
      }));
      streamLogs(currentLog, _offset);
    };

    const handleNext = () => {
      (logViewerRef.current as any).scrollToItem(0);
      const _offset =
        pageOffset[currentLog] >= LOGS_PAGE_LIMIT
          ? pageOffset[currentLog] - LOGS_PAGE_LIMIT
          : 0;

      setPageOffset((prev) => ({
        ...prev,
        [currentLog]: _offset,
      }));
      streamLogs(currentLog, _offset);
    };

    return (
      <Stack direction="row" spacing={3}>
        <Button
          color="primary"
          startIcon={<FastRewind />}
          sx={{ alignSelf: 'self-start', width: '200px' }}
          onClick={handlePrevious}
        >
          Load previous logs
        </Button>
        {currentOffset ? (
          <Button
            color="primary"
            startIcon={<FastForward />}
            sx={classes.backButton}
            onClick={handleNext}
          >
            Load next logs
          </Button>
        ) : (
          ''
        )}
      </Stack>
    );
  };

  const FilteredComponent = () => (
    <LoadingButton loading={false} onClick={handleRefresh} variant="contained">
      Refresh
    </LoadingButton>
  );

  return (
    <Logs
      logFiles={logFiles}
      isFilesLoading={isLoading}
      entities={instances?.filter((_i) => _i?.status !== 'DELETED')}
      entityLabel="Instance"
      selectedEntity={instance}
      setSelectedEntity={setInstance}
      HeaderComponent={HeaderComponent}
      FilteredComponent={FilteredComponent}
      logViewerRef={logViewerRef}
      streamLogs={streamLogs}
      isLogsLoading={isLogsLoading}
      currentLog={currentLog}
      setCurrentLog={setCurrentLog}
      selectedLogs={selectedLogs}
      setSelectedLogs={setSelectedLogs}
      logText={logText}
      setLogText={setLogText}
    />
  );
};

export default VMLogs;
