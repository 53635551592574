import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { useMemo } from 'react';
import { useDAP } from './useDAP';
import { awsRegions } from 'customers/tenants-list-view/constants/regions';
import { getCommaSeparatedRegionNames } from './helpers';

type DAPPropType = {
  avMachine?: any;
};

export const DAP = (props: DAPPropType) => {
  const { avMachine } = props;
  const { dapList, dapIsLoading } = useDAP(avMachine);

  const dapColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
      },
      {
        field: 'id',
        headerName: 'Id',
      },
      {
        field: 'status',
        headerName: 'Status',
      },
      {
        field: 'contentType',
        headerName: 'Content Type',
      },
      {
        field: 'cloudRegions',
        headerName: 'Cloud/Region',
        renderCell: (params) => {
          const { row } = params;
          const name = awsRegions?.find(
            (i) =>
              i?.value === row?.cloudRegions?.[0]?.regions?.[0]?.['region'],
          )?.name;
          return (
            <Tooltip
              title={getCommaSeparatedRegionNames(row?.cloudRegions || [])}
            >
              <span>{name}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'type',
        headerName: 'Manual/Automated',
      },
      {
        field: 'sharedWith',
        headerName: 'Access',
        renderCell: (params) => {
          const { row } = params;
          const { sharedWith } = row;
          return sharedWith?.length === 0 ? 'None' : sharedWith?.length;
        },
      },
    ],

    [],
  );

  return (
    <Stack sx={{ height: '50vh' }}>
      <DataGrid
        columns={dapColumns || []}
        rows={dapList || []}
        styleOverrides={{ hideShadow: true }}
        clickable={false}
        loading={dapIsLoading}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
    </Stack>
  );
};
