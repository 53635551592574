import { useTheme } from '@mui/material/styles';
import { styles } from 'assets/scss/style-templates';

const useVirtualNetworksTableStyles = () => {
  const theme = useTheme();
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    userInput: {
      marginBottom: theme.spacing(2),
    },
    input: {
      marginTop: theme.spacing(2),
    },
    personaBox: {
      height: '350px',
      width: '320px',
      // padding: theme.spacing(1),
      margin: theme.spacing(1),
      overflow: 'hidden',
      borderRadius: '10px',
      border: `1px solid ${styles.color.lighterShade}`,
    },
    iconText: {
      fontWeight: 400,
      fontSize: '8.5px',
      textOverflow: 'inherit',
      // lineHeight: '26px',
      textAlign: 'center',
      m: 0.5,
    },
    IAMpersonaBox: {
      height: '370px',
      width: '300px',
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      overflow: 'auto',
      borderRadius: '10px',
    },
    featureBox: {
      borderRadius: '15px',
      backgroundColor: '#8996DF33',
      transition: 'background-color 200ms linear',
      '&:hover': {
        backgroundColor: '#8996DF77',
      },
    },
    filterBox: {
      width: 200,
    },
    slaBox: {
      height: 200,
      width: 300,
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    addIcon: {
      fontSize: '64px',
    },
    protectionIcon: {
      width: 32,
      marginRight: theme.spacing(1),
    },
    appIcon: {
      width: '32px',
    },
    personaIcon: {
      height: '100px',
    },
    featureIcon: {
      fontSize: '32px',
    },
    appPlus: {
      fontSize: '12px',
    },
    selectedTreeItem: {
      backgroundColor: 'transparent !important',
    },
    avatar: {
      backgroundColor: styles.color.lightShade,
      width: 72,
      height: 72,
    },
    userAvatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      color: styles.color.lighterShade2,
      background: '#EFF1F2',
    },
    personaAvatar: {
      width: '48px',
      height: '48px',
      color: styles.color.lighterShade2,
      fontWeight: 800,
      background: '#EFF1F2',
    },
    avatarBox: {
      background: 'white',
    },
    list: {
      width: '100%',
      maxWidth: 360,
      bgcolor: 'background.paper',
    },
    tree: {
      minWidth: 300,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    reset: {
      width: '100%',
      height: '100%',
      background: '#E6E9FF',
    },
    cancel: {
      width: '10%',
    },
    error: {
      color: 'red',
    },
    tessellLogo: {
      width: '5%',
      height: '5%',
      mx: 'auto',
      my: '-2%',
      border: '10% solid #f0f0f0',
      borderRadius: '50%',
      background: '#E6E9FF',
      zIndex: 'tooltip',
    },
    form: {
      width: '30%',
      border: '1px solid #f0f0f0',
      borderRadius: '3%',
      mx: 'auto',
      background: '#E6E9FF',
    },
    login: {
      width: '150px',
    },
    link: {
      textDecoration: 'inherit',
    },
    userInput60: {
      margin: theme.spacing(1),
      width: '60%',
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    personaInput: {
      width: '220px',
    },
    personaImg: {
      width: '72px',
    },
    personaHeader: {
      zIndex: '1000',
    },
    cardHeader: {
      position: 'sticky',
      zIndex: 200,
      overflow: 'auto',
      // width: '300px',
    },
    filter: {
      width: 250,
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
    contentBox: {
      boxSizing: 'border-box',
      borderRadius: '10px',
      boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25) !important',
      background: 'white',
    },
    unselectedSubscription: {
      padding: '2px 20px 2px 20px',
      position: 'absolute',
      // width: '130px',
      height: '25px',
      background: '#E9E9E9',
      borderRadius: '25px',
      cursor: 'pointer',
    },
    selectedSubscription: {
      padding: '2px 20px 2px 20px',
      position: 'absolute',
      // width: '140px',
      height: '25px',
      background: '#FFFFFF',
      border: '1px solid #11567F',
      borderRadius: '25px',
      cursor: 'pointer',
    },
    unselectedSubscriptionText: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#828282',
    },
    selectedSubscriptionText: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#11567F',
    },
    addPersonaPersonaBox: {
      background: '#FFFFFF',
      boxShadow: '0px 2px 4px rgba(8, 8, 13, 0.1)',
      borderRadius: '5px',
    },

    appFamilyHeader: {
      cursor: 'pointer',
    },
  };
};

export default useVirtualNetworksTableStyles;
