import { useNavigate, useParams } from 'react-router-dom';
import { TabView } from 'common/tab-view/lib/components/TabView';
import _isEmpty from 'lodash/isEmpty';
import { MonitoringIcon } from 'assets/icons-v2';
import { styles } from 'assets/scss/style-templates';
import PGSyncMonitoring from 'Administration/PortalAdministration/Monitoring/PGSyncMonitoring';
import { TabType } from 'common/tab-view/lib/schema/TabType';
import InfraMonitoring from './InfraMonitoring/InfraMonitoring';

enum Tab {
  InfraMonitoring = 'Infra Monitoring',
}

// Snake to Camel case
const RouteToTabMap = {
  'infra-monitoring': 'Infra Monitoring',
};

// Camel case to Snake case
const TabToRouteMap = {
  'Infra Monitoring': 'infra-monitoring',
};

export const CloudInfrastructureTabView = () => {
  const navigate = useNavigate();

  const params = useParams();

  const { tabId = 'infra-monitoring' } = params;
  const onTabClick = (event: any, newValue: string) => {
    navigate(`/cloud-infrastructure/${TabToRouteMap[newValue]}`, {
      replace: true,
    });
  };

  const tabValue = RouteToTabMap[tabId] as Tab;

  const tabsProps: TabType[] = [
    // {
    //   label: Tab.InfraMonitoring,
    //   tabValue: Tab.InfraMonitoring,
    //   render: () => {
    //     return <InfraMonitoring />;
    //   },
    //   icon: <MonitoringIcon height={16} color={styles.color.darkShade} />,
    // },
  ].filter(Boolean);

  const currentTab =
    tabsProps?.find((tab) => tab?.tabValue === tabValue) || tabsProps?.[0];

  return (
    <>
      {
        <TabView
          tabs={tabsProps}
          onTabClick={onTabClick}
          currentTab={currentTab}
        />
      }
    </>
  );
};
