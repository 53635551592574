/* eslint-disable import/prefer-default-export */
import STATUS from './Status';

const MINIMUM_DATABASE_INSTACES_COUNT = 2;

/**
 * The util takes the arguments current database
 * and the list of databases that are enabled for instance resize feature
 * and returns true/false based on whether the database is present inside feature flag
 * @param {Database in which the instance resize feature will get enabled/disabled} database
 * @param {Instance resize feature flag info} listOfDatabaseEnabled
 * @returns Boolean value
 */

export function shouldEnableInstanceResizeFeature(database, listOfDatabaseEnabled) {
  if (!database || !listOfDatabaseEnabled) {
    return false;
  }

  // Check workload type
  const matchWorkloadType = (workloadType) => database.compute?.workloadType === workloadType;

  // Check the cloud info
  const findCompatibleCloud = (cloudInfo) => {
    if (cloudInfo?.cloud === database.cloud?.toUpperCase()) {
      return cloudInfo?.supportedWorkloadTypes?.some(matchWorkloadType);
    }
    return false;
  };

  // Check database info
  const findCompatibleDatabase = (enabledDatabase) => {
    if (
      database.status === STATUS.Ready
      && database.instances?.length >= MINIMUM_DATABASE_INSTACES_COUNT
      && database.engine?.toUpperCase() === enabledDatabase?.engineType
    ) {
      return enabledDatabase?.clouds?.some(findCompatibleCloud);
    }
    return false;
  };

  return listOfDatabaseEnabled?.some(findCompatibleDatabase);
}
