import { DB_SERVICES_BASE_URL, URLS } from 'constants/URL';
import { useEffect, useMemo, useState } from 'react';
import { adaptInstancesResponse } from './adaptInstancesResponse';
import { useDataAndCount } from 'common/Hooks/useDataAndCount';
import { useLazyGetCall } from 'common/api/useApiCall';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { createParams } from 'helpers/createParams';

export const showCloudDetails = true;

type InstancesProps = {
  service: DatabaseServiceType;
};

export const useInstances = (props: InstancesProps) => {
  const { service } = props;
  const {
    _id: dbServiceId,
    pgSource: tenantDomain,
    metadata,
    infrastructureInfo,
  } = service;

  const { cloudAccountId } = metadata;
  const { userView } = infrastructureInfo;
  const { region } = userView;

  const [detailsAnchorEl, setDetailsAnchorEl] = useState(null);
  const [computeAnchor, setComputeAnchor] = useState(null);
  const [networkDetails, setNetworkDetails] = useState(null);
  const [computeDetails, setComputeDetails] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState({} as any);
  const [selectedCloudResource, setSelectedCloudResource] = useState('');
  const [updateType, setUpdateType] = useState(null as any);
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);

  const {
    count: instancesCount,
    pageCount: instancesPageCount,
    dataResponse: instances,
    dataIsLoading: instancesIsLoading,
    countIsLoading: instancesCountIsLoading,
    page,
    handlePaginationChange,
  } = useDataAndCount({
    url: `${DB_SERVICES_BASE_URL}/${dbServiceId}/instances`,
    dataResponseAdapter: adaptInstancesResponse,
  });

  const computeResourceIds = useMemo(() => {
    return instances?.map((_i) => _i.computeResourceId) || [];
  }, [instances]);

  const cloudInstanceDetailsParams = useMemo(() => {
    return {
      'tenant-domain': tenantDomain,
      'cloud-account-id': cloudAccountId,
      'cloud-region': region,
      'compute-resource-ids': computeResourceIds,
    };
  }, [tenantDomain, cloudAccountId, region, computeResourceIds]);

  const { response: cloudInstanceDetails, postData: getCloudInstanceDetails } =
    useLazyGetCall(
      `${URLS.getCloudInstanceDetails}instances/cloud-status?${createParams(
        cloudInstanceDetailsParams,
      )}`,
    );

  useEffect(() => {
    if (showCloudDetails && instances?.length) {
      getCloudInstanceDetails();
    }
  }, [instances]);

  return {
    page,
    handlePaginationChange,
    instances,
    instancesIsLoading,
    instancesCountIsLoading,
    instancesCount,
    instancesPageCount,
    detailsAnchorEl,
    setDetailsAnchorEl,
    computeAnchor,
    setComputeAnchor,
    networkDetails,
    setNetworkDetails,
    computeDetails,
    setComputeDetails,
    selectedInstance,
    setSelectedInstance,
    selectedCloudResource,
    setSelectedCloudResource,
    updateType,
    setUpdateType,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    cloudInstanceDetails,
  };
};
