import { useState, useEffect, useMemo } from 'react';
import { CloudAndRegionLocationType } from '../schema/CloudAndRegionLocationType';
import { cloudAndRegionLocation } from '../utils/cloudAndRegionLocation';

type CloudAndRegionLocationTypeArray = CloudAndRegionLocationType[];

export const useFetchCloudLocations = () => {
  const response = useMemo(() => ({ response: cloudAndRegionLocation() }), []);

  const [cloudLocations, setCloudLocations] =
    useState<CloudAndRegionLocationTypeArray>([]);

  const [regionMap, setRegionMap] = useState({});

  useEffect(() => {
    if (response) {
      const locations = (response.response || []).filter(
        (l) => !!l.region && !!l.name,
      );
      const regionMap = locations.reduce((obj, cl) => {
        if (cl.region) {
          return {
            ...obj,
            [cl.region]: cl.name,
          };
        }
        return obj;
      }, {});
      setCloudLocations(locations);
      setRegionMap(regionMap);
    }
  }, [response]);

  return {
    response: cloudLocations,
    regionMap,
  };
};
