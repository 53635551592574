import { fontStyles, styles } from '../monitoringCustomStyles';

export const topProcessTableStyles = {
  table: {
    cellStyles: {
      padding: 1,
      borderBottom: 'none',
    },
    columnCellStyles: {
      height: '20px',
      padding: 1,
      color: styles.color.monotoneDarkDark,
      borderBottom: `1px solid ${styles.color.greyBackground6}`,
    },
    headerStyles: {
      background: '#F8FAFC',
      color: 'pink',
    },
  },
  supportText: {
    pb: 1,
    ...fontStyles.fs14_fw400,
    color: styles.color.monotoneDarkDark,
  },
  name: { ...fontStyles.fs16_fw600 },
};
