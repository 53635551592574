export const tenantDetailedViewStyles = {
  backButtonStyle: {
    minWidth: '24px',
    padding: '8px',
    marginRight: 2,
  },
  topActionBar: {
    pb: 3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tenantDomainContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 2,
    width: '110px',
  },
  actionButtonsContainer: {
    alignItems: 'flex-end',
  },
  otpDetailsFinalStep: {
    p: 2,
    width: '100%',
  },
};
