import { useMemo } from 'react';
import { Stack } from '@mui/material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { usePrivileges } from './usePrivileges';

const Privileges = () => {
  const { privileges, isLoading } = usePrivileges();
  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Permissions',
        width: 300,
      },
      {
        field: 'info',
        headerName: 'Details',
        width: 350,
      },
    ],
    [],
  );

  return (
    <Stack sx={{ height: '80vh' }}>
      <DataGrid columns={columns} rows={privileges} loading={isLoading} />
    </Stack>
  );
};

export default Privileges;
