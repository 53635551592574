import { URLS } from 'constants/URL';
import { useEffect, useMemo, useState } from 'react';
import { EMPTY_OBJECT } from 'constants/appConstants';
import { peeringResponseAdapter } from './VPCPeeringsAdapter';
import { useLazyGetCall, useLazyGetCallWithURL } from 'common/api/useApiCall';
import { getLabelValue } from 'common/utils';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import { Button, Link, Stack } from '@mui/material';
import { GetInstanceStatus } from 'customers/db-services-detailed-view/utils';

export const useVPCPeerings = ({ tenant, vnDetails }) => {
  const [selectedPeeringDetails, setSelectedPeeringDetails] = useState(null);
  const [showPeeringDetails, setShowPeeringDetails] = useState(false);

  const url = `${URLS.fetchVPCPeeringsURL}tenant-domain=${tenant?.pgSource}&tenant-id=${tenant?._id}&vpc-id=${vnDetails?.id}`;
  const adapter = peeringResponseAdapter(vnDetails?.cloudType);
  const {
    postData: fetchPeerings,
    response: peerings,
    isLoading,
  } = useLazyGetCall(url, adapter);

  const { postData: fetchPeeringsDetails, response: peeringsDetails } =
    useLazyGetCallWithURL();

  useEffect(() => {
    fetchPeerings();
  }, []);

  useEffect(() => {
    if (peeringsDetails?.id) {
      setSelectedPeeringDetails(peeringsDetails);
      setShowPeeringDetails(true);
    }
  }, [peeringsDetails]);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 300,
      },
      {
        field: 'cloudId',
        headerName: 'Cloud Id',
        renderCell: (params) =>
          params.value ? <OverflowTooltip text={params.value} /> : '-',
      },
      {
        headerName: 'VPC Details',
        renderCell: (params) => {
          const { row = EMPTY_OBJECT } = params;
          const clientVpcInfo = row?.clientVpcInfo || EMPTY_OBJECT;
          const keys = [
            'name',
            'clientVpcId',
            'clientAccountId',
            'cloudId',
            'cidrBlock',
            'cloudType',
            'region',
          ];
          return (
            <>
              {row?.clientVpcId ? (
                <Link
                  underline="hover"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    const url = `${URLS.fetchVPCDetailsURL}vpcId=${row?.clientVpcId}&tenant-domain=${tenant?.pgSource}&tenant-id=${tenant?._id}`;
                    fetchPeeringsDetails(url);
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                  variant="body2"
                >
                  Cloud Account Details
                </Link>
              ) : (
                keys.map(
                  (key) =>
                    clientVpcInfo &&
                    clientVpcInfo[key]?.length && (
                      <OverflowTooltip
                        text={getLabelValue(
                          key.toUpperCase(),
                          clientVpcInfo[key],
                          'body2',
                        )}
                      />
                    ),
                )
              )}
            </>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 200,
        renderCell: (params) => {
          const { row: data } = params;
          const { status } = data;
          return (
            <Stack direction="row" alignItems="center">
              <GetInstanceStatus status={status} />
            </Stack>
          );
        },
      },
    ],
    [peerings],
  );

  const DialogTitle = () => (
    <>{`VPC Details for ${selectedPeeringDetails?.name}`}</>
  );
  const DialogActions = () => (
    <Stack width="100%" direction="row" justifyContent="flex-start" mt={2}>
      <Stack spacing={1} direction="row" justifyContent="flex-end" width="100%">
        <Button
          onClick={() => {
            setSelectedPeeringDetails(null);
            setShowPeeringDetails(false);
          }}
          variant="outlined"
          size="small"
        >
          Close
        </Button>
      </Stack>
    </Stack>
  );
  const DialogContent = () => {
    const keys = ['cloudId', 'cloudType', 'region'];
    const DISPLAY_NAME = {
      cloudId: 'Cloud Id',
      cloudType: 'Cloud',
      region: 'Region',
    };
    return (
      <Stack direction="column" pt={2}>
        {keys?.map((key) =>
          getLabelValue(
            DISPLAY_NAME[key],
            selectedPeeringDetails[key] || '',
            'body2',
          ),
        )}
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: () => DialogContent(),
    actions: DialogActions,
  };

  return {
    peerings,
    columns,
    isLoading,
    showPeeringDetails,
    renderFunctions,
  };
};
