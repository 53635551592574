import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { TenantCostChart } from './Charts/TenantCostChart';
import { ServicesCostChart } from './Charts/ServicesCostChart';
import { ToggleButtonStyles } from 'customers/tenants-list-view/lib/components/TenantListView/styles';
import { ChartType, useSingleTenantCost } from './useSingleTenantCost';
import { isEmpty } from 'lodash';
import { DatePicker } from '@mui/x-date-pickers';
import { dateFormatterCostChart } from './dateFormatter';
import { singleTenantCostStyles } from './styles';
import BetaLabel from 'common/custom-components/lib/components/BetaLabel';
import { DF_DATE_PICKER } from 'helpers/dateFormats';
import { now, servicesMinDate, tenantMinDate } from './constants';

type TenantCostModalProps = {
  open: boolean;
  setOpen: any;
  tenantDomain: string;
};

export const TenantCostModal = (props: TenantCostModalProps) => {
  const { open, setOpen, tenantDomain } = props;

  const {
    chartType,
    showTenantCost,
    tenantCPDPCostIsLoading,
    servicesIsLoading,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    tenantCPDPCost,
    servicesCost,
    handleShowChartTypeChange,
  } = useSingleTenantCost({
    tenantDomain,
  });

  const onClose = () => {
    setOpen(false);
  };

  const DialogTitle = () => {
    return (
      <Stack sx={singleTenantCostStyles.titleContainer}>
        <Stack direction={'row'} spacing={1}>
          <Typography variant="h5" color={'primary'}>
            {chartType === ChartType.Tenant ? 'Tenant' : 'Services'} Cost
          </Typography>
          <BetaLabel />
        </Stack>

        <Stack direction={'row'} spacing={2}>
          <DatePicker
            label="Start"
            renderInput={(params) => <TextField {...params} />}
            value={startTime}
            onChange={(val) => {
              const date = dateFormatterCostChart(val);
              setStartTime(date);
            }}
            inputFormat={DF_DATE_PICKER}
            PopperProps={{
              placement: 'left',
            }}
            minDate={showTenantCost ? tenantMinDate : servicesMinDate}
            maxDate={now}
          />
          <DatePicker
            label="End"
            renderInput={(params) => <TextField {...params} />}
            value={endTime}
            onChange={(val) => {
              const date = dateFormatterCostChart(val);
              setEndTime(date);
            }}
            inputFormat={DF_DATE_PICKER}
            PopperProps={{
              placement: 'right',
            }}
            minDate={startTime}
            maxDate={now}
          />
        </Stack>

        <ToggleButtonGroup
          color="standard"
          value={chartType}
          exclusive
          onChange={handleShowChartTypeChange}
          sx={ToggleButtonStyles.toggleButtonGroup}
        >
          <ToggleButton
            value={ChartType.Tenant}
            sx={ToggleButtonStyles.toggleButtonContainer}
          >
            {ChartType.Tenant}
          </ToggleButton>
          <ToggleButton
            value={ChartType.Services}
            sx={ToggleButtonStyles.toggleButtonContainer}
          >
            {ChartType.Services}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    );
  };

  const DialogActions = () => {
    return <Button onClick={onClose}> Close</Button>;
  };

  const DialogContent = () => {
    return (
      <Stack spacing={2} sx={singleTenantCostStyles.chartContainer}>
        {showTenantCost ? (
          <Stack>
            {tenantCPDPCostIsLoading ? (
              <CircularProgress />
            ) : !tenantCPDPCostIsLoading && !isEmpty(tenantCPDPCost) ? (
              <TenantCostChart tenantCost={tenantCPDPCost} />
            ) : (
              <Typography>No data</Typography>
            )}
          </Stack>
        ) : (
          <Stack>
            {servicesIsLoading ? (
              <CircularProgress />
            ) : !servicesIsLoading && !isEmpty(servicesCost) ? (
              <ServicesCostChart servicesCost={servicesCost} />
            ) : (
              <Typography>No data</Typography>
            )}
          </Stack>
        )}
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  return (
    <TessellModal
      renderFunctions={renderFunctions}
      maxWidth="xl"
      open={open}
      onClose={onClose}
    />
  );
};
