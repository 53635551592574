import { Divider } from '@aws-amplify/ui-react';
import { FileCopyOutlined } from '@mui/icons-material';
import { Box, Typography, Button } from '@mui/material';
import { ShowAttr } from '../components/ServiceOverview';
import { useStyles } from '../utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export function GetNetworkDetails(props) {
  const classes = useStyles();

  const { connectDescriptor, endpoint, servicePort, masterUser } = props;
  return (
    <Box sx={classes.instanceNwInfoBox} p={2} mr={0}>
      <Typography sx={classes.headingText}>Connection Details</Typography>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" sx={classes.dbInfo}>
          {ShowAttr(
            'Service URL',
            connectDescriptor,
            null,
            null,
            null,
            <CopyToClipboard text={connectDescriptor}>
              <Button color="primary" startIcon={<FileCopyOutlined />} />
            </CopyToClipboard>,
            'compact',
          )}
          <Divider />
          {ShowAttr(
            'Host',
            endpoint,
            null,
            null,
            null,
            <CopyToClipboard text={endpoint}>
              <Button color="primary" startIcon={<FileCopyOutlined />} />
            </CopyToClipboard>,
            'compact',
          )}
          <Divider />
          {ShowAttr(
            'Port',
            servicePort,
            null,
            null,
            null,
            <CopyToClipboard text={servicePort}>
              <Button color="primary" startIcon={<FileCopyOutlined />} />
            </CopyToClipboard>,
            'compact',
          )}
          <Divider />
          {ShowAttr(
            'Username',
            masterUser,
            null,
            null,
            null,
            <CopyToClipboard text={masterUser}>
              <Button color="primary" startIcon={<FileCopyOutlined />} />
            </CopyToClipboard>,
            'compact',
          )}
        </Box>
      </Box>
    </Box>
  );
}
