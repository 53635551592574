import { Typography } from '@mui/material';
import { ChipListing } from 'common/ChipListing/ChipListing';

export const ConfirmationComponent = ({ filters = null, text }) => (
  <>
    <Typography variant="h6" my={2}>
      {text}
    </Typography>
    {filters && Object.keys(filters)?.length ? (
      <>
        <ChipListing data={filters} />
      </>
    ) : (
      ''
    )}
  </>
);
