import React, { useState, useMemo, useEffect } from 'react';
import { EXCEPTIONS_BASE_URL, URLS } from 'constants/URL';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { useGetCall, useLazyGetCallWithURL } from 'common/api/useApiCall';
import { adaptExceptionsResponse } from './adaptExceptionsResponse';
import { createParams } from 'helpers/createParams';
import { useCreateExceptionJira } from './useCreateExceptionJira';

export const useExceptions = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({ status: 'All', type: 'All' });
  const [typeFilter, setTypeFilter] = useState([]);

  const [selectedException, setSelectedException] = useState(null);
  const [openExceptionStatusUpdateModal, setOpenExceptionStatusUpdateModal] =
    useState(false);
  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);

  const {
    response: exceptions,
    isLoading,
    postData: fetchExceptions,
  } = useLazyGetCallWithURL(adaptExceptionsResponse);

  const { response: exceptionsCountRes, postData: fetchExceptionsCount } =
    useLazyGetCallWithURL();

  const { response: exceptionTypes, isLoading: groupsIsLoading } = useGetCall(
    `${URLS.fetchExceptionTypes}?is-leak=false`,
  );

  const exceptionsCount = useMemo(() => {
    return exceptionsCountRes?.response?.count;
  }, [exceptionsCountRes]);

  const pageCount = useMemo(
    () => Math.ceil((exceptionsCount || 0) / ITEMS_PER_PAGE),
    [exceptionsCount],
  );

  useEffect(() => {
    if (Array.isArray(exceptionTypes?.response)) {
      setTypeFilter([
        { name: 'All', id: 'All' },
        ...(exceptionTypes?.response.map((type) => ({
          name: type,
          id: type,
        })) || []),
      ]);
    }
  }, [exceptionTypes]);

  const fetchExceptionsAndCount = () => {
    let _url = `${EXCEPTIONS_BASE_URL}?page-size=${ITEMS_PER_PAGE}&page-offset=${
      page - 1
    }&is-leak=false${createParams(filters)}`;
    fetchExceptions(`${_url}`);
    fetchExceptionsCount(
      `${URLS.fetchExceptionsCount}&is-leak=false${createParams(filters)}`,
    );
  };

  useEffect(() => {
    fetchExceptionsAndCount();
  }, [page, filters]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, [filterKey]: val }));
  };
  const handleOnSuccess = () => {
    fetchExceptionsAndCount();
    resetTicketState();
    setShowCreateTicketModal(false);
  };

  const handleResetFilters = () => {
    setFilters({ status: 'All', type: 'All' });
  };

  const handleOnSuccessStatusUpdate = () => {
    fetchExceptionsAndCount();
  };

  const {
    createTicket,
    resetState: resetTicketState,
    ...createTicketProps
  } = useCreateExceptionJira({
    onSuccess: handleOnSuccess,
    exception: selectedException,
  });

  return {
    page,
    exceptions,
    typeFilter,
    filters,
    exceptionsCount,
    isLoading,
    pageCount,
    handlePaginationChange,
    handleFilterChange,
    selectedException,
    setFilters,
    setSelectedException,
    openExceptionStatusUpdateModal,
    setOpenExceptionStatusUpdateModal,
    showCreateTicketModal,
    setShowCreateTicketModal,
    createTicketProps,
    createTicket,
    handleResetFilters,
    handleOnSuccessStatusUpdate,
  };
};
