import { Button, InputAdornment, Stack, Typography } from '@mui/material';

import TessellInput from 'common/custom-components/lib/components/TessellInput';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { useStyles } from 'common/utils';
import { useCreateuser } from './useCreateUser';
import { LoadingButton } from '@mui/lab';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import { adaptToAutocompleteOption } from 'Administration/IAM/Groups/EditGroup/groupResponseAdapter';
import { adaptGroupToautocompleteOption } from 'Administration/IAM/Groups/Common/helper';

type CreateUserModalProps = {
  open: boolean;
  setOpen: any;
  handleCreateUserSuccess: any;
};

export const CreateUserModal = (props: CreateUserModalProps) => {
  const { open, setOpen, handleCreateUserSuccess } = props;
  const classes = useStyles();

  const {
    createUser,
    createUserLoading,
    userEmail,
    userName,
    setUserName,
    setUserEmail,
    onClose,
    initialGroups,
    setInitialGroups,
    groups,
    groupsIsLoading,
    getGroups,
  } = useCreateuser({ handleCreateUserSuccess, setOpen });

  const DialogTitle = () => {
    return <Typography variant="h5">Create User</Typography>;
  };

  const DialogActions = () => {
    return (
      <LoadingButton
        loading={createUserLoading || groupsIsLoading}
        variant="contained"
        disabled={
          userName.length === 0 ||
          userEmail.includes('@') ||
          userEmail.length === 0
        }
        onClick={() => {
          createUser();
        }}
      >
        Create
      </LoadingButton>
    );
  };

  const DialogContent = () => {
    return (
      <Stack width="400px" spacing={2}>
        <TessellInput
          label="Name"
          width="100%"
          value={userName}
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          placeholder="Username"
        />
        <TessellInput
          label="Email"
          width="100%"
          type="email"
          value={userEmail}
          onChange={(e) => {
            setUserEmail(e.target.value);
          }}
          placeholder="Email"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">@tessell.com</InputAdornment>
            ),
          }}
        />
        <TessellAutocomplete
          label="Personas"
          value={initialGroups}
          variant="outlined"
          size="small"
          onChange={(_val) => {
            setInitialGroups(_val);
          }}
          fullWidth
          options={
            adaptToAutocompleteOption(groups, adaptGroupToautocompleteOption) ||
            []
          }
          name="personas"
          multiple
          disabled={createUserLoading || groupsIsLoading}
        />
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    />
  );
};
