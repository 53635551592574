import { useEffect, useMemo, useState } from 'react';
import { COST_DEFAULT_DATE_RANGE } from '../constants';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { costAdapter } from '../adapters';
import { CostEnums } from 'constants/URLEnums';
import { createParams } from 'helpers/createParams';
import { COST_BASE_URL } from 'constants/URL';
import moment from 'moment';
import { dateFormatterCostChart } from 'customers/tenants-detailed-view/lib/components/costSingleTenant/dateFormatter';

type CostDataProps = {
  customCostAdapter?: any;
  costType: CostEnums;
  costParamsKey: string;
};

export const useCostData = (props: CostDataProps) => {
  const { customCostAdapter = costAdapter, costType, costParamsKey } = props;

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [range, setRange] = useState(COST_DEFAULT_DATE_RANGE);
  const [selectedEntities, setSelectedEntities] = useState([]);

  const {
    response: costData,
    isLoading: costDataisLoading,
    postData: getCostData,
  } = useLazyGetCallWithURL(customCostAdapter);

  const costParams = useMemo(() => {
    return {
      'start-time': startTime,
      'end-time': endTime,
      'cost-type': costType,
      ...(selectedEntities?.length && {
        [costParamsKey]: selectedEntities.join(','),
      }),
    };
  }, [startTime, endTime, selectedEntities]);

  useEffect(() => {
    if (startTime && endTime)
      getCostData(`${COST_BASE_URL}?${createParams(costParams)}`);
  }, [costParams]);

  useEffect(() => {
    if (range === 'Custom') {
      return;
    }
    const et = moment(new Date());
    let st;
    switch (range) {
      case '1w':
        st = moment(new Date()).subtract(1, 'weeks');
        break;
      case '2w':
        st = moment(new Date()).subtract(2, 'weeks');
        break;
      case '3w':
        st = moment(new Date()).subtract(3, 'weeks');
        break;
      case '1m':
        st = moment(new Date()).subtract(1, 'month');
        break;
      default:
    }
    setStartTime(dateFormatterCostChart(st));
    setEndTime(dateFormatterCostChart(et?.toString()));
  }, [range]);

  const handleDateToggle = (e, v) => {
    if (v) {
      setRange(v);
    }
  };

  const updateTimeUsingNativePicker = (start, end) => {
    setStartTime(dateFormatterCostChart(start));
    setEndTime(dateFormatterCostChart(end));
  };

  return {
    startTime,
    endTime,
    selectedEntities,
    setSelectedEntities,
    costData,
    costDataisLoading,
    updateTimeUsingNativePicker,
    handleDateToggle,
    range,
  };
};
