/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from 'react';
import { Typography, Box, Stack, CircularProgress } from '@mui/material';
import useVirtualNetworksTableStyles from './VirtualNetworksTable.styles';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import { GetInstanceStatus } from 'customers/db-services-detailed-view/utils';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import UpdateMetadata from 'common/UpdateMetadata/UpdateMetadata';
import { CloudAccountDetailsModal } from 'common/Modals/CloudAccountDetailsModal/CloudAccountDetailsModal';
import { useVirtualNetworkTable } from './useVirtualNetworkTable';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';

interface VirtualNetworksTableProps {
  tenant: TenantType;
}
export default function VirtualNetworksTable(props: VirtualNetworksTableProps) {
  const { tenant } = props;
  const { hasPermission } = useContext(UserPermissionsContext);

  const {
    page,
    open,
    setOpen,
    cloudAccountID,
    setCloudAccountID,
    subIdNameMap,
    filteredList,
    regionMap,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    selectedVn,
    setSelectedVn,
    handleRowClick,
    vpcsIsLoading,
    vpcsCountIsLoading,
    vpcsCount,
    vpcsPageCount,
    handlePaginationChange,
  } = useVirtualNetworkTable({ tenant: tenant });

  const classes = useVirtualNetworksTableStyles();

  const networkColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 300,
        renderCell: (params) => (
          <>
            <Stack
              direction="row"
              alignItems="center"
              mr={1}
              sx={classes.boldText}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack direction="column">{params.value}</Stack>
              </Stack>
            </Stack>
          </>
        ),
      },
      {
        field: 'subscriptionId',
        headerName: 'Subscription',
        width: 100,
        renderCell: (params) => {
          const { row } = params;
          return (
            <OverflowTooltip
              text={subIdNameMap?.[row?.subscriptionId] || '-'}
              placement="right"
            />
          );
        },
      },
      {
        field: 'cidrBlock',
        headerName: 'IP Address Range',
        width: 200,
      },
      {
        field: 'cloudAvailability',
        headerName: 'Cloud/Region',
        width: 220,
        renderCell: (params) => {
          const { row } = params;
          let region = '';
          if (regionMap[row?.region]) {
            region = regionMap[row?.region];
          }
          region = row?.region || '-';
          const regionText = `${region}`;
          return (
            <Stack
              onClick={(ev) => {
                ev.stopPropagation();
                setCloudAccountID(row.cloudAccountId);
                setOpen(true);
              }}
            >
              <Typography sx={classes.boldText} color="textPrimary">
                {row.cloudType}
              </Typography>
              <OverflowTooltip
                text={regionText}
                variant="body2"
                placement="right"
              />
            </Stack>
          );
        },
      },
      {
        field: 'internalStatus',
        headerName: 'Internal Status',
        width: 200,
        renderCell: (params) => {
          const { row } = params;
          return <GetInstanceStatus status={row?.internalStatus} />;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 200,
        renderCell: (params) => {
          const { row } = params;
          return <GetInstanceStatus status={row?.status} />;
        },
      },
      {
        field: 'maturityStatus',
        headerName: 'Maturity',
        width: 200,
        renderCell: (params) => {
          const { row } = params;
          const { maturityStatus } = row;
          if (!maturityStatus) {
            return null;
          }
          return <Box>{capitaliseFirstLetterInEachWord(maturityStatus)}</Box>;
        },
      },
    ],
    [regionMap, filteredList],
  );

  const menuItemsProps = useMemo(
    () =>
      [
        hasPermission(PERMISSIONS.UPDATE_TENANT) && {
          label: 'Update Metadata',
          onClick: (row) => {
            setSelectedVn(row);
            setUpdateMetadataOpen(true);
          },
        },
      ].filter((a) => a),
    [],
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Stack sx={{ height: '100vh' }}>
        <DataGrid
          componentName="virtual-net"
          columns={networkColumns}
          rows={filteredList}
          styleOverrides={{ smallWidth: true }}
          onRowClick={handleRowClick}
          components={{
            Toolbar: CustomDataGridToolbar,
            DetailPanelCollapseIcon: ExpandMore,
            DetailPanelExpandIcon: ChevronRight,
          }}
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          loading={vpcsIsLoading || vpcsCountIsLoading}
          menuItems={menuItemsProps}
          pageNo={page}
          totalCount={vpcsCount || 0}
          handlePaginationChange={handlePaginationChange}
          pageCount={vpcsPageCount}
        />
        <UpdateMetadata
          open={updateMetadataOpen}
          setOpen={setUpdateMetadataOpen}
          entityType="vpc"
          entityName={selectedVn?.name}
          tenantId={tenant?._id}
          tenantDomain={tenant?.pgSource}
          entityId={selectedVn?.id}
        />
        <CloudAccountDetailsModal
          open={open}
          setOpen={setOpen}
          title="Cloud Account Details"
          cloudAccountID={cloudAccountID}
        />
      </Stack>
    </Box>
  );
}
