import { useMemo } from 'react';
import { Paper, Stack } from '@mui/material';
import _debounce from 'lodash/debounce';
import { ApexOptions } from 'apexcharts';

import _merge from 'lodash/merge';
import { millisecondsInSecond } from 'date-fns/constants';
import { AdaptedMetricType, LegendType } from '../monitoringTypes';
import { BasicAreaCharts } from '../../../../common/charts/BasicAreaCharts';
import { getCustomChartOptionForAvailabilityChart } from '../getCustomChartOptionForAvailabilityChart';
import { styles, useStyles } from '../monitoringCustomStyles';

export const AvailabilityChart = (props) => {
  const { xMin, xMax, updateTimeUsingChartZoomIn, availabilityChart } = props;
  const chart: AdaptedMetricType = availabilityChart?.[0];
  let maxLabel = 0;
  chart?.dataSets?.forEach((legend) => {
    if (maxLabel && legend?.labelSet)
      maxLabel = Math.max(maxLabel, ...legend.labelSet);
  });

  const labelInterval = useMemo(
    () =>
      getLabelIntervalValue(chart, {
        xMin,
        xMax,
      }),
    [chart],
  );
  const finalChart = useMemo(
    () =>
      getFormattedAvChart(chart, labelInterval, {
        xMin,
        xMax,
      }),
    [chart],
  );

  const newChartOptions: ApexOptions = {
    chart: {
      height: 125,
      animations: {
        enabled: false,
        easing: 'easeinout',
        speed: 0,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 300,
        },
      },

      events: {
        zoomed: (chart: any, options?: any) => {
          updateTimeUsingChartZoomIn(chart, options);
        },
      },
    },
    xaxis: {
      min: xMin,
      max:
        xMax - maxLabel <= labelInterval ||
        xMax - maxLabel <= 60 * millisecondsInSecond
          ? maxLabel
          : xMax,
    },

    tooltip: {
      shared: true,
      followCursor: true,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const statusString = series[0][dataPointIndex]
          ? 'Service Available'
          : 'Service Unavailable';
        const legendColor = series[0][dataPointIndex]
          ? '#4DAC4A'
          : styles.color.greyBackground2;
        const fontColor = series[0][dataPointIndex]
          ? styles.color.textBoxLabel
          : styles.color.monotoneDarkDark;
        return `<div style = "
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            background:#F9F9F9;"
                        >
                            <div style = "
                                margin-left: 12px;
                                height: 12px;
                                width: 12px;
                                border-radius: 50%;
                                background-color:${legendColor};"
                            >
                            </div>
                            <div style = "
                                color:${fontColor}; 
                                padding:12px 12px 12px 12px; 
                                font-family:Lato; 
                                font-size:16px; 
                                font-weight:700;" 
                            >
                                ${statusString}
                            </div>
                    </div>`;
      },
    },
  };

  const chartOptionForAvailabilityChart: ApexOptions = _merge(
    getCustomChartOptionForAvailabilityChart(),
    newChartOptions,
  );
  const classes = useStyles();

  return (
    <>
      {!!availabilityChart?.[0] && (
        <Paper
          elevation={0}
          sx={{
            ...classes.monTile,
            maxWidth: 'none',
            marginTop: '16px',
            paddingLeft: '24px',
            width: '100%',
          }}
        >
          <Stack sx={{ flex: '1 1 auto' }}>
            <BasicAreaCharts
              chart={finalChart}
              chartOptions={chartOptionForAvailabilityChart}
              customColor={['#4DAC4A', styles.color.greyBackground2]}
              showFullScreenToolbar={false}
              showLatestValue={false}
              showUnit={false}
            />
          </Stack>
        </Paper>
      )}
    </>
  );
};

const getLabelIntervalValue = (
  chart: AdaptedMetricType,
  xRange: { xMin: number; xMax: number },
) => {
  const labelSet = chart?.dataSets?.[0]?.labelSet;
  let prevLabelValue = labelSet?.[0];
  const labelDiffObj = {};
  labelSet?.forEach((label) => {
    if (!labelDiffObj[label - prevLabelValue]) {
      labelDiffObj[label - prevLabelValue] = 1;
    } else {
      labelDiffObj[label - prevLabelValue] =
        labelDiffObj[label - prevLabelValue] + 1;
    }
    prevLabelValue = label;
  });

  let labelInterval = '0';
  let labelIntervalCount = 0;
  Object.keys(labelDiffObj)?.forEach((labelDiff) => {
    if (labelIntervalCount < labelDiffObj[labelDiff]) {
      labelIntervalCount = labelDiffObj[labelDiff];
      labelInterval = labelDiff;
    }
  });
  return parseInt(labelInterval, 10);
};

const getFormattedAvChart = (
  chart: AdaptedMetricType,
  labelInterval: number,
  xRange: { xMin: number; xMax: number },
) => {
  if (!chart) return chart;
  const { xMin, xMax } = xRange;
  const dataSets = chart?.dataSets;
  const dataSet = dataSets?.[0]?.dataSet;
  const labelSet = dataSets?.[0]?.labelSet;

  const unavailabilityChart: LegendType = {
    labelSet: [],
    dataSet: [],
    minVal: null,
    maxVal: 1,
    displayName: 'Unavailable',
    additionalMetricInfo: 'unavailable_service',
  };

  const availabilityChart: LegendType = {
    labelSet: [],
    dataSet: [],
    minVal: null,
    maxVal: 1,
    displayName: 'Available',
    additionalMetricInfo: 'available_service',
  };

  // check if we can move the if statement into the for loop and then make it into map callback function
  if (dataSet[0]) {
    availabilityChart.labelSet.push(labelSet[0]);
    availabilityChart.dataSet.push(1);
    unavailabilityChart.labelSet.push(labelSet[0]);
    unavailabilityChart.dataSet.push(null);
  } else {
    availabilityChart.labelSet.push(labelSet[0]);
    availabilityChart.dataSet.push(null);
    unavailabilityChart.labelSet.push(labelSet[0]);
    unavailabilityChart.dataSet.push(1);
  }

  for (let i = 1; i < labelSet?.length; i += 1) {
    if (dataSet[i] === dataSet[i - 1] && dataSet[i]) {
      availabilityChart.labelSet.push(labelSet[i]);
      availabilityChart.dataSet.push(1);
      unavailabilityChart.labelSet.push(labelSet[i]);
      unavailabilityChart.dataSet.push(null);
    } else if (dataSet[i] === dataSet[i - 1] && !dataSet[i]) {
      availabilityChart.labelSet.push(labelSet[i]);
      availabilityChart.dataSet.push(null);
      unavailabilityChart.labelSet.push(labelSet[i]);
      unavailabilityChart.dataSet.push(1);
    } else if (dataSet[i] !== dataSet[i - 1] && !dataSet[i]) {
      availabilityChart.labelSet.push(labelSet[i]);
      availabilityChart.dataSet.push(null);
      unavailabilityChart.dataSet[unavailabilityChart.dataSet.length - 1] = 1;
      unavailabilityChart.labelSet.push(labelSet[i]);
      unavailabilityChart.dataSet.push(1);
    } else if (dataSet[i] !== dataSet[i - 1] && dataSet[i]) {
      availabilityChart.labelSet.push(labelSet[i]);
      availabilityChart.dataSet.push(1);
      unavailabilityChart.labelSet.push(labelSet[i]);
      unavailabilityChart.dataSet.push(1);
    }
  }

  const finalChart = {
    ...chart,
    dataSets: [availabilityChart, unavailabilityChart],
  };
  return finalChart;
};
