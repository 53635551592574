import { DB_SERVICES_BASE_URL } from 'constants/URL';
import { useState } from 'react';
import { adaptDatabasesResponse } from './adaptDatabsesResponse';
import { useDataAndCount } from 'common/Hooks/useDataAndCount';

type DatabasesProps = {
  dbServiceId?: string;
};

export const useDatabases = (props: DatabasesProps) => {
  const { dbServiceId } = props;
  const [selectedDatabase, setSelectedDatabase] = useState({} as any);
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);

  const {
    count: databasesCount,
    pageCount: databasesPageCount,
    dataResponse: databases,
    dataIsLoading: databasesIsLoading,
    countIsLoading: databasesCountIsLoading,
    page,
    handlePaginationChange,
  } = useDataAndCount({
    url: `${DB_SERVICES_BASE_URL}/${dbServiceId}/databases`,
    dataResponseAdapter: adaptDatabasesResponse,
  });

  return {
    page,
    handlePaginationChange,
    databases,
    databasesIsLoading,
    databasesCountIsLoading,
    databasesCount,
    databasesPageCount,
    selectedDatabase,
    setSelectedDatabase,
    updateMetadataOpen,
    setUpdateMetadataOpen,
  };
};
