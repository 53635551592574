export const DF = 'YYYY-MM-DD HH:mm:ss';
export const DF_DATE = 'MMM Do, YYYY';
export const DF_TIME = 'h:mm a';
export const DF_DAY = 'ddd MMM Do, YYYY';
export const DF_HUMAN = 'MMM D, YYYY HH:mm:ss';
export const DF_HUMAN2 = 'ddd MMM Do, YYYY HH:mm:ss';
export const DF_HUMAN3 = 'ddd MMM DD, YYYY h:mm a';
export const DF_HUMAN4 = 'ddd MMM DD, YYYY h:mm:ss a';
export const DF_HUMAN5 = 'MMM DD, YYYY h:mm:ss a';
export const DF_HUMAN_CHART = 'MMM D, YY';
export const DF_HUMAN_WITHOUT_TIME = 'MMM D, YYYY';
export const DF_DATE_PICKER = 'dd/MM/yyyy';
export const DF_DATE_TIME_PICKER = 'MMM d, yyyy HH:mm:ss';
export const DF_DOWNLOAD = 'MMMM Do YYYY, h:mm:ss a';
