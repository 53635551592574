import { Box, Stack, Typography } from '@mui/material';

export const ParseText = (text) => {
  const temp = text.split("'").map((phrase, i) => {
    if (i % 2 === 1) {
      return (
        <Box
          fontWeight="fontWeightMedium"
          display="inline"
        >{` ${phrase} `}</Box>
      );
    }
    return <Typography display="inline">{` ${phrase} `}</Typography>;
  });
  return (
    <Stack direction="row" display="inline">
      {temp}
    </Stack>
  );
};
