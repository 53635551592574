import { styles } from 'assets/scss/style-templates';

export const costBackgroundContainerStyle = {
  borderRadius: 2,
  backgroundColor: styles.color.baseBackground,
  border: `1px solid ${styles.color.darkBorderGrey}`,
};

export const costToggleButtonStyle = {
  '& .MuiToggleButtonGroup-grouped': {
    borderRadius: '4px',
  },
};

export const costLayout = {
  topLevelContainer: {
    pt: 8.5,
    backgroundColor: styles.color.monotoneLightDark,
    height: 'auto',
  },
  titleContainer: {
    width: '17%',
    pl: 2,
    py: 2,
    height: '70px',
    position: 'fixed',
    top: 70,
    zIndex: 100,
  },
  contentContainer: {},
  topBarContainer: {
    height: '70px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100vw',
    right: 1,
    position: 'fixed',
    backgroundColor: styles.color.monotoneLightDark,
    zIndex: 99,
    top: 65,
  },
  topBarContentContainer: {
    pr: 2,
    alignItems: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    width: '82%',
    height: '100%',
    pt: 1.5,
  },
  graphAndTableContainer: {
    px: 2,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'relative',
  },
  loaderContainer: {
    width: '90%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chartContainer: {
    width: '68%',
    position: 'relative',
    top: 73,
    height: '100%',
  },
  tableAndPieContainer: {
    height: '83vh',
    width: '30vw',
    ...costBackgroundContainerStyle,
    p: 2,
    position: 'fixed',
    right: 2,
    top: 140,
  },
  tableAndPieTopBarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableandPieToggleIcon: { fontSize: 'medium' },
  dayWiseTableContainer: {
    p: 2,
    mb: 2,
    ...costBackgroundContainerStyle,
  },
  dayWiseTable: {
    'overflow-x': 'scroll',
    py: 2,
  },
  multiLineChartContainer: {
    py: 1,
    px: 2,
    height: '83vh',
    ...costBackgroundContainerStyle,
  },
  topBox: {
    backgroundColor: styles.color.monotoneLightDark,
    pb: 8.5,
  },
};

export const dayWiseCostTableStyles = {
  rows: {
    '&:nth-of-type(odd)': {
      backgroundColor: styles.color.greyBackground,
    },
    '&:last-child td, &:last-child th': { border: 0 },
  },
  columnHead: {
    backgroundColor: styles.color.lightShade,
    color: styles.color.baseBackground,
  },
};

export const legendStylesLineChart = {
  '& .apexcharts-legend-series .apexcharts-legend-text': {
    position: 'relative',
    top: '-3px',
  },
};

export const noDataStyles = {
  width: '100%',
  height: '84.5vh',
  alignItems: 'center',
  justifyContent: 'center',
};
