export const ALERT_STATUS_LIST = [
  {
    id: 'resolved',
    name: 'Resolved',
  },
  {
    id: 'firing',
    name: 'Firing',
  },
];

export const ALERT_PRIORITY_LIST = [
  {
    id: 'URGENT',
    name: 'URGENT',
  },
  {
    id: 'HIGH',
    name: 'HIGH',
  },
  {
    id: 'MEDIUM',
    name: 'MEDIUM',
  },
  {
    id: 'LOW',
    name: 'LOW',
  },
];
