import { Button, InputAdornment, Stack, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { useStyles } from 'common/utils';

type AddCreditsModalProps = {
  open: boolean;
  setOpen: any;
  credits: number;
  setCredits: any;
  title: string;
  tenantId: string;
  tenantDomain: string;
  onNextClick: any;
  subTitle?: string;
};

export const AddCreditsModal = (props: AddCreditsModalProps) => {
  const {
    credits,
    setCredits,
    title,
    open,
    setOpen,
    tenantId,
    tenantDomain,
    onNextClick,
    subTitle,
  } = props;
  const classes = useStyles();

  const onClose = () => {
    setOpen(false);
  };

  const DialogTitle = () =>
    title ? (
      <Stack>
        {title}
        {subTitle && <Typography>{subTitle}</Typography>}
      </Stack>
    ) : (
      <>{'Add Credits'}</>
    );

  const DialogActions = () => {
    return (
      <Button
        variant="contained"
        disabled={credits === 0}
        onClick={() => {
          onNextClick();
        }}
      >
        Next
      </Button>
    );
  };

  const DialogContent = () => {
    return (
      <Stack mt={2}>
        <Stack width="400px" spacing={2}>
          <Stack spacing={1}>
            <ShowLabelValue label="Tenant Id" value={tenantId} />
            <ShowLabelValue label="Tenant Domain" value={tenantDomain} />
          </Stack>

          <TessellInput
            label="Enter Additional Credits to add"
            labelStyles={{ color: styles.color.monotoneDarker }}
            width="100%"
            value={credits}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            inputProps={{ min: 0, max: 10000 }}
            onChange={(e) => {
              setCredits(e.target.value);
            }}
            placeholder="Please enter additional credits to add"
            InputAdornment="$"
          />
        </Stack>
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    />
  );
};
