import { Stack, Tooltip, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import { DEFAULT_NULL } from 'constants/appConstants';

type AdditionalDataTooltipProps = {
  list?: string[];
  tooltipListTitle: string;
};

const additionalDataTooltipStyles = {
  moreText: {
    background: styles.color.darkBorder,
    fontSize: '12px',
    color: styles.color.baseBackground,
    borderRadius: 1,
    p: 0.5,
  },
};

export const AdditionalDataListTooltip = (
  props: AdditionalDataTooltipProps,
) => {
  const { list, tooltipListTitle } = props;
  return list?.length <= 1 ? (
    <Stack>{list?.[0] || DEFAULT_NULL}</Stack>
  ) : (
    <Tooltip
      arrow
      title={
        <Stack>
          <Typography>{tooltipListTitle}</Typography>
          <Stack>
            {list?.map((text, index) => {
              if (index === 0) return null;
              return <Typography>{text}</Typography>;
            })}
          </Stack>
        </Stack>
      }
    >
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography>{list[0]}</Typography>
        <Typography sx={additionalDataTooltipStyles.moreText}>
          + {list.length - 1} more
        </Typography>
      </Stack>
    </Tooltip>
  );
};
