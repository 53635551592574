/* eslint-disable react/react-in-jsx-scope */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date               Author                  Comment
 *   --------------------------------------------------
 *     12/22/2021     bakul.banthia         Created
 *
 */

import clsx from 'clsx';
import { useStyles } from './utils';

export default function BenchmarkIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6042 15.4861L15.8628 16.5669C16.0052 16.6891 16.2187 16.6797 16.3436 16.5397C17.6848 15.037 18.5 13.0547 18.5 10.882C18.5 6.18755 14.6944 2.38197 10 2.38197C5.30558 2.38197 1.5 6.18755 1.5 10.882C1.5 12.9818 2.26139 14.9037 3.52315 16.3868C3.65007 16.536 3.87514 16.5441 4.01867 16.4108L5.39583 15.132"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M14.5 6.49999L8.86917 10.8314C8.44257 11.1596 8.46459 11.8097 8.9124 12.1083C9.24658 12.331 9.69498 12.266 9.9521 11.9575L14.5 6.49999Z"
        fill="#D9D9D9"
        stroke="var(--icon-color)"
        strokeLinejoin="round"
      />
      <path d="M2.20825 10.882H3.62492" stroke="var(--icon-color)" strokeLinecap="round" />
      <path
        d="M16.375 10.382C16.0989 10.382 15.875 10.6058 15.875 10.882C15.875 11.1581 16.0989 11.382 16.375 11.382V10.382ZM17.7917 11.382H18.2917V10.382H17.7917V11.382ZM16.375 11.382H17.7917V10.382H16.375V11.382Z"
        fill="var(--icon-color)"
      />
      <path d="M5.34631 6.21704L4.34458 5.21531" stroke="var(--icon-color)" strokeLinecap="round" />
      <path d="M10 4.50697V3.09031" stroke="var(--icon-color)" strokeLinecap="round" />
    </svg>
  );
}
