import { ENV, ENV_VARIABLES } from './env';

export const EMPTY_STRING = '';
export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const DEFAULT_UNKNOWN = 'TBD';
export const DEFAULT_NULL = '-';
export const DEFAULT_NA = 'N/A';

export const DEFAULT = {
  enableSSL: false,
  dnsPrefix: null,
  servicePort: 3306,
  enablePublicAccess: true,
  allowedIpAddresses: ['185.182.193.32'],
};

export const REFRESH_TOKEN_AFTER: number = 58 * 6000;

export const ITEMS_PER_PAGE = 21;
export const DBSERVICES_PER_PAGE = 10;
export const TICKETS_PER_PAGE = 30;

export const JIRA_BASE_LINK = 'https://tessell.atlassian.net/browse/';
export const FRESHDESK_BASE_LINK = ENV_VARIABLES[ENV].FRESHDESK_BASE_LINK;
