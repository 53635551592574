/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/7/2021     bakul.banthia         Created
 *
 */

import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';
import {
  Box,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
  Menu,
} from '@mui/material';
import { MoreVert, RotateLeft, Search } from '@mui/icons-material';
import {
  DataGridPro as DataGridMui,
  gridClasses,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import _debounce from 'lodash/debounce';
import TessellPagination from 'common/Pagination/TessellPagination';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import ExportContainer from 'customers/tenants-list-view/lib/components/ExportData/ExportContainer';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { DF_DATE_TIME_PICKER } from 'helpers/dateFormats';

export const useStyles = (styleOverrides) => ({
  filter: {
    width: styleOverrides?.smallWidth ? '13vw' : 250,
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
    },
    '& .MuiFilledInput-root': {
      borderRadius: '24px',
    },
    marginInlineEnd: '16px',
    marginBottom: '8px',
  },
  contentBox: {
    boxSizing: 'border-box',
    borderRadius: '10px',
    ...(!styleOverrides?.hideShadow
      ? { boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25) !important' }
      : {
          border: '1px solid #E6E6E6',
          '& .MuiDataGrid-columnHeaders': {
            borderTopRightRadius: '9px',
            borderTopLeftRadius: '9px',
          },
        }),
    ...(styleOverrides?.boxShadow && {
      boxShadow: `${styleOverrides?.boxShadow} !important`,
    }),
    background: 'white',
    '& .actions': {
      color: 'text.secondary',
    },
    '& .actionButton': {
      visibility: 'hidden',
    },
    [`& .${gridClasses.row}:hover`]: {
      '.actionButton': {
        visibility: 'visible',
      },
    },
    '& .disabled-row': {
      cursor: 'not-allowed !important',
    },
    '& .enabled-row': {
      cursor: 'pointer !important',
    },
  },
  clickable: {
    '& .MuiDataGrid-row': {
      // ----------------------------------------------------------------
      // todo: integrate disable functionality for specific row based on status
      // perfromed by siddharth and saakshya
      // cursor: `${
      //   styleOverrides?.disableRowClick ? 'not-allowed !important' : 'pointer !important'
      // }`,
      // ----------------------------------------------------------------
      cursor: 'pointer',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      color: 'rgba(30, 126, 161, 0.4) !important',
    },
  },
  notClickable: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      color: 'rgba(30, 126, 161, 0.4) !important',
    },
    '& .MuiDataGrid-row': {
      // ----------------------------------------------------------------
      // todo: integrate disable functionality for specific row based on status
      // perfromed by siddharth and saakshya
      // cursor: `${
      //   styleOverrides?.disableRowClick ? 'not-allowed !important' : 'pointer !important'
      // }`,
      // ----------------------------------------------------------------
      cursor: 'default !important',
    },
  },
  actionButton: {
    width: styleOverrides?.smallWidth && '6vw', // makes create and register button in networks tab the same width
    minWidth: styleOverrides?.smallWidth && '100px',
  },
});

function NoRows() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography>{`You don't have any data for the given filter.`}</Typography>
    </Box>
  );
}

export default function DataGrid(props) {
  const {
    clickable,
    fullHeight,
    width,
    styleOverrides,
    componentName = '',
    search,
    filters,
    actions,
    menuItems,
    hidePagination = false,
    rows,
    columns,
    totalCount,
    itemsPerPage,
    rowMenuOpenCallback = () => {},
    showMenuButtonOnHover = false,
    customNoRowsOverlay = undefined,
    customExportData,
    ...datagridProps
  } = props;
  const classes = useStyles(styleOverrides);
  const {
    treeData,
    getTreeDataPath,
    apiFilter,
    onResetFilters,
    pageCount,
    pageNo,
    handlePaginationChange,
  } = datagridProps;

  const [columnWidth, setColumnWidth] = useState(columns?.map((c) => c.width));

  const showMenu = useMemo(
    () => typeof menuItems === 'function' || menuItems.length > 0,
    [menuItems],
  );

  const [searchText, setSearchText] = useState('');
  const [filterVal, setfilterVal] = useState({});
  const [refreshFilter, setRefreshFilter] = useState(true);
  const [rowMenuEl, setRowMenuEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const colFlex = useMemo(() => columnWidth.map((c) => c / 100), []);

  const searchParams = useMemo(() => {
    if (!search) {
      return null;
    }
    const {
      name,
      onChange,
      filterFunc,
      columns = [],
      ...textFieldProps
    } = search;
    return {
      name,
      onChange: typeof onChange === 'function' ? onChange : () => {},
      filterFunc:
        typeof filterFunc === 'function'
          ? filterFunc
          : (row, txt) => {
              if (columns.length === 0) {
                return true;
              }
              return columns?.reduce(
                (isFilter, col) =>
                  isFilter ||
                  (typeof row[col] === 'string' &&
                    row[col]?.toLowerCase().indexOf(txt.toLowerCase()) !==
                      -1) ||
                  (Array.isArray(row[col]) &&
                    row[col].some(
                      (t) => t.toLowerCase().indexOf(txt.toLowerCase()) !== -1,
                    )),
                false,
              );
            },
      textFieldProps,
    };
  }, [search]);

  const filterParams = useMemo(
    () =>
      filters.reduce((obj, filter) => {
        if (!(Array.isArray(filters) && filters.length > 0)) {
          return null;
        }
        const {
          name,
          initialVal,
          options,
          onChange,
          filterFunc,
          column,
          ...textFieldProps
        } = filter;
        return {
          ...obj,
          [name]: {
            name,
            options,
            onChange: typeof onChange === 'function' ? onChange : () => {},
            filterFunc:
              typeof filterFunc === 'function'
                ? filterFunc
                : (row, val) => {
                    if (!column || !val || val === 'All') {
                      return true;
                    }
                    return row[column] === val;
                  },
            ...textFieldProps,
          },
        };
      }, {}),
    [filters],
  );

  const treeView = useMemo(
    () =>
      _reduce(
        rows,
        (obj, row) => {
          if (typeof getTreeDataPath === 'function') {
            const path = getTreeDataPath(row);
            return {
              ...obj,
              [JSON.stringify(path)]: row,
            };
          }
          return obj;
        },
        {},
      ),
    [treeData, rows],
  );

  useEffect(() => {
    if (search) {
      setSearchText(search.initialVal || '');
    }
  }, [search]);

  useEffect(() => {
    if (Array.isArray(filters) && filters.length > 0) {
      setfilterVal((prev) => ({
        ...prev,
        ...filters.reduce((obj, filter) => {
          const { name, initialVal } = filter;
          return {
            ...obj,
            [name]: initialVal,
          };
        }, {}),
      }));
    }
  }, [filters]);

  const resetFilters = () => {
    const resetFiltersObj = filterVal;
    filters.map((filter) => {
      resetFiltersObj[filter.name] = filter.initialVal;
      return null;
    });
    setfilterVal({ ...resetFiltersObj });
    onResetFilters?.();
    setSearchText('');
  };

  useEffect(() => {
    if (
      Object.keys(filterVal).length > 0 &&
      filters.length > 0 &&
      refreshFilter
    ) {
      filters.forEach(({ name, onChange }) => {
        if (onChange) {
          onChange(filterVal[name]);
        }
      });
      setRefreshFilter(false);
    }
  }, [filters, filterVal]);

  const onSearchChange = (e) => {
    searchParams?.onChange?.(e.target.value);
  };

  const debouncedResults = useMemo(() => _debounce(onSearchChange, 500), []);

  useEffect(() => {
    debouncedResults.cancel();
  }, []);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    if (apiFilter) {
      debouncedResults(e);
    } else {
      onSearchChange(e);
    }
  };

  function GetMenuItems(menuItemsList, customSelectedRow) {
    return menuItemsList.map((item) => {
      const {
        customComponent,
        label,
        icon,
        onClick = () => {},
        visible = () => true,
        disabled = () => false,
        ...menuProps
      } = item;
      if (!visible(selectedUser || customSelectedRow)) {
        return null;
      }
      if (customComponent) {
        return customComponent();
      }
      return (
        <MenuItem
          size="small"
          sx={{ justifyContent: 'flex-start' }}
          color="primary"
          disabled={disabled(selectedUser)}
          dense
          onClick={() => {
            onClick(selectedUser);
            setRowMenuEl(null);
          }}
          {...menuProps}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {icon}
            <div>{label}</div>
          </Stack>
        </MenuItem>
      );
    });
  }

  const findMenuItemsForRow = useCallback(
    (row) => {
      if (!row) {
        return null;
      }
      const menuItemsList =
        typeof menuItems === 'function' ? menuItems(row) : menuItems;
      if (!menuItemsList.length) {
        return null;
      }
      return GetMenuItems(menuItemsList, row).filter(Boolean);
    },
    [menuItems],
  );

  const rowToMenuItemsMap = useRef({});

  useEffect(() => {
    rows?.forEach((row) => {
      const rowId = row?.id;
      rowToMenuItemsMap.current[rowId] = findMenuItemsForRow(row);
    });
    return () => {
      rowToMenuItemsMap.current = {};
    };
  }, [rows, findMenuItemsForRow]);

  const menuList = useMemo(
    () => rowToMenuItemsMap.current?.[(selectedUser as any)?.id] || [],
    [selectedUser, rowToMenuItemsMap],
  );

  const getMenuItemsForRow = (row) => rowToMenuItemsMap.current?.[row?.id];

  const toggleMenu = (e, row) => {
    setRowMenuEl(e ? e.currentTarget : null);
    setSelectedUser(row);
    if (row) {
      rowMenuOpenCallback(row);
    }
  };

  function getFilteredTasks() {
    if (apiFilter) {
      return rows;
    }
    let filteredRows = _filter(rows, (t) =>
      searchParams?.filterFunc(t, searchText),
    ).filter((t) =>
      Object.keys(filterParams).reduce((isFilter, filterName) => {
        const { filterFunc } = filterParams[filterName];
        const val = filterVal[filterName];
        return isFilter && filterFunc(t, val);
      }, true),
    );

    if (treeData) {
      const paths: Array<any> = [];
      filteredRows.forEach((row) => {
        const path = getTreeDataPath(row);
        const leaf: Array<any> = [];
        path.forEach((p) => {
          leaf.push(p);
          paths.push(JSON.stringify(leaf));
        });
      });
      filteredRows = paths
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((p) => treeView[p]);
    }
    return filteredRows;
  }

  function GetSearch() {
    const { name, textfieldProps } = searchParams as any;
    return (
      <TextField
        label={name}
        name={name}
        value={searchText || ''}
        size="small"
        variant="standard"
        sx={classes.filter}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        {...textfieldProps}
      />
    );
  }

  function GetFilters() {
    return Object.keys(filterParams).map((filterName) => {
      const {
        name,
        options,
        type,
        onChange,
        startTime,
        endTime,
        maxTime,
        startDateAndTime,
        endDateAndTime,
        ...textfieldProps
      } = filterParams[filterName];
      switch (type) {
        case 'date':
          return (
            <>
              <TextField
                label="Start Date"
                variant="standard"
                type="date"
                size="small"
                value={startTime}
                InputProps={{
                  inputProps: { max: endTime },
                }}
                sx={classes.filter}
                onChange={(e) => onChange('start-time', e.target.value)}
              />
              <TextField
                label="End Date"
                variant="standard"
                type="date"
                size="small"
                value={endTime}
                InputProps={{
                  inputProps: { max: maxTime },
                }}
                sx={classes.filter}
                onChange={(e) => onChange('end-time', e.target.value)}
              />
            </>
          );

        case 'dateAndTime':
          return (
            <>
              <MobileDateTimePicker
                label="Start"
                value={startDateAndTime}
                onChange={(newValue) => {
                  onChange('start-time', newValue?.toISOString());
                }}
                inputFormat={DF_DATE_TIME_PICKER}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    sx={classes.filter}
                  />
                )}
                maxDateTime={endTime || new Date()}
              />
              <MobileDateTimePicker
                label="End"
                value={endDateAndTime}
                inputFormat={DF_DATE_TIME_PICKER}
                onChange={(newValue) =>
                  onChange('end-time', newValue?.toISOString())
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    sx={classes.filter}
                  />
                )}
                maxDateTime={new Date()}
              />
            </>
          );
        case 'multipleSelect':
          return (
            <TessellAutocomplete
              id={name}
              name={name}
              label={name}
              value={filterVal[filterName] || []}
              variant="standard"
              size="small"
              onChange={(_val) => {
                onChange(_val);
              }}
              fullWidth
              sx={classes.filter}
              options={options}
              multiple
              showDefaultStyleLabel
            />
          );
        default:
          return (
            <TextField
              select
              label={name}
              name={name}
              id={name}
              variant="standard"
              size="small"
              value={filterVal[filterName] || ''}
              onChange={(e) => {
                setfilterVal((prev) => ({
                  ...prev,
                  [name]: e.target.value,
                }));
                onChange(e.target.value);
              }}
              sx={classes.filter}
              {...textfieldProps}
            >
              {options &&
                options.map((o) => (
                  <MenuItem key={o.id} value={o.id}>
                    {o.name}
                  </MenuItem>
                ))}
            </TextField>
          );
      }
    });
  }

  function GetActionButtons() {
    return actions.map((action) => {
      const {
        customComponent,
        label,
        startIcon,
        onClick = () => {},
        ...buttonProps
      } = action;
      if (customComponent) {
        return customComponent();
      }
      return (
        <Button
          size="medium"
          variant="contained"
          color="primary"
          startIcon={startIcon}
          onClick={onClick}
          sx={classes.actionButton}
          {...buttonProps}
        >
          {label}
        </Button>
      );
    });
  }

  const datagridColumns = useMemo(
    () =>
      columns
        .map((c, i) => ({
          ...c,
          flex: colFlex[i] || 1,
          sortable: false,
        }))
        .concat(
          !!showMenu
            ? [
                {
                  field: 'actions',
                  headerName: '',
                  flex: 0.62,
                  sortable: false,
                  align: 'left',
                  headerAlign: 'left',
                  disableColumnMenu: true,
                  filterable: false,
                  resizable: false,
                  hideable: false,
                  disableExport: true,
                  disableReorder: true,
                  renderCell: (params) => {
                    const { row } = params;
                    const showMenuIcon = !!getMenuItemsForRow(row)?.length;
                    return (
                      <Stack
                        onClick={(ev) => {
                          ev?.stopPropagation?.();
                        }}
                      >
                        <GridActionsCellItem
                          icon={<MoreVert />}
                          disabled={!showMenuIcon}
                          label="MenuItem"
                          className={showMenuButtonOnHover && 'actionButton'}
                          onClick={(e) => {
                            toggleMenu(e, row);
                          }}
                          color="primary"
                          sx={{
                            height: '30px',
                            width: '30px',
                            alignSelf: 'center',
                          }}
                        />
                      </Stack>
                    );
                  },
                },
              ]
            : [],
        ),
    [columns, colFlex],
  );

  useEffect(() => {
    if (columnWidth.length === 0) {
      setColumnWidth(() => datagridColumns.map((r) => r.width));
    }
  }, [datagridColumns]);

  const resizeCol = (e) => {
    const id = datagridColumns.findIndex((col) => col.field === e.colDef.field);
    const changedWidth = [...columnWidth];
    changedWidth[id] = e.colDef.width;
    setColumnWidth(changedWidth);
  };
  const displayMenuItems = Boolean(rowMenuEl);

  return (
    <>
      <Stack direction="column" height="100%">
        {customNoRowsOverlay ? (
          <>{customNoRowsOverlay()}</>
        ) : (
          <>
            <Stack
              direction="row"
              maxWidth="100%"
              flexWrap="wrap"
              alignItems="center"
              pb={1.5}
              px={1}
            >
              <Stack
                direction="row"
                alignItems="center"
                maxWidth="100%"
                flexWrap="wrap"
                flexGrow={1}
              >
                {filterParams && GetFilters()}
                {searchParams?.name && GetSearch()}
                {Object.keys(filterParams).length > 0 && (
                  <Stack my={1}>
                    <Button
                      color="primary"
                      size="large"
                      startIcon={<RotateLeft fontSize="large" />}
                      onClick={resetFilters}
                    >
                      Clear
                    </Button>
                  </Stack>
                )}
              </Stack>
              <Stack
                flexGrow={0}
                direction="row"
                alignItems="center"
                spacing={2}
              >
                {actions && GetActionButtons()}
                {customExportData && <ExportContainer {...customExportData} />}
              </Stack>
            </Stack>
            <Box
              height={fullHeight ? '100%' : '87%'}
              sx={classes.contentBox}
              width={width || 'auto'}
            >
              <DataGridMui
                hideFooter
                sx={clickable ? classes.clickable : classes.notClickable}
                density="comfortable"
                onColumnWidthChange={(e) => {
                  resizeCol(e);
                }}
                columns={datagridColumns}
                rows={getFilteredTasks()}
                disableSelectionOnClick
                components={{
                  NoRowsOverlay: NoRows,
                }}
                {...datagridProps}
              />
            </Box>
            {showMenu && displayMenuItems && (
              <Menu
                anchorEl={rowMenuEl}
                keepMounted
                open={displayMenuItems}
                onClose={() => toggleMenu(null, null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  zIndex: 2147483647,
                }}
              >
                {typeof menuItems === 'function'
                  ? menuList
                  : GetMenuItems(menuItems, null)}
              </Menu>
            )}
            {!hidePagination && (
              <TessellPagination
                totalCount={totalCount || rows?.length}
                itemsPerPage={itemsPerPage}
                pageCount={pageCount || 1}
                pageNo={pageNo || 1}
                onChange={handlePaginationChange}
              />
            )}
          </>
        )}
      </Stack>
    </>
  );
}

DataGrid.defaultProps = {
  clickable: true,
  search: {},
  filters: [],
  actions: [],
  menuItems: [],
};
