export const awsRegions = [
  {
    name: 'Asia Pacific (Mumbai)',
    value: 'ap-south-1',
    isoCountryCode: 'in',
  },
  {
    name: 'US East (Ohio)',
    value: 'us-east-2',
    isoCountryCode: 'us',
  },
  {
    name: 'US East (N. Virginia)',
    value: 'us-east-1',
    isoCountryCode: 'us',
  },
  {
    name: 'US West (N. California)',
    value: 'us-west-1',
    isoCountryCode: 'us',
  },
  {
    name: 'US West (Oregon)',
    value: 'us-west-2',
    isoCountryCode: 'us',
  },
  {
    name: 'Africa (Cape Town)',
    value: 'af-south-1',
    isoCountryCode: 'za',
  },
  {
    name: 'Asia Pacific (Hong Kong)',
    value: 'ap-east-1',
    isoCountryCode: 'hk',
  },
  {
    name: 'Asia Pacific (Hyderabad)',
    value: 'ap-south-2',
    isoCountryCode: 'in',
  },
  {
    name: 'Asia Pacific (Jakarta)',
    value: 'ap-southeast-3',
    isoCountryCode: 'id',
  },
  {
    name: 'Asia Pacific (Melbourne)',
    value: 'ap-southeast-4',
    isoCountryCode: 'au',
  },

  {
    name: 'Asia Pacific (Osaka)',
    value: 'ap-northeast-3',
    isoCountryCode: 'jp',
  },
  {
    name: 'Asia Pacific (Seoul)',
    value: 'ap-northeast-2',
    isoCountryCode: 'kr',
  },
  {
    name: 'Asia Pacific (Singapore)',
    value: 'ap-southeast-1',
    isoCountryCode: 'sg',
  },
  {
    name: 'Asia Pacific (Sydney)',
    value: 'ap-southeast-2',
    isoCountryCode: 'au',
  },
  {
    name: 'Asia Pacific (Tokyo)',
    value: 'ap-northeast-1',
    isoCountryCode: 'jp',
  },
  {
    name: 'Canada (Central)',
    value: 'ca-central-1',
    isoCountryCode: 'ca',
  },
  {
    name: 'Europe (Frankfurt)',
    value: 'eu-central-1',
    isoCountryCode: 'de',
  },
  {
    name: 'Europe (Ireland)',
    value: 'eu-west-1',
    isoCountryCode: 'ie',
  },
  {
    name: 'Europe (London)',
    value: 'eu-west-2',
    isoCountryCode: 'uk',
  },
  {
    name: 'Europe (Milan)',
    value: 'eu-south-1',
    isoCountryCode: 'it',
  },
  {
    name: 'Europe (Paris)',
    value: 'eu-west-3',
    isoCountryCode: 'fr',
  },
  {
    name: 'Europe (Spain)',
    value: 'eu-south-2',
    isoCountryCode: 'es',
  },
  {
    name: 'Europe (Stockholm)',
    value: 'eu-north-1',
    isoCountryCode: 'se',
  },
  {
    name: 'Europe (Zurich)',
    value: 'eu-central-2',
    isoCountryCode: 'ch',
  },
  {
    name: 'Israel (Tel Aviv)',
    value: 'il-central-1',
    isoCountryCode: 'il',
  },
  {
    name: 'Middle East (Bahrain)',
    value: 'me-south-1',
    isoCountryCode: 'bh',
  },
  {
    name: 'Middle East (UAE)',
    value: 'me-central-1',
    isoCountryCode: 'ae',
  },
  {
    name: 'South America (São Paulo)',
    value: 'sa-east-1',
    isoCountryCode: 'br',
  },
  {
    name: 'AWS GovCloud (US-East)',
    value: 'us-gov-east-1',
    isoCountryCode: 'us',
  },
  {
    name: 'AWS GovCloud (US-West)',
    value: 'us-gov-west-1',
    isoCountryCode: 'us',
  },
];
export const azureRegion = [
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: 'westUS2',
    name: 'West US 2 (Americas)',
    id: 'azure/westUS2',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: 'westEurope',
    name: 'West Europe (Europe)',
    id: 'azure/westEurope',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: 'northEurope',
    name: 'North Europe (Europe)',
    id: 'azure/northEurope',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: 'northCentralUS',
    name: 'North Central (Americas)',
    id: 'azure/northCentralUS',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: null,
    name: null,
    id: 'azure',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: null,
    name: null,
    id: 'AZURE',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: 'australiaEast',
    name: 'Australia East (Asia Pacific)',
    id: 'azure/australiaEast',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: 'australiaSoutheast',
    name: 'Australia SouthEast (Asia Pacific)',
    id: 'azure/australiaSoutheast',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: 'ukSouth',
    name: 'UK South (Europe)',
    id: 'azure/ukSouth',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: 'eastUS',
    name: 'East US (Americas)',
    id: 'azure/eastUS',
    isEnabled: true,
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    value: 'centralIndia',
    name: 'Central India (Asia Pacific)',
    id: 'azure/centralIndia',
    isEnabled: true,
  },
];
