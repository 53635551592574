import React from 'react';
import { CircularProgress, Box } from '@mui/material';

type LoadingOverlayProps = {
  isLoading: boolean;
  children: any;
  showChildrenInBackground?: boolean;
};

const LoadingOverlay = (props: LoadingOverlayProps) => {
  const { isLoading, children, showChildrenInBackground = false } = props;
  return (
    <Box position="relative" height="100%" width="100%">
      {isLoading ? (
        <Box
          position="absolute"
          justifyContent="center"
          alignItems="center"
          height="100%"
          top="50%"
          right="50%"
        >
          <CircularProgress />
        </Box>
      ) : !showChildrenInBackground ? (
        children
      ) : (
        ''
      )}

      {showChildrenInBackground ? children : ''}
    </Box>
  );
};

export default LoadingOverlay;
