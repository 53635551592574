import { useGetCall, useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import {
  adaptInfraDeploymentResponse,
  adaptInfraResourcesResponse,
} from './responseAdapters';
import { useEffect } from 'react';

export const useTenantInfraDetails = ({ tenant }) => {
  const { domain, metadata, infraDeploymentId } = tenant;
  const { infraInfo = {} as any } = metadata || {};
  const {
    response: { eksCloudResourceUrl, ecrepo },
  } = useGetCall(
    `${URLS.fetchInfraDeployementDetails}${infraDeploymentId}`,
    adaptInfraDeploymentResponse,
  );
  const { response: ec2InstanceTailscaleIp, postData } = useLazyGetCallWithURL(
    adaptInfraResourcesResponse,
  );
  useEffect(() => {
    if (ecrepo) {
      postData(`${URLS.fetchInfraResourceDetails}${ecrepo}`);
    }
  }, [ecrepo]);

  return {
    infraInfo,
    eksCloudResourceUrl,
    domain,
    ec2InstanceTailscaleIp,
  };
};
