export const adaptDBIntegrationsResponse = (response) => {
  return response?.length
    ? response?.map((_res) => {
        return {
          id: _res?.id,
          status: _res?.status,
          endpoint: _res?.name,
          name: _res?.integrationType,
        };
      })
    : [];
};
