import { TICKETS_INTERNAL_BASE_URL, URLS } from 'constants/URL';
import { useEffect, useMemo, useState } from 'react';
import { adaptAssignableUsersResponse } from 'customers/ticket/responseAdapter/adaptAssignableUsersResponse';
import useAddApiNotification from 'common/NotificationContainer/useAddApiNotification';
import { useGetCall, useLazyPostCall } from 'common/api/useApiCall';
import { adaptIssueTypesResponse } from 'customers/ticket/responseAdapter/adaptIssueTypesResponse';
import { JiraPriorities } from 'customers/tenants-detailed-view/lib/utils/constants';

const initialTicketState = {
  priority: 3,
  jiraAssignee: '',
  jiraReporter: '61d62c4c68926d0068670468',
  issuetype: 'Bug',
  subject: '',
  summary: '',
  status: 2,
};

export const useCreateExceptionJira = ({
  exception,
  onSuccess,
}: {
  exception: any;
  onSuccess: () => void;
}) => {
  const [state, setState] = useState<any>(initialTicketState);
  const updateState = (obj) => {
    setState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  useEffect(() => {
    if (exception)
      updateState({
        subject: exception?.exception,
        summary: JSON?.stringify?.(exception, null, '\t'),
      });
  }, [exception]);

  const formatKeyToString = (key) => {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const { response: jiraUsers } = useGetCall(
    URLS.getJiraAssignableUsers,
    adaptAssignableUsersResponse,
  );
  const { response: jiraIssues } = useGetCall(
    URLS.getJiraIssueTypes,
    adaptIssueTypesResponse,
  );

  const createTicketRes = useLazyPostCall(TICKETS_INTERNAL_BASE_URL);

  const createTicket = () => {
    const jiraPayload = {
      fields: {
        project: {
          key: 'TOPS',
        },
        issuetype: {
          name: state?.issuetype,
        },
        summary: state?.subject,
        description: state?.summary,
        environment: exception?.pgSource,
        priority: {
          name: JiraPriorities[state?.priority],
        },
        assignee: {
          id: state?.jiraAssignee,
        },
        reporter: {
          id: state?.jiraReporter,
        },
        labels: ['tessell-ops'],
      },
    };

    createTicketRes.postData(
      JSON.stringify({
        exceptionId: exception?.exceptionId,
        jiraPayload: jiraPayload,
        isExceptionsJira: true,
      }),
    );
  };

  const resetState = () => {
    setState(initialTicketState);
  };

  useAddApiNotification({
    successMsg: 'Successfully created the ticket.',
    errorMsg: 'Something went wrong. Please try again.',
    apiResponse: createTicketRes,
    onSuccess: onSuccess,
  });

  const isSubmitBtnDisabled = useMemo(() => {
    if (
      !state?.subject?.trim() ||
      !state?.summary?.trim() ||
      !state?.priority ||
      !state?.jiraAssignee ||
      !state?.issuetype
    ) {
      return true;
    }
    return false;
  }, [state]);

  return {
    createTicket,
    state,
    updateState,
    isSubmitting: createTicketRes?.isLoading,
    jiraUsers,
    jiraIssues,
    resetState,
    isSubmitBtnDisabled,
  };
};
