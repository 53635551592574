import { Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';

export const CustomDataGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <Stack sx={{ flexDirection: 'row' }}>
        <Stack sx={{ padding: '0 12px' }}>
          <GridToolbarColumnsButton />
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
};
