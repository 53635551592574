import { LoadingButton } from '@mui/lab';
import { Button, Chip, Stack, Typography } from '@mui/material';
import DatePickerToggler from 'common/custom-components/lib/components/DatePickerToggler';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import moment from 'moment';
import { styles } from './styles';
import useSilenceAlertsModel from './useSilenceAlerts';
import { OTP_TYPE } from 'constants/otpType';
import OtpModal from '../OtpModal';
import { ChipListing } from 'common/ChipListing/ChipListing';

type SilenceAlertsModalProps = {
  open: boolean;
  setOpen: any;
  selectedAlertLabels?: any;
  hideInputs?: boolean;
  handleOnSuccess: () => void;
};

const rangeType = {
  '1h': '1 hour',
  '3h': '3 hour',
  '6h': '6 hour',
  '12h': '12 hour',
  '24h': '24 hour',
  Custom: 'Custom',
};

const startMin = moment(new Date());

export const SilenceAlertsModal = (props: SilenceAlertsModalProps) => {
  const {
    open,
    setOpen,
    selectedAlertLabels,
    hideInputs = false,
    handleOnSuccess,
  } = props;

  const {
    inputs,
    isLoading,
    openOTP,
    sendOTPIsLoading,
    step,
    disableSilence,
    handleChange,
    setOpenOTP,
    setStep,
    handleConfirm,
    otpSubmitHandler,
    handleDateToggle,
    updateTimeUsingNativePicker,
    range,
  } = useSilenceAlertsModel({ selectedAlertLabels, handleOnSuccess });

  const onClose = () => {
    setOpen(false);
  };

  const DialogTitle = () => <Typography variant="h5">Silence Alert</Typography>;

  const DialogContent = () => {
    return (
      <>
        <Stack sx={styles.form}>
          {selectedAlertLabels ? (
            <ChipListing data={selectedAlertLabels} />
          ) : (
            ''
          )}
          {!hideInputs ? (
            <Stack width="100%" alignItems="center" alignSelf="center">
              <TessellInput
                sx={styles.formInput}
                name="tenant_id"
                label="Tenant Id"
                value={inputs?.tenant_id || ''}
                onChange={handleChange}
                placeholder="All alerts of this tenant will be silenced"
              />
              <TessellInput
                sx={styles.formInput}
                name="service_id"
                label="Service Id"
                value={inputs?.service_id || ''}
                onChange={handleChange}
                placeholder="All alerts of this service will be silenced"
              />
              <TessellInput
                sx={styles.formInput}
                name="service_instance_id"
                label="Service Instance Id"
                value={inputs?.service_instance_id || ''}
                onChange={handleChange}
                placeholder="All alerts of this service instance will be silenced"
              />
              <TessellInput
                sx={styles.formInput}
                name="alertname"
                label="Alert Name"
                value={inputs?.alertname || ''}
                onChange={handleChange}
                placeholder="All alerts of this type will be silenced"
              />
              <TessellInput
                sx={styles.formInput}
                name="compute_resource_id"
                label="Compute Resource Id"
                value={inputs?.compute_resource_id || ''}
                onChange={handleChange}
                placeholder="All alerts with this compute resource will be silenced"
              />
              <TessellInput
                sx={styles.formInput}
                name="job"
                label="Job Name"
                value={inputs?.job || ''}
                onChange={handleChange}
                placeholder="All alerts with this job name will be silenced"
              />
            </Stack>
          ) : (
            <></>
          )}

          <Stack direction="row" spacing={1} sx={styles.timeSelectionFrom}>
            <Typography>Silence alerts for</Typography>
            <DatePickerToggler
              rangeType={rangeType}
              startTime={inputs?.startsAt}
              endTime={inputs?.endsAt}
              updateTimeUsingNativePicker={updateTimeUsingNativePicker}
              startMin={startMin}
              handleOnChange={handleDateToggle}
              range={range}
              additionalInitialSettings={{ drops: 'up' }}
            />
          </Stack>
        </Stack>
        <OtpModal
          open={openOTP}
          setOpen={setOpenOTP}
          onSubmit={otpSubmitHandler}
          isSubmitting={step === 1 ? sendOTPIsLoading : isLoading}
          otpUser="Ops admin"
          step={step}
          setStep={setStep}
          reasonSubmitBtnText="Submit"
          reasonHeading="Enter reason for silencing alerts (with JIRA link)"
          otpType={OTP_TYPE.SILENCE_ALERTS}
        />
      </>
    );
  };

  const DialogActions = () => {
    return (
      <>
        <Button
          onClick={() => {
            setOpen(false);
            setStep(1);
          }}
          variant="outlined"
          size="small"
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleConfirm}
          size="small"
          variant="contained"
          loading={false}
          disabled={disableSilence}
        >
          Silence
        </LoadingButton>
      </>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  return (
    <TessellModal
      renderFunctions={renderFunctions}
      maxWidth="md"
      open={open}
      onClose={onClose}
    />
  );
};
