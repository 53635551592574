import { Stack, Typography } from '@mui/material';
import VerticalLegend from '../VerticalLegend';
import { useMemo } from 'react';
import { DonutChart } from '../ChartComponents';
import { donutChartCardStyles } from './styles';

type DonutChartCardProps = {
  labels?: any;
  data?: any;
  title?: String;
};

export const DonutChartCard = (props: DonutChartCardProps) => {
  const { labels, data, title } = props;
  const chartData = {
    labels: labels || [],
    data: data || [],
  };

  const legendData = useMemo(() => {
    const data = [];
    chartData?.labels?.forEach((item, idx) => {
      data.push({ name: item, value: chartData?.data[idx] });
    });
    return data;
  }, [chartData]);

  return (
    <Stack sx={donutChartCardStyles.container}>
      <Stack sx={donutChartCardStyles.titleContainer}>
        <Typography sx={donutChartCardStyles.titleText}>{title}</Typography>
      </Stack>
      <Stack sx={donutChartCardStyles.chartAndLegendContainer}>
        <Stack sx={donutChartCardStyles.chartContainer}>
          <DonutChart chartData={chartData} />
        </Stack>
        <Stack sx={donutChartCardStyles.legendContainer}>
          <VerticalLegend
            series={legendData}
            labels={{
              keyLabel: 'Type',
              valueLabel: 'Count',
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
