export const adaptDBEngineConfigsResponse = (obj: any): any[] => {
  const result: any[] = [];
  let count = 1;
  const flatten = (obj: any, engine, trace) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key]?.default === undefined) {
        flatten(obj[key], engine, ` ${trace} ->  ${key} -> `);
      } else {
        const name = key;
        const defaultValue = obj[key].default;
        const tenantValue = obj[key].computedValue;
        const isDifferent = defaultValue !== tenantValue;
        result.push({
          name,
          defaultValue,
          tenantValue,
          engine: engine,
          id: count++,
          isDifferent,
          trace,
        });
      }
    }
  };

  obj?.forEach((element) => {
    flatten(element, element.engine, '');
  });

  result.sort((a, b) => {
    if (a.isDifferent && !b.isDifferent) {
      return -1; // a comes before b
    } else if (!a.isDifferent && b.isDifferent) {
      return 1; // b comes before a
    } else {
      return 0; // no change in order
    }
  });

  return result;
};
