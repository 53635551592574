import { Button, Stack, Typography } from '@mui/material';
import { useStyles } from 'common/utils';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { URLS } from 'constants/URL';
import { useEffect, useState } from 'react';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';
import { cloudAccountDetailsModalStyles } from './styles';

interface CloudAccountDetailsModalProps {
  open: boolean;
  setOpen: any;
  title?: string;
  cloudAccountID: string;
}

export const CloudAccountDetailsModal = (
  props: CloudAccountDetailsModalProps,
) => {
  const classes = useStyles();
  const { open, setOpen, title, cloudAccountID } = props;
  const [cloudAccountDetails, setCloudAccountDetails] = useState<any>({});
  const { postData, response, isLoading } = useLazyGetCallWithURL();

  useEffect(() => {
    if (cloudAccountID) {
      postData(`${URLS.fetchCloudAccountDetails}${cloudAccountID}`);
    }
  }, [cloudAccountID]);

  useEffect(() => {
    setCloudAccountDetails(response?.response);
  }, [response]);

  const onClose = () => {
    setOpen(false);
  };

  const DialogTitle = () =>
    title ? <>{title}</> : <>{'Cloud Account Details'}</>;

  const DialogActions = () => {
    return (
      <Button
        onClick={() => {
          setOpen(false);
        }}
      >
        Close
      </Button>
    );
  };

  const DialogContent = () => {
    return (
      <Stack sx={cloudAccountDetailsModalStyles.container}>
        {isLoading && (
          <Typography variant="subtitle1">Fetching data...</Typography>
        )}
        {!isLoading &&
          (cloudAccountID && cloudAccountDetails ? (
            <>
              {cloudAccountDetails?.accountId && (
                <ShowLabelValue
                  label="Cloud Account Id"
                  value={cloudAccountDetails?.accountId}
                />
              )}
              <ShowLabelValue
                label="Cloud account name"
                value={cloudAccountDetails?.name}
              />
              <ShowLabelValue
                label="Managed type"
                value={cloudAccountDetails?.managedType}
              />
              {cloudAccountDetails?.resourceGroupName && (
                <ShowLabelValue
                  label="Resource Group Name"
                  value={cloudAccountDetails?.resourceGroupName}
                />
              )}
            </>
          ) : (
            <>No details found.</>
          ))}
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    />
  );
};
