import { URLS } from 'constants/URL';
import fileDownload from 'js-file-download';
import JSZip from 'jszip';
import moment from 'moment';
import React from 'react';
import { getHeaders } from 'urls';

export interface UrlProps {
  entityId: string;
  instance: string;
  fl: string;
  startTime: string;
  endTime: string;
  forward: string;
}

interface Props {
  urlProps: UrlProps;
  selectedLogs: string[];
}

export interface DownloadFileLogs {
  logs: string[];
  text: any;
  startTime: string;
  endTime: string;
}

const useDownloadLog = (props: Props) => {
  const { urlProps, selectedLogs } = props;
  const { entityId, instance, fl, startTime, endTime, forward } = urlProps;

  const url = `${URLS.fetchDBLogs}/logs/stream?entity-id=${entityId}&service-instance-id=${instance}&file-name=${fl}&start-time=${startTime}&end-time=${endTime}&limit=3000&forward=${forward}`;

  const [downloadFile, setDownloadFile] =
    React.useState<DownloadFileLogs | null>(null);
  const [_isLogsLoading, setIsLogsLoading] = React.useState<boolean>(false);
  function logsDateFormatter(d) {
    return moment(d).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSSSS[Z]');
  }

  function setFileInfo(logs, metadata) {
    function handleTimestamp(d1, d2) {
      if (d1 && logs.length >= 500) {
        return logsDateFormatter(d1);
      }
      return d2;
    }

    function setText(logs) {
      if (logs.length === 0) {
        return 'No logs found. Please load previous logs';
      }
      const errorValues = ['\u0000\u0000\u0000\u0000\u0000']; // keeping an array to include any potential new values that we need to filter out.
      const filteredLogs = logs.filter(
        (log) =>
          !errorValues.some((value) => log.includes(value) && log.length > 100),
      );
      return filteredLogs.join('\n');
    }

    const fileInfo = downloadFile?.[fl] || {};
    if (metadata) {
      return {
        logs,
        text: setText(logs),
        startTime: handleTimestamp(metadata.firstEventTimestamp, startTime),
        endTime: fileInfo.endTime || endTime,
      };
    }
    return fileInfo;
  }

  const fetchLogs = async () => {
    setIsLogsLoading(true);
    try {
      const res = await fetch(url, {
        headers: getHeaders(),
      });
      const data = await res.json();
      if (Array.isArray(data)) {
        setDownloadFile((prev) => ({
          ...prev,
          [fl]: setFileInfo(data?.[0]?.logs, data?.[0]?.metadata),
        }));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLogsLoading(false);
    }
  };

  return { downloadFile, fetchLogs };
};

export default useDownloadLog;
