import Cache from 'common/cache/lib/utils/Cache';

// In case of new message types are introduced
// add cases and corresponding functions accordingly
export const useOnMessage = (setNotificationCount, addNotification) => {
  return (data) => {
    const wsData =
      (data?.data &&
        typeof data?.data === 'string' &&
        JSON.parse(data?.data)) ||
      {};
    switch (wsData?.type?.toLowerCase()) {
      case 'alert':
        alert(wsData, setNotificationCount, addNotification);
        break;
      case 'health':
        health(wsData, addNotification);
        break;
    }
  };
};

const alert = (wsData, setNotificationCount, addNotification) => {
  // accepts JSON parsed message from websocket as wsData data
  // Leave the console log for debugging purposes
  console.log('wsData received', wsData);
  const DEFAULT_ALERT = 'An alert has been received from the server';

  let notificationsList = [];
  try {
    notificationsList = JSON.parse(Cache.get('notifications'));
  } catch (error) {
    notificationsList = [];
  }
  if (
    !Cache.get('notificationCount') ||
    Cache.get('notificationCount') === 'undefined'
  ) {
    Cache.set('notificationCount', 1);
    setNotificationCount(1);
  } else {
    Cache.set(
      'notificationCount',
      parseInt(Cache.get('notificationCount')) + 1,
    );
    setNotificationCount((prev) => ++prev);
  }
  const alertText =
    wsData?.data?.message ||
    wsData?.data?.annotations?.summary ||
    wsData?.data?.annotations?.title ||
    wsData?.data?.annotations?.alertName ||
    DEFAULT_ALERT;
  if (wsData?.type?.toLowerCase() === 'alert') {
    addNotification({
      message: `${wsData?.type?.toUpperCase()}: ${alertText}`,
      severity: 'error',
    });
    if (wsData?.data) {
      wsData.data.message = alertText;
    }
  }
  notificationsList.push(wsData);
  Cache.set('notifications', JSON.stringify(notificationsList));
};

const health = (wsData, addNotification) => {
  // accepts JSON parsed message from websocket as wsData data
  // Leave the console log for debugging purposes
  console.log('wsData received', wsData);
  const DEFAULT_ALERT = `A tenant's health has changed please refresh your page`;

  const alertText = wsData?.data?.message || DEFAULT_ALERT;
  if (wsData?.type?.toLowerCase() === 'health') {
    addNotification({
      message: `Tenant Health update: ${alertText}`,
      severity: 'error',
    });
    if (wsData?.data) {
      wsData.data.message = alertText;
    }
  }
};
