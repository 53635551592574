import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';

type ExportContainerProps = {
  isLoading: boolean;
  handleExportClick: any;
};

const ExportContainer = (props: ExportContainerProps) => {
  const { isLoading, handleExportClick } = props;

  return (
    <Stack>
      <LoadingButton
        loading={isLoading}
        variant="contained"
        onClick={() => handleExportClick('csv')}
      >
        Export as csv
      </LoadingButton>
    </Stack>
  );
};

export default ExportContainer;
