import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { Stack } from '@mui/material';
import UpdateMetadata from 'common/UpdateMetadata/UpdateMetadata';
import { useSubnets } from './useSubnets';

type SubnetsProps = {
  tenant: any;
  subnets: any;
};

const Subnets = ({ tenant, subnets = [] }: SubnetsProps) => {
  const {
    updateMetadataOpen,
    setUpdateMetadataOpen,
    selectedSubnet,
    menuItemsProps,
    columns,
  } = useSubnets();

  return (
    <Stack sx={{ height: '100vh' }}>
      <DataGrid
        columns={columns}
        componentName="subnets"
        rows={subnets || []}
        styleOverrides={{ hideShadow: true }}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        menuItems={menuItemsProps}
      />
      <UpdateMetadata
        open={updateMetadataOpen}
        setOpen={setUpdateMetadataOpen}
        entityType="subnet"
        entityName={selectedSubnet?.name}
        tenantId={tenant?._id}
        tenantDomain={tenant?.pgSource}
        entityId={selectedSubnet?.id}
      />
    </Stack>
  );
};

export default Subnets;
