import { Stack, Box, Button, Grid, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileCopyOutlined } from '@mui/icons-material';
import LabelValue from 'common/custom-components/lib/components/LabelValue';
import { CloudIcon, SLAIcon, UsersIcon } from 'assets/icons-v2';
import { useGetSubscriptionIdNameMap } from 'tools/useGetSubscriptionIdNameMap';
import moment from 'moment-timezone';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';
import { styles } from 'assets/scss/style-templates';

interface AMSummaryContentProps {
  sla: any;
  dataIngestionStatus: string;
  cloud: any;
  regionMap: any;
  owner: any;
  entityId: string;
  tenantId: string;
  subscriptionId: string;
  snapshotTime: string;
  snapshotTimeIsLoading: boolean;
}

export const AMSummaryContent = (props: AMSummaryContentProps) => {
  const {
    sla,
    dataIngestionStatus,
    cloud,
    owner,
    entityId,
    tenantId,
    subscriptionId,
    snapshotTime,
    snapshotTimeIsLoading,
  } = props;
  const { subIdNameMap } = useGetSubscriptionIdNameMap({ tenantId });

  return (
    <Stack direction="row" spacing={15} p={2} height="100%">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item md={7} lg={4}>
          <Grid container>
            <Grid item>
              <Box pt={0.3} pr={0.3}>
                <SLAIcon width={24} darkBg={false} />
              </Box>
            </Grid>
            <Grid item>
              <LabelValue label="RPO SLA" value={sla} />
              {!snapshotTimeIsLoading && (
                <ShowLabelValue
                  label="Snapshot Time"
                  value={
                    <Stack direction={'row'} spacing={1}>
                      <Typography> &nbsp;{snapshotTime}</Typography>
                      <Typography color={styles.color.monotoneDarkLight}>
                        {' '}
                        (Asia/Calcutta)
                      </Typography>
                    </Stack>
                  }
                />
              )}
              {
                <LabelValue
                  label="Source DB Status"
                  value={dataIngestionStatus}
                />
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} lg={4}>
          <Grid container>
            <Grid item>
              <Box pt={0.3} style={{ marginRight: 10 }}>
                <CloudIcon width={24} darkBg={false} />
              </Box>
            </Grid>
            <Grid item>
              <LabelValue label="Cloud" value={cloud?.toUpperCase()} />
              <LabelValue
                label="Subscription"
                value={subIdNameMap[subscriptionId] || '-'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} lg={4}>
          <Grid container>
            <Grid item>
              <Box pt={0.5}>
                <UsersIcon width={30} darkBg={false} />
              </Box>
            </Grid>
            <Grid item>
              <LabelValue label="Owner" value={owner} />

              {
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <LabelValue label="TRN" value={entityId} />
                  <CopyToClipboard text={entityId}>
                    <Button color="primary" startIcon={<FileCopyOutlined />} />
                  </CopyToClipboard>
                </Stack>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};
