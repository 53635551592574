import { objectDeepCopy } from 'common/utils';
import { DashboardChartType } from './types';
import {
  CHART_FAMILIES,
  CHART_TYPES,
  DISPLAY_NAME,
  SAMPLE_CHART_OPTION,
  labelFormatter,
} from './constants';
import { awsRegions } from 'customers/tenants-list-view/constants/regions';

export const dashboardResponseAdapter = (
  resp,
): { dashboardSummary: DashboardChartType; regionCountChartData: any } => {
  // accepts response and returns {chartType: [options, [valuesSeries]], }
  const response = resp?.response || {};

  let regionCountChartData = [];

  const familyWiseData = CHART_FAMILIES.reduce(
    (acc, curr) => ({ ...acc, [curr]: [], total: { ...acc.total, [curr]: 0 } }),
    { total: {} },
  ) as DashboardChartType;

  if (resp?.response) {
    // extract nativeBackup and snapshot to top level
    if (resp?.response?.dbbackups) {
      response.nativeBackup = response?.dbbackups?.nativeBackup || {};
      response.snapshots = response?.dbbackups?.snapshots || {};
    }

    CHART_FAMILIES.forEach((family) => {
      const data = response[family];
      CHART_TYPES[family]?.forEach((chart) => {
        if (family === 'genieLogs') {
          familyWiseData?.[family]?.push(data);
          if (data?.total) familyWiseData.total[family] = data?.total || 0;
          return;
        }
        if (data?.total) {
          const row = objectDeepCopy(SAMPLE_CHART_OPTION);
          const labelValueArrays = Object.keys(data[chart] || {}).reduce(
            (acc, curr) => [
              [...acc[0], curr],
              [...acc[1], data[chart][curr] || 0],
            ],
            [[], []],
          );
          row.chart.id = `${family}-${chart}`;
          row.chart.group = family;
          row.chart.labels = labelValueArrays[0];
          row.dataLabels = {
            formatter: labelFormatter,
          };
          row.labels = labelValueArrays[0];
          row.title = {
            text: DISPLAY_NAME[chart.toUpperCase()] || chart.toUpperCase(),
          };
          familyWiseData[family].push([row, labelValueArrays[1]]);
          familyWiseData.total[family] = data?.total || 0;
        }
      });
    });
    const regionCount = response?.tenants?.regionCount;
    if (regionCount) {
      regionCountChartData = Object.keys(regionCount?.aws)
        ?.map((_key) => ({
          country: awsRegions?.find((_region) => _region?.value === _key)
            ?.isoCountryCode,
          value: regionCount?.aws[_key],
        }))
        .filter((_a) => _a.country)
        .reduce((acc, obj) => {
          const index = acc?.findIndex((_a) => _a.country === obj.country);
          if (index === -1) {
            return [...acc, obj];
          } else {
            return acc?.map((_a, i) =>
              index === i ? { ..._a, value: _a.value + obj.value } : _a,
            );
          }
        }, []);
    }
  }

  return {
    dashboardSummary: familyWiseData,
    regionCountChartData,
  };
};
