import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import BreadCrumb from 'common/custom-components/lib/components/BreadCrumb';
import { DbServicesTable } from 'customers/db-services-common-view/components/DBServices/DbServicesTable';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';

interface ServiceListViewProps {
  services?: DatabaseServiceType;
}

const ServiceListView = (props: ServiceListViewProps) => {
  document.title = 'DB Services | Tessell - Ops';

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Database Services" showBackButton={false} />
        <Row>
          <Col className="col-12">
            <Row>
              <DbServicesTable />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceListView;
