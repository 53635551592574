export const nCountString = (
  count: number,
  zeroCountString: string,
  singleCountString: string,
  multipleCountString: string,
): string => {
  switch (count) {
    case 0:
      return zeroCountString;
    case undefined:
      return zeroCountString;
    case 1:
      return `1 ${singleCountString}`;
    default:
      return `${count} ${multipleCountString}`;
  }
};
