import Cache from 'common/cache/lib/utils/Cache';
import { NotificationPropType } from './types';

export const useNotificationDrawer = (props) => {
  const { open, setOpen } = props;

  const close = () => {
    // Cache.set('notifications', []);
    setOpen(false);
  };
  let data: NotificationPropType[] | [];
  try {
    data = JSON.parse(Cache.get('notifications'));
  } catch (error) {
    data = [];
  }
  return { open, data, close };
};
