import { useState } from 'react';
import { useDeleteGroup } from './DeleteGroup/useDeleteGroup';
import { useGetGroups } from './Common/hooks/useGetGroups';

export type Group = {
  name?: string;
  description?: string;
  creationDate?: string;
  id?: string;
  lastModifiedDate?: string;
  permissions?: string;
  privileges?: any;
};

export const useGroups = () => {
  const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false);
  const [openAddUsersModal, setOpenAddUsersModal] = useState(false);
  const [openDeleteUsersModal, setOpenDeleteUsersModal] = useState(false);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState<Group>({});

  const { groups, groupsIsLoading, getGroups } = useGetGroups();

  const { deleteGroup, deleteGroupIsLoading } = useDeleteGroup({
    onSuccess: getGroups,
  });
  const handleOnSuccess = () => {
    getGroups();
    setOpenCreateGroupModal(false);
    setOpenPermissionModal(false);
  };

  return {
    groups,
    groupsIsLoading,
    selectedGroup,
    openCreateGroupModal,
    setOpenCreateGroupModal,
    setSelectedGroup,
    getGroups,
    handleOnSuccess,
    openPermissionModal,
    setOpenPermissionModal,
    openDeleteConfirmation,
    setOpenDeleteConfirmation,
    deleteGroup,
    deleteGroupIsLoading,
    openAddUsersModal,
    setOpenAddUsersModal,
    openDeleteUsersModal,
    setOpenDeleteUsersModal,
  };
};
