import { useEffect, useState } from 'react';
import { Input, Label, Form } from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { signIn as signIn2 } from './signIn';
import {
  CircularProgress,
  Link,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import {
  useSetUserLoginInfoContext,
  useUserLoginInfoContext,
} from './UserLoginInfo/UserLoginInfoContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styles } from 'assets/scss/style-templates';
import withRouter from 'common/withRouter';
import LoginContainer from 'common/custom-components/LoginContainer';

const Login = (props) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();

  const setUserLoginInfo = useSetUserLoginInfoContext();
  const [submitting, setSubmitting] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values) => {
      signIn2(
        values.email,
        values.password,
        setUserLoginInfo,
        navigate,
        setSubmitting,
        setErrMessage,
      );
    },
  });

  const loginInfo = useUserLoginInfoContext();

  useEffect(() => {
    if (Object.keys(loginInfo)?.length !== 0) {
      if (loginInfo?.signInUserSession?.accessToken?.jwtToken) {
        navigate('/');
      }
    }
  }, [loginInfo]);

  document.title = 'SignIn | Tessell Ops';

  return (
    <>
      <LoginContainer>
        <Stack>
          <Stack alignItems="center" justifyContent="center" p={2}>
            <Typography sx={{ fontWeight: '500', fontSize: '20px' }}>
              Sign In to your account
            </Typography>
          </Stack>
          <Stack mt={2}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
            >
              <div className="mb-3">
                <Label htmlFor="email" className="form-label">
                  <Typography variant="body1" sx={{ color: '#08080D' }}>
                    Email
                  </Typography>
                </Label>
                <Input
                  name="email"
                  className="form-control"
                  placeholder="Enter email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
              </div>

              <div className="mb-3">
                <Stack direction="row" justifyContent="space-between">
                  <Label className="form-label" htmlFor="password-input">
                    Password
                  </Label>
                  <Link
                    onClick={() => navigate('/forget-password')}
                    underline="always"
                    sx={{
                      display: 'flex',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    type="link"
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: '#3986C1', fontWeight: '500' }}
                    >
                      Forgot?
                    </Typography>
                  </Link>
                </Stack>
                <div className="position-relative auth-pass-inputgroup mb-3">
                  <Input
                    name="password"
                    value={validation.values.password || ''}
                    type={passwordShow ? 'text' : 'password'}
                    className="form-control pe-5"
                    placeholder="Enter Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />

                  <button
                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                    type="button"
                    id="password-addon"
                    onClick={() => setPasswordShow(!passwordShow)}
                  >
                    {passwordShow ? (
                      <Visibility
                        className="align-middle"
                        sx={{ color: styles?.color?.darkShade }}
                      />
                    ) : (
                      <VisibilityOff
                        className="align-middle"
                        sx={{ color: styles?.color?.darkShade }}
                      />
                    )}
                  </button>
                </div>
              </div>

              <Stack>
                {errMessage && (
                  <Stack
                    m={1}
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography color="red">{errMessage}</Typography>
                  </Stack>
                )}
                <Stack mt={errMessage ? 1 : 3}>
                  <Button
                    variant="contained"
                    sx={{
                      height: '50px',
                      borderRadius: '4px',
                      backgroundColor: '#3986C1',
                    }}
                    disabled={submitting}
                    type="submit"
                  >
                    {submitting ? (
                      <CircularProgress
                        thickness={6}
                        sx={{ color: 'primary' }}
                        size={18}
                      />
                    ) : (
                      'Sign In'
                    )}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          </Stack>
        </Stack>
      </LoginContainer>
    </>
  );
};

export default withRouter(Login);
