import {
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { tessellPaginationStyles } from './styles';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { styles } from 'assets/scss/style-templates';

type TessellPaginationProps = {
  totalCount: number;
  pageCount: number;
  itemsPerPage?: number;
  pageNo: number;
  onChange: any;
};

const TessellPagination = (props: TessellPaginationProps) => {
  const {
    totalCount,
    pageCount,
    pageNo,
    onChange,
    itemsPerPage = ITEMS_PER_PAGE,
  } = props;
  const getFrom = () => {
    return pageNo === 1 ? 1 : (pageNo - 1) * itemsPerPage + 1;
  };

  const getTill = () => {
    return pageNo === pageCount ? totalCount : pageNo * itemsPerPage;
  };

  const jumpToOptions = Array?.from({ length: pageCount }, (_, i) => i + 1);

  return (
    <Stack sx={tessellPaginationStyles.mainContainer}>
      {pageCount > 1 ? (
        <Stack direction={'row'} alignItems={'center'} spacing={4}>
          <Pagination
            count={pageCount}
            page={pageNo}
            onChange={onChange}
            color="primary"
            showFirstButton
            showLastButton
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: ArrowBackRounded,
                  next: ArrowForwardRounded,
                }}
                {...item}
              />
            )}
          />
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography variant="body1" color={styles.color.monotoneDarkDark}>
              Jump to
            </Typography>
            <TessellInput
              width="70px"
              select
              pb={1}
              size="small"
              value={pageNo}
              onChange={(e) => {
                onChange(e, e?.target?.value);
              }}
              options={jumpToOptions?.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
              SelectProps={{
                MenuProps: {
                  sx: { height: '300px' },
                },
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack></Stack>
      )}
      {Boolean(totalCount) && (
        <Stack
          spacing={1}
          direction={'row'}
          alignItems={'center'}
          sx={tessellPaginationStyles.countContainer}
        >
          <Typography variant="h6">{`${getFrom()} - ${getTill()}`}</Typography>
          <Typography variant="h6">{`of ${totalCount}`}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default TessellPagination;
