export const ControllerNodeTooltipStyles = {
  TextContianer: { alignItems: 'center', justifyContent: 'center', m: 0.5 },
  TooltipText: { fontSize: '12px', fontWeight: 500, px: 1 },
  InfoIconContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  PopperProps: {
    sx: {
      '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        color: 'black',
        borderRadius: '8px',
        boxShadow:
          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      },
      '& .MuiTooltip-arrow': {
        color: 'white',
      },
    },
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-10, 0],
        },
      },
    ],
  },
};
