/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/*
 * Copyright © Tessell Inc, 2021. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

export default function Notification(props) {
  const { message, notify, setNotify, severity, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [duration, setDuration] = useState(6000);

  useEffect(() => {
    if (notify) {
      if (message?.length > 100) {
        setDuration(12000);
      } else {
        setDuration(12000);
      }
    }
  }, [notify, message]);
  useEffect(() => {
    if (message?.length && notify) {
      enqueueSnackbar({
        variant: severity,
        message,
        onClose,
        autoHideDuration: duration,
      });
    }
  }, [message, notify]);

  return null;
}

Notification.propTypes = {
  notify: PropTypes.bool.isRequired,
  setNotify: PropTypes.func.isRequired,
  message: PropTypes.string,
  severity: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  severity: 'info',
};
