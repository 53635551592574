import { styles } from 'assets/scss/style-templates';
import MyDatabasesIcon from 'assets/icons/MyDatabasesIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { TabType } from 'common/tab-view/lib/schema/TabType';
import { TabView } from 'common/tab-view/lib/components/TabView';
import VMContainer from 'customers/db-services-detailed-view/components/VMContainer/VMContainer';
import { evaluateTenantName } from 'common/TenantContainer/evaluateTenantName';
import { useContext, useMemo } from 'react';
import { DbServicesTable } from 'customers/db-services-common-view/components/DBServices/DbServicesTable';
import { DBServicesIcon } from 'assets/icons-v2';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';

enum Tab {
  Overview = 'Overview',
  Services = 'Services',
}

// Snake to Camel case
const RouteToTabMap = {
  overview: 'Overview',
  database: 'Services',
};

// Camel case to Snake case
const TabToRouteMap = {
  Overview: 'overview',
  Services: 'database',
};

type DBServersDetailsTabViewProps = {
  tenant: any;
};

const DBServersDetailsTabView = ({ tenant }: DBServersDetailsTabViewProps) => {
  const navigate = useNavigate();

  const params = useParams();

  const { tabId = 'overview', dbServerId, tenantId } = params;
  const { hasPermission } = useContext(UserPermissionsContext);
  const onTabClick = (event: any, newValue: string) => {
    navigate(
      `/tenants/${tenantId}/database-servers/${dbServerId}/${TabToRouteMap[newValue]}`,
      {
        replace: true,
      },
    );
  };

  const tenantName = useMemo(() => {
    return evaluateTenantName({
      tenant: tenant,
    });
  }, [tenant]);

  const tabValue = RouteToTabMap[tabId] as Tab;
  const tabsProps: TabType[] = [
    hasPermission(PERMISSIONS.GENIE) && {
      label: Tab.Overview,
      tabValue: Tab.Overview,
      render: () => (
        <>
          <VMContainer
            computeId={dbServerId}
            tenantId={tenantId}
            tenantDomain={tenant?.pgSource}
            tenantName={tenantName}
          />
        </>
      ),
      icon: <MyDatabasesIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: Tab.Services,
      tabValue: Tab.Services,
      render: () => (
        <>
          <DbServicesTable tenant={tenant} computeID={dbServerId} />
        </>
      ),
      icon: <DBServicesIcon height={16} color={styles.color.darkShade} />,
    },
  ].filter(Boolean);

  const currentTab =
    tabsProps?.find((tab) => tab?.tabValue === tabValue) || tabsProps?.[0];

  return (
    <>
      <TabView
        tabs={tabsProps}
        onTabClick={onTabClick}
        currentTab={currentTab}
      />
    </>
  );
};

export default DBServersDetailsTabView;
