import {
  TICKETS_BASE_URL,
  TICKETS_INTERNAL_BASE_URL,
  URLS,
} from 'constants/URL';
import { useContext, useEffect, useMemo, useState } from 'react';
import useAddApiNotification from 'common/NotificationContainer/useAddApiNotification';
import { useLazyPutCall, useLazyGetCallWithURL } from 'common/api/useApiCall';
import { adaptJiraResponse } from 'common/Modals/adaptJiraResponse';
import { CommonDataContext } from 'common/Contexts/CommonData';

export const useUpdateTicketStatus = ({
  ticket,
  onSuccess,
}: {
  ticket: any;
  onSuccess: any;
}) => {
  const [state, setState] = useState<any>();
  const { freshdeskStatuses } = useContext(CommonDataContext);
  const {
    response: jiraResponse,
    isLoading: isLoadingJiraDetails,
    postData: getJiraDetails,
  } = useLazyGetCallWithURL(adaptJiraResponse);

  const [isJiraStatusDone, setIsJiraStatusDone] = useState(false);

  useEffect(() => {
    if (ticket) {
      setState({
        status: ticket?.status,
      });
      setIsJiraStatusDone(false);
      if (ticket?.jira) {
        getJiraDetails(`${TICKETS_INTERNAL_BASE_URL}/${ticket?.jira}`);
      }
    }
  }, [ticket]);

  useEffect(() => {
    if (jiraResponse?.id && jiraResponse?.statusCategory === 'done') {
      setIsJiraStatusDone(true);
    }
  }, [jiraResponse]);

  const updateTicketStatusRes = useLazyPutCall(
    `${TICKETS_BASE_URL}/${ticket?.id}/status`,
  );

  const updateState = (obj) => {
    setState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  };

  const updateTicketStatus = () => {
    const _bodyData = {
      status: state?.status,
    };
    updateTicketStatusRes.postData(JSON.stringify(_bodyData));
  };

  useAddApiNotification({
    successMsg: 'Successfully updated the ticket',
    errorMsg: 'Something went wrong. Please try again.',
    apiResponse: updateTicketStatusRes,
    onSuccess: onSuccess,
  });

  const statusOptions = useMemo(() => {
    if (!ticket?.jira) {
      return freshdeskStatuses;
    }
    return isJiraStatusDone
      ? freshdeskStatuses
      : freshdeskStatuses?.filter((_option) => _option.value !== 5);
  }, [isJiraStatusDone]);

  return {
    state,
    updateState,
    updateTicketStatus,
    isSubmitting: updateTicketStatusRes?.isLoading,
    statusOptions,
  };
};
