import { Divider } from '@aws-amplify/ui-react';
import { FileCopyOutlined } from '@mui/icons-material';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material';
import { ShowAttr } from '../components/ServiceOverview';
import { useStyles, GetInstanceStatus } from '../utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useComputeDetails } from './useComputeDetails';

type GetComputeDetailsProps = {
  computeId: string;
};

export function GetComputeDetails(props: GetComputeDetailsProps) {
  const { computeId } = props;
  const {
    computeIsLoading,
    id,
    cloudResourceId,
    status,
    dbserverSystemId,
    deploymentId,
    name,
  } = useComputeDetails({ computeId });
  const classes = useStyles();

  const computeFields = [
    {
      label: 'Id',
      value: id,
    },
    {
      label: 'Status',
      value: status,
      customRender: <GetInstanceStatus status={status} />,
    },
    {
      label: 'Cloud Resource Id',
      value: cloudResourceId,
    },
    {
      label: 'DB Server System Id',
      value: dbserverSystemId,
    },
    {
      label: 'Deployment Id',
      value: deploymentId,
    },
    {
      label: 'Name',
      value: name,
    },
  ];

  return (
    <Box sx={classes.instanceNwInfoBox} p={2} mr={0}>
      <Typography sx={classes.headingText}>Compute Details</Typography>
      {!computeIsLoading ? (
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column" sx={classes.dbInfo}>
            {computeFields?.map((_property, index) => {
              const val = _property?.customRender
                ? _property?.customRender
                : _property?.value;
              return (
                <Stack>
                  {ShowAttr(
                    _property?.label,
                    val,
                    null,
                    null,
                    null,
                    <CopyToClipboard text={_property.value}>
                      <Button
                        color="primary"
                        startIcon={<FileCopyOutlined />}
                      />
                    </CopyToClipboard>,
                    'compact',
                  )}
                  {index !== computeFields.length - 1 ? <Divider /> : <></>}
                </Stack>
              );
            })}
          </Box>
        </Box>
      ) : (
        <Stack width={'100%'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress />
        </Stack>
      )}
    </Box>
  );
}
