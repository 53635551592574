import { Stack, Typography } from '@mui/material';
import SingleEventIcon from 'assets/icons-v2/SingleEventIcon';
import { eventsStyles } from '../styles';
import { SingleEventType } from '../constants';
import moment from 'moment';
type SingleEventProps = {
  event: SingleEventType;
};

export const SingleEvent = (props: SingleEventProps) => {
  const { event } = props;
  const createdInfo = event?.label?.split(' by');
  const createdText = createdInfo?.[0] || '-';
  const createdBy = createdInfo?.[1] || '-';
  const timestamp = event?.timestamp;
  const timeStampTime = moment(timestamp).format('hh:mm A');
  const timeStampDate = moment(timestamp).format('DD MMM YYYY');

  return (
    <Stack sx={eventsStyles.singleEventContainer}>
      <SingleEventIcon />
      <Stack sx={eventsStyles.singleEventTextContainer}>
        <Stack>
          <Typography variant="subtitle2">{createdText}</Typography>
        </Stack>
        <Stack mt="8px">
          <Typography variant="body1">{`by ${createdBy}`}</Typography>
        </Stack>
        <Stack mt="16px">
          {timestamp && (
            <Typography sx={eventsStyles.timelineContentTimestamp}>
              {timeStampDate} at {timeStampTime}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
