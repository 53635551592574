export default function CopyClipboardIcon() {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5333 5V3.16667C12.5333 2.79848 12.2946 2.5 12 2.5H4.53333C4.23878 2.5 4 2.79847 4 3.16667V12.5C4 12.8682 4.23878 13.1667 4.53333 13.1667H6"
          stroke="#11567F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.4668 7.50016C7.4668 7.13197 7.70558 6.8335 8.00013 6.8335H15.4668C15.7614 6.8335 16.0001 7.13198 16.0001 7.50016V16.8335C16.0001 17.2017 15.7614 17.5002 15.4668 17.5002H8.00013C7.70558 17.5002 7.4668 17.2017 7.4668 16.8335V7.50016Z"
          stroke="#11567F"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  