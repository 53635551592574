import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import {
  AVAILABILITY_MACHINES_BASE_URL,
  DB_SERVICES_BASE_URL,
  URLS,
} from 'constants/URL';
import { useEffect, useMemo, useState } from 'react';
import { adaptAvailabilityMachineResponse } from './adaptAvailabilityMachineResponse';
import { useParams } from 'react-router-dom';
import { createParams } from 'helpers/createParams';

export const useAMDetails = () => {
  const params = useParams();
  const { avMachineId } = params;

  const [service, setService] = useState<any>();

  const {
    response: availabilityMachineResponse,
    isLoading: availabilityMachineIsLoading,
    postData: fetchAvailabilityMachine,
  } = useLazyGetCallWithURL(adaptAvailabilityMachineResponse);

  const availabilityMachine = useMemo(() => {
    return availabilityMachineResponse || {};
  }, [availabilityMachineResponse]);

  useEffect(() => {
    fetchAvailabilityMachine(
      `${AVAILABILITY_MACHINES_BASE_URL}/${avMachineId}`,
    );
  }, [avMachineId]);

  const {
    response: responseByServiceId,
    isLoading: serviceIsLoading,
    postData: fetchDBServiceId,
  } = useLazyGetCallWithURL();

  useEffect(() => {
    if (params?.databaseServiceId) {
      fetchDBServiceId(`${DB_SERVICES_BASE_URL}/${params?.databaseServiceId}`);
    }
  }, [params?.databaseServiceId]);

  useEffect(() => {
    if (responseByServiceId?.response?.length) {
      setService(responseByServiceId?.response?.[0]);
    }
  }, [responseByServiceId]);

  return {
    availabilityMachine,
    availabilityMachineIsLoading,
    service,
    serviceIsLoading,
  };
};
