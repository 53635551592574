export const adaptJiraResponse = (response) => {
  return response
    ? {
        id: response?.key,
        status: response?.fields?.status?.name,
        statusCategory: response?.fields?.status?.statusCategory?.key,
        summary: response?.fields?.summary,
      }
    : null;
};
