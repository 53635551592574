import { TopProcessType } from '../types/TopProcessTypes';
import { styles } from 'assets/scss/style-templates';

const countDecimals = (value: number): number => {
  if (value % 1 !== 0) return value.toString().split('.')[1].length;
  return 0;
};

export const getTopProcessTableRows = (topProcess: TopProcessType) =>
  topProcess?.metricValues?.map((row, rowIndex) => {
    const { pid, processName, value } = row;

    return {
      pid,
      processName,
      lastVal: `${value.toFixed(Math.min(countDecimals(value), 3))} ${
        topProcess?.unit
      }`,
      styles: {
        background:
          rowIndex % 2
            ? styles.color.greyBackgroundFA
            : styles.color.baseBackground,
      },
    };
  });
