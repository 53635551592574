import { InfoOutlined } from '@mui/icons-material';
import { Divider, Stack, Tooltip, Typography } from '@mui/material';

interface ComputeShapeInfoProps {
  readIops: any;
  writeIops: any;
}

const ComputeShapeInfo = (props: ComputeShapeInfoProps) => {
  const { readIops, writeIops } = props;
  const isHighPerformance = readIops && writeIops;
  const displayStrings = {
    title: isHighPerformance
      ? 'High Performance Tessell Compute'
      : 'Standard Tessell Compute',
    subText: isHighPerformance
      ? 'Leverage locally attached NVMe for data storage and achieve all the durability needed for your data infrastructureInfo.'
      : 'Leverage the traditional cloud storage for data storage with cloud-native durability for your data infrastructureInfo.',
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      m={0.5}
      spacing={0.5}
    >
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Typography variant="body1" fontSize="16px" fontWeight="600">
          {displayStrings.title}
        </Typography>
      </Stack>
      <Divider
        sx={{
          width: '100%',
          height: '5px',
        }}
      />
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Typography variant="body1" fontSize="14px" fontWeight="500">
          {displayStrings.subText}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ComputeShapeInfo;
