import { useGetGroups } from 'Administration/IAM/Groups/Common/hooks/useGetGroups';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useLazyPostCall } from 'common/api/useApiCall';
import { URLS, USERS_BASE_URL } from 'constants/URL';
import { useContext, useEffect, useMemo, useState } from 'react';

type CreateUserProps = {
  handleCreateUserSuccess: Function;
  setOpen: Function;
};

export const useCreateuser = (props: CreateUserProps) => {
  const { handleCreateUserSuccess, setOpen } = props;
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const { addNotification } = useContext(NotificationContext);

  const [initialGroups, setInitialGroups] = useState([]);

  const resetState = () => {
    setUserName('');
    setUserEmail('');
    setInitialGroups([]);
  };

  const { groups, groupsIsLoading, getGroups } = useGetGroups();

  const {
    response: createUserResponse,
    postData: createUserPostData,
    isLoading: createUserLoading,
  } = useLazyPostCall(USERS_BASE_URL);

  const payload = useMemo(
    () => ({
      name: userName,
      userEmail: `${userEmail}@tessell.com`,
      groups: initialGroups,
    }),
    [userName, userEmail, initialGroups],
  );

  useEffect(() => {
    if (createUserResponse?.username) {
      addNotification({
        severity: 'success',
        message: `User added successfully with username: ${createUserResponse?.username}`,
      });
      resetState();
      handleCreateUserSuccess();
    } else if (createUserResponse?.message) {
      addNotification({
        severity: 'error',
        message: `${createUserResponse?.message}`,
      });
    }
  }, [createUserResponse]);

  const createUser = () => {
    createUserPostData(JSON.stringify(payload, null));
  };
  const onClose = () => {
    setOpen(false);
  };

  return {
    createUser,
    createUserLoading,
    userEmail,
    userName,
    setUserName,
    setUserEmail,
    onClose,
    initialGroups,
    setInitialGroups,
    groups,
    groupsIsLoading,
    getGroups,
  };
};
