import { Stack, Typography } from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { useStyles } from 'common/utils';
import { useCreateGroup } from './useCreateGroup';
import { LoadingButton } from '@mui/lab';
import { GroupStringConstants } from '../constants';

type CreateGroupModalProps = {
  open: boolean;
  setOpen: any;
  handleOnSuccess: Function;
};

export const CreateGroupModal = (props: CreateGroupModalProps) => {
  const { open, setOpen, handleOnSuccess } = props;

  const classes = useStyles();
  const {
    groupName,
    description,
    createGroup,
    createGroupLoading,
    payload,
    setGroupName,
    setDescription,
    onClose,
  } = useCreateGroup({ handleOnSuccess, setOpen });

  const DialogTitle = () => {
    return (
      <Typography variant="h5">{GroupStringConstants.createPersona}</Typography>
    );
  };

  const DialogActions = () => {
    return (
      <LoadingButton
        loading={createGroupLoading}
        variant="contained"
        disabled={!groupName || !description}
        onClick={() => {
          createGroup(JSON.stringify(payload, null));
        }}
      >
        Create
      </LoadingButton>
    );
  };

  const DialogContent = () => {
    return (
      <Stack width="400px" spacing={2}>
        <TessellInput
          label="Name"
          width="100%"
          onChange={(e) => {
            setGroupName(e.target.value?.trim());
          }}
          placeholder="Enter name"
        />
        <TessellInput
          label="Description"
          width="100%"
          onChange={(e) => {
            setDescription(e.target.value?.trim());
          }}
          placeholder="Enter description"
        />
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    />
  );
};
