import { Button, Stack, Typography } from '@mui/material';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { useStyles } from 'common/utils';
import { LoadingButton } from '@mui/lab';
import { useUpdateGroupPermissions } from './useUpdateGroupPrivilege';
import TessellAutocomplete, {
  changeHomogenousArrayToAutoCompleteOption,
} from 'common/custom-components/lib/components/TessellAutocomplete';
import { PermissionsArray } from 'Administration/IAM/Privileges/permissions';
import ConfirmationDialog from 'common/Modals/ConfirmationDialog';
import { GroupStringConstants } from '../constants';
import ChipGrid from 'common/custom-components/lib/components/ChipGrid';

type UpdateGroupPermissionsProps = {
  open: boolean;
  setOpen: any;
  handleOnSuccess: Function;
  group: any;
};

const UpdateGroupPermissions = (props: UpdateGroupPermissionsProps) => {
  const { open, setOpen, handleOnSuccess, group } = props;
  const classes = useStyles();
  const {
    isLoading,
    permissions,
    setPermissions,
    onClose,
    onSubmit,
    confirmOpen,
    setConfirmOpen,
  } = useUpdateGroupPermissions({ handleOnSuccess, setOpen, group });

  const DialogTitle = () => {
    return <Typography variant="h5">Update Persona permissions</Typography>;
  };

  const DialogActions = () => {
    return (
      <>
        <Button variant="outlined" size="small" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          size="small"
        >
          Update
        </LoadingButton>
      </>
    );
  };

  const DialogContent = () => {
    return (
      <Stack width="400px" spacing={2} pt={2}>
        <TessellAutocomplete
          label="Permissions"
          value={permissions}
          variant="outlined"
          size="small"
          onChange={(_val) => {
            setPermissions(_val);
          }}
          fullWidth
          options={changeHomogenousArrayToAutoCompleteOption(PermissionsArray)}
          name="permissions"
          multiple
        />
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <>
      <TessellModal
        customStyles={customStyles}
        renderFunctions={renderFunctions}
        maxWidth="sm"
        open={open}
        onClose={onClose}
      />
      <ConfirmationDialog
        title={GroupStringConstants.updatePermissions}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        handleConfirm={() => {
          onSubmit();
          setConfirmOpen(false);
        }}
        confirmContent={
          <Stack>
            <Typography mb={1}>
              Are you sure you want update permission
              {permissions?.length > 1 && 's'} for '{group?.name}' from:
            </Typography>
            <ChipGrid
              array={group?.permissions?.map((p) => ({
                name: p,
                color: permissions?.indexOf(p) !== -1 ? 'primary' : 'error',
              }))}
              label={'name'}
            />
            <Typography mb={1}>To:</Typography>
            <ChipGrid
              array={permissions?.map((p) => ({
                name: p,
                color:
                  group?.permissions?.indexOf(p) === -1 ? 'success' : 'primary',
              }))}
              label={'name'}
            />
          </Stack>
        }
        isLoading={isLoading}
      />
    </>
  );
};

export default UpdateGroupPermissions;
