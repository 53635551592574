import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { useDBServer } from './useDBServers';
import OverflowTooltip from 'common/custom-components/lib/components/OverflowTooltip';
import { useStyles } from 'customers/db-services-detailed-view/utils';
import { Button, Popover, Stack, Tooltip, Typography } from '@mui/material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { ITEMS_PER_PAGE, DEFAULT_NULL } from 'constants/appConstants';
import { useMemo } from 'react';
import ComputeInstance from 'customers/db-services-common-view/components/ComputeInstance';
import CloudLogo from 'common/custom-components/lib/components/CloudLogo';
import { GetEngineIcon } from '../../utils/GetEngineIcon';
import { ComputeDetails } from './ComputeDetails';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import {
  StatusBackgroundColor,
  StatusTextColor,
} from 'constants/statusConfigs';
import { Info } from '@mui/icons-material';
import { IPAddressesTooltip } from './IPAddressesTooltip/IPAddressesTooltip';
import { CLOUD_LIST } from 'customers/db-services-common-view/components/DBServices/constants';
import { STATUS_LIST } from './constants';

type DBServersProps = {
  tenant: TenantType;
};

const DBServers = (props: DBServersProps) => {
  const { tenant } = props;
  const classes = useStyles();
  const {
    filters,
    subsList,
    page,
    dbServersCount,
    dbServersPageCount,
    computeAnchor,
    isLoading,
    selectedRow,
    dbServers,
    setComputeAnchor,
    setSelectedRow,
    handlePaginationChange,
    handleFilterChange,
    handleRowClick,
  } = useDBServer({
    tenant,
  });

  const filterProps = useMemo(
    () => [
      {
        name: 'Cloud',
        initialVal: filters?.cloud || 'ALL',
        options: CLOUD_LIST,
        onChange: (value) => handleFilterChange('cloud', value),
      },
      {
        name: 'Status',
        initialVal: filters?.status || 'ALL',
        options: STATUS_LIST,
        onChange: (value) => handleFilterChange('status', value),
      },
      subsList?.length && {
        name: 'Subscription',
        initialVal: filters?.subscription || 'ALL',
        options: [
          {
            id: 'ALL',
            name: 'All',
          },
          ...subsList,
        ],
        onChange: (value) => handleFilterChange('subscription', value),
      },
    ],
    [filters],
  );
  const searchProps = useMemo(
    () => ({
      name: 'Search',
      onChange: (value) => handleFilterChange('search-query', value),
    }),
    [],
  );

  const DBServersColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Server Name',
        renderCell: (params) => {
          const { row } = params;
          const { serverName, engineType } = row;
          return (
            <Stack direction="row" spacing={2}>
              <Stack>
                <GetEngineIcon engine={engineType?.toUpperCase()} size={24} />
              </Stack>
              <OverflowTooltip
                sxClass={classes.boldText}
                text={serverName || DEFAULT_NULL}
                placement="left"
                variant="body2"
              />
            </Stack>
          );
        },
        width: 150,
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params) => {
          const { row: data } = params;
          const { status } = data;
          return (
            <Stack direction="row" alignItems="center">
              <StatusLabel
                backgroundColor={StatusBackgroundColor[status]}
                textColor={StatusTextColor[status]}
                text={status || 'NA'}
              />
            </Stack>
          );
        },
        width: 90,
      },
      {
        field: 'cloud',
        headerName: 'Cloud/Region',
        width: 150,
        renderCell: (params) => {
          const { row } = params;
          const { cloud, cloudRegion, cloudRegionFullName } = row;

          return (
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              height="36px"
            >
              <Stack width="25px">
                <CloudLogo name={cloud?.toUpperCase()} />
              </Stack>
              <Stack>
                <Typography>{cloudRegion || DEFAULT_NULL}</Typography>
                <Typography>{cloudRegionFullName || DEFAULT_NULL}</Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        field: 'subscription',
        headerName: 'Subscription',
        width: 100,
        renderCell: (params) => {
          const { row } = params;
          const { subscriptionId } = row;
          const name =
            tenant?.subscriptions?.find((_sub) => {
              return _sub?._id === subscriptionId;
            })?.name || '';
          return (
            <Stack>
              <OverflowTooltip
                text={name || DEFAULT_NULL}
                placement="bottom"
                variant="body2"
              />
            </Stack>
          );
        },
      },
      {
        field: 'compute',
        headerName: 'Compute',
        width: 100,
        renderCell: (params) => {
          const { row } = params;
          const { computeType } = row;

          return (
            <Stack>
              <ComputeInstance value={computeType} row={row} />
            </Stack>
          );
        },
      },
      {
        field: 'created',
        headerName: 'Created',
        width: 150,
        renderCell: (params) => {
          const { row } = params;
          const { dateCreated, owner } = row;
          return (
            <Stack>
              <Typography>{dateCreated || DEFAULT_NULL}</Typography>
              <Typography variant="body2">{owner || DEFAULT_NULL}</Typography>
            </Stack>
          );
        },
      },
      {
        field: 'vmIp',
        headerName: 'Vm IP (Private)',
        width: 100,
        renderCell: (params) => {
          const { row } = params;
          const { privateIp, ipAddresses } = row;
          return (
            <Stack direction="row" spacing={1}>
              <OverflowTooltip
                text={privateIp || DEFAULT_NULL}
                placement="bottom"
                variant="body2"
              />
              <Tooltip
                arrow
                title={<IPAddressesTooltip ipAddresses={ipAddresses} />}
              >
                <Info color="primary" sx={{ fontSize: '14px' }} />
              </Tooltip>
            </Stack>
          );
        },
      },
      {
        field: 'moreDetails',
        headerName: '',
        width: 100,
        renderCell: (params) => {
          const { row } = params;
          return (
            <Button
              color="primary"
              component="button"
              sx={{ alignSelf: 'flex-start', padding: '0px' }}
              onClick={(e) => {
                e?.stopPropagation();
                setSelectedRow(row);
                setComputeAnchor(e.currentTarget);
              }}
            >
              More Details
            </Button>
          );
        },
      },
    ],
    [dbServers],
  );

  return (
    <Stack sx={{ height: '60vh' }}>
      <DataGrid
        columns={DBServersColumns}
        rows={dbServers || []}
        filters={filterProps}
        search={searchProps}
        styleOverrides={{ hideShadow: true }}
        loading={isLoading}
        itemsPerPage={ITEMS_PER_PAGE}
        pageNo={page}
        totalCount={dbServersCount || 0}
        handlePaginationChange={handlePaginationChange}
        pageCount={dbServersPageCount}
        onRowClick={handleRowClick}
      />
      <Popover
        open={Boolean(computeAnchor)}
        anchorEl={computeAnchor}
        onClose={() => setComputeAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {selectedRow && <ComputeDetails row={selectedRow} />}
      </Popover>
    </Stack>
  );
};

export default DBServers;
