import { useDataAndCount } from 'common/Hooks/useDataAndCount';
import { BACKUP_BASE_URL } from 'constants/URL';
import { DBBackupType } from 'constants/URLEnums';
import { useMemo, useState } from 'react';

type BackupProps = {
  availabilityMachineId: string;
  tenantId: string;
  backupIdentifier?: string;
};

export const useBackups = (props: BackupProps) => {
  const { availabilityMachineId, tenantId, backupIdentifier } = props;
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);
  const [selectedBackup, setSelectedBackup] = useState(null);
  const [openChildrenModal, setOpenChildrenModal] = useState(false);
  const [
    backupIdentifierFromRowClick,
    setSelectedBackupIdentifierFromRowClick,
  ] = useState('');

  const [filters, setFilters] = useState({
    status: 'All',
  });

  const backupParams = useMemo(() => {
    return {
      'db-backup-type': DBBackupType.backup,
      'availability-machine-id': availabilityMachineId,
      ...(backupIdentifier?.length !== 0
        ? { 'backup-identifier': backupIdentifier }
        : {}),
      status: filters?.status,
    };
  }, [availabilityMachineId, backupIdentifier, filters]);

  const {
    count: backupsCount,
    pageCount: backupsPageCount,
    dataResponse: backups,
    dataIsLoading: backupsIsLoading,
    countIsLoading: backupsCountIsLoading,
    page,
    handlePaginationChange,
    fetchDataAndCount: fetchBackups,
    setPage,
  } = useDataAndCount({
    url: BACKUP_BASE_URL,
    dataResponseAdapter: (response) => {
      return response?.response?.map((res) => ({ ...res, id: res?._id })) || [];
    },
    params: backupParams,
  });

  const handleRowClick = (rowClicked) => {
    setSelectedBackupIdentifierFromRowClick(rowClicked?.row?.backupIdentifier);
    setOpenChildrenModal(true);
  };

  const handleResetFilters = () => {
    setFilters({ status: 'All' });
  };

  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, [filterKey]: val }));
  };

  return {
    updateMetadataOpen,
    setUpdateMetadataOpen,
    selectedBackup,
    setSelectedBackup,
    backupsCount,
    backupsPageCount,
    backups,
    backupsIsLoading,
    backupsCountIsLoading,
    page,
    handlePaginationChange,
    openChildrenModal,
    setOpenChildrenModal,
    handleRowClick,
    backupIdentifierFromRowClick,
    fetchBackups,
    filters,
    handleResetFilters,
    handleFilterChange,
  };
};
