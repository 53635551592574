import { Stack, Typography } from '@mui/material';
import { noDataStyles } from './styles';

const NoData = () => {
  return (
    <Stack sx={noDataStyles}>
      <Typography variant="body1">No data for the selected filters.</Typography>
    </Stack>
  );
};

export default NoData;
