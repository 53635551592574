/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function ConnectionPool(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="8.74997" cy="4.5" rx="6.75" ry="3" stroke="var(--icon-color)" />
      <path
        d="M1.99997 9C1.99997 10.3807 5.02205 11.5 8.74997 11.5C9.17707 11.5 9.59489 11.4853 9.99997 11.4572"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M1.99997 4.5V13.0185C1.99997 14.3978 4.12043 15.5584 7 15.8988M15.5 4.5V7"
        stroke="var(--icon-color)"
        strokeLinecap="round"
      />
      <path
        d="M14 10.5V13H15V10.5H14ZM10.5 16.5V18H11.5V16.5H10.5ZM12.5 14.5C11.3954 14.5 10.5 15.3954 10.5 16.5H11.5C11.5 15.9477 11.9477 15.5 12.5 15.5V14.5ZM14 13C14 13.8284 13.3284 14.5 12.5 14.5V15.5C13.8807 15.5 15 14.3807 15 13H14Z"
        fill="var(--icon-color)"
      />
      <path
        d="M14 10.5V13H15V10.5H14ZM17.5 16.5V18H18.5V16.5H17.5ZM16.5 15.5C17.0523 15.5 17.5 15.9477 17.5 16.5H18.5C18.5 15.3954 17.6046 14.5 16.5 14.5V15.5ZM14 13C14 14.3807 15.1193 15.5 16.5 15.5V14.5C15.6716 14.5 15 13.8284 15 13H14Z"
        fill="var(--icon-color)"
      />
      <circle cx="14.5" cy="10.5" r="1.5" fill="var(--icon-color)" />
      <circle cx="11" cy="18" r="1" fill="var(--icon-color)" />
      <circle cx="18" cy="18" r="1" fill="var(--icon-color)" />
    </svg>
  );
}
