import { Add, ChevronRight, Edit, ExpandMore } from '@mui/icons-material';
import { Link, Stack } from '@mui/material';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { Priorities } from '../../utils/constants';
import { useStyles } from 'customers/db-services-detailed-view/utils';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import {
  StatusBackgroundColor,
  StatusTextColor,
} from 'constants/statusConfigs';
import { useContext, useMemo } from 'react';
import { TicketFormModal } from 'customers/ticket/TicketFormModal';
import { JIRA_BASE_LINK, TICKETS_PER_PAGE } from 'constants/appConstants';
import { useTickets } from './useTickets';
import { TicketUpdateModal } from './UpdateTicket';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';

interface TicketsTableProps {
  tenant?: TenantType;
}

export const enum MODAL_TYPE {
  CREATE,
  UPDATE,
  UPDATE_STATUS,
}

export const TicketsTable = (props: TicketsTableProps) => {
  const { tenant } = props;
  const classes = useStyles();

  const {
    tickets,
    isLoadingTickets,
    showModal,
    setShowModal,
    updateTicketStatus,
    updateTicketStatusProps,
    createTicket,
    createTicketProps,
    handleFilterChange,
    handleResetFilters,
    handleModalClose,
    handleOnSuccess,
    selectedTicket,
    setSelectedTicket,
    filters,
    handleRowClick,
    ticketsCount,
    ticketAgents,
    ticketsPageCount,
    page,
    handlePaginationChange,
    freshdeskStatuses,
    getStatusUsingValue,
    tenants,
    exportIsLoading,
    handleExportClick,
  } = useTickets({ tenant });

  const { hasPermission } = useContext(UserPermissionsContext);

  const ticketColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 120,
      },
      {
        field: 'subject',
        headerName: 'Subject',
        minWidth: 120,
        editable: true,
      },
      {
        field: 'descriptionText',
        headerName: 'Description',
        minWidth: 120,
        editable: true,
      },
      {
        field: 'severity',
        headerName: 'Severity',
        minWidth: 120,
        renderCell: (params) => {
          const { row } = params;
          return (
            <StatusLabel
              backgroundColor={StatusBackgroundColor[row?.severity]}
              textColor={StatusTextColor[row?.severity]}
              text={row?.severity || 'NA'}
            />
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 120,
        renderCell: (params) => {
          const { row } = params;
          const _status = getStatusUsingValue(row?.status);

          return (
            <StatusLabel
              backgroundColor={StatusBackgroundColor[_status]}
              textColor={StatusTextColor[_status]}
              text={_status || 'NA'}
            />
          );
        },
      },
      {
        field: 'agent',
        headerName: 'Assignee',
        minWidth: 120,
        editable: true,
      },
      {
        field: 'ticketId',
        headerName: 'JIRA Ticket',
        minWidth: 120,
        renderCell: (params) => {
          const { row } = params;
          const link = JIRA_BASE_LINK + row?.jira;
          return (
            <Stack>
              {row?.jira ? (
                <Link href={link} target="_blank">
                  {row?.jira}
                </Link>
              ) : (
                '-'
              )}
            </Stack>
          );
        },
      },
      {
        field: 'created',
        headerName: 'Created',
        minWidth: 120,
      },
      {
        field: 'lastUpdated',
        headerName: 'Last Updated',
        minWidth: 120,
      },
    ],
    [freshdeskStatuses],
  );

  const menuItems = (_ticket) => {
    return [
      hasPermission(PERMISSIONS.TICKET_UPDATE) && {
        label: 'Update',
        icon: <Edit sx={classes.icon} />,
        onClick: () => {
          setShowModal(MODAL_TYPE.UPDATE);
          setSelectedTicket(_ticket);
        },
      },
    ].filter(Boolean);
  };

  const filterProps = useMemo(
    () =>
      [
        !tenant?._id && {
          name: 'Tenant',
          column: 'tenant',
          initialVal: filters?.['tenant-ids'] || [],
          options: tenants,
          onChange: (value) => handleFilterChange('tenant-ids', value),
          type: 'multipleSelect',
        },
        {
          name: 'Severity',
          initialVal: filters?.priority,
          column: 'priority',
          options: Priorities?.map(({ value, label }) => ({
            id: value,
            name: label,
          })),
          onChange: (value) => handleFilterChange('priority', value),
        },
        {
          name: 'Status',
          column: 'status',
          initialVal: filters?.status || [],
          options: freshdeskStatuses,
          onChange: (value) => handleFilterChange('status', value),
          type: 'multipleSelect',
        },
      ].filter(Boolean),
    [filters, freshdeskStatuses, tenants],
  );

  const actionProps = useMemo(
    () =>
      [
        hasPermission(PERMISSIONS.TICKET_UPDATE) && {
          startIcon: <Add />,
          label: 'Create Ticket',
          onClick: (e) => {
            setShowModal(MODAL_TYPE.CREATE);
          },
        },
      ].filter(Boolean),
    [],
  );

  return (
    <>
      <Stack sx={{ height: '100vh' }}>
        <DataGrid
          columns={ticketColumns.filter((col) => !!col)}
          rows={tickets || []}
          menuItems={menuItems}
          disableSelectionOnClick
          components={{
            Toolbar: CustomDataGridToolbar,
            DetailPanelCollapseIcon: ExpandMore,
            DetailPanelExpandIcon: ChevronRight,
          }}
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          itemsPerPage={TICKETS_PER_PAGE}
          styleOverrides={{ hideShadow: true }}
          filters={filterProps}
          onResetFilters={handleResetFilters}
          loading={isLoadingTickets}
          apiFilter
          actions={tenant?._id && actionProps}
          onRowClick={handleRowClick}
          totalCount={ticketsCount || 0}
          pageCount={ticketsPageCount}
          pageNo={page}
          handlePaginationChange={handlePaginationChange}
          customExportData={
            hasPermission(PERMISSIONS.DOWNLOAD_TAB_ACCESS) && {
              isLoading: exportIsLoading,
              handleExportClick: handleExportClick,
            }
          }
        />
        <TicketFormModal
          show={showModal === MODAL_TYPE.CREATE}
          onClose={handleModalClose}
          {...createTicketProps}
          onSubmit={createTicket}
          showDueDate={true}
        />

        <TicketUpdateModal
          show={showModal === MODAL_TYPE.UPDATE}
          onClose={handleModalClose}
          onSuccess={handleOnSuccess}
          selectedTicket={selectedTicket}
          ticketAgents={ticketAgents || {}}
          {...updateTicketStatusProps}
        />
      </Stack>
    </>
  );
};
