import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { useContext, useEffect, useState } from 'react';
import { status } from '../constants';
import {
  useLazyGetCallWithURL,
  useLazyPostCall,
  useLazyPutCall,
} from 'common/api/useApiCall';
import { EXCEPTIONS_BASE_URL, URLS } from 'constants/URL';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';

type ExceptionStatusUpdateModalProps = {
  show: boolean;
  setShow: any;

  exception: any;
  handleOnSuccess: any;
  initialStatus: string;
};

export const ExceptionStatusUpdateModal = (
  props: ExceptionStatusUpdateModalProps,
) => {
  const { show, setShow, exception, handleOnSuccess, initialStatus } = props;
  const [selectedStatus, setSelectedStatus] = useState('open');

  useEffect(() => {
    setSelectedStatus(initialStatus);
  }, [initialStatus]);

  const { addNotification } = useContext(NotificationContext);

  const onClose = () => {
    setShow(false);
  };

  const {
    response: updateExceptionStatusResponse,
    isLoading: exceptionStatusUpdating,
    postData: updateExceptionStatus,
    responseStatus: updateExceptionResponseStatus,
  } = useLazyPutCall(`${EXCEPTIONS_BASE_URL}/${exception?.exceptionId}`);

  useEffect(() => {
    if (updateExceptionResponseStatus) {
      if (updateExceptionResponseStatus !== 200) {
        addNotification({
          severity: 'error',
          message: 'Something went wrong.',
        });
        onClose();
      } else if (
        updateExceptionStatusResponse?.response?.status === 'SUCCESSFUL'
      ) {
        addNotification({
          severity: 'success',
          message: `${updateExceptionStatusResponse?.response?.message}`,
        });
        handleOnSuccess();
        onClose();
      }
    }
  }, [updateExceptionStatusResponse, updateExceptionResponseStatus]);

  const makeUpdateStatusCall = () => {
    const payload = {
      status: selectedStatus,
    };
    updateExceptionStatus(JSON.stringify(payload, null));
  };

  return (
    <>
      <Dialog open={show} maxWidth="md" disableEscapeKeyDown>
        <DialogTitle>Update Exception Status</DialogTitle>
        <DialogContent>
          <Stack spacing={2} width={500} py={2}>
            <ShowLabelValue
              label="Exception Id"
              value={exception?.exceptionId}
            />
            <TessellInput
              label="Select status"
              variant="outlined"
              size="medium"
              name="status"
              select
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
              }}
              options={status.map((v) => (
                <MenuItem key={v.name} value={v.id}>
                  <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <Typography variant="subtitle2">{v.name}</Typography>
                    <Typography>{` (${v?.subText})`}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              onClose();
            }}
            size="large"
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => {
              makeUpdateStatusCall();
            }}
            size="large"
            loading={exceptionStatusUpdating}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
