export const adaptEventsResponse = (response) => {
  return (
    response?.response?.map((event) => {
      return {
        label: `${event?.completionMessage} by ${(event as any)?.initiatedBy}`,
        userComment: event?.comment || '',
        timestamp: event?.completedTime,
        eventType: event?.eventType || '',
        contextInfo: event?.contextInfo || [],
      };
    }) || []
  );
};
