import { Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import { useStyles } from 'common/utils';
import { DF } from 'helpers/dateFormats';
import moment from 'moment';
import { useMemo } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const DatePickerToggler = ({
  startTime,
  endTime,
  updateTimeUsingNativePicker,
  startMin,
  endMax,
  handleOnChange,
  rangeType,
  range,
  maxSpanInDays,
  customDateFormat = DF,
  showTimePicker = true,
  additionalInitialSettings,
}: {
  startTime?: any;
  endTime?: any;
  updateTimeUsingNativePicker: any;
  startMin?: any;
  endMax?: any;
  handleOnChange: any;
  rangeType: any;
  range: any;
  maxSpanInDays?: number;
  customDateFormat?: string;
  showTimePicker?: boolean;
  additionalInitialSettings?: any;
}) => {
  const classes = useStyles();

  const timeVal =
    startTime && endTime
      ? `${moment(startTime).format(customDateFormat)} - ${moment(
          endTime,
        ).format(customDateFormat)}`
      : '';

  const initialSettings = useMemo(() => {
    let _settings = {
      timePicker: showTimePicker,
      timePicker24Hour: true,
      timePickerSeconds: true,
      opens: 'center',
      ...additionalInitialSettings,
    };
    if (startTime) {
      _settings['startDate'] = moment(startTime);
    }
    if (endTime) {
      _settings['endDate'] = moment(endTime);
    }
    if (startMin) {
      _settings['minDate'] = moment(startMin);
    }
    if (endMax) {
      _settings['maxDate'] = moment(endMax);
    }
    if (maxSpanInDays) {
      _settings['maxSpan'] = { days: maxSpanInDays };
    }
    return _settings;
  }, [additionalInitialSettings]);

  const NativePicker = () => (
    /* @ts-expect-error: Let's ignore a single compiler error like this unreachable code */
    <DateRangePicker
      onCallback={updateTimeUsingNativePicker}
      initialSettings={initialSettings}
    >
      <Typography sx={{ display: 'flex', fontWeight: 600 }}>
        {timeVal}
      </Typography>
    </DateRangePicker>
  );

  return (
    <ToggleButtonGroup
      value={range}
      exclusive
      color="primary"
      sx={{ ...classes.toggleButton }}
      onChange={handleOnChange}
    >
      {Object.keys(rangeType).map((r) => (
        <ToggleButton
          value={r}
          size="large"
          sx={{
            borderRadius: '4px',
            minWidth: '40px',
            borderColor: styles.color.greyBackground2,
            '&.MuiToggleButton-root': {
              color: `${styles.color.monotoneDarkDark} !important`,
            },
            '&.MuiToggleButton-root.Mui-selected': {
              backgroundColor: `${styles.color.primaryBackgroundLight} !important`,
              color: `${styles.color.darkShade} !important`,
              borderColor: `${styles.color.darkShade} !important`,
              fontWeight: 600,
            },
            ':hover': {
              background: '#E3F3FD !important',
              color: styles.color.darkShade,
            },
          }}
        >
          {r === 'Custom' && range === 'Custom' ? (
            NativePicker()
          ) : (
            <Typography>{r}</Typography>
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default DatePickerToggler;
