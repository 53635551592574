export const OTP_TYPE = {
  ADD_CREDIT: 'add-credits',
  DISABLE_BILLING: 'disable-billing',
  ENABLE_BILLING: 'enable-billing',
  ONBOARD_TENANT: 'onboard-tenant',
  REQUEST_GENIE: 'request-genie',
  RESET_PASSWORD: 'reset-password',
  SILENCE_ALERTS: 'silence-alerts',
  UPDATE_METADATA: 'update-metadata',
  UPDATE_DB_ENGINE_CONFIG: 'update-db-engine-config',
};
