import { SingleEventTimelineTileProps } from '../constants';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { eventsStyles } from '../styles';
import { EventTimelineContent } from './EventsTimelineContent';
import { Stack } from '@mui/material';
import { timelineEventTypesInfo } from '../EventTimelineIcons';

const EventsSingleTimelineTile = (data: SingleEventTimelineTileProps) => {
  const { label, userComment, timestamp, eventType, isLast, contextInfo } =
    data;

  let Icon = null;
  Object.keys(timelineEventTypesInfo).forEach((key, index) => {
    if (
      timelineEventTypesInfo[key]?.value === eventType &&
      timelineEventTypesInfo[key]?.Icon
    ) {
      Icon = timelineEventTypesInfo[key]?.Icon;
    }
  });

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot sx={eventsStyles.timelineDot}>
          {Icon || <Stack sx={eventsStyles.defaultIcon} />}
        </TimelineDot>
        {!isLast && <TimelineConnector sx={eventsStyles.timelineConnector} />}
      </TimelineSeparator>
      <TimelineContent sx={eventsStyles.timelineItem}>
        <EventTimelineContent
          label={label}
          userComment={userComment}
          timestamp={timestamp}
          contextInfo={contextInfo}
        />
      </TimelineContent>
    </TimelineItem>
  );
};

export default EventsSingleTimelineTile;
