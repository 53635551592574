import { LinearProgress, Stack } from '@mui/material';
import { adaptToAutocompleteOption } from 'Administration/IAM/Groups/EditGroup/groupResponseAdapter';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import { costLayout } from '../styles';
import { isEmpty } from 'lodash';
import { COST_MAX_RANGE, CostRangeType, CostResponseKeys } from '../constants';
import DatePickerToggler from 'common/custom-components/lib/components/DatePickerToggler';
import { DF_HUMAN_WITHOUT_TIME } from 'helpers/dateFormats';
import TotalTableAndPieView from '../TotalTableAndPieView/TotalTableAndPieView';
import { EMPTY_STRING } from 'constants/appConstants';
import { adaptSingleElementToAutoCompleteOption } from '../adapters';
import { CostEnums } from 'constants/URLEnums';
import { useCostData } from '../Hooks/useCostData';
import DayWiseChartAndTable from '../DayWiseChartAndTable/DayWiseChartAndTable';
import NoData from '../NoData';

type TenantCostProps = {
  tenantNames: string[];
  tenantNamesIsLoading: boolean;
};

const TenantsCost = (props: TenantCostProps) => {
  const { tenantNames = [], tenantNamesIsLoading = false } = props;

  const {
    startTime,
    endTime,
    selectedEntities: selectedTenants,
    setSelectedEntities: setSelectedTenants,
    costData: tenantsCost,
    costDataisLoading: tenantsCostIsLoading,
    updateTimeUsingNativePicker,
    handleDateToggle,
    range,
  } = useCostData({
    costType: CostEnums.COST_BY_TENANT,
    costParamsKey: 'tenant-name',
  });

  return (
    <Stack sx={costLayout.contentContainer}>
      <Stack sx={costLayout.topBarContainer}>
        <Stack
          direction={'row'}
          sx={costLayout.topBarContentContainer}
          spacing={2}
        >
          <TessellAutocomplete
            label="Tenants"
            showDefaultStyleLabel
            value={selectedTenants}
            variant="outlined"
            size="small"
            onChange={(_val) => {
              setSelectedTenants(_val);
            }}
            fullWidth
            options={
              adaptToAutocompleteOption(
                tenantNames,
                adaptSingleElementToAutoCompleteOption,
              ) || []
            }
            multiple
            disabled={tenantNamesIsLoading}
            sx={{
              ...(!selectedTenants?.length && { width: '150px' }),
            }}
          />
          <DatePickerToggler
            rangeType={CostRangeType}
            startTime={startTime}
            endTime={endTime}
            updateTimeUsingNativePicker={updateTimeUsingNativePicker}
            handleOnChange={handleDateToggle}
            range={range}
            customDateFormat={DF_HUMAN_WITHOUT_TIME}
            showTimePicker={false}
            maxSpanInDays={COST_MAX_RANGE}
          />
        </Stack>
      </Stack>
      <Stack sx={costLayout.graphAndTableContainer}>
        {tenantsCostIsLoading ? (
          <Stack sx={costLayout.loaderContainer}>
            <Stack width="100%">
              <LinearProgress color="primary" />
            </Stack>
          </Stack>
        ) : !tenantsCostIsLoading && !isEmpty(tenantsCost) ? (
          <Stack direction={'row'} spacing={1} width="100%">
            <Stack sx={costLayout.chartContainer}>
              <DayWiseChartAndTable
                costArray={tenantsCost}
                seriesAndHeadName={CostResponseKeys.TENANT_NAME}
                firstColmnTitle="Tenant Name"
              />
            </Stack>
            <TotalTableAndPieView
              costArray={tenantsCost}
              tableFirstColumnKey={CostResponseKeys.TENANT_NAME}
            />
          </Stack>
        ) : (
          <Stack>{!tenantsCost?.length ? <NoData /> : EMPTY_STRING}</Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default TenantsCost;
