import { TabType } from 'common/tab-view/lib/schema/TabType';
import { TabView } from 'common/tab-view/lib/components/TabView';
import _isEmpty from 'lodash/isEmpty';
import { Users } from '../Users';
import { Personas } from '../Groups';
import { People, Person, PrivacyTip } from '@mui/icons-material';
import { useIAMTabView } from './useIAMTabView';
import Privileges from '../Privileges/Privileges';

export const IAMTabView = (): JSX.Element => {
  const { IAMTabType, tabValue, onTabClick } = useIAMTabView();
  const tabsProps: TabType[] = [
    {
      label: IAMTabType.Users,
      tabValue: IAMTabType.Users,
      render: () => {
        return <Users />;
      },
      icon: <Person color="primary" />,
    },
    {
      label: IAMTabType.Personas,
      tabValue: IAMTabType.Personas,
      render: () => {
        return <Personas />;
      },
      icon: <People color="primary" />,
    },
    {
      label: IAMTabType.Permissions,
      tabValue: IAMTabType.Permissions,
      render: () => {
        return <Privileges />;
      },
      icon: <PrivacyTip color="primary" />,
    },
  ].filter(Boolean);

  const currentTab =
    tabsProps?.find((tab) => tab?.tabValue === tabValue) || tabsProps?.[0];

  return (
    <>
      {
        <TabView
          tabs={tabsProps}
          onTabClick={onTabClick}
          currentTab={currentTab}
        />
      }
    </>
  );
};
