import { styles } from 'assets/scss/style-templates';

export const onboardingStyles = {
  titleContainer: {
    mt: 8,
    mb: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionTitles: {
    color: styles.color.monotoneDarkDark,
  },
  mainContainer: {
    width: '100%',
    alignItems: 'start',
    justifyContent: 'center',
  },
  formBox: {
    p: 3,
    width: '100%',
    border: `1px solid ${styles.color.monotoneDarkLight}`,
    borderRadius: '10px',
    minHeight: '100vh',
  },
  actionButtonContainer: { alignItems: 'flex-end' },
  toggleActionContainer: {
    p: 3,
    border: '1px solid grey',
    borderRadius: '10px',
  },
  customerDetailsBox: {},
};
