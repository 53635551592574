import clsx from 'clsx';
import { useStyles } from './utils';

export default function DBServicesIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_227_97)">
        <path
          d="M8.00011 6.28582C12.1028 6.28582 15.4287 5.00663 15.4287 3.42868C15.4287 1.85072 12.1028 0.571533 8.00011 0.571533C3.89742 0.571533 0.571533 1.85072 0.571533 3.42868C0.571533 5.00663 3.89742 6.28582 8.00011 6.28582Z"
          stroke="var(--icon-color)"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.571533 3.42871V12.5716C0.571533 14.1487 3.89725 15.4287 8.00011 15.4287C12.103 15.4287 15.4287 14.1487 15.4287 12.5716V3.42871"
          stroke="var(--icon-color)"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.4287 8C15.4287 9.57714 12.103 10.8571 8.00011 10.8571C3.89725 10.8571 0.571533 9.57714 0.571533 8"
          stroke="var(--icon-color)"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_227_97">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
