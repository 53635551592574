import { BYTES_TO_GB } from 'Layouts/VerticalLayouts/AppUtil';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useLazyGetCallWithURL, useLazyPostCall } from 'common/api/useApiCall';
import { useFetchCloudLocations } from 'common/cloud-region-location/lib/component/useFetchCloudLocations';
import { URLS } from 'constants/URL';
import { DBSERVICES_PER_PAGE, ITEMS_PER_PAGE } from 'constants/appConstants';
import { StatusPriorityOrder } from 'customers/db-services-common-view/utils/StatusPriorityOrder';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { exportResponseAdapter } from 'customers/tenants-list-view/lib/components/ExportData/exportResponseAdapter';
import { capitaliseWord } from 'helpers/capitaliseFirstLetterInEachWord';
import { DF_HUMAN } from 'helpers/dateFormats';
import moment from 'moment';
import { useEffect, useMemo, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

type DBServicesProps = {
  tenant?: TenantType;
  subscriptionId?: any;
  computeID?: string;
};

export const useDBServices = (props: DBServicesProps) => {
  const navigate = useNavigate();
  const { addNotification } = useContext(NotificationContext);

  const { tenant, subscriptionId, computeID } = props;
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);
  const [selectedDbService, setSelectedDbService] = useState(null);
  const [cloudAccountID, setCloudAccountID] = useState('');

  const subscriptionIdToNameMap = {};
  const subs = new Set();
  tenant?.subscriptions?.forEach((sub) => {
    subscriptionIdToNameMap[sub._id] = sub.name;
    subs.add(sub.name);
  });

  const subsList = Array.from(subs)?.map((sub) => ({ id: sub, name: sub }));
  sessionStorage.removeItem('db-services-table-filter');
  const { regionMap } = useFetchCloudLocations();

  const [services, setServices] = useState<DatabaseServiceType[] | any>([]);
  const [filters, setFilters] = useState<any>({
    subscription: tenant?.subscriptions?.find(
      (_s) => _s?._id === subscriptionId,
    )?.name,
  });

  const createParams = () => {
    const _subscriptionId = tenant?.subscriptions?.find(
      (_s) => _s?.name === filters.subscription,
    )?._id;
    const _params = Object.keys(filters)?.reduce(
      (acc, key) =>
        key === 'subscription'
          ? filters[key] && _subscriptionId
            ? `${acc}&subscription-id=${_subscriptionId}`
            : ''
          : acc
          ? `${acc}&${key}=${filters[key]}`
          : `${key}=${filters[key]}`,
      '',
    );
    return (
      _params +
      (tenant ? (_params ? '&' : '') + `tenant-ids=${tenant?._id}` : '')
    );
  };

  const {
    response: servicesResponse,
    responseStatus: servicesResponseStatus,
    isLoading: isLoadingServices,
    postData: servicesPostData,
  } = useLazyGetCallWithURL();

  const {
    response: exportResponse,
    responseStatus: exportResponseStatus,
    isLoading: exportIsLoading,
    postData: exportData,
  } = useLazyPostCall(
    `${URLS.exportDBServices}&${createParams()}`,
    exportResponseAdapter,
  );

  const { response: servicesCountResponse, postData: servicesCountPostData } =
    useLazyGetCallWithURL();

  const servicesCount = useMemo(() => {
    return servicesCountResponse?.response?.count;
  }, [servicesCountResponse]);

  const servicesPageCount = useMemo(
    () => Math.ceil(servicesCount / DBSERVICES_PER_PAGE) || 0,
    [servicesCount],
  );

  useEffect(() => {
    let servicesURL = URLS.fetchDBsURL + (page - 1);
    servicesPostData(
      `${servicesURL}&${createParams()}${
        computeID ? `&compute-resource-id=${computeID}` : ''
      }`,
    );
    servicesCountPostData(
      `${URLS.fetchServicesCount}?${createParams()}${
        computeID ? `&compute-resource-id=${computeID}` : ''
      }`,
    );
  }, [page, filters]);

  useEffect(() => {
    if (
      servicesResponse &&
      servicesResponseStatus === 200 &&
      Array.isArray(servicesResponse?.response)
    ) {
      const unSortedServices: any[] = servicesResponse?.response?.map(
        (service) => ({
          ...service,
          id: service._id,
        }),
      );
      const sortedServices = unSortedServices.sort((a, b) =>
        StatusPriorityOrder[a.status] < StatusPriorityOrder[b.status] ? -1 : 1,
      );
      setServices(sortedServices);
    }
  }, [servicesResponse, servicesResponseStatus]);

  useEffect(() => {
    if (exportResponse?.message) {
      addNotification({
        severity: exportResponseStatus === 200 ? 'success' : 'error',
        message: `${exportResponse?.message}`,
      });
    }
  }, [exportResponse]);

  const dBServicesRows = useMemo(
    () =>
      services?.map((service) => ({
        id: service?._id,
        name: service?.name,
        status: service?.status,
        createdOn: moment(service?.dateCreated)?.format(DF_HUMAN),
        tenantDomain: service?.domain,
        tenantCompany: service?.company,
        cloud: capitaliseWord(service?.infrastructureInfo?.userView?.cloud),
        region: service?.infrastructureInfo?.userView?.region,
        compute: service?.infrastructureInfo?.userView?.computeType,
        owner: service?.ownerId,
        engineType: service?.engineType,
        cloudAccountId: service?.metadata?.cloudAccountId,
        subscription: subscriptionIdToNameMap[service.subscriptionId],
        service,
        computeDetails: {
          vcpus:
            service?.provisionInfo?.infrastructure?.awsInfraConfig
              ?.awsCpuOptions?.vcpus,
          name: service?.infrastructureInfo?.userView?.computeType,
          memoryGB: (
            service?.infrastructureInfo?.userView?.storage / BYTES_TO_GB
          )?.toFixed(5),
        },
        subscriptionId: service?.subscriptionId,
        topology: service?.topology,
      })),
    [services],
  );

  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, [filterKey]: val }));
  };

  const handleRowClick = (params) => {
    const { row: data } = params;
    const { id } = data;
    const url = `/database-services/details/${id}/monitoring`;
    navigate(url);
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleResetFilters = () => {
    setFilters({});
  };

  const handleExportClick = (fileType: string) => {
    const payload = {
      'file-type': fileType,
    };
    exportData(JSON.stringify(payload, null));
  };

  return {
    filters,
    subsList,
    open,
    page,
    regionMap,
    isLoadingServices,
    servicesCount,
    servicesPageCount,
    updateMetadataOpen,
    dBServicesRows,
    cloudAccountID,
    selectedDbService,
    setOpen,
    setSelectedDbService,
    setCloudAccountID,
    setUpdateMetadataOpen,
    handleFilterChange,
    handleRowClick,
    handlePaginationChange,
    handleResetFilters,
    createParams,
    exportIsLoading,
    handleExportClick,
  };
};
