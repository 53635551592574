import { useState } from 'react';
import { ShowLabelValueProps } from 'common/custom-components/lib/components/ShowLabelValue';

type VNSummaryProps = {
  vnDetails: any;
  tenant: any;
};

export const useVNSummary = ({ vnDetails, tenant }: VNSummaryProps) => {
  const [expanded, setExpanded] = useState(true);

  const accordionHeaderProps: ShowLabelValueProps[] = [
    {
      label: 'Cloud',
      value: vnDetails?.cloudType,
    },
    {
      label: 'Subscription',
      value:
        tenant?.subscriptions?.find(
          (_sub) => _sub?._id === vnDetails?.subscriptionId,
        )?.name || '-',
    },
    {
      label: 'Owner',
      value: vnDetails?.owner,
    },
  ];

  const cardSummaryProps: ShowLabelValueProps[] = [
    ...accordionHeaderProps,
    {
      label: 'Region',
      value: vnDetails?.region,
    },
    {
      label: 'IP Address Range',
      value: vnDetails?.cidrBlock,
    },
    {
      label: 'Maturity',
      value: vnDetails?.maturityStatus,
    },
    {
      label: 'Status',
      value: vnDetails?.status,
    },
  ];

  return { expanded, setExpanded, accordionHeaderProps, cardSummaryProps };
};
