export const useGenieOverviewStyles = () => {
  return {
    genieOverviewContainer: {
      overflow: 'hidden',
      border: '1px solid #E6E6E6',
      borderRadius: '8px',
      maxWidth: '700px',
    },
    requestButton: {
      borderRadius: '4px',
      ml: 1,
      height: 30,
      width: 111,
    },
    instanceName: {
      borderTop: '1px solid #E6E6E6',
      marginTop: '10px',
      paddingTop: '10px',
    },
    genieComponent: {
      py: '16px',
      px: '16px',
      borderBottom: '1px solid #E6E6E6',
    },
  };
};
