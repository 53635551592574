import { DF_HUMAN, DF_HUMAN_CHART } from 'helpers/dateFormats';
import { isEmpty } from 'lodash';
import moment from 'moment';

type chartObject = {
  data: object;
};

export const chartObjectAdapter = (props: chartObject) => {
  const { data } = props;
  const keyValuePairs = isEmpty(data) ? {} : data;
  const keys = Object.keys(keyValuePairs)?.map((item) => {
    return moment(item).format(DF_HUMAN_CHART) || '';
  });
  const values = Object.values(keyValuePairs)?.map((_costString) => {
    return Number?.(_costString?.slice(1));
  });
  return { keys, values };
};
