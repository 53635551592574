import { Box, Stack, Typography } from '@mui/material';
import { BackupIcon } from 'assets/icons-v2';
import fontStyles from 'common/TaskProgress/styles/fontStyles';
import getStatusString from 'common/utils/getStatusString';
import { BYTES_TO_GB } from 'customers/db-services-detailed-view/constants/bytesToGB';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import OverflowTip from 'common/custom-components/lib/components/OverflowTooltip';
import moment from 'moment';
import { TessellNumberInRectangle } from 'common/custom-components/lib/components/TessellNulberInRectangle';

export const snapshotsColumns = [
  {
    field: '_id',
    headerName: 'ID',
    headerAlign: 'center',
    hide: true,
  },
  {
    field: 'name',
    headerName: 'Snapshot Name',
    width: 250,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    renderCell: (params) => {
      const { value, row: data } = params;
      const { runningStepName } = data;
      const statusString = getStatusString(value);
      return (
        <>
          {statusString === 'Available' ? (
            <Stack direction={'row'} spacing={2}>
              <Typography>{statusString}</Typography>
              <TessellNumberInRectangle number={data?.childrenCount} />
            </Stack>
          ) : (
            <Stack direction="row">
              <BackupIcon darkBg={false} mr="4px" mt="2px" width={20} />
              <Typography
                sx={{
                  ...fontStyles.fs14_fw500,
                  textDecoration: 'underline',
                  color: '#11567f',
                }}
              >
                {runningStepName || statusString}
              </Typography>
            </Stack>
          )}
        </>
      );
    },
  },
  {
    field: 'logicalSize',
    headerName: 'Logical Size (GiB)',
    width: 100,
    renderCell: (params) => {
      const { row: data } = params;
      const { sizeInfo } = data;

      const backupLogicalSize = (
        sizeInfo?.backupLogicalSize / BYTES_TO_GB
      ).toFixed(3);
      return <Box>{backupLogicalSize}</Box>;
    },
  },
  {
    field: 'physicalSize',
    headerName: 'Physical Size (GiB)',
    width: 100,
    renderCell: (params) => {
      const { row: data } = params;
      const { sizeInfo } = data;

      const backupPhysicalSize = (
        sizeInfo?.backupPhysicalSize / BYTES_TO_GB
      ).toFixed(3);
      return <Box>{backupPhysicalSize}</Box>;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    maxWidth: 100,
    renderCell: (params) => {
      const { row } = params;
      return capitaliseFirstLetterInEachWord(row?.type)?.replace('_', ' ');
    },
  },

  {
    field: 'snapshotType',
    headerName: 'Snapshot Type',
    width: 200,
    renderCell: (params) => {
      const { row } = params;
      return row?.isManual === true || row?.isManual === 'true'
        ? 'Manual'
        : 'Automatic';
    },
  },
  {
    field: 'snapshotID',
    headerName: 'Snapshot Id',
    width: 200,
    renderCell: (params) => {
      const { row } = params;
      return row?.cloudResourceId ? (
        <OverflowTip
          placement="bottom"
          text={row?.cloudResourceId}
          variant="body"
        />
      ) : (
        '-'
      );
    },
  },
  {
    field: 'snapshotTime',
    headerName: 'Snapshot Time',
    width: 200,
    renderCell: (params) => {
      const { row: data } = params;
      const { dateCreated: dateCreatedFromResponse } = data;
      const dateCreated = moment(dateCreatedFromResponse).format(
        'MMM Do, YYYY HH:mm',
      );
      if (!dateCreated) {
        return '';
      }
      return <Box>{dateCreated}</Box>;
    },
  },
];
