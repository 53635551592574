import { useLazyGetCall } from 'common/api/useApiCall';
import { TENANTS_BASE_URL, URLS } from 'constants/URL';
import { useEffect, useState } from 'react';
interface useGetSubscriptionIdNameMapType {
  tenantId: string;
}

export const useGetSubscriptionIdNameMap = (
  props: useGetSubscriptionIdNameMapType,
) => {
  const { tenantId } = props;
  // This hook returns a map of the format {subscriptionId: Name} for a particular tenant
  const [subIdNameMap, setSubIdMap] = useState({});
  const {
    response: tenant,
    isLoading,
    postData: fetchTenant,
  } = useLazyGetCall(`${TENANTS_BASE_URL}/${tenantId}`);
  useEffect(() => {
    fetchTenant();
  }, []);

  useEffect(() => {
    const resp = tenant?.response[0];
    if (resp?.subscriptions && Array.isArray(resp?.subscriptions)) {
      const localSubsIdNameMap = {};
      resp?.subscriptions?.forEach(
        (sub) => (localSubsIdNameMap[sub?._id] = sub?.name),
      );
      setSubIdMap(localSubsIdNameMap);
    }
  }, [tenant]);
  return { subIdNameMap, isLoading };
};
