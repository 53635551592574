import AppUtil from 'tools/AppUtil';

export const adaptLeaksResponse = (response) => {
  return response?.response?.length
    ? response?.response?.map((_res) => {
        return {
          id: _res?._id,
          exception: _res?.exceptionSummary,
          exceptionId: _res?._id,
          status: AppUtil?.toTitleCase(_res?.status || ''),
          dateCreated: _res?.dateCreated,
          dateModified: _res?.dateModified,
          data:
            _res?.data ||
            _res?.volumeData ||
            _res?.vmData ||
            _res?.snapshotData ||
            {},
          tenant:
            _res?.data?.tenantName ||
            _res?.volumeData?.tenantName ||
            _res?.vmData?.tenantName ||
            _res?.snapshotData?.tenantName ||
            null,
          exceptionType: _res?.type,
          reason:
            _res?.data?.reason ||
            _res?.volumeData?.reason ||
            _res?.vmData?.reason ||
            _res?.snapshotData?.reason ||
            null,
          jiraId: _res?.jiraId === '-' ? '' : _res?.jiraId,
        };
      })
    : [];
};
