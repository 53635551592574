export const DBServiceTableStyles = {
  tableContainer: { height: '75vh' },
  NameColumn: {
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      mr: 1,
    },
  },
};
