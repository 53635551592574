import { colorsList } from 'global-components/Dashboard/Charts/Constants';
import ReactApexChart from 'react-apexcharts';
import { CostColors } from '../constants';
import { formatValueToCost } from '../adapters';

type CostPieChartProps = {
  labels: string[];
  data: number[];
  customColors?: string[];
};

export const CostPieChart = (props: CostPieChartProps) => {
  const { labels = [], data = [], customColors } = props;
  return (
    <ReactApexChart
      options={{
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        labels: labels,
        legend: {
          show: false,
        },
        colors: customColors || CostColors,
        plotOptions: {
          pie: {
            expandOnClick: true,
            donut: {
              size: '70',
              labels: {
                show: true,
                value: {
                  formatter: (val) => formatValueToCost(val),
                },
                total: {
                  show: true,
                  formatter: (w) => {
                    const total = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                    return formatValueToCost(total?.toFixed(2));
                  },
                },
              },
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
        },
      }}
      series={data}
      type="donut"
    />
  );
};
