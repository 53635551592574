import { DF_HUMAN } from 'helpers/dateFormats';
import moment from 'moment';

export const adaptTicketResponse = (response) => {
  return response?.response
    ? {
        ...response?.response,
        lastUpdate: moment(response?.response.updated_at).format(DF_HUMAN),
        created: moment(response?.response.created_at).format(DF_HUMAN),
        jira: response?.response?.tags.find(
          (t) => t.startsWith('TS-') || t?.includes('SRE-'),
        ),
      }
    : {};
};

export const adaptTicketConversationsResponse = (response) => {
  return response?.components?.length ? response?.components : [];
};

export const adaptTicketAgentsResponse = (response) => {
  const agents = {};
  if (response?.agents?.length) {
    response?.agents?.forEach((a) => {
      agents[a.id] = a;
    });
  }
  return agents;
};
