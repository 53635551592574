import { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { blue } from '@mui/material/colors';

//Layouts
import VerticalLayout from '../Layouts/index';

//routes
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import { AuthProtected } from './AuthProtected';
import { styles } from 'assets/scss/style-templates';

const AppRoutes = () => {
  const tessellBlue = JSON.parse(JSON.stringify(blue));
  tessellBlue['500'] = styles.color.darkShade;

  const tessellTheme = useMemo(
    () =>
      createTheme({
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                borderRadius: '20px',
                transition:
                  'background-color 200ms linear, box-shadow 200ms linear, border-color 200ms linear',
              },
              text: {
                '&:hover': {
                  color: `${styles.color.hover} !important`,
                  background: 'none !important',
                  border: 'none !important',
                },
              },
              contained: {
                '&:hover': {
                  color: 'white',
                  boxSizing: 'border-box',
                  boxShadow: '0 4px 10px rgb(0 0 0 / 10%) !important',
                  backgroundColor: `${styles.color.hover} !important`,
                  border: 'none !important',
                },
              },
              outlined: {
                '&:hover': {
                  color: 'white',
                  boxSizing: 'border-box',
                  boxShadow: '0 4px 10px rgb(0 0 0 / 10%) !important',
                  backgroundColor: `${styles.color.hover} !important`,
                  border: 'none !important',
                },
              },
            },
          },
          MuiFab: {
            styleOverrides: {
              circular: {
                '&:hover': {
                  color: 'white',
                  boxSizing: 'border-box',
                  boxShadow: '0 4px 10px rgb(0 0 0 / 10%) !important',
                  backgroundColor: `${styles.color.hover} !important`,
                  border: 'none !important',
                },
              },
            },
          },
          MuiLoadingButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                borderRadius: '20px',
                transition:
                  'background-color 200ms linear, box-shadow 200ms linear, border-color 200ms linear',
              },
              text: {
                '&:hover': {
                  color: `${styles.color.hover} !important`,
                  background: 'none !important',
                  border: 'none !important',
                },
              },
              contained: {
                '&:hover': {
                  color: 'white',
                  boxSizing: 'border-box',
                  boxShadow: '0 4px 10px rgb(0 0 0 / 10%) !important',
                  backgroundColor: `${styles.color.hover} !important`,
                  border: 'none !important',
                },
              },
              outlined: {
                '&:hover': {
                  color: 'white',
                  boxSizing: 'border-box',
                  boxShadow: '0 4px 10px rgb(0 0 0 / 10%) !important',
                  backgroundColor: `${styles.color.hover} !important`,
                  border: 'none !important',
                },
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                transition:
                  'background-color 200ms linear, box-shadow 200ms linear',
                '&:hover': {
                  boxSizing: 'border-box',
                  boxShadow: '0 4px 10px rgb(0 0 0 / 10%) !important',
                },
              },
            },
          },
          MuiToggleButton: {
            styleOverrides: {
              root: {
                borderRadius: '20px',
                borderColor: '#c4c4c4',
                textTransform: 'none',
                padding: '8px',
                '&.Mui-selected': {
                  backgroundColor: `${styles.color.darkShade} !important`,
                  color: styles.color.baseBackground,
                },
              },
            },
          },
          MuiSnackbar: {
            styleOverrides: {
              anchorOriginBottomLeft: {
                left: '5px !important',
                bottom: '90px !important',
              },
            },
          },
          MuiListItem: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  backgroundColor: `${styles.color.lighterShade} !important`,
                },
              },
            },
          },
          MuiAccordionSummary: {
            styleOverrides: {
              root: {
                backgroundColor: styles.color.greyBackground,
              },
              content: {
                margin: '0 !important',
              },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                '&.Mui-expanded': {
                  margin: 0,
                  marginTop: 2,
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: '5px',
                transition:
                  'background-color 500ms linear, box-shadow 500ms linear',
                '&:hover': {
                  backgroundColor: styles.color.greyBackground,
                  boxSizing: 'border-box',
                  boxShadow: '0 4px 10px rgb(0 0 0 / 10%) !important',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: styles.color.darkShade,
                },
              },
            },
          },
          MuiFilledInput: {
            styleOverrides: {
              root: {
                borderRadius: '8px',
                transition:
                  'background-color 500ms linear, box-shadow 500ms linear',
                backgroundColor: '#FFF',
                '&:hover': {
                  backgroundColor: styles.color.lighterShade,
                  boxSizing: 'border-box',
                  boxShadow: '0 4px 10px rgb(0 0 0 / 10%) !important',
                },
                '&.Mui-focused': {
                  backgroundColor: '#FFF',
                },
              },
              underline: {
                '&:before': {
                  border: '0 !important',
                },
                '&:after': {
                  border: '0 !important',
                },
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              select: {
                '&:focus': {
                  borderTopRightRadius: '20px !important',
                  borderBottomRightRadius: '20px !important',
                  backgroundColor: 'transparent',
                },
              },
              icon: {
                color: styles.color.darkShade,
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              root: {
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                borderBottom: `1px solid #E6E6E6`,
                minHeight: '44px',
              },
              indicator: {
                backgroundColor: '#4996DB',
                height: '3px',
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                paddingTop: 0,
                paddingBottom: 0,
                minHeight: '44px',
                marginLeft: '16px',
                marginRight: '16px',
                paddingLeft: '4px',
                paddingRight: '4px',
                minWidth: 0,
                // opacity: 1,
                // transition: 'background-color 200ms linear',
                color: styles.color.textBoxLabel,
                '&.Mui-selected': {
                  // background: styles.color.lighterShade,
                  color: styles.color.darkShade,
                },
              },
            },
          },
          MuiTabPanel: {
            styleOverrides: {
              root: {
                padding: '8px',
              },
            },
          },
          MuiBadge: {
            styleOverrides: {
              colorPrimary: {
                color: 'white',
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              stickyHeader: {
                backgroundColor: '#E7F3FA',
              },
              head: {
                padding: '8px 16px',
              },
            },
          },
          MuiDialogActions: {
            styleOverrides: {
              root: {
                borderTop: '1px solid #E6E6E6',
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                backgroundColor: '#F8F8F8',
              },
            },
          },
          MuiDataGrid: {
            styleOverrides: {
              root: {
                border: 'none',
                '& .MuiDataGrid-columnSeparator': {
                  color: 'transparent',
                  transition: 'color 200ms linear',
                  '&:hover': {
                    color: styles.color.lighterShade,
                  },
                },
                '& .MuiDataGrid-columnHeader': {
                  '&:focus': {
                    outline: 'none',
                  },
                },
                '& .MuiDataGrid-columnHeaders': {
                  background: `${styles.color.lighterShade2}22`,
                  minHeight: '44px !important',
                  maxHeight: '44px !important',
                  // borderTopRightRadius: '10px',
                  // borderTopLeftRadius: '10px',
                  // borderLeft: '10px solid transparent',
                  textTransform: 'capitalize',
                  padding: '0 12px',
                },
                '& .MuiDataGrid-virtualScroller': {
                  marginTop: '44px !important',
                },
                '& .MuiDataGrid-cell': {
                  flexDirection: 'column',
                  alignItems: 'unset !important',
                  justifyContent: 'center !important',
                  height: 'unset !important',
                  minHeight: '70px !important',
                  maxHeight: 'unset !important',
                  padding: '16px',
                  '&:focus': {
                    outline: 'none',
                  },
                },
                '& .MuiDataGrid-row': {
                  minHeight: 'unset !important',
                  maxHeight: 'unset !important',
                  transition:
                    'background-color 200ms linear, box-shadow 200ms linear, border-color 200ms linear',
                  borderLeft: '4px solid transparent',
                  '&:hover': {
                    backgroundColor: '#F8F8F8',
                    boxShadow: '1px 6px 15px -9px rgba(164, 164, 164, 0.5)',
                    borderLeft: `4px solid ${styles.color.hover}`,
                  },
                },
                '& .MuiDataGrid-toolbarContainer': {
                  background: `${styles.color.lighterShade2}22`,
                  borderTopRightRadius: '10px',
                  borderTopLeftRadius: '10px',
                  borderBottom: `1px solid ${styles.color.lighterShade}`,
                  padding: '12px 12px 6px',
                  display: 'flex',
                  justifyContent: 'space-between',
                },
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              rounded: {
                borderRadius: '10px',
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              root: {
                borderRadius: '10px',
              },
            },
          },
          MuiSlider: {
            styleOverrides: {
              root: {
                '& .MuiSlider-valueLabel': {
                  lineHeight: 1.2,
                  fontSize: 12,
                  padding: 0,
                  width: 24,
                  height: 24,
                  borderRadius: '50% 50% 50% 0',
                  backgroundColor: styles.color.darkShade,
                  transformOrigin: 'bottom left',
                  transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
                  '&:before': { display: 'none' },
                  '&.MuiSlider-valueLabelOpen': {
                    transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
                  },
                  '& > *': {
                    transform: 'rotate(45deg)',
                  },
                },
                '& .MuiSlider-track': {
                  height: '2px',
                },
                '& .MuiSlider-rail': {
                  height: '2px',
                },
              },
            },
          },
        },
        palette: {
          primary: tessellBlue,
          secondary: {
            main: styles.color.lightShade,
          },
          error: {
            light: styles.color.errorBackground,
            main: styles.color.red,
            // dark: styles.color.red,
          },
          warning: {
            light: styles.color.warningBackground,
            main: styles.color.warn,
            // dark: styles.color.warn,
          },
          success: {
            main: styles.color.green,
          },
        },
        typography: {
          fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
          fontWeightLight: 400,
          fontWeightRegular: 500,
          fontWeightMedium: 600,

          h1: {
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
            fontSize: '72px',
            fontWeight: '700',
          },
          h2: {
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
            fontSize: '60px',
            fontWeight: '700',
          },
          h3: {
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
            fontSize: '48px',
            fontWeight: '700',
          },
          h4: {
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
            fontSize: '36px',
            fontWeight: '700',
          },
          h5: {
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
            fontSize: '24px',
            fontWeight: '700',
          },
          h6: {
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
            fontSize: '20px',
            fontWeight: '700',
          },
          subtitle1: {
            fontSize: '16px',
            fontWeight: '400',
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
          },
          subtitle2: {
            fontSize: '16px',
            fontWeight: '600',
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
          },
          body1: {
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
          },
          body2: {
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
          },
          caption: {
            fontSize: '12px',
            fontWeight: '400',
            fontFamily: '"Lato", "Texta", "Helvetica", "Arial", sans-serif',
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            laptop: 1439,
            desktop: 1679,
            miniLaptop: 1279,
          },
        },
      } as any),
    [tessellBlue],
  );

  return (
    <ThemeProvider theme={tessellTheme}>
      <CssBaseline />
      <>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.component} key={idx} />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </AuthProtected>
              }
              key={idx}
            />
          ))}
        </Routes>
      </>
    </ThemeProvider>
  );
};

export default AppRoutes;
