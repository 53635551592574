import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import { TabType } from '../schema/TabType';
import { TabPanel } from './TabPanel';
import { TabViewStyles } from './TabView.styles';

interface TabViewProps {
  tabs: TabType[];
  onTabClick: (event: any, newValue: any) => void;
  currentTab: TabType;
}

export const TabView = (props: TabViewProps) => {
  const { tabs, onTabClick, currentTab } = props;
  const styles = TabViewStyles();

  return (
    <Stack
      sx={{ ...styles.contentBox, width: '100%' }}
      flexGrow={1}
      display="flex"
      minHeight="500px"
      flexDirection="column"
    >
      <Stack sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs
          value={currentTab?.tabValue}
          textColor="primary"
          indicatorColor="primary"
          onChange={onTabClick}
          // aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {tabs?.map((tabProps) => (
            <MuiTab
              icon={tabProps?.icon}
              iconPosition="start"
              value={tabProps?.tabValue}
              label={tabProps?.label}
            />
          ))}
        </MuiTabs>
      </Stack>
      {tabs?.map((tabProps) => {
        return (
          <TabPanel value={currentTab?.tabValue} index={tabProps?.tabValue}>
            {tabProps?.render?.()}
          </TabPanel>
        );
      })}
    </Stack>
  );
};
