export const PERMISSIONS = {
  ONBOARD_TENANT: 'ONBOARD_TENANT',
  TICKET_UPDATE: 'TICKET_UPDATE',
  TICKET_VIEW: 'TICKET_VIEW',
  GENIE: 'GENIE',
  UPDATE_TENANT: 'UPDATE_TENANT',
  INFRA_MONITORING: 'INFRA_MONITORING',
  DOWNLOAD_TAB_ACCESS: 'DOWNLOAD_TAB_ACCESS',
  ALERTS_SILENCE: 'ALERTS_SILENCE',
  ALERTS_VIEW: 'ALERTS_VIEW',
  IAM: 'IAM',
};

export const PermissionsArray = Object.values(PERMISSIONS);
