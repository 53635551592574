import { EMPTY_STRING } from 'constants/appConstants';
import {
  DayWiseTableAdapterProps,
  PieAdapterProps,
  TableAdapterProps,
} from './types';
import { PlaneNames, CostResponseKeys, COST_UNKNOWN_STRING } from './constants';
import { useMemo } from 'react';
import { chartObjectAdapter } from 'customers/tenants-detailed-view/lib/components/costSingleTenant/chartObjectAdapter';
import { adaptToAutocompleteOption } from 'Administration/IAM/Groups/EditGroup/groupResponseAdapter';

export const costAdapter = (response) => {
  return response?.response || [];
};

export const envCostAdapter = (response) => {
  return (
    response?.response?.map((_envCostObj) => {
      if (_envCostObj?.[CostResponseKeys.ENV_NAME] === EMPTY_STRING) {
        return {
          ..._envCostObj,
          [CostResponseKeys.ENV_NAME]: COST_UNKNOWN_STRING,
        };
      }
      return _envCostObj;
    }) || []
  );
};

export const planesCostAdapter = (response) => {
  return (
    response?.response?.map((_planeCostObj) => {
      if (_planeCostObj?.serviceType === EMPTY_STRING) {
        return { ..._planeCostObj, serviceType: COST_UNKNOWN_STRING };
      }
      return {
        ..._planeCostObj,
        serviceType:
          PlaneNames[_planeCostObj?.serviceType] || _planeCostObj?.serviceType,
      };
    }) || []
  );
};

export const adaptCostArrayToPieLabelsAndData = (props: PieAdapterProps) => {
  const { costArray, labelKey, dataKey } = props;
  const labels = [];
  const data = [];
  costArray?.forEach((_costObj) => {
    labels?.push(_costObj?.[labelKey]);
    data?.push(Number?.(_costObj?.[dataKey]?.slice(1)));
  });

  return { labels: labels, data: data };
};

const removeDollarFromCostStringAndConvertToNumber = (_costString) => {
  return Number(_costString?.slice(1));
};

export const adaptSingleElementToAutoCompleteOption = (name) => ({
  label: name,
  value: name,
});

export const adaptCostArrayToTableData = (props: TableAdapterProps) => {
  const { costArray, column1, column2 } = props;

  const columns = [
    {
      hide: false,
      headerName: column1?.label,
      field: column1?.value,
    },
    {
      hide: false,
      headerName: column2?.label,
      field: column2?.value,
    },
  ];
  const rows = costArray?.map((_costObj, i) => {
    return {
      id: i,
      [column1?.value]: _costObj?.[column1?.value],
      [column2?.value]: _costObj?.[column2?.value],
    };
  });

  rows?.sort((a, b) => {
    return (
      removeDollarFromCostStringAndConvertToNumber(
        b?.[CostResponseKeys.TOTAL_COST],
      ) -
      removeDollarFromCostStringAndConvertToNumber(
        a?.[CostResponseKeys.TOTAL_COST],
      )
    );
  });

  return { rows, columns };
};

export const useAdaptCostArrayToDayWiseTableData = (
  props: DayWiseTableAdapterProps,
) => {
  const { costArray, headName } = props;
  const keys = useMemo(() => {
    return chartObjectAdapter(costArray?.[0] || {})?.keys || [];
  }, [costArray]);

  const rows = useMemo(() => {
    return costArray?.map((_costObj) => {
      const { keys, values } = chartObjectAdapter(_costObj);
      const datesAndRespectiveCosts = keys?.map((_item, i) => {
        return { [_item]: values[i] };
      });
      const returnObj = {
        name: _costObj?.[headName],
        totalCost: _costObj?.[CostResponseKeys.TOTAL_COST],
      };
      datesAndRespectiveCosts?.forEach((_item) => {
        returnObj[Object.keys(_item)[0]] = `$ ${Object.values(_item)[0]}`;
      });
      return returnObj;
    });
  }, [costArray]);

  rows?.sort((a, b) => {
    return (
      removeDollarFromCostStringAndConvertToNumber(
        b?.[CostResponseKeys.TOTAL_COST],
      ) -
      removeDollarFromCostStringAndConvertToNumber(
        a?.[CostResponseKeys.TOTAL_COST],
      )
    );
  });

  return { tableColumnTitles: keys, tableRows: rows };
};

export const formatValueToCost = (val) => {
  return `$${val}`;
};

export const adaptToAutocompleteOptionWithEmptyString = (array) =>
  adaptToAutocompleteOption(array, (_name) => {
    if (_name === EMPTY_STRING) {
      return { label: COST_UNKNOWN_STRING, value: '' };
    }
    return adaptSingleElementToAutoCompleteOption(_name);
  });
