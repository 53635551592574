import { FiberManualRecord, Download, Close } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  IconButton,
  Tab,
  Toolbar,
  FormControlLabel,
  Switch,
  Tooltip,
  MenuItem,
} from '@mui/material';
import {
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core';
import { LogViewer, LogViewerSearch } from '@patternfly/react-log-viewer';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';
import { noop } from 'lodash';

import '@patternfly/react-core/dist/styles/base.css';
import './log-viewer.scss';

const LogsView = (props: any) => {
  const {
    selectedLogs,
    logFiles,
    isPollingEnabled,
    isPolling,
    goLive,
    entities,
    entityLabel,
    selectedEntity,
    setSelectedEntity,
    downloadFile,
    currentLog,
    setCurrentLog,
    removeFileFromViewList,
    isLogsLoading,
    logViewerRef,
    logText,
    hasLineNumbers,
    onScroll = noop,
    search,
    toggleLineNumbers,
    // * ---- components ---
    HeaderComponent,
    FilteredComponent,

    // * helper fns
    onChangeHandlerLogsSearching,
    onClearHandlerLogsSearching,
    classes,
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      sx={{ '--pf-c-log-viewer__text--PaddingLeft': '5px' }}
      p={!selectedLogs.length ? 2 : 1}
    >
      {!selectedLogs?.length ? (
        logFiles?.length ? (
          <Typography variant="h5">
            Select a file to see the corresponding logs.
          </Typography>
        ) : (
          <Typography variant="h5">No log files to select</Typography>
        )
      ) : (
        <Stack direction="column">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mx={1}
            my={0.5}
          >
            {isPollingEnabled ? (
              <Tooltip
                arrow
                title={
                  isPolling
                    ? 'Fetching live logs every 15 secs'
                    : 'Go Live to start fetching live logs'
                }
                placement="right"
              >
                <Button
                  color="primary"
                  startIcon={
                    isPolling && <FiberManualRecord style={classes.liveLogs} />
                  }
                  onClick={() => {
                    if (!isPolling) {
                      goLive();
                    }
                  }}
                >
                  {isPolling ? 'Live' : 'Go Live'}
                </Button>
              </Tooltip>
            ) : (
              <Box />
            )}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              width="60%"
            >
              {entities?.length > 1 && (
                <TextField
                  select
                  label={entityLabel}
                  value={selectedEntity}
                  variant="standard"
                  size="small"
                  sx={{ width: '300px' }}
                  onChange={(e) => setSelectedEntity(e.target.value)}
                  name="selectedNode"
                >
                  {entities?.map((i) => {
                    return (
                      <MenuItem key={i._id} value={i._id}>
                        {i.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
              {FilteredComponent && <FilteredComponent />}
              <IconButton
                size="small"
                color="primary"
                onClick={() => downloadFile()}
              >
                <Download fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
          <TabContext value={currentLog}>
            <TabList
              onChange={(e, v) => setCurrentLog(v)}
              indicatorColor="primary"
              variant="scrollable"
            >
              {selectedLogs?.map((l) => (
                <Tab
                  value={l}
                  disableRipple
                  sx={classes.logsTab}
                  label={
                    <span>
                      {l}
                      <IconButton
                        size="small"
                        onClick={() => removeFileFromViewList(l)}
                        style={{
                          color: currentLog === l ? '#11567F' : '#08080D',
                        }}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </span>
                  }
                />
              ))}
            </TabList>
            {selectedLogs?.map((l) => (
              <TabPanel value={l}>
                <LoadingOverlay
                  isLoading={isLogsLoading}
                  showChildrenInBackground={true}
                >
                  <LogViewer
                    ref={logViewerRef}
                    data={logText?.[l]?.text}
                    hasLineNumbers={hasLineNumbers[l]}
                    height={450}
                    header={<HeaderComponent />}
                    onScroll={onScroll}
                    toolbar={
                      <Toolbar>
                        <ToolbarContent>
                          <ToolbarGroup
                            alignment={{
                              default: 'alignLeft',
                            }}
                          >
                            <ToolbarItem>
                              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                              {/* @ts-expect-error */}
                              <LogViewerSearch
                                onChange={(event) =>
                                  onChangeHandlerLogsSearching(event, l)
                                }
                                className="log-search-input"
                                onClear={() => onClearHandlerLogsSearching(l)}
                                value={search[l] || ''}
                              />
                            </ToolbarItem>
                          </ToolbarGroup>
                          <ToolbarGroup
                            alignment={{
                              default: 'alignRight',
                            }}
                          >
                            <ToolbarItem>
                              <FormControlLabel
                                label="Show line numbers"
                                control={
                                  <Switch
                                    color="primary"
                                    checked={hasLineNumbers[l]}
                                    onChange={() => toggleLineNumbers(l)}
                                  />
                                }
                              />
                            </ToolbarItem>
                          </ToolbarGroup>
                        </ToolbarContent>
                      </Toolbar>
                    }
                  />
                </LoadingOverlay>
              </TabPanel>
            ))}
          </TabContext>
        </Stack>
      )}
    </Box>
  );
};

export default LogsView;
