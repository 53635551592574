import { Stack, Typography } from '@mui/material';
import TessellTable from 'common/custom-components/lib/components/TessellTable';
import { getTopProcessTableRows } from './helpers/getTopProcessTableRows';
import { getTopProcessTableColumns } from './helpers/getTopProcessTableColumns';
import { commonMonitoringStyles } from './MonitoringDashboardStyles';
import { topProcessTableStyles } from './styles';

export const TopProcess = (props: any) => {
  const { process } = props;

  const { displayName } = process;
  const rows = getTopProcessTableRows(process);
  const columns = getTopProcessTableColumns();
  return (
    <Stack sx={commonMonitoringStyles.monitoringChartContainer} mb={2}>
      <Typography sx={topProcessTableStyles.name}>{displayName}</Typography>
      <Typography sx={topProcessTableStyles.supportText}>
        *Independent of the time range
      </Typography>

      <TessellTable
        columns={columns}
        rows={rows}
        styles={topProcessTableStyles.table}
      />
    </Stack>
  );
};
