export const adaptPGSyncMonitoringResponse = (response) => {
  if (response?.response.result) {
    const _keys = Object.keys(response?.response.result);
    return _keys?.map((_key) => ({
      id: _key,
      name: _key,
      ...response?.response.result[_key],
    }));
  }
  return [];
};
