import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';

interface TabPanelProps {
  children: ReactNode;
  index: string;
  value: string;
}

export const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Stack sx={{ padding: 3 }}>{children}</Stack>}
    </div>
  );
};
