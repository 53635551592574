import { Stack, Typography } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import _includes from 'lodash/includes';
import { styles } from './styles';
import { IAMTabView } from './IAMTabView/IAMTabView';
import { useIAM } from './useIAM';

export const IAM = () => {
  useIAM();
  return (
    <Stack sx={styles.body}>
      <Stack sx={styles.title}>
        <Stack sx={styles.titleContainer}>
          <Typography variant="h5">IAM</Typography>
        </Stack>
      </Stack>
      <Routes>
        <Route path={':tabId/*'} element={<IAMTabView />} />
      </Routes>
    </Stack>
  );
};
