import { useMemo } from 'react';
import { chartObjectAdapter } from '../../chartObjectAdapter';
import { individualTenantCPDPCostObject } from '../TenantCostChart';
import { PlaneCostLabels } from 'customers/cost/constants';

type TenantCPDPCost = {
  tenantCost: [individualTenantCPDPCostObject];
};

export const useTenantCPDPCost = (props: TenantCPDPCost) => {
  const { tenantCost } = props;
  const { keys } = useMemo(() => {
    return chartObjectAdapter(tenantCost?.[0]) || [];
  }, [tenantCost]);

  const servicesData = tenantCost?.map((_costObj) => {
    return {
      name: PlaneCostLabels?.[_costObj?.serviceType],
      values: chartObjectAdapter(_costObj),
    };
  });

  const series = servicesData.map((_item) => {
    return {
      name: _item?.name || '-',
      data: _item?.values?.values || [],
    };
  });

  return {
    keys,
    series,
  };
};
