import { Button, MenuItem, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import OtpModal from 'common/Modals/OtpModal';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { OTP_TYPE } from 'constants/otpType';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { useUpdateDBEngineConfig } from './useUpdateDBEngineConfig';
import { UpdateDBEngineConfigProps } from './types';
import LabelValue from 'common/custom-components/lib/components/LabelValue';
import { FileCopyOutlined } from '@mui/icons-material';
// import 'ace-builds/src-noconflict/mode-json';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ENGINE_LIST } from 'customers/db-services-common-view/components/DBServices/constants';

const UpdateDBEngineConfig = (props: UpdateDBEngineConfigProps) => {
  const { open, tenantId, tenantDomain, handleSuccess } = props;
  const {
    openOTP,
    data,
    sendOTPIsLoading,
    updateIsLoading,
    step,
    engine,
    setEngine,
    onClose,
    setOpenOTP,
    setData,
    setStep,
    modalSubmitHandler,
    handleUpdateClick,
  } = useUpdateDBEngineConfig(props);

  // Dialog
  const DialogTitle = () => <>Update DB Engine Config</>;
  const DialogContent = () => (
    <>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <TessellInput
          filter
          label="Engine"
          width="30%"
          variant="standard"
          size="small"
          name="engine"
          select
          mr={2}
          value={engine || ENGINE_LIST[0]}
          onChange={(e) => setEngine(e.target.value)}
          options={ENGINE_LIST?.filter(
            (e) => e?.name?.toLowerCase() !== 'all',
          )?.map((v) => (
            <MenuItem key={v.id} value={v.id}>
              {v.name}
            </MenuItem>
          ))}
        />
      </Stack>

      <Stack direction="row" alignItems="center" spacing={0.5}>
        <LabelValue label="Tenant id:" value={props.tenantId} />
        <CopyToClipboard text={props.tenantId}>
          <Button color="primary" startIcon={<FileCopyOutlined />} />
        </CopyToClipboard>
      </Stack>

      <TessellInput
        label="DB Engine Config"
        value={data}
        onChange={(e) => setData(e.target.value)}
        multiline
        width="500px"
        rows={50}
        variant="outlined"
        size="small"
        input
      />
    </>
  );

  const DialogActions = () => (
    <Stack width="100%" direction="row" justifyContent="flex-start" mt={2}>
      <Stack spacing={1} direction="row" justifyContent="flex-end" width="100%">
        <Button onClick={onClose} variant="outlined" size="small">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          onClick={handleUpdateClick}
        >
          {'Update'}
        </LoadingButton>
      </Stack>
    </Stack>
  );
  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  return (
    <>
      <TessellModal
        renderFunctions={renderFunctions}
        open={open}
        onClose={onClose}
      />

      <OtpModal
        open={openOTP}
        setOpen={setOpenOTP}
        setStep={setStep}
        onSubmit={modalSubmitHandler}
        isSubmitting={step === 1 ? sendOTPIsLoading : updateIsLoading}
        otpUser="Ops admin"
        step={step}
        reasonSubmitBtnText="Update Config"
        reasonHeading="Enter Reason (with JIRA link)"
        otpType={OTP_TYPE.UPDATE_METADATA}
      />
    </>
  );
};

export default UpdateDBEngineConfig;
