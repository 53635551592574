import { useTheme } from '@mui/styles';
import { styles } from 'assets/scss/style-templates';

const useStyles = () => {
  const theme = useTheme();
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    progress: {
      color: 'white',
    },
    userInput: {
      marginBottom: theme.spacing(2),
    },
    input: {
      marginTop: theme.spacing(2),
    },
    vInput: {
      margin: '10px',
    },
    backButton: {
      alignSelf: 'self-start',
      marginLeft: -1.25,
    },
    personaBox: {
      height: '300px',
      width: '300px',
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      overflow: 'auto',
    },
    featureBox: {
      borderRadius: '15px',
      backgroundColor: '#8996DF33',
      transition: 'background-color 200ms linear',
      '&:hover': {
        backgroundColor: '#8996DF77',
      },
    },
    filterBox: {
      width: 200,
    },
    slaBox: {
      height: 200,
      width: 300,
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    addIcon: {
      fontSize: '64px',
    },
    protectionIcon: {
      width: 32,
      marginRight: theme.spacing(1),
    },
    appIcon: {
      width: '32px',
    },
    personaIcon: {
      height: '100px',
    },
    featureIcon: {
      fontSize: '32px',
    },
    appPlus: {
      fontSize: '12px',
    },
    selectedTreeItem: {
      backgroundColor: 'transparent !important',
    },
    avatar: {
      backgroundColor: styles.color.lightShade,
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    list: {
      width: '100%',
      maxWidth: 360,
      bgcolor: 'background.paper',
    },
    deleteModalContent: {
      marginTop: 1,
    },
    tree: {
      minWidth: 300,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    roundedBtn: {
      marginRight: theme.spacing(1),
      borderRadius: 20,
    },
    reset: {
      width: '100%',
      height: '100%',
      background: '#E6E9FF',
    },
    cancel: {
      width: '10%',
    },
    error: {
      color: 'red',
    },
    tessellLogo: {
      width: '5%',
      height: '5%',
      mx: 'auto',
      my: '-2%',
      border: '10% solid #f0f0f0',
      borderRadius: '50%',
      background: '#E6E9FF',
      zIndex: 'tooltip',
    },
    form: {
      width: '30%',
      border: '1px solid #f0f0f0',
      borderRadius: '3%',
      mx: 'auto',
      background: '#E6E9FF',
    },
    login: {
      width: '150px',
    },
    link: {
      textDecoration: 'inherit',
    },
    userInput60: {
      margin: theme.spacing(1),
      width: '60%',
    },
    filter: {
      width: 250,
      '& .MuiOutlinedInput-root': {
        borderRadius: '20px',
      },
      '& .MuiFilledInput-root': {
        borderRadius: '24px',
      },
    },
  };
};

export default useStyles;
