import {
  Box,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';

type ParameterProfileObject = {
  name: string;
  value: string;
};

type ParameterProfilesPopoverProps = {
  parameters: ParameterProfileObject[];
  isLoading: boolean;
  anchorEl: any;
  handleClose: any;
  isPopoverOpen: boolean;
};

const ParameterProfilesPopover = (props: ParameterProfilesPopoverProps) => {
  const { parameters, isLoading, anchorEl, handleClose, isPopoverOpen } = props;
  return (
    <Popover
      open={isPopoverOpen}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <LoadingOverlay isLoading={isLoading}>
        <Box display="flex" flexDirection="column" p={2} minHeight={50}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>Value</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parameters?.map((param) => (
                  <TableRow key={param.name}>
                    <TableCell component="th" scope="row">
                      {param.name}
                    </TableCell>
                    <TableCell align="right">{param.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </LoadingOverlay>
    </Popover>
  );
};

export default ParameterProfilesPopover;
