import { useState } from 'react';
import { CostView } from './constants';
import { useGetCall } from 'common/api/useApiCall';
import { COST_BASE_URL, URLS } from 'constants/URL';
import { costAdapter } from './adapters';

export const useCost = () => {
  const [view, setView] = useState(CostView.Tenant);
  const handleViewChange = (e, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const { response: tenantNames, isLoading: tenantNamesIsLoading } = useGetCall(
    `${URLS.getTenantIdNames}with-id=false`,
    costAdapter,
  );

  const { response: servicesNames, isLoading: servicesNamesIsLoading } =
    useGetCall(`${COST_BASE_URL}/list/service-names`, costAdapter);

  const { response: environmentsNames, isLoading: environmentsNamesIsLoading } =
    useGetCall(`${COST_BASE_URL}/list/environment-names`, costAdapter);

  const { response: planeNames, isLoading: planeNamesIsLoading } = useGetCall(
    `${COST_BASE_URL}/list/service-types`,
    costAdapter,
  );

  return {
    view,
    handleViewChange,
    tenantNames,
    tenantNamesIsLoading,
    servicesNames,
    servicesNamesIsLoading,
    environmentsNames,
    environmentsNamesIsLoading,
    planeNames,
    planeNamesIsLoading,
  };
};
