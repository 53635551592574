import {
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Cancel,
  CheckCircle,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { passwordChecksArr } from 'helpers/passwordValidation';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { styles } from 'assets/scss/style-templates';
import { useState } from 'react';

const passwordValidationStyles = {
  passwordValidationTooltipBox: {
    backgroundColor: 'white',
    minWidth: '450px',
    width: '100%',
    height: '100%',
    color: 'black',
    borderRadius: '10px',
    border: '1px solid #ccc',
    padding: '12px',
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    top: '20px !important',
  },
  passwordValidationTooltipTitle: {
    color: '#0C3B57',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.4px',
  },
  passwordValidationTooltipText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    color: '#666666',
    display: 'inline',
  },
};

type PasswordValidationTooltipProps = {
  label: string;
  password: string;
  setPassword: any;
  onEnterPress: any;
  setIsPasswordValid: any;
};

export const PasswordValidation = (props: PasswordValidationTooltipProps) => {
  const { label, password, setPassword, onEnterPress, setIsPasswordValid } =
    props;
  const [showPassword, setShowPassword] = useState(false);

  const AllChecks = (password) => {
    const individualChecks = passwordChecksArr.map((currentCheck) =>
      currentCheck?.regex?.test(password),
    );
    const allTest = individualChecks?.reduce((a, c) => a && c);
    return allTest;
  };

  return (
    <Tooltip
      arrow
      disableHoverListener
      placement="right-start"
      title={
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Typography
            sx={passwordValidationStyles.passwordValidationTooltipTitle}
          >
            REQUIREMENTS
          </Typography>
          {passwordChecksArr.map((item) => {
            return (
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Stack width="10px" mr={1}>
                  {item.regex.test(password) ? (
                    <CheckCircle
                      fontSize="small"
                      sx={{ color: styles.color.green }}
                    />
                  ) : (
                    <Cancel fontSize="small" sx={{ color: styles.color.red }} />
                  )}
                </Stack>
                <Typography
                  sx={{
                    ...passwordValidationStyles.passwordValidationTooltipText,
                  }}
                >
                  {item?.text}
                  {item?.subText && (
                    <Typography
                      sx={{
                        ...passwordValidationStyles.passwordValidationTooltipText,
                        color: '#979797',
                      }}
                    >
                      {item?.subText}
                    </Typography>
                  )}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      }
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            ...passwordValidationStyles.passwordValidationTooltipBox,
          },
          '& .MuiTooltip-arrow': {
            '&:before': {
              border: '1px solid #ccc',
            },
            color: 'white',
          },
        },
      }}
    >
      <div>
        <TessellInput
          label={label}
          sx={{
            width: '300px',
            mb: '20px',
          }}
        >
          <TextField
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                onEnterPress();
                ev.preventDefault();
              }
            }}
            variant="outlined"
            size="small"
            name="password"
            fullWidth
            placeholder="Please enter password"
            value={password}
            onChange={(e) => {
              setPassword(e?.target?.value);
              setIsPasswordValid(AllChecks(e?.target?.value));
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              className: 'form-input',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </TessellInput>
      </div>
    </Tooltip>
  );
};
