import { DF_METER } from 'Layouts/VerticalLayouts/AppUtil';
import moment from 'moment';

export const dateFormatterCostChart = (date: string) => {
  const _date = moment(date).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return _date?.format(DF_METER) + 'Z';
};
