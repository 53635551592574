import clsx from 'clsx';
import { useStyles } from './utils';

export default function InfrastructureIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);
  return (
    <svg
      className={clsx({ [className]: !!className })}
      style={iconStyle}
      width="25"
      height="25"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.742676 12.3642V8.22798L4.36191 6.67688L7.98115 8.22798V12.3642L4.36191 13.9153L0.742676 12.3642Z"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.742676 8.2196L4.36191 9.7707L7.98115 8.2196"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.36157 2.56799L7.98081 4.11909L11.6001 2.56799"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.98096 8.2196L11.6002 9.7707L15.2195 8.2196"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.36401 6.67394V2.53767L7.98326 0.986572L11.6025 2.53767V6.67394L7.98326 8.22505L4.36401 6.67394Z"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.98096 12.3642V8.22798L11.6002 6.67688L15.2195 8.22798V12.3642L11.6002 13.9153L7.98096 12.3642Z"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.36157 9.77234V13.9152"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6025 9.77234V13.9152"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.98096 4.11914V8.262"
        stroke="var(--icon-color)"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
