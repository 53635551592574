import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { useStyles } from 'common/utils';
import { useDeleteUsers } from './useDeleteUsers';
import { adaptToAutocompleteOption } from '../groupResponseAdapter';
import { GroupStringConstants } from '../../constants';
import { adaptUserToAutocompleteOption } from '../../Common/helper';
import ConfirmationDialog from 'common/Modals/ConfirmationDialog';
import { usersStyles } from 'Administration/IAM/Users/styles';
import ChipGrid from 'common/custom-components/lib/components/ChipGrid';

type DeleteUsersModalProps = {
  open: boolean;
  setOpen: any;
  group: any;
};

const DeleteUsersModal = (props: DeleteUsersModalProps) => {
  const { open, setOpen, group } = props;
  const classes = useStyles();

  const {
    onClose,
    usersToDelete,
    setUsersToDelete,
    removeUsers,
    usersAvailableToDelete,
    isAnyLoading,
    deleteConfirmation,
    setDeleteConfirmation,
    usersForDeleteConfirmation,
  } = useDeleteUsers({ group, open, setOpen });

  const DialogTitle = () => {
    return (
      <Typography variant="h5">{GroupStringConstants.removeUsers}</Typography>
    );
  };

  const DialogActions = () => {
    return (
      <>
        <Button variant="outlined" size="small" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isAnyLoading}
          variant="contained"
          onClick={() => setDeleteConfirmation(open)}
          size="small"
          disabled={usersToDelete?.length === 0}
        >
          Remove
        </LoadingButton>
      </>
    );
  };

  const DialogContent = () => {
    return (
      <Stack width="400px" spacing={2} pt={2}>
        <TessellAutocomplete
          label="Users"
          value={usersToDelete}
          variant="outlined"
          size="small"
          onChange={(_val) => {
            setUsersToDelete(_val);
          }}
          fullWidth
          options={
            adaptToAutocompleteOption(
              usersAvailableToDelete,
              adaptUserToAutocompleteOption,
            ) || []
          }
          name="removeUsers"
          multiple
          disabled={isAnyLoading}
        />
        <ConfirmationDialog
          title={GroupStringConstants.removeUsers}
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          handleConfirm={() => {
            removeUsers();
            !isAnyLoading && setDeleteConfirmation(false);
          }}
          confirmContent={
            <Stack>
              <Typography mb={1}>
                Are you sure you want to delete following user
                {usersToDelete?.length > 1 && 's'}:
              </Typography>
              <ChipGrid
                array={usersForDeleteConfirmation}
                label={'name'}
                fallback="email"
              />
            </Stack>
          }
          isLoading={isAnyLoading}
        />
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    />
  );
};
export default DeleteUsersModal;
