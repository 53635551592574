import { useGetCall, useLazyGetCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { adaptPGSyncMonitoringResponse } from './adaptPGSyncMonitoringResponse';
import { useEffect } from 'react';

export const usePGSyncMonitoring = () => {
  const {
    isLoading: refreshIsLoading,
    responseStatus: refreshResponseStatus,
    postData: triggerRefresh,
  } = useLazyGetCall(URLS.refreshPGSync);

  const {
    response: pgSyncMonitoringResponse,
    isLoading: monitoringDataisLoading,
    postData: getMonitoringData,
  } = useGetCall(URLS.fetchPGSyncMonitoring, adaptPGSyncMonitoringResponse);

  useEffect(() => {
    if (refreshResponseStatus === 200 && !refreshIsLoading) getMonitoringData();
  }, [refreshIsLoading, refreshResponseStatus]);

  return {
    pgSyncMonitoringResponse,
    isLoading: monitoringDataisLoading || refreshIsLoading,
    triggerRefresh,
  };
};
