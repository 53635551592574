import { Typography } from '@mui/material';
import React from 'react';
import { URLS } from 'constants/URL';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { adaptDapResponse } from './adaptDapResponse';
import { nCountString } from 'helpers/nCountString';
import { ButtonPopover } from 'common/custom-components/lib/components/ButtonPopover';

type DapPopoverProps = {
  tenantId: string;
  tenantPgSource: string;
  amId: string;
};

export const DapPopover = ({
  tenantId,
  tenantPgSource,
  amId,
}: DapPopoverProps) => {
  const {
    response: { count, regions, users },
    postData,
    isLoading,
  } = useLazyGetCallWithURL(adaptDapResponse);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    postData(
      `${URLS.fetchAvailabilityMachineDaps}availability-machine-id=${amId}&tenant-id=${tenantId}&tenant-domain=${tenantPgSource}`,
    );
  };

  return (
    <ButtonPopover isLoading={isLoading} handleClick={handleClick}>
      {count ? (
        <Typography variant="body1">
          {nCountString(count, '', 'DAP across', 'DAPs across')}{' '}
          {nCountString(regions?.size, '', 'region', 'regions')}{' '}
          {!!users.size && 'shared with'}{' '}
          {nCountString(users.size, '', 'user.', 'users.')}
        </Typography>
      ) : (
        'No DAPs created yet'
      )}
    </ButtonPopover>
  );
};
