import { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { useNavigate } from 'react-router-dom';
import {
  useSetUserLoginInfoContext,
  useUserLoginInfoContext,
} from './UserLoginInfo/UserLoginInfoContext';
import { resetPasswordAuth } from './resetPasswordAuth';
import LoginContainer from 'common/custom-components/LoginContainer';
import { PasswordValidation } from 'common/Tooltips/PasswordValidation';
import { ENV, EnvType } from 'constants/env';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { isEmpty } from 'lodash';
import { LoadingButton } from '@mui/lab';

export const ResetPassword = () => {
  //@ts-ignore
  const isProductionOrStage =
    //@ts-ignore
    ENV === EnvType.Production || ENV === EnvType.Staging;
  const [attributes, setAttributes] = useState(
    isProductionOrStage
      ? {
          name: '',
          gender: 'NA',
          phone_number: '+919999999999',
        }
      : {
          name: '',
        },
  );

  const { addNotification } = useContext(NotificationContext);

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState({});
  const [verifyLoading, setVerifyLoading] = useState(false);

  const updateAttributes = (newPayload) => {
    setAttributes((prev) => ({ ...prev, ...newPayload }));
  };

  const loginInfo = useUserLoginInfoContext();
  const setLoginInfo = useSetUserLoginInfoContext();

  const navigate = useNavigate();

  const onSubmit = () =>
    resetPasswordAuth(
      loginInfo,
      password,
      attributes,
      setLoginInfo,
      navigate,
      setError,
      setVerifyLoading,
    );

  useEffect(() => {
    if (typeof error === 'string' && error?.length) {
      addNotification({
        severity: 'error',
        message: error || 'Something went wrong',
      });
    }
    if (typeof error === 'object' && !isEmpty(error)) {
      addNotification({
        severity: 'error',
        message: error?.toString() || 'Something went wrong',
      });
    }
  }, [error]);

  useEffect(() => {
    if (Object.keys(loginInfo)?.length !== 0) {
      if (loginInfo?.signInUserSession?.accessToken?.jwtToken) {
        navigate('/dashboard');
      }
    } else {
      navigate('/login');
    }
  }, [loginInfo]);

  return (
    <LoginContainer>
      <Stack alignItems={'center'} justifyContent={'center'} p={1} spacing={2}>
        <TessellInput
          label="Name"
          value={attributes.name}
          variant="outlined"
          size="small"
          onChange={(e) => updateAttributes({ name: e.target.value })}
          sx={{
            width: '300px',
          }}
        />
        <PasswordValidation
          label={'Reset Password'}
          password={password}
          setPassword={setPassword}
          setIsPasswordValid={setIsPasswordValid}
          onEnterPress={onSubmit}
        />
        <LoadingButton
          disabled={!isPasswordValid || !attributes?.name}
          sx={{
            height: '50px',
            width: '105%',
            borderRadius: '4px',
            backgroundColor: '#3986C1',
          }}
          variant="contained"
          onClick={onSubmit}
          loading={verifyLoading}
        >
          Verify
        </LoadingButton>
      </Stack>
    </LoginContainer>
  );
};
