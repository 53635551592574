class CacheClass {
  prefix = 'tessell-';

  reduxPersistKey = 'persist:root';

  ItemsList: string[] = [];
  constructor() {
    this.ItemsList = Object.keys(localStorage).filter((k) =>
      k.includes(this.prefix),
    );
  }

  get(key): string {
    if (!key) {
      return '';
    }
    return localStorage.getItem(this.prefix + key) || '';
  }

  set(key, value) {
    if (!key) {
      return null;
    }
    this.ItemsList.push(this.prefix + key);
    return localStorage.setItem(this.prefix + key, value);
  }

  remove(key) {
    if (!key) {
      return null;
    }
    try {
      const ret = localStorage.removeItem(this.prefix + key);
      this.ItemsList
        .pop
        // this.ItemsList.indexOf(this.prefix + key)
        ();
      return ret;
    } catch (error) {
      return null;
    }
  }

  clear() {
    sessionStorage.clear();
    this.ItemsList.filter((item) => item !== 'redirect-url').forEach((item) => {
      localStorage.removeItem(item);
    });
    localStorage.removeItem(this.reduxPersistKey);
    this.ItemsList = [];
  }

  toJson() {
    const data = {};
    this.ItemsList.forEach((i) => {
      let value = localStorage.getItem(i) || '';
      try {
        value = JSON.parse(value);
      } catch (e) {
        // do nothing
      }
      data[i] = value;
    });
    return data;
  }
}

const Cache = new CacheClass();

export default Cache;
