import { FileCopyOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DEFAULT_NULL } from 'constants/appConstants';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const showLabelValueElipsisWithCopyStyles = {
  valueContainer: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 12,
    fontWeight: 500,
  },
};

type ShowLabelValueElipsisWithCopyProps = {
  label?: string;
  value?: any;
};

export const ShowLabelValueElipsisWithCopy = (
  props: ShowLabelValueElipsisWithCopyProps,
) => {
  const { label, value } = props;
  return (
    <Stack direction="row" whiteSpace="nowrap" alignItems="center" spacing={1}>
      <Typography fontWeight={700}>{`${label}: `}</Typography>
      <Box
        component="div"
        sx={showLabelValueElipsisWithCopyStyles.valueContainer}
      >
        {value || DEFAULT_NULL}
      </Box>
      <CopyToClipboard text={value || DEFAULT_NULL}>
        <IconButton color="primary" size="small">
          <FileCopyOutlined />
        </IconButton>
      </CopyToClipboard>
    </Stack>
  );
};
