export function TessellLogo(props) {
  return (
    <svg
      width="50"
      height="47"
      viewBox="0 0 50 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.6054 32.7386L25.4789 24.5601L39.5859 16.4154L46.3564 20.4262L46.6589 28.6662L39.6054 32.7386Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5326 6.50139L24.513 22.8246L10.406 14.6799L10.4942 6.81107L17.479 2.42904L24.5326 6.50139Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.56189 33.5496L23.7079 25.4049V41.6943L16.8492 45.5523L9.56189 41.6943V33.5496Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.4446 40.9183L25.4641 24.5951L39.5712 32.7398L39.4829 40.6086L32.4981 44.9906L25.4446 40.9183Z"
        fill="#29B5E8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3946 14.7371L24.5211 22.9156L10.4141 31.0603L3.64364 27.0495L3.3411 18.8095L10.3946 14.7371Z"
        fill="#29B5E8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.7192 14.7993L24.5732 22.944L24.5732 6.65459L31.4319 2.79658L38.7192 6.65459L38.7192 14.7993Z"
        fill="#29B5E8"
      />
    </svg>
  );
}
