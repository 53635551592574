import { CloudAndRegionLocationType } from '../schema/CloudAndRegionLocationType';

export const cloudAndRegionLocation = (): CloudAndRegionLocationType[] => [
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: null,
    name: null,
    id: 'aws',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: null,
    name: null,
    id: 'AWS',
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    region: null,
    name: null,
    id: 'azure',
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    region: null,
    name: null,
    id: 'AZURE',
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    region: 'westUS3',
    name: null,
    id: 'azure/westUS3',
  },
  {
    availabilityZone: 'us-east-2a',
    cloudType: 'AWS',
    region: 'us-east-2',
    name: 'US East (Ohio)',
    id: 'aws/us-east-2/us-east-2a',
  },
  {
    availabilityZone: 'us-east-2b',
    cloudType: 'AWS',
    region: 'us-east-2',
    name: 'US East (Ohio)',
    id: 'aws/us-east-2/us-east-2b',
  },
  {
    availabilityZone: 'us-east-2c',
    cloudType: 'AWS',
    region: 'us-east-2',
    name: 'US East (Ohio)',
    id: 'aws/us-east-2/us-east-2c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'us-east-2',
    name: 'US East (Ohio)',
    id: 'aws/us-east-2',
  },
  {
    availabilityZone: 'us-east-1a',
    cloudType: 'AWS',
    region: 'us-east-1',
    name: 'US East (N. Virginia)',
    id: 'aws/us-east-1/us-east-1a',
  },
  {
    availabilityZone: 'us-east-1b',
    cloudType: 'AWS',
    region: 'us-east-1',
    name: 'US East (N. Virginia)',
    id: 'aws/us-east-1/us-east-1b',
  },
  {
    availabilityZone: 'us-east-1c',
    cloudType: 'AWS',
    region: 'us-east-1',
    name: 'US East (N. Virginia)',
    id: 'aws/us-east-1/us-east-1c',
  },
  {
    availabilityZone: 'us-east-1d',
    cloudType: 'AWS',
    region: 'us-east-1',
    name: 'US East (N. Virginia)',
    id: 'aws/us-east-1/us-east-1d',
  },
  {
    availabilityZone: 'us-east-1e',
    cloudType: 'AWS',
    region: 'us-east-1',
    name: 'US East (N. Virginia)',
    id: 'aws/us-east-1/us-east-1e',
  },
  {
    availabilityZone: 'us-east-1f',
    cloudType: 'AWS',
    region: 'us-east-1',
    name: 'US East (N. Virginia)',
    id: 'aws/us-east-1/us-east-1f',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'us-east-1',
    name: 'US East (N. Virginia)',
    id: 'aws/us-east-1',
  },
  {
    availabilityZone: 'us-west-1a',
    cloudType: 'AWS',
    region: 'us-west-1',
    name: 'US West (N. California)',
    id: 'aws/us-west-1/us-west-1a',
  },
  {
    availabilityZone: 'us-west-1c',
    cloudType: 'AWS',
    region: 'us-west-1',
    name: 'US West (N. California)',
    id: 'aws/us-west-1/us-west-1c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'us-west-1',
    name: 'US West (N. California)',
    id: 'aws/us-west-1',
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    region: 'westUS2',
    name: 'West US 2 (Americas)',
    id: 'azure/westUS2',
  },
  {
    availabilityZone: 'us-west-2a',
    cloudType: 'AWS',
    region: 'us-west-2',
    name: 'US West (Oregon)',
    id: 'aws/us-west-2/us-west-2a',
  },
  {
    availabilityZone: 'us-west-2b',
    cloudType: 'AWS',
    region: 'us-west-2',
    name: 'US West (Oregon)',
    id: 'aws/us-west-2/us-west-2b',
  },
  {
    availabilityZone: 'us-west-2c',
    cloudType: 'AWS',
    region: 'us-west-2',
    name: 'US West (Oregon)',
    id: 'aws/us-west-2/us-west-2c',
  },
  {
    availabilityZone: 'us-west-2d',
    cloudType: 'AWS',
    region: 'us-west-2',
    name: 'US West (Oregon)',
    id: 'aws/us-west-2/us-west-2d',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'us-west-2',
    name: 'US West (Oregon)',
    id: 'aws/us-west-2',
  },
  {
    availabilityZone: 'ap-south-1a',
    cloudType: 'AWS',
    region: 'ap-south-1',
    name: 'Asia Pacific (Mumbai)',
    id: 'aws/ap-south-1/ap-south-1a',
  },
  {
    availabilityZone: 'ap-south-1b',
    cloudType: 'AWS',
    region: 'ap-south-1',
    name: 'Asia Pacific (Mumbai)',
    id: 'aws/ap-south-1/ap-south-1b',
  },
  {
    availabilityZone: 'ap-south-1c',
    cloudType: 'AWS',
    region: 'ap-south-1',
    name: 'Asia Pacific (Mumbai)',
    id: 'aws/ap-south-1/ap-south-1c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'ap-south-1',
    name: 'Asia Pacific (Mumbai)',
    id: 'aws/ap-south-1',
  },
  {
    availabilityZone: 'ap-northeast-3a',
    cloudType: 'AWS',
    region: 'ap-northeast-3',
    name: 'Asia Pacific (Osaka)',
    id: 'aws/ap-northeast-3/ap-northeast-3a',
  },
  {
    availabilityZone: 'ap-northeast-3b',
    cloudType: 'AWS',
    region: 'ap-northeast-3',
    name: 'Asia Pacific (Osaka)',
    id: 'aws/ap-northeast-3/ap-northeast-3b',
  },
  {
    availabilityZone: 'ap-northeast-3c',
    cloudType: 'AWS',
    region: 'ap-northeast-3',
    name: 'Asia Pacific (Osaka)',
    id: 'aws/ap-northeast-3/ap-northeast-3c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'ap-northeast-3',
    name: 'Asia Pacific (Osaka)',
    id: 'aws/ap-northeast-3',
  },
  {
    availabilityZone: 'ap-northeast-2a',
    cloudType: 'AWS',
    region: 'ap-northeast-2',
    name: 'Asia Pacific (Seoul)',
    id: 'aws/ap-northeast-2/ap-northeast-2a',
  },
  {
    availabilityZone: 'ap-northeast-2b',
    cloudType: 'AWS',
    region: 'ap-northeast-2',
    name: 'Asia Pacific (Seoul)',
    id: 'aws/ap-northeast-2/ap-northeast-2b',
  },
  {
    availabilityZone: 'ap-northeast-2c',
    cloudType: 'AWS',
    region: 'ap-northeast-2',
    name: 'Asia Pacific (Seoul)',
    id: 'aws/ap-northeast-2/ap-northeast-2c',
  },
  {
    availabilityZone: 'ap-northeast-2d',
    cloudType: 'AWS',
    region: 'ap-northeast-2',
    name: 'Asia Pacific (Seoul)',
    id: 'aws/ap-northeast-2/ap-northeast-2d',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'ap-northeast-2',
    name: 'Asia Pacific (Seoul)',
    id: 'aws/ap-northeast-2',
  },
  {
    availabilityZone: 'ap-southeast-1a',
    cloudType: 'AWS',
    region: 'ap-southeast-1',
    name: 'Asia Pacific (Singapore)',
    id: 'aws/ap-southeast-1/ap-southeast-1a',
  },
  {
    availabilityZone: 'ap-southeast-1b',
    cloudType: 'AWS',
    region: 'ap-southeast-1',
    name: 'Asia Pacific (Singapore)',
    id: 'aws/ap-southeast-1/ap-southeast-1b',
  },
  {
    availabilityZone: 'ap-southeast-1c',
    cloudType: 'AWS',
    region: 'ap-southeast-1',
    name: 'Asia Pacific (Singapore)',
    id: 'aws/ap-southeast-1/ap-southeast-1c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'ap-southeast-1',
    name: 'Asia Pacific (Singapore)',
    id: 'aws/ap-southeast-1',
  },
  {
    availabilityZone: 'ap-southeast-2a',
    cloudType: 'AWS',
    region: 'ap-southeast-2',
    name: 'Asia Pacific (Sydney)',
    id: 'aws/ap-southeast-2/ap-southeast-2a',
  },
  {
    availabilityZone: 'ap-southeast-2b',
    cloudType: 'AWS',
    region: 'ap-southeast-2',
    name: 'Asia Pacific (Sydney)',
    id: 'aws/ap-southeast-2/ap-southeast-2b',
  },
  {
    availabilityZone: 'ap-southeast-2c',
    cloudType: 'AWS',
    region: 'ap-southeast-2',
    name: 'Asia Pacific (Sydney)',
    id: 'aws/ap-southeast-2/ap-southeast-2c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'ap-southeast-2',
    name: 'Asia Pacific (Sydney)',
    id: 'aws/ap-southeast-2',
  },
  {
    availabilityZone: 'ap-northeast-1a',
    cloudType: 'AWS',
    region: 'ap-northeast-1',
    name: 'Asia Pacific (Tokyo)',
    id: 'aws/ap-northeast-1/ap-northeast-1a',
  },
  {
    availabilityZone: 'ap-northeast-1c',
    cloudType: 'AWS',
    region: 'ap-northeast-1',
    name: 'Asia Pacific (Tokyo)',
    id: 'aws/ap-northeast-1/ap-northeast-1c',
  },
  {
    availabilityZone: 'ap-northeast-1d',
    cloudType: 'AWS',
    region: 'ap-northeast-1',
    name: 'Asia Pacific (Tokyo)',
    id: 'aws/ap-northeast-1/ap-northeast-1d',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'ap-northeast-1',
    name: 'Asia Pacific (Tokyo)',
    id: 'aws/ap-northeast-1',
  },
  {
    availabilityZone: 'ca-central-1a',
    cloudType: 'AWS',
    region: 'ca-central-1',
    name: 'Canada (Central)',
    id: 'aws/ca-central-1/ca-central-1a',
  },
  {
    availabilityZone: 'ca-central-1b',
    cloudType: 'AWS',
    region: 'ca-central-1',
    name: 'Canada (Central)',
    id: 'aws/ca-central-1/ca-central-1b',
  },
  {
    availabilityZone: 'ca-central-1d',
    cloudType: 'AWS',
    region: 'ca-central-1',
    name: 'Canada (Central)',
    id: 'aws/ca-central-1/ca-central-1d',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'ca-central-1',
    name: 'Canada (Central)',
    id: 'aws/ca-central-1',
  },
  {
    availabilityZone: 'eu-central-1a',
    cloudType: 'AWS',
    region: 'eu-central-1',
    name: 'Europe (Frankfurt)',
    id: 'aws/eu-central-1/eu-central-1a',
  },
  {
    availabilityZone: 'eu-central-1b',
    cloudType: 'AWS',
    region: 'eu-central-1',
    name: 'Europe (Frankfurt)',
    id: 'aws/eu-central-1/eu-central-1b',
  },
  {
    availabilityZone: 'eu-central-1c',
    cloudType: 'AWS',
    region: 'eu-central-1',
    name: 'Europe (Frankfurt)',
    id: 'aws/eu-central-1/eu-central-1c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'eu-central-1',
    name: 'Europe (Frankfurt)',
    id: 'aws/eu-central-1',
  },
  {
    availabilityZone: 'eu-west-1a',
    cloudType: 'AWS',
    region: 'eu-west-1',
    name: 'Europe (Ireland)',
    id: 'aws/eu-west-1/eu-west-1a',
  },
  {
    availabilityZone: 'eu-west-1b',
    cloudType: 'AWS',
    region: 'eu-west-1',
    name: 'Europe (Ireland)',
    id: 'aws/eu-west-1/eu-west-1b',
  },
  {
    availabilityZone: 'eu-west-1c',
    cloudType: 'AWS',
    region: 'eu-west-1',
    name: 'Europe (Ireland)',
    id: 'aws/eu-west-1/eu-west-1c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'eu-west-1',
    name: 'Europe (Ireland)',
    id: 'aws/eu-west-1',
  },
  {
    availabilityZone: 'eu-west-2a',
    cloudType: 'AWS',
    region: 'eu-west-2',
    name: 'Europe (London)',
    id: 'aws/eu-west-2/eu-west-2a',
  },
  {
    availabilityZone: 'eu-west-2b',
    cloudType: 'AWS',
    region: 'eu-west-2',
    name: 'Europe (London)',
    id: 'aws/eu-west-2/eu-west-2b',
  },
  {
    availabilityZone: 'eu-west-2c',
    cloudType: 'AWS',
    region: 'eu-west-2',
    name: 'Europe (London)',
    id: 'aws/eu-west-2/eu-west-2c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'eu-west-2',
    name: 'Europe (London)',
    id: 'aws/eu-west-2',
  },
  {
    availabilityZone: 'eu-west-3a',
    cloudType: 'AWS',
    region: 'eu-west-3',
    name: 'Europe (Paris)',
    id: 'aws/eu-west-3/eu-west-3a',
  },
  {
    availabilityZone: 'eu-west-3b',
    cloudType: 'AWS',
    region: 'eu-west-3',
    name: 'Europe (Paris)',
    id: 'aws/eu-west-3/eu-west-3b',
  },
  {
    availabilityZone: 'eu-west-3c',
    cloudType: 'AWS',
    region: 'eu-west-3',
    name: 'Europe (Paris)',
    id: 'aws/eu-west-3/eu-west-3c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'eu-west-3',
    name: 'Europe (Paris)',
    id: 'aws/eu-west-3',
  },
  {
    availabilityZone: 'eu-north-1b',
    cloudType: 'AWS',
    region: 'eu-north-1',
    name: 'Europe (Stockholm)',
    id: 'aws/eu-north-1/eu-north-1b',
  },
  {
    availabilityZone: 'eu-north-1c',
    cloudType: 'AWS',
    region: 'eu-north-1',
    name: 'Europe (Stockholm)',
    id: 'aws/eu-north-1/eu-north-1c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'eu-north-1',
    name: 'Europe (Stockholm)',
    id: 'aws/eu-north-1',
  },
  {
    availabilityZone: 'eu-north-1a',
    cloudType: 'AWS',
    region: 'eu-north-1',
    name: 'Europe (Stockholm)',
    id: 'aws/eu-north-1/eu-north-1a',
  },
  {
    availabilityZone: 'sa-east-1a',
    cloudType: 'AWS',
    region: 'sa-east-1',
    name: 'South America (São Paulo)',
    id: 'aws/sa-east-1/sa-east-1a',
  },
  {
    availabilityZone: 'sa-east-1b',
    cloudType: 'AWS',
    region: 'sa-east-1',
    name: 'South America (São Paulo)',
    id: 'aws/sa-east-1/sa-east-1b',
  },
  {
    availabilityZone: 'sa-east-1c',
    cloudType: 'AWS',
    region: 'sa-east-1',
    name: 'South America (São Paulo)',
    id: 'aws/sa-east-1/sa-east-1c',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'sa-east-1',
    name: 'South America (São Paulo)',
    id: 'aws/sa-east-1',
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    region: 'eastUS',
    name: 'East US (Americas)',
    id: 'azure/eastUS',
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    region: 'westUS',
    name: 'West US (Americas)',
    id: 'azure/westUS',
  },
  {
    availabilityZone: null,
    cloudType: 'AWS',
    region: 'af-south-1',
    name: 'Africa (Cape Town)',
    id: 'aws/af-south-1',
  },
  {
    availabilityZone: 'af-south-1a',
    cloudType: 'AWS',
    region: 'af-south-1',
    name: 'Africa (Cape Town)',
    id: 'aws/af-south-1/af-south-1a',
  },
  {
    availabilityZone: 'af-south-1b',
    cloudType: 'AWS',
    region: 'af-south-1',
    name: 'Africa (Cape Town)',
    id: 'aws/af-south-1/af-south-1b',
  },
  {
    availabilityZone: 'af-south-1c',
    cloudType: 'AWS',
    region: 'af-south-1',
    name: 'Africa (Cape Town)',
    id: 'aws/af-south-1/af-south-1c',
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    region: 'centralIndia',
    name: 'Central India (Asia Pacific)',
    id: 'azure/centralIndia',
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    region: 'westEurope',
    name: 'West Europe (Europe)',
    id: 'azure/westEurope',
  },
  {
    availabilityZone: null,
    cloudType: 'AZURE',
    region: 'southCentralUS',
    name: 'South Central US (Americas)',
    id: 'azure/southCentralUS',
  },
];
