/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/3/2021     bakul.banthia         Created
 *
 */
import {
  AccountBalanceOutlined,
  AccountTreeOutlined,
  AdminPanelSettingsRounded,
  BuildRounded,
  CloudOutlined,
  FeedbackOutlined,
  GroupRounded,
  LanOutlined,
  ManageAccountsOutlined,
  PaidOutlined,
  PersonOutline,
  StorageOutlined,
} from '@mui/icons-material';

import {
  AdministrationAppsLabel,
  AppFamilyLabels,
  CustomerAppsLabel,
  InfrastructureAppsLabel,
} from 'Layouts/AppLabels';
import {
  DBServicesIcon,
  InfrastructureIcon,
  PortalAdministrationIcon,
} from 'assets/icons-v2';

export const AllAppsAndFamilies = {
  [AppFamilyLabels.Customers]: {
    [CustomerAppsLabel.Tenants]: {
      title: CustomerAppsLabel.Tenants,
      icon: AccountBalanceOutlined,
      url: '/tenants',
      selectedUrl: '/tenants',
      parentApp: AppFamilyLabels.Customers,
    },
    [CustomerAppsLabel.DatabaseServices]: {
      title: CustomerAppsLabel.DatabaseServices,
      icon: DBServicesIcon,
      url: '/database-services',
      selectedUrl: '/database-services',
      parentApp: AppFamilyLabels.Customers,
    },
    [CustomerAppsLabel.Tickets]: {
      title: CustomerAppsLabel.Tickets,
      icon: FeedbackOutlined,
      url: '/tickets',
      selectedUrl: '/tickets',
      parentApp: AppFamilyLabels.Customers,
    },
    [CustomerAppsLabel.Billing]: {
      title: CustomerAppsLabel.Billing,
      icon: PaidOutlined,
      url: '/billing',
      selectedUrl: '/billing',
      parentApp: AppFamilyLabels.Customers,
    },
  },
  [AppFamilyLabels.Infrastructure]: {
    [InfrastructureAppsLabel.CloudAccounts]: {
      title: InfrastructureAppsLabel.CloudAccounts,
      icon: CloudOutlined,
      url: '/cloud-accounts',
      selectedUrl: '/cloud-accounts',
      parentApp: AppFamilyLabels.Infrastructure,
    },
    [InfrastructureAppsLabel.CloudInfrastructure]: {
      title: InfrastructureAppsLabel.CloudInfrastructure,
      icon: AccountTreeOutlined,
      url: '/cloud-infrastructure',
      selectedUrl: '/cloud-infrastructure',
      parentApp: AppFamilyLabels.Infrastructure,
    },
    [InfrastructureAppsLabel.PlatformServices]: {
      title: InfrastructureAppsLabel.PlatformServices,
      icon: LanOutlined,
      url: '/platform-services',
      selectedUrl: '/platform-services',
      parentApp: AppFamilyLabels.Infrastructure,
    },
  },
  [AppFamilyLabels.Administration]: {
    [AdministrationAppsLabel.IAM]: {
      title: AdministrationAppsLabel.IAM,
      icon: PersonOutline,
      url: '/iam',
      selectedUrl: '/iam',
      parentApp: AppFamilyLabels.Administration,
    },
    [AdministrationAppsLabel.SREAdministration]: {
      title: AdministrationAppsLabel.SREAdministration,
      icon: ManageAccountsOutlined,
      url: '/sre-administration',
      selectedUrl: '/sre-administration',
      parentApp: AppFamilyLabels.Administration,
    },
    [AdministrationAppsLabel.PortalAdministration]: {
      title: AdministrationAppsLabel.PortalAdministration,
      icon: PortalAdministrationIcon,
      url: '/portal-administration',
      selectedUrl: '/portal-administration',
      parentApp: AppFamilyLabels.Administration,
    },
  },
};

export const BasicRDSApps = {
  [AppFamilyLabels.Customers]: [
    AllAppsAndFamilies.Customers[CustomerAppsLabel.Tenants],
    AllAppsAndFamilies.Customers[CustomerAppsLabel.DatabaseServices],
    AllAppsAndFamilies.Customers[CustomerAppsLabel.Tickets],
    AllAppsAndFamilies.Customers[CustomerAppsLabel.Billing],
  ],
  [AppFamilyLabels.Infrastructure]: [
    AllAppsAndFamilies.Infrastructure[InfrastructureAppsLabel.CloudAccounts],
    AllAppsAndFamilies.Infrastructure[
      InfrastructureAppsLabel.CloudInfrastructure
    ],
    AllAppsAndFamilies.Infrastructure[InfrastructureAppsLabel.PlatformServices],
  ],
  [AppFamilyLabels.Administration]: [
    AllAppsAndFamilies.Administration[AdministrationAppsLabel.IAM],
    AllAppsAndFamilies.Administration[
      AdministrationAppsLabel.SREAdministration
    ],
    AllAppsAndFamilies.Administration[
      AdministrationAppsLabel.PortalAdministration
    ],
  ],
};

export const Families = [
  {
    name: AppFamilyLabels.Customers,
    icon: GroupRounded,
  },
  {
    name: AppFamilyLabels.Infrastructure,
    icon: InfrastructureIcon,
  },
  {
    name: AppFamilyLabels.Administration,
    icon: AdminPanelSettingsRounded,
  },
];

export const AllAppsList = [
  AllAppsAndFamilies.Customers[CustomerAppsLabel.Tenants],
  AllAppsAndFamilies.Customers[CustomerAppsLabel.DatabaseServices],
  AllAppsAndFamilies.Customers[CustomerAppsLabel.Tickets],
  AllAppsAndFamilies.Customers[CustomerAppsLabel.Billing],
  AllAppsAndFamilies.Infrastructure[InfrastructureAppsLabel.CloudAccounts],
  AllAppsAndFamilies.Infrastructure[
    InfrastructureAppsLabel.CloudInfrastructure
  ],
  AllAppsAndFamilies.Infrastructure[InfrastructureAppsLabel.PlatformServices],
  AllAppsAndFamilies.Administration[AdministrationAppsLabel.IAM],
  AllAppsAndFamilies.Administration[AdministrationAppsLabel.SREAdministration],
  AllAppsAndFamilies.Administration[
    AdministrationAppsLabel.PortalAdministration
  ],
];
