export const getTopProcessTableColumns = () => [
  {
    field: 'pid',
    headerName: 'ID',
    hide: true,
  },
  {
    field: 'processName',
    headerName: 'Process',
    hide: false,
    styles: {
      width: '320px',
    },
  },
  {
    field: 'lastVal',
    headerName: 'Value',
    hide: false,
  },
];
