import moment from 'moment';
import {
  Stack,
  Typography,
  Button,
  Autocomplete,
  Chip,
  MenuItem,
} from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import _debounce from 'lodash/debounce';
import { CachedRounded } from '@mui/icons-material';
import { DF_HUMAN } from 'helpers/dateFormats';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { AdaptedMetrics } from './utils/fetchMonitoringAdapter';
import { EMPTY_STRING } from 'constants/appConstants';
import { SelectInstance } from './components/SelectInstance';
import { styles, useStyles } from './monitoringCustomStyles';
import {
  MetricToggleButtonOptions,
  MonitoringMetricTogglerType,
  rangeType,
} from './constants';
import { updateLatestTime } from './helpers/updateLatestTime';

export const DbMonitoringChartConfiguration = (props) => {
  const {
    database,
    metricsRes,
    startTime,
    endTime,
    searchText,
    setSearchText,
    metricOption,
    setMetricOption,
    fetchMetrics,
    range,
    setRange,
    updateStartEndTime,
    instance,
    setInstance,
  } = props;
  const { dbMetrics } = metricsRes as AdaptedMetrics;
  const {
    createdOn,
    instances,
    engineType = EMPTY_STRING,
    _id: entityId,
  } = database;

  const classes = useStyles();
  const startMin = new Date(createdOn).getTime();
  const endMax = new Date().getTime();
  const timeVal =
    startTime && endTime
      ? `${moment(startTime).format(DF_HUMAN)} - ${moment(endTime).format(
          DF_HUMAN,
        )}`
      : '';

  const DatePickerToggler = () => {
    const handleOnChange = (e, v) => {
      if (v) {
        setRange(v);
        updateLatestTime(v, updateStartEndTime, true);
      }
    };

    const NativePicker = () => (
      /* @ts-expect-error: Let's ignore a single compiler error like this unreachable code */
      <DateRangePicker
        onCallback={updateTimeUsingNativePicker}
        initialSettings={{
          timePicker: true,
          timePicker24Hour: true,
          timePickerSeconds: true,
          startDate: moment(startTime),
          endDate: moment(endTime),
          minDate: moment(startMin),
          maxDate: moment(endMax),
          opens: 'center',
          maxSpan: {
            days: 7,
          },
        }}
      >
        <Typography sx={{ display: 'flex', fontWeight: 600 }}>
          {timeVal}
        </Typography>
      </DateRangePicker>
    );

    return (
      <ToggleButtonGroup
        value={range}
        exclusive
        color="primary"
        sx={{ ...classes.toggleButton }}
        onChange={handleOnChange}
      >
        {Object.keys(rangeType).map((r) => (
          <ToggleButton
            value={r}
            size="large"
            sx={{
              borderRadius: '4px',
              minWidth: '40px',
              borderColor: styles.color.greyBackground2,
              '&.MuiToggleButton-root': {
                color: `${styles.color.monotoneDarkDark} !important`,
              },
              '&.MuiToggleButton-root.Mui-selected': {
                backgroundColor: `${styles.color.primaryBackgroundLight} !important`,
                color: `${styles.color.darkShade} !important`,
                borderColor: `${styles.color.darkShade} !important`,
                fontWeight: 600,
              },
              ':hover': {
                background: '#E3F3FD !important',
                color: styles.color.darkShade,
              },
            }}
          >
            {r === 'Custom' && range === 'Custom' ? (
              NativePicker()
            ) : (
              <Typography sx={range === r ? { fontWeight: 600 } : {}}>
                {r}
              </Typography>
            )}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  };

  const MetricTypeToggler = () => {
    const handleOnChange = (e, v) => {
      if (v) {
        setMetricOption(v as MonitoringMetricTogglerType);
      }
    };

    const shouldDisableButton = (buttonValue): boolean => {
      const countOfAllMetrics = dbMetrics?.length;
      const countOfOsMetrics = dbMetrics?.filter(
        (chart) => chart?.entityType === 'NODE',
      )?.length;
      const countOfDbMetrics = dbMetrics?.filter(
        (chart) => chart?.entityType === 'DB',
      )?.length;

      switch (buttonValue) {
        case 'ALL':
          return !countOfAllMetrics;
        case 'NODE':
          return !countOfOsMetrics;
        case 'DB':
          return !countOfDbMetrics;
        default:
      }
      return false;
    };

    return (
      <ToggleButtonGroup
        value={metricOption}
        exclusive
        color="primary"
        sx={{
          ...classes.toggleButton,
          height: '36px',
          marginRight: 2,
        }}
        onChange={handleOnChange}
      >
        {Object.keys(MetricToggleButtonOptions).map((r) => (
          <ToggleButton
            disabled={shouldDisableButton(r)}
            value={r}
            size="large"
            sx={{
              borderRadius: '4px',
              minWidth: '100px',
              borderColor: styles.color.greyBackground2,
              '&.MuiToggleButton-root': {
                color: `${styles.color.monotoneDarkDark} !important`,
              },
              '&.MuiToggleButton-root.Mui-selected': {
                backgroundColor: `${styles.color.primaryBackgroundLight} !important`,
                color: `${styles.color.darkShade} !important`,
                borderColor: `${styles.color.darkShade} !important`,
              },
              ':hover': {
                background: '#E3F3FD !important',
                color: styles.color.darkShade,
              },
              '&.Mui-disabled': {
                backgroundColor: `${styles.color.greyBackground} !important`,
                color: `${styles.color.monotoneDarkLight} !important`,
              },
            }}
          >
            <Typography sx={metricOption === r ? { fontWeight: 600 } : {}}>
              {MetricToggleButtonOptions[r]}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  };

  const updateTimeUsingNativePicker = (start, end) => {
    const st = start.valueOf();
    const et = end.valueOf();
    setRange('Custom');
    updateStartEndTime(st, et, true);
  };

  const chartSearchOptions = dbMetrics.map((chart) => {
    const { title = '' } = chart;
    const optionsList = [title];
    return optionsList;
  });

  let searchOptions = [];
  chartSearchOptions?.forEach((options) => {
    searchOptions?.push(...options);
  });
  searchOptions = [...new Set(searchOptions)];

  function SearchField() {
    return (
      <Stack>
        <Autocomplete
          sx={{ marginTop: '-16px', width: '300px' }}
          multiple
          id="tags-filled"
          options={searchOptions || []}
          value={searchText}
          freeSolo
          onChange={(_event, value) => {
            setSearchText(value);
          }}
          size={'small'}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                sx={{
                  background: styles.color.greyBackground,
                  height: '24px',
                }}
                label={
                  <Typography sx={{ fontWeight: 400, fontSize: 12 }}>
                    {option}
                  </Typography>
                }
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionLabel={(option) => option}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option} value={option}>
              <Typography sx={{ fontSize: 14, textOverflow: 'ellipsis' }}>
                {option}
              </Typography>
            </MenuItem>
          )}
          renderInput={(params) => (
            <TessellInput
              {...params}
              placeholder={'Search...'}
              id="AddUsers"
              variant="outlined"
              size="small"
            />
          )}
        />
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        rowGap: '16px',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          alignSelf: 'flex-start',
          alignItems: 'flex-end',
        }}
      >
        {instances?.length > 1 && (
          <Stack sx={{ marginRight: 2 }}>
            <SelectInstance
              instance={instance}
              instances={instances}
              setInstance={setInstance}
            />
          </Stack>
        )}
        <MetricTypeToggler />
        {SearchField()}
      </Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          alignSelf: 'flex-end',
          height: '36px',
        }}
      >
        <Button
          onClick={() => {
            if (startTime && endTime) {
              fetchMetrics();
            }
          }}
          variant="outlined"
          sx={{
            p: 0,
            m: 0,
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${styles.color.greyBackground2}`,
            borderRadius: '4px',
            mr: '12px',
            cursor: 'pointer',
            width: '36px',
            minWidth: '36px',
            ':hover': {
              background: '#E3F3FD !important',
              border: `1px solid ${styles.color.darkShade} !important`,
              color: styles.color.darkShade,
            },
          }}
        >
          <CachedRounded />
        </Button>
        <DatePickerToggler />
      </Stack>
    </Stack>
  );
};
