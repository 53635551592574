import { Chip, Stack } from '@mui/material';

// Accepts object and returns Chip list key value pairs
export const ChipListing = ({ data }) => (
  <Stack
    direction="row"
    flexWrap="wrap"
    alignItems="center"
    spacing={1}
    width="98%"
  >
    {Object.keys(data)?.map((_l) => (
      <Stack pb={1}>
        <Chip label={`${_l}: ${data[_l]}`} />
      </Stack>
    ))}
  </Stack>
);
