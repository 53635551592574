export const adaptConnectionPoolsResponse = (response) => {
  return response?.response?.length
    ? response?.response?.map((_pool) => {
        return {
          id: _pool?.id,
          name: _pool?.name,
          status: _pool?.status,
          dbName: _pool?.connectionPoolInfo?.userView?.postgresqlConfig?.dbName,
          poolMode:
            _pool?.connectionPoolInfo?.userView?.postgresqlConfig?.connPoolMode,
          poolSize:
            _pool?.connectionPoolInfo?.userView?.postgresqlConfig?.connPoolSize,
        };
      })
    : [];
};
