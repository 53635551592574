import { Box, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import TenantsCost from './Tenant/TenantsCost';
import { CostView } from './constants';
import { costLayout, costToggleButtonStyle } from './styles';
import { useCost } from './useCost';
import ServicesCost from './Services/ServicesCost';
import EnvironmentsCost from './Environment/EnvironmentsCost';
import { useMemo } from 'react';
import CostByPlane from './Plane/CostByPlane';

export const CostDashboard = () => {
  const {
    view,
    handleViewChange,
    tenantNames,
    tenantNamesIsLoading,
    servicesNames,
    servicesNamesIsLoading,
    environmentsNames,
    environmentsNamesIsLoading,
    planeNames,
    planeNamesIsLoading,
  } = useCost();
  const ComponentToRender = useMemo(() => {
    switch (view) {
      case CostView.Tenant: {
        return (
          <TenantsCost
            tenantNames={tenantNames}
            tenantNamesIsLoading={tenantNamesIsLoading}
          />
        );
      }
      case CostView.Service: {
        return (
          <ServicesCost
            servicesNames={servicesNames}
            servicesNamesIsLoading={servicesNamesIsLoading}
          />
        );
      }
      case CostView.Plane: {
        return (
          <CostByPlane
            planeNames={planeNames}
            planeNamesIsLoading={planeNamesIsLoading}
          />
        );
      }
      case CostView.Environment: {
        return (
          <EnvironmentsCost
            environmentsNames={environmentsNames}
            environmentsNamesIsLoading={environmentsNamesIsLoading}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  }, [
    view,
    tenantNames,
    tenantNamesIsLoading,
    servicesNames,
    servicesNamesIsLoading,
    environmentsNames,
    environmentsNamesIsLoading,
    planeNames,
    planeNamesIsLoading,
  ]);

  return (
    <Box sx={costLayout.topBox}>
      <Stack sx={costLayout.topLevelContainer}>
        <Stack sx={costLayout.titleContainer}>
          <Stack direction={'row'}>
            <ToggleButtonGroup
              color="standard"
              value={view}
              exclusive
              size="small"
              onChange={handleViewChange}
              sx={costToggleButtonStyle}
            >
              {Object.keys(CostView)?.map((_view) => {
                return (
                  <ToggleButton value={CostView[_view]}>
                    {CostView[_view]}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Stack>
        </Stack>
        <Stack>{ComponentToRender}</Stack>
      </Stack>
    </Box>
  );
};
