import { useCountAndPageCount } from 'common/Hooks/useCountAndPageCount';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { useFetchCloudLocations } from 'common/cloud-region-location/lib/component/useFetchCloudLocations';
import { URLS } from 'constants/URL';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { capitaliseWord } from 'helpers/capitaliseFirstLetterInEachWord';
import { createParams } from 'helpers/createParams';
import { DF_HUMAN } from 'helpers/dateFormats';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useGetSubscriptionIdNameMap } from 'tools/useGetSubscriptionIdNameMap';

type ClonesProps = {
  tenantId: string;
  availabilityMachineId: string;
};

export const useClones = (props: ClonesProps) => {
  const { tenantId, availabilityMachineId } = props;
  const { regionMap } = useFetchCloudLocations();
  const [page, setPage] = useState(1);
  const { subIdNameMap } = useGetSubscriptionIdNameMap({ tenantId });

  const {
    response: clones,
    postData: fetchClones,
    isLoading: clonesIsLoading,
  } = useLazyGetCallWithURL();

  const {
    response: clonesCountRes,
    postData: fetchClonesCount,
    isLoading: clonesCountIsLoading,
  } = useLazyGetCallWithURL();

  const { count: clonesCount, pageCount: clonesPageCount } =
    useCountAndPageCount({
      response: clonesCountRes,
    });

  const clonesParams = useMemo(() => {
    return {
      'availability-machine-id': availabilityMachineId,
      'page-size': ITEMS_PER_PAGE,
      'page-offset': page - 1,
      clone: true,
    };
  }, [page]);

  useEffect(() => {
    fetchClones(
      `${URLS.fetchClonesByAvailabilityMachineId}${createParams(clonesParams)}`,
    );
    fetchClonesCount(`${URLS.fetchClonesCount}${createParams(clonesParams)}`);
  }, [clonesParams]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const clonesList = useMemo(() => {
    return clones?.response?.map((clone) => ({
      id: clone?._id,
      name: clone?.name,
      status: clone?.status,
      cloudAvailability: clone?.cloudAvailability,
      cloud: capitaliseWord(clone?.infrastructureInfo?.userView?.cloud),
      subscription: subIdNameMap[clone?.subscriptionId] || '-',
      region: clone?.infrastructureInfo?.userView?.region,
      availability: clone?.infrastructureInfo?.userView?.availabilityZone,
      computeType: clone?.infrastructureInfo?.userView?.computeType,
      dateCreated: moment(clone?.dateCreated).format(DF_HUMAN),
      cloneInfo: clone?.metadata?.clonedFromMetadataInfo,
    }));
  }, [clones, subIdNameMap]);

  return {
    regionMap,
    clonesList,
    clonesIsLoading,
    clonesCountIsLoading,
    clonesPageCount,
    page,
    handlePaginationChange,
    clonesCount,
  };
};
