import { DURATION_RANGE } from 'customers/db-services-detailed-view/constants/DurationList';
import { useState } from 'react';

export const useGenieRequestModal = () => {
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [expiryInMinutes, setExpiryInMinutes] = useState(
    DURATION_RANGE[0].value,
  );

  const handleInstanceSelection = (_computeId: string) => {
    if (selectedInstances?.includes(_computeId)) {
      setSelectedInstances(
        selectedInstances?.filter((_c) => _c !== _computeId),
      );
    } else {
      setSelectedInstances((prev) => [...prev, _computeId]);
    }
  };

  return {
    selectedInstances,
    expiryInMinutes,
    setExpiryInMinutes,
    handleInstanceSelection,
  };
};
