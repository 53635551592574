import React, { useContext } from 'react';
import { useSetUserLoginInfoContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import Cache from 'common/cache/lib/utils/Cache';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';

export const useLogoutUser = (notify = false) => {
  const navigate = useNavigate();
  const setUserLoginInfo = useSetUserLoginInfoContext();
  const { connection } = useContext(NotificationContext);

  return () => {
    Auth.signOut();
    setUserLoginInfo({});
    connection?.close?.();
    Cache.clear();
    navigate('/login');
    connection?.close?.();
  };
};
