import moment from 'moment';
import { SingleEventType } from '../constants';
import { eventsStyles } from '../styles';
import { Stack, Typography } from '@mui/material';

export const EventTimelineContent = (
  dbEventTimelineContentProps: SingleEventType,
) => {
  const { label, userComment, timestamp, contextInfo } =
    dbEventTimelineContentProps;
  const timeStampTime = moment(timestamp).format('hh:mm A');
  const timeStampDate = moment(timestamp).format('DD MMM YYYY');

  return (
    <Stack sx={eventsStyles.timelineContent} spacing={1}>
      {label && (
        <Typography sx={eventsStyles.timelineContentHeader}>{label}</Typography>
      )}
      {contextInfo &&
        contextInfo.length > 0 &&
        contextInfo.map((item) => (
          <Typography sx={eventsStyles.timelineContentContext}>
            {item}
          </Typography>
        ))}
      {userComment && (
        <Typography sx={eventsStyles.timelineContentBody}>
          {userComment}
        </Typography>
      )}
      {timestamp && (
        <Typography sx={eventsStyles.timelineContentTimestamp}>
          {timeStampDate} at {timeStampTime}
        </Typography>
      )}
    </Stack>
  );
};
