import { useFetch } from './useFetch';
import { useEffect } from 'react';

export const useGetCall = (url: string, responseAdaptFn?: any) => {
  const { postData, ...res } = useFetch(
    url,
    {
      method: 'GET',
    },
    responseAdaptFn,
  );

  useEffect(() => {
    postData({});
  }, [url]);

  const _postData = () => {
    postData({});
  };

  return {
    ...res,
    postData: _postData,
  };
};

export const useLazyGetCall = (url: string, responseAdaptFn?: any) => {
  const res = useFetch(
    url,
    {
      method: 'GET',
    },
    responseAdaptFn,
  );

  const postData = () => {
    res.postData({});
  };

  return {
    ...res,
    postData,
  };
};

export const useLazyGetCallWithURL = (responseAdaptFn?: any) => {
  const res = useFetch(
    '',
    {
      method: 'GET',
    },
    responseAdaptFn,
  );
  const postData = (url) => {
    res.postData({ customUrl: url });
  };
  return {
    ...res,
    postData,
  };
};

export const useLazyPostCall = (url: string, responseAdaptFn?: any) => {
  const res = useFetch(
    url,
    {
      method: 'POST',
    },
    responseAdaptFn,
  );
  const postData = (body) => {
    res.postData({ body });
  };
  return {
    ...res,
    postData,
  };
};

export const useLazyPutCall = (url: string, responseAdaptFn?: any) => {
  const res = useFetch(
    url,
    {
      method: 'PUT',
    },
    responseAdaptFn,
  );
  const postData = (body) => {
    res.postData({ body });
  };
  return {
    ...res,
    postData,
  };
};

export const useLazyPutCallWithURL = (responseAdaptFn?: any) => {
  const res = useFetch(
    '',
    {
      method: 'PUT',
    },
    responseAdaptFn,
  );
  const postData = (url, body) => {
    res.postData({ customUrl: url, body });
  };
  return {
    ...res,
    postData,
  };
};

export const useLazyDeleteCallWithURL = (responseAdaptFn?: any) => {
  const res = useFetch(
    '',
    {
      method: 'DELETE',
    },
    responseAdaptFn,
  );
  const postData = (url) => {
    res.postData({ customUrl: url });
  };
  return {
    ...res,
    postData,
  };
};

export const useLazyDeleteCall = (url: string, responseAdaptFn?: any) => {
  const res = useFetch(
    '',
    {
      method: 'DELETE',
    },
    responseAdaptFn,
  );
  const postData = (body) => {
    res.postData({ customUrl: url, body });
  };
  return {
    ...res,
    postData,
  };
};
