import { styles } from 'assets/scss/style-templates';

export const tenantCardSummaryStyles = {
  headerBackground: {
    backgroundColor: 'rgba(30, 126, 161, 0.133)',
    borderRadius: '4px 4px 0 0',
  },
  accordionBox: {
    boxSizing: 'border-box',
    borderRadius: '4px',
    boxShadow:
      '0px 1px 4px rgba(31, 41, 55, 0.06), 0px 1px 6px rgba(31, 41, 55, 0.1) !important',
    marginBottom: '20px',
  },
  title: {
    fontWeight: 'medium',
    fontSize: 15,
    pt: 2,
  },
  billingStatusContainer: {
    p: 0,
    m: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },
  billingStatusToggle: {
    cursor: 'pointer',
    ml: 1,
    mt: '0 !important',
  },
  labelValueStyles: {
    pt: 1.2,
    pb: 1.6,
    px: 2,
    borderBottom: `1px solid ${styles.color.greyBackground}`,
  },
};
