// @flow
/* eslint-disable react/jsx-props-no-spreading */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/20/2021     bakul.banthia         Created
 *
 */

import React from 'react';
import {
  Box, Tooltip,
} from '@mui/material';
import { styles } from 'assets/scss/style-templates';

const useStyles = () => ({
  iconColor: {
    '--dark-shade': styles.color.darkShade,
    '--light-shade': styles.color.lightShade,
  },
  selectedApp: {
    cursor: 'default',
  },
  unselectedApp: {
    cursor: 'pointer',
  },
  unselectedAppHover: {
    transition: 'background-color 500ms linear',
    cursor: 'pointer',
    '&:hover': {
      background: `${styles.color.darkShade}99`,
    },
  },
  selectedAppBox: {
    border: `2px solid ${styles.color.darkShade}`,
    background: '#FFF',
    transition: 'background-color 200ms linear',
    cursor: 'default',
  },
  selectedAppBoxDarkBg: {
    border: `2px solid ${styles.color.darkShade}`,
    background: styles.color.darkShade,
    transition: 'background-color 200ms linear',
    cursor: 'default',
  },
  unselectedAppBox: {
    transition: 'background-color 200ms linear',
    border: `1px solid ${styles.color.monotoneDarkLight}88`,
    cursor: 'pointer',
    '&:hover': {
      background: `${styles.color.darkShade}44`,
    },
  },
});

// type AppIconPropType = {
//   size: number,
//   icon: element,
//   name: string,
//   tooltip: node,
//   tooltipPlacement: string,
//   sxClass: any,
//   style: any,
//   selected: boolean,
//   borderless: boolean,
//   hideTooltip: boolean,
//   darkBg: boolean,
//   hoverBg: boolean,
//   monotone: boolean,
// };

export default function AppIcon(props) {
  const classes = useStyles();
  const {
    size, selected, darkBg, tooltip, name, tooltipPlacement,
    icon, sxClass, borderless, hideTooltip, style, hoverBg, monotone,
  } = props;
  const dimension = size * 1.5;
  const borderRadius = (size * 5) / 12;

  let renderIcon = icon;

  if (typeof icon === 'function') {
    const Icon = icon;
    renderIcon = <Icon width={`${size}px`} monotone={monotone} />;
  }

  return (
    <Tooltip
      title={tooltip || name}
      arrow
      interactive
      disableHoverListener={!!hideTooltip}
      placement={tooltipPlacement || 'right'}
      sx={{ maxWidth: 1000 }}
    >
      <Box
        {...props}
        display="flex"
        flexDirection="column"
        width={dimension}
        height={dimension}
        id={name.replaceAll(' ', '-') || 'icon1'}
        justifyContent="center"
        alignItems="center"
        p={1}
        style={{
          borderRadius: `${borderRadius}px`,
          // width: size + 'px',
          ...style,
        }}
        sx={{
          ...classes.iconColor,
          ...(!!sxClass && sxClass),
          ...(borderless && selected && classes.selectedApp),
          ...(borderless && !selected && classes.unselectedApp),
          ...(borderless && !selected && hoverBg && classes.unselectedAppHover),
          ...(!borderless && selected && darkBg && classes.selectedAppBoxDarkBg),
          ...(!borderless && selected && !darkBg && classes.selectedAppBox),
          ...(!borderless && !selected && classes.unselectedAppBox),
        }}
      >
        {renderIcon}
      </Box>
    </Tooltip>
  );
}
