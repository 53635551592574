import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { DotIcon } from 'assets/icons-v2';
import { EMPTY_STRING } from 'constants/appConstants';
import { NotificationListProps } from './types';
import { styles } from './styles';

const NotificationList = (props: NotificationListProps) => {
  const { data } = props;
  return (
    <Stack sx={styles.listContainer} role="presentation">
      <List>
        {data.map(({ data, type }) => (
          <>
            <ListItem key={data?.message || EMPTY_STRING} disablePadding>
              <ListItemIcon>
                <DotIcon />
                <Typography sx={styles.entityText}>
                  {type?.toUpperCase() || EMPTY_STRING}
                </Typography>
              </ListItemIcon>
              <ListItemText primary={data?.message || EMPTY_STRING} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </Stack>
  );
};
export default NotificationList;
