import {
  TenantType,
  TenantViewType,
} from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { TenantCard } from './TenantCard';

interface TenantCardsListProps {
  tenants: TenantViewType[];
  handleClickOnTenant: (id: string) => void;
}

export const TenantCardsList = ({
  tenants,
  handleClickOnTenant,
}: TenantCardsListProps) => (
  <>
    {tenants?.map((tenant) => (
      <TenantCard
        tenant={tenant}
        key={tenant?.id}
        handleClickOnTenant={handleClickOnTenant}
      />
    ))}
  </>
);
