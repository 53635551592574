/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Copyright © Tessell Inc, 2021. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import Notification from './Notification';
import isNullOrUndefined from '../TaskProgress/utils/isNullOrUndefined';

// type NotificationPropType = {
//   successMsg: string,
//   errorMsg: string,
//   onClose: (any) => any,
//   onSuccess?: (any) => any,
//   onError?: (any) => any,
//   apiResponse?: any,
// };

export default function APINotification(props) {
  const {
    successMsg,
    errorMsg,
    onSuccess,
    onError,
    apiResponse,
    onClose = () => {},
  } = props;

  const { response, responseStatus, error } = apiResponse;

  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  useEffect(() => {
    if (error || (responseStatus && responseStatus !== 200)) {
      setNotify(true);
      setSeverity('error');
      const errorMessageFromResponse = !isNullOrUndefined(
        response?.message || error,
      )
        ? `Error: ${response?.message || error}`
        : '';
      setMessage(`${errorMsg || errorMessageFromResponse}`);
      onError?.();
    }

    if (responseStatus === 200) {
      setNotify(true);
      setSeverity('success');
      setMessage(successMsg);
      onSuccess();
    }
  }, [response, error, responseStatus]);
  return (
    <Notification
      notify={notify}
      setNotify={setNotify}
      message={message}
      severity={severity}
      onClose={onClose}
    />
  );
}

APINotification.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
  apiResponse: {},
};
