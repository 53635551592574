import { useMemo, useState } from 'react';
import { GenieOptions } from '../constant';

export const useGenieOverview = ({ approveRequest }) => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showDurationModal, setShowDurationModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showForceRevokeModal, setShowForceRevokeModal] = useState(false);
  const [showAddComputesModal, setShowAddComputesModal] = useState(false);
  const [showConnectionDetailsModal, setShowConnectionDetailsModal] =
    useState(false);
  const [selectedGenieInfo, setSelectedGenieInfo] = useState<any>();

  const [openWithdrawConfirmation, setOpenWithdrawConfirmation] =
    useState(false);

  const detailsFields = useMemo(() => {
    if (selectedGenieInfo) {
      return [
        {
          label: 'Duration',
          value: `${selectedGenieInfo?.expiryInMinutes} mins`,
        },
        {
          label: 'Reason for access',
          value: selectedGenieInfo?.description,
        },
      ];
    }
    return [];
  }, [selectedGenieInfo]);

  const handleMenuSelection = (_genieInfo, _option) => {
    setSelectedGenieInfo(_genieInfo);
    if (_option === GenieOptions.EXTEND_DURATION) {
      setShowDurationModal(true);
    } else if (_option === GenieOptions.VIEW_DETAILS) {
      setShowDetailsModal(true);
    } else if (
      _option === GenieOptions.WITHDRAW_ACCESS ||
      _option === GenieOptions.WITHDRAW_REQUEST
    ) {
      setOpenWithdrawConfirmation(true);
    } else if (
      _option === GenieOptions.RETRY ||
      _option === GenieOptions.ENABLE
    ) {
      approveRequest(_genieInfo?.requestId);
    } else if (_option === GenieOptions.FORCE_REVOKE) {
      setShowForceRevokeModal(true);
    } else if (_option === GenieOptions.ADD_COMPUTES) {
      setShowAddComputesModal(true);
    }
  };

  return {
    showRequestModal,
    setShowRequestModal,
    showDurationModal,
    setShowDurationModal,
    showDetailsModal,
    setShowDetailsModal,
    showConnectionDetailsModal,
    setShowConnectionDetailsModal,
    showForceRevokeModal,
    setShowForceRevokeModal,
    showAddComputesModal,
    setShowAddComputesModal,
    selectedGenieInfo,
    setSelectedGenieInfo,
    openWithdrawConfirmation,
    setOpenWithdrawConfirmation,
    detailsFields,
    handleMenuSelection,
  };
};
