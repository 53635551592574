import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};
