export enum StatusPriorityOrder {
  DOWN,
  START_FAILED,
  VM_STOP_FAILED,
  'STOP FAILED',
  DELETION_FAILED,
  CREATION_FAILED,
  FAILED,
  SWITCHOVER_FAILED,
  PATCH_FAILED,
  RESTORE_FAILED,
  CONNECTION_STOP_FAILED,
  READY,
  STARTING,
  STOPPING,
  DEGRADED,
  HEALING,
  REBUILDING,
  // Not much priority
  UP,
  STOPPED,
  DELETING,
  CREATING,
  SWITCHOVER,
  ACTIVE,
  UPDATING,
  DELETED,

  CONNECTION_STOPPED,
  WARM_STOPPED,
  TO_BE_DELETED,
  DELETED_FOR_USER,
  SWITCHING_OVER,
  RESTORING,
  BACKING_UP,
  PATCHING,
}
