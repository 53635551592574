import { useLazyGetCall } from 'common/api/useApiCall';
import { GROUPS_BASE_URL, URLS, USERS_BASE_URL } from 'constants/URL';
import { responseAdapter } from './groupResponseAdapter';
import { useEffect } from 'react';

type GetAllAndGroupUsersProps = {
  group: any;
  open: boolean;
};

export const useGetAllAndGroupUsers = (props: GetAllAndGroupUsersProps) => {
  const { group, open } = props;
  const {
    response: allUsers,
    isLoading: getAllUsersIsLoading,
    postData: getAllUsers,
  } = useLazyGetCall(USERS_BASE_URL, responseAdapter);

  const {
    response: groupUsers,
    isLoading: getPersonaUsersIsLoading,
    postData: getPersonaUsers,
  } = useLazyGetCall(
    `${GROUPS_BASE_URL}/${group?.name}/users`,
    responseAdapter,
  );

  useEffect(() => {
    if (group?.name?.length && open) {
      getAllUsers();
      getPersonaUsers();
    }
  }, [group?.name, open]);

  return {
    allUsers,
    getAllUsersIsLoading,
    getAllUsers,
    groupUsers,
    getPersonaUsersIsLoading,
    getPersonaUsers,
  };
};
