export const ToggleButtonStyles = {
  toggleButtonGroup: { mr: 2, height: 'fit-content' },
  toggleButtonContainer: { minWidth: '100px' },
  toggleButtonIcon: {
    marginRight: '8px',
    fontSize: 'medium',
  },
};

export const TenantListViewStyles = {
  toolBar: {
    flexDirection: 'row',
    pb: 3,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterInnerBox: {
    flexDirection: 'row',
    width: '850px',
  },
  actionButtonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewToggleButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ...ToggleButtonStyles,
  onBoardButton: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 2,
    height: 'fit-content',
    padding: '10px',
  },
  circularLoadingContainer: {
    width: '100%',
    height: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noDataContainer: {
    width: '100%',
    height: '600px',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
