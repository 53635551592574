import { useMemo, useContext } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import moment from 'moment';
import { BYTES_TO_GB } from 'customers/db-services-detailed-view/constants/bytesToGB';
import { useFetchCloudLocations } from 'common/cloud-region-location/lib/component/useFetchCloudLocations';
import UpdateMetadata from 'common/UpdateMetadata/UpdateMetadata';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { DF_HUMAN } from 'helpers/dateFormats';
import { TessellNumberInRectangle } from 'common/custom-components/lib/components/TessellNulberInRectangle';
import { useBackups } from '../useBackup';
import { SNAPSHOT_BACKUP_STATUS_LIST } from '../../utils';

type BackupsChildrenTableProps = {
  availabilityMachineId: string;
  tenantId: string;
  backupIdentifier?: string;
};

export const BackupsChildrenTable = (props: BackupsChildrenTableProps) => {
  const { availabilityMachineId, tenantId, backupIdentifier } = props;
  const { regionMap } = useFetchCloudLocations();

  const {
    selectedBackup,
    setSelectedBackup,
    backupsPageCount,
    backups,
    backupsCount,
    backupsIsLoading,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    page,
    handlePaginationChange,
    fetchBackups,
    filters,
    handleResetFilters,
    handleFilterChange,
  } = useBackups({
    availabilityMachineId: availabilityMachineId,
    tenantId: tenantId,
    backupIdentifier: backupIdentifier,
  });

  const { hasPermission } = useContext(UserPermissionsContext);

  const menuItemsProps = useMemo(
    () =>
      [
        hasPermission(PERMISSIONS.UPDATE_TENANT) && {
          label: 'Update Metadata',
          onClick: (row) => {
            setSelectedBackup(row);
            setUpdateMetadataOpen(true);
          },
        },
      ].filter((a) => a),
    [],
  );

  const backupFilterProps = useMemo(
    () => [
      {
        name: 'Status',
        column: 'status',
        initialVal: filters?.status || 'All',
        options: SNAPSHOT_BACKUP_STATUS_LIST,
        onChange: (value) => handleFilterChange('status', value),
      },
    ],
    [filters],
  );

  const backupColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        headerAlign: 'center',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Backup Name',
        width: 100,
      },
      {
        field: 'backupType',
        headerName: 'Backup Type',
        width: 80,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
        renderCell: (params) => {
          const { row: data } = params;
          return (
            <Stack direction={'row'} spacing={2}>
              <Typography>{data?.status}</Typography>
              <TessellNumberInRectangle number={data?.childrenCount} />
            </Stack>
          );
        },
      },
      {
        field: 'sizeInfo',
        headerName: 'Size (GiB)',
        renderCell: (params) => {
          const { row: data } = params;
          const { sizeInfo, storageTier } = data;
          const { backupLogicalSize, backupPhysicalSize } = sizeInfo;

          return (
            <Stack direction="column">
              <Typography>
                Logical size:{' '}
                {(backupLogicalSize / BYTES_TO_GB)?.toFixed(5) || '-'}
              </Typography>
              <Typography>
                Physical size:{' '}
                {(backupPhysicalSize / BYTES_TO_GB)?.toFixed(5) || '-'}
              </Typography>
              <Typography>storageTier: {storageTier || '-'}</Typography>
            </Stack>
          );
        },
      },
      {
        field: 'cloudLocation',
        headerName: 'Availability',
        renderCell: (params) => {
          const { row: data } = params;
          const { cloudLocation, cloudType } = data;
          const regionText = regionMap[cloudLocation || ''];
          return (
            <Stack direction="column">
              <Typography fontWeight={500}>{cloudType}</Typography>
              <Typography>{regionText || cloudLocation}</Typography>
            </Stack>
          );
        },
      },
      {
        field: 'backupTime',
        headerName: 'Created On',
        renderCell: (params) => {
          const { row: data } = params;
          const backupTime = moment(data?.backupTime).format(DF_HUMAN);
          if (!backupTime) {
            return '';
          }
          return <Box>{backupTime}</Box>;
        },
      },
      {
        field: 'cloudResourceId',
        headerName: 'S3 URL',
        width: 100,
      },
    ],
    [],
  );

  return (
    <Stack sx={{ height: '80vh' }}>
      <DataGrid
        menuItems={menuItemsProps}
        filters={backupFilterProps}
        columns={backupColumns}
        rows={backups}
        getRowId={(row) => row?.id}
        loading={backupsIsLoading}
        styleOverrides={{ hideShadow: true }}
        pageCount={backupsPageCount}
        pageNo={page}
        totalCount={backupsCount}
        handlePaginationChange={handlePaginationChange}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        onResetFilters={handleResetFilters}
      />
      <UpdateMetadata
        open={updateMetadataOpen}
        setOpen={setUpdateMetadataOpen}
        entityType="backups"
        entityName={selectedBackup?.name}
        parentEntityId={availabilityMachineId}
        tenantId={tenantId}
        tenantDomain={selectedBackup?.pgSource}
        entityId={selectedBackup?.id}
        handleSuccess={() => fetchBackups()}
      />
    </Stack>
  );
};
