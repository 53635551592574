import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Button, Chip, Link, Stack, Typography } from '@mui/material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { JIRA_BASE_LINK } from 'constants/appConstants';
import { GetInstanceStatus } from 'customers/db-services-detailed-view/utils';
import { TicketFormModal } from 'customers/ticket/TicketFormModal';
import { UPDATE_TYPE, useAlerts } from './useAlerts';
import { useCallback, useContext, useMemo, useState } from 'react';
import { SilenceAlertsModal } from 'common/Modals/SilenceAlertsModal/SilenceAlertsModal';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { GetServiceNameByCompute } from './GetServicenameByComputeId';
import { ALERT_PRIORITY_LIST, ALERT_STATUS_LIST } from './constants';
import ConfirmationDialog from 'common/Modals/ConfirmationDialog';
import AssigneeModal from './AssigneeModal';
import { AlertsLabelsPopover } from './AlertsLabelsPopover';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import {
  StatusBackgroundColor,
  StatusBorderColor,
  StatusTextColor,
} from 'constants/statusConfigs';
import { TenantPhases } from 'customers/tenants-list-view/constants/tenantFilterConstants';
import { ConfirmationComponent } from './ConfirmationComponent';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import { LoadingButton } from '@mui/lab';

export const Alerts = () => {
  const {
    showCreateTicketModal,
    selectedAlert,
    alerts,
    isLoading,
    createTicketProps,
    dbsInCompute,
    setShowCreateTicketModal,
    createTicket,
    setSelectedAlert,
    setDbsInCompute,
    handleModalClose,
    filters,
    tenants,
    handleFilterChange,
    handleResetFilters,
    page,
    pageCount,
    totalCount,
    handlePaginationChange,
    openConfirmationDialog,
    setOpenConfirmationDialog,
    updateAlertIsLoading,
    onResolveAlert,
    selectedLabels,
    updateSelectedLabels,
    deleteSelectedLabel,
    openAssigneeDialog,
    setOpenAssigneeDialog,
    users,
    handleUpdateAssignee,
    silenceAlertType,
    setSilenceAlertType,
    silenceAlertsOpen,
    setSilenceAlertsOpen,
    resolveAlertType,
    setResolveAlertType,
    updateAssigneeType,
    setUpdateAssigneeType,
    onCloseAssignee,
    onCloseResolveAlert,
    assignToMe,
  } = useAlerts();
  const { hasPermission } = useContext(UserPermissionsContext);

  const alertColumns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'alert',
      headerName: 'Alert',
      width: 100,
      renderCell: (params) => {
        const { row } = params;
        const { summary, title, alertName } = row;
        return (
          <Stack direction="row">
            <Stack direction="column" m={1}>
              <Typography variant="body2" fontWeight={600}>
                {title || alertName}
              </Typography>
              <Typography variant="body2">{summary}</Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'labels',
      headerName: 'Labels',
      width: 300,
      renderCell: (params) => {
        const { row } = params;
        const { labels } = row;
        return (
          <Stack direction="column">
            <Stack direction="column" m={1} mt={2}>
              <Stack
                direction="row"
                flexWrap="wrap"
                spacing={1}
                alignItems="center"
              >
                {Object.keys(labels)?.map(
                  (_l, index) =>
                    index < 2 && (
                      <Stack py={1}>
                        <CopyToClipboard text={labels[_l]}>
                          <Chip
                            label={`${_l}: ${labels[_l]}`}
                            onClick={() => {
                              updateSelectedLabels(_l, labels[_l]);
                            }}
                            clickable
                          />
                        </CopyToClipboard>
                      </Stack>
                    ),
                )}
                {Object.keys(labels)?.length > 2 && (
                  <AlertsLabelsPopover
                    labels={labels}
                    updateSelectedLabels={updateSelectedLabels}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'priority',
      headerName: 'Priority',
      renderCell: (params) => {
        const { row } = params;
        const _priority = capitaliseFirstLetterInEachWord?.(row?.priority);
        return (
          <StatusLabel
            backgroundColor={StatusBackgroundColor[_priority]}
            textColor={StatusTextColor[_priority]}
            border={StatusBorderColor[_priority]}
            text={_priority}
          />
        );
      },
      width: 60,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 40,
      renderCell: (params) => {
        const { row } = params;
        return <GetInstanceStatus status={row?.status} />;
      },
    },
    {
      field: 'startsAt',
      headerName: 'Reported At',
      width: 60,
      renderCell: (params) => {
        const { row } = params;
        const { startsAt, endsAt } = row;
        return (
          <Stack direction="column">
            <Typography variant="body1">{startsAt}</Typography>
          </Stack>
        );
      },
    },
    {
      field: 'jira',
      headerName: 'JIRA Ticket',
      width: 40,
      renderCell: (params) => {
        const { row } = params;
        const { jiraId } = row;
        return (
          <>
            {jiraId ? (
              <Stack>
                <Link href={JIRA_BASE_LINK + jiraId} target="_blank">
                  {jiraId}
                </Link>
              </Stack>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      width: 80,
      renderCell: (params) => {
        const { row } = params;
        const { assignee } = row;
        return assignee ? (
          <Stack direction="column">
            <OverflowTooltip text={assignee} tooltip={assignee} />
          </Stack>
        ) : (
          <Stack>
            <LoadingButton
              type="link"
              href=""
              onClick={() => assignToMe(row)}
              loading={updateAlertIsLoading}
            >
              Assign to me
            </LoadingButton>
          </Stack>
        );
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 80,
      hide: true,
    },
  ];

  const filterProps = useMemo(
    () => [
      {
        name: 'Tenant',
        column: 'tenant',
        initialVal: filters?.['tenant-id'] || [],
        options: tenants,
        onChange: (value) => handleFilterChange('tenant-id', value),
        type: 'multipleSelect',
      },
      {
        name: 'Status',
        column: 'status',
        initialVal: filters?.status || [],
        options: ALERT_STATUS_LIST,
        onChange: (value) => handleFilterChange('status', value),
      },
      {
        name: 'Priority',
        column: 'priority',
        initialVal: filters?.priority || [],
        options: ALERT_PRIORITY_LIST,
        onChange: (value) => handleFilterChange('priority', value),
      },
      {
        name: 'Tenant Phase',
        initialVal: filters?.['tenant-phase'] || [],
        options: [
          { id: 'All', name: 'All' },
          ...(TenantPhases?.map((_phase) => {
            return {
              id: _phase,
              name: _phase,
            };
          }) || []),
        ],
        onChange: (value) => handleFilterChange('tenant-phase', value),
      },
      {
        startDateAndTime: filters?.['start-time'],
        endDateAndTime: filters?.['end-time'],
        onChange: handleFilterChange,
        type: 'dateAndTime',
      },
    ],
    [filters, tenants],
  );

  const searchProps = useMemo(
    () => ({
      name: 'Search',
      initialVal: filters?.['search-query'],
      onChange: (value) => handleFilterChange('search-query', value),
    }),
    [],
  );

  const menuItemsProps = useCallback(
    (_row) => {
      return [
        {
          label: 'Update Assignee',
          onClick: () => {
            setSelectedAlert(_row);
            setUpdateAssigneeType(UPDATE_TYPE.SINGLE);
            setOpenAssigneeDialog(true);
          },
        },
        {
          label: 'Assignee to me',
          onClick: () => {
            assignToMe(_row);
          },
        },
        _row?.status === 'firing' && {
          label: 'Resolve Alert',
          onClick: () => {
            setSelectedAlert(_row);
            setResolveAlertType(UPDATE_TYPE.SINGLE);
            setOpenConfirmationDialog(true);
          },
        },
        {
          label: (
            <Stack>
              <Typography>Create Jira</Typography>
            </Stack>
          ),
          disabled: () => {
            return _row?.jiraId ? true : false;
          },
          onClick: () => {
            setSelectedAlert(_row);
            setShowCreateTicketModal(true);
          },
        },
        hasPermission(PERMISSIONS.ALERTS_SILENCE) && {
          label: 'Silence Alert',
          onClick: () => {
            setSilenceAlertType('row');
            setSelectedAlert(_row);
            setSilenceAlertsOpen(true);
          },
        },
      ].filter(Boolean);
    },
    [alerts],
  );

  const actionProps = useMemo(
    () => [
      {
        label: 'Db service by compute Id',
        onClick: (e) => {
          setDbsInCompute(true);
        },
      },
      {
        label: 'Bulk Resolve',
        onClick: (e) => {
          setResolveAlertType(UPDATE_TYPE.BULK);
          setOpenConfirmationDialog(true);
        },
      },
      {
        label: 'Bulk Assign',
        onClick: (e) => {
          setUpdateAssigneeType(UPDATE_TYPE.BULK);
          setOpenAssigneeDialog(true);
        },
      },
    ],
    [],
  );

  const handleOnSuccess = () => {
    setSilenceAlertsOpen(false);
  };

  return (
    <>
      <Stack sx={{ height: '100vh' }}>
        {Object.keys(selectedLabels)?.length ? (
          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            spacing={1}
            border="1px solid #E6E6E6"
            borderRadius="8px"
            p={1}
          >
            {Object.keys(selectedLabels)?.map((_l) => (
              <Stack py={1}>
                <Chip
                  label={`${_l}: ${selectedLabels[_l]}`}
                  onDelete={() => {
                    deleteSelectedLabel(_l);
                  }}
                />
              </Stack>
            ))}
            {hasPermission(PERMISSIONS.ALERTS_SILENCE) && (
              <Button
                onClick={() => {
                  setSilenceAlertType('labels');
                  setSilenceAlertsOpen(true);
                }}
                variant="contained"
                size="small"
                color="primary"
                sx={{ height: 'max-content' }}
              >
                Silence Alert
              </Button>
            )}
          </Stack>
        ) : (
          ''
        )}
        <DataGrid
          columns={alertColumns.filter((col) => !!col)}
          rows={alerts || []}
          actions={actionProps}
          disableSelectionOnClick
          loading={isLoading}
          components={{
            Toolbar: CustomDataGridToolbar,
            DetailPanelCollapseIcon: ExpandMore,
            DetailPanelExpandIcon: ChevronRight,
          }}
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          componentName="alerts"
          styleOverrides={{ hideShadow: true }}
          menuItems={menuItemsProps}
          search={Object.keys(selectedLabels)?.length ? [] : searchProps}
          filters={Object.keys(selectedLabels)?.length ? [] : filterProps}
          apiFilter
          onResetFilters={handleResetFilters}
          pageCount={pageCount}
          pageNo={page}
          totalCount={totalCount}
          handlePaginationChange={handlePaginationChange}
        />
        <TicketFormModal
          show={showCreateTicketModal}
          onClose={handleModalClose}
          {...createTicketProps}
          onSubmit={createTicket}
          showDueDate={true}
        />
        {silenceAlertsOpen && (
          <SilenceAlertsModal
            open={silenceAlertsOpen}
            setOpen={setSilenceAlertsOpen}
            selectedAlertLabels={
              silenceAlertType === 'row'
                ? selectedAlert?.labels
                : selectedLabels
            }
            hideInputs={true}
            handleOnSuccess={handleOnSuccess}
          />
        )}
        <GetServiceNameByCompute
          setOpen={setDbsInCompute}
          open={dbsInCompute}
        />

        {/*To resolve alert*/}
        {openConfirmationDialog && (
          <ConfirmationDialog
            title="Resolve Alert"
            open={openConfirmationDialog}
            setOpen={setOpenConfirmationDialog}
            handleConfirm={onResolveAlert}
            confirmContent={
              <ConfirmationComponent
                text="Are you sure you want to resolve this alert?"
                filters={
                  resolveAlertType === UPDATE_TYPE.BULK
                    ? { ...filters, ...(selectedLabels || {}) }
                    : null
                }
              />
            }
            isLoading={updateAlertIsLoading}
            isCommentRequired
            onClose={onCloseResolveAlert}
          />
        )}
        {openAssigneeDialog && (
          <AssigneeModal
            open={openAssigneeDialog}
            setOpen={setOpenAssigneeDialog}
            handleConfirm={handleUpdateAssignee}
            isLoading={updateAlertIsLoading}
            users={users}
            filters={
              updateAssigneeType === UPDATE_TYPE.BULK
                ? { ...filters, ...(selectedLabels || {}) }
                : null
            }
            onClose={onCloseAssignee}
          />
        )}
      </Stack>
    </>
  );
};
