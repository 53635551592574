import {
  useLazyDeleteCall,
  useLazyDeleteCallWithURL,
  useLazyGetCall,
  useLazyPostCall,
} from 'common/api/useApiCall';
import { GROUPS_BASE_URL, URLS } from 'constants/URL';
import { useContext, useEffect, useMemo, useState } from 'react';
import { responseAdapter } from '../groupResponseAdapter';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useGetAllAndGroupUsers } from '../useGetAllAndGroupUsers';

type DeleteUsersProps = {
  group: any;
  open: boolean;
  setOpen: any;
};

export const useDeleteUsers = (props: DeleteUsersProps) => {
  const { group, open, setOpen } = props;
  const { addNotification } = useContext(NotificationContext);

  const [usersToDelete, setUsersToDelete] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const {
    allUsers,
    getAllUsersIsLoading,
    groupUsers,
    getPersonaUsersIsLoading,
  } = useGetAllAndGroupUsers({ group, open });

  const usersAvailableToDelete = useMemo(() => {
    return groupUsers?.length ? groupUsers : [];
  }, [groupUsers]);

  const {
    response: removeUserResponse,
    isLoading: removeUserIsLoading,
    postData: removeUserPostData,
  } = useLazyDeleteCall(`${GROUPS_BASE_URL}/${group?.name}/users`);

  const usersForDeleteConfirmation = useMemo(() => {
    return usersToDelete?.map((_username) => {
      return allUsers?.find((user) => {
        return user?.username === _username;
      });
    });
  }, [groupUsers, allUsers, usersToDelete]);

  const removeUsers = () => {
    const payload = {
      groupName: group?.name,
      users: usersToDelete,
    };
    removeUserPostData(JSON.stringify(payload, null, 2));
  };

  const isAnyLoading = useMemo(() => {
    return getPersonaUsersIsLoading || removeUserIsLoading;
  }, [getPersonaUsersIsLoading, removeUserIsLoading]);

  useEffect(() => {
    if (removeUserResponse?.status === 'SUCCESSFUL') {
      addNotification({
        severity: 'success',
        message: `${removeUserResponse?.message}`,
      });
      setUsersToDelete([]);
      onClose();
    } else if (removeUserResponse?.status) {
      addNotification({
        severity: 'error',
        message: removeUserResponse?.message || 'Something went wrong',
      });
    }
  }, [removeUserResponse]);

  const onClose = () => {
    setOpen(false);
  };

  return {
    onClose,
    usersToDelete,
    removeUsers,
    setUsersToDelete,
    usersAvailableToDelete,
    isAnyLoading,
    deleteConfirmation,
    setDeleteConfirmation,
    allUsers,
    usersForDeleteConfirmation,
  };
};
