import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { URLS } from 'constants/URL';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useLazyGetCallWithURL, useLazyPostCall } from 'common/api/useApiCall';
import { OTPRequestType } from 'constants/URLEnums';

const DEFAULT_PAYLOAD = {
  tenant_id: '',
  service_id: '',
  service_instance_id: '',
  alertname: '',
  compute_resource_id: '',
  job: '',
  startsAt: moment(new Date()),
  endsAt: moment(new Date()).add(1, 'hours'),
};

export default function useSilenceAlertsModel({
  selectedAlertLabels,
  handleOnSuccess,
}) {
  const [inputs, setInputs] = useState<any>(DEFAULT_PAYLOAD);
  const [comment, setComment] = useState('');
  const [range, setRange] = useState('1h');

  const [openOTP, setOpenOTP] = useState(false);
  const [step, setStep] = useState(1);
  const [uuid, setUuid] = useState('');
  const disableSilence = selectedAlertLabels
    ? false
    : !inputs?.tenant_id?.length &&
      !inputs?.service_id?.length &&
      !inputs?.alertname?.length &&
      !inputs?.service_instance_id?.length &&
      !inputs?.compute_resource_id &&
      !inputs?.job;

  const {
    response,
    postData: silenceAlert,
    isLoading,
    responseStatus,
  } = useLazyPostCall(URLS.silenceAlertsURL);

  const {
    response: sendOTPRes,
    isLoading: sendOTPIsLoading,
    postData: sendOTP,
  } = useLazyPostCall(`${URLS.otp}purpose=${OTPRequestType.SILENCE_ALERT_OTP}`);

  const { addNotification } = useContext(NotificationContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (selectedAlertLabels) {
      setInputs({
        ...selectedAlertLabels,
        startsAt: moment(new Date()),
        endsAt: moment(new Date()).add(1, 'hours'),
      });
      setRange('1h');
    }
  }, [selectedAlertLabels]);

  const handleTimeChange = (obj) => {
    setInputs((prev) => ({ ...prev, ...obj }));
  };

  useEffect(() => {
    if (sendOTPRes?.uuid) {
      setUuid(sendOTPRes?.uuid);
      setStep(2);
    }
  }, [sendOTPRes]);

  useEffect(() => {
    if (response?.status === 'SUCCESSFUL') {
      addNotification({
        severity: 'success',
        message: `${response?.message}`,
      });
      setInputs(DEFAULT_PAYLOAD);
      setStep(1);
      setOpenOTP(false);
      handleOnSuccess();
    } else if (responseStatus > 200) {
      addNotification({
        severity: 'error',
        message: response?.message || 'Something went wrong',
      });
    }
  }, [response]);

  const createPayload = (inputs) => {
    const matchers = Object.keys(inputs)
      .filter((key) => key !== ('endsAt' as any) || key !== ('startsAt' as any))
      .map((key) =>
        inputs[key]?.length
          ? { name: key, value: inputs[key], isRegex: false, isEqual: true }
          : null,
      )
      .filter(Boolean);
    return {
      matchers,
      comment,
      startsAt: inputs.startsAt,
      endsAt: inputs.endsAt,
    };
  };

  const handleConfirm = () => {
    setOpenOTP(true);
  };

  const otpSubmitHandler = (val) => {
    if (step === 1) {
      setComment(val?.reason);
      const payload = {
        comment: val?.reason,
        jiraId: val?.jiraId,
      };
      sendOTP(JSON.stringify(payload, null));
    } else {
      const payload = {
        otp: val,
        'request-id': uuid,
        silencePayload: createPayload(inputs),
      };
      silenceAlert(JSON.stringify(payload));
    }
  };

  useEffect(() => {
    if (range === 'Custom') {
      return;
    }
    const st = moment(new Date());
    let et;
    switch (range) {
      case '1h':
        et = moment(new Date()).add(1, 'hours');
        break;
      case '3h':
        et = moment(new Date()).add(3, 'hours');
        break;
      case '6h':
        et = moment(new Date()).add(6, 'hours');
        break;
      case '12h':
        et = moment(new Date()).add(12, 'hours');
        break;
      case '24h':
        et = moment(new Date()).add(24, 'hours');
        break;
      default:
    }
    handleTimeChange({ startsAt: st, endsAt: et });
  }, [range]);

  const handleDateToggle = (e, v) => {
    if (v) {
      setRange(v);
    }
  };

  const updateTimeUsingNativePicker = (start, end) => {
    handleTimeChange({ startsAt: start, endsAt: end });
  };

  return {
    inputs,
    isLoading,
    openOTP,
    sendOTPIsLoading,
    step,
    disableSilence,
    handleChange,
    setOpenOTP,
    setStep,
    handleConfirm,
    otpSubmitHandler,
    handleDateToggle,
    updateTimeUsingNativePicker,
    range,
  };
}
