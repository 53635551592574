import { green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { styles } from 'assets/scss/style-templates';

export const useAmStyles = () => {
  const theme = useTheme();
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    engineLogo: {
      width: '100px',
    },
    amCard: {
      width: '70%',
      overflow: 'visible',
      marginTop: theme.spacing(2),
      cursor: 'pointer',
      boxSizing: 'border-box',
      borderRadius: '10px',
      boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25) !important',
      borderLeftColor: styles.color.darkShade,
      borderLeftWidth: '2px',
    },
    policyCard: {
      width: '80%',
      overflow: 'visible',
      marginTop: theme.spacing(2),
      // background: 'rgba(232, 245, 255, 0.3)',
      // border: '0.5px solid rgba(151, 151, 151, 0.25)',
      boxSizing: 'border-box',
      borderRadius: '10px',
      // boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25) !important',
      borderLeftColor: styles.color.darkShade,
      borderLeftWidth: '2px',
    },
    contentTable: {
      borderRadius: '10px',
      border: `1px solid ${styles.color.lighterShade}`,
      boxShadow: 'none',
    },
    contentBox: {
      boxSizing: 'border-box',
      borderRadius: '10px',
      boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25) !important',
      background: 'white',
    },
    button: {
      alignSelf: 'self-start',
    },
    AWS: {
      width: '50px',
    },
    AZURE: {
      width: '90px',
    },
    AWSAdornment: {
      width: '35px',
      mb: -0.5,
    },
    AZUREAdornment: {
      ml: -1,
      width: '50px',
    },
    AWSDisplay: {
      mb: -0.5,
      width: '30px',
    },
    AZUREDisplay: {
      mb: -0.5,
      padding: 1,
      width: '40px',
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttonLinkText: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: '0.7rem',
    },
    captionIcon: {
      fontSize: '0.75rem',
    },
    selectedCloudBox: {
      border: `1px solid ${styles.color.darkShade}`,
      boxShadow: `inset 0 0 10px ${styles.color.darkShade}`,
      transition: 'border 200ms linear, box-shadow 200ms linear',
      cursor: 'default',
    },
    cloudBox: {
      border: `1px solid ${styles.color.darkShade}`,
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'border 200ms linear',
    },
    vInput: {
      marginTop: theme.spacing(2),
    },
    status: {
      color: green['400'],
      paddingLeft: '2px',
    },
    icon: {
      color: styles.color.darkShade,
    },
    textBoxLabel: styles.textBoxLabel,
    statusBox: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '3px',
      paddingBottom: '3px',
      borderRadius: '4px',
    },
  };
};
