import { EMPTY_STRING } from 'constants/appConstants';

export const DeploymentOptions = [
  {
    name: 'Professional',
    value: 'PROFESSIONAL',
  },
  {
    name: 'Business',
    value: 'BUSINESS',
  },
  {
    name: 'VPT',
    value: 'VIRTUAL_PRIVATE_TESSELL',
  },
  {
    name: 'VPT @ Customer',
    value: 'VIRTUAL_PRIVATE_TESSELL_AT_CUSTOMER',
  },
];

export const DeploymentOptionsEnum = {
  Professional: 'PROFESSIONAL',
  Business: 'BUSINESS',
  VPT: 'VIRTUAL_PRIVATE_TESSELL',
  VPTCustomer: 'VIRTUAL_PRIVATE_TESSELL_AT_CUSTOMER',
};

export const SupportPlans = [
  {
    name: 'Standard',
    value: 'standard',
  },
  {
    name: 'Premium',
    value: 'premium',
  },
  {
    name: 'Mission Critical',
    value: 'mission-critical',
  },
];

export const DedicatedCloudTypes = [
  { name: 'AWS', value: 'AWS' },
  { name: 'AZURE', value: 'AZURE' },
  { name: 'GCP', value: 'GCP' },
];

export const CustomerType = [
  {
    name: 'Internal',
    value: 'INTERNAL',
  },
  {
    name: 'External',
    value: 'EXTERNAL',
  },
];

export const enabledCloudsArray = ['AWS', 'AZURE'];

export const defaultDedicatedCloudAccount = {
  cloudAccountId: '',
  cloudType: 'AWS',
};

export const tenantPhases = {
  POC: 'POC',
  LIVE: 'LIVE',
};

export const tenantPhasesOptions = Object?.keys(tenantPhases)?.map((key) => {
  return { name: tenantPhases?.[key], value: tenantPhases?.[key] };
});
