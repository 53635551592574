import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useLazyPutCall } from 'common/api/useApiCall';
import { TICKETS_BASE_URL } from 'constants/URL';
import { useContext, useEffect, useState } from 'react';

export const useUpdateTicket = ({ selectedTicket, onClose, onSuccess }) => {
  const { priority, status, subject, responderId, id } = selectedTicket || {};
  const [updateData, setUpdateData] = useState({} as any);
  const { response, responseStatus, postData } = useLazyPutCall(
    `${TICKETS_BASE_URL}/${id}`,
  );
  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (response?.message || response?.id) {
      setUpdateData({});
      addNotification({
        severity: responseStatus === 200 ? 'success' : 'error',
        message: `${
          response?.message || responseStatus === 200
            ? 'Updated successfully'
            : 'Could not update'
        }`,
      });
      if (responseStatus && responseStatus !== 200) {
        onClose();
      }
      onSuccess();
    }
  }, [response]);

  return {
    priority,
    status,
    subject,
    responderId,
    updateData,
    setUpdateData,
    postData,
  };
};
