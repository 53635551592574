export default function CircularPlayIcon() {

    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_690_2610)">
          <path
            d="M7.99986 15.4287C12.1025 15.4287 15.4284 12.1028 15.4284 8.0001C15.4284 3.89742 12.1025 0.571533 7.99986 0.571533C3.89717 0.571533 0.571289 3.89742 0.571289 8.0001C0.571289 12.1028 3.89717 15.4287 7.99986 15.4287Z"
            stroke="#11567F"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.74512 10.8888C5.74705 11.0012 5.77974 11.1108 5.83963 11.2059C5.89951 11.3009 5.98431 11.3778 6.08479 11.428C6.18514 11.4847 6.29843 11.5145 6.41369 11.5145C6.52894 11.5145 6.64224 11.4847 6.74258 11.428L11.4064 8.54344C11.5079 8.49456 11.5935 8.41802 11.6534 8.32264C11.7133 8.22727 11.7451 8.11691 11.7451 8.00427C11.7451 7.89164 11.7133 7.78128 11.6534 7.6859C11.5935 7.59053 11.5079 7.51399 11.4064 7.46511L6.74258 4.60212C6.64224 4.54542 6.52894 4.51562 6.41369 4.51562C6.29843 4.51562 6.18514 4.54542 6.08479 4.60212C5.98431 4.65236 5.89951 4.72919 5.83963 4.82424C5.77974 4.9193 5.74705 5.02895 5.74512 5.14129V10.8888Z"
            fill="#11567F"
            stroke="#11567F"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_690_2610">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  