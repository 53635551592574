// Hooks
import { makeStyles } from '@mui/styles';

export const computeInstanceStyles = makeStyles((theme: any) => ({
  primaryText: {
    fontWeight: theme?.typography?.fontWeightMedium || 600,
  },
  secondaryText: {
    fontWeight: theme?.typography?.fontWeightRegular || 500,
  },
}));
