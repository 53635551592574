import { styles } from 'assets/scss/style-templates';

export enum TessellGenieTypeEnum {
  Customer = 'CUSTOMER',
  Internal = 'INTERNAL',
}

export enum GenieStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  REQUESTED = 'REQUESTED',
  ENABLING = 'ENABLING',
  DISABLING = 'DISABLING',
  FAILED_ENABLE = 'FAILED_ENABLE',
  FAILED_DISABLE = 'FAILED_DISABLE',
  PARTIAL_ENABLED = 'PARTIAL_ENABLED',
  REQUEST_DECLINED = 'REQUEST_DECLINED',
}

export const GenieOptions = {
  VIEW_DETAILS: 'View details',
  WITHDRAW_ACCESS: 'Withdraw access',
  WITHDRAW_REQUEST: 'Withdraw request',
  EXTEND_DURATION: 'Extend duration',
  ENABLE: 'Enable',
  RETRY: 'Retry',
  FORCE_REVOKE: 'Force revoke',
  ADD_COMPUTES: 'Add computes',
};

export const GenieStatusConfig = {
  [GenieStatus.ENABLED]: {
    text: 'Enabled',
    backgroundColor: '#F0FCF0',
    textColor: styles.color.green,
  },
  [GenieStatus.DISABLED]: {
    text: 'Disabled',
    backgroundColor: styles.color.greyBackground2,
  },
  [GenieStatus.REQUESTED]: {
    text: 'Requested',
    backgroundColor: styles.color.warningBackground,
    textColor: styles.color.warn,
  },
  [GenieStatus.ENABLING]: {
    text: 'Enabling',
    backgroundColor: styles.color.yellow,
  },
  [GenieStatus.DISABLING]: {
    text: 'Disabling',
    backgroundColor: styles.color.yellow,
  },
  [GenieStatus.FAILED_ENABLE]: {
    text: 'Enable Failed',
    backgroundColor: styles.color.warn,
  },
  [GenieStatus.FAILED_DISABLE]: {
    text: 'Disable Failed',
    backgroundColor: styles.color.warn,
  },
  [GenieStatus.PARTIAL_ENABLED]: {
    text: 'Partial Enabled',
    backgroundColor: '#F0FCF0',
    textColor: styles.color.green,
  },
  [GenieStatus.REQUEST_DECLINED]: {
    text: 'Request Declined',
    backgroundColor: styles.color.greyBackground2,
  },
};

export const genieRequestButtonStatuses = [
  GenieStatus.DISABLED,
  GenieStatus.FAILED_DISABLE,
  GenieStatus.REQUEST_DECLINED,
];

export const InternalGenieActionsForStatus = {
  [GenieStatus.ENABLED]: [
    GenieOptions.EXTEND_DURATION,
    GenieOptions.WITHDRAW_ACCESS,
    GenieOptions.VIEW_DETAILS,
    GenieOptions.FORCE_REVOKE,
    GenieOptions.ADD_COMPUTES,
  ],
  [GenieStatus.REQUESTED]: [
    GenieOptions.ENABLE,
    GenieOptions.WITHDRAW_ACCESS,
    GenieOptions.VIEW_DETAILS,
  ],
  [GenieStatus.ENABLING]: [GenieOptions.VIEW_DETAILS],
  [GenieStatus.FAILED_ENABLE]: [
    GenieOptions.RETRY,
    GenieOptions.WITHDRAW_ACCESS,
    GenieOptions.VIEW_DETAILS,
  ],
  [GenieStatus.PARTIAL_ENABLED]: [
    GenieOptions.RETRY,
    GenieOptions.WITHDRAW_ACCESS,
    GenieOptions.EXTEND_DURATION,
    GenieOptions.VIEW_DETAILS,
    GenieOptions.FORCE_REVOKE,
    GenieOptions.ADD_COMPUTES,
  ],
};
