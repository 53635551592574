import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/material';
import { costLayout, dayWiseCostTableStyles } from '../styles';

type DayWiseCostTableProps = {
  keys: string[];
  rows: any[];
  firstColumnTitle: string;
};

const DayWiseCostTable = (props: DayWiseCostTableProps) => {
  const { keys = [], rows = [], firstColumnTitle } = props;

  return (
    <Stack sx={costLayout.dayWiseTable}>
      <TableContainer component={Paper} sx={{ width: 'max-content' }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  [`&.${tableCellClasses.head}`]:
                    dayWiseCostTableStyles.columnHead,
                }}
              >
                {firstColumnTitle}
              </TableCell>
              {keys?.map((_data) => {
                return (
                  <TableCell
                    sx={{
                      [`&.${tableCellClasses.head}`]:
                        dayWiseCostTableStyles.columnHead,
                    }}
                    align="center"
                  >
                    {_data}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row?.name} sx={dayWiseCostTableStyles?.rows}>
                <TableCell component="th" scope="row">
                  {row?.name}
                </TableCell>
                {keys?.map((key) => {
                  return (
                    <TableCell
                      key={row?.[key]}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row?.[key]}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default DayWiseCostTable;
