export enum CostEnums {
  COST_BY_TENANT = 'COST_BY_TENANT',
  COST_BY_SERVICE = 'COST_BY_SERVICE',
  COST_BY_ENV = 'COST_BY_ENV',
  SERVICE_COST_BY_TENANT = 'SERVICE_COST_BY_TENANT',
  CONTROL_DATA_PLANE_COST_BY_TENANT = 'CONTROL_DATA_PLANE_COST_BY_TENANT',
  TENANT_COST_BY_CONTROL_DATA_PLANE = 'TENANT_COST_BY_CONTROL_DATA_PLANE',
  ENV_COST_BY_TENANT = 'ENV_COST_BY_TENANT',
  TENANT_COST_BY_ENV = 'TENANT_COST_BY_ENV',
  COST_BY_CONTROL_DATA_PLANE = 'COST_BY_CONTROL_DATA_PLANE',
}

export enum DBBackupType {
  snapshot = 'snapshotBackup',
  backup = 'dbNativeBackup',
}

export enum OTPRequestType {
  ONBOARD_OTP = 'ONBOARD_OTP',
  FORGOT_PASSWORD_OTP = 'FORGOT_PASSWORD_OTP',
  METADATA_UPDATE_OTP = 'METADATA_UPDATE_OTP',
  BILLING_OTP = 'BILLING_OTP',
  ADD_CREDITS_OTP = 'ADD_CREDITS_OTP',
  SILENCE_ALERT_OTP = 'SILENCE_ALERT_OTP',
  DB_ENGINE_CONFIG_OTP = 'ENGINE_CONFIG_UPDATE_OTP',
}
