/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Stack,
  MenuItem,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  ConnectOptions,
  InstanceRole,
  serviceURLGenerator,
  useIsFeatureEnabled,
} from './utils';
import { CopyClipboardIcon, EditIcon } from 'assets/icons-v2';
import UpdateInProgress from './updateInProgress';
import STATUS from './Status';
import { DEFAULT_UNKNOWN } from 'constants/appConstants';

// type UpdateTagsProps = {
//   visible: boolean,
//   service: any,
//   onClose: (any) => void,
//   pools: any,
// };
export const DATABASE_TYPE = {
  Oracle: 'ORACLE',
  Postgresql: 'POSTGRESQL',
  MySQL: 'MYSQL',
  MsSQL: 'SQLSERVER',
};

export default function ConnectStringGenerator(props) {
  const { service, pools, setOpenModal, MODAL, updateInProgressInfo } = props;
  const [connectTo, setConnectTo] = useState(ConnectOptions.database);
  const [selectedEntity, setSelectedEntity] = useState('');
  const [inputUsername, setInputUsername] = useState('');

  const [inputPassword, setInputPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [selectedHost, setSelectedHost] = useState('');

  const fetchEnabledFeatures = useIsFeatureEnabled();

  const { isMultiAz, isOracleServiceMultiTenant } = useMemo(
    () => fetchEnabledFeatures(service),
    [service],
  );

  const updatingHost = updateInProgressInfo?.dnsPrefix
    ? updateInProgressInfo?.dnsPrefix
    : null;

  const [sslParams, setSslParams] = useState<any>({
    MySqlSslParams: '',
    PostgresSslParams: '',
    OracleSslParams: '',
  });
  const {
    // networkDetails = {},
    databases = [],
    engineType,
    instances = [],
    provisionInfo,
    connectivityInfo,
  } = service;
  const { serviceConnectivity } = provisionInfo;
  const { connectStrings = [DEFAULT_UNKNOWN] } = connectivityInfo.userView;

  const { enableSSL } = serviceConnectivity;
  const activeDatabases = [...databases]?.filter(
    (db) =>
      db?.status !== 'DELETING' &&
      db?.status !== 'CREATING' &&
      db?.status !== 'DELETED',
  );
  const activePools =
    pools?.postgresqlConfig?.filter(
      (pool) =>
        pool?.status !== 'CREATING' &&
        pool?.status !== 'DELETING' &&
        pool?.status !== 'DELETED',
    ) || [];
  const poolPort =
    !!activePools?.length && activePools[0]?.connectionDetails?.port;

  const isOracle = engineType === 'ORACLE';

  const connectDescFromServiceConnectivity =
    connectivityInfo?.userView?.connectStrings?.[0]?.connectDescriptor;

  const hostList = useMemo(() => {
    const endpoints: any[] = [];
    endpoints.push({
      endpoint: connectStrings && connectStrings[0]?.endpoint,
      role: 'Service',
    });

    instances.length > 1 &&
      instances?.forEach((instance) => {
        //* check for instance status -- only push endpoint of instance which is up
        instance?.status === STATUS.Up &&
          endpoints.push({
            endpoint: instance?.connectionInfo?.connectString?.endpoint,
            role: InstanceRole[instance?.role],
          });
      });

    return endpoints;
  }, [service]);

  useEffect(() => {
    const connectDescriptor =
      instances[0]?.connectionInfo?.connectString?.connectDescriptor;
    if (connectDescriptor) {
      switch (engineType) {
        case DATABASE_TYPE.MySQL: {
          const connectDescArr = connectDescriptor
            ?.replace(/ /g, '')
            .split(',')
            .join('(')
            .split('(')
            .join(')')
            .split(')');
          connectDescArr.forEach(
            (el) =>
              el.includes('ssl_') &&
              setSslParams({
                MySqlSslParams: el,
              }),
          );
          break;
        }

        case DATABASE_TYPE.Postgresql: {
          const connectDescArr = connectDescriptor?.split('?');
          connectDescArr.forEach(
            (el) =>
              el.includes('sslmode') &&
              setSslParams({
                PostgresSslParams: el,
              }),
          );
          break;
        }

        case DATABASE_TYPE.Oracle: {
          const connectDescArr = connectDescriptor?.split(
            'SSL_SERVER_CERT_DN = ',
          );
          const sslString = connectDescArr[1]?.split('"')[1];
          setSslParams({
            OracleSslParams: sslString,
          });
          break;
        }

        default:
          break;
      }
    }
  }, [enableSSL]);

  const { servicePort, masterUser } = (connectStrings || [])[0] || {};

  useMemo(() => setInputUsername(masterUser), [masterUser]);
  useMemo(() => setSelectedHost(hostList[0]?.endpoint), [hostList]);

  useEffect(() => {
    setSelectedEntity(
      connectTo === ConnectOptions?.database
        ? activeDatabases[0]?.name
        : activePools[0]?.name,
    );
  }, [connectTo]);

  function ConnectStringContent() {
    const content = [
      {
        label: 'Host',
        renderFn: () =>
          updatingHost !== null ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <UpdatingHost />
            </Stack>
          ) : (
            HostSelect()
          ),
        helperFn: () =>
          updatingHost !== null ? null : (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              {CopyButton(selectedHost)}
            </Stack>
          ),
      },
      {
        label: 'Connect to',
        renderFn: () => ConnectorInput(),
        helperFn: () => {},
      },
      {
        label: connectTo,
        renderFn: () => getDbPools(),
        helperFn: () => CopyButton(selectedEntity),
      },
      {
        label: 'Username',
        renderFn: () => inputUsername,
        helperFn: () => CopyButton(inputUsername),
      },
      // {
      //   label: 'Password',
      //   // renderFn: () => UsernamePasswordInput('Password'),
      //   renderFn: () => {},
      //   helperFn: () => ResetPassword(),
      // },
      {
        label: 'Port',
        renderFn: () =>
          connectTo === ConnectOptions.database ? servicePort : poolPort,
        helperFn: () =>
          CopyButton(
            connectTo === ConnectOptions.database ? servicePort : poolPort,
          ),
      },
      {
        label: 'Service URL',
        renderFn: () => {
          if (!activeDatabases.length && !activePools.length) {
            return (
              <Typography
                sx={{
                  width: '450px',
                  minWidth: '450px',
                  maxWidth: '450px',
                  flexWrap: 'wrap',
                  wordWrap: 'break-word',
                }}
              >
                {' '}
                NA
              </Typography>
            );
          } else {
            return (
              <Typography
                fontWeight="600"
                color="#11567F"
                sx={{
                  width: '450px',
                  minWidth: '450px',
                  maxWidth: '450px',
                  flexWrap: 'wrap',
                  wordWrap: 'break-word',
                }}
              >
                {serviceURLGenerator(
                  connectTo === ConnectOptions.database ? selectedEntity : null,
                  connectTo === ConnectOptions.pools ? selectedEntity : null,
                  inputUsername,
                  inputPassword.length === 0
                    ? '<PASSWORD>'
                    : '*'.repeat(inputPassword.length),
                  selectedHost,
                  engineType,
                  connectTo === ConnectOptions.database
                    ? servicePort
                    : poolPort,
                  sslParams,
                  enableSSL,
                  connectDescFromServiceConnectivity,
                  isOracleServiceMultiTenant,
                )}
              </Typography>
            );
          }
        },
        helperFn: () =>
          CopyButton(
            serviceURLGenerator(
              connectTo === ConnectOptions.database ? selectedEntity : null,
              connectTo === ConnectOptions.pools ? selectedEntity : null,
              inputUsername,
              inputPassword.length === 0 ? '<PASSWORD>' : inputPassword,
              selectedHost,
              engineType,
              connectTo === ConnectOptions.database ? servicePort : poolPort,
              sslParams,
              enableSSL,
              connectDescFromServiceConnectivity,
              isOracleServiceMultiTenant,
            ),
          ),
      },
    ];

    return (
      <>
        <Stack
          border="1px solid #E6E6E6"
          borderRadius="8px"
          sx={{
            overflow: 'hidden',
          }}
          maxWidth="700px"
        >
          {content.map((item, index) => (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                py: '16px',
                px: '16px',
                borderBottom:
                  index < content.length - 1 ? '1px solid #E6E6E6' : '',
                height:
                  item.label === 'Service URL' || item.label === 'Host'
                    ? '100%'
                    : '55px',
                backgroundColor: index === content.length - 1 ? '#F7FBFE' : '',
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignContent="center"
                width="160px"
              >
                <Typography fontSize="16" color="#666666" fontWeight="500">
                  {item?.label}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignContent="center"
                  width="70%"
                >
                  {(item as any)?.renderFn?.()}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignContent="center"
                  width="30%"
                >
                  {/* {item?.helperFn?.()} */}
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </>
    );
  }

  return <>{ConnectStringContent()}</>;

  function getContainerDatabaseNameOracle() {
    const splitConnectDescriptor =
      connectDescFromServiceConnectivity?.split('/');
    const containerDbNameForOracle =
      splitConnectDescriptor[splitConnectDescriptor.length - 1];
    return containerDbNameForOracle;
  }

  function getContainerDatabaseNameForSslOracle() {
    const splitConnectDescriptor =
      connectDescFromServiceConnectivity?.split('SERVICE_NAME = ');
    const containerDbNameForOracle =
      splitConnectDescriptor?.[1]?.split(')')?.[0];
    return containerDbNameForOracle;
  }

  function getDbPools() {
    if (
      (!activeDatabases.length && connectTo === 'Database') ||
      (!activePools.length && connectTo === 'Pools')
    ) {
      return (
        <Typography
          sx={{
            width: '450px',
            minWidth: '450px',
            maxWidth: '450px',
            flexWrap: 'wrap',
            wordWrap: 'break-word',
          }}
        >
          {' '}
          No Database available
        </Typography>
      );
    } else {
      switch (connectTo) {
        case 'Database':
          if (isOracle) {
            return (
              <Typography variant="body1">
                {enableSSL
                  ? getContainerDatabaseNameForSslOracle()
                  : getContainerDatabaseNameOracle()}
              </Typography>
            );
          }
          if (activeDatabases.length === 1) {
            return (
              <Typography variant="body1">
                {activeDatabases[0]?.name}
              </Typography>
            );
          }
          return (
            <TextField
              select
              value={selectedEntity || activeDatabases[0]?.name}
              variant="outlined"
              size="small"
              onChange={(e) =>
                engineType === 'ORACLE'
                  ? e.stopPropagation()
                  : setSelectedEntity(e.target.value)
              }
              fullWidth
            >
              {engineType !== 'ORACLE' &&
                activeDatabases?.map((db) => (
                  <MenuItem key={db?.name} value={db?.name}>
                    {db?.name}
                  </MenuItem>
                ))}
            </TextField>
          );

        case 'Pools':
          if (activePools.length === 1) {
            return (
              <Typography variant="body1">{activePools[0]?.name}</Typography>
            );
          }
          return (
            <TextField
              select
              value={selectedEntity || activePools[0]?.name}
              variant="outlined"
              size="small"
              onChange={(e) =>
                engineType === 'ORACLE'
                  ? e.stopPropagation()
                  : setSelectedEntity(e.target.value)
              }
              fullWidth
            >
              {engineType !== 'ORACLE' &&
                activePools?.map((pool) => (
                  <MenuItem key={pool?.name} value={pool?.name}>
                    {pool?.name}
                  </MenuItem>
                ))}
            </TextField>
          );
        default:
          return null;
      }
    }
  }

  function CopyButton(text) {
    return (
      <CopyToClipboard text={text}>
        <Button
          sx={{
            height: '30px !important',
            width: '30px !important',
            minWidth: '30px !important',
            minHeight: '30px !important',
            borderRadius: '4px !important',
          }}
        >
          <Stack
          // zoom={2}
          >
            <CopyClipboardIcon />
          </Stack>
        </Button>
      </CopyToClipboard>
    );
  }

  function EditHost() {
    return (
      <Button
        onClick={() => setOpenModal(MODAL.updateHost)}
        sx={{
          height: '30px !important',
          width: '30px !important',
          minWidth: '30px !important',
          minHeight: '30px !important',
          borderRadius: '4px !important',
        }}
      >
        <EditIcon darkBg={false} />
      </Button>
    );
  }

  function UpdatingHost() {
    return (
      <UpdateInProgress
        updateInfo={
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Typography fontSize={12}>
              Service Host Name update is in progress
            </Typography>
          </Stack>
        }
      />
    );
  }

  function ConnectorInput() {
    let showSelect;
    if (isOracle) {
      showSelect = false;
    } else {
      activeDatabases.length > 0 && activePools.length > 0
        ? (showSelect = true)
        : (showSelect = false);
    }

    return (
      <>
        {showSelect ? (
          <TextField
            select
            value={connectTo}
            variant="outlined"
            size="small"
            onChange={(e) => {
              engineType === 'ORACLE'
                ? e.stopPropagation()
                : setConnectTo(e.target.value);
            }}
            fullWidth
          >
            {engineType !== 'ORACLE' &&
              Object.keys(ConnectOptions).map((option) => (
                <MenuItem
                  key={ConnectOptions[option]}
                  value={ConnectOptions[option]}
                >
                  {ConnectOptions[option]}
                </MenuItem>
              ))}
          </TextField>
        ) : (
          <Typography variant="body1">{connectTo}</Typography>
        )}
      </>
    );
  }

  function HostSelect() {
    if (hostList?.length === 0) {
      return <Typography variant="body1">No host links found</Typography>;
    }
    if (hostList.length === 1) {
      return <Typography variant="body1">{hostList[0]?.endpoint}</Typography>;
    }
    return (
      <TextField
        select
        value={selectedHost}
        variant="outlined"
        size="small"
        onChange={(e) => {
          setSelectedHost(e.target.value);
        }}
        fullWidth
        sx={{ mt: 0 }}
      >
        {instances &&
          hostList.map((host) => (
            <MenuItem key={host} value={host.endpoint}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Typography fontWeight="400" fontSize="14px" lineHeight="20px">
                  {host.endpoint}
                </Typography>
                <Typography
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="16px"
                  color="#666666"
                >
                  {host.role}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
      </TextField>
    );
  }

  // function UsernamePasswordInput(type) {
  //   const content = [
  //     {
  //       label: 'Username',
  //       value: inputUsername,
  //       setValue: (e) => setInputUsername(e.target.value),
  //     },
  //     {
  //       label: 'Password',
  //       value: inputPassword,
  //       setValue: (e) => setInputPassword(e.target.value),
  //     },
  //   ];

  //   const item = content.find((contentItem) => contentItem.label === type);

  //   return (
  //     <TextField
  //       value={item.value}
  //       placeholder={`Enter your ${item.label.toLowerCase()} ${
  //         item.label === 'Password' ? 'to generate connnection URL' : ''
  //       }`}
  //       variant="outlined"
  //       size="small"
  //       onChange={(e) => item.setValue(e)}
  //       fullWidth
  //       mt={0}
  //       type={!showPassword && item.label === 'Password' ? 'password' : 'text'}
  //       InputProps={
  //         item.label === 'Password' && {
  //           endAdornment: (
  //             <InputAdornment
  //               position="start"
  //               sx={{
  //                 cursor: 'pointer',
  //               }}
  //             >
  //               {showPassword ? (
  //                 <VisibilityOff
  //                   color="primary"
  //                   fontSize="small"
  //                   onClick={() => setShowPassword(false)}
  //                 />
  //               ) : (
  //                 <Visibility
  //                   color="primary"
  //                   fontSize="small"
  //                   onClick={() => setShowPassword(true)}
  //                 />
  //               )}
  //             </InputAdornment>
  //           ),
  //         }
  //       }
  //     />
  //   );
  // }

  function ResetPassword() {
    return (
      <Button
        variant="text"
        onClick={() => setOpenModal(MODAL?.password)}
        sx={{
          left: '15px',
        }}
      >
        Reset
      </Button>
    );
  }
}
