import { styles } from 'assets/scss/style-templates';

export const useStyles = () => {
  return {
    filesDisplayBox: {
      background: styles.color.greyBackground,
      height: '100%',
      minWidth: 250,
    },
    logsTab: {
      paddingLeft: '16px',
      paddingTop: '0px',
      paddingBottom: '0px',
      '&.Mui-selected': {
        backgroundColor: styles.color.greyBackground,
      },
    },
    liveLogs: {
      color: styles.color.green,
    },
    testss: {
      input: {
        padding: '6px 8px 6px 32px',
      },
    },
  };
};
