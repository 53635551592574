import { handleNullCasesInUpdateMetadataChain } from './constants';

export const getObjectValue = (
  data: any,
  updateListKeys: string[],
  returnType: 'stringified' | 'object' = 'stringified',
) => {
  // This function accepts an object and a list of nested keys
  // eg. data = { name: {fName: 'Tessell',} & updateListKeys = [name, fName]
  // returns {fName: Tessell}
  let localData = { ...data };
  for (let i = 0; i < updateListKeys?.length; i++) {
    localData = localData?.[updateListKeys[i]];
  }
  if (returnType === 'object') {
    return localData;
  }

  // In case of stringified return type, if it is an object, we need to stringify it

  return typeof localData === 'object'
    ? JSON.stringify(localData, null, 2)
    : localData;
};
export const getObjectFromString = (data: string) => {
  try {
    if (data === 'null' || data === null) {
      return null;
    }
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

export const replaceEmptyStringWithNull = (object) => {
  Object.keys(object || {})?.forEach((_k) => {
    if (typeof object[_k] === 'object') {
      replaceEmptyStringWithNull?.(object?.[_k] || {});
    }
    if (typeof object[_k] === 'string') {
      object[_k] = object?.[_k]?.trim();
      if (object?.[_k] === '') {
        object[_k] = null;
      }
    }
  });
  return object;
};

export const clearEmptyValuesFromObject = (object) => {
  Object.keys(object)?.forEach((key) => {
    if (typeof object[key] === 'object') {
      clearEmptyValuesFromObject(object[key]);
    }
    if (object[key] === '' || object[key] === undefined) {
      delete object[key];
    }
  });
  return object;
};

export const findAndReplaceInNestedObject = (
  obj: object,
  keyName: string,
  replacer: any,
) => {
  for (const key in obj) {
    if (
      obj[key] === null &&
      Object.keys(handleNullCasesInUpdateMetadataChain).includes(key)
    ) {
      obj[key] = handleNullCasesInUpdateMetadataChain[key];
    }
    if (key === keyName) {
      obj[key] = replacer;
    } else if (Array.isArray(obj[key])) {
      (obj[key] as any[]).forEach((member) =>
        findAndReplaceInNestedObject(member, keyName, replacer),
      );
    } else if (typeof obj[key] === 'object') {
      findAndReplaceInNestedObject(obj[key], keyName, replacer);
    }
  }
  return obj;
};
