import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { TenantViewType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { LabelStrings } from 'customers/tenants-list-view/lib/LabelStrings';
import { HealthStatus } from 'customers/tenants-list-view/lib/components/HealthStatus';

type TenantsTableViewProps = {
  tenants: TenantViewType[];
  isLoading: Boolean;
  handleClickOnTenant: (id: string) => void;
};

export const TenantsTableView = ({
  tenants,
  isLoading,
  handleClickOnTenant,
}: TenantsTableViewProps) => {
  const tenantColumns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
      hide: true,
    },
    {
      field: 'tenantDomain',
      headerName: LabelStrings?.name,
      minWidth: 140,
      width: 140,
      editable: true,
      renderCell: (params) => {
        const { row } = params;
        return (
          <Stack direction="row">
            <HealthStatus health={row?.health || {}} />{' '}
            <Stack sx={{ width: `calc(100% - 40px)` }}>
              <Typography fontSize="18px" variant="h6">
                {row?.tenantDomain}
              </Typography>
              <Typography fontSize="16px" variant="body2">
                {row?.domainUrl}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'owner',
      headerName: LabelStrings?.owner,
      minWidth: 120,
      width: 200,
      editable: true,
      renderCell: (params) => {
        const { row } = params;
        return (
          <Stack>
            <Typography fontWeight={700}>{row?.owner}</Typography>
            <Typography fontWeight={300}>{row?.contactEmail}</Typography>
          </Stack>
        );
      },
    },
    {
      field: 'company',
      headerName: LabelStrings?.company,
      minWidth: 80,
    },
    {
      field: 'country',
      headerName: LabelStrings?.geoLocation,
      minWidth: 40,
      width: 60,
    },
    {
      field: 'deploymentOption',
      headerName: LabelStrings?.deploymentOption,
      minWidth: 120,
    },

    {
      field: 'subscriptionsForTenant',
      headerName: LabelStrings?.subscriptionsForTenant,
      minWidth: 80,
      width: 90,
    },
  ];

  return (
    <>
      <Stack sx={{ height: '75vh' }}>
        <DataGrid
          components={{
            Toolbar: CustomDataGridToolbar,
            DetailPanelCollapseIcon: ExpandMore,
            DetailPanelExpandIcon: ChevronRight,
          }}
          loading={isLoading}
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          columns={tenantColumns.filter((col) => !!col)}
          rows={tenants || []}
          hidePagination={true}
          onRowClick={(params) => {
            handleClickOnTenant(params?.row?.id);
          }}
        />
      </Stack>
    </>
  );
};
