export const STATUS_LIST = [
  'ALL',
  'RUNNING',
  'CREATING',
  'DELETING',
  'DELETED',
  'STOPPED',
  'UPGRADING',
  'DELETION_FAILED',
].map((status) => ({ id: status, name: status }));
