import { useEffect, useMemo, useState } from 'react';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { useFetchCloudLocations } from 'common/cloud-region-location/lib/component/useFetchCloudLocations';
import { useNavigate } from 'react-router-dom';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { useCountAndPageCount } from 'common/Hooks/useCountAndPageCount';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { TENANTS_BASE_URL, URLS } from 'constants/URL';
import { createParams } from 'helpers/createParams';
import { adaptVirtualNetworksResponse } from './adaptVirtualNetworksReponse';

type VirtualNetworksTableProps = {
  tenant: TenantType;
};
export const useVirtualNetworkTable = (props: VirtualNetworksTableProps) => {
  const { tenant } = props;
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [cloudAccountID, setCloudAccountID] = useState('');

  const subIdNameMap = useMemo(() => {
    const _subIdNameMap = {};
    tenant?.subscriptions?.forEach(
      (_subscription) =>
        (_subIdNameMap[_subscription?._id] = _subscription?.name),
    );
    return _subIdNameMap;
  }, [tenant]);
  const { regionMap } = useFetchCloudLocations();
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);
  const [selectedVn, setSelectedVn] = useState(null);

  const {
    response: vpcs,
    isLoading: vpcsIsLoading,
    postData: fetchVPCs,
  } = useLazyGetCallWithURL(adaptVirtualNetworksResponse);

  const {
    response: vpcsCountResponse,
    isLoading: vpcsCountIsLoading,
    postData: fetchVPCsCount,
  } = useLazyGetCallWithURL();

  const { count: vpcsCount, pageCount: vpcsPageCount } = useCountAndPageCount({
    response: vpcsCountResponse,
  });

  const vpcParams = useMemo(() => {
    return {
      'tenant-id': tenant?._id,
      'page-size': ITEMS_PER_PAGE,
      'page-offset': page - 1,
    };
  }, [page]);

  useEffect(() => {
    if (tenant?._id) {
      fetchVPCs(
        `${TENANTS_BASE_URL}/${tenant?._id}/vpcs?${createParams(vpcParams)}`,
      );
      fetchVPCsCount(
        `${TENANTS_BASE_URL}/${tenant?._id}/vpcs/count?${createParams(
          vpcParams,
        )}`,
      );
    }
  }, [vpcParams]);

  const filteredList = useMemo(() => {
    return (
      vpcs?.map((network) => ({
        ...network,
        id: network?._id,
        cloudAccountId: tenant?.subscriptions?.find(
          (_sub) => _sub?._id === network?.subscriptionId,
        )?.infraInfo?.associatedClouds[network?.cloudType?.toLowerCase()]?.[0]
          ?.cloudAccountId,
      })) || []
    );
  }, [vpcs]);

  const handleRowClick = (rowData) => {
    const { row } = rowData;
    const url = `/tenants/details/${tenant?._id}/virtual-network/${row._id}/subnets`;
    navigate(url, {
      state: { vpc: row },
    });
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return {
    page,
    open,
    setOpen,
    cloudAccountID,
    setCloudAccountID,
    subIdNameMap,
    filteredList,
    regionMap,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    selectedVn,
    setSelectedVn,
    handleRowClick,
    vpcsIsLoading,
    vpcsCountIsLoading,
    vpcsCount,
    vpcsPageCount,
    handlePaginationChange,
  };
};
