/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function StorageIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1V20.5C1 20.7761 1.22386 21 1.5 21H21.5C21.7761 21 22 20.7761 22 20.5V1" stroke="var(--icon-color)" />
      <path d="M1.5 7.5C1.22386 7.5 1 7.72386 1 8C1 8.27614 1.22386 8.5 1.5 8.5L1.5 7.5ZM14.5 8.5C14.7761 8.5 15 8.27614 15 8C15 7.72386 14.7761 7.5 14.5 7.5V8.5ZM1.5 8.5L14.5 8.5V7.5L1.5 7.5L1.5 8.5Z" fill="var(--icon-color)" />
      <path d="M1.5 14.5C1.22386 14.5 1 14.7239 1 15C1 15.2761 1.22386 15.5 1.5 15.5L1.5 14.5ZM14.5 15.5C14.7761 15.5 15 15.2761 15 15C15 14.7239 14.7761 14.5 14.5 14.5V15.5ZM1.5 15.5L14.5 15.5V14.5L1.5 14.5L1.5 15.5Z" fill="var(--icon-color)" />
      <circle cx="4.75" cy="18.25" r="1.25" fill="var(--icon-color)" />
      <circle cx="4.75" cy="11.25" r="1.25" fill="var(--icon-color)" />
      <circle cx="4.75" cy="4.25" r="1.25" fill="var(--icon-color)" />
      <path d="M1 1H22" stroke="var(--icon-color)" strokeLinecap="round" />
    </svg>
  );
}
