import { useNavigate, useParams } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { useGetCall, useLazyGetCallWithURL } from 'common/api/useApiCall';
import { TENANTS_BASE_URL, URLS } from 'constants/URL';
import { useEffect, useState } from 'react';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';

export const useVNDetailedView = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { vnId, tenantId } = params;
  const [tenant, setTenant] = useState<TenantType>({} as TenantType);

  const { response: tenantResponse } = useGetCall(
    `${TENANTS_BASE_URL}/${tenantId}`,
  );

  const { postData: fetchVNDetails, response: vnDetails } =
    useLazyGetCallWithURL();

  useEffect(() => {
    if (tenantResponse?.response?.length) {
      setTenant(tenantResponse?.response[0]);
      fetchVNDetails(
        `${URLS.fetchVPCDetailsURL}tenant-domain=${tenantResponse?.response[0]?.pgSource}&tenant-id=${tenantId}&vpcId=${vnId}`,
      );
    }
  }, [tenantResponse]);

  const handleBackClick = (e) => {
    e?.preventDefault();
    navigate(-1);
  };

  return { tenant, vnDetails, handleBackClick };
};
