//import Scss
import TenantContainer from 'common/TenantContainer/TenantContainer';
import './assets/scss/themes.scss';

//imoprt AppRoutes
import AppRoutes from './Routes/AppRoutes';

import { UserLoginInfo } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import NotificationContainer from 'common/NotificationContainer/NotificationContainer';
import { CommonData } from 'common/Contexts/CommonData';

function App(): JSX.Element {
  return (
    <NotificationContainer>
      <UserLoginInfo>
        <CommonData>
          <TenantContainer>
            <AppRoutes />
          </TenantContainer>
        </CommonData>
      </UserLoginInfo>
    </NotificationContainer>
  );
}

export default App;
