import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import {
  ShowLabelValue,
  ShowLabelValueProps,
} from 'common/custom-components/lib/components/ShowLabelValue';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { useTenantInfraDetails } from './useTenantInfraDetails';
import { infraDetailsCardStyles } from './styles';

interface TenantInfraDetailsProps {
  tenant: TenantType;
}

export default function TenantInfraDetails(props: TenantInfraDetailsProps) {
  const { tenant } = props;
  const { infraInfo, eksCloudResourceUrl, domain, ec2InstanceTailscaleIp } =
    useTenantInfraDetails({ tenant });
  const cardProps: ShowLabelValueProps[] = [
    {
      label: 'Namespace',
      value: infraInfo?.eksNamespace || '',
    },
    {
      label: 'Cloud Resource URL',
      value: eksCloudResourceUrl || '-',
      linkName: eksCloudResourceUrl || '-',
    },
    {
      label: 'Username',
      value: `for_tenant-${domain}_tessell `,
    },
    {
      label: 'Database Name',
      value: `tenant-${domain}_tessell `,
    },
    {
      label: 'IP Address(Tailscale)',
      value: ec2InstanceTailscaleIp || '-',
    },
  ];

  return (
    <Card>
      <CardHeader
        title={
          <Typography sx={infraDetailsCardStyles.title}>
            Tenant Infrastructure Details
          </Typography>
        }
        style={infraDetailsCardStyles.cardHeader}
      />
      <CardContent>
        <Stack spacing={2} sx={infraDetailsCardStyles.card}>
          <Stack>
            <Typography sx={infraDetailsCardStyles.subHeading}>
              Kubernetes Cluster
            </Typography>
            <Stack spacing={1}>
              {cardProps?.map((item, index) => {
                if (index <= 1) {
                  return (
                    <ShowLabelValue
                      label={item?.label}
                      value={item?.value}
                      linkName={item?.linkName}
                    />
                  );
                }
                return <></>;
              })}
            </Stack>
          </Stack>
          <Stack>
            <Typography sx={infraDetailsCardStyles.subHeading}>
              Metadata Repository
            </Typography>
            <Stack spacing={1}>
              {cardProps?.map((item, index) => {
                if (index > 1) {
                  return (
                    <ShowLabelValue
                      label={item?.label}
                      value={item?.value}
                      linkName={item?.linkName}
                    />
                  );
                }
                return <></>;
              })}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
