import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { multiFactorAuth } from './multiFactorAuth';
import { useNavigate } from 'react-router-dom';
import {
  useSetUserLoginInfoContext,
  useUserLoginInfoContext,
} from './UserLoginInfo/UserLoginInfoContext';
import LoginContainer from 'common/custom-components/LoginContainer';
import { LoadingButton } from '@mui/lab';

export const MultiFactorAuthentication = () => {
  const [otp, setOtp] = useState('');

  const loginInfo = useUserLoginInfoContext();
  const setLoginInfo = useSetUserLoginInfoContext();
  const [verifyLoading, setVerifyLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = () =>
    multiFactorAuth(loginInfo, otp, setLoginInfo, navigate, setVerifyLoading);

  useEffect(() => {
    if (Object.keys(loginInfo)?.length !== 0) {
      if (loginInfo?.signInUserSession?.accessToken?.jwtToken) {
        navigate('/dashboard');
      }
    } else {
      navigate('/login');
    }
  }, [loginInfo]);

  return (
    <div className="auth-page-content">
      <LoginContainer>
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: '24px',
          }}
        >
          <TessellInput
            label="Enter OTP (Authenticator app)"
            value={otp}
            variant="outlined"
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                onSubmit();
                ev.preventDefault();
              }
            }}
            onChange={(e) => setOtp(e.target.value)}
            sx={{
              width: '300px',
              mb: '20px',
            }}
          />
          <LoadingButton
            sx={{ minWidth: '100px', borderRadius: '10px' }}
            variant="contained"
            onClick={onSubmit}
            loading={verifyLoading}
            disabled={!otp.length}
          >
            Verify
          </LoadingButton>
        </Stack>
      </LoginContainer>
    </div>
  );
};
