import { useState, useEffect } from 'react';

import { AccountCircle, ExitToApp } from '@mui/icons-material';
import { useLogoutUser } from 'helpers/logout';
import { useUserLoginInfoContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';

const ProfileDropdown = () => {
  const [userName, setUserName] = useState('Admin');
  const [userMenuEl, setUserMenuEl] = useState(null);
  const userMenuId = 'user-menu';

  const loginInfo = useUserLoginInfoContext();

  useEffect(() => {
    if (loginInfo) {
      setUserName(loginInfo?.attributes?.name || 'Admin');
    }
  }, [loginInfo]);

  const logoutUser = useLogoutUser();

  const handleUserMenu = (e?) => {
    setUserMenuEl(e ? e.currentTarget : null);
  };

  const userMenu = (
    <Menu
      anchorEl={userMenuEl}
      id={userMenuId}
      keepMounted
      open={Boolean(userMenuEl)}
      onClose={() => handleUserMenu()}
      onClick={() => handleUserMenu()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Stack sx={{ cursor: 'default', ...styles.textBoxLabel }}>
        <Typography variant="h6" m={2}>
          Welcome {userName}!{' '}
        </Typography>
      </Stack>
      <MenuItem
        onClick={() => {
          logoutUser();
        }}
      >
        <ExitToApp />
        <Typography variant="body1" ml={1}>
          {' '}
          Logout
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <IconButton
        aria-label="Account"
        aria-controls={userMenuId}
        aria-haspopup="true"
        color="inherit"
        onClick={handleUserMenu}
        size="large"
      >
        <AccountCircle sx={{ width: '30px', color: '#FFF' }} fontSize="large" />
      </IconButton>
      {userMenu}
    </>
  );
};

export default ProfileDropdown;
