import moment from 'moment';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getDefaultStartEndTime } from '../helpers/getDefaultStartEndTime';
import {
  MetricToggleButtonOptions,
  MonitoringMetricTogglerType,
  rangeType,
} from '../constants';

import { useFetchMetrics } from '../useFetchMetrics';
import _debounce from 'lodash/debounce';
import { getFilteredMonitoringMetrics } from '../getFilteredMonitoringMetrics';
import { updateLatestTime } from '../helpers/updateLatestTime';

const getInstanceById = (instances: Array<any>, instanceId: string): any =>
  instances?.find((instance) => instance._id === instanceId);

export const useMonitoring = (props: { database }) => {
  const { database } = props;
  const { instances, _id: entityId, pgSource, tenantId } = database;
  const primaryNode = instances?.filter(
    (i) => i?.role?.toLowerCase() === 'primary',
  );
  const { _id: primaryNodeInstanceId } =
    (primaryNode?.length && primaryNode[0]) || '';
  const rangeMax = moment().subtract(moment().seconds() % 10, 's');

  const [step, setStep] = useState(30);

  const [range, setRange] = useState(Object.keys(rangeType)?.[0]);
  const [instance, setInstance] = useState(primaryNodeInstanceId);

  const { initialStartTime, initialEndTime } = getDefaultStartEndTime();
  const initialStartTimestamp = initialStartTime.valueOf();
  const initialEndTimestamp = initialEndTime.valueOf();

  const [startTime, setStartTime] = useState(initialStartTimestamp);
  const [endTime, setEndTime] = useState(initialEndTimestamp);
  const [xMin, setXMin] = useState(initialStartTimestamp);
  const [xMax, setXMax] = useState(initialEndTimestamp);
  const [metricOption, setMetricOption] = useState<MonitoringMetricTogglerType>(
    Object.keys(MetricToggleButtonOptions)[0] as MonitoringMetricTogglerType,
  );
  const [searchText, setSearchText] = useState<Array<string>>([]);
  const [shouldRenderCharts, setShouldRenderCharts] = useState(false);

  const scrollRef = useRef<HTMLDivElement>();

  const {
    response: combinedAdaptedResponse,
    isLoading: combinedIsLoading,
    postData: fetchAllMetrics,
  } = useFetchMetrics({
    serviceInstanceId: instance,
    endTime: endTime,
    startTime: startTime,
    pgSource: pgSource,
    tenantId: tenantId,
    tenantDomain: database?.pgSource,
    entityId: entityId,
    instances: instances,
    instance: instance,
    step: step,
    status: getInstanceById(instances, instance)?.status,
    updateTimeUsingToggleButton: (resetDisplayDuration: boolean) =>
      updateLatestTime(range, updateStartEndTime, resetDisplayDuration),
  });

  const { availability, dbMetrics } = combinedAdaptedResponse;

  useEffect(() => {
    if (startTime && endTime && !combinedIsLoading) {
      fetchAllMetrics();
    }
  }, [instance, startTime, endTime]);

  const updateStartEndTime = (
    st: number,
    et: number,
    resetDisplayDuration: boolean,
    step: number,
  ) => {
    if (resetDisplayDuration) {
      setXMin(st);
      setXMax(et);
    } else {
      setXMin((prev) => prev + 1);
      setXMax((prev) => prev + 1);
    }
    setStartTime(st);
    setEndTime(et);
    if (step) {
      setStep(step);
    }
  };

  const updateTimeUsingChartZoomIn = (chart: any, options?: any) => {
    const xMin = options?.xaxis?.min;
    const xMax = options?.xaxis?.max;

    setXMin(xMin ?? startTime);
    setXMax(xMax ?? endTime);
    setShouldRenderCharts((prev) => !prev);
  };

  const debounceSearchFn = useCallback(
    _debounce(() => setShouldRenderCharts((prev) => !prev), 500),
    [],
  );

  const updateSearchText = (newSearchValue) => {
    setSearchText(newSearchValue);
    debounceSearchFn();
  };

  const getFilteredCharts = useMemo(() => {
    return getFilteredMonitoringMetrics(dbMetrics, metricOption, searchText);
  }, [dbMetrics, metricOption, searchText]);

  const getLatestXRange = () => ({ xMin, xMax });

  useLayoutEffect(() => {
    setShouldRenderCharts((prev) => !prev);
  }, [metricOption]);
  return {
    scrollRef,
    combinedAdaptedResponse,
    startTime,
    endTime,
    searchText,
    updateSearchText,
    metricOption,
    setMetricOption,
    updateStartEndTime,
    fetchAllMetrics,
    range,
    setRange,
    instance,
    setInstance,
    xMin,
    xMax,
    updateTimeUsingChartZoomIn,
    availability,
    getLatestXRange,
    dbMetrics,
    getFilteredCharts,
    combinedIsLoading,
    shouldRenderCharts,
  };
};
