export enum CostView {
  Tenant = 'Tenant',
  Service = 'Service',
  Plane = 'Plane',
  Environment = 'Environment',
}

export enum SubView {
  Table,
  Pie,
}
export const COST_UNKNOWN_STRING = 'Unknown';
export const COST_MAX_RANGE = 30;
export const COST_DEFAULT_DATE_RANGE = '1m';
export const DAY_WISE_COST_TABLE_TITLE = 'Table View';

export const PlaneCostLabels = {
  CP: 'Control Plane',
  DP: 'Data Plane',
  CnDP: 'Control and Data Plane',
};

export const CostRangeType = {
  '1w': '1 week',
  '2w': '2 weeks',
  '3w': '2 weeks',
  '1m': '1 month',
  Custom: 'Custom',
};

export const CostResponseKeys = {
  ENV_NAME: 'envName',
  SERVICE_NAME: 'serviceName',
  TENANT_NAME: 'tenantName',
  TOTAL_COST: 'totalCost',
  SERVICE_TYPE: 'serviceType',
};

export const PlaneNames = {
  CP: 'Control Plane',
  DP: 'Data Plane',
  CnDP: 'Control and Data Plane',
};

export const CostColors = [
  '#4BA6E3',
  '#51AB4F',
  '#E93942',
  '#10485d',
  '#F0913E',
  '#2191ba',
  '#f3a765',
  '#74bc72',
  '#f6bd8b',
  '#94daf4',
  '#8c2228',
  '#54c4ed',
  '#f6b0b3',
  '#ed6168',
  '#31672f',
  '#905725',
  '#196d8b',
  '#c07432',
  '#5d171a',
  '#29B5E8',
  '#ba2e35',
  '#1E7EA1',
  '#f2888e',
  '#7fd3f1',
  '#41893f',
  '#97cd95',
  '#4DAC4A',
];
