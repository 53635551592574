/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay, reset) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay, reset]);
}

export function useIntervalWithDependency(callback, delay, dependencies) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay, ...dependencies]);
}

export function useIntervalWithIntervalId(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick(id) {
      savedCallback.current(id);
    }
    const intervalId = setInterval(() => {
      setTimeout(tick, 0, intervalId);
    }, delay);
    return () => clearInterval(intervalId);
  }, [delay]);
}
