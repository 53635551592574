import { Fragment } from 'react';

import { Divider, Drawer, Typography } from '@mui/material';
import NotificationList from './NotificationList';
import { NotificationDrawerPropsType } from './types';
import { styles } from './styles';
import { useNotificationDrawer } from './useNotificationDrawer';

export default function NotificationDrawer(props: NotificationDrawerPropsType) {
  const { open, data, close } = useNotificationDrawer(props);

  return (
    <Fragment key="right">
      <Drawer anchor="right" open={open} onClose={close}>
        <Typography sx={styles.notificationHeaderText}>
          Notifications
        </Typography>
        <Divider />
        <NotificationList data={data} />
      </Drawer>
    </Fragment>
  );
}
