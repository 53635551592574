import { Stack } from '@mui/material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { useVPCPeerings } from './useVPCPeering';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';

type VPCPeeringsProps = {
  tenant: any;
  vnDetails: any;
};

export default function VPCPeerings({ tenant, vnDetails }: VPCPeeringsProps) {
  const { peerings, columns, isLoading, showPeeringDetails, renderFunctions } =
    useVPCPeerings({ tenant, vnDetails });

  return (
    <Stack sx={{ height: '100vh' }}>
      <DataGrid
        density="comfortable"
        hideFooter
        columns={columns}
        componentName="virtual-networks"
        rows={peerings}
        loading={isLoading}
        styleOverrides={{ hideShadow: true }}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
      <TessellModal
        renderFunctions={renderFunctions}
        open={showPeeringDetails}
      />
    </Stack>
  );
}
