import { TextField, Typography, Stack, Autocomplete } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import { useMemo } from 'react';
import RequiredIndication from './RequiredIndicator';

type TessellAutocompleteOption = {
  label: string;
  value: string | number;
  iconUrl?: string;
};

export const changeHomogenousArrayToAutoCompleteOption = (arr) => {
  return arr.length
    ? arr?.map((item) => {
        return { label: item, value: item };
      })
    : [];
};

const TessellAutocomplete = (props) => {
  const {
    sx,
    showDefaultStyleLabel = false,
    label,
    labelStyles,
    options,
    width = 'auto',
    variant = 'outlined',
    disabled,
    onlyTextLabel = false,
    value,
    onChange,
    id,
    multiple = false,
    markAsRequired,
    ...textFieldProps
  } = props;

  const autocompleteValue = useMemo(() => {
    return multiple
      ? (options || [])?.filter((_option: TessellAutocompleteOption) =>
          (value || []).includes(_option.value),
        ) ?? []
      : (options || [])?.find(
          (_option: TessellAutocompleteOption) => _option.value === value,
        ) ?? null;
  }, [multiple, options, value]);

  const GetComponent = () => {
    return (
      <Autocomplete
        id={id}
        options={options}
        getOptionLabel={(option: TessellAutocompleteOption) => {
          return option?.label || '';
        }}
        renderOption={(props, option: TessellAutocompleteOption) => {
          return (
            <Stack
              component="li"
              direction="row"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option?.iconUrl ? (
                <img
                  loading="lazy"
                  src={option?.iconUrl}
                  alt={option?.label}
                  width={20}
                  height={16}
                />
              ) : (
                ''
              )}
              {option?.label || ''}
            </Stack>
          );
        }}
        disabled={disabled}
        getOptionSelected={(
          option: TessellAutocompleteOption,
          value: TessellAutocompleteOption,
        ) => option?.value === value?.value}
        value={autocompleteValue}
        onChange={(event: any, newValue: any) => {
          onChange(
            multiple ? newValue?.map((_v) => _v.value) : newValue?.value,
          );
        }}
        multiple={multiple}
        {...textFieldProps}
        renderInput={(params) => (
          <TextField
            label={showDefaultStyleLabel ? label : ''}
            variant={variant}
            {...params}
          />
        )}
      />
    );
  };
  const OnlyTextLabel = () => {
    const { value } = textFieldProps;

    return (
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        height="37.13px"
        minHeight="37.13px"
      >
        {value}
      </Stack>
    );
  };
  return (
    <Stack width={width} sx={sx}>
      {!showDefaultStyleLabel && (
        <Stack direction={'row'}>
          <Typography
            sx={{
              ...(labelStyles ? labelStyles : styles.textBoxLabel),
              ...(disabled ? { color: styles.color.monotoneDarkLight } : {}),
              width: 'fit-content',
            }}
            mb={1}
          >
            {label}
          </Typography>
          {markAsRequired && <RequiredIndication />}
        </Stack>
      )}
      {onlyTextLabel ? OnlyTextLabel() : GetComponent()}
    </Stack>
  );
};

export default TessellAutocomplete;
