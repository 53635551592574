export const tessellPaginationStyles = {
  mainContainer: {
    flexDirection: 'row',
    pt: 3,
    width: '100%',
    justifyContent: 'space-between',
  },
  countContainer: {
    pr: 2,
  },
};
