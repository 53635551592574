/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function CloudIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.75781 15.4086C4.68382 15.4965 0.0460934 14.9366 1.17435 10.2027C1.43786 9.00235 2.83345 7.00594 5.7207 6.84082C5.97107 6.8265 6.18398 6.64424 6.23798 6.39934C6.71726 4.22565 8.6963 0.770856 13.0003 1.01198" stroke="var(--icon-color)" />
      <path d="M12.823 1.01198C17.169 0.770791 19.2211 4.22702 19.7116 6.40095C19.7667 6.64533 19.9794 6.82757 20.2296 6.8397C23.1479 6.98117 24.559 8.74565 24.8252 9.94678C25.9643 14.6813 21.2417 15.4985 19.1477 15.4106H6.33286" stroke="var(--icon-color)" />
    </svg>
  );
}
