import { DeploymentOptions } from 'constants/deploymentOptions';

function billingStatusString(isBillingEnabled) {
  return isBillingEnabled ? 'Enabled' : 'Disabled';
}

export const FEATURES_TO_SHOW = ['multi-disk', 'multi-service'];

export function tenantsAdapter(response) {
  const tenantsData = response?.response?.map((_tenant) => {
    const {
      _id,
      firstName,
      lastName,
      domain,
      country,
      company,
      status,
      emailAddress,
      metadata,
      onboardingInfo,
      subscriptions,
      type,
      pgSource,
      health,
      features,
      tenantPhase,
    } = _tenant;
    const isDomainConsole = domain?.toUpperCase() === 'CONSOLE';
    const isProfessionalEdition = type === 'PROFESSIONAL';
    const { infraInfo } = metadata;
    const { domainUrl = '' } = onboardingInfo || {};
    const isBillingEnabled =
      metadata?.billingInfo?.skipBillGeneration === false;
    return {
      id: _id,
      tenantDomain:
        isDomainConsole || isProfessionalEdition
          ? `${emailAddress}`
          : `${domain?.toUpperCase()}`,
      owner: `${firstName} ${lastName}`,
      company: company,
      status: status,
      country: country,
      contactEmail: emailAddress,
      billing: metadata?.billingInfo
        ? billingStatusString(isBillingEnabled)
        : '-',
      deploymentOption: DeploymentOptions[infraInfo?.deploymentType] || '',
      subscriptionsForTenant: !!subscriptions?.length
        ? `${subscriptions?.length}`
        : '-',
      domainUrl: domainUrl,
      domain: domain,
      pgSource: pgSource,
      tenantPhase: tenantPhase,
      health,
      features: features?.filter(
        (feature) =>
          feature?.name?.includes(FEATURES_TO_SHOW[0]) ||
          feature?.name?.includes(FEATURES_TO_SHOW[1]),
      ),
    };
  });
  return tenantsData;
}

export function tenantRegionsAdapter(response) {
  const def = ['All'];
  return Array.isArray(response?.response)
    ? [...def, ...response.response]
    : def;
}
