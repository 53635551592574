import { Stack, Typography } from '@mui/material';
import { useStyles } from '../utils';
import moment from 'moment';
import { DF_HUMAN } from 'helpers/dateFormats';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import { databaseColumns } from './constants';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import { StatusString } from '../Status';
import {
  StatusBackgroundColor,
  StatusBorderColor,
  StatusTextColor,
} from 'constants/statusConfigs';

const DatabasesColumnComponents = (column, row) => {
  const classes = useStyles();
  switch (column) {
    case databaseColumns.Name: {
      const { name } = row;
      return (
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <OverflowTooltip
            sxClass={classes.boldText}
            text={name}
            placement="left"
            variant="body2"
          />
        </Stack>
      );
    }
    case databaseColumns.Status: {
      const { status } = row;
      return (
        <Stack direction="row" alignItems="flex-start">
          <StatusLabel
            backgroundColor={StatusBackgroundColor[status]}
            textColor={StatusTextColor[status]}
            border={StatusBorderColor[status]}
            text={StatusString[status] || 'NA'}
          />
        </Stack>
      );
    }
    case databaseColumns.Created: {
      const { date_created, dateCreated } = row;
      if (!date_created && !dateCreated) {
        return 'NA';
      }
      const dateCreatedOn = moment(date_created || dateCreated).format(
        DF_HUMAN,
      );
      return (
        <>
          <Typography color="textPrimary">{dateCreatedOn}</Typography>
        </>
      );
    }
    default:
      return null;
  }
};

export default DatabasesColumnComponents;
