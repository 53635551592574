import { useState } from 'react';

export const useSelectEntitiesForForceRevokeAndAddingComputes = () => {
  const [selectedEntities, setSelectedEntities] = useState([]);
  const handleEntitySelection = (selection: string) => {
    if (selectedEntities?.includes(selection)) {
      setSelectedEntities(selectedEntities?.filter((_c) => _c !== selection));
    } else {
      setSelectedEntities((prev) => [...prev, selection]);
    }
  };

  return { selectedEntities, setSelectedEntities, handleEntitySelection };
};
