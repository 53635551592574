import {
  Box,
  Link as ExternalLink,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { DEFAULT_NULL } from 'constants/appConstants';
import { AdditionalDataListTooltip } from 'customers/tenants-detailed-view/lib/TenantCardSummary/AdditionalDataTooltip';
import { Link as InternalLink } from 'react-router-dom';
import { TessellLabelValueStyles } from './styles';
import { TessellLabelValueProps } from './types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileCopyOutlined } from '@mui/icons-material';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';

const TessellLabelValue = (props: TessellLabelValueProps) => {
  const {
    label,
    value,
    internalLink,
    externalLink,
    newTab,
    showCopy,
    moreTooltipTitle,
    containerStyles,
    labelStyles,
    labelContainerStyles,
    valueStyles,
    copyIconFontSize = '14px',
  } = props;

  const getValueComponent = (value) => {
    if (typeof value === 'string' || typeof value === 'number') {
      if (internalLink) {
        return (
          <InternalLink
            to={internalLink}
            style={{ textDecoration: 'underline' }}
          >
            {value}
          </InternalLink>
        );
      } else if (externalLink) {
        return (
          <Stack sx={{ textDecoration: 'underline' }}>
            <ExternalLink
              href={externalLink}
              underline="always"
              target={newTab ? '_blank' : '_self'}
            >
              {value}
            </ExternalLink>
          </Stack>
        );
      } else if (showCopy) {
        return (
          <Stack
            direction="row"
            whiteSpace="nowrap"
            alignItems="center"
            spacing={1}
            justifyContent={'flex-end'}
            sx={{ width: '80%' }}
          >
            <Box component="div" sx={TessellLabelValueStyles.valueContainer}>
              {value || DEFAULT_NULL}
            </Box>
            {showCopy && (
              <CopyToClipboard text={value || DEFAULT_NULL}>
                <IconButton color="primary" size="small">
                  <FileCopyOutlined sx={{ fontSize: copyIconFontSize }} />
                </IconButton>
              </CopyToClipboard>
            )}
          </Stack>
        );
      } else {
        return (
          <OverflowTooltip text={value} sxClass={valueStyles}>
            {value}
          </OverflowTooltip>
        );
      }
    } else if (Array.isArray(value)) {
      return (
        <AdditionalDataListTooltip
          list={value}
          tooltipListTitle={moreTooltipTitle || value[0]}
        />
      );
    }

    return value;
  };

  return (
    <Stack
      direction={'row'}
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      sx={{ ...containerStyles }}
    >
      <Stack sx={{ whiteSpace: 'nowrap', ...labelContainerStyles }}>
        <Typography fontWeight={600} sx={{ ...labelStyles }}>
          {`${label} : `}
        </Typography>
      </Stack>
      {getValueComponent(value)}
    </Stack>
  );
};

export default TessellLabelValue;
