import { styles } from 'assets/scss/style-templates';
import ReactApexChart from 'react-apexcharts';
import { colorsList } from './Constants';

export const DonutChart = (props) => {
  const { chartData } = props;
  const { labels, data } = chartData;
  return (
    <ReactApexChart
      options={{
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        chart: {
          type: 'donut',
        },
        labels: labels || [],
        legend: {
          show: false,
        },
        colors: [...colorsList],
        plotOptions: {
          pie: {
            expandOnClick: false,
            customScale: 1.25,
            offsetY: 16,
            donut: {
              size: '75%',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
      }}
      series={data || []}
      type="donut"
      height="100%"
      width="100%"
    />
  );
};

export const BarChart = (props) => {
  const { chartData } = props;
  const { labels, data } = chartData;
  return (
    <ReactApexChart
      options={{
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        colors: [...colorsList],
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '85%',
            distributed: true,
            dataLabels: {
              position: 'top',
            },
          },
        },

        dataLabels: {
          enabled: true,
          offsetX: 25,
          style: {
            fontSize: '12px',
            colors: [`${styles.color.darkBorder}`],
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val}`;
            },
            title: {
              formatter: function () {
                return '';
              },
            },
          },
        },
        xaxis: {
          categories: labels || [],
          labels: {
            formatter(val) {
              return val;
            },
          },
        },
        legend: {
          show: false,
        },
      }}
      series={[
        {
          data: data || [],
        },
      ]}
      type="bar"
    />
  );
};
