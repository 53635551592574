import React from 'react';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

import { styles } from 'assets/scss/style-templates';
import Cache from 'common/cache/lib/utils/Cache';

export const billingDisabled = () =>
  JSON.parse(Cache.get('whoami'))?.billingDisabled || false;

export const useStyles = () => {
  const theme = useTheme();
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    progress: {
      color: 'white',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    select: {
      marginRight: theme.spacing(2),
    },
    appBox: {
      borderRadius: '10px',
      backgroundColor: '#8996DF33',
    },
    appIcon: {
      width: '32px',
    },
    AWS: {
      width: '100px',
    },
    AZURE: {
      width: '180px',
      zoom: '2',
    },
    dialogBox: {
      border: `1px solid ${styles.color.darkShade}`,
      borderTop: 'none',
      borderRadius: '5px',
    },
    engineBox: {
      border: '1px solid #CCCCCC',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'border 200ms linear',
    },
    editionBox: {
      border: '1px solid #E1E5EA',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'border 200ms linear',
    },
    selectedEngineBox: {
      border: '1px solid #004D73',
      borderRadius: '5px',
      transition: 'border 200ms linear, box-shadow 200ms linear',
      cursor: 'default',
    },
    disabledEngineBox: {
      filter: 'grayscale(100%)',
      cursor: 'not-allowed',
    },
    engineLogo: {
      width: '150px',
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

export const getLabelValue = (label, value, variant) => (
  <Box display="flex" flexDirection="row">
    <Typography color="textSecondary" variant={variant}>
      {label}
      :&nbsp;
    </Typography>
    <Typography variant={variant}>{value}</Typography>
  </Box>
);

export const numberFormatter = new Intl.NumberFormat();

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const enUsNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
});

export const weekDays = () => {
  const mom = moment().day(0);
  return Array(7)
    .fill()
    .map(() => {
      const day = mom.format('dddd');
      mom.add(1, 'd');
      return day;
    });
};

export const DATABASE_TYPE = {
  Oracle: 'ORACLE',
  Postgresql: 'POSTGRESQL',
};

export const DATABASE_EDITION = {
  Enterprise: 'Enterprise',
  Standard: 'Standard',
};
export const objectDeepCopy = (object) => {
  if (object === undefined) return;
  return JSON.parse(JSON.stringify(object));
};
