import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { useStyles } from 'common/utils';
import { useSelectEntitiesForForceRevokeAndAddingComputes } from '../useSelectEntitiesForForceRevokeAndAddingComputes';
import { forceRevokeModalStyles } from './styles';

type ForceRevokeModalProps = {
  open: boolean;
  setOpen: any;
  entities: any[];
  requestId: string;
  handleForceRevoke: Function;
};

export const ForceRevokeModal = (props: ForceRevokeModalProps) => {
  const { open, setOpen, entities, requestId, handleForceRevoke } = props;
  const onClose = () => {
    setOpen(false);
  };

  const { selectedEntities, setSelectedEntities, handleEntitySelection } =
    useSelectEntitiesForForceRevokeAndAddingComputes();

  const classes = useStyles();

  const DialogTitle = () => {
    return <Typography variant="h5">Force Revoke</Typography>;
  };

  const DialogActions = () => {
    return (
      <Stack direction="row" spacing={2} mr={2}>
        <Button variant="outlined" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleForceRevoke(
              requestId,
              entities?.length === selectedEntities?.length
                ? {
                    allComputes: true,
                  }
                : {
                    computeResourceIds: selectedEntities,
                  },
            );
            onClose();
          }}
          disabled={!selectedEntities?.length}
          sx={forceRevokeModalStyles.revokeButton}
        >
          Revoke
        </Button>
      </Stack>
    );
  };

  const DialogContent = () => {
    return (
      <Stack spacing={2} sx={forceRevokeModalStyles.contentContainer}>
        <Alert severity="warning">
          The action to force revoke will disable the Genie for the compute from
          all service pages (in case of multi-service) and server page where it
          was previously enabled.
        </Alert>
        <Stack>
          <Typography variant="h6">
            Select instances for which you want to force revoke:
          </Typography>
          <FormControlLabel
            label="All Instances"
            control={
              <Checkbox
                checked={entities?.length === selectedEntities?.length}
                onChange={() => {
                  if (
                    selectedEntities?.length === 0 ||
                    selectedEntities?.length < entities?.length
                  ) {
                    setSelectedEntities(
                      entities?.map((entity) => entity?.computeResourceId),
                    );
                  } else {
                    setSelectedEntities([]);
                  }
                }}
              />
            }
          />
          <Box sx={forceRevokeModalStyles.entitiesContainer}>
            {entities?.map((_e) => (
              <FormControlLabel
                checked={selectedEntities?.includes(_e?.computeResourceId)}
                onChange={() => {
                  handleEntitySelection(_e?.computeResourceId);
                }}
                control={<Checkbox />}
                label={_e?.metadata?.name}
              />
            ))}
          </Box>
        </Stack>
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    />
  );
};
