import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ArrowBackRounded } from '@mui/icons-material';

interface BreadCrumbProps {
  title?: string;
  showBackButton?: boolean;
  pageTitle?: string;
}

const BreadCrumb = (props: BreadCrumbProps): JSX.Element => {
  const { title, showBackButton = true } = props;
  const navigate = useNavigate();
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center">
            {showBackButton && (
              <button
                className="d-flex btn btn-icon btn-ghost-secondary rounded-circle me-2"
                type="button"
                onClick={(e) => {
                  e?.preventDefault();
                  navigate(-1);
                }}
              >
                <ArrowBackRounded className="fs-20" />
              </button>
            )}
            <h4 className="d-flex mb-sm-0">{title}</h4>

            {/* <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li
                  className="breadcrumb-item"
                  onClick={(ev) => {
                    ev?.stopPropagation?.();
                    navigate(-1);
                  }}
                >
                  {pageTitle}
                </li>
                <li className="breadcrumb-item active">{title}</li>
              </ol>
            </div> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BreadCrumb;
