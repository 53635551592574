import { useMemo } from 'react';
import { servicesCostObject } from '../ServicesCostChart';
import { chartObjectAdapter } from '../../chartObjectAdapter';

type ServicesCostProps = {
  servicesCost: servicesCostObject;
};

export const useServicesCost = (props: ServicesCostProps) => {
  const { servicesCost } = props;
  const servicesList = servicesCost?.serviceCost || [];

  const { keys } = useMemo(() => {
    return chartObjectAdapter(servicesList?.[0]) || [];
  }, [servicesCost]);

  const servicesData = servicesList?.map((_costObj) => {
    return {
      name: _costObj?.serviceName,
      values: chartObjectAdapter(_costObj),
    };
  });

  const series = servicesData.map((_item, i) => {
    return {
      name: _item?.name || '-',
      data: _item?.values?.values || [],
    };
  });

  return {
    keys,
    series,
  };
};
