import { Stack } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import WorldMap from 'react-svg-worldmap';

export const RegionCountWorldMap = ({ data }) => {
  return (
    <Stack alignItems="center">
      <WorldMap
        color={styles.color.darkShade}
        size="xl"
        data={data}
        tooltipTextFunction={(e) => {
          return `${e.countryName}: ${e.countryValue}`;
        }}
        richInteraction
      />
    </Stack>
  );
};

export default RegionCountWorldMap;
