import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { useEffect, useMemo, useState } from 'react';
import { adaptDBEngineConfigsResponse } from './adaptDBEngineConfigResponse';
import { createParams } from 'helpers/createParams';
import { DBEngineConfigProps } from './types';
import { URLS } from 'constants/URL';
import { filter } from 'lodash';

export const useDBEngineConfig = (props: DBEngineConfigProps) => {
  const [filters, setFilters] = useState({ engine: 'ALL' });
  const [rows, setRows] = useState([]);
  const [updateDBEngineConfigOpen, setUpdateDBEngineConfigOpen] =
    useState(false);

  const {
    response,
    isLoading,
    postData: fetchDBEngineConfig,
  } = useLazyGetCallWithURL();

  const params = useMemo(
    () => ({
      'tenant-id': props.tenantID,
      'tenant-domain': props.tenantDomain,
      ...filters,
    }),
    [filters],
  );

  useEffect(() => {
    fetchDBEngineConfig(`${URLS.getDBEngineConfig}${createParams(params)}`);
  }, [params]);

  useEffect(() => {
    if (response?.response) {
      adaptDBEngineConfigsResponse(response?.response);
      setRows(adaptDBEngineConfigsResponse(response?.response));
    }
  }, [response, filters]);

  return {
    rows,
    filters,
    setFilters,
    isLoading,
    updateDBEngineConfigOpen,
    setUpdateDBEngineConfigOpen,
  };
};
