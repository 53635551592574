export const adaptCreditResponse = (response) => {
  if (response?.message) {
    return { error: response?.message };
  }

  if (
    typeof response?.amount === 'number' &&
    typeof response?.balance === 'number'
  ) {
    return {
      amount: response?.amount?.toFixed(2),
      balance: response?.balance?.toFixed(2),
      creditsUsed: (response?.amount - response?.balance)?.toFixed(2),
    };
  } else return { error: 'Invalid credit response.' };
};
