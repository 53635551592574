/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/3/2021     bakul.banthia         Created
 *
 */

const AppUtil = {
  isOpsMode() {
    return sessionStorage.getItem('opsMode') === 'true';
  },

  isOpsImpersonation() {
    return sessionStorage.getItem('opsImpersonation') === 'true';
  },

  toTitleCase(str) {
    if (!str) {
      return false;
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },

  getNumber(num, precision) {
    let p = precision || 1;
    if (precision === 0) {
      p = 0;
    }
    if (typeof num !== 'bigint') {
      num = Number(num);
    }
    return Number(num.toFixed(p));
  },

  updateUrlParams(urlParams, params) {
    Object.keys(params).forEach((k) => {
      urlParams.set(k, params[k]);
    });
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${urlParams}`,
    );
  },

  deleteUrlParams(urlParams, params) {
    params.forEach((p) => {
      urlParams.delete(p);
    });
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${urlParams}`,
    );
  },
};

export default AppUtil;
