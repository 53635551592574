import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useLazyPostCall } from 'common/api/useApiCall';
import { GROUPS_BASE_URL } from 'constants/URL';
import { useContext, useEffect, useMemo, useState } from 'react';

type CreateGroupProps = {
  handleOnSuccess: Function;
  setOpen: Function;
};

export const useCreateGroup = (props: CreateGroupProps) => {
  const { handleOnSuccess, setOpen } = props;
  const [groupName, setGroupName] = useState('');

  const [description, setDescription] = useState('');
  const { addNotification } = useContext(NotificationContext);

  const {
    response: createGroupResponse,
    postData: createGroup,
    isLoading: createGroupLoading,
  } = useLazyPostCall(GROUPS_BASE_URL);

  const payload = useMemo(
    () => ({
      groupName: groupName,
      description: description,
    }),
    [groupName, description],
  );

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (createGroupResponse?.status === 'SUCCESSFUL') {
      addNotification({
        severity: 'success',
        message: `${createGroupResponse?.message}`,
      });
      handleOnSuccess();
    } else if (createGroupResponse?.status) {
      addNotification({
        severity: 'error',
        message: createGroupResponse?.message || 'Something went wrong',
      });
    }
  }, [createGroupResponse]);

  return {
    groupName,
    description,
    createGroup,
    createGroupLoading,
    payload,
    setGroupName,
    setDescription,
    onClose,
  };
};
