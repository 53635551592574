export const enum CustomerAppsLabel {
  Tenants = 'Tenants',
  DatabaseServices = 'DB Services',
  Tickets = 'Tickets',
  Billing = 'Billing',
}

export const enum InfrastructureAppsLabel {
  CloudAccounts = 'Cloud Accounts',
  CloudInfrastructure = 'Cloud Infrastructure',
  PlatformServices = 'Platform Services',
}

export const enum AdministrationAppsLabel {
  IAM = 'IAM',
  SREAdministration = 'SRE Administration',
  PortalAdministration = 'Monitoring',
}

export const enum AppFamilyLabels {
  Customers = 'Customers',
  Infrastructure = 'Infrastructure',
  Administration = 'Administration',
}
