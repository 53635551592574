/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function TicketsIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.51798 10.3992L8.70208 13.1798L6.6532 15.4948L9.69324 15.1688L11.21 17.7765L11.9085 14.7896L14.8953 14.0911L12.2876 12.5744L12.6136 9.53438L10.2986 11.5833L7.51798 10.3992Z" fill="var(--icon-color)" />
      <path d="M0.363632 14.9091C0.162811 15.1099 0.162802 15.4355 0.363632 15.6363V15.6363L2.36364 17.6363V17.6363C2.56446 17.8372 2.89009 17.8372 3.09093 17.6363V17.6363C3.99898 16.7283 5.45564 16.7283 6.36365 17.6363C7.2717 18.5444 7.2717 20.001 6.36365 20.9091V20.9091C6.16281 21.1099 6.16281 21.4355 6.36365 21.6363V21.6363L8.36365 23.6363V23.6363C8.56447 23.8372 8.89009 23.8372 9.09091 23.6363V23.6363L23.6364 9.0909V9.0909C23.8372 8.89008 23.8372 8.56446 23.6364 8.36363V8.36363L21.6364 6.36363V6.36363C21.4355 6.1628 21.1099 6.1628 20.9091 6.36363V6.36363C20.0011 7.27166 18.5444 7.27169 17.6364 6.36363C16.7284 5.45561 16.7283 3.99894 17.6364 3.0909V3.0909C17.8372 2.89008 17.8372 2.56446 17.6364 2.36363V2.36363L15.6364 0.363642V0.363642C15.4355 0.162813 15.1099 0.162807 14.9091 0.363642V0.363642L0.363632 14.9091V14.9091ZM1.71885 15.3638L6.27272 10.8182L10.9091 6.18181L11.8182 7.0909L12.5454 6.36363L11.6363 5.45454L15.3707 1.75297L16.4957 2.87797C15.5427 4.18363 15.7319 5.91372 16.9091 7.0909C18.0862 8.26806 19.8435 8.48425 21.1491 7.53138L22.345 8.72728L18.6106 12.4288L17.6364 11.4546L16.9091 12.1818L17.8834 13.1561L8.69312 22.3381L7.49724 21.1422C8.45011 19.8366 8.26807 18.0862 7.09091 16.9091C5.91372 15.7319 4.14949 15.5358 2.84384 16.4888L1.71885 15.3638ZM12.5454 7.81817L14 9.27272L14.7273 8.54545L13.2727 7.0909L12.5454 7.81817ZM14.7273 9.99999L16.1818 11.4546L16.9091 10.7273L15.4545 9.27272L14.7273 9.99999Z" fill="var(--icon-color)" />
    </svg>
  );
}
