export const adaptInfraDeploymentResponse = (response) => {
  const infraResources = response?.response?.infraResources || '{}';
  return {
    eksCloudResourceUrl: infraResources?.k8sCloudResourceUrl,
    ecrepo: infraResources?.ec2RepoCloudResourceUrl,
  };
};

export const adaptInfraResourcesResponse = (response) => {
  return response?.response?.resourceInfo?.parameters?.repoTailscaleIp?.value;
};
