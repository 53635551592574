import { useEffect } from 'react';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { adaptDBIntegrationsResponse } from './adaptDBIntegrationsResponse';

type DBIntegrationsProps = {
  service?: DatabaseServiceType;
};

export const useDBIntegrations = ({ service }: DBIntegrationsProps) => {
  const {
    response: data,
    isLoading,
    postData: fetchDBIntegrations,
  } = useLazyGetCallWithURL(adaptDBIntegrationsResponse);

  useEffect(() => {
    if (service?._id) {
      fetchDBIntegrations(`${URLS.fetchDBIntegrations}tenant-id=${service?.tenantId}&tenant-domain=${service?.pgSource}&dbservice-id=${service?._id}
      `);
    }
  }, [service]);

  return { data, isLoading };
};
