import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import ModalCrossIcon from 'assets/icons-v2/ModalCrossIcon';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { DURATION_RANGE } from 'customers/db-services-detailed-view/constants/DurationList';
import {
  ResizeDrawerHeader,
  ResizeDrawerBody,
  ResizeDrawerFooter,
} from '../styledResize';
import { useGenieRequestModal } from './useGenieRequestModal';
import { TessellDrawer } from '@tessell/tessell-common-ui-library';
import { ControllerNodeTooltip } from '../ControllerNodeTooltip/ControllerNodeInfoTooltip';

type GenieRequestModalProps = {
  show: boolean;
  setShowModal: any;
  instances: Array<{ label: string; value: string }>;
  handleRequest: (state: any) => void;
  hideInstanceSelection?: boolean;
};

export const GenieRequestModal = ({
  show,
  setShowModal,
  instances,
  handleRequest,
  hideInstanceSelection = false,
}: GenieRequestModalProps) => {
  const {
    selectedInstances,
    expiryInMinutes,
    setExpiryInMinutes,
    handleInstanceSelection,
  } = useGenieRequestModal();

  return (
    <TessellDrawer open={show} onCloseDrawer={() => setShowModal(false)}>
      <Stack overflow="hidden">
        <ResizeDrawerHeader>
          <Typography fontSize="18px" lineHeight="28px" fontWeight="600">
            Request VM Access
          </Typography>

          <Stack
            onClick={() => setShowModal(false)}
            sx={{
              cursor: 'pointer',
            }}
          >
            <ModalCrossIcon />
          </Stack>
        </ResizeDrawerHeader>
        <Stack overflow="scroll" mb="24px">
          <ResizeDrawerBody>
            <Stack width="468px">
              {!hideInstanceSelection && (
                <>
                  <Typography>
                    Select instances for which you want access:
                  </Typography>
                  <FormControlLabel
                    checked={selectedInstances?.includes('all')}
                    onChange={() => {
                      handleInstanceSelection('all');
                    }}
                    control={<Checkbox />}
                    label={
                      <Stack direction={'row'} alignItems={'center'} gap={1}>
                        All instances (Service level)
                        <ControllerNodeTooltip />
                      </Stack>
                    }
                  />
                  {instances?.map((_c) => (
                    <FormControlLabel
                      checked={selectedInstances?.includes(_c?.value)}
                      onChange={() => {
                        handleInstanceSelection(_c?.value);
                      }}
                      control={<Checkbox />}
                      label={_c?.label}
                    />
                  ))}
                  <Divider />
                </>
              )}
              <Typography mt={2}>SPECIFY DETAILS</Typography>
              <TessellInput
                label="Duration"
                variant="outlined"
                size="small"
                name="duration"
                select
                value={expiryInMinutes}
                onChange={(e) => {
                  setExpiryInMinutes(e.target.value);
                }}
                options={DURATION_RANGE.map((v) => (
                  <MenuItem key={v.label} value={v.value}>
                    {v.label}
                  </MenuItem>
                ))}
              />
            </Stack>
          </ResizeDrawerBody>
        </Stack>
        <ResizeDrawerFooter>
          <Stack direction="row">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleRequest({
                  computes: selectedInstances?.includes('all')
                    ? undefined
                    : selectedInstances,
                  expiryInMinutes: expiryInMinutes,
                });
                setShowModal(false);
              }}
              disabled={
                hideInstanceSelection ? false : !selectedInstances?.length
              }
              sx={{ mr: 1 }}
            >
              Request
            </Button>

            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </ResizeDrawerFooter>
      </Stack>
    </TessellDrawer>
  );
};
