import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { useStyles } from 'common/utils';
import { useSelectEntitiesForForceRevokeAndAddingComputes } from '../useSelectEntitiesForForceRevokeAndAddingComputes';
import { useMemo } from 'react';
import { ControllerNodeTooltip } from 'common/Genie/ControllerNodeTooltip/ControllerNodeInfoTooltip';
import { useAddComputes } from './useAddComputes';
import { addComputesModalStyles } from './styles';

type AddComputesModalProps = {
  open: boolean;
  setOpen: any;
  entitiesFromRequest: any[];
  requestId: string;
  handleAddComputes: Function;
  serviceId?: string;
  instances?: Array<{ label: string; value: string }>;
};

export const AddComputesModal = (props: AddComputesModalProps) => {
  const {
    open,
    setOpen,
    entitiesFromRequest,
    requestId,
    handleAddComputes,
    serviceId,
    instances: instancesFromService,
  } = props;

  const classes = useStyles();

  const { selectedEntities, handleEntitySelection, instances, onClose } =
    useAddComputes({
      setOpen: setOpen,
      instancesFromService: instancesFromService,
      entitiesFromRequest: entitiesFromRequest,
    });

  const DialogTitle = () => {
    return (
      <Typography variant="h5">
        Select instances to add to the request
      </Typography>
    );
  };

  const DialogActions = () => {
    return (
      <Stack direction="row" spacing={2} mr={2}>
        <Button variant="outlined" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleAddComputes(
              requestId,
              selectedEntities.includes('all')
                ? {
                    serviceId: serviceId,
                  }
                : {
                    serviceId: serviceId,
                    computeResourceIds: selectedEntities,
                  },
            );
            onClose();
          }}
          disabled={!selectedEntities?.length}
          sx={addComputesModalStyles.addButtonStyles}
        >
          Add
        </Button>
      </Stack>
    );
  };

  const DialogContent = () => {
    return (
      <Stack spacing={2} sx={addComputesModalStyles.contentContainer}>
        <Stack>
          <FormControlLabel
            checked={selectedEntities?.includes('all')}
            onChange={() => {
              handleEntitySelection('all');
            }}
            control={<Checkbox />}
            label={
              <Stack
                direction="row"
                sx={addComputesModalStyles.tooltipContainer}
              >
                All instances (Service level)
                <ControllerNodeTooltip />
              </Stack>
            }
          />
          {instances?.map((instance) => (
            <FormControlLabel
              checked={selectedEntities?.includes(instance?.value)}
              onChange={() => {
                handleEntitySelection(instance?.value);
              }}
              control={<Checkbox />}
              label={instance?.label}
            />
          ))}
        </Stack>
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    />
  );
};
