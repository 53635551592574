export const LabelStrings = {
  id: 'Tenant Id',
  owner: 'Tenant Owner',
  name: 'Name',
  company: 'Company',
  geoLocation: 'Country',
  contactEmail: 'Tenant Email',
  deploymentOption: 'Deployment Option',
  billing: 'Billing Status',
  credits: 'Credits',
  subscriptionsForTenant: 'Subscriptions',
  domainUrl: 'Domain/URL',
  status: 'Status',
  mtdSpend: 'Month to date',
  ytdSpend: 'Year to date',
  avgUptime: 'Average uptime/RPO SLA',
  totalTickets: 'Active Tickets',
  sev1: 'P0 tickets',
  sev2: 'P1 tickets',
  sev3: 'P2 tickets',
  sev4: 'P3 tickets',
  totalCredits: 'Total credits',
  utilizedCredits: 'Utilized credits',
  creditUtilization: 'Credits Utilization ',
  amount: 'Total credits given',
  balance: 'Balance',
  creditUsed: 'Credits consumed',
  pocOwner: 'Tenant POC Owner',
  customerContacts: 'Customer Contact Emails',
};
