import { useMemo } from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Box, Stack } from '@mui/material';
import { AvailabilityChart } from './AvailabilityChart';
import { DbMonitoringChartConfiguration } from '../DbMonitoringChartConfiguration';
import _debounce from 'lodash/debounce';
import { ApexOptions } from 'apexcharts';
import { ResponsiveVirtualizedChartGrid } from './ResponsiveVirtualizedChartGrid';
import { monitoringCustomStyles } from '../monitoringCustomStyles';
import { useMonitoring } from './useMonitoring';

export default function DbMonitoring({ database }) {
  const {
    scrollRef,
    combinedAdaptedResponse,
    startTime,
    endTime,
    searchText,
    updateSearchText,
    metricOption,
    setMetricOption,
    updateStartEndTime,
    fetchAllMetrics,
    range,
    setRange,
    instance,
    setInstance,
    xMin,
    xMax,
    updateTimeUsingChartZoomIn,
    availability,
    getLatestXRange,
    dbMetrics,
    getFilteredCharts,
    combinedIsLoading,
    shouldRenderCharts,
  } = useMonitoring({ database });
  const Charts = useMemo(() => {
    const chartOptions: ApexOptions = {
      chart: {
        toolbar: {
          offsetX: -20,
        },
        animations: {
          enabled: false,
          easing: 'easeinout',
          speed: 0,
          animateGradually: {
            enabled: false,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 300,
          },
        },

        events: {
          zoomed: (chart: any, options?: any) => {
            updateTimeUsingChartZoomIn(chart, options);
          },
        },
      },
      xaxis: {
        min: getLatestXRange()?.xMin,
        max: getLatestXRange()?.xMax,
      },
    };
    return (
      <ResponsiveVirtualizedChartGrid
        monitoringMetrics={dbMetrics}
        filteredMonitoringMetrics={getFilteredCharts}
        scrollRef={scrollRef}
        chartOptions={chartOptions}
        isLoadingCharts={combinedIsLoading}
      />
    );
  }, [dbMetrics, shouldRenderCharts, combinedIsLoading]);

  return (
    <Box p={1} height={'500px'} sx={{ overflow: 'hidden' }}>
      <Stack
        sx={monitoringCustomStyles.DbMonitoring.mainComponent}
        ref={scrollRef}
      >
        <Stack sx={monitoringCustomStyles.DbMonitoring.configuration}>
          <DbMonitoringChartConfiguration
            database={database}
            metricsRes={combinedAdaptedResponse}
            startTime={startTime}
            endTime={endTime}
            searchText={searchText}
            setSearchText={updateSearchText}
            metricOption={metricOption}
            setMetricOption={setMetricOption}
            updateStartEndTime={updateStartEndTime}
            fetchMetrics={fetchAllMetrics}
            range={range}
            setRange={setRange}
            instance={instance}
            setInstance={setInstance}
          />

          <AvailabilityChart
            xMin={xMin}
            xMax={xMax}
            updateTimeUsingChartZoomIn={updateTimeUsingChartZoomIn}
            availabilityChart={availability}
          />
        </Stack>
        <Stack sx={monitoringCustomStyles.DbMonitoring.charts}>{Charts}</Stack>
      </Stack>
    </Box>
  );
}
