/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Fade, Stack } from '@mui/material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { iconNameMap } from 'constants/iconMap';
import OverflowTooltip from 'common/custom-components/lib/components/OverflowTooltip';
import { CheckOutlineIcon, CrossIcon, CrossOutlineIcon } from 'assets/icons-v2';
import CheckIcon from 'assets/icons-v2/CheckIcon';
import { styles } from 'assets/scss/style-templates';
import { URLS } from 'constants/URL';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { useLazyGetCall } from 'common/api/useApiCall';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { DF_HUMAN } from 'helpers/dateFormats';

const colors = ['#4DAC4A', '#F29132', '#EC373C'];

const columns = [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'message',
    headerName: 'Activity',
    width: 380,
    renderCell: (params) => {
      const { row: data } = params;

      return (
        <Stack direction="row" alignContent="center">
          <Stack p={0.75}>
            {data?.hierarchy?.length === 1 &&
              (data.severity === 'CRITICAL' || data.childError) && (
                <CrossIcon width={18} />
              )}
            {data?.hierarchy?.length !== 1 &&
              (data.severity === 'CRITICAL' || data.childError) && (
                <CrossOutlineIcon width={18} />
              )}
            {!data?.childError &&
              data.severity !== 'CRITICAL' &&
              data?.hierarchy?.length === 1 && <CheckIcon width={18} />}
            {!data?.childError &&
              data.severity !== 'CRITICAL' &&
              data?.hierarchy?.length !== 1 && <CheckOutlineIcon width={18} />}
          </Stack>
          <Typography
            sx={{
              ml: 1,
              color: 'textPrimary',
              wordWrap: 'break-word !important',
              whiteSpace: 'normal !important',
              maxHeight: '50px',
            }}
          >
            <Typography fontWeight="fontWeightMedium" display="inline">
              {`${data.entityName}: `}
            </Typography>
            {data.message}
          </Typography>
        </Stack>
      );
    },
  },

  {
    field: 'eventType',
    headerName: 'Event Type',
    width: '270',
    hide: true,
    renderCell: (params) => {
      const { row: data } = params;

      return data?.hierarchy?.length > 1 ? (
        <Stack direction="row">
          <Typography
            color={
              data?.severity !== 'CRITICAL' ? 'textPrimary' : styles.color.red
            }
            ml={2}
          >
            {data.eventType}
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row">
          {data?.childError ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <CloseIcon />
            </Stack>
          ) : null}
          <Typography
            sx={{
              color:
                data?.severity !== 'CRITICAL'
                  ? 'textPrimary'
                  : styles.color.red,
            }}
          >
            {data.eventType}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: 'entityName',
    headerName: 'Resource Name',
    width: 190,
    hide: true,
  },
  {
    field: 'app',
    headerName: 'App',
    width: 170,
    renderCell: (params) => {
      const Icon =
        iconNameMap.hasOwnProperty(params.value?.toUpperCase()) &&
        iconNameMap[params.value?.toUpperCase()];
      return (
        <Stack direction="row">
          {Icon && (
            <Stack
              width="20%"
              sx={{
                padding: '8px',
                gap: '8px',
                width: '36px',
                height: '36px',
                background: '#E7F3FA',
                borderRadius: '20px',
                alignItems: 'center',
              }}
            >
              <Icon width={26} darkBg={false} />
            </Stack>
          )}
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              color: '#08080D',
            }}
            m={1}
          >
            {params.value}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: 'occurredAt',
    headerName: 'Time',
    type: 'date',
    disableReorder: true,
    width: 130,
    renderCell: (params) => {
      const { row: data } = params;
      return (
        <Stack direction="column">
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              color: '#08080D',
            }}
          >
            {moment(data?.occurredAt).format(DF_HUMAN)}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '16px',
              color: '#666666',
            }}
            mt={1}
          >
            {moment(data?.occurredAt).format('HH:mm:ss')}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: 'entityType',
    headerName: 'Resource Type',
    width: 150,
    hide: true,
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 90,
    hide: true,
  },
  {
    field: 'owner',
    headerName: 'Owner',
    width: 180,
    hide: true,
  },
  {
    field: 'user',
    headerName: 'Initiated by',
    width: 260,
    renderCell: (params) => {
      const { row: data } = params;
      return (
        <Stack direction="row">
          <OverflowTooltip
            tooltip={params.value}
            text={
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#08080D',
                }}
                m={1}
              >
                {params.value}
              </Typography>
            }
            variant="h5"
            placement="right"
          />
        </Stack>
      );
    },
  },
  {
    field: 'systemGenerated',
    headerName: 'System Event',
    width: 90,
    hide: true,
  },
];

const getTreeDataPath = (row) => row?.hierarchy;

export const ParseText = (text) => {
  const temp = text.split("'").map((phrase, i) => {
    if (i % 2 === 1) {
      return (
        <Box
          fontWeight="fontWeightMedium"
          display="inline"
        >{` ${phrase} `}</Box>
      );
    }
    return <Typography display="inline">{` ${phrase} `}</Typography>;
  });
  return (
    <Stack direction="row" display="inline">
      {temp}
    </Stack>
  );
};
interface EventsProps {
  tenant: TenantType;
}

export default function Events(props: EventsProps) {
  const { tenant } = props;
  const { pgSource, _id: tenantId, emailAddress } = tenant;
  const [rows, setRows] = useState([]);
  const [owners, setOwners] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [severities, setSeverities] = useState([]);
  const [entityName, setEntityName] = useState([]);
  const [entityType, setEntityType] = useState([]);
  const [apps, setApps] = useState([]);
  const [users, setUsers] = useState([]);

  const {
    response: getActivitiesRes,
    responseStatus: getActivitiesResStatus,
    postData: getActivities,
    isLoading: getActivitiesIsLoading,
  } = useLazyGetCall(
    `${URLS.fetchEvents}?tenant-domain=${pgSource}&tenant-id=${tenantId}&user-id=${emailAddress}`,
  );

  useEffect(() => {
    getActivities();
  }, []);

  useEffect(() => {
    if (getActivitiesResStatus === 200 && getActivitiesRes?.response) {
      const localRows = [];
      const localEventTypes = new Set(['All']);
      const localUsers = new Set(['All']);
      const localOwners = new Set(['All']);
      const localEntityName = new Set(['All']);
      const localSeverity = new Set(['All']);
      const localEntityType = new Set(['All']);
      const localApps = new Set(['All']);
      const userColorMap = {};
      getActivitiesRes?.response?.forEach((ev, i) => {
        const events = ev?.events?.reverse();
        events?.forEach((event, j) => {
          localEventTypes.add(event?.event?.eventType);
          localUsers.add(event?.event?.userId);
          localOwners.add(event?.event?.ownerName);
          localEntityName.add(event?.event?.entityName);
          localSeverity.add(event?.event?.severity);
          localEntityType.add(event?.event?.entityType);
          localApps.add(event?.event?.appName);
          if (!userColorMap[event?.event?.userId]) {
            userColorMap[event?.event?.userId] =
              colors[Math.floor(Math.random() * colors.length)];
          }
          localRows.push({
            id: event?.event?.id,
            acknowledged: event?.acknowledged,
            message: ParseText(event?.event?.message),
            owner: event?.event?.ownerName,
            app: event?.event?.appName,
            user: event?.event?.userId,
            eventType: event?.event?.eventType,
            entityName: event?.event?.entityName,
            entityType: event?.event?.entityType,
            systemGenerated: event?.event?.systemGenerated ? 'Yes' : 'No',
            severity: event?.event?.severity,
            entityMetadata: event?.event?.entityMetadata,
            eventMetadata: event?.event?.eventMetadata,
            occurredAt: event?.event?.occurredAt,
            order: event?.event?.order,
            hierarchy: j === 0 ? [i + 1] : [i + 1, j + 1],
            childError: events.reduce(
              (prev, row) => prev || row?.event?.severity === 'CRITICAL',
              false,
            ),
            color: userColorMap[event?.event?.userId],
          });
        });
      });
      setRows(localRows);
      setOwners(Array.from(localOwners));
      setUsers(Array.from(localUsers));
      setEventTypes(Array.from(localEventTypes));
      setEntityName(Array.from(localEntityName));
      setSeverities(Array.from(localSeverity));
      setEntityType(Array.from(localEntityType));
      setApps(Array.from(localApps));
    }
  }, [getActivitiesRes, getActivitiesResStatus]);

  const searchProps = useMemo(
    () => ({
      name: 'Search',
      columns: [
        'message',
        'eventType',
        'user',
        'owner',
        'entityName',
        'entityType',
        'app',
      ],
    }),
    [],
  );

  const filterProps = useMemo(
    () => [
      {
        name: 'App',
        column: 'app',
        initialVal: 'All',
        options: apps
          ?.map((el) => ({ id: el, name: el }))
          ?.sort((a, b) => a.name - b.name),
      },
      {
        name: 'Initiated by',
        initialVal: 'All',
        column: 'user',
        options: users
          ?.map((el) => ({ id: el, name: el }))
          ?.sort((a, b) => a.name - b.name),
      },
      {
        name: 'Severity',
        initialVal: 'All',
        column: 'severity',
        options: severities
          ?.map((el) => ({ id: el, name: el }))
          ?.sort((a, b) => a.name - b.name),
      },
      {
        name: 'Event type',
        initialVal: 'All',
        column: 'eventType',
        options: eventTypes
          ?.map((el) => ({ id: el, name: el }))
          ?.sort((a, b) => a.name - b.name),
      },
    ],
    [eventTypes, users, owners, entityName, severities, entityType],
  );

  return (
    <Stack sx={{ height: '100vh' }}>
      <DataGrid
        treeData
        rows={rows || []}
        columns={columns}
        search={searchProps}
        filters={filterProps}
        getTreeDataPath={getTreeDataPath}
        clickable={false}
        rowHoverId="id"
        loading={getActivitiesIsLoading}
        groupingColDef={{
          headerName: '',
          width: '20',
          maxWidth: '20',
        }}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
    </Stack>
  );
}
