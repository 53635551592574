import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { adaptDBServersResponse } from './adaptDBServersResponse';
import { useEffect, useMemo, useState } from 'react';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { useNavigate } from 'react-router-dom';

type DBServer = {
  tenant: TenantType;
  subscriptionId?: any;
};

export const typeOfRequest = 'DBSERVER';

export const useDBServer = (props: DBServer) => {
  const { tenant, subscriptionId } = props;

  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [computeAnchor, setComputeAnchor] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});

  const [filters, setFilters] = useState<any>({
    subscription: tenant?.subscriptions?.find(
      (_s) => _s?._id === subscriptionId,
    )?.name,
  });

  const subscriptionIdToNameMap = {};
  const subs = new Set();
  tenant?.subscriptions?.forEach((sub) => {
    subscriptionIdToNameMap[sub._id] = sub.name;
    subs.add(sub.name);
  });

  const subsList = Array.from(subs)?.map((sub) => ({ id: sub, name: sub }));

  const {
    response: dBServersResponse,
    isLoading: dBServersLoading,
    postData: fetchDBServers,
  } = useLazyGetCallWithURL(adaptDBServersResponse);

  const {
    response: dBServerCountResponse,
    isLoading: dBServersCountIsLoading,
    postData: fetchDBServersCount,
  } = useLazyGetCallWithURL();

  const createParams = () => {
    const _subscriptionId = tenant?.subscriptions?.find(
      (_s) => _s?.name === filters.subscription,
    )?._id;
    const _params = Object.keys(filters)?.reduce(
      (acc, key) =>
        key === 'subscription'
          ? filters[key] && _subscriptionId
            ? `${acc}&subscription-id=${_subscriptionId}`
            : ''
          : acc
          ? `${acc}${
              filters[key]?.toString()?.toLowerCase() !== 'all'
                ? `&${key}=${filters[key]}`
                : ''
            }`
          : `${
              filters[key]?.toString()?.toLowerCase() !== 'all'
                ? `${key}=${filters[key]}`
                : ''
            }`,
      '',
    );
    return (
      _params +
      (tenant ? (_params ? '&' : '') + `tenant-id=${tenant?._id}` : '')
    );
  };

  useEffect(() => {
    fetchDBServers(
      `${URLS.fetchComputes}page-size=${ITEMS_PER_PAGE}&page-offset=${
        page - 1
      }&${createParams()}`,
    );
    fetchDBServersCount(
      `${URLS.fetchComputesCount}page-size=${ITEMS_PER_PAGE}&page-offset=${
        page - 1
      }&${createParams()}`,
    );
  }, [page, filters]);

  const dbServers = useMemo(() => {
    return dBServersResponse || [];
  }, [dBServersResponse]);

  const dbServersCount = useMemo(() => {
    return dBServerCountResponse?.response?.count;
  }, [dBServerCountResponse]);

  const dbServersPageCount = useMemo(
    () => Math.ceil(dbServersCount / ITEMS_PER_PAGE) || 0,
    [dbServersCount],
  );

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleRowClick = (params) => {
    const { row: data } = params;
    const { id } = data;
    const url = `/tenants/${tenant?._id}/database-servers/${id}/overview`;
    navigate(url);
  };
  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, [filterKey]: val }));
  };

  return {
    filters,
    subsList,
    page,
    dbServersCount,
    computeAnchor,
    isLoading: dBServersLoading || dBServersCountIsLoading,
    selectedRow,
    dbServersPageCount,
    dbServers,
    setComputeAnchor,
    setSelectedRow,
    handlePaginationChange,
    handleRowClick,
    handleFilterChange,
  };
};
