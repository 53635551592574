/* eslint-disable react-hooks/exhaustive-deps */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 */

import React, { useState } from 'react';
import { Popover, Stack, Typography, IconButton, Box } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

// type UpdateInProgressPropType = {
//   updateInfo: any,
//   anchorOrigin: any,
// };

export default function UpdateInProgress(props) {
  const { updateInfo, anchorOrigin } = props;

  const [anchor, setAnchor] = useState(null);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        <Typography color="primary">Updating</Typography>
        <IconButton>
          <InfoOutlined color="primary" sx={{ width: '20px' }} />
        </IconButton>
      </Stack>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: anchorOrigin?.vertical || 'bottom',
          horizontal: anchorOrigin?.horizontal || 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box maxWidth="800px" p={2}>
          {updateInfo}
        </Box>
      </Popover>
    </>
  );
}
