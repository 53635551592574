import { useGetCall, useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { useEffect, useMemo } from 'react';
import { DeploymentOptionsEnum } from './constants';

const deploymentNamesResponseAdapter = (response) => {
  if (response?.results) return response?.results;
};

const nameSpaceResponseAdapter = (response) => {
  if (response?.results) return response?.results;
};

type OnboardingConfigProps = {
  isPe: Boolean;
  deploymentName: string;
};

export const useOnboardingConfig = (props: OnboardingConfigProps) => {
  const { isPe, deploymentName } = props;

  const { response: deploymentNamesProfessional } = useGetCall(
    `${URLS.getDeploymentNames}${DeploymentOptionsEnum.Professional}`,
    deploymentNamesResponseAdapter,
  );
  const { response: deploymentNamesBusiness } = useGetCall(
    `${URLS.getDeploymentNames}${DeploymentOptionsEnum.Business}`,
    deploymentNamesResponseAdapter,
  );

  const {
    response: nameSpaceResponse,
    isLoading: nameSpacesIsLoading,
    postData: getAvailableNameSpaces,
  } = useLazyGetCallWithURL(nameSpaceResponseAdapter);

  const deploymentNames = useMemo(() => {
    if (isPe) {
      return deploymentNamesProfessional || [];
    }
    return deploymentNamesBusiness || [];
  }, [deploymentNamesProfessional, deploymentNamesBusiness, isPe]);

  const infraDeploymentId = useMemo(() => {
    if (deploymentName)
      return (
        deploymentNames?.find((item) => item?.name === deploymentName)?.id || ''
      );
  }, [deploymentNames, deploymentName, isPe]);

  useEffect(() => {
    if (infraDeploymentId)
      getAvailableNameSpaces(
        `${URLS.getAvailableNamespaces}${infraDeploymentId}`,
      );
  }, [infraDeploymentId]);

  const availableNamespaces = useMemo(() => {
    if (nameSpaceResponse) return nameSpaceResponse;
    return [];
  }, [nameSpaceResponse]);

  return { deploymentNames, availableNamespaces, nameSpacesIsLoading };
};
