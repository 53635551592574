import { useEffect } from 'react';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { adaptConnectionPoolsResponse } from './adaptConnectionPoolsResponse';

type ConnectionPoolsProps = {
  service?: DatabaseServiceType;
};

export const useConnectionPools = ({ service }: ConnectionPoolsProps) => {
  const {
    response: data,
    isLoading,
    postData: fetchConnectionPools,
  } = useLazyGetCallWithURL(adaptConnectionPoolsResponse);

  useEffect(() => {
    if (service?._id) {
      fetchConnectionPools(`${URLS.fetchConnectionPools}tenant-id=${service?.tenantId}&tenant-domain=${service?.pgSource}&dbservice-id=${service?._id}
      `);
    }
  }, [service]);

  return { data, isLoading };
};
