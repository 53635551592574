import { useMemo } from 'react';
import { useSelectEntitiesForForceRevokeAndAddingComputes } from '../useSelectEntitiesForForceRevokeAndAddingComputes';

type AddComputeProps = {
  setOpen: any;
  instancesFromService: any[];
  entitiesFromRequest: any[];
};

export const useAddComputes = (props: AddComputeProps) => {
  const { setOpen, instancesFromService, entitiesFromRequest } = props;

  const { selectedEntities, handleEntitySelection } =
    useSelectEntitiesForForceRevokeAndAddingComputes();

  const instances = useMemo(() => {
    const validInstances = instancesFromService?.filter(
      (_instance) => _instance?.value !== null,
    );

    const adaptedEntities = entitiesFromRequest?.map((_entity) => {
      return {
        label: _entity?.metadata?.name,
        value: _entity?.computeResourceId,
      };
    });

    const filteredAdaptedEntities = adaptedEntities?.filter(
      (_entity) => _entity?.label !== 'Controller-node',
    );

    const instancesNotPartOfRequest = validInstances?.filter(
      (instItem) =>
        !filteredAdaptedEntities?.some(
          (entity) => entity?.value === instItem?.value,
        ),
    );

    return instancesNotPartOfRequest;
  }, [instancesFromService, entitiesFromRequest]);

  const onClose = () => {
    setOpen(false);
  };

  return {
    selectedEntities,
    handleEntitySelection,
    instances,
    onClose,
  };
};
