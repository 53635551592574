import { ParseText } from './AuditLogsHelper';

export const auditLogsFilterResponseAdapter = (response) => {
  return (
    response?.response?.map((option) => {
      return { value: option, label: option };
    }) || []
  );
};

export const auditLogsReasonsFilterAdapter = (response) => {
  return (
    (typeof response?.response === 'object' &&
      Object.keys(response?.response)?.map((option) => {
        return { value: option, label: response?.response[option] };
      })) ||
    []
  );
};

export const auditLogResponseAdapter = (response) => {
  return (
    response?.response?.map((log) => ({
      ...log,
      id: log?._id,
      message: ParseText(log?.data?.message || ''),
      jiraId: log?.data?.jiraId,
      reason: log?.data?.reason,
    })) || []
  );
};
