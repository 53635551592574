/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function IntegrationsIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.58338 10.5499V1.31477C5.58338 1.03543 6.10905 0.989678 6.37188 1.00172H10.5906C11.2728 0.986718 11.5232 1.31477 11.6371 1.46395L12.8065 3.04259H17.808C18.3789 3.04259 18.4411 3.59245 18.4008 3.86737V10.7726C18.5435 11.5866 17.8886 11.7941 17.5432 11.7961H6.61361C5.73878 11.7961 5.56228 10.9653 5.58338 10.5499Z" stroke="var(--icon-color)" strokeWidth="0.7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.57725 16.842V24.1647C8.58896 24.4383 8.78807 24.9854 9.49082 24.9854H14.5506C14.8083 25.0064 15.3236 24.8718 15.3236 24.1647V16.842C15.3236 16.6105 15.2182 16.1223 14.7965 16.0213H9.49082C9.18629 15.9792 8.57725 16.0844 8.57725 16.842ZM9.21431 17.38V23.607C9.22513 23.8265 9.409 24.2654 10.058 24.2654L13.9695 24.2654C14.2074 24.2823 14.6834 24.1743 14.6834 23.607V17.38C14.6834 17.1943 14.586 16.8026 14.1966 16.7216L10.058 16.7215C9.77675 16.6878 9.21431 16.7722 9.21431 17.38Z" fill="var(--icon-color)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.2536 16.8286V24.1773C17.2654 24.4509 17.4645 24.998 18.1672 24.998H23.227C23.4847 25.019 24 24.8843 24 24.1773V16.8286C24 16.5971 23.8946 16.1089 23.4729 16.0079H18.1672C17.8627 15.9658 17.2536 16.071 17.2536 16.8286ZM18 17.4126V23.607C18.0108 23.8265 18.1947 24.2654 18.8436 24.2654H22.5728C22.8108 24.2823 23.2867 24.1742 23.2867 23.607V17.4126C23.2867 17.2269 23.1894 16.8352 22.8 16.7542H18.8436C18.5624 16.7204 18 16.8048 18 17.4126Z" fill="var(--icon-color)" />
      <path d="M3.24743 16.2779V14.3085C3.26998 14.1442 3.43011 13.8156 3.8902 13.8156L20.417 13.7745C20.5861 13.8156 20.7553 13.8977 20.7553 14.3496V16.2779" stroke="var(--icon-color)" strokeWidth="0.7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 16.8451V24.1679C0.0117169 24.4414 0.210905 24.9885 0.91392 24.9885H5.97563C6.2334 25.0096 6.74895 24.8749 6.74895 24.1679V16.8451C6.74895 16.6136 6.6435 16.1254 6.22169 16.0244H0.91392C0.60928 15.9823 0 16.0875 0 16.8451ZM0.661011 17.4327V23.5589C0.670961 23.7981 0.840112 24.2764 1.43712 24.2764L5.40002 24.2948C5.61429 24.2948 6.0521 24.1771 6.0521 23.5589V17.4327C6.0521 17.2303 5.96255 16.8035 5.60434 16.7152L1.43712 16.7152C1.17842 16.6784 0.661011 16.7704 0.661011 17.4327Z" fill="var(--icon-color)" />
      <path d="M12.0013 11.7961L12 16.5" stroke="var(--icon-color)" strokeWidth="0.7" />
    </svg>
  );
}
