import { Home } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import { useNavigate } from 'react-router-dom';

const dashboardAppIconStyles = {
  container: {
    px: 1,
    py: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    background: styles.color.lighterShade2,
    cursor: 'default',
    transition: 'background-color 1000ms ease',
  },
  unselected: {
    transition: 'background-color 1000ms ease',
    cursor: 'pointer',
    color: 'white',
    position: 'relative',
    '&:hover': {
      background: `${styles.color.lighterShade2}`,
    },
  },
};

export const DashboardApp = () => {
  const navigate = useNavigate();
  const url = window?.location?.pathname;
  return (
    <Stack
      onClick={() => navigate('/dashboard')}
      sx={{
        ...(url === '/dashboard'
          ? dashboardAppIconStyles.selected
          : dashboardAppIconStyles.unselected),
        ...dashboardAppIconStyles.container,
      }}
    >
      <Home height={20} sx={{ color: styles.color.baseBackground }} />
    </Stack>
  );
};
