import { Stack } from '@mui/material';
import { adaptIpAddresses } from './adaptIpAddresses';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';
import { styles } from 'assets/scss/style-templates';
type IPAddressesTooltipProps = {
  ipAddresses: any[];
};

export const IPAddressesTooltip = (props: IPAddressesTooltipProps) => {
  const { ipAddresses } = props;
  const adaptedIPAddressesArray = adaptIpAddresses(ipAddresses);
  return (
    <Stack width="150px">
      {adaptedIPAddressesArray?.map((_ipObj) => {
        return (
          <ShowLabelValue
            labelColor={styles.color.baseBackground}
            label={_ipObj?.type}
            value={_ipObj?.ip}
          />
        );
      })}
    </Stack>
  );
};
