export const adaptFreshdeskStatusesResponse = (response) => {
  return response?.response
    ? response?.response?.map((_status) => {
        return {
          value: _status?.id,
          label: _status?.label,
        };
      })
    : [];
};
