import Cache from 'common/cache/lib/utils/Cache';
import AppUtil from 'tools/AppUtil';
// import SupportUtil from 'tools/SupportUtil';

export const tenantUrl = 'https://api.relicxtesting001.tsl-terls.cloud';
export const tenants = 'https://api.tessell.com';
const { validateEmail } = AppUtil;

export const getHeaders = () => {
    const headers = {
        Authorization: Cache.get('accessToken'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'tenant-id': Cache.get('tenant-id'),
        // 'session-id': Cache.get('session-id'),
    };
    if (validateEmail(Cache.get('tenant-id')) || Cache.get('tenant-id') === 'undefined') {
        Cache.clear();
        Cache.set('reload', true);
        // SupportUtil.reset();
        window.location.replace(window.location.origin);
    }
    return headers;
};

export const getLoginUrl = () =>
    window.location.hostname.includes('localhost')
        ? 'http://localhost:3000'
        : window.location.origin;

export const getDomainUrl = () =>
    window.location.hostname.includes('localhost')
        ? tenantUrl
        : `https://api.${window.location.host}`;