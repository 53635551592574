import { isEmpty } from 'lodash';
import moment from 'moment';

function convertUTCDateToLocalDate(date) {
  const newDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000,
  );
  return newDate;
}

function convertTimeToLocalTime(time) {
  const localDateTime = convertUTCDateToLocalDate(
    new Date(`01/01/1970 ${time}`),
  );
  return moment(localDateTime).format('hh:mm A');
}

export const adaptScheduleResponse = (response) => {
  const { scheduleInfo, status: scheduleStatus } = response || {};
  const adaptedSchedule = {
    status: scheduleStatus,
    recurrence: scheduleInfo?.oneTime
      ? 'doesNotRepeat'
      : scheduleInfo?.recurring?.dailySchedule
      ? 'daily'
      : 'weekly',
    duration: {
      startDate: scheduleInfo?.oneTime
        ? scheduleInfo?.oneTime.dbServiceStartAt === null
          ? null
          : moment(new Date(scheduleInfo?.oneTime?.dbServiceStartAt)).format(
              'ddd, LLL',
            )
        : moment(new Date(scheduleInfo?.recurring?.scheduleStartDate)).format(
            'ddd, LL',
          ),
      endDate: scheduleInfo?.oneTime
        ? scheduleInfo?.oneTime?.dbServiceStopAt === null
          ? null
          : moment(new Date(scheduleInfo?.oneTime.dbServiceStopAt)).format(
              'ddd, LLL',
            )
        : scheduleInfo?.recurring?.scheduleExpiry.on === null
        ? null
        : moment(new Date(scheduleInfo?.recurring?.scheduleExpiry.on)).format(
            'ddd, LL',
          ),
      startTime: scheduleInfo?.oneTime
        ? scheduleInfo?.oneTime.dbServiceStartAt === null
          ? null
          : moment(new Date(scheduleInfo?.oneTime.dbServiceStartAt)).format(
              'hh:mm A',
            )
        : convertTimeToLocalTime(scheduleInfo?.recurring?.dbServiceStartAt),
      endTime: scheduleInfo?.oneTime
        ? scheduleInfo?.oneTime?.dbServiceStopAt === null
          ? null
          : moment(new Date(scheduleInfo?.oneTime?.dbServiceStopAt)).format(
              'hh:mm A',
            )
        : convertTimeToLocalTime(scheduleInfo?.recurring?.dbServiceStopAt),
    },
    repeatsOn: scheduleInfo?.oneTime
      ? []
      : scheduleInfo?.recurring?.weeklySchedule?.days,
    ends: {
      type:
        scheduleInfo?.recurring?.scheduleExpiry?.never === true
          ? 'never'
          : scheduleInfo?.recurring?.scheduleExpiry?.on
          ? 'onDate'
          : scheduleInfo?.recurring?.scheduleExpiry?.afterOccurrences
          ? 'afterOccurrences'
          : 'never',
      endDate: scheduleInfo?.recurring?.scheduleExpiry?.on
        ? moment(scheduleInfo?.recurring?.scheduleExpiry?.on).format('ddd, LL')
        : '',
      occurrences: scheduleInfo?.recurring?.scheduleExpiry?.afterOccurrences
        ? scheduleInfo?.recurring?.scheduleExpiry?.afterOccurrences
        : '',
    },
    isDelete: false,
  };

  return !isEmpty(response) ? adaptedSchedule : {};
};
