import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { useContext, useMemo, useState } from 'react';

export const useSubnets = () => {
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);
  const [selectedSubnet, setSelectedSubnet] = useState(null);
  const { hasPermission } = useContext(UserPermissionsContext);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        headerAlign: 'center',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Subnet Name',
        width: 100,
      },
      {
        field: 'cloudId',
        headerName: 'Cloud Id',
        width: 80,
      },
      {
        field: 'cloudLocationId',
        headerName: 'Cloud Location Id',
        width: 80,
      },
      {
        field: 'cidrBlock',
        headerName: 'IP Address Range',
        width: 80,
      },
    ],
    [],
  );

  const menuItemsProps = useMemo(
    () =>
      [
        hasPermission(PERMISSIONS.UPDATE_TENANT) && {
          label: 'Update Metadata',
          onClick: (row) => {
            setSelectedSubnet(row);
            setUpdateMetadataOpen(true);
          },
        },
      ].filter((a) => a),
    [],
  );

  return {
    updateMetadataOpen,
    setUpdateMetadataOpen,
    selectedSubnet,
    menuItemsProps,
    columns,
  };
};
