/*
 * Copyright © Tessell Inc, 2021. All rights reserved.
 */

import { useState, useEffect, useContext } from 'react';
import {
  NotificationContext,
  AddNotificationProps,
} from './NotificationContainer';
import { isNullOrUndefined } from 'helpers/isNullOrUndefined';

const useAddApiNotification = (props: AddNotificationProps) => {
  const { response, responseStatus, isLoading } = props?.apiResponse || {};
  const [shouldAddNotification, setShouldAddNotification] = useState(false);
  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (props?.apiResponse) {
      if (!isNullOrUndefined(responseStatus) && !isLoading) {
        setShouldAddNotification(true);
      }
    }
  }, [responseStatus, response, isLoading]);

  if (shouldAddNotification) {
    addNotification({
      ...props,
    });
    setShouldAddNotification(false);
  }
};

export default useAddApiNotification;
