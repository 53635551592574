export const JiraPriorities = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
  4: 'Highest',
};

export const FreshdeskPriorities = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
  4: 'Urgent',
};

export const Priorities = [
  {
    value: 4,
    label: 'Urgent',
  },
  {
    value: 3,
    label: 'High',
  },
  {
    value: 2,
    label: 'Medium',
  },
  {
    value: 1,
    label: 'Low',
  },
];
