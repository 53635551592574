import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import {
  useGetCall,
  useLazyDeleteCallWithURL,
  useLazyGetCallWithURL,
} from 'common/api/useApiCall';
import { URLS, USERS_BASE_URL } from 'constants/URL';
import { useContext, useEffect, useState } from 'react';

type userObject = {
  name?: string;
  email: string;
  username: string;
};

export const useUsers = () => {
  const {
    response: usersResponse,
    isLoading,
    postData: getUsers,
  } = useGetCall(USERS_BASE_URL);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userPersonaMap, setUserPersonaMap] = useState({});
  const [currentUser, setCurrentUser] = useState<userObject>();

  const { addNotification } = useContext(NotificationContext);

  const {
    response: deleteUserResponse,
    postData: deleteUserPostData,
    isLoading: deleteUserIsLoading,
  } = useLazyDeleteCallWithURL();

  const {
    response: persona,
    postData: getPersona,
    isLoading: isPersonaLoading,
  } = useLazyGetCallWithURL();

  useEffect(() => {
    setUserPersonaMap((prev) => ({
      ...prev,
      [currentUser?.username]: persona?.groups || [],
    }));
  }, [persona]);

  useEffect(() => {
    if (deleteUserResponse?.status === 'SUCCESSFUL') {
      addNotification({
        severity: 'success',
        message: `${deleteUserResponse?.message}`,
      });
      getUsers();
    } else if (deleteUserResponse?.status) {
      addNotification({
        severity: 'error',
        message: deleteUserResponse?.message || 'Something went wrong',
      });
    }
  }, [deleteUserResponse]);

  const deleteUser = (userObject) => {
    deleteUserPostData(`${USERS_BASE_URL}/${userObject?.username}`);
  };

  const handleCreateUserSuccess = () => {
    getUsers();
    setOpenCreateUserModal(false);
  };

  return {
    usersList: usersResponse?.response,
    usersIsLoading: isLoading,
    userPersonaMap,
    openCreateUserModal,
    openDeleteConfirmation,
    deleteUserIsLoading,
    currentUser,
    isPersonaLoading,
    deleteUser,
    getUsers,
    setOpenCreateUserModal,
    setOpenDeleteConfirmation,
    handleCreateUserSuccess,
    setCurrentUser,
    getPersona,
  };
};
