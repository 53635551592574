import { Typography } from '@mui/material';
import _isEmpty from 'lodash/isEmpty';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import { computeInstanceStyles } from '../utils/computeInstance.styles';
import { useContext } from 'react';
import { CommonDataContext } from 'common/Contexts/CommonData';

const ComputeInstance = (props) => {
  const { value: computeName, row } = props;
  const { findCompute } = useContext(CommonDataContext);
  const classNames = computeInstanceStyles();
  const computeDetails = findCompute(computeName);
  const computeData = row?.computeDetails || {};

  if (_isEmpty(computeName)) {
    return (
      <Typography className={classNames.primaryText} color="textPrimary">
        -
      </Typography>
    );
  }

  const profileInfo = computeDetails?.profileInfo;
  const { vcpus, memoryGB } = (profileInfo || computeData || {}) as any;

  return (
    <div key={`${computeName}-key`}>
      <Typography className={classNames.primaryText} color="textPrimary">
        {computeName}
      </Typography>
      <OverflowTooltip
        placement="right"
        text={`${vcpus} vCPU / ${Number(memoryGB).toFixed(0)} GB`}
      />
    </div>
  );
};

export default ComputeInstance;
