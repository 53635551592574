import { Stack } from '@mui/material';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import {
  StatusBackgroundColor,
  StatusTextColor,
} from 'constants/statusConfigs';
import { useDBIntegrations } from './useDBIntegrations';

type DBIntegrationsProps = {
  service?: DatabaseServiceType;
};

const DBIntegrations = (props: DBIntegrationsProps) => {
  const { data, isLoading } = useDBIntegrations(props);
  const dbColumns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'endpoint',
      headerName: 'Endpoint',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => {
        const { row: data } = params;
        const { status } = data;
        return (
          <Stack direction="row" alignItems="flex-start">
            <StatusLabel
              backgroundColor={StatusBackgroundColor[status]}
              textColor={StatusTextColor[status]}
              text={status || 'NA'}
            />
          </Stack>
        );
      },
      width: 150,
    },
  ];

  return (
    <Stack sx={{ height: '50vh' }}>
      <DataGrid
        columns={dbColumns}
        rows={data}
        loading={isLoading}
        styleOverrides={{ hideShadow: true }}
        clickable={false}
        rowHoverId="id"
        getRowId={(row) => `${row.id}`}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
    </Stack>
  );
};

export default DBIntegrations;
