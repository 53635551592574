import moment from 'moment';
import { FreshdeskPriorities } from '../../utils/constants';
import { DF_HUMAN } from 'helpers/dateFormats';

export const adaptTicketsResponse = (response) => {
  return response?.response?.tickets?.length
    ? response?.response?.tickets?.map((ticket) => ({
        id: ticket.id,
        subject: ticket?.subject || '',
        status: ticket?.status,
        jira: ticket?.tags?.find(
          (tag) => tag?.includes('TS-') || tag?.includes('SRE-'),
        ),
        severity: FreshdeskPriorities[ticket?.priority],
        created: moment(ticket?.created_at)?.format(DF_HUMAN),
        lastUpdated: moment(ticket?.updated_at)?.format(DF_HUMAN),
        descriptionText: ticket?.description_text,
        priority: ticket?.priority,
        responder_id: ticket?.responder_id,
      }))
    : [];
};
export const adaptTicketUsersResponse = (response) =>
  response?.agents?.map((agent) => ({
    id: agent.id,
    name: agent?.contact?.name,
    email: agent?.contact?.email,
  }));
