import { useMemo } from 'react';
import { Stack } from '@mui/material';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-virtualized/styles.css';
import { ApexOptions } from 'apexcharts';
import _merge from 'lodash/merge';
import { AutoSizer, WindowScroller } from 'react-virtualized';
import { BasicAreaCharts } from '../../../../common/charts/BasicAreaCharts';
import { ZoomableLineChart } from './ZoomableLineChart';
import { AdaptedMetricType } from '../monitoringTypes';
import { getMemoizedChartKey } from '../getMemoizedChartKey';
import { VirtualizedChartGrid } from './VirtualizedChartGrid';
import {
  ResponsiveVirtualizedChartGridProps,
  AutoSizerChildProps,
  MemoizedChartType,
  WindowScrollerChildProps,
} from '../DbMonitoringSchema';
import { TopProcess } from '../TopProcesses/TopProcess';

const getChartComponent = (chartTitle: string) => {
  if (chartTitle === 'Memory') {
    return {
      chart: BasicAreaCharts,
      type: 'area',
      props: { customColor: ['#FA8073', '#84C57D'] },
    };
  }
  if (chartTitle?.toLowerCase()?.includes('filesystem')) {
    return {
      chart: BasicAreaCharts,
      type: 'area',
      props: { customColor: ['#FA8073', '#84C57D'] },
    };
  }

  const defaultChart = { chart: ZoomableLineChart, type: 'line', props: {} };

  return defaultChart;
};

const getChartOptions = (
  chartType: string,
  chartOptions: ApexOptions,
): ApexOptions => {
  const chartOptionsMap = {
    line: chartOptions,
    area: {
      ..._merge(getAreaChartOptions(), chartOptions),
    } as ApexOptions,
  };

  return chartOptionsMap[chartType] || chartOptions;
};

export const ResponsiveVirtualizedChartGrid = (
  props: ResponsiveVirtualizedChartGridProps,
) => {
  const { monitoringMetrics, chartOptions, scrollRef } = props;
  const memoizedCharts: MemoizedChartType = {};

  useMemo(() => {
    monitoringMetrics?.forEach(
      (currMetric: AdaptedMetricType, index: number) => {
        const memoizationKey = getMemoizedChartKey(currMetric);
        if (!currMetric?.id) return;
        if (currMetric?.metricValues) {
          const process = monitoringMetrics?.find((item) => {
            return item?.id === currMetric?.title;
          });
          const memoizationValue = <TopProcess process={process} />;
          memoizedCharts[memoizationKey] = memoizationValue;
          return;
        }

        const {
          chart: ChartComponent,
          type,
          props: currChartProps,
        } = getChartComponent(currMetric?.title);
        const chartCompOptions = getChartOptions(type, chartOptions);
        const memoizationValue = (
          <ChartComponent
            chart={currMetric}
            chartOptions={chartCompOptions}
            {...currChartProps}
          />
        );

        memoizedCharts[memoizationKey] = memoizationValue;
      },
    );
  }, [monitoringMetrics, chartOptions]);

  const countOfValidCharts = Object?.keys?.(memoizedCharts)?.length;
  return (
    <>
      {countOfValidCharts ? (
        <WindowScroller
          scrollElement={scrollRef?.current}
          scrollingResetTimeInterval={0}
        >
          {(wsScrollerProps: WindowScrollerChildProps) => (
            <Stack
              flex="1 1 auto"
              width={scrollRef?.current?.clientWidth}
              height={scrollRef?.current?.clientHeight - 300}
            >
              <AutoSizer disableHeight>
                {(autoSizerProps: AutoSizerChildProps) => (
                  <VirtualizedChartGrid
                    {...props}
                    {...autoSizerProps}
                    {...wsScrollerProps}
                    memoizedCharts={memoizedCharts}
                  />
                )}
              </AutoSizer>
            </Stack>
          )}
        </WindowScroller>
      ) : (
        <></>
      )}
    </>
  );
};

export const getAreaChartOptions = (): ApexOptions => ({
  chart: {
    stacked: true,
    stackType: 'normal',
  },
  legend: {
    showForSingleSeries: true,
    onItemClick: {
      toggleDataSeries: false,
    },
    position: 'bottom',
    horizontalAlign: 'left',
    markers: {
      offsetX: -5,
      offsetY: 2,
    },
  },
  fill: {
    type: 'solid',
  },
});
