import ReactApexChart from 'react-apexcharts';
import { CostColors } from '../constants';
import { formatValueToCost } from '../adapters';
import { Stack } from '@mui/material';
import { legendStylesLineChart } from '../styles';

type CostLineChartProps = {
  keys: string[];
  series: any[];
  customColors?: string[];
};

const CostLineChart = (props: CostLineChartProps) => {
  const { keys = [], series = [], customColors } = props;
  return (
    <Stack sx={legendStylesLineChart}>
      <ReactApexChart
        options={{
          states: {
            active: {
              filter: {
                type: 'none',
              },
            },
          },
          stroke: { width: 3 },
          colors: customColors || CostColors,
          chart: {
            type: 'line',
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return ` $${val}`;
              },
            },
          },
          xaxis: {
            categories: keys || [],
            labels: {
              rotate: 0,
              formatter(val, i) {
                return val;
              },
            },
            tickAmount: 5,
          },
          yaxis: {
            labels: {
              formatter(val) {
                return formatValueToCost(val);
              },
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            horizontalAlign: 'right',
            offsetX: 10,
          },
        }}
        series={series}
      />
    </Stack>
  );
};

export default CostLineChart;
