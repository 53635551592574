import { LinearProgress, Stack } from '@mui/material';
import { costLayout } from '../styles';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import {
  PlaneNames,
  COST_MAX_RANGE,
  CostRangeType,
  CostResponseKeys,
  COST_UNKNOWN_STRING,
} from '../constants';
import DatePickerToggler from 'common/custom-components/lib/components/DatePickerToggler';
import { DF_HUMAN_WITHOUT_TIME } from 'helpers/dateFormats';
import { EMPTY_STRING } from 'constants/appConstants';
import { isEmpty } from 'lodash';
import { colorsList } from 'global-components/Dashboard/Charts/Constants';
import TotalTableAndPieView from '../TotalTableAndPieView/TotalTableAndPieView';
import { planesCostAdapter } from '../adapters';
import { CostEnums } from 'constants/URLEnums';
import { useCostData } from '../Hooks/useCostData';
import { adaptToAutocompleteOption } from 'Administration/IAM/Groups/EditGroup/groupResponseAdapter';
import DayWiseChartAndTable from '../DayWiseChartAndTable/DayWiseChartAndTable';
import NoData from '../NoData';

type CostByPlaneProps = {
  planeNames: string[];
  planeNamesIsLoading: boolean;
};

const CostByPlane = (props: CostByPlaneProps) => {
  const { planeNames = [], planeNamesIsLoading = false } = props;
  const {
    startTime,
    endTime,
    selectedEntities: selectedServiceTypes,
    setSelectedEntities: setSelectedServiceTypes,
    costData: planesCost,
    costDataisLoading: planesCostIsLoading,
    updateTimeUsingNativePicker,
    handleDateToggle,
    range,
  } = useCostData({
    costType: CostEnums.COST_BY_CONTROL_DATA_PLANE,
    costParamsKey: 'service-type',
    customCostAdapter: planesCostAdapter,
  });

  return (
    <Stack sx={costLayout.contentContainer}>
      <Stack sx={costLayout.topBarContainer}>
        <Stack
          direction={'row'}
          sx={costLayout.topBarContentContainer}
          spacing={2}
        >
          <TessellAutocomplete
            label="Plane"
            showDefaultStyleLabel
            value={selectedServiceTypes}
            variant="outlined"
            size="small"
            onChange={(_val) => {
              setSelectedServiceTypes(_val);
            }}
            fullWidth
            options={
              adaptToAutocompleteOption(planeNames, (_name) => {
                if (_name === EMPTY_STRING) {
                  return { label: COST_UNKNOWN_STRING, value: '' };
                }
                return {
                  label: PlaneNames[_name] || _name,
                  value: _name,
                };
              }) || []
            }
            multiple
            disabled={planeNamesIsLoading}
            sx={{
              ...(!selectedServiceTypes?.length && { width: '150px' }),
            }}
          />
          <DatePickerToggler
            rangeType={CostRangeType}
            startTime={startTime}
            endTime={endTime}
            updateTimeUsingNativePicker={updateTimeUsingNativePicker}
            handleOnChange={handleDateToggle}
            range={range}
            customDateFormat={DF_HUMAN_WITHOUT_TIME}
            showTimePicker={false}
            maxSpanInDays={COST_MAX_RANGE}
          />
        </Stack>
      </Stack>

      <Stack sx={costLayout.graphAndTableContainer}>
        {planesCostIsLoading ? (
          <Stack sx={costLayout.loaderContainer}>
            <Stack width="100%">
              <LinearProgress color="primary" />
            </Stack>
          </Stack>
        ) : !planesCostIsLoading && !isEmpty(planesCost) ? (
          <Stack direction={'row'} spacing={1} width="100%">
            <Stack sx={costLayout.chartContainer}>
              <DayWiseChartAndTable
                costArray={planesCost}
                seriesAndHeadName={CostResponseKeys.SERVICE_TYPE}
                firstColmnTitle="Plane"
              />
            </Stack>

            <TotalTableAndPieView
              costArray={planesCost}
              tableFirstColumnKey={CostResponseKeys.SERVICE_TYPE}
            />
          </Stack>
        ) : (
          <Stack>{!planesCost?.length ? <NoData /> : EMPTY_STRING}</Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default CostByPlane;
