export const addComputesModalStyles = {
  contentContainer: {
    width: '400px',
    p: 1,
    m: 1,
  },
  tooltipContainer: {
    alignItems: 'center',
    gap: 1,
  },
  addButtonStyles: { mr: 1 },
};
