import { Auth } from 'aws-amplify';

export const resetPasswordAuth = async (
  loginInfo,
  password,
  attributes,
  setUserLoginInfo,
  navigate,
  setError,
  setVerifyLoading,
) => {
  setError('');
  setVerifyLoading(true);
  try {
    const userSignIn = await Auth.completeNewPassword(
      loginInfo,
      password,
      attributes,
    )
      ?.then((user) => {
        setUserLoginInfo(user);
        if (user?.challengeName === 'MFA_SETUP') {
          navigate('/mfa-setup');
        }
        if (
          user?.challengeName === undefined &&
          user?.signInUserSession?.idToken?.jwtToken.length
        ) {
          navigate('/dashboard');
        }
        setVerifyLoading(false);
        return user;
      })
      .catch((error) => {
        console.log('Error Signing in:', error);
        setVerifyLoading(false);
        setError(error);
        setUserLoginInfo({});
      });
    return userSignIn;
  } catch (error: any) {
    console.log('Error Signing in', error);
    setError(error?.message || 'Error Signing in');
    setUserLoginInfo({});
    return error;
  }
};
