import { Stack, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';

type TessellNumberInRectangleProps = {
  number: number;
};

const TessellNumberInRectangleStyles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '20px',
    backgroundColor: styles.color.darkShade,
    borderRadius: '4px',
    color: styles.color.baseBackground,
  },
};

export const TessellNumberInRectangle = (
  props: TessellNumberInRectangleProps,
) => {
  const { number } = props;
  return number ? (
    <Stack sx={TessellNumberInRectangleStyles.container} px={0.5}>
      <Typography variant="body2" fontWeight={500} fontSize={'12px'}>
        {number}
      </Typography>
    </Stack>
  ) : (
    <></>
  );
};
