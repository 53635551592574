import { DEFAULT_NULL } from 'constants/appConstants';
import {
  awsRegions,
  azureRegion,
} from 'customers/tenants-list-view/constants/regions';

export const getCloudRegionFullText = (cloud, cloudRegion) => {
  return cloud === 'aws'
    ? awsRegions?.find((_region) => {
        return _region?.value === cloudRegion;
      })?.name || DEFAULT_NULL
    : azureRegion?.find((_region) => {
        return _region?.value === cloudRegion;
      })?.name || DEFAULT_NULL;
};
