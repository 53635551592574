export const barChartCardStyles = {
  container: {
    p: 3,
    borderRadius: '12px',
    mb: 2,
    backgroundColor: '#FAFAFA',
  },
  titleContainer: {
    height: '36px',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 0,
    mb: '26px',
  },
  titleText: {
    color: '#08080D',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
  },
};

export const donutChartCardStyles = {
  container: {
    p: '24px',
    borderRadius: '12px',
    mr: 2,
    mb: 2,
    width: '100%',
    backgroundColor: '#FAFAFA',
    height: '350px',
  },
  titleContainer: {
    height: '36px',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 0,
    mb: '26px',
  },
  titleText: {
    color: '#08080D',
    fontSize: '20px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
  },
  chartAndLegendContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    spacing: '32px',
  },
  chartContainer: {
    height: '200px',
    flexGrow: 0,
  },
  legendContainer: {
    flexGrow: 1,
    height: '100%',
    width: '200px',
    maxWidth: '380px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};
