import { ChevronRight, ExpandMore, Password } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import { nCountString } from 'helpers/nCountString';
import moment from 'moment';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import {
  GetInstanceStatus,
  useStyles,
} from 'customers/db-services-detailed-view/utils';
import { URLS } from 'constants/URL';
import { useContext, useEffect, useMemo, useState } from 'react';
import { formatString } from 'helpers/formatString';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import ConfirmationDialog from 'common/Modals/ConfirmationDialog';
import OtpModal from 'common/Modals/OtpModal';
import useAddApiNotification from 'common/NotificationContainer/useAddApiNotification';
import {
  useLazyPostCall,
  useGetCall,
  useLazyGetCallWithURL,
} from 'common/api/useApiCall';
import { OTP_TYPE } from 'constants/otpType';
import { evaluateTenantName } from 'common/TenantContainer/evaluateTenantName';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { DF_HUMAN } from 'helpers/dateFormats';
import { OTPRequestType } from 'constants/URLEnums';

interface SharedUsersTableProps {
  tenant: TenantType;
}

export const SharedUsersTable = (props: SharedUsersTableProps) => {
  const classes = useStyles();

  const { tenant } = props;
  const { hasPermission } = useContext(UserPermissionsContext);

  const forgotPasswordRes = useLazyPostCall(`${URLS.forgotPassword}`);

  const usersCountRes = useGetCall(
    URLS.fetchUsersCount + `?tenant-id=${tenant?._id}`,
  );

  const usersCount = useMemo(() => {
    return usersCountRes?.response?.response?.count;
  }, [usersCountRes?.response]);

  const usersPageCount = useMemo(
    () => Math.ceil((usersCount || 0) / ITEMS_PER_PAGE),
    [usersCount],
  );

  const usersURL = formatString(URLS.fetchUsersByTenantIdURL, [
    `${tenant?._id}`,
  ]);

  const {
    response: usersResponse,
    responseStatus: usersResponseStatus,
    isLoading,
    postData: fetchUsers,
  } = useLazyGetCallWithURL();

  const {
    response: sendOTPRes,
    isLoading: sendOTPIsLoading,
    postData: sendOTP,
  } = useLazyPostCall(
    `${URLS.otp}purpose=${OTPRequestType.FORGOT_PASSWORD_OTP}`,
  );

  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([] as any[]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [step, setStep] = useState(1);
  const [uuid, setUuid] = useState('');

  // TODO: camelCase conversion
  const sharedUsers = users ? users : tenant?.sharedWith?.users;
  const sharedUsersRows = sharedUsers?.map((user) => ({
    id: user?._id,
    name: capitaliseFirstLetterInEachWord(
      `${user?.firstName} ${user?.lastName}`,
    ),
    emailAddress: user?.emailId,
    authType: capitaliseFirstLetterInEachWord(user?.authType),
    persona: user?.tenants?.[0]?.personas?.[0]?.personaName,
    status: user?.status,
    dateLastLoggedIn: moment(user?.dateLastLoggedIn)?.format(DF_HUMAN),
    subscriptions: nCountString(
      user?.tenants?.[0]?.subscriptions?.length || 0,
      'No Subscription',
      'Subscription',
      'Subcriptions',
    ),
  }));
  const sharedUsersColumns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 120,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 120,
    },
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      minWidth: 120,
    },
    {
      field: 'persona',
      headerName: 'Persona',
      minWidth: 120,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      renderCell: (params) => {
        const { row } = params;
        return <GetInstanceStatus status={row?.status} />;
      },
    },
    {
      field: 'dateLastLoggedIn',
      headerName: 'Last login',
      minWidth: 120,
    },
    {
      field: 'authType',
      headerName: 'Auth Type',
      minWidth: 120,
    },
  ];

  const menuItems = (_user) => {
    return [
      hasPermission(PERMISSIONS.UPDATE_TENANT) && {
        label: 'Reset Password',
        icon: <Password sx={classes.icon} />,
        onClick: () => {
          setOpenConfirmationDialog(true);
          setSelectedUser(_user);
        },
      },
    ].filter((a) => a);
  };

  useEffect(() => {
    if (sendOTPRes?.uuid) {
      // Add notification and error messages handling
      setUuid(sendOTPRes?.uuid);
      setStep(2);
    }
  }, [sendOTPRes]);

  const handelResetPassword = (otp) => {
    const _bodyData = {
      otp: otp,
      'request-id': uuid,
      emailId: selectedUser?.emailAddress,
      'tenant-domain': tenant?.pgSource,
      host: tenant?.onboardingInfo?.apiGatewayUrl,
    };
    forgotPasswordRes.postData(JSON.stringify(_bodyData));
  };

  useEffect(() => {
    let _url = `${usersURL}&page-size=${ITEMS_PER_PAGE}&page-offset=${
      page - 1
    }`;
    fetchUsers(`${_url}`);
  }, [page]);

  useEffect(() => {
    if (Array.isArray(usersResponse?.response) && usersResponseStatus === 200) {
      setUsers(
        usersResponse.response.map((user) => ({ ...user, id: user._id })),
      );
    }
  }, [usersResponse, usersResponseStatus]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useAddApiNotification({
    successMsg: 'Reset Password Link has been sent successfully',
    errorMsg: 'Something went wrong. Please try again.',
    apiResponse: forgotPasswordRes,
    onSuccess: () => {
      setShowOtpModal(false);
      setStep(1);
    },
  });

  return (
    <>
      <Stack sx={{ height: '100vh' }}>
        <DataGrid
          columns={sharedUsersColumns}
          rows={sharedUsersRows}
          menuItems={menuItems}
          disableSelectionOnClick
          components={{
            Toolbar: CustomDataGridToolbar,
            DetailPanelCollapseIcon: ExpandMore,
            DetailPanelExpandIcon: ChevronRight,
          }}
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          styleOverrides={{ hideShadow: true }}
          totalCount={usersCount}
          pageCount={usersPageCount}
          pageNo={page}
          handlePaginationChange={handlePaginationChange}
          loading={isLoading}
        />
        <ConfirmationDialog
          open={openConfirmationDialog}
          setOpen={setOpenConfirmationDialog}
          handleConfirm={() => {
            setStep(1);
            setShowOtpModal(true);
            setOpenConfirmationDialog(false);
          }}
          confirmContent={`Are you sure you want to send the password reset link to ${selectedUser?.name}?`}
        />
        <OtpModal
          open={showOtpModal}
          setOpen={setShowOtpModal}
          onSubmit={(val) => {
            if (step === 1) {
              const { reason, jiraId } = val;
              const payload = {
                comment: reason,
                jiraId: jiraId,
                'tenant-user': encodeURIComponent(selectedUser?.emailAddress),
                'tenant-id': tenant?._id,
                'tenant-domain': tenant?.pgSource,
                'tenant-name': evaluateTenantName({
                  tenant: tenant,
                }),
              };
              sendOTP(JSON.stringify(payload, null));
            } else {
              handelResetPassword(val);
            }
          }}
          isSubmitting={
            step === 1 ? sendOTPIsLoading : forgotPasswordRes?.isLoading
          }
          otpUser={selectedUser?.name}
          step={step}
          reasonHeading="Enter Reason for Reset Password (with JIRA link)"
          otpType={OTP_TYPE.RESET_PASSWORD}
        />
      </Stack>
    </>
  );
};
