import { Stack, Typography } from '@mui/material';
import { TessellLogo } from './TessellLogo';

export default function LoginHeader() {
  return (
    <Stack direction="row" alignItems="center" p={3}>
      <Stack mr={1} ml={2}>
        <TessellLogo />
      </Stack>
      <Typography variant="h3" sx={{ color: '#fff', fontWeight: '500' }}>
        Tessell
      </Typography>
      <Typography variant="h3" sx={{ color: '#fff', fontWeight: '600' }}>
        OPS
      </Typography>
    </Stack>
  );
}
