import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { styles } from 'assets/scss/style-templates';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';
import { LabelValue } from 'customers/db-services-detailed-view/LabelValue';
import { useStyles } from 'common/utils';
import { tenantCardSummaryStyles } from 'customers/tenants-detailed-view/lib/TenantCardSummary/TenantCardSummaryStyles';
import { useVNSummary } from './useVNSummary';

type VNSummaryProps = {
  vnDetails: any;
  tenant: any;
};

export const VNSummary = (props: VNSummaryProps) => {
  const classes = useStyles();

  const { expanded, setExpanded, accordionHeaderProps, cardSummaryProps } =
    useVNSummary(props);

  const GetSummaryHeader = () => {
    if (expanded) {
      return <Typography sx={classes.boldText}>Summary</Typography>;
    }
    return accordionHeaderProps?.map((row, index) => (
      <Grid item xs={12} md={4} key={`vn_${index}`} spacing={3}>
        <LabelValue {...row} />
      </Grid>
    ));
  };

  return (
    <Box sx={styles.contentBox} mt={1}>
      <Accordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded((prev) => !prev)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={tenantCardSummaryStyles.headerBackground}
        >
          <Grid container direction="row" p={2}>
            {GetSummaryHeader()}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid p={2} container direction="row" spacing={3}>
            {cardSummaryProps?.map((row, index) => (
              <Grid item xs={12} md={6} xl={4} key={`vn_${index}`}>
                <ShowLabelValue {...row} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
