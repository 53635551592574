import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';

type EvaluateTenantNameProps = {
  tenant: TenantType;
};

export const evaluateTenantName = (props: EvaluateTenantNameProps) => {
  const { tenant } = props;
  return tenant?.type === 'PROFESSIONAL'
    ? tenant?.emailAddress || ''
    : tenant?.domain || '';
};
