import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { ControllerNodeTooltipStyles } from './styles';

export const ControllerNodeTooltip = () => {
  return (
    <Tooltip
      placement="right-start"
      arrow
      title={
        <Stack
          direction="column"
          spacing={0.5}
          sx={ControllerNodeTooltipStyles.TextContianer}
        >
          <Typography
            variant="body1"
            sx={ControllerNodeTooltipStyles.TooltipText}
          >
            All instances will also include access details for controller node
            of the service.
          </Typography>
        </Stack>
      }
      PopperProps={ControllerNodeTooltipStyles.PopperProps}
    >
      <Stack sx={ControllerNodeTooltipStyles.InfoIconContainer}>
        <InfoOutlined />
      </Stack>
    </Tooltip>
  );
};
