export const adaptDapResponse = (dapResponse) => {
  let count = 0;
  const regions = new Set();
  const users = new Set();

  if (dapResponse?.response?.length) {
    dapResponse?.response?.forEach((dap) => {
      count += 1;
      dap.cloudAvailability.forEach((_c) => {
        _c.regions?.forEach((reg) => regions.add(reg));
      });
      dap.sharedWith?.users?.forEach((u) => users.add(u));
    });
  }
  return { count, regions, users };
};
