/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/29/2021     bakul.banthia         Created
 *
 */

import { Box } from '@mui/material';
import {
  GCPIcon, OCIIcon,
} from 'assets/icons-v1';
// import { AzureIcon } from 'assets/icons-v2';
import { AWSIcon, AzureIcon } from 'assets/icons-v2';

export default function CloudLogo(props) {
  const { name, sxClass } = props;
  switch (name) {
    case 'AWS':
      return (
        <Box
          display="flex"
          justifyContent="center"
          sx={sxClass}
        >
          <AWSIcon />
        </Box>
      );
    case 'AZURE':
      return (
        <Box
          display="flex"
          justifyContent="center"
          sx={sxClass}
        >
          <AzureIcon />
        </Box>
      );
    case 'Google':
      return (
        <Box
          display="flex"
          justifyContent="center"
          sx={sxClass}
        >
          <GCPIcon />
        </Box>
      );
    case 'Oracle':
      return (
        <Box
          display="flex"
          justifyContent="center"
          sx={sxClass}
        >
          <OCIIcon />
        </Box>
      );
    default:
      return null;
  }
}
