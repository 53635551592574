import { Route, Routes } from 'react-router-dom';
import { TenantTabView } from '../TenantTabView';
import { Button, Stack, Typography } from '@mui/material';
import { ArrowBackRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import OtpModal from 'common/Modals/OtpModal';
import { AddCreditsModal } from 'common/Modals/AddCreditsModal';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';
import { useTenantDetails } from 'customers/tenants-detailed-view/lib/components/TenantDetails/useTenantDetails';
import { tenantDetailedViewStyles } from './styles';
import { useContext, useEffect } from 'react';
import { TenantCardSummary } from '../../TenantCardSummary/TenantCardSummary';
import { OTP_TYPE } from 'constants/otpType';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { HealthStatus } from 'customers/tenants-list-view/lib/components/HealthStatus';
import UpdateMetadata from 'common/UpdateMetadata/UpdateMetadata';
import TenantStatusLabel from 'customers/tenants-list-view/lib/components/TenantListView/TenantStatusLabel';
import { UPDATE_METADATA_ENTITY_TYPE } from 'common/UpdateMetadata/constants';
import { SilenceAlertsModal } from 'common/Modals/SilenceAlertsModal/SilenceAlertsModal';

export const TenantDetailedView = () => {
  document.title = 'Tenants | Tessell - Ops App';
  const subscriptionId = new URLSearchParams(window.location.search).get(
    'subscription-id',
  );
  const { hasPermission } = useContext(UserPermissionsContext);

  const {
    selectedTenant,
    step,
    credits,
    openOTP,
    openAddCreditsModal,
    creditDetails,
    ticketsResponse,
    addCreditsIsLoading,
    sendOTPIsLoading,
    tenantIsLoading,
    isCreditDetailsLoading,
    silenceAlertsOpen,
    navigate,
    fetchTenantByID,
    creditsPostData,
    setOpenOTP,
    setCredits,
    setOpenAddCreditsModal,
    onSubmit,
    handleToggleBillingSuccess,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    openGenieConfigUpdateModal,
    setOpenGenieConfigUpdateModal,
    onUpdateMetadataSuccess,
    setSilenceAlertsOpen,
    handleOnSuccess,
  } = useTenantDetails();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack className="page-content vh-100">
      <LoadingOverlay isLoading={!selectedTenant?._id}>
        <Stack px={2}>
          <Stack sx={tenantDetailedViewStyles.topActionBar}>
            <Stack sx={tenantDetailedViewStyles.backButtonContainer}>
              <Button
                onClick={(e) => {
                  e?.preventDefault();
                  navigate(-1);
                }}
                sx={tenantDetailedViewStyles.backButtonStyle}
              >
                <ArrowBackRounded />
              </Button>
              <Stack mx={3} direction="row">
                <Typography variant="h6">{`${selectedTenant?.domain?.toUpperCase()}`}</Typography>
                <Stack ml={1} pt={0.7}>
                  <HealthStatus health={selectedTenant?.health || {}} />
                </Stack>
                {selectedTenant?.tenantPhase && (
                  <TenantStatusLabel status={selectedTenant?.tenantPhase} />
                )}
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
              sx={tenantDetailedViewStyles.actionButtonsContainer}
            >
              {hasPermission(PERMISSIONS.GENIE) && (
                <LoadingButton
                  loading={tenantIsLoading}
                  onClick={() => {
                    setOpenGenieConfigUpdateModal(true);
                  }}
                  variant="contained"
                >
                  Update Genie Config
                </LoadingButton>
              )}
              {hasPermission(PERMISSIONS.UPDATE_TENANT) && (
                <LoadingButton
                  loading={tenantIsLoading}
                  onClick={() => {
                    setUpdateMetadataOpen(true);
                  }}
                  variant="contained"
                >
                  Update Metadata
                </LoadingButton>
              )}
              <LoadingButton
                loading={tenantIsLoading}
                onClick={() => {
                  fetchTenantByID();
                }}
                variant="contained"
              >
                Refresh
              </LoadingButton>
              {hasPermission(PERMISSIONS?.UPDATE_TENANT) && (
                <LoadingButton
                  disabled={
                    selectedTenant?.metadata?.billingInfo
                      ?.skipBillGeneration === true || creditDetails?.error
                  }
                  onClick={() => {
                    setOpenAddCreditsModal(true);
                  }}
                  variant="contained"
                >
                  Add Credits
                </LoadingButton>
              )}
              {hasPermission(PERMISSIONS.ALERTS_SILENCE) && (
                <Button
                  onClick={() => {
                    setSilenceAlertsOpen(true);
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                  sx={{ height: 'max-content' }}
                >
                  Silence Alert for this tenant
                </Button>
              )}
            </Stack>
          </Stack>

          <TenantCardSummary
            tenant={{
              ...selectedTenant,
              ...((hasPermission(PERMISSIONS.TICKET_VIEW) &&
                ticketsResponse?.response?.summary) ||
                {}),
            }}
            creditDetails={creditDetails}
            isCreditDetailsLoading={isCreditDetailsLoading}
            creditsPostData={creditsPostData}
            handleToggleBillingSuccess={handleToggleBillingSuccess}
          />

          <Routes>
            <Route
              path={':tabId/*'}
              element={
                !tenantIsLoading ? (
                  <TenantTabView
                    tenant={selectedTenant}
                    subscriptionId={subscriptionId}
                  />
                ) : (
                  <></>
                )
              }
            />
          </Routes>
          {hasPermission(PERMISSIONS.UPDATE_TENANT) && updateMetadataOpen && (
            <UpdateMetadata
              open={updateMetadataOpen}
              setOpen={setUpdateMetadataOpen}
              entityType="tenant"
              entityName={
                selectedTenant?.domain || selectedTenant?.emailAddress
              }
              tenantId={selectedTenant?._id}
              tenantDomain={selectedTenant?.pgSource}
              entityId={selectedTenant?._id}
              handleSuccess={onUpdateMetadataSuccess}
            />
          )}
          {hasPermission(PERMISSIONS.GENIE) && openGenieConfigUpdateModal && (
            <UpdateMetadata
              open={openGenieConfigUpdateModal}
              setOpen={setOpenGenieConfigUpdateModal}
              entityType={UPDATE_METADATA_ENTITY_TYPE.TENANT_GENIE_CONFIG}
              entityName={
                selectedTenant?.domain || selectedTenant?.emailAddress
              }
              tenantId={selectedTenant?._id}
              tenantDomain={selectedTenant?.pgSource}
              entityId={selectedTenant?._id}
              handleSuccess={() => fetchTenantByID()}
            />
          )}
          {hasPermission(PERMISSIONS.UPDATE_TENANT) && (
            <AddCreditsModal
              open={openAddCreditsModal}
              setOpen={setOpenAddCreditsModal}
              title={`Add Credits for ${selectedTenant?.domain?.toUpperCase()}`}
              subTitle={
                selectedTenant?.metadata?.infraInfo?.deploymentType ===
                'PROFESSIONAL'
                  ? selectedTenant?.emailAddress
                  : null
              }
              credits={credits}
              setCredits={setCredits}
              tenantId={selectedTenant?._id}
              tenantDomain={selectedTenant?.domain}
              onNextClick={() => {
                setOpenAddCreditsModal(false);
                setOpenOTP(true);
              }}
            />
          )}

          <OtpModal
            open={openOTP}
            setOpen={setOpenOTP}
            title={`Add additional credits for ${selectedTenant?.domain?.toUpperCase()}`}
            subTitle={
              selectedTenant?.metadata?.infraInfo?.deploymentType ===
              'PROFESSIONAL'
                ? selectedTenant?.emailAddress
                : null
            }
            onSubmit={(val) => {
              onSubmit(val);
            }}
            isSubmitting={step === 1 ? sendOTPIsLoading : addCreditsIsLoading}
            otpUser="Ops admin"
            step={step}
            reasonHeading="Enter reason for adding additional credits."
            otpType={OTP_TYPE.ADD_CREDIT}
          >
            <Stack sx={tenantDetailedViewStyles.otpDetailsFinalStep}>
              <ShowLabelValue label="Tenant Id" value={selectedTenant?._id} />
              <ShowLabelValue
                label="Tenant Domain"
                value={selectedTenant?.domain}
              />
              <ShowLabelValue
                label="Credits to be added"
                value={`$ ${credits}`}
              />
            </Stack>
          </OtpModal>
          {silenceAlertsOpen && (
            <SilenceAlertsModal
              open={silenceAlertsOpen}
              setOpen={setSilenceAlertsOpen}
              selectedAlertLabels={{
                tenant_id: selectedTenant?._id,
              }}
              hideInputs={true}
              handleOnSuccess={handleOnSuccess}
            />
          )}
        </Stack>
      </LoadingOverlay>
    </Stack>
  );
};
