import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { useMemo } from 'react';
import { GetEngineIcon } from '../../utils/GetEngineIcon';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import { useAmStyles } from 'customers/availability-machine-view/utils/useAmStyles';
import { useAvailabilityMachine } from './useAvailabilityMachine';
import { DapPopover } from './dap-popover/DapPopover';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';

interface AvailabilityMachinesTableProps {
  tenant?: TenantType;
  service?: DatabaseServiceType;
}

export const AvailabilityMachinesTable = (
  props: AvailabilityMachinesTableProps,
) => {
  const { tenant, service } = props;
  const classes = useAmStyles();
  const {
    regionMap,
    availabilityMachines,
    availabilityMachinesCount,
    handleRowClick,
    availabilityMachinesIsLoading,
    availabilityMachinesPageCount,
    page,
    handlePaginationChange,
  } = useAvailabilityMachine({ tenant, service });

  const avMachineCols = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        headerAlign: 'center',
        hide: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        renderCell: (params) => {
          const { row: data } = params;
          const { name, engineType } = data;
          return (
            <Stack direction="row" alignItems="center" mr={1}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <GetEngineIcon engine={engineType} />
                <Stack direction="column">
                  <OverflowTooltip
                    text={name}
                    sxClass={classes.boldText}
                    color="textPrimary"
                  />
                </Stack>
              </Stack>
            </Stack>
          );
        },
        width: 300,
      },
      {
        field: 'sla',
        headerName: 'RPO SLA',
        width: 200,
        renderCell: (params) => {
          const { row: data } = params;
          const { sla } = data;
          return (
            <>
              <Typography sx={classes.boldText} color="textPrimary">
                {sla}
              </Typography>
            </>
          );
        },
      },
      {
        field: 'daps',
        headerName: 'DAPs',
        width: 400,
        renderCell: (params) => {
          const { row } = params;
          return (
            <DapPopover
              tenantId={row?.tenantId}
              tenantPgSource={row?.pgSource}
              amId={row?.id}
            />
          );
        },
      },
      {
        field: 'dateCreated',
        headerName: 'Created',
        width: 280,
        renderCell: (params) => {
          const { row: data } = params;
          const { dateCreated, owner } = data;
          if (!dateCreated) {
            return 'NA';
          }
          const displayDate = moment(dateCreated).format('MMM D, YYYY, HH:mm');

          return (
            <>
              <Typography color="textPrimary">{displayDate}</Typography>
            </>
          );
        },
      },
    ],
    [regionMap],
  );
  return (
    <>
      <Stack sx={{ height: '100vh' }}>
        <DataGrid
          columns={avMachineCols}
          rows={availabilityMachines}
          getRowId={(row) => `${row?.id}`}
          disableSelectionOnClick
          components={{
            Toolbar: CustomDataGridToolbar,
            DetailPanelCollapseIcon: ExpandMore,
            DetailPanelExpandIcon: ChevronRight,
          }}
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          styleOverrides={{ hideShadow: true }}
          onRowClick={handleRowClick}
          loading={availabilityMachinesIsLoading}
          pageCount={availabilityMachinesPageCount}
          pageNo={page}
          totalCount={availabilityMachinesCount}
          handlePaginationChange={handlePaginationChange}
        />
      </Stack>
    </>
  );
};
