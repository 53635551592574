import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useLazyDeleteCallWithURL } from 'common/api/useApiCall';
import { GROUPS_BASE_URL } from 'constants/URL';
import { useContext, useEffect } from 'react';

type DeleteGroupProps = {
  onSuccess?: any;
};

export const useDeleteGroup = (props: DeleteGroupProps) => {
  const { onSuccess } = props;
  const {
    response: deleteGroupResponse,
    isLoading: deleteGroupIsLoading,
    postData: deleteGroupWithName,
  } = useLazyDeleteCallWithURL();

  const { addNotification } = useContext(NotificationContext);

  const deleteGroup = (name) => {
    deleteGroupWithName(`${GROUPS_BASE_URL}/${name}`);
  };

  useEffect(() => {
    if (deleteGroupResponse?.status === 'SUCCESSFUL') {
      addNotification({
        severity: 'success',
        message: `${deleteGroupResponse?.message}`,
      });
      onSuccess();
    } else if (deleteGroupResponse?.status) {
      addNotification({
        severity: 'error',
        message: deleteGroupResponse?.message || 'Something went wrong',
      });
    }
  }, [deleteGroupResponse]);

  return { deleteGroup, deleteGroupIsLoading };
};
