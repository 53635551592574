import { useLazyGetCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { useState, useEffect } from 'react';
import { dashboardResponseAdapter } from 'global-components/Dashboard/DashboardResponseAdapter';
import { DashboardChartType } from 'global-components/Dashboard/types';

export const useDashboard = () => {
  const [dataset, setDataset] = useState({} as DashboardChartType);
  const url = `${URLS.dashboardSummaryURL}`;

  const {
    response: { dashboardSummary, regionCountChartData },
    isLoading,
    postData: fetchDashboardSummary,
  } = useLazyGetCall(url, dashboardResponseAdapter);

  useEffect(() => {
    fetchDashboardSummary();
  }, []);

  useEffect(() => {
    if (dashboardSummary) {
      setDataset(dashboardSummary);
    }
  }, [dashboardSummary]);

  return {
    isLoading,
    dataset,
    regionCountChartData,
  };
};
