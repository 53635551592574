import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { evaluateTenantName } from 'common/TenantContainer/evaluateTenantName';
import {
  useLazyGetCallWithURL,
  useLazyPostCall,
  useLazyPutCall,
} from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { OTPRequestType } from 'constants/URLEnums';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { useContext, useEffect, useState } from 'react';

type useToggleBillingProps = {
  tenant: TenantType;
  handleToggleBillingSuccess?: any;
};

export const useToggleBilling = (props: useToggleBillingProps) => {
  const { tenant, handleToggleBillingSuccess } = props;
  const { _id, metadata, pgSource } = tenant;

  const currentBillingStatus =
    metadata?.billingInfo?.skipBillGeneration === false;

  const [openOTP, setOpenOTP] = useState(false);
  const [step, setStep] = useState(1);
  const [uuid, setUuid] = useState('');
  const { addNotification } = useContext(NotificationContext);

  const {
    response: sendOTPRes,
    isLoading: sendOTPIsLoading,
    postData: sendOTP,
  } = useLazyPostCall(`${URLS.otp}purpose=${OTPRequestType.BILLING_OTP}`);

  const {
    response: toggleBillingResponse,
    postData: toggleBillingPostData,
    isLoading: toggleBillingIsLoading,
    responseStatus: toggleBillingResponseStatus,
  } = useLazyPutCall(
    `${URLS.toggleBilling}tenant-id=${_id}&tenant-domain=${pgSource}`,
  );

  useEffect(() => {
    if (toggleBillingResponseStatus === 200 && toggleBillingResponse?.message) {
      addNotification({
        severity: 'success',
        message: `${toggleBillingResponse?.message}`,
      });
      handleToggleBillingSuccess();
      setOpenOTP(false);
      setStep(1);
    } else if (
      toggleBillingResponseStatus &&
      toggleBillingResponseStatus !== 200
    ) {
      addNotification({
        severity: 'error',
        message: 'Something went wrong, please try again.',
      });
    }
  }, [toggleBillingResponse]);

  const sendOTPCall = ({ comment, jiraId }) => {
    const payload = {
      'tenant-id': _id,
      comment: comment,
      jiraId: jiraId,
      'tenant-domain': pgSource,
      'new-billing-status': !currentBillingStatus,
      'old-billing-status': currentBillingStatus,
      'tenant-name': evaluateTenantName({
        tenant: tenant,
      }),
    };
    sendOTP(JSON.stringify(payload, null));
  };

  useEffect(() => {
    if (sendOTPRes?.uuid) {
      setUuid(sendOTPRes?.uuid);
      setStep(2);
    }
  }, [sendOTPRes]);

  const onSubmit = (val) => {
    if (step === 1) {
      sendOTPCall({
        comment: val?.reason,
        jiraId: val?.jiraId,
      });
    } else if (step === 2) {
      const payload = {
        otp: val,
        'request-id': uuid,
        'billing-status': (!currentBillingStatus).toString(),
      };
      toggleBillingPostData(JSON.stringify(payload, null, 2));
    }
  };

  return {
    openOTP,
    setOpenOTP,
    step,
    setStep,
    uuid,
    setUuid,
    onSubmit,
    sendOTPIsLoading,
    toggleBillingIsLoading,
  };
};
