import { useMemo } from 'react';
import { Box, Stack, Popover, Button, Link, Typography } from '@mui/material';
import { Add, Edit, FileCopyOutlined } from '@mui/icons-material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import moment from 'moment';
import { DF_HUMAN, DF_HUMAN_WITHOUT_TIME } from 'helpers/dateFormats';
import { useLeaks } from './useLeaks';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import {
  StatusBackgroundColor,
  StatusTextColor,
} from 'constants/statusConfigs';
import { ShowAttr } from 'customers/db-services-detailed-view/components/ServiceOverview';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isArray } from 'lodash';
import { statusFilter } from '../Exceptions/constants';
import { useStyles } from 'customers/db-services-detailed-view/utils';
import { ExceptionStatusUpdateModal } from '../Exceptions/UpdateModals/ExceptionStatusUpdateModal';
import { TicketFormModal } from 'customers/ticket/TicketFormModal';
import { DEFAULT_NULL, JIRA_BASE_LINK } from 'constants/appConstants';
import { TenantPhaseFilter } from 'customers/tenants-list-view/constants/tenantFilterConstants';
import { DownloadIcon } from 'assets/icons-v2';
import { leaksShowAttrStyles } from './styles';
import { LeaksInternalStatusOptions } from './constants';
import DatePickerToggler from 'common/custom-components/lib/components/DatePickerToggler';
import { COST_MAX_RANGE, CostRangeType } from 'customers/cost/constants';

const Leaks = () => {
  const {
    page,
    Leaks,
    LeaksCount,
    typeFilter,
    filters,
    tenants,
    leaksIsLoading,
    pageCount,
    detailsAnchorEl,
    selectedData,
    selectedLeak,
    openLeakStatusUpdateModal,
    showCreateTicketModal,
    createTicketProps,
    startTime,
    endTime,
    range,
    handleDateToggle,
    updateTimeUsingNativePicker,
    createTicket,
    setSelectedLeak,
    setOpenLeakStatusUpdateModal,
    setShowCreateTicketModal,
    handleFilterChange,
    setSelectedData,
    setDetailsAnchorEl,
    handlePaginationChange,
    handleOnSuccessStatusUpdate,
    handleResetFilters,
    downloadLeaks,
  } = useLeaks();

  const classes = useStyles();

  const actionProps = useMemo(
    () => [
      {
        startIcon: <DownloadIcon />,
        label: 'Download Report',
        onClick: (e) => {
          downloadLeaks();
        },
      },
    ],
    [filters],
  );

  const filterProps = useMemo(
    () =>
      [
        {
          name: 'Tenant Phase',
          initialVal: filters?.['tenant-phase'] || 'All',
          options: TenantPhaseFilter?.map((_p) => {
            return { name: _p.name, id: _p.value };
          }),
          onChange: (value) => handleFilterChange('tenant-phase', value),
        },
        {
          name: 'Type',
          initialVal: filters?.type || 'All',
          options: typeFilter,
          onChange: (value) => handleFilterChange('type', value),
        },
        {
          name: 'Status',
          initialVal: filters?.status || 'All',
          options: statusFilter,
          onChange: (value) => handleFilterChange('status', value),
        },
        {
          name: 'Tenant',
          initialVal: filters?.['tenant-name'] || 'All',
          options: tenants || [],
          type: 'multipleSelect',
          onChange: (value) => handleFilterChange('tenant-name', value),
        },
        {
          name: 'Internal Status',
          initialVal: filters?.['leak-status'] || 'All',
          options: LeaksInternalStatusOptions,
          onChange: (value) => handleFilterChange('leak-status', value),
        },
      ].filter(Boolean),
    [filters, typeFilter, tenants],
  );

  const menuItems = (_leak) => {
    return [
      {
        label: 'Update Status',
        icon: <Edit sx={classes.icon} />,
        onClick: () => {
          setSelectedLeak(_leak);
          setOpenLeakStatusUpdateModal(true);
        },
        disabled: () => {
          return _leak?.exceptionId === '';
        },
      },
      {
        label: 'Create Jira',
        icon: <Add sx={classes.icon} />,
        onClick: () => {
          setSelectedLeak(_leak);
          setShowCreateTicketModal(true);
        },
        disabled: () => {
          return _leak?.exceptionId === '' || _leak?.jiraId;
        },
      },
    ];
  };

  const dbColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'exception',
        headerName: 'Cloud Resource Name',
        width: 200,
        renderCell: (params) => {
          const { row: data } = params;
          const { exception, data: leakData } = data;
          return (
            <Stack direction="row" alignItems="flex-start">
              <OverflowTooltip
                text={leakData?.cloudResourceName}
                variant="body"
              />
            </Stack>
          );
        },
      },
      {
        field: 'exceptionType',
        headerName: 'Type',
        width: 100,
      },
      {
        field: 'reason',
        headerName: 'Reason',
        width: 100,
        renderCell: (params) => {
          const { row: data } = params;
          const { reason } = data;
          return (
            <Stack direction="row" alignItems="flex-start">
              <OverflowTooltip text={reason} variant="body" />
            </Stack>
          );
        },
      },
      {
        field: 'tenant',
        headerName: 'Tenant',
        width: 100,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
        renderCell: (params) => {
          const { row: data } = params;
          const { status } = data;
          return (
            <Stack direction="row" alignItems="flex-start">
              <StatusLabel
                backgroundColor={StatusBackgroundColor[status]}
                textColor={StatusTextColor[status]}
                text={status || 'NA'}
              />
            </Stack>
          );
        },
      },
      {
        field: 'internalStatus',
        headerName: 'Internal Status',
        width: 100,
        renderCell: (params) => {
          const { row } = params;
          const { data } = row;
          const status = data?.leakStatus;
          return (
            <Stack direction="row" alignItems="flex-start">
              <StatusLabel text={status || 'NA'} />
            </Stack>
          );
        },
      },

      {
        field: 'dateCreated',
        headerName: 'Created',
        width: 150,
        renderCell: (params) => {
          const { row: data } = params;
          const { dateCreated: dateCreatedFromResponse } = data;
          const dateCreated = moment(dateCreatedFromResponse).format(DF_HUMAN);
          if (!dateCreated) {
            return '';
          }
          return <Box>{dateCreated}</Box>;
        },
      },
      {
        field: 'dateModified',
        headerName: 'Modified',
        width: 150,
        renderCell: (params) => {
          const { row: data } = params;
          const { dateModified: dateModifiedFromResponse } = data;
          const dateModified = moment(dateModifiedFromResponse).format(
            DF_HUMAN,
          );
          if (!dateModified) {
            return '';
          }
          return <Box>{dateModified}</Box>;
        },
      },
      {
        field: 'Jira',
        headerName: 'Jira',
        width: 50,
        renderCell: (params) => {
          const { row } = params;

          return row?.jiraId ? (
            <Link href={`${JIRA_BASE_LINK}${row?.jiraId}`} target="_blank">
              {row?.jiraId}
            </Link>
          ) : (
            '-'
          );
        },
      },
      {
        field: 'data',
        headerName: 'Details',
        width: 150,
        renderCell: (params) => {
          const { row } = params;
          const { data } = row;
          if (!data) {
            return '';
          }
          return (
            <Button
              color="primary"
              component="button"
              sx={{ alignSelf: 'flex-start', padding: '0px' }}
              onClick={(e) => {
                setDetailsAnchorEl(e.currentTarget);
                setSelectedData(data);
              }}
            >
              See Details
            </Button>
          );
        },
      },
    ],
    [],
  );
  const AssociatedDB = 'associatedWithDBServices';
  //TODO: Move ShowAttr to common components

  const GetDetails = (data) => (
    <Stack p={1}>
      {data &&
        Object.keys(data)?.map((key, i) => {
          if (Array.isArray(data[key])) {
            return (
              <>
                {data[key].map((obj) =>
                  Object.keys(obj)?.map((k) => {
                    return ShowAttr(
                      `${
                        key === AssociatedDB ? `Associated DB ${i + 1}` : key
                      }: ${k}`,
                      obj[k],
                      null,
                      null,
                      null,
                      obj[k]?.length ? (
                        <CopyToClipboard text={obj[k]}>
                          <Button
                            color="primary"
                            startIcon={<FileCopyOutlined />}
                          />
                        </CopyToClipboard>
                      ) : null,
                      'compact',
                      leaksShowAttrStyles,
                    );
                  }),
                )}
              </>
            );
          } else {
            return typeof data[key] !== 'object' ? (
              <>
                {ShowAttr(
                  key,
                  data[key] || DEFAULT_NULL,
                  null,
                  null,
                  null,
                  data[key]?.length ? (
                    <CopyToClipboard text={data[key]}>
                      <Button
                        color="primary"
                        startIcon={<FileCopyOutlined />}
                      />
                    </CopyToClipboard>
                  ) : null,
                  'compact',
                  leaksShowAttrStyles,
                )}
              </>
            ) : (
              !isArray(data[key]) &&
                data[key] &&
                Object.keys(data[key])?.map((k) => (
                  <>
                    {ShowAttr(
                      `${key}: ${k}`,
                      data[key][k],
                      null,
                      null,
                      null,
                      data[key][k]?.length ? (
                        <CopyToClipboard text={data[key][k]}>
                          <Button
                            color="primary"
                            startIcon={<FileCopyOutlined />}
                          />
                        </CopyToClipboard>
                      ) : null,
                      'compact',
                      leaksShowAttrStyles,
                    )}
                  </>
                ))
            );
          }
        })}
    </Stack>
  );

  return (
    <Stack sx={{ height: '100vh' }}>
      <Stack direction="row" spacing={2} sx={{ padding: '10px' }}>
        <DatePickerToggler
          rangeType={CostRangeType}
          startTime={startTime}
          endTime={endTime}
          updateTimeUsingNativePicker={updateTimeUsingNativePicker}
          handleOnChange={handleDateToggle}
          range={range}
          customDateFormat={DF_HUMAN_WITHOUT_TIME}
          showTimePicker={false}
          maxSpanInDays={COST_MAX_RANGE}
        />
      </Stack>
      <DataGrid
        columns={dbColumns}
        rows={Leaks}
        loading={leaksIsLoading}
        filters={filterProps}
        menuItems={menuItems}
        actions={actionProps}
        styleOverrides={{ hideShadow: true }}
        pageCount={pageCount}
        pageNo={page}
        handlePaginationChange={handlePaginationChange}
        totalCount={LeaksCount}
        onResetFilters={handleResetFilters}
      />
      <Popover
        open={Boolean(detailsAnchorEl)}
        anchorEl={detailsAnchorEl}
        onClose={() => setDetailsAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {selectedData && GetDetails(selectedData)}
      </Popover>
      <ExceptionStatusUpdateModal
        show={openLeakStatusUpdateModal}
        setShow={setOpenLeakStatusUpdateModal}
        exception={selectedLeak}
        handleOnSuccess={handleOnSuccessStatusUpdate}
        initialStatus={selectedLeak?.status || ''}
      />
      <TicketFormModal
        show={showCreateTicketModal}
        onClose={() => setShowCreateTicketModal(false)}
        {...createTicketProps}
        onSubmit={createTicket}
        showDueDate={false}
      />
    </Stack>
  );
};

export default Leaks;
