/* eslint-disable no-nested-ternary */

import { useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { AdaptedMetricType, ChartProps } from '../monitoringTypes';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import _merge from 'lodash/merge';
import { Stack, Typography } from '@mui/material';
import { FullscreenRounded } from '@mui/icons-material';
import { FullScreenChart } from '../../../../common/charts/FullScreenChart';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import { DF_HUMAN } from 'helpers/dateFormats';
import {
  fontStyles,
  graphColours,
  monitoringCustomStyles,
  styles,
} from '../monitoringCustomStyles';

export const getYAxisRange = (
  visibleSeries: boolean[],
  seriesList: ApexAxisChartSeries,
  chartOptions: ApexOptions,
) => {
  let minNum = 0;
  let maxNum = 0;
  const xMin = chartOptions?.xaxis?.min;
  const xMax = chartOptions?.xaxis?.max;
  seriesList?.forEach((singleSeries, index) => {
    singleSeries?.data?.forEach((data) => {
      if (visibleSeries?.[index] && data?.x <= xMax && xMin <= data?.x) {
        minNum = Math.min(minNum, data?.y);
        maxNum = Math.max(maxNum, data?.y);
      }
    });
  });
  return { min: minNum, max: maxNum };
};

export const countDecimals = (value: number) => {
  if (value % 1 !== 0) return value.toString().split('.')[1].length;
  return 0;
};

export const ZoomableLineChart = (props: ChartProps) => {
  const {
    showUnit = true,
    chart,
    chartOptions,
    showFullScreenToolbar = true,
  } = props;
  const {
    title: name,
    dataSets,
    minVal,
    maxVal,
    fill,
    unit,
  } = chart || {
    title: 'This is a Placeholder chart',
    dataSets: [
      {
        labelSet: [1, 2, 3],
        dataSet: [4, 5, 6],
        minVal: 4,
        maxVal: 6,
        displayName: 'Placeholder Line',
      },
    ],
    minVal: 1,
    maxVal: 10,
    unit: 'Unit',
  };

  const [visibleSeries, setVisibleSeries] = useState<boolean[]>(
    dataSets?.map(() => true),
  );

  const updateVisibleSeries = (index) => {
    setVisibleSeries((visibleSeries) => {
      const newVisibleSeries = [...visibleSeries];
      newVisibleSeries[index] = !visibleSeries[index];
      return newVisibleSeries;
    });
  };

  const series: ApexAxisChartSeries = useMemo(
    () =>
      dataSets?.map((dataset) => {
        const newData = dataset?.labelSet?.map?.((value, index) => ({
          x: value,
          y: dataset?.dataSet[index],
        }));

        return { name: dataset?.displayName, data: newData };
      }) || [],
    [dataSets],
  );

  const yAxisRange = useMemo(
    () => getYAxisRange(visibleSeries, series, chartOptions),
    [visibleSeries, series, chartOptions],
  );

  let dpCount = 0;
  dataSets?.forEach((dataset) => {
    dpCount += dataset?.labelSet?.length;
  });

  const isPercentageUnit =
    unit?.toLowerCase() === 'percent' || unit?.toLowerCase() === '%';

  const yMin = yAxisRange.min;
  const yMax = isPercentageUnit
    ? 100
    : yAxisRange.max && !!dpCount
    ? yAxisRange.max
    : 5;

  const [fullScreenChart, setFullScreenChart] =
    useState<AdaptedMetricType>(null);
  const memoChart = useMemo(() => {
    const options: ApexOptions = {
      chart: {
        type: 'line',
        stacked: false,
        width: 640,
        height: 400,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        events: {
          legendClick: (chart: any, seriesIndex: number, options: any) => {
            updateVisibleSeries(seriesIndex);
          },
        },
        toolbar: {
          show: true,
          autoSelected: 'zoom',
          offsetY: -85,
          tools: {
            download: true,
            selection: false,
            pan: true,
            zoomin: false,
            zoomout: false,
            zoom: true,
            reset: true,
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp: number) {
                return new Date(timestamp).toDateString();
              },
            },
          },
        },
        animations: {
          enabled: false,
          easing: 'easeinout',
          speed: 0,
          animateGradually: {
            enabled: false,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 300,
          },
        },
      },

      stroke: {
        show: true,
        curve: 'straight',
        width: 2,
      },
      markers: {
        size: 0,
      },
      colors: graphColours,
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        forceNiceScale: !isPercentageUnit,
        showAlways: true,
        showForNullSeries: true,
        tickAmount: 5,
        labels: {
          show: true,
          formatter: (val) => {
            const formatVal = (labelText) => {
              if (yMax < 1) {
                return val.toFixed(Math.min(countDecimals(val), 3));
              }
              return val.toFixed(1);
            };
            return Number.isInteger(val) ? val.toString() : formatVal(val);
          },
          style: {
            colors: '#979797',
          },
        },

        min: yMin,
        max: yMax,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        crosshairs: {
          show: true,
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: true,
          hideOverlappingLabels: true,
          style: {
            colors: '#979797',
          },
          datetimeUTC: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
            minute: 'HH:mm',
          },
        },
        tickAmount: 5,
      },

      legend: {
        showForSingleSeries: true,
        onItemClick: {
          toggleDataSeries: true,
        },
        position: 'bottom',
        horizontalAlign: 'left',
        markers: {
          offsetX: -5,
          offsetY: 2,
        },
      },
      tooltip: {
        shared: true,
        followCursor: true,

        y: {
          formatter: (val) => val?.toFixed?.(3),
        },
        x: {
          formatter: (value) => moment(value)?.format?.(DF_HUMAN),
        },
      },
    };

    const mergedOptions = _merge(options, chartOptions);
    return (
      <Stack sx={monitoringCustomStyles.ZoomableLineChart.memoChart}>
        {showUnit && (
          <Stack
            sx={{
              justifyContent: 'center',
              width: '20px',
              textWrap: 'nowrap',
              ml: 1,
              mr: 0.5,
            }}
          >
            <Typography
              sx={{
                transform: `rotate(-90deg)`,
                fontSize: '12px',
                fontWeight: 500,
                color: '#979797',
                whiteSpace: 'nowrap',
              }}
            >
              {unit}
            </Typography>
          </Stack>
        )}

        <Stack sx={{ flex: '1 1 auto' }} justifyContent={'space-between'}>
          <Stack>
            <Typography
              sx={{
                ...fontStyles.fs16_fw600,
                color: styles.color.textBoxLabel,
              }}
            >
              {name}
            </Typography>
          </Stack>
          {series?.length < 4 && (
            <Stack sx={{ flexDirection: 'row', width: '100%', pt: '12px' }}>
              {series?.map((legend) => (
                <Stack sx={{ mr: 2, width: '120px' }}>
                  <OverflowTooltip
                    text={`${legend?.name} (latest)`}
                    variant="body"
                  />
                  <Typography
                    sx={{
                      ...fontStyles.fs18_fw600,
                      color: styles.color.textBoxLabel,
                    }}
                  >
                    {(
                      legend?.data?.[legend?.data?.length - 1] as any
                    )?.y?.toFixed(2) || '-'}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}
          <ReactApexChart
            dir="ltr"
            options={mergedOptions}
            series={series}
            type="line"
            height={chartOptions?.chart?.height || '350'}
            className="apex-charts"
          />
        </Stack>
      </Stack>
    );
  }, [series, chartOptions, yMin, yMax, name, unit]);

  return (
    <>
      {showFullScreenToolbar && (
        <Stack
          sx={
            monitoringCustomStyles.ZoomableLineChart.showFullScreenChartButton
          }
          onClick={(ev) => {
            ev?.stopPropagation();
            setFullScreenChart(chart);
          }}
        >
          <FullscreenRounded
            sx={monitoringCustomStyles.ZoomableLineChart.fullScreenRounded}
          />
        </Stack>
      )}
      {memoChart}
      {fullScreenChart && (
        <FullScreenChart
          fullScreenChart={fullScreenChart}
          setFullScreenChart={setFullScreenChart}
          chartOptions={chartOptions}
        />
      )}
    </>
  );
};
