export const tenantByIdResponseAdapter = (response) => {
  const metadata = response?.response?.[0]?.metadata || {};

  return response?.response?.[0]
    ? {
        ...response?.response?.[0],
        POCOwnersList: metadata?.opsMetadata?.tenantPocOwnerEmails || [],
        CustomerContactEmailsList:
          metadata?.opsMetadata?.tenantCustomerEmailContacts || [],
      }
    : {};
};
