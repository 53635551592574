import { Stack } from '@mui/material';
import { colorsList } from 'global-components/Dashboard/Charts/Constants';
import ReactApexChart from 'react-apexcharts';
import { useTenantCPDPCost } from './PlaneWiseCost/useTenantCPDPCost';

export type individualTenantCPDPCostObject = {
  data: [object];
  serviceType: string;
  totalCost: string;
};

type TenantCostChartProps = {
  tenantCost: [individualTenantCPDPCostObject];
};

export const TenantCostChart = (props: TenantCostChartProps) => {
  const { tenantCost } = props;

  const { keys, series } = useTenantCPDPCost({ tenantCost });
  return (
    <Stack width={'1000px'}>
      <ReactApexChart
        options={{
          states: {
            active: {
              filter: {
                type: 'none',
              },
            },
          },
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
          },
          colors: [...colorsList],
          tooltip: {
            y: {
              formatter: function (val) {
                return ` $${val}`;
              },
            },
          },
          xaxis: {
            categories: keys || [],
            labels: {
              formatter(val) {
                return val;
              },
            },
            offsetX: 10,
            offsetY: 10,
          },
          yaxis: {
            title: {
              text: 'Cost in $',
              style: {
                fontSize: '14px',
              },
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            horizontalAlign: 'right',
          },
        }}
        series={[...series]}
        type="line"
      />
    </Stack>
  );
};
