export const styles = {
  body: {
    flexDirection: 'column',
    px: 4,
    py: 10,
    height: '100%',
  },
  title: {
    my: 3,
    flexDirection: 'column',
    width: '100%',
    spacing: 0.5,
    alignItems: 'flex-start',
  },
  titleContainer: {
    direction: 'row',
    alignContent: 'flex-start',
    width: '50%',
  },
};
