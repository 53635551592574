import { useTheme } from '@mui/material/styles';
import { styles } from 'assets/scss/style-templates';

export type IconPropType = {
  width?: number;
  height?: number;
  minWidth?: number;
  mr?: number;
  ml?: number;
  mt?: number;
  mb?: number;
  my?: number;
  mx?: number;
  className?: string;
  style?: any;
  darkBg?: boolean;
  isTabIcon?: boolean;
  enabledTabIcon?: boolean;
  monotone?: boolean;
  zoom?: number;
  color?: string;
};

export function useStyles(props: IconPropType) {
  const theme = useTheme();
  const {
    darkBg = true,
    monotone = false,
    style,
    width,
    height,
    minWidth,
    mt,
    mb,
    mr,
    ml,
    mx,
    my,
    zoom,
    color = null,
    isTabIcon,
    enabledTabIcon,
  } = props;

  const iconStyle: any = {};

  if (width) {
    iconStyle.width = `${width}px`;
  }
  if (height) {
    iconStyle.height = `${height}px`;
  }
  if (minWidth) {
    iconStyle.minWidth = `${minWidth}px`;
  }
  if (mt || my) {
    iconStyle.marginTop = theme.spacing(mt || my || 0);
  }
  if (mb || my) {
    iconStyle.marginBottom = theme.spacing(mb || my || 0);
  }
  if (mr || mx) {
    iconStyle.marginRight = theme.spacing(mr || mx || 0);
  }
  if (ml || mx) {
    iconStyle.marginLeft = theme.spacing(ml || mx || 0);
  }
  if (darkBg) {
    iconStyle['--icon-color'] = '#FFF';
  } else {
    iconStyle['--icon-color'] = styles.color.darkShade;
  }
  if (monotone) {
    iconStyle['--icon-color'] = '#979797';
  }
  if (color) {
    iconStyle['--icon-color'] = color;
  }
  if (zoom) {
    iconStyle.zoom = zoom;
  }

  if (isTabIcon && !enabledTabIcon) {
    iconStyle['--icon-color'] = styles.color.textBoxLabel;
  }

  return {
    ...iconStyle,
    ...style,
  };
}
