import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@mui/material';
import { CloudIcon, SLAIcon, UsersIcon } from 'assets/icons-v2';
import { useStyles } from '../../utils/useStyles';
import { ExpandMore } from '@mui/icons-material';
import { styles } from 'assets/scss/style-templates';
import { AMSummaryContent } from './AMSummaryContent';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { useAMSummary } from './useAMSummary';

interface AvMachineSummaryProps {
  avMachine: any;
  regionMap: any;
  service: DatabaseServiceType;
}

export const AvMachineSummary = (props: AvMachineSummaryProps) => {
  const { avMachine, regionMap, service } = props;
  const classes = useStyles();
  const {
    metadata,
    subscriptionId,
    userId: owner,
    _id: amId,
    tenantId,
    pgSource,
  } = avMachine?.availabilityMachine || {};
  const { backupSla: sla } = metadata?.backupConfiguration || {};
  const { status: dataIngestionStatus, infrastructureInfo } = service;
  const { cloud } = infrastructureInfo?.userView || {};

  const { expanded, setExpanded, slaTimingResponse, slaTimingIsLoading } =
    useAMSummary({
      amId: amId,
      pgSource: pgSource,
    });

  function GetSummaryHeader() {
    if (expanded) {
      return <Typography sx={classes.boldText}>Summary</Typography>;
    }
    return (
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item sm={12} md={6} lg={4} alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <SLAIcon style={{ marginTop: 6 }} width={24} darkBg={false} />
            </Grid>
            <Grid item>
              <Typography pl={1} sx={classes.boldText}>
                RPO SLA:
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ paddingLeft: '16px' }}>{sla}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} lg={4} alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <CloudIcon style={{ marginTop: 6 }} width={30} darkBg={false} />
            </Grid>
            <Grid item>
              <Typography ml={1} sx={classes.boldText}>
                Cloud:
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ paddingLeft: '16px' }}>
                {cloud?.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} lg={4} alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <UsersIcon style={{ marginTop: 6 }} width={30} darkBg={false} />
            </Grid>
            <Grid item>
              <Typography ml={1} sx={classes.boldText}>
                Owner:
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ paddingLeft: '16px' }}>{owner}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box sx={styles.contentBox} mt={1}>
      <Accordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded((prev) => !prev)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          {GetSummaryHeader()}
        </AccordionSummary>
        <AccordionDetails>
          <AMSummaryContent
            sla={sla}
            cloud={cloud}
            dataIngestionStatus={dataIngestionStatus}
            regionMap={regionMap}
            owner={owner}
            entityId={amId}
            tenantId={tenantId}
            subscriptionId={subscriptionId}
            snapshotTime={slaTimingResponse}
            snapshotTimeIsLoading={slaTimingIsLoading}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
