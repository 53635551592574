import Cache from 'common/cache/lib/utils/Cache';
import { ENV, ENV_VARIABLES } from 'constants/env';

const DEFAULT_EVENT_FUNCTION_MAP = {
  close: (event) => {
    console.log('Websocket connection closed');
  },
};
const heartbeat = (socket) => {
  if (!socket) return;
  setTimeout(() => heartbeat(socket), 9 * 60 * 100);
  if (socket.readyState !== 1) window.location.reload();
  socket.send('ping');
};

const WSConnect = (
  url: string = `${ENV_VARIABLES[ENV]?.WS_URL}?user-id=${Cache.get(
    'userName',
  )}`,
  onMessageFunction,
) => {
  if (!url?.length || !url?.includes('user')) return;
  const eventsFunctionMap = DEFAULT_EVENT_FUNCTION_MAP;
  const ws = new WebSocket(url);
  Object.keys(eventsFunctionMap)?.map((ev) =>
    ws.addEventListener(ev, eventsFunctionMap[ev]),
  );
  ws.addEventListener('open', () => {
    console.log('Websocket connection opened');
    heartbeat(ws);
  });
  ws.onmessage = onMessageFunction;
  return ws;
};

export default WSConnect;
