/* eslint-disable max-len */
import React, { useState } from 'react';
import { Button, DialogContentText, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useStyles from '../useStyles';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import TessellInput from 'common/custom-components/lib/components/TessellInput';

type ConfirmationDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
  handleConfirm: (comment?: string) => void;
  confirmContent?: any;
  title?: string;
  cancelBtnText?: string;
  confirmBtnText?: string;
  isLoading?: boolean;
  isCommentRequired?: boolean;
};

export default function ConfirmationDialog({
  open,
  setOpen,
  onClose,
  handleConfirm,
  confirmContent,
  title,
  cancelBtnText,
  confirmBtnText,
  isLoading,
  isCommentRequired = false,
}: ConfirmationDialogProps) {
  const classes = useStyles();

  const [comment, setComment] = useState('');

  const DialogTitle = () => <>{title || 'Confirmation'}</>;

  const DialogContent = () => (
    <DialogContentText id="alert-dialog-description">
      {confirmContent || 'Are you sure you want to confirm?'}

      {isCommentRequired ? (
        <Stack>
          <TessellInput
            label="Reason"
            variant="outlined"
            size="small"
            name="reason"
            onChange={(e) => {
              setComment(e.target.value?.trim());
            }}
            rows={3}
          />
        </Stack>
      ) : (
        ''
      )}
    </DialogContentText>
  );

  const DialogActions = () => (
    <Stack width="100%" direction="row" justifyContent="flex-start" mt={2}>
      <Stack spacing={1} direction="row" justifyContent="flex-end" width="100%">
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
          size="small"
          sx={classes.roundedBtn}
        >
          {cancelBtnText || 'Cancel'}
        </Button>
        <LoadingButton
          onClick={() => {
            isCommentRequired ? handleConfirm(comment) : handleConfirm();
          }}
          color="primary"
          size="small"
          variant="contained"
          loading={isLoading}
          disabled={isCommentRequired ? !Boolean(comment) : false}
        >
          {confirmBtnText || 'Confirm'}
        </LoadingButton>
      </Stack>
    </Stack>
  );

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={() => (onClose ? onClose() : setOpen(false))}
    />
  );
}
