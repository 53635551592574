import clsx from 'clsx';
import { useStyles } from './utils';

export default function CrossIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="8.5" fill="#EC373C" stroke="#EC373C"/>
      <path d="M12.5971 6.20857C12.8196 5.98608 12.8196 5.62535 12.5971 5.40286C12.3747 5.18037 12.0139 5.18037 11.7914 5.40286L9 8.19429L6.20857 5.40286C5.98608 5.18037 5.62535 5.18037 5.40286 5.40286C5.18037 5.62535 5.18037 5.98608 5.40286 6.20857L8.19429 9L5.40286 11.7914C5.18036 12.0139 5.18037 12.3747 5.40286 12.5971C5.62535 12.8196 5.98608 12.8196 6.20857 12.5971L9 9.80571L11.7914 12.5971C12.0139 12.8196 12.3747 12.8196 12.5971 12.5971C12.8196 12.3747 12.8196 12.0139 12.5971 11.7914L9.80571 9L12.5971 6.20857Z" fill="white"/>
    </svg>
  );
}
