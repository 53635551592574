import { styles } from 'assets/scss/style-templates';

export const infraDetailsCardStyles = {
  cardHeader: { backgroundColor: 'rgba(30, 126, 161, 0.133)' },
  card: {
    p: 3,
    width: { lg: '50%' },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: styles.color.black,
  },
  subHeading: {
    mb: 2,
    fontSize: '24px',
    fontWeight: '700',
    color: styles.color.monotoneDarkDark,
  },
};
