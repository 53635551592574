import { useMemo } from 'react';
import { Stack, Box, Typography, Link } from '@mui/material';
import moment from 'moment';
import { JIRA_BASE_LINK } from 'constants/appConstants';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import { GetInstanceStatus } from 'customers/db-services-detailed-view/utils';
import { DF_HUMAN } from 'helpers/dateFormats';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { AUDIT_LOGS_STATUS_LIST } from './constants';
import { useAuditLogs } from './useAuditLogs';
import { DownloadIcon } from 'assets/icons-v2';

export default function AuditLogs() {
  const {
    page,
    filters,
    auditLogsIsLoading,
    auditLogsCount,
    pageCount,
    auditLogs,
    users,
    types,
    tenants,
    reasons,
    handlePaginationChange,
    handleResetFilters,
    handleFilterChange,
    downloadData,
  } = useAuditLogs();

  const filterProps = useMemo(
    () => [
      {
        name: 'Status',
        column: 'status',
        initialVal: filters?.status || [],
        options: AUDIT_LOGS_STATUS_LIST,
        onChange: (value) => handleFilterChange('status', value),
        type: 'multipleSelect',
      },
      {
        name: 'Type',
        column: 'type',
        initialVal: filters?.type || [],
        options: types,
        onChange: (value) => handleFilterChange('type', value),
        type: 'multipleSelect',
      },
      {
        name: 'User',
        column: 'requestor',
        initialVal: filters?.['requestor-email'] || [],
        options: users,
        onChange: (value) => handleFilterChange('requestor-email', value),
        type: 'multipleSelect',
      },
      {
        name: 'Tenant',
        column: 'tenant',
        initialVal: filters?.['tenant-name'] || [],
        options: tenants,
        onChange: (value) => handleFilterChange('tenant-name', value),
        type: 'multipleSelect',
      },
      {
        startDateAndTime: filters?.['start-time'],
        endDateAndTime: filters?.['end-time'],
        onChange: handleFilterChange,
        type: 'dateAndTime',
      },
      {
        name: 'Reason',
        column: 'reason',
        initialVal: filters?.reason || [],
        options: reasons,
        onChange: (value) => handleFilterChange('reason', value),
        type: 'multipleSelect',
      },
    ],
    [filters, users, tenants],
  );
  const actionProps = useMemo(
    () => [
      {
        startIcon: <DownloadIcon />,
        label: 'Download Report',
        onClick: (e) => {
          downloadData(filters);
        },
      },
    ],
    [filters],
  );

  const dbColumns = useMemo(
    () => [
      {
        field: '_id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'data',
        headerName: 'Reason',
        width: 200,
        renderCell: (params) => {
          const { row: data } = params;
          const { reason } = data;
          return (
            <Stack direction="row" alignContent="center">
              <Typography
                sx={{
                  ml: 1,
                  color: 'textPrimary',
                  wordWrap: 'break-word !important',
                  whiteSpace: 'normal !important',
                  maxHeight: '50px',
                }}
              >
                {reason}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params) => {
          const { row: data } = params;
          const { status } = data;
          return (
            <Stack direction="row" alignItems="center">
              <GetInstanceStatus
                status={capitaliseFirstLetterInEachWord(
                  status?.split('_').join(' ') || '',
                )}
              />
            </Stack>
          );
        },
        width: 80,
      },
      {
        field: 'type',
        headerName: 'Type',
        maxWidth: 180,
        renderCell: (params) => {
          const { row } = params;
          const { type } = row;
          return type?.split('_').join(' ') || '';
        },
      },
      {
        field: 'requestor',
        headerName: 'User',
        width: 100,
      },
      {
        field: 'tenantName',
        headerName: 'Tenant Name',
        width: 100,
        renderCell: (params) => {
          const { row } = params;
          const { data } = row;
          const { tenantName } = data;
          return tenantName ? tenantName : '-';
        },
      },
      {
        field: 'jiraId',
        headerName: 'Jira',
        width: 100,
        renderCell: (params) => {
          const { row } = params;
          const { jiraId } = row;
          return (
            <Link href={JIRA_BASE_LINK + jiraId} target="_blank">
              {jiraId}
            </Link>
          );
        },
      },
      {
        field: 'timestamp',
        headerName: 'Timestamp',
        width: 100,
        renderCell: (params) => {
          const { row: data } = params;
          const { timestamp } = data;
          const dateCreated = moment(timestamp).format(DF_HUMAN);
          if (!dateCreated) {
            return '';
          }
          return <Box>{dateCreated}</Box>;
        },
      },
    ],
    [],
  );

  return (
    <Stack sx={{ height: '90vh' }} mt={10} mx={5}>
      <Stack>
        <Typography variant="h6">Audit Logs</Typography>
      </Stack>
      <DataGrid
        filters={filterProps}
        columns={dbColumns}
        disableSelectionOnClick
        rows={auditLogs}
        loading={auditLogsIsLoading}
        apiFilter={true}
        actions={actionProps}
        componentName="auditLog"
        pageCount={pageCount}
        pageNo={page}
        totalCount={auditLogsCount}
        handlePaginationChange={handlePaginationChange}
        onResetFilters={handleResetFilters}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
    </Stack>
  );
}
