export const AUDIT_LOGS_STATUS_LIST = [
  {
    value: 'UNSUCCESSFUL',
    label: 'Unsuccessful',
  },
  {
    value: 'SUCCESSFUL',
    label: 'Successful',
  },
  {
    value: 'REQUESTED',
    label: 'Requested',
  },
  {
    value: 'REQUEST_SENT',
    label: 'Request sent',
  },
  {
    value: 'INITIAL',
    label: 'Initial',
  },
];
export const AUDIT_LOGS_TYPE_LIST = [
  {
    value: 'onboard-tenant',
    label: 'Onboard Tenant',
  },
  {
    value: 'password-reset',
    label: 'Reset Password',
  },
  {
    value: 'update-metadata',
    label: 'Update Metadata',
  },
  {
    value: 'enable-genie',
    label: 'Enable Genie',
  },
  {
    value: 'request_genie_access',
    label: 'Request Genie Access',
  },
  {
    value: 'disable-genie',
    label: 'Disable Genie',
  },
  {
    value: 'add-credits',
    label: 'Add Credits',
  },
  {
    value: 'silence-alert',
    label: 'Silence Alerts',
  },
  {
    value: 'enable-billing',
    label: 'Enable Billing',
  },
  {
    value: 'disable-billing',
    label: 'Disable Billing',
  },
];
