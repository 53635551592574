import { useTheme } from '@mui/material/styles';

export const useServiceOverviewStyles = () => {
  const theme = useTheme();
  return {
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    headingText: {
      fontWeight: theme.typography.fontWeightMedium,
      paddingBottom: 2,
      fontSize: '16px',
    },

    lightText: {
      fontWeight: theme.typography.fontWeightRegular,
    },
    statusBox: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '3px',
      paddingBottom: '3px',
      borderRadius: '4px',
    },
  };
};
