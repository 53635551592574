import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { CREDITS_BASE_URL, URLS } from 'constants/URL';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { adaptCreditResponse } from 'customers/tenants-detailed-view/lib/components/creditDetails/adaptCreditResponse';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';

import { LabelStrings } from '../LabelStrings';

type CreditsPopoverProps = {
  tenantId?: string;
  tenantPgSource?: string;
};

export const CreditsPopover = (props: CreditsPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const {
    response: creditDetails,
    postData,
    isLoading,
  } = useLazyGetCallWithURL(adaptCreditResponse);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    postData(
      `${CREDITS_BASE_URL}tenant-id=${props?.tenantId}&tenant-domain=${props?.tenantPgSource}`,
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Stack m={0}>
      <Stack>
        <Button
          variant="text"
          onClick={handleClick}
          sx={{
            padding: '0px !important',
          }}
        >
          Show Credits
        </Button>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {!isLoading ? (
          creditDetails?.error ? (
            <Stack p={2}>
              <Typography>{creditDetails?.error}</Typography>
            </Stack>
          ) : (
            <Stack p={2} width="250px">
              <ShowLabelValue
                label={LabelStrings.amount}
                value={'$' + creditDetails?.amount}
              />
              <ShowLabelValue
                label={LabelStrings.creditUsed}
                value={'$' + creditDetails?.creditsUsed}
              />

              <Divider />
              <ShowLabelValue
                label={LabelStrings.balance}
                value={'$' + creditDetails?.balance}
              />
            </Stack>
          )
        ) : (
          <Stack p={3}>
            <CircularProgress />
          </Stack>
        )}
      </Popover>
    </Stack>
  );
};
