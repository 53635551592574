import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useGetCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { useContext, useEffect, useState } from 'react';
import { adaptPrivilegesResponse } from './adaptPrivilegesResponse';

export const usePrivileges = () => {
  const [privileges, setPrivileges] = useState([]);
  const { response, isLoading } = useGetCall(
    URLS.getAllPermissions,
    adaptPrivilegesResponse,
  );

  useEffect(() => {
    if (response?.length) {
      setPrivileges(response);
    }
  }, [response]);

  return { privileges, isLoading };
};
