export const removeResolvedAndClosedFromDefaultFilter = (freshdeskStatuses) => {
  return (
    freshdeskStatuses
      ?.filter((item) => {
        return item?.value !== 4 && item?.value !== 5;
      })
      ?.map((item) => {
        return item?.value;
      }) || [2, 3, 6, 7]
  );
};
