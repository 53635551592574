import { Button, MenuItem, Stack } from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';

type DedicatedCloudObject = typeof initUser;
const initUser = {
  cloudAccountId: 'sample-id',
  cloudType: 'AWS',
};
interface DedicatedCloudRowProps {
  dedicatedCloudObject?: any;
  handleOnChange?: Function;
  handleActionClick?: Function;
  indexForButtonText?: Number;
}

const dedicatedCloudTypes = [
  { name: 'AWS', value: 'AWS' },
  { name: 'AZURE', value: 'AZURE' },
  { name: 'GCP', value: 'GCP' },
];

export const DedicatedCloudRow = (props: DedicatedCloudRowProps) => {
  return (
    <Stack direction="row" sx={{ alignItems: 'end' }}>
      <TessellInput
        width="40%"
        mr={2}
        variant="outlined"
        size="small"
        name="cloudAccountId"
        placeholder="Cloud Account Id"
        value={props?.dedicatedCloudObject?.cloudAccountId}
        onChange={(e) => {
          props?.handleOnChange('cloudAccountId', e.target.value);
        }}
      />
      <TessellInput
        width="40%"
        mr={2}
        variant="outlined"
        size="small"
        name="cloudType"
        select
        value={props?.dedicatedCloudObject?.cloudType}
        onChange={(e) => {
          props?.handleOnChange('cloudType', e.target.value);
        }}
        options={dedicatedCloudTypes.map((v) => (
          <MenuItem key={v.value} value={v.value}>
            {v.name}
          </MenuItem>
        ))}
      />

      <Button
        onClick={() => props.handleActionClick()}
        color="primary"
        variant="outlined"
        size="small"
        sx={{
          width: 100,
          height: 40,
        }}
      >
        {props?.indexForButtonText === 0 ? 'Add more' : 'Delete'}
      </Button>
    </Stack>
  );
};
