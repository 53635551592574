import { Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';

const RequiredIndication = () => {
  return (
    <Typography sx={{ ...styles.textBoxLabel, color: styles.color.red }}>
      &nbsp;*
    </Typography>
  );
};
export default RequiredIndication;
