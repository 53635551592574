import { useEffect, useMemo, useState } from 'react';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { useCountAndPageCount } from 'common/Hooks/useCountAndPageCount';
import { TENANTS_BASE_URL, URLS } from 'constants/URL';
import { createParams } from 'helpers/createParams';
import { useNavigate } from 'react-router-dom';
import { adaptTenantSubscriptions } from './adaptTenantSubscriptions';

type SubscriptionProps = {
  tenantId: string;
};

export const useSubscriptions = (props: SubscriptionProps) => {
  const { tenantId } = props;
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [cloudAccountID, setCloudAccountID] = useState('');
  const [detailsAnchorEl, setDetailsAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState([]);

  const {
    response: tenantSubscriptions,
    isLoading: subscriptionsIsLoading,
    postData: fetchSubscriptions,
  } = useLazyGetCallWithURL(adaptTenantSubscriptions);

  const {
    response: subscriptionsCountResponse,
    isLoading: subscriptionsCountIsLoading,
    postData: fetchSubscriptionsCount,
  } = useLazyGetCallWithURL();

  const { count: subscriptionsCount, pageCount: subscriptionsPageCount } =
    useCountAndPageCount({
      response: subscriptionsCountResponse,
    });

  const subscriptionsParams = useMemo(() => {
    return {
      'page-size': ITEMS_PER_PAGE,
      'page-offset': page - 1,
    };
  }, [page]);

  useEffect(() => {
    if (tenantId) {
      fetchSubscriptions(
        `${TENANTS_BASE_URL}/${tenantId}/subscriptions?${createParams(
          subscriptionsParams,
        )}`,
      );
      fetchSubscriptionsCount(
        `${TENANTS_BASE_URL}/${tenantId}/subscriptions/count?${createParams(
          subscriptionsParams,
        )}`,
      );
    }
  }, [subscriptionsParams]);

  const showDetails = (e = null, row = []) => {
    e?.stopPropagation?.();
    if (row?.length && e) {
      setPopoverData(row);
      setDetailsAnchorEl(e.currentTarget);
    } else {
      setPopoverData([]);
      setDetailsAnchorEl(null);
    }
  };

  const handleRowClick = (params) => {
    const { row: data } = params;
    const { id } = data;
    const url = `../database-services?subscription-id=${id}`;
    navigate(url);
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return {
    page,
    open,
    setOpen,
    tenantSubscriptions,
    subscriptionsIsLoading,
    subscriptionsCountIsLoading,
    subscriptionsCount,
    subscriptionsPageCount,
    cloudAccountID,
    setCloudAccountID,
    detailsAnchorEl,
    popoverData,
    showDetails,
    handleRowClick,
    handlePaginationChange,
  };
};
