import { useLazyGetCall } from 'common/api/useApiCall';
import { COST_BASE_URL, URLS } from 'constants/URL';
import { useEffect, useMemo, useState } from 'react';
import { commonResponseAdapterCost } from './costResponseAdapter';
import moment from 'moment';
import { dateFormatterCostChart } from './dateFormatter';
import { adaptCPDPCost } from './Charts/PlaneWiseCost/adaptCPDPCost';
import { now, tenantMinDate } from './constants';
import { CostEnums } from 'constants/URLEnums';

export enum ChartType {
  Tenant = 'Tenant',
  Services = 'Services',
}

type SingleTenantCostProps = {
  tenantDomain: string;
};

export const useSingleTenantCost = (props: SingleTenantCostProps) => {
  const { tenantDomain } = props;
  const [chartType, setChartType] = useState(ChartType.Tenant);

  const [startTime, setStartTime] = useState(
    dateFormatterCostChart(tenantMinDate),
  );
  const [endTime, setEndTime] = useState(dateFormatterCostChart(now));

  const {
    response: tenantCPDPCost,
    isLoading: tenantCPDPCostIsLoading,
    postData: getTenantCPDPCostData,
  } = useLazyGetCall(
    `${COST_BASE_URL}?tenant-name=${tenantDomain?.toLowerCase()}&start-time=${startTime}&end-time=${endTime}&cost-type=${
      CostEnums.CONTROL_DATA_PLANE_COST_BY_TENANT
    }`,
    adaptCPDPCost,
  );

  const {
    response: servicesCost,
    isLoading: servicesIsLoading,
    postData: getServicesCostData,
  } = useLazyGetCall(
    `${COST_BASE_URL}?tenant-name=${tenantDomain?.toLowerCase()}&start-time=${startTime}&end-time=${endTime}&cost-type=${
      CostEnums.SERVICE_COST_BY_TENANT
    }`,
    commonResponseAdapterCost,
  );

  useEffect(() => {
    if (chartType === ChartType.Tenant) getTenantCPDPCostData();
    if (chartType === ChartType.Services) getServicesCostData();
  }, [startTime, endTime, chartType]);

  const handleShowChartTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: any,
  ) => {
    if (newView !== null) {
      setChartType(newView);
    }
  };

  const showTenantCost = useMemo(() => {
    return chartType === ChartType.Tenant;
  }, [chartType]);

  useEffect(() => {
    const isAfter = moment(tenantMinDate).isAfter(startTime);
    if (isAfter && showTenantCost) {
      setStartTime(dateFormatterCostChart(tenantMinDate));
    }
  }, [chartType]);

  return {
    chartType,
    showTenantCost,
    getServicesCostData,
    tenantCPDPCostIsLoading,
    servicesIsLoading,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    tenantCPDPCost,
    servicesCost,
    handleShowChartTypeChange,
  };
};
