import { DonutLarge, TableRowsRounded } from '@mui/icons-material';
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  adaptCostArrayToPieLabelsAndData,
  adaptCostArrayToTableData,
} from 'customers/cost/adapters';
import { SubView, CostResponseKeys } from 'customers/cost/constants';
import { costLayout, costToggleButtonStyle } from 'customers/cost/styles';
import { useMemo, useState } from 'react';
import TessellTable from 'common/custom-components/lib/components/TessellTable';
import { CostPieChart } from 'customers/cost/Charts/CostPieChart';

type TotalTableAndPieProps = {
  costArray: any;
  tableFirstColumnKey: string;
  customColors?: string[];
};

const TotalTableAndPieView = (props: TotalTableAndPieProps) => {
  const { costArray, tableFirstColumnKey, customColors } = props;
  const [subView, setSubView] = useState(SubView.Pie);
  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: any,
  ) => {
    if (newView !== null) {
      setSubView(newView);
    }
  };

  const { rows, columns, labels, data } = useMemo(() => {
    const { rows, columns } = adaptCostArrayToTableData({
      costArray: costArray,
      column1: { label: 'Name', value: tableFirstColumnKey },
      column2: { label: 'Total Cost', value: CostResponseKeys.TOTAL_COST },
    });
    const { labels, data } = adaptCostArrayToPieLabelsAndData({
      costArray: costArray,
      labelKey: tableFirstColumnKey,
      dataKey: CostResponseKeys.TOTAL_COST,
    });
    return { rows, columns, labels, data };
  }, [costArray]);

  const SubComponent = useMemo(() => {
    switch (subView) {
      case SubView.Table: {
        return (
          <Stack spacing={2} borderRadius={4}>
            <TessellTable columns={columns} rows={rows} />
          </Stack>
        );
      }
      case SubView.Pie: {
        return (
          <Stack>
            <CostPieChart
              labels={labels}
              data={data}
              customColors={customColors}
            />
          </Stack>
        );
      }
      default: {
        return <></>;
      }
    }
  }, [subView, rows, columns, labels, data]);

  return (
    <Stack spacing={2} sx={costLayout.tableAndPieContainer}>
      <Stack sx={costLayout.tableAndPieTopBarContainer}>
        <Typography variant="h6">{SubView[subView]} View</Typography>
        <ToggleButtonGroup
          color="standard"
          value={subView}
          exclusive
          size="small"
          onChange={handleViewChange}
          sx={costToggleButtonStyle}
        >
          <ToggleButton value={SubView.Pie}>
            <DonutLarge sx={costLayout.tableandPieToggleIcon} />
          </ToggleButton>
          <ToggleButton value={SubView.Table}>
            <TableRowsRounded sx={costLayout.tableandPieToggleIcon} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {SubComponent}
    </Stack>
  );
};

export default TotalTableAndPieView;
