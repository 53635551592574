import { CircularProgress, Stack, Typography } from '@mui/material';
import useInterval from 'helpers/useInterval';
import { useEffect, useState } from 'react';

type GenieRefreshStatusProps = {
  refreshAction: any;
  isLoading: boolean;
};

const GENIE_REFRESH_TIMER = 30;

export const GenieRefreshStatus = (props: GenieRefreshStatusProps) => {
  const { refreshAction, isLoading } = props;
  const [timer, setTimer] = useState(GENIE_REFRESH_TIMER);

  useInterval(() => {
    if (!isLoading) {
      setTimer(timer - 1);
    }
  }, [1000]);

  useEffect(() => {
    if (timer === 0) {
      refreshAction();
      setTimer(GENIE_REFRESH_TIMER);
    }
  }, [timer]);

  return (
    <Stack px={2}>
      {isLoading ? (
        <CircularProgress size={18} thickness={4} color="primary" />
      ) : (
        <Typography fontSize={'12px'}>Refreshing in: {timer}s</Typography>
      )}
    </Stack>
  );
};
