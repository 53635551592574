import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';

export const JiraInput = ({ label, key, values, value, onChange }) => {
  return (
    <TessellAutocomplete
      label={label}
      value={value}
      variant="outlined"
      size="small"
      onChange={onChange}
      fullWidth
      options={values}
      name={key}
    />
  );
};
