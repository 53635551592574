import { Stack, Typography } from '@mui/material';
import {
  CircularPlayIcon,
  CircularStopIcon,
  ScheduleDurationIcon,
  ScheduleRepeatIcon,
  DeleteScheduleIcon,
} from 'assets/icons-v2';
import { GetScheduleStatus } from './ScheduleStatus';
import moment from 'moment';
import _toUpper from 'lodash/toUpper';

const dayMap = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

export const getSortedDays = (days = []) => {
  const sortedDays = days?.sort(
    (a, b) => dayMap[_toUpper(a)] - dayMap[_toUpper(b)],
  );
  return sortedDays;
};

const StyledTypography = (props) => {
  return (
    <Typography
      sx={{
        fontSize: props.isAvmText ? '12px' : '14px',
        lineHeight: props.isAvmText ? '16px' : '21px',
        color: (props.isLabel || props.isAvmText) && '#666666',
      }}
    >
      {props.children}
    </Typography>
  );
};

export function doesNotRepeatDetails(schedule, status) {
  const startInfo = schedule?.duration?.startDate;
  const stopInfo = schedule?.duration?.endDate;
  return (
    <Stack direction="column" height="100%" width="100%" spacing={3}>
      {startInfo !== null && (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            width="100%"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Stack width="16px" height="16px">
              <CircularPlayIcon />
            </Stack>
            <Stack width="70px">
              <StyledTypography isLabel>Start on:</StyledTypography>
            </Stack>
            <StyledTypography>{startInfo}</StyledTypography>
          </Stack>
          <Stack>
            <GetScheduleStatus status={status} />
          </Stack>
        </Stack>
      )}
      {stopInfo !== null && (
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            width="100%"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Stack width="16px" height="16px">
              <CircularStopIcon />
            </Stack>
            <Stack width="70px">
              <StyledTypography isLabel>Stop on:</StyledTypography>
            </Stack>
            <StyledTypography>{stopInfo}</StyledTypography>
          </Stack>
          <Stack>
            {startInfo === null && <GetScheduleStatus status={status} />}
          </Stack>
        </Stack>
      )}
      <Stack
        direction="row"
        width="100%"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Stack width="16px" height="16px" mr={2}>
            <ScheduleDurationIcon />
          </Stack>
          <Stack width="70px">
            <StyledTypography isLabel>Repeats:</StyledTypography>
          </Stack>
        </Stack>
        <Stack width="100%" m={0}>
          <StyledTypography>One Time</StyledTypography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export function dailyRepeatsDetails(schedule, recurrence, status) {
  const occurrencesTillNow = schedule?.metadata?.scheduleCounter;
  return (
    <Stack direction="column" height="100%" width="100%" spacing={3}>
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          width="100%"
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack width="16px" height="16px">
            <CircularPlayIcon />
          </Stack>
          <Stack width="70px">
            <StyledTypography isLabel>Start date:</StyledTypography>
          </Stack>
          <StyledTypography>{schedule.duration?.startDate}</StyledTypography>
        </Stack>
        <GetScheduleStatus status={status} />
      </Stack>
      <Stack
        direction="row"
        width="100%"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack width="16px" height="16px">
          <ScheduleRepeatIcon />
        </Stack>
        <Stack width="70px">
          <StyledTypography isLabel>Duration:</StyledTypography>
        </Stack>
        <StyledTypography>
          {`${schedule.duration?.startTime} `} -{' '}
          {`${schedule.duration?.endTime} `}
        </StyledTypography>
      </Stack>
      <Stack
        direction="row"
        width="100%"
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Stack width="16px" height="16px" mr={2}>
            <ScheduleDurationIcon />
          </Stack>
          <Stack width="70px">
            <StyledTypography isLabel>Repeats:</StyledTypography>
          </Stack>
        </Stack>
        <Stack width="100%" m={0}>
          <StyledTypography>
            {recurrence}{' '}
            {schedule.ends.type === 'onDate' && (
              <> till {`${schedule.ends?.endDate} `}</>
            )}
            {schedule.ends.type === 'afterOccurrences' && (
              <>
                {' '}
                <>Schedule has ran {occurrencesTillNow} times till now.</>
                <br />
                <>
                  Schedule will end after a total of {}
                  {schedule.ends?.occurrences} occurrences.
                </>
              </>
            )}
          </StyledTypography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export function weeklyRepeatDetails(schedule, recurrence, status) {
  return (
    <Stack direction="column" height="100%" width="100%" spacing={3}>
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          width="100%"
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack width="16px" height="16px">
            <CircularPlayIcon />
          </Stack>
          <Stack width="70px">
            <StyledTypography isLabel>Start date:</StyledTypography>
          </Stack>
          <StyledTypography>{`${schedule.duration?.startDate} `}</StyledTypography>
        </Stack>
        <Stack>
          <GetScheduleStatus status={status} />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        width="100%"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack width="16px" height="16px">
          <ScheduleRepeatIcon />
        </Stack>
        <Stack width="70px">
          <StyledTypography isLabel>Duration:</StyledTypography>
        </Stack>
        <StyledTypography>
          {`${schedule.duration?.startTime} `} -{' '}
          {`${schedule.duration?.endTime} `}
        </StyledTypography>
      </Stack>
      <Stack
        direction="row"
        width="100%"
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Stack width="16px" height="16px" mr={2}>
            <ScheduleDurationIcon />
          </Stack>
          <Stack width="70px">
            <StyledTypography isLabel>Repeats:</StyledTypography>
          </Stack>
        </Stack>
        <Stack width="100%" m={0}>
          <StyledTypography>
            Every{' '}
            {getSortedDays(schedule.repeatsOn || [])
              ?.map((day) => day)
              .join(', ') || ''}
            <br />
            {schedule.ends.type === 'onDate' && (
              <> till {`${schedule.ends?.endDate} `}</>
            )}
            {schedule.ends.type === 'afterOccurrences' && (
              <> Ends after {schedule.ends?.occurrences} occurrences</>
            )}
          </StyledTypography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export function deletionScheduleDetails(schedule) {
  const date = new Date(schedule.deleteAt);
  const deletionDate = moment(date).format('ddd, MMMM DD, YYYY');
  const deletionTime = moment(date).format('hh:mm A');

  const stringAvmRetention =
    !!schedule?.deletionConfig?.retainAvailabilityMachine === true
      ? 'Retain the Availability Machine and delete only the service.'
      : 'Completely delete the service along with the Availability Machine.';
  return (
    <Stack direction="column" height="100%" width="100%" spacing={3}>
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          width="100%"
          spacing={2}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Stack width="16px" height="16px" mr={2} mt="2px">
              <DeleteScheduleIcon />
            </Stack>
            <Stack width="70px">
              <StyledTypography isLabel>Delete on:</StyledTypography>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing={0.2}
            alignItems="flex-start"
            justifyContent="center"
          >
            <StyledTypography>
              {deletionDate} {deletionTime}
            </StyledTypography>
            <StyledTypography isAvmText>{stringAvmRetention}</StyledTypography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
