import { Button, CircularProgress, Popover, Stack } from '@mui/material';
import React from 'react';

type ButtonPopoverProps = {
  label?: string;
  handleClick: any;
  isLoading: boolean;
  children: any;
};

export const ButtonPopover = ({
  label = 'See Details',
  handleClick,
  isLoading,
  children,
}: ButtonPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Stack m={0}>
      <Stack>
        <Button
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
            handleClick(e);
          }}
          sx={{ alignSelf: 'flex-start', padding: '0px' }}
        >
          {label}
        </Button>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack p={3}>{!isLoading ? children : <CircularProgress />}</Stack>
      </Popover>
    </Stack>
  );
};
