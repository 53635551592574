import {
  ChatBubbleOutline,
  ExpandMore,
  GetApp,
  MailOutline,
  NavigateBefore,
  Send,
} from '@mui/icons-material';
import {
  Stack,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  IconButton,
  AccordionDetails,
  Fade,
  Avatar,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
} from '@mui/material';
import { TessellBox } from 'Layouts/VerticalLayouts/TessellBox';
import { styles } from 'assets/scss/style-templates';
import clsx from 'clsx';
import {
  useGetCall,
  useLazyGetCallWithURL,
  useLazyPostCall,
} from 'common/api/useApiCall';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';
import { getLabelValue, numberFormatter } from 'common/utils';
import { TICKETS_BASE_URL, URLS } from 'constants/URL';
import { FreshdeskPriorities } from 'customers/tenants-detailed-view/lib/utils/constants';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppUtil from 'tools/AppUtil';
import { FileIcon, defaultStyles } from 'react-file-icon';
import fileExtension from 'file-extension';
import {
  adaptTicketAgentsResponse,
  adaptTicketConversationsResponse,
  adaptTicketResponse,
} from './ticketAdapter';
import { DF_HUMAN } from 'helpers/dateFormats';
import moment from 'moment';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import Prism from 'prismjs';
import { Editor } from '@toast-ui/react-editor';
import { LoadingButton } from '@mui/lab';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import './ticketDetails.scss';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { CommonDataContext } from 'common/Contexts/CommonData';

const useStyles = () => {
  const theme = useTheme();
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    sendButton: {
      width: 100,
    },
    ticketSel: {
      width: 200,
    },
    boldText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    contentBox: {
      boxSizing: 'border-box',
      borderRadius: '5px',
      boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25) !important',
      background: 'white',
      '& .toastui-editor-defaultUI': {
        border: 'none',
      },
    },
    inputActions: {
      background: styles.color.greyBackground,
    },
    downloadIcon: {
      position: 'absolute',
      right: 20,
      top: 30,
    },
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    msgContent: {
      '& div': {
        fontFamily: 'inherit !important',
        fontWeight: 'inherit !important',
        fontSize: 'inherit !important',
      },
    },
    attachment: {
      wordWrap: 'break-word',
      lineHeight: 24,
      position: 'relative',
      padding: '10px 40px 20px 20px',
      borderRadius: 5,
      border: '1px solid #ebeff3',
      '& svg': {
        width: 40,
        position: 'absolute',
      },
    },
  };
};

enum EditorType {
  REPLY = 'REPLY',
  NOTE = 'NOTE',
}

const Ticket = () => {
  const { getStatusUsingValue } = useContext(CommonDataContext);
  const classes = useStyles();
  const params = useParams();
  const scroll = useRef<any>();
  const navigate = useNavigate();
  const { addNotification } = useContext(NotificationContext);
  const { hasPermission } = useContext(UserPermissionsContext);
  const [expanded, setExpanded] = useState(true);

  const { ticketId } = params;

  const [state, setState] = useState({
    editor: useRef(),
    markdown: '',
    mode: 'mail',
    showDownloadFor: null,
  });

  const { markdown, mode, showDownloadFor } = state;

  const [editorType, setEditorType] = useState<any>();

  const {
    response: conversationsResponse,
    isLoading: conversationsLoading,
    postData: fetchConversations,
  } = useLazyGetCallWithURL(adaptTicketConversationsResponse);

  const {
    response: ticket,
    isLoading: ticketDetailsLoading,
    postData: fetchTicketsDetails,
  } = useLazyGetCallWithURL(adaptTicketResponse);

  const { response: ticketAgents, isLoading: ticketAgentsLoading } = useGetCall(
    URLS.fetchTicketAgents,
    adaptTicketAgentsResponse,
  );

  const {
    response: replyResponse,
    isLoading: replyLoading,
    postData: reply,
  } = useLazyPostCall(URLS.replyToTicket);

  useEffect(() => {
    if (ticketId) {
      fetchConversations(`${TICKETS_BASE_URL}/` + ticketId + '/conversations');
      fetchTicketsDetails(`${TICKETS_BASE_URL}/` + ticketId);
    }
  }, [ticketId]);

  const conversations = useMemo(() => {
    if (!ticket?.id) {
      return [];
    }
    const _conversations: any = [
      {
        text: ticket.description,
        attachments: ticket.attachments || [],
        time: moment(ticket.created_at).format(DF_HUMAN),
        name: 'Customer',
        sender: 'customer',
        private: false,
      },
    ];
    if (conversationsResponse?.length) {
      const msgs = conversationsResponse;
      msgs.forEach((msg) => {
        const agent = ticketAgents[msg.user_id];
        const agentSent = Boolean(agent);
        _conversations.push({
          text: msg.body,
          attachments: msg.attachments || [],
          time: moment(msg.created_at).format(DF_HUMAN),
          name: agentSent ? agent.contact.name : 'Customer',
          sender: agentSent ? 'agent' : 'customer',
          private: msg.private,
          picture: agentSent
            ? agent.contact.avatar
              ? agent.contact.avatar.thumb_url
              : null
            : null,
        });
      });
    }
    return _conversations;
  }, [ticket, conversationsResponse, ticketAgents]);

  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const send = () => {
    let formData = new FormData();
    formData.append('id', ticket.id);
    formData.append('reply', markdown);
    formData.append(
      'type',
      editorType === EditorType.REPLY ? 'PUBLIC' : 'PRIVATE',
    );
    reply(formData);
  };

  useEffect(() => {
    if (replyResponse?.status === 'SUCCESSFUL') {
      setEditorType(null);
      fetchConversations(`${TICKETS_BASE_URL}/` + ticketId + '/conversations');
    } else if (replyResponse?.status) {
      addNotification({
        severity: 'error',
        message: replyResponse?.message || 'Something went wrong',
      });
    }
  }, [replyResponse]);

  const getAvatar = (item) =>
    item.picture ? (
      <Avatar alt={item.name} src={item.picture} sx={classes.avatar} />
    ) : (
      <Avatar sx={classes.avatar}>{item.name.charAt(0)}</Avatar>
    );

  const showInChatMode = () =>
    conversations.map((c) => (
      <>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          className={`${c.sender}-text`}
          mb={1}
        >
          {c.sender === 'agent' ? getAvatar(c) : null}
          <Stack>
            <Typography variant="subtitle2">
              {c.name}{' '}
              <Typography variant="caption">
                {c.private ? 'added a private note' : 'replied'}
              </Typography>
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {c.time}
            </Typography>
          </Stack>
          {c.sender === 'customer' ? getAvatar(c) : null}
        </Stack>
        {c.text && (
          <Stack
            dangerouslySetInnerHTML={{ __html: c.text }}
            className={clsx({
              [`${c.sender}-msg`]: true,
              message: true,
            })}
          />
        )}
        {c.attachments.map((a) => {
          const ext = fileExtension(a.name);
          return (
            <Stack
              display="flex"
              flexDirection="row"
              onMouseEnter={() => updateState('showDownloadFor', a.id)}
              onMouseLeave={() => updateState('showDownloadFor', null)}
              className={clsx({
                [`${c.sender}-file`]: true,
                attachment: true,
              })}
            >
              <Stack width={40} minWidth={40}>
                <FileIcon extension={ext} {...defaultStyles[ext]} />
              </Stack>
              <Stack direction="column" ml={2}>
                <Typography variant="caption">{a.name}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {numberFormatter.format(AppUtil.getNumber(a.size / 1024))} KB
                </Typography>
              </Stack>
              <Fade in={a.id === showDownloadFor} timeout={500}>
                <IconButton
                  color="primary"
                  size="small"
                  sx={classes.downloadIcon}
                  href={a.attachment_url}
                  target="_blank"
                >
                  <GetApp color="primary" fontSize="small" />
                </IconButton>
              </Fade>
            </Stack>
          );
        })}
      </>
    ));

  const showInMailMode = () =>
    conversations.map((c) => (
      <Stack direction="row" my={2}>
        {getAvatar(c)}
        <Stack direction="column" ml={2} width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            mb={1}
          >
            <Typography variant="subtitle2">
              {c.name}{' '}
              <Typography variant="caption">
                {c.private ? 'added a private note' : 'replied'}
              </Typography>
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <i>{c.time}</i>
            </Typography>
          </Stack>
          <div
            dangerouslySetInnerHTML={{ __html: c.text }}
            //@ts-ignore
            className={classes.msgContent}
          />
          {c.attachments.map((a) => {
            const ext = fileExtension(a.name);
            return (
              <Stack
                direction="row"
                maxWidth={500}
                mt={1.5}
                onMouseEnter={() => updateState('showDownloadFor', a.id)}
                onMouseLeave={() => updateState('showDownloadFor', null)}
                sx={classes.attachment}
              >
                <Stack width={40} minWidth={40}>
                  <FileIcon extension={ext} {...defaultStyles[ext]} />
                </Stack>
                <Stack direction="column" ml={2}>
                  <Typography variant="caption">{a.name}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {numberFormatter.format(AppUtil.getNumber(a.size / 1024))}{' '}
                    KB
                  </Typography>
                </Stack>
                <Fade in={a.id === showDownloadFor} timeout={500}>
                  <IconButton
                    color="primary"
                    size="small"
                    sx={classes.downloadIcon}
                    href={a.attachment_url}
                    target="_blank"
                  >
                    <GetApp color="primary" fontSize="small" />
                  </IconButton>
                </Fade>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    ));

  return (
    <Stack className="page-content vh-100">
      <LoadingOverlay isLoading={ticketDetailsLoading || ticketAgentsLoading}>
        <Stack direction="column" height="90%" p={4}>
          <Stack
            direction="row"
            mb={2}
            justifyContent="space-between"
            alignItems="center"
            maxWidth={1000}
          >
            <Stack direction="row" alignItems="center">
              <Button
                color="primary"
                startIcon={<NavigateBefore />}
                sx={classes.button}
                onClick={(e) => {
                  e?.preventDefault();
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </Stack>
            <ToggleButtonGroup
              value={mode}
              size="small"
              exclusive
              onChange={(e, v) => {
                if (v) {
                  updateState('mode', v);
                }
              }}
            >
              <ToggleButton value="mail" size="small">
                <MailOutline fontSize="small" />
              </ToggleButton>
              <ToggleButton value="chat" size="small">
                <ChatBubbleOutline fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack
            sx={classes.contentBox}
            width="100%"
            maxWidth={1000}
            height="auto"
          >
            <Accordion
              elevation={0}
              expanded={expanded}
              onChange={() => setExpanded((prev) => !prev)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography sx={classes.boldText}>
                    {ticket.subject}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="column" width="100%">
                  <Stack direction="row">
                    <Stack direction="column" width="50%" spacing={0.5}>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        {getLabelValue(
                          'Status',
                          getStatusUsingValue?.(ticket?.status),
                        )}
                      </Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        {getLabelValue(
                          'Severity',
                          FreshdeskPriorities[ticket.priority],
                        )}
                      </Stack>
                      {getLabelValue(
                        'Agent',
                        ticket.responder_id
                          ? ticketAgents[ticket.responder_id]?.contact?.name
                          : 'Unassigned',
                      )}
                    </Stack>
                    <Stack direction="column" width="50%" spacing={0.5}>
                      {getLabelValue('Id', ticket.id)}
                      {getLabelValue('Created', ticket.created)}
                      {getLabelValue('Last updated', ticket.lastUpdate)}
                    </Stack>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          <Stack
            direction="column"
            height="100%"
            width="100%"
            maxWidth={1000}
            mt={2}
            sx={classes.contentBox}
          >
            <TessellBox
              display="flex"
              flexDirection="column"
              flexGrow={1}
              overflow="scroll"
              p={2}
              className="ticket-conversation"
            >
              {mode === 'chat' ? showInChatMode() : showInMailMode()}
              <div ref={scroll} />
            </TessellBox>

            {editorType ? (
              <Stack direction="column" className="ticket-reply-editor">
                <Editor
                  placeholder="Send a message ..."
                  height="200px"
                  initialEditType="wysiwyg"
                  minHeight="200px"
                  hideModeSwitch
                  ref={state.editor}
                  onChange={() =>
                    updateState(
                      'markdown',
                      //@ts-ignore
                      state?.editor?.current.getInstance()?.getHTML(),
                    )
                  }
                  plugins={[
                    [codeSyntaxHighlight, { highlighter: Prism }],
                    colorSyntax,
                  ]}
                />
                <Stack direction="column" sx={classes.inputActions} p={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      startIcon={<Send />}
                      disabled={!markdown || replyLoading}
                      loading={replyLoading}
                      onClick={send}
                    >
                      {editorType === EditorType.REPLY ? 'Send' : 'Add note'}
                    </LoadingButton>

                    <Button
                      variant="outlined"
                      sx={classes.button}
                      onClick={(e) => {
                        setEditorType(null);
                      }}
                      disabled={replyLoading}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              ''
            )}
          </Stack>
          {!editorType && hasPermission(PERMISSIONS.TICKET_UPDATE) && (
            <Stack direction="row" mt={2}>
              <Button
                variant="outlined"
                sx={classes.button}
                onClick={(e) => {
                  setEditorType(EditorType.REPLY);
                }}
              >
                Reply
              </Button>
              <Button
                variant="outlined"
                sx={classes.button}
                onClick={(e) => {
                  setEditorType(EditorType.NOTE);
                }}
              >
                Add note
              </Button>
            </Stack>
          )}
        </Stack>
      </LoadingOverlay>
    </Stack>
  );
};

export default Ticket;
