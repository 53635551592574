/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { TextField, Typography, Stack } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import RequiredIndication from './RequiredIndicator';

export default function TessellInput(props) {
  const {
    sx,
    label,
    labelStyles,
    select = false,
    options,
    m,
    mt,
    mb,
    ml,
    mr,
    p,
    pt,
    pb,
    pl,
    pr,
    width = 'auto',
    variant = 'outlined',
    disabled,
    children,
    shouldAutoFocus,
    onlyTextLabel = false,
    rows,
    markAsRequired,
    ...textFieldProps
  } = props;

  function GetComponent() {
    if (children) {
      return children;
    }
    if (select) {
      return (
        <TextField
          select
          variant={variant}
          disabled={disabled}
          {...textFieldProps}
        >
          {options}
        </TextField>
      );
    }

    return (
      <TextField
        variant={variant}
        disabled={disabled}
        autoFocus={shouldAutoFocus}
        multiline={rows > 1}
        rows={rows}
        {...textFieldProps}
      />
    );
  }
  function OnlyTextLabel() {
    const { value } = textFieldProps;

    return (
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        height="37.13px"
        minHeight="37.13px"
      >
        {value}
      </Stack>
    );
  }
  return (
    <Stack
      m={m}
      mb={mb}
      mt={mt === 0 ? 0 : mt || 2}
      ml={ml}
      mr={mr}
      p={p}
      pl={pl}
      pb={pb}
      pr={pr}
      pt={pt}
      width={width}
      sx={sx}
    >
      <Stack direction={'row'}>
        <Typography
          sx={{
            ...(labelStyles ? labelStyles : styles.textBoxLabel),
            ...(disabled ? { color: styles.color.monotoneDarkLight } : {}),
            width: 'fit-content',
          }}
          mb={1}
        >
          {label}
        </Typography>
        {markAsRequired && <RequiredIndication />}
      </Stack>

      {onlyTextLabel ? OnlyTextLabel() : GetComponent()}
    </Stack>
  );
}
