import { DEFAULT_NULL } from 'constants/appConstants';

export const adaptComputeResponse = (response) => {
  const compute = response?.response?.[0];
  return response?.response.length
    ? {
        id: compute?._id || DEFAULT_NULL,
        cloudResourceId: compute?.cloudResourceId || DEFAULT_NULL,
        status: compute?.status || DEFAULT_NULL,
        dbserverSystemId: compute?.dbserverSystemId || DEFAULT_NULL,
        deploymentId: compute?.deploymentId || DEFAULT_NULL,
        name: compute?.name || DEFAULT_NULL,
      }
    : {};
};
