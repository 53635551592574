import { useState } from 'react';
import { Stack } from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { useNavigate } from 'react-router-dom';
import {
  useSetUserLoginInfoContext,
  useUserLoginInfoContext,
} from './UserLoginInfo/UserLoginInfoContext';
import {
  forgetPasswordAuth,
  sendForgetPasswordVerificationCode,
} from './forgetPasswordAuth';
import LoginContainer from 'common/custom-components/LoginContainer';
import { PasswordValidation } from 'common/Tooltips/PasswordValidation';
import { LoadingButton } from '@mui/lab';

const buttonStyle = {
  width: '100%',
  height: '50px',
  borderRadius: '4px',
  backgroundColor: '#3986C1',
};

export const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [sendVerificationCodeLoading, setSendVerificationCodeLoading] =
    useState(false);

  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);

  const loginInfo = useUserLoginInfoContext();
  const setLoginInfo = useSetUserLoginInfoContext();

  const navigate = useNavigate();

  const onSubmit = () => {
    sendForgetPasswordVerificationCode(email, setSendVerificationCodeLoading);
    setVerificationSent(true);
  };

  const resetPassword = () => {
    forgetPasswordAuth(
      email,
      verificationCode,
      newPassword,
      setLoginInfo,
      navigate,
      setForgotPasswordLoading,
    );
  };

  return (
    <LoginContainer>
      <Stack alignItems={'center'} justifyContent={'center'} p={2}>
        <TessellInput
          label="Enter Email"
          disabled={verificationSent}
          value={email}
          variant="outlined"
          size="small"
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              onSubmit();
              ev.preventDefault();
            }
          }}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            width: '300px',
            mb: '20px',
          }}
        />
        <LoadingButton
          sx={buttonStyle}
          variant="contained"
          onClick={onSubmit}
          loading={sendVerificationCodeLoading}
          disabled={!email.length}
        >
          {verificationSent ? 'Send again' : 'Send Verification Code'}
        </LoadingButton>
      </Stack>
      {verificationSent && (
        <Stack alignItems={'center'} justifyContent={'center'} p={2}>
          <TessellInput
            label="Enter verification code"
            value={verificationCode}
            variant="outlined"
            size="small"
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                onSubmit();
                ev.preventDefault();
              }
            }}
            onChange={(e) => setVerificationCode(e.target.value)}
            sx={{
              width: '300px',
            }}
          />
          <PasswordValidation
            label={'Enter New Password'}
            password={newPassword}
            setPassword={setNewPassword}
            setIsPasswordValid={setIsPasswordValid}
            onEnterPress={onSubmit}
          />
          <LoadingButton
            disabled={!isPasswordValid}
            sx={buttonStyle}
            variant="contained"
            onClick={resetPassword}
            loading={forgotPasswordLoading}
          >
            Set New Password
          </LoadingButton>
        </Stack>
      )}
    </LoginContainer>
  );
};
