export const eventsStyles = {
  singleEventContainer: {
    direction: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  singleEventTextContainer: {
    direction: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timelineContainer: {
    width: '100%',
    height: '100%',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '24px',
    overflow: 'auto',
  },
  timelineItem: {
    padding: '6px 10px 24px 10px',
  },
  timelineDot: {
    backgroundColor: 'white',
    border: '1px solid #E6E6E6',
    height: '28px',
    width: '28px',
    marginX: '0px',
    marginY: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    padding: '6px',
  },
  defaultIcon: {
    width: '8px',
    height: '8px',
    borderRadius: '4px',
    bgcolor: '#1E7EA1',
  },
  timelineConnector: {
    width: '2px',
    backgroundColor: '#E5E5E5',
    borderRadius: '1px',
  },
  timelineContent: {
    direction: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: '800px',
  },
  timelineContentHeader: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#08080D',
  },
  timelineContentTimestamp: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#666666',
  },
  timelineContentContext: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#08080D',
  },
  timelineContentBody: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#666',
    backgroundColor: '#f4f4f4',
    width: '100%',
    borderRadius: '4px',
    padding: '12px',
  },
};
