import { Chip, Grid, Stack, Typography } from '@mui/material';
import { useGroups } from './useGroups';
import { personasStyles } from './styles';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { useMemo } from 'react';
import {
  Add,
  ChevronRight,
  Delete,
  Edit,
  ExpandMore,
  GroupAdd,
  PersonAdd,
  PersonRemove,
} from '@mui/icons-material';
import { CreateGroupModal } from './CreateGroup/CreateGroupModal';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import UpdateGroupPermissions from './UpdateGroupPrivilege/UpdateGroupPrivilege';
import moment from 'moment';
import { DF_HUMAN } from 'helpers/dateFormats';
import ConfirmationDialog from 'common/Modals/ConfirmationDialog';
import { usersStyles } from '../Users/styles';
import AddUsersModal from './EditGroup/AddUsers/AddUsersModal';
import DeleteUsersModal from './EditGroup/DeleteUsers/DeleteUsersModal';
import { GroupStringConstants } from './constants';

export const Groups = () => {
  const {
    groups,
    groupsIsLoading,
    openCreateGroupModal,
    selectedGroup,
    setSelectedGroup,
    setOpenCreateGroupModal,
    handleOnSuccess,
    openPermissionModal,
    setOpenPermissionModal,
    openDeleteConfirmation,
    setOpenDeleteConfirmation,
    deleteGroup,
    deleteGroupIsLoading,
    openAddUsersModal,
    setOpenAddUsersModal,
    openDeleteUsersModal,
    setOpenDeleteUsersModal,
  } = useGroups();

  const groupsColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
      },
      {
        field: 'description',
        headerName: 'Description',
      },
      {
        field: 'permissions',
        headerName: 'Permissions',
        renderCell: (params) => {
          const { row: data } = params;
          const { permissions } = data;
          return permissions?.length ? (
            <Grid container rowSpacing={1} columnSpacing={1}>
              {permissions?.map((_p) => (
                <Grid item>
                  <Chip label={_p} size="medium" />
                </Grid>
              ))}
            </Grid>
          ) : (
            '-'
          );
        },
      },
      {
        field: 'creationDate',
        headerName: 'Creation Date',
        renderCell: (params) => {
          const { row } = params;
          return moment(row?.creationDate)?.format(DF_HUMAN);
        },
      },
    ],
    [],
  );

  const actionProps = useMemo(
    () => [
      {
        startIcon: <GroupAdd />,
        label: GroupStringConstants.createPersona,
        onClick: (e) => {
          setOpenCreateGroupModal(true);
        },
      },
    ],
    [],
  );

  const menuItemProps = useMemo(
    () => [
      {
        icon: <PersonAdd color="primary" />,
        label: GroupStringConstants.addUsers,
        onClick: (groupObject) => {
          setSelectedGroup(groupObject);
          setOpenAddUsersModal(true);
        },
      },
      {
        icon: <PersonRemove color="primary" />,
        label: GroupStringConstants.removeUsers,
        onClick: (groupObject) => {
          setSelectedGroup(groupObject);
          setOpenDeleteUsersModal(true);
        },
      },
      {
        icon: <Edit color="primary" />,
        label: 'Update Permissions',
        onClick: (groupObject) => {
          setSelectedGroup(groupObject);
          setOpenPermissionModal(true);
        },
      },
      {
        icon: <Delete color="primary" />,
        label: 'Delete Persona',
        onClick: (groupObject) => {
          setSelectedGroup(groupObject);
          setOpenDeleteConfirmation(true);
        },
      },
    ],
    [],
  );

  return (
    <Stack sx={personasStyles.mainComponent}>
      <DataGrid
        columns={groupsColumns}
        rows={groups}
        actions={actionProps}
        loading={groupsIsLoading}
        menuItems={menuItemProps}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
      <CreateGroupModal
        open={openCreateGroupModal}
        setOpen={setOpenCreateGroupModal}
        handleOnSuccess={handleOnSuccess}
      />
      <AddUsersModal
        open={openAddUsersModal}
        setOpen={setOpenAddUsersModal}
        group={selectedGroup}
      />
      <DeleteUsersModal
        open={openDeleteUsersModal}
        setOpen={setOpenDeleteUsersModal}
        group={selectedGroup}
      />
      <ConfirmationDialog
        title="Delete group"
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        handleConfirm={() => {
          deleteGroup(selectedGroup?.name);
          !deleteGroupIsLoading && setOpenDeleteConfirmation(false);
        }}
        confirmContent={
          <Stack>
            <Typography>
              Are you sure you want to delete group{' '}
              <Typography sx={usersStyles.emailText}>
                {selectedGroup?.name}
              </Typography>
            </Typography>
            <Typography>
              Description :{' '}
              <Typography sx={usersStyles.emailText}>
                {selectedGroup?.description}
              </Typography>
            </Typography>
          </Stack>
        }
        isLoading={deleteGroupIsLoading}
      />
      {openPermissionModal && selectedGroup && (
        <UpdateGroupPermissions
          open={openPermissionModal}
          setOpen={setOpenPermissionModal}
          handleOnSuccess={handleOnSuccess}
          group={selectedGroup}
        />
      )}
    </Stack>
  );
};
