import { isEmpty } from 'lodash';

const RemoveAllFromObject = (object) => {
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === 'string') {
      if (object[key]?.toLowerCase() === 'all') {
        delete object[key];
      }
    }
  });
  return object;
};

const RemoveEmptyArrayAndStringFromObject = (object) => {
  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) {
      if (isEmpty(object[key])) {
        delete object[key];
      }
    }
    if (typeof object[key] === 'string') {
      if (isEmpty(object[key])) {
        delete object[key];
      }
    }
  });
  return object;
};

const ChangeArrayToCommaSeparatedInObject = (object) => {
  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) {
      object[key] = object[key]?.join(',');
    }
  });
  return object;
};

const filterBeforeSending = (filters) => {
  RemoveAllFromObject(filters);
  RemoveEmptyArrayAndStringFromObject(filters);
  ChangeArrayToCommaSeparatedInObject(filters);
  return filters;
};

export const adaptFilterBeforeCall = (filters) => {
  return `${encodeURIComponent?.(
    JSON.stringify(filterBeforeSending?.(filters)),
  )}`;
};
