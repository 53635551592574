/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function PaymentIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.913 13H23.413V12.5H22.913V13ZM22.913 7V7.5H23.413V7H22.913ZM4.65217 3C4.37603 3 4.15217 3.22386 4.15217 3.5C4.15217 3.77614 4.37603 4 4.65217 4V3ZM0.5 3.5V18H1.5V3.5H0.5ZM1.5 19H22.413V18H1.5V19ZM23.413 18V13H22.413V18H23.413ZM22.913 12.5H18.6957V13.5H22.913V12.5ZM18.1957 12V8H17.1957V12H18.1957ZM18.6957 7.5H22.913V6.5H18.6957V7.5ZM22.413 0H18.2174V1H22.413V0ZM19.5448 3H7.78261V4H19.5448V3ZM7.78261 3H4.65217V4H7.78261V3ZM16 1H18.2174V0H16V1ZM23.413 7V3.5H22.413V7H23.413ZM23.413 3.5V1H22.413V3.5H23.413ZM19.5448 4H22.913V3H19.5448V4ZM1.5 3.5V1H0.5V3.5H1.5ZM13 1H16V0H13V1ZM1.5 1H13V0H1.5V1ZM1.5 1V1V0C0.947715 0 0.5 0.447715 0.5 1H1.5ZM18.1957 8C18.1957 7.72386 18.4195 7.5 18.6957 7.5V6.5C17.8672 6.5 17.1957 7.17157 17.1957 8H18.1957ZM18.6957 12.5C18.4195 12.5 18.1957 12.2761 18.1957 12H17.1957C17.1957 12.8284 17.8672 13.5 18.6957 13.5V12.5ZM22.413 1V1H23.413C23.413 0.447715 22.9653 0 22.413 0V1ZM22.413 19C22.9653 19 23.413 18.5523 23.413 18H22.413V19ZM0.5 18C0.5 18.5523 0.947715 19 1.5 19V18H1.5H0.5Z" fill="var(--icon-color)" />
      <path d="M22.913 7H24C24.5523 7 25 7.44772 25 8V12C25 12.5523 24.5523 13 24 13H22.913" stroke="var(--icon-color)" />
    </svg>
  );
}
