import { useGetCall, useLazyGetCallWithURL } from 'common/api/useApiCall';
import { AUDIT_LOGS_BASE_URL, URLS, headers } from 'constants/URL';
import { createParams } from 'helpers/createParams';
import { useEffect, useMemo, useState } from 'react';
import { omit } from 'lodash';
import {
  auditLogResponseAdapter,
  auditLogsFilterResponseAdapter,
  auditLogsReasonsFilterAdapter,
} from './auditLogsResponseAdapter';
import moment from 'moment';
import { adaptFilterBeforeCall } from 'helpers/adaptFilterBeforeCall';
import { useDataAndCount } from 'common/Hooks/useDataAndCount';

export const useAuditLogs = () => {
  const end = new Date();
  const start = new Date();
  start.setMonth(start.getMonth() - 1);

  const [auditLogs, setAuditLogs] = useState([]);
  const [filters, setFilters] = useState({
    'start-time': start.toISOString(),
    'end-time': end.toISOString(),
  } as any);

  const fileDownload = require('js-file-download');

  const { response: users } = useGetCall(
    URLS.fetchAuditLogsUsers,
    auditLogsFilterResponseAdapter,
  );
  const { response: types } = useGetCall(
    URLS.fetchAuditLogsTypes,
    auditLogsFilterResponseAdapter,
  );
  const { response: tenants } = useGetCall(
    `${URLS.fetchAuditLogsTenants}?with-id=false`,
    auditLogsFilterResponseAdapter,
  );
  const { response: reasons } = useGetCall(
    URLS.fetchAuditLogsReasons,
    auditLogsReasonsFilterAdapter,
  );

  const params = useMemo(() => {
    return {
      filter: adaptFilterBeforeCall(filters),
    };
  }, [filters]);

  const {
    page,
    setPage,
    pageCount,
    dataResponse: auditLogResponse,
    dataIsLoading: auditLogsIsLoading,
    dataResponseStatus: auditLogResponseStatus,
    count: auditLogsCount,
    handlePaginationChange,
  } = useDataAndCount({
    url: `${AUDIT_LOGS_BASE_URL}`,
    dataResponseAdapter: auditLogResponseAdapter,
    params: params,
  });

  const {
    response: downloadAuditLogResponse,
    isLoading: downloadIsLoading,
    postData: downloadAuditLog,
  } = useLazyGetCallWithURL();

  useEffect(() => {
    if (auditLogResponseStatus === 200) {
      setAuditLogs(auditLogResponse || []);
    }
  }, [auditLogResponse]);

  useEffect(() => {
    if (downloadAuditLogResponse?.length) {
      fileDownload(downloadAuditLogResponse, 'report.csv');
    }
  }, [downloadAuditLogResponse]);

  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    setFilters((prev) =>
      (typeof val === 'string' && val?.toLowerCase() !== 'all') ||
      typeof val !== 'string'
        ? { ...prev, [filterKey]: val }
        : omit({ ...prev }, [filterKey]),
    );
  };

  const downloadData = (_filters) => {
    const _url = `${URLS.downloadAuditLogs}?${createParams({
      filter: adaptFilterBeforeCall(_filters),
    })}`;
    downloadAuditLog(_url);
    const _filename = `report-${moment().format('YYYY_MM_DDD_HH_mm_ss')}.csv`;

    //TODO: Move to function
    fetch(_url, {
      method: 'GET',
      headers: headers(),
    })
      .then((response) => {
        return response.text();
      })
      .then((v) => {
        fileDownload(v, _filename);
      })
      .catch((err) => console.log(err));
  };

  const handleResetFilters = () => {
    setFilters({
      'start-time': start.toISOString(),
      'end-time': end.toISOString(),
    });
  };

  return {
    page,
    filters,
    auditLogsIsLoading,
    pageCount,
    auditLogsCount,
    auditLogs,
    users,
    types,
    tenants,
    reasons,
    handlePaginationChange,
    handleResetFilters,
    handleFilterChange,
    downloadData,
    downloadIsLoading,
  };
};
