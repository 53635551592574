import { Stack, Typography } from '@mui/material';
import React from 'react';

interface LabelActionValueProps {
  label?: any;
  value?: any;
  action?: any;
  overrideStyles?: any;
}

function LabelValueAction(props: LabelActionValueProps) {
  const { label, value, action, overrideStyles } = props;
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="700px"
      maxWidth="700px"
      height="100%"
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        width="160px"
        minWidth="160px"
        maxWidth="160px"
        height="100%"
        py="16px"
        flexGrow={0}
        sx={overrideStyles?.labelContainer}
      >
        <Typography fontWeight="600" fontSize="14px">
          {label}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        flexGrow={1}
        p="16px"
        sx={overrideStyles?.valueActionContainer}
      >
        <Stack
          flexGrow={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          pr={2}
        >
          {value}
        </Stack>
        <Stack
          flexGrow={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {action}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default LabelValueAction;
