import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useIAM = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    if (params['*'] === '') {
      navigate('users');
    }
  }, []);
  return {};
};
