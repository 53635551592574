export default function ModalCrossIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8334 5.34199L14.6584 4.16699L10 8.82533L5.34169 4.16699L4.16669 5.34199L8.82502 10.0003L4.16669 14.6587L5.34169 15.8337L10 11.1753L14.6584 15.8337L15.8334 14.6587L11.175 10.0003L15.8334 5.34199Z"
        fill="#666666"
      />
    </svg>
  );
}
