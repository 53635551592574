import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import _includes from 'lodash/includes';

import { useFetchCloudLocations } from 'common/cloud-region-location/lib/component/useFetchCloudLocations';
import BackButton from 'common/custom-components/lib/components/BackButton';
import Cache from 'common/cache/lib/utils/Cache';

import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';
import { AMHeader } from './AMHeader';
import { AvailabilityMachineRowType } from 'customers/tenants-common-view/lib/schema/AvailabilityMachine.types';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { AvMachineSummary } from './AMSummary';
import { AMTabView } from './AMTabView';
import { URLS } from 'constants/URL';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { useAMDetails } from './useAMDetails';

type AMDetailedViewPropType = {
  availabilityMachine: AvailabilityMachineRowType;
  service?: DatabaseServiceType;
  amId?: string;
};

export default function AMDetailedView(props: AMDetailedViewPropType) {
  const params = useParams();
  const { regionMap } = useFetchCloudLocations();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    availabilityMachine,
    availabilityMachineIsLoading,
    service,
    serviceIsLoading,
  } = useAMDetails();

  return (
    <div className="page-content vh-100">
      <LoadingOverlay
        isLoading={
          !service?._id || availabilityMachineIsLoading || serviceIsLoading
        }
      >
        <Box display="flex" flexDirection="column" px={4} pb={2} pt={1}>
          <BackButton />
          <AMHeader avMachine={availabilityMachine} />
          <AvMachineSummary
            avMachine={availabilityMachine}
            service={service}
            regionMap={regionMap}
          />
          <AMTabView service={service} avMachine={availabilityMachine} />
        </Box>
      </LoadingOverlay>
    </div>
  );
}
