import _isEmpty from 'lodash/isEmpty';
import { Button, Stack, Typography } from '@mui/material';
import { VNTabView } from '../vn-tab-view/VNTabView';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';
import { ArrowBackRounded } from '@mui/icons-material';
import { VNSummary } from '../vn-summary/VNSummary';
import useStyles from 'common/useStyles';
import { useVNDetailedView } from './useVNDetailedView';

export const VNDetailedView = () => {
  const classes = useStyles();

  const { tenant, vnDetails, handleBackClick } = useVNDetailedView();

  return (
    <Stack className="page-content vh-100">
      <LoadingOverlay isLoading={!vnDetails?.id}>
        <Stack px={2}>
          <Stack direction="row">
            <Button onClick={handleBackClick} sx={classes.backButton}>
              <ArrowBackRounded />
            </Button>
            <Typography variant="h6">{`${vnDetails?.name}`}</Typography>
          </Stack>
          <VNSummary tenant={tenant} vnDetails={vnDetails} />
          <VNTabView tenant={tenant} vnDetails={vnDetails} />
        </Stack>
      </LoadingOverlay>
    </Stack>
  );
};
