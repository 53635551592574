export const singleTenantCostStyles = {
  chartContainer: {
    width: '1200px',
    height: '75vh',
    p: 3,
    alignItems: 'center',
  },
  titleContainer: {
    flexDirection: 'row',
    py: 1,
    width: '1200px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
