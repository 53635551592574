export const dapResponseAdapter = (response) => {
  return (
    response?.response?.map((dap) => {
      return {
        id: dap?.id || '-',
        name: dap?.name || '-',
        status: dap?.status || '-',
        sharedWith: dap?.sharedWith?.users || [],
        contentType: dap?.contentType || '-',
        type:
          dap?.contentInfo?.asIsContent?.automated === true
            ? 'Automated'
            : 'Manual',
        cloudRegions: dap?.cloudAvailability || [],
        availability:
          dap?.dataAccessConfig?.retentionConfig?.dailyBackups || '-',
      };
    }) || []
  );
};
