import { PageUnderConstruction } from 'authentication/authentication-inner/Errors/PageUnderConstruction';

export const AppUnderConstruction = () => {
  document.title = 'Tessell Operations | App Under Construction';
  return (
    <>
      <div className="page-content">
        <div className="padding-top-2">
          <PageUnderConstruction />
        </div>
      </div>
    </>
  );
};
