import { Auth } from 'aws-amplify';
import { currentAwsConfig } from './awsConfigure';

export const signIn = async (
  email,
  password,
  setUserLoginInfo,
  navigate,
  setSubmitting,
  setErrMessage,
) => {
  const awsConfig = currentAwsConfig;
  setErrMessage('');
  setSubmitting(true);
  try {
    const userSignIn = await Auth.signIn(email, password);
    setUserLoginInfo(userSignIn);
    if (
      userSignIn?.challengeName === 'SOFTWARE_TOKEN_MFA' &&
      !userSignIn?.signInUserSession
    ) {
      navigate('/multi-factor-authentication');
    }
    if (
      userSignIn?.challengeName === 'NEW_PASSWORD_REQUIRED' &&
      !userSignIn?.signInUserSession
    ) {
      navigate('/reset-password');
    }
    if (
      userSignIn?.challengeName === undefined &&
      userSignIn?.signInUserSession?.idToken?.jwtToken.length
    ) {
      navigate('/dashboard');
      setSubmitting(false);
    }
    if (
      userSignIn?.challengeName === 'MFA_SETUP' &&
      !userSignIn?.signInUserSession
    ) {
      navigate('/mfa-setup');
    }
    setSubmitting(false);
    setErrMessage('');
    return userSignIn;
  } catch (err: any) {
    setErrMessage(err?.message || 'Error signing in');
    setSubmitting(false);
    setUserLoginInfo({});
    return err;
  }
};
