/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Button,
  DialogContentText,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { ChipListing } from 'common/ChipListing/ChipListing';

type AssigneeModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  handleConfirm: (assignee) => void;
  isLoading?: boolean;
  users: any;
  filters?: any;
};

const AssigneeModal = ({
  open,
  setOpen,
  onClose,
  handleConfirm,
  isLoading,
  users,
  filters = null,
}: AssigneeModalProps) => {
  const [user, setUser] = useState('');

  const DialogTitle = () => <>Update Assignee</>;

  const DialogContent = () => (
    <DialogContentText id="alert-dialog-description">
      {filters && Object.keys(filters)?.length && (
        <Stack m={2}>
          <Typography variant="h6" my={2}>
            {' '}
            Assign all alerts with following config
          </Typography>
          <ChipListing data={filters} />
        </Stack>
      )}
      <TessellInput
        label="Assignee"
        variant="outlined"
        size="small"
        width={'300px'}
        name="reason"
        select
        onChange={(e) => {
          setUser(e.target.value);
        }}
        options={users?.map((v) => (
          <MenuItem key={v.value} value={v.value}>
            {v.label}
          </MenuItem>
        ))}
      />
    </DialogContentText>
  );

  const DialogActions = () => (
    <Stack width="100%" direction="row" justifyContent="flex-start" mt={2}>
      <Stack spacing={1} direction="row" justifyContent="flex-end" width="100%">
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
          size="small"
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={() => {
            handleConfirm(user);
          }}
          color="primary"
          size="small"
          variant="contained"
          loading={isLoading}
          disabled={!user}
        >
          Update
        </LoadingButton>
      </Stack>
    </Stack>
  );

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: {},
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={() => (onClose ? onClose() : setOpen(false))}
    />
  );
};

export default AssigneeModal;
