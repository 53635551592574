import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useLazyPostCall, useLazyPutCall } from 'common/api/useApiCall';
import { GROUPS_BASE_URL, URLS } from 'constants/URL';
import { useContext, useEffect, useState } from 'react';

type UpdateGroupPermissionsProps = {
  handleOnSuccess: Function;
  setOpen: Function;
  group: any;
};

export const useUpdateGroupPermissions = (
  props: UpdateGroupPermissionsProps,
) => {
  const { handleOnSuccess, setOpen, group } = props;
  const [permissions, setPermissions] = useState(group?.permissions);
  const { addNotification } = useContext(NotificationContext);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const {
    response: updateGroupPermissionsResponse,
    postData: updateGroupPermissions,
    responseStatus,
    isLoading,
  } = useLazyPostCall(`${GROUPS_BASE_URL}/${group?.name}/permissions`);

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    const payload = {
      permissions: permissions,
    };
    updateGroupPermissions(JSON.stringify(payload));
  };

  useEffect(() => {
    if (updateGroupPermissionsResponse?.status === 'SUCCESSFUL') {
      addNotification({
        severity: 'success',
        message: `${updateGroupPermissionsResponse?.message}`,
      });
      handleOnSuccess();
    } else if (updateGroupPermissionsResponse?.status) {
      addNotification({
        severity: 'error',
        message:
          updateGroupPermissionsResponse?.message || 'Something went wrong',
      });
    }
  }, [updateGroupPermissionsResponse]);

  return {
    isLoading,
    permissions,
    setPermissions,
    onClose,
    onSubmit,
    confirmOpen,
    setConfirmOpen,
  };
};
