export default function AlertIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0001 7.96505V11.7682"
        stroke="#F29132"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1282 2.4576C11.0219 2.24995 10.8602 2.07568 10.6612 1.95399C10.4621 1.8323 10.2333 1.76791 9.99998 1.76791C9.76666 1.76791 9.53787 1.8323 9.3388 1.95399C9.13973 2.07568 8.9781 2.24995 8.87171 2.4576L1.89928 16.4025C1.80204 16.5954 1.75574 16.8101 1.76478 17.026C1.77383 17.2419 1.83792 17.4519 1.95096 17.6361C2.06401 17.8203 2.22225 17.9725 2.41068 18.0783C2.59911 18.1841 2.81145 18.24 3.02755 18.2406H16.9724C17.1885 18.24 17.4009 18.1841 17.5893 18.0783C17.7777 17.9725 17.936 17.8203 18.049 17.6361C18.162 17.4519 18.2261 17.2419 18.2352 17.026C18.2442 16.8101 18.1979 16.5954 18.1007 16.4025L11.1282 2.4576Z"
        stroke="#F29132"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="14.8937" r="1" fill="#F29132" />
    </svg>
  );
}
