import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';

const TessellTable = (props) => {
  const { columns, rows } = props;
  return (
    <TableContainer>
      <Table size="small">
        <TableHead sx={{ background: '#F4F9FA' }}>
          <TableRow sx={{ borderRadius: '50%' }}>
            {columns?.map((column) => (
              <>
                {!column?.hide && (
                  <TableCell sx={{ paddingY: '12px' }}>
                    <Stack sx={{ ...column?.styles, color: '#11567F' }}>
                      <strong>{column?.headerName}</strong>
                    </Stack>
                  </TableCell>
                )}
              </>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row?.id}>
              {columns.map((column) => (
                <>
                  {!column?.hide && (
                    <TableCell
                      variant="body"
                      component="th"
                      scope="row"
                      paddingY="12px"
                    >
                      <Stack sx={column?.styles}>
                        <OverflowTooltip
                          text={row?.[column?.field]}
                          variant="body"
                        />
                      </Stack>
                    </TableCell>
                  )}
                </>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TessellTable;
