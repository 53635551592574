import {
  useGetCall,
  useLazyPostCall,
  useLazyPutCallWithURL,
} from 'common/api/useApiCall';
import { ALERTS_BASE_URL, URLS, USERS_BASE_URL } from 'constants/URL';
import { useState, useEffect, useMemo, useContext } from 'react';
import {
  adaptAlertsResponse,
  alertActionResponseAdapter,
} from './adaptAlertsResponse';
import { useCreateAlertJira } from 'customers/tenants-detailed-view/hooks/useCreateAlertJira';
import { createCommaSeparatedKeyValuesFromObject } from 'helpers/createParams';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { adaptFilterBeforeCall } from 'helpers/adaptFilterBeforeCall';
import { useDataAndCount } from 'common/Hooks/useDataAndCount';
import Cache from 'common/cache/lib/utils/Cache';

export const UPDATE_TYPE = {
  SINGLE: 'single',
  BULK: 'bulk',
};

export const useAlerts = () => {
  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState<any>();
  const [dbsInCompute, setDbsInCompute] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openAssigneeDialog, setOpenAssigneeDialog] = useState(false);
  const [silenceAlertType, setSilenceAlertType] = useState('');
  const [silenceAlertsOpen, setSilenceAlertsOpen] = useState(false);
  const [updateAssigneeType, setUpdateAssigneeType] = useState(null);
  const [resolveAlertType, setResolveAlertType] = useState(null);

  const { addNotification } = useContext(NotificationContext);

  const start = new Date();
  const end = new Date();
  start.setDate(start.getDate() - 7);

  const [filters, setFilters] = useState<any>({
    status: 'firing',
    'tenant-phase': 'LIVE',
    'start-time': start?.toISOString(),
    'end-time': end?.toISOString(),
  });

  const params = useMemo(() => {
    return {
      filter: Object.keys(selectedLabels)?.length
        ? adaptFilterBeforeCall({
            labels: createCommaSeparatedKeyValuesFromObject(selectedLabels),
          })
        : adaptFilterBeforeCall(filters),
    };
  }, [filters, selectedLabels]);

  const {
    page,
    setPage,
    pageCount,
    dataResponse: alerts,
    dataIsLoading: isLoading,
    fetchDataAndCount,
    count: totalCount,
    handlePaginationChange,
  } = useDataAndCount({
    url: `${ALERTS_BASE_URL}`,
    dataResponseAdapter: adaptAlertsResponse,
    params: params,
  });

  const { response: usersResponse } = useGetCall(USERS_BASE_URL);

  const { response: tenantIdNamesResponse } = useGetCall(
    `${URLS.getTenantIdNames}with-id=true`,
  );

  const {
    response: updateAlertResponse,
    postData: updateAlertPostData,
    isLoading: updateAlertIsLoading,
  } = useLazyPutCallWithURL(alertActionResponseAdapter);

  const {
    response: bulkResolveAlertResponse,
    postData: bulkResolveAlert,
    responseStatus: bulkResolveAlertResponseStatus,
  } = useLazyPostCall(URLS.bulkResolveAlertsURL);
  const {
    response: bulkAssignAlertResponse,
    postData: bulkAssignAlert,
    responseStatus: bulkAssignAlertResponseStatus,
  } = useLazyPostCall(URLS.bulkAssignAlertsURL);

  const tenants = useMemo(() => {
    return tenantIdNamesResponse?.response?.length
      ? tenantIdNamesResponse?.response?.map((_t) => ({
          value: _t?._id,
          label: _t?.domain,
        }))
      : [];
  }, [tenantIdNamesResponse]);

  const users = useMemo(
    () =>
      usersResponse?.response?.map((_obj) => ({
        label: _obj.name || _obj.email,
        value: _obj.email,
      })) || [],
    [usersResponse],
  );

  const handleOnSuccess = () => {
    resetTicketState();
    setShowCreateTicketModal(false);
    fetchDataAndCount();
  };

  const handleModalClose = () => {
    resetTicketState();
    setSelectedAlert(null);
    setShowCreateTicketModal(false);
  };

  const {
    createTicket,
    resetState: resetTicketState,
    ...createTicketProps
  } = useCreateAlertJira({
    onSuccess: handleOnSuccess,
    alert: selectedAlert,
  });

  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    if (filterKey && val) {
      setFilters((prev) => ({ ...prev, [filterKey]: val }));
    }
  };

  const handleResetFilters = () => {
    setPage(1);
    setFilters({
      'start-time': start?.toISOString(),
      'end-time': end?.toISOString(),
    });
  };

  const onResolveAlert = (comment) => {
    if (resolveAlertType === UPDATE_TYPE.SINGLE) {
      updateAlertPostData(
        `${ALERTS_BASE_URL}/${selectedAlert?.id}`,
        JSON.stringify({ status: 'resolved', comment: comment }),
      );
    } else if (resolveAlertType === UPDATE_TYPE.BULK) {
      bulkResolveAlert(
        JSON.stringify({
          comment,
          filter: {
            labels: createCommaSeparatedKeyValuesFromObject(selectedLabels),
            ...filters,
          },
        }),
      );
    }
  };
  
  const handleUpdateAssignee = (_user, type = null, id = null) => {
    if (
      updateAssigneeType === UPDATE_TYPE.SINGLE ||
      type === UPDATE_TYPE.SINGLE
    ) {
      updateAlertPostData(
        `${ALERTS_BASE_URL}/${id || selectedAlert?.id}`,
        JSON.stringify({ assignee: _user }),
      );
    } else if (updateAssigneeType === UPDATE_TYPE.BULK) {
      bulkAssignAlert(
        JSON.stringify({
          assignee: _user,
          filter: {
            labels: createCommaSeparatedKeyValuesFromObject(selectedLabels),
            ...filters,
          },
        }),
      );
    }
  };

  const assignToMe = (_row) => {
    setUpdateAssigneeType(UPDATE_TYPE.SINGLE);
    setSelectedAlert(_row);
    handleUpdateAssignee(
      JSON.parse(Cache.get('loginInfo'))?.attributes?.email,
      UPDATE_TYPE.SINGLE,
      _row?.id,
    );
  };

  useEffect(() => {
    if (updateAlertResponse?.status === 'SUCCESSFUL') {
      addNotification({
        severity: 'success',
        message: `${updateAlertResponse?.message}`,
      });
      setOpenAssigneeDialog(false);
      setOpenConfirmationDialog(false);
      fetchDataAndCount();
    } else if (updateAlertResponse?.status) {
      addNotification({
        severity: 'error',
        message: updateAlertResponse?.message || 'Something went wrong',
      });
    }
  }, [updateAlertResponse]);

  useEffect(() => {
    if (bulkResolveAlertResponse?.message) {
      addNotification({
        severity: bulkResolveAlertResponseStatus === 200 ? 'success' : 'error',
        message: bulkResolveAlertResponse?.message,
      });
      setResolveAlertType(null);
      setOpenConfirmationDialog(false);
      fetchDataAndCount();
    }
  }, [bulkResolveAlertResponse?.message]);

  useEffect(() => {
    if (bulkAssignAlertResponse?.message) {
      addNotification({
        severity: bulkAssignAlertResponseStatus === 200 ? 'success' : 'error',
        message: bulkAssignAlertResponse?.message,
      });
      setUpdateAssigneeType(null);
      setOpenAssigneeDialog(false);
      fetchDataAndCount();
    }
  }, [bulkAssignAlertResponse?.message]);

  const updateSelectedLabels = (_key, _value) => {
    addNotification({
      severity: 'success',
      message: 'Copied!',
    });
    setPage(1);
    setSelectedLabels((_prev) => ({ ..._prev, [_key]: _value }));
  };

  const deleteSelectedLabel = (_key) => {
    setPage(1);
    setSelectedLabels((_prev) => {
      const _arr = { ..._prev };
      delete _arr[_key];
      return _arr;
    });
  };

  const onCloseAssignee = () => {
    setOpenAssigneeDialog(false);
    setUpdateAssigneeType(null);
  };

  const onCloseResolveAlert = () => {
    setOpenConfirmationDialog(false);
    setResolveAlertType(null);
  };

  return {
    showCreateTicketModal,
    selectedAlert,
    alerts,
    isLoading,
    createTicketProps,
    dbsInCompute,
    setShowCreateTicketModal,
    createTicket,
    setSelectedAlert,
    setDbsInCompute,
    handleModalClose,
    filters,
    tenants,
    handleFilterChange,
    handleResetFilters,
    page,
    pageCount,
    totalCount,
    handlePaginationChange,
    openConfirmationDialog,
    setOpenConfirmationDialog,
    updateAlertIsLoading,
    onResolveAlert,
    selectedLabels,
    updateSelectedLabels,
    deleteSelectedLabel,
    openAssigneeDialog,
    setOpenAssigneeDialog,
    users,
    handleUpdateAssignee,
    silenceAlertType,
    setSilenceAlertType,
    silenceAlertsOpen,
    setSilenceAlertsOpen,
    resolveAlertType,
    setResolveAlertType,
    updateAssigneeType,
    setUpdateAssigneeType,
    onCloseAssignee,
    onCloseResolveAlert,
    assignToMe,
  };
};
