import { CircularProgress, Stack, Typography } from '@mui/material';
import { useDashboard } from './hooks/useDashboard';
import { dashboardStyles } from './styles';
import {
  CHART_FAMILIES,
  CHART_FAMILIES_WITH_HALF_WIDTH,
  DISPLAY_NAME,
} from './constants';
import RegionCountWorldMap from './RegionCountWorldMap';
import { DonutChartCard } from './Charts/Cards/DonutChartCard';
import { BarChartCard } from './Charts/Cards/BarChartCard';
import { DashboardSummaryTable } from './SummaryTable/DashboardSummaryTable';
import { GenieCharts } from './Charts/GenieCharts';

export const Dashboard = () => {
  document.title = 'Tessell | Dashboard';
  const { isLoading, dataset, regionCountChartData } = useDashboard();

  return isLoading ? (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      //@ts-ignore
      sx={{ ...dashboardStyles?.fullWidthHeightLoadingOverlay }}
    >
      <CircularProgress size={40} />
    </Stack>
  ) : (
    <Stack sx={dashboardStyles.body}>
      <Stack sx={dashboardStyles.mapAndSummaryContainer}>
        <Stack sx={dashboardStyles.mapContainer}>
          <RegionCountWorldMap data={regionCountChartData} />
          <Typography sx={dashboardStyles.mapTitle}>
            Region wise tenants
          </Typography>
        </Stack>
        <DashboardSummaryTable dataset={dataset} />
      </Stack>
      <Stack sx={dashboardStyles.chartsContainer}>
        {CHART_FAMILIES.map((family) =>
          Array.isArray(dataset[family]) &&
          dataset[family]?.length &&
          dataset?.total[family] !== 0 ? (
            <Stack
              id={family}
              sx={dashboardStyles.chartFamilyContainer}
              width={
                CHART_FAMILIES_WITH_HALF_WIDTH.includes(family) ? '49%' : '100%'
              }
              direction="column"
            >
              <Typography
                sx={dashboardStyles.chartFamilyTitle}
              >{`${DISPLAY_NAME[family]}: ${dataset.total[family]}`}</Typography>
              <Stack sx={dashboardStyles.individualChartContainer}>
                {dataset[family]?.map((chart) => {
                  const labels = chart?.[0]?.['labels'];
                  if (family === 'genieLogs') {
                    return <GenieCharts genieLogs={dataset?.[family]?.[0]} />;
                  }
                  return chart?.[1]?.length > 4 ? (
                    <BarChartCard
                      data={chart?.[1] || []}
                      labels={labels || []}
                      title={chart?.[0]?.['title']?.['text']}
                    />
                  ) : (
                    <Stack
                      width={
                        CHART_FAMILIES_WITH_HALF_WIDTH.includes(family)
                          ? '100%'
                          : '49%'
                      }
                    >
                      <DonutChartCard
                        data={chart?.[1] || []}
                        labels={labels || []}
                        title={chart?.[0]?.['title']?.['text']}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          ) : (
            <></>
          ),
        )}
      </Stack>
    </Stack>
  );
};
