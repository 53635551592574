import { Card, CardBody, CardHeader, Col } from 'reactstrap';

import './TenantCard.scss';
import { TenantViewType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { Stack, Tooltip, Typography } from '@mui/material';
import { AttachMoney, ChevronRight } from '@mui/icons-material';
import { styles } from 'assets/scss/style-templates';
import { DeploymentOptions } from 'constants/deploymentOptions';
import { LabelStrings } from '../LabelStrings';
import { CreditsPopover } from './BillingPopover';
import { HealthStatus } from './HealthStatus';
import { useState } from 'react';
import { TenantCostModal } from 'customers/tenants-detailed-view/lib/components/costSingleTenant/CostSingleTenant';
import TenantStatusLabel from './TenantListView/TenantStatusLabel';
import OverflowTooltip from 'common/custom-components/lib/components/OverflowTooltip';
import TessellLabelValue from 'common/custom-components/lib/components/TessellLabelValue/TessellLabelValue';
import { TessellLabelValueProps } from 'common/custom-components/lib/components/TessellLabelValue/types';
import { tenantCardSummaryStyles } from 'customers/tenants-detailed-view/lib/TenantCardSummary/TenantCardSummaryStyles';

interface TenantCardProps {
  tenant: TenantViewType;
  handleClickOnTenant?: (id: string) => void;
}

export const TenantCard = ({
  tenant,
  handleClickOnTenant,
}: TenantCardProps): any => {
  const {
    id,
    tenantDomain,
    owner,
    company,
    status,
    country,
    contactEmail,
    billing,
    deploymentOption,
    subscriptionsForTenant,
    domainUrl,
    pgSource,
    health,
    features,
    tenantPhase,
  } = tenant;

  const [openCostmodal, setOpenCostModal] = useState(false);
  const featureList =
    features?.map((featureData) => ({
      label: featureData.displayName,
      value: featureData.status,
    })) || [];

  const cardProps: TessellLabelValueProps[] = [
    {
      label: LabelStrings?.owner || '-',
      value: owner,
    },
    {
      label: LabelStrings?.company,
      value: company,
    },
    {
      label: LabelStrings?.geoLocation,
      value: country,
    },
    {
      label: LabelStrings?.contactEmail,
      value: contactEmail,
    },
    {
      label: LabelStrings?.billing,
      value: billing,
    },
    billing === 'Enabled' && {
      label: LabelStrings?.credits,
      value: <CreditsPopover tenantId={id} tenantPgSource={pgSource} />,
    },
    {
      label: LabelStrings?.deploymentOption,
      value: deploymentOption,
    },
    {
      label: LabelStrings?.subscriptionsForTenant,
      value: subscriptionsForTenant,
    },
    {
      label: LabelStrings?.domainUrl,
      value: domainUrl,
      externalLink: domainUrl,
      newTab: true,
    },
    ...featureList,
  ];

  return (
    <Col lg={6} xxl={4} key={id} style={{ paddingBottom: '10px' }}>
      <Card className="d-flex tenant-card-wrapper">
        <CardHeader>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Stack
              direction={'row'}
              spacing={1}
              sx={{
                alignItems: 'flex-start',
                width: '75%',
              }}
            >
              <Stack maxWidth={'87%'}>
                <OverflowTooltip
                  text={tenantDomain}
                  variant="h6"
                  placement="top"
                />
                {deploymentOption === DeploymentOptions.PROFESSIONAL ? (
                  <Typography>{contactEmail}</Typography>
                ) : (
                  ''
                )}
              </Stack>
              <Stack>
                {tenantPhase ? (
                  <TenantStatusLabel status={tenantPhase} />
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{
                height: '30px',
                paddingLeft: '8px',
                paddingRight: '3px',
                paddingTop: '3px',
                paddingBottom: '3px',
                borderRadius: '4px',
              }}
            >
              <Stack
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenCostModal(true);
                }}
              >
                <Tooltip title="Click to view cost details" arrow>
                  <AttachMoney color="primary" />
                </Tooltip>
              </Stack>
              <HealthStatus health={tenant.health || {}} />
            </Stack>
          </Stack>
        </CardHeader>
        <CardBody>
          {cardProps?.map((row) => {
            return (
              row && (
                <TessellLabelValue
                  {...row}
                  containerStyles={tenantCardSummaryStyles.labelValueStyles}
                />
              )
            );
          })}
        </CardBody>
        <Stack className="card-footer" p={2}>
          <Stack
            alignItems="end"
            onClick={() => handleClickOnTenant(id)}
            sx={{ cursor: 'pointer' }}
          >
            <Stack
              color={styles.color.darkShade}
              direction="row"
              alignItems="center"
            >
              <Typography fontWeight={700}>More Details</Typography>
              <ChevronRight />
            </Stack>
          </Stack>
        </Stack>
      </Card>
      {openCostmodal && (
        <TenantCostModal
          open={openCostmodal}
          setOpen={setOpenCostModal}
          tenantDomain={tenantDomain}
        />
      )}
    </Col>
  );
};
