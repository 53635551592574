import { Box, Typography } from '@mui/material';
import { useGetCall } from 'common/api/useApiCall';
import BackButton from 'common/custom-components/lib/components/BackButton';
import { TENANTS_BASE_URL, URLS } from 'constants/URL';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DBServersDetailsTabView from './DBServerDetailsTabView';
import { tenantByIdResponseAdapter } from 'common/TenantContainer/tenantByIdResponseAdapter';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';

const DBServersDetailedView = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    response: dBServersResponse,
    isLoading: dBServersLoading,
    postData: fetchDBServers,
  } = useGetCall(`${URLS.getComputeDetails}${params.dbServerId}`);

  const {
    response: tenantResponse,
    isLoading: tenantIsLoading,
    postData: fetchTenantByID,
  } = useGetCall(
    `${TENANTS_BASE_URL}/${params.tenantId}`,
    tenantByIdResponseAdapter,
  );

  const dbServer = useMemo(() => {
    return dBServersResponse?.response?.[0];
  }, [dBServersResponse]);

  useEffect(() => {
    if (params['*'] === '') {
      navigate('overview');
    }
  }, []);

  return (
    <div className="page-content vh-100">
      <LoadingOverlay isLoading={!tenantResponse?._id || !dbServer?._id}>
        <Box display="flex" flexDirection="column" px={4} pb={2} pt={1}>
          <BackButton />
          <Typography variant="h5" pb={2}>
            {dbServer?.name}
          </Typography>

          <div>
            <DBServersDetailsTabView tenant={tenantResponse} />
          </div>
        </Box>
      </LoadingOverlay>
    </div>
  );
};

export default DBServersDetailedView;
