/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
/*
*
* Copyright © Tessell Inc, 2021. All rights reserved.
*
*     Date               Author                  Comment
*   --------------------------------------------------
*     12/22/2021     bakul.banthia         Created
*
*/

import clsx from 'clsx';
import { useStyles } from './utils';

export default function SLAIcon(props) {
  const { className } = props;
  const iconStyle = useStyles(props);

  return (
    <svg className={clsx({ [className]: !!className })} style={iconStyle} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1651_644)">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.74024 6.60889H9.56025C9.87091 6.60889 10.1227 6.86073 10.1227 7.17139C10.1227 7.48205 9.87091 7.73389 9.56025 7.73389H5.59692L4.05311 13.3302L10.734 18.6356C10.8053 18.6922 10.907 18.6894 10.9751 18.6289L12.0036 17.7147L10.7269 16.5976C10.4931 16.3931 10.4694 16.0377 10.674 15.8039C10.8786 15.5701 11.2339 15.5464 11.4677 15.751L13.2151 17.2799L13.9328 17.8541L15.0778 16.8364L13.8011 15.7193C13.5673 15.5147 13.5436 15.1594 13.7482 14.9256C13.9527 14.6918 14.3081 14.6681 14.5419 14.8726L16.3127 16.4221L16.8705 16.9799L18.1206 16.1049L12.7343 10.9047C12.5108 10.689 12.5045 10.3329 12.7203 10.1094C12.9361 9.88587 13.2922 9.87961 13.5157 10.0954L19.0657 15.4536C19.4899 15.8631 19.4352 16.5579 18.9521 16.8961L17.3868 17.9918C17.0141 18.2527 16.508 18.2083 16.1863 17.8867L15.9055 17.6058L14.562 18.8C14.2206 19.1035 13.7102 19.1167 13.3535 18.8314L12.8731 18.447L11.7225 19.4697C11.2459 19.8934 10.5337 19.9132 10.0343 19.5166L2.77072 13.7484L4.74024 6.60889Z" fill="var(--icon-color)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.1623 6.05011C10.6532 5.69948 11.2414 5.51099 11.8446 5.51099C12.1331 5.51099 12.42 5.55413 12.6958 5.63898L18.3188 7.36914L21.2803 14.2792L17.7804 15.7375L12.6252 10.7806C12.3223 10.4893 11.8624 10.4352 11.5001 10.6483L9.39155 11.8887C8.80058 12.2363 8.05496 12.1754 7.52824 11.7364C6.88376 11.1994 6.75434 10.2598 7.22962 9.56853L8.72301 7.39632C9.00735 6.98274 9.36169 6.62197 9.7701 6.33025L10.1623 6.05011ZM11.8446 6.63599C11.4758 6.63599 11.1163 6.75121 10.8162 6.96556L10.424 7.2457C10.1221 7.46132 9.86022 7.72798 9.65006 8.03367L8.15667 10.2059C8.01059 10.4184 8.05036 10.7071 8.24845 10.8722C8.41034 11.0071 8.63951 11.0258 8.82115 10.919L10.9297 9.67865C11.7268 9.20981 12.7384 9.32875 13.405 9.96967L18.0285 14.4153L19.7986 13.6778L17.49 8.29117L12.365 6.71423C12.1964 6.66236 12.021 6.63599 11.8446 6.63599Z" fill="var(--icon-color)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.10007 5.8227C2.29364 5.12415 3.01685 4.71478 3.7154 4.90835L4.8806 5.23123C5.57915 5.4248 5.98852 6.14801 5.79495 6.84656L3.72808 14.3055C3.53451 15.004 2.8113 15.4134 2.11275 15.2198L0.947545 14.8969C0.248994 14.7034 -0.160373 13.9801 0.0331968 13.2816L2.10007 5.8227ZM3.41498 5.9925C3.31519 5.96484 3.21187 6.02332 3.18422 6.12312L1.11734 13.582C1.08969 13.6818 1.14817 13.7851 1.24796 13.8128L2.41317 14.1357C2.51296 14.1633 2.61628 14.1048 2.64393 14.005L4.71081 6.54614C4.73846 6.44634 4.67998 6.34303 4.58018 6.31538L3.41498 5.9925Z" fill="var(--icon-color)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M20.5342 5.55775C20.2493 4.8912 19.478 4.5818 18.8115 4.86667L17.6899 5.34603C17.0234 5.63091 16.7139 6.40219 16.9988 7.06874L20.2259 14.5469C20.5108 15.2135 21.282 15.5229 21.9486 15.238L23.0702 14.7586C23.7367 14.4738 24.0461 13.7025 23.7613 13.0359L20.5342 5.55775ZM19.2536 5.90115C19.3488 5.86046 19.459 5.90466 19.4997 5.99988L22.7268 13.4781C22.7675 13.5733 22.7233 13.6835 22.6281 13.7242L21.5065 14.2035C21.4112 14.2442 21.3011 14.2 21.2604 14.1048L18.0333 6.62661C17.9926 6.53139 18.0368 6.42121 18.132 6.38051L19.2536 5.90115Z" fill="var(--icon-color)" />
      </g>
      <defs>
        <clipPath id="clip0_1651_644">
          <rect width="24" height="24" fill="var(--icon-color)" />
        </clipPath>
      </defs>
    </svg>
  );
}
