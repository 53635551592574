import { Stack, Typography } from '@mui/material';
import { useStyles } from '../../../../customers/db-services-detailed-view/utils';
import { styles } from 'assets/scss/style-templates';

export const SubscriptionStatusString = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  CREATING: 'Creating',

  FAILED: 'Failed',
  EXPIRED: 'Expired',
  DELETING: 'Deleting',
  DELETION_FAILED: 'Deletion Failed',
  DELETED: 'Deleted',
};

const SubscriptionStatusConfig = {
  INACTIVE: {
    text: SubscriptionStatusString.INACTIVE,
    textColor: styles.color.baseBackground,
    backgroundColor: styles.color.red,
  },
  ACTIVE: {
    text: SubscriptionStatusString.ACTIVE,
    textColor: styles.color.baseBackground,
    backgroundColor: styles.color.green,
  },
  CREATING: {
    text: SubscriptionStatusString.CREATING,
    textColor: styles.color.baseBackground,
    backgroundColor: styles.color.green,
  },
  FAILED: {
    text: SubscriptionStatusString.FAILED,
    textColor: styles.color.baseBackground,
    backgroundColor: styles.color.red,
  },
  EXPIRED: {
    text: SubscriptionStatusString.EXPIRED,
    textColor: styles.color.baseBackground,
    backgroundColor: styles.color.red,
  },
  DELETING: {
    text: SubscriptionStatusString.DELETING,
    textColor: styles.color.baseBackground,
    backgroundColor: styles.color.red,
  },
  DELETION_FAILED: {
    text: SubscriptionStatusString.DELETION_FAILED,
    textColor: styles.color.baseBackground,
    backgroundColor: styles.color.red,
  },
  DELETED: {
    text: SubscriptionStatusString.DELETED,
    textColor: styles.color.baseBackground,
    backgroundColor: styles.color.red,
  },
  DEFAULT: {
    text: 'NA',
    textColor: styles.color.baseBackground,
    backgroundColor: styles.color.warn,
  },
};

export const SubscriptionStatusIcon = ({ status }) => {
  const classes = useStyles();
  const config =
    SubscriptionStatusConfig[status] || SubscriptionStatusConfig.DEFAULT;
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        ...classes.statusBox,
        backgroundColor: config.backgroundColor,
      }}
    >
      <Typography color={config.textColor} mr={1}>
        {config.text}
      </Typography>
    </Stack>
  );
};
