import { useMemo } from 'react';
import { adaptAvailabilityMachinesResponse } from './adaptAvailabilityMachineResponse';
import { AVAILABILITY_MACHINES_BASE_URL } from 'constants/URL';
import { useFetchCloudLocations } from 'common/cloud-region-location/lib/component/useFetchCloudLocations';
import { useNavigate } from 'react-router-dom';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { useDataAndCount } from 'common/Hooks/useDataAndCount';

type AvailabilityMachineProps = {
  tenant?: TenantType;
  service?: DatabaseServiceType;
};

export const useAvailabilityMachine = ({
  tenant,
  service,
}: AvailabilityMachineProps) => {
  const { regionMap } = useFetchCloudLocations();
  const navigate = useNavigate();

  const availabilityMachineParams = useMemo(() => {
    return tenant || service
      ? {
          [tenant ? 'tenant-id' : 'dbservice-id']: tenant
            ? tenant?._id
            : service?._id,
        }
      : {};
  }, [tenant, service]);

  const handleRowClick = (params) => {
    const { row: data } = params;
    const url = `/database-services/details/${data?.dbID}/availability-machine/${data?.id}/pitr`;
    navigate(url);
  };

  const {
    count: availabilityMachinesCount,
    pageCount: availabilityMachinesPageCount,
    dataResponse: availabilityMachinesResponse,
    dataIsLoading: availabilityMachinesIsLoading,
    page,
    handlePaginationChange,
  } = useDataAndCount({
    url: AVAILABILITY_MACHINES_BASE_URL,
    dataResponseAdapter: adaptAvailabilityMachinesResponse,
    params: availabilityMachineParams,
  });

  return {
    regionMap,
    availabilityMachines: availabilityMachinesResponse,
    availabilityMachinesCount,
    handleRowClick,
    availabilityMachinesIsLoading,
    availabilityMachinesPageCount,
    page,
    handlePaginationChange,
  };
};
