import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import OverflowTooltip from 'common/overflow-tooltip/lib/components/OverflowTooltip';
import ComputeInstance from '../ComputeInstance';
import { DataGridColumnType } from 'common/mui-data-grid/lib/schema/DataGridColumn.types';
import { useContext, useMemo } from 'react';
import {
  GetEngineIcon,
  GetInstanceStatus,
  useStyles,
} from 'customers/db-services-detailed-view/utils';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import UpdateMetadata from 'common/UpdateMetadata/UpdateMetadata';
import { CloudAccountDetailsModal } from 'common/Modals/CloudAccountDetailsModal/CloudAccountDetailsModal';
import { CLOUD_LIST, ENGINE_LIST, STATUS_LIST } from './constants';
import { useDBServices } from './useDBServices';
import { DBServiceTableStyles } from './styles';
import { useTheme } from '@mui/styles';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { DBSERVICES_PER_PAGE } from 'constants/appConstants';

interface TenantDBServicesProps {
  tenant?: TenantType;
  dataGridColumns?: DataGridColumnType[];
  subscriptionId?: string;
  computeID?: string;
}

export const DbServicesTable = (props: TenantDBServicesProps) => {
  const {
    dataGridColumns = [],
    subscriptionId,
    tenant = null,
    computeID = null,
  } = props;
  const classes = useStyles();
  const theme: any = useTheme();
  const { hasPermission } = useContext(UserPermissionsContext);

  const {
    filters,
    subsList,
    open,
    page,
    regionMap,
    isLoadingServices,
    servicesCount,
    servicesPageCount,
    setCloudAccountID,
    dBServicesRows,
    cloudAccountID,
    selectedDbService,
    updateMetadataOpen,
    createParams,
    setOpen,
    setSelectedDbService,
    setUpdateMetadataOpen,
    handleFilterChange,
    handleRowClick,
    handlePaginationChange,
    handleResetFilters,
    handleExportClick,
    exportIsLoading,
  } = useDBServices({ tenant, subscriptionId, computeID });

  const filterProps = useMemo(
    () =>
      [
        {
          name: 'Engine',
          initialVal: filters?.engine || 'ALL',
          column: 'engineType',
          options: ENGINE_LIST,
          onChange: (value) => handleFilterChange('engine', value),
        },
        {
          name: 'Cloud',
          initialVal: filters?.cloud || 'ALL',
          column: 'cloud',
          options: CLOUD_LIST,
          onChange: (value) => handleFilterChange('cloud', value),
        },
        {
          name: 'Status',
          initialVal: filters?.status || 'ALL',
          column: 'status',
          options: STATUS_LIST,
          onChange: (value) => handleFilterChange('status', value),
        },
        subsList?.length && {
          name: 'Subscription',
          initialVal: filters?.subscription || 'ALL',
          column: 'subscription',
          options: [
            {
              id: 'ALL',
              name: 'All',
            },
            ...subsList,
          ],
          onChange: (value) => handleFilterChange('subscription', value),
        },
      ].filter(Boolean),
    [filters],
  );

  const searchProps = useMemo(
    () => ({
      name: 'Search',
      onChange: (value) => handleFilterChange('search-query', value),
    }),
    [],
  );

  const tenantDBServicesColumns: DataGridColumnType[] = useMemo(
    () =>
      [
        {
          field: 'id',
          headerName: 'ID',
          minWidth: 120,
          hide: true,
        },
        {
          field: 'engineType',
          headerName: 'Engine Type',
          hide: true,
        },
        {
          field: 'name',
          headerName: 'Name',
          renderCell: (params) => {
            const { row: data } = params;
            const { name, engineType: engine } = data;
            return (
              <Stack sx={DBServiceTableStyles.NameColumn.container}>
                <Stack spacing={2} direction={'row'} alignItems={'center'}>
                  <GetEngineIcon engine={engine} size={null} />
                  <Stack>
                    <OverflowTooltip
                      text={name}
                      sxClass={classes.boldText}
                      color="textPrimary"
                    />
                  </Stack>
                </Stack>
              </Stack>
            );
          },
          minWidth: 350,
        },
        {
          field: 'status',
          headerName: 'Status',
          minWidth: 120,
          renderCell: (params) => {
            const { row } = params;
            return <GetInstanceStatus status={row.status} />;
          },
        },
        {
          field: 'topology',
          headerName: 'Topology',
          minWidth: 120,
        },
        subsList?.length && {
          field: 'subscription',
          headerName: 'Subscription',
          minWidth: 120,
        },
        {
          field: 'subscriptionId',
          headerName: 'Subscription Id',
          minWidth: 120,
          hide: true,
        },
        {
          field: 'cloud',
          headerName: 'Cloud/Region',
          minWidth: 120,
          renderCell: (params) => {
            const { row: data } = params;
            const { cloud, region } = data;
            if (!cloud) {
              return 'NA';
            }

            const regionText = regionMap[region];

            return (
              <Stack
                onClick={(ev) => {
                  ev.stopPropagation();
                  setCloudAccountID(`${data.cloudAccountId}`);
                  setOpen(true);
                }}
              >
                {cloud && (
                  <Typography
                    sx={{ fontWeight: theme?.typography?.fontWeightMedium }}
                  >
                    {cloud?.toUpperCase()}
                  </Typography>
                )}
                {regionText && (
                  <OverflowTooltip text={regionText} placement="right" />
                )}
              </Stack>
            );
          },
        },
        {
          field: 'compute',
          headerName: 'Compute',
          minWidth: 120,
          renderCell: (params) => {
            const { row } = params;
            return <ComputeInstance value={row?.compute} row={row} />;
          },
        },
        {
          field: 'createdOn',
          headerName: 'Created',
          minWidth: 180,
        },
        {
          field: 'owner',
          headerName: 'Owner',
          minWidth: 120,
        },
        ...dataGridColumns,
      ].filter((col) => col),
    [dataGridColumns, dBServicesRows],
  );

  const menuItemsProps = useMemo(
    () =>
      [
        hasPermission(PERMISSIONS.UPDATE_TENANT) && {
          label: 'Update Metadata',
          onClick: (row) => {
            setSelectedDbService(row);
            setUpdateMetadataOpen(true);
          },
        },
      ].filter((a) => a),
    [],
  );

  return (
    <>
      <Stack sx={DBServiceTableStyles.tableContainer}>
        <DataGrid
          columns={tenantDBServicesColumns}
          rows={dBServicesRows.filter((r) => !!r)}
          menuItems={menuItemsProps}
          disableSelectionOnClick
          components={{
            Toolbar: CustomDataGridToolbar,
            DetailPanelCollapseIcon: ExpandMore,
            DetailPanelExpandIcon: ChevronRight,
          }}
          componentsProps={{
            toolbar: { showQuickFilter: true },
          }}
          itemsPerPage={DBSERVICES_PER_PAGE}
          styleOverrides={{ hideShadow: true }}
          onRowClick={handleRowClick}
          filters={filterProps}
          customExportData={
            hasPermission(PERMISSIONS.DOWNLOAD_TAB_ACCESS) && {
              isLoading: exportIsLoading,
              handleExportClick: handleExportClick,
            }
          }
          loading={isLoadingServices}
          apiFilter={true}
          totalCount={servicesCount || 0}
          search={searchProps}
          componentName={'db-services-table'}
          onResetFilters={handleResetFilters}
          pageCount={servicesPageCount}
          pageNo={page}
          handlePaginationChange={handlePaginationChange}
        />
        <CloudAccountDetailsModal
          open={open}
          setOpen={setOpen}
          cloudAccountID={cloudAccountID}
        />
        {updateMetadataOpen && (
          <UpdateMetadata
            open={updateMetadataOpen}
            setOpen={setUpdateMetadataOpen}
            entityType="dbServices"
            entityName={selectedDbService?.name}
            tenantId={selectedDbService?.service?.tenantId}
            tenantDomain={selectedDbService?.service?.pgSource}
            entityId={selectedDbService?.id}
          />
        )}
      </Stack>
    </>
  );
};
