import AppUtil from 'tools/AppUtil';

export const adaptExceptionsResponse = (response) => {
  return response?.response?.length
    ? response?.response?.map((_res) => {
        return {
          id: _res?._id,
          exception: _res?.exceptionSummary,
          exceptionId: _res?._id,
          status: AppUtil?.toTitleCase(_res?.status || ''),
          timestamp: _res?.dateCreated,
          data: _res?.data || _res?.volumeData || {},
          exceptionType: _res?.type,
          jiraId: _res?.jiraId === '-' ? '' : _res?.jiraId,
        };
      })
    : [];
};
