export const passwordChecksArr = [
  {
    text: 'Minimum of 8 characters',
    regex: /^.{8,}$/,
  },
  {
    text: 'Contains an upper case letter',
    regex: /[A-Z]/,
  },
  {
    text: 'Contains a lower case letter',
    regex: /[a-z]/,
  },
  {
    text: 'Contains a numeric character',
    regex: /[0-9]/,
  },
  {
    text: 'At least one special character ',
    subText: `("^$*.[]{}()?-"!@#%&/,><':;|_~'+=")`,
    regex: /[!#$%&'()*+,-./:;<=>?@^_`{|}~]/,
  },
];
