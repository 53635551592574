const STATUS = {
  Ready: 'READY',
  Up: 'UP',
  Down: 'DOWN',
  Stopped: 'STOPPED',
  Running: 'RUNNING',
  Deleting: 'DELETING',
  Deleted: 'DELETED',
  Starting: 'STARTING',
  Stopping: 'STOPPING',
  Creating: 'CREATING',
  Failed: 'FAILED',
  Switching: 'SWITCHOVER',
  Rebuilding: 'REBUILDING',
  Degraded: 'DEGRADED',
  Active: 'ACTIVE',
  Updating: 'UPDATING',
  DegradedHealing: 'HEALING',
  DeletionFailed: 'DELETION_FAILED',
  VmStopFailed: 'VM_STOP_FAILED',
  Invited: 'INVITED',
  Inactive: 'INACTIVE',
  resolved: 'resolved',
  warning: 'warning',
  StartFailed: 'START_FAILED',
  StopFailed: 'STOP_FAILED',
  CreationFailed: 'CREATION_FAILED',
  SwitchoverFailed: 'SWITCHOVER_FAILED',
  PatchFailed: 'PATCH_FAILED',
  RestoreFailed: 'RESTORE_FAILED',
  ConnectionStopFailed: 'CONNECTION_STOP_FAILED',
  Healing: 'HEALING',
  WarmStopped: 'WARM_STOPPED',
  ToBeDeleted: 'TO_BE_DELETED',
  DeletedForUser: 'DELETED_FOR_USER',
  SwitchingOver: 'SWITCHING_OVER',
  Restoring: 'RESTORING',
  BackingUp: 'BACKING_UP',
  Patching: 'PATCHING',
  Firing: 'firing',
  ConnectionStopped: 'CONNECTION_STOPPED',
  critical: 'critical',
  Urgent: 'Urgent',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  Requested: 'Requested',
  Successful: 'Successful',
  Unsuccessful: 'Unsuccessful',
  Open: 'Open',
  Closed: 'Closed',
  Assigned: 'Assigned',
  Fixed: 'Fixed',
  Resolved: 'Resolved',
  Duplicate: 'Duplicate',
  Investigating: 'Investigating',
  Leak: 'LEAK',
  Unknown: 'UNKNOWN',
};

export const StatusString = {
  READY: 'Ready',
  UP: 'Up',
  DOWN: 'Down',
  STOPPED: 'Stopped',
  DELETING: 'Deleting',
  STARTING: 'Starting',
  STOPPING: 'Stopping',
  CREATING: 'Creating',
  FAILED: 'Failed',
  SWITCHOVER: 'Switchover',
  REBUILDING: 'Rebuilding',
  RUNNING: 'Running',
  DEGRADED: 'Degraded',
  ACTIVE: 'Active',
  UPDATING: 'Updating',
  HEALING: 'Degraded(Healing)',
  DELETION_FAILED: 'Deletion Failed',
  VM_STOP_FAILED: 'VM Stop Failed',
  INVITED: 'Invited',
  START_FAILED: 'Start Failed',
  STOP_FAILED: 'Stop Failed',
  CREATION_FAILED: 'Creation Failed',
  SWITCHOVER_FAILED: 'Switchover Failed',
  PATCH_FAILED: 'Patch Failed',
  RESTORE_FAILED: 'Restore Failed',
  CONNECTION_STOP_FAILED: 'Connection Stop Failed',
  WARM_STOPPED: 'Warm Stopped',
  TO_BE_DELETED: 'To be Deleted',
  DELETED_FOR_USER: 'Deleted for User',
  SWITCHING_OVER: 'Switching Over',
  RESTORING: 'Restoring',
  BACKING_UP: 'BackingUp',
  PATCHING: 'Patching',
  resolved: 'Resolved',
  warning: 'Warning',
  critical: 'Critical',
  firing: 'Firing',
  INACTIVE: 'Inactive',
  CONNECTION_STOPPED: 'Connection Stopped',
  Urgent: 'Urgent',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  Open: 'Open',
  Closed: 'Closed',
  Assigned: 'Assigned',
  Fixed: 'Fixed',
  Resolved: 'Resolved',
  Duplicate: 'Duplicate',
  Investigating: 'Investigating',
  LEAK: 'Leak',
  UNKNOWN: 'Unknown',
};

export const SubStatus = {
  BackingUp: 'BACKING_UP',
  StartFailed: 'START_FAILED',
  SwitchoverFailed: 'SWITCHOVER_FAILED',
};

export default STATUS;
