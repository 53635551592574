import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import _includes from 'lodash/includes';
import { PortalAdministrationTabView } from './PortalAdministrationTabView';
import { styles } from './styles';

export default function PortalAdministration() {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (params['*'] === '') {
      navigate('alerts');
    }
  }, []);

  return (
    <Stack sx={styles.body}>
      <Stack sx={styles.title}>
        <Stack direction="row" alignItems="flex-start" spacing={2} width="50%">
          <Typography variant="h5">Monitoring</Typography>
        </Stack>
      </Stack>
      <Routes>
        <Route path={':tabId/*'} element={<PortalAdministrationTabView />} />
      </Routes>
    </Stack>
  );
}
