import React from 'react';
import {
  AvailabilityMachinesIcon,
  BillingIcon,
  CloudIcon,
  ComputeIcon,
  CostIcon,
  DBServicesIcon,
  GovernanceIcon,
  IAMIcon,
  MyDatabasesIcon,
  NetworkIcon,
  ProvisioningIcon,
  SoftwareImageIcon,
  StorageIcon,
  SubscriptionIcon,
  DataFlixIcon,
  DBGovernanceIcon,
  TicketIcon,
  UtilizationIcon,
  SupportIcon,
  PaymentIcon,
  ScriptLibraryIcon,
  SecurityIcon,
  NotificationBellIcon,
} from '../assets/icons-v2';
import BenchmarkIcon from '../assets/icons-v2/BenchmarkIcon';

export const iconNameMap = {
  CLOUDS_DESIGN: CloudIcon,
  CLOUD_ACCOUNT_DESIGN: CloudIcon,
  COMPUTE_DESIGN: ComputeIcon,
  COMPUTE: ComputeIcon,
  CLONING: MyDatabasesIcon,
  SOFTWARE_IMAGES_DESIGN: SoftwareImageIcon,
  SOFTWARE_IMAGES: SoftwareImageIcon,
  SOFTWARE_VERSIONS_DESIGN: SoftwareImageIcon,
  SOFTWARE_VERSIONS: SoftwareImageIcon,
  PROVISIONING: ProvisioningIcon,
  AVAILABILITY_MACHINE: AvailabilityMachinesIcon,
  AVAILABILITY_MACHINES: AvailabilityMachinesIcon,
  MY_DATABASES: MyDatabasesIcon,
  MY_SERVICES: MyDatabasesIcon,
  IAM: IAMIcon,
  DATAFLIX: DataFlixIcon,
  NETWORKS: NetworkIcon,
  DB_GOVERNANCE: DBGovernanceIcon,
  SUBSCRIPTIONS: SubscriptionIcon,
  CLOUDS: CloudIcon,
  GOVERNANCE: GovernanceIcon,
  STORAGE: StorageIcon,
  COST: CostIcon,
  BENCHMARK: BenchmarkIcon,
  BENCHMARKS: BenchmarkIcon,
  BILLING: BillingIcon,
  TICKETS: TicketIcon,
  UTILIZATION: UtilizationIcon,
  SUPPORT: SupportIcon,
  PAYMENTS: PaymentIcon,
  SECURITY: SecurityIcon,
  UNKNOWN: NotificationBellIcon,
  'MY SERVICES': MyDatabasesIcon,
  'DB SERVICES': DBServicesIcon,
  'DB GOVERNANCE': DBGovernanceIcon,
  'MY DATABASES': MyDatabasesIcon,
  'AVAILABILITY MACHINES': AvailabilityMachinesIcon,
  'SOFTWARE IMAGES': SoftwareImageIcon,
  'SOFTWARE VERSIONS': SoftwareImageIcon,
  'CLOUD ACCOUNTS': CloudIcon,
  'SCRIPT LIBRARY': ScriptLibraryIcon,
};
// export default iconMap;
