import React, { useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Typography,
  Box,
  Card,
  Button,
  TextField,
  Stack,
  Popover,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  TimelineMarkers,
  TodayMarker,
  CursorMarker,
  CustomMarker,
} from 'react-calendar-timeline/lib';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { styles } from 'styles/style-templates';
import DbEngineLogo from 'common/custom-components/lib/components/DbEngineLogo';
import CloudLogo from 'common/custom-components/lib/components/CloudLogo';
// import { ClockIcon } from 'icons';
// import { BackupIcon } from 'icons-v2';
import useStyles from './hooks/useStyles';
// import { DF_HUMAN } from 'tools/AppUtil';
import './pitr.scss';
import 'react-calendar-timeline/lib/Timeline.css';
// import Cache from 'tools/Cache';
// import BackupDetailsForCRR from './BackupDetailsForCRR';
import fontStyles from 'common/TaskProgress/styles/fontStyles';
// import ArrowBottomIcon from 'icons-v2/ArrowBottomIcon';
import { styles } from 'assets/scss/style-templates';
import { getLabelValue } from '../../../db-services-detailed-view/utils';
import { useFetchCloudLocations } from 'common/cloud-region-location/lib/component/useFetchCloudLocations';
import { DF_HUMAN } from 'helpers/dateFormats';

// type ContinuousViewPropType = {
//   avMachine: any,
//   state: any,
//   setData: (any) => void,
//   isDataFlixRestore: boolean,
//   onRestoreClickNext: (params) => void,
// };

const ContinuousView = (props) => {
  const classes = useStyles();
  const {
    avMachine,
    state,
    setData,
    isDataFlixRestore = false,
    onRestoreClickNext = () => {},
    dmmId = '',
  } = props;

  useEffect(() => {
    document.title = `${avMachine.name || ''} | Continuous Region | Tessell`;
  }, []);

  const {
    dataRegions,
    cloudRegions,
    selectedTime,
    selectedRegion = {},
  } = state;
  const { regionMap } = useFetchCloudLocations();

  const [detailsAnchorEl, setDetailsAnchorEl] = useState(null);
  const openDetails = Boolean(detailsAnchorEl);
  const [modalDatabases, setModalDatabases] = useState([]);

  const showDetails = (e, v) => {
    setModalDatabases(v);
    setDetailsAnchorEl(e ? e.currentTarget : null);
  };

  const Keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'key',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start',
    itemTimeEndKey: 'end',
    groupLabelKey: 'title',
  };

  const nowColor = styles.color.lightShade;
  const selectedColor = styles.color.darkShade;
  const defaultTimeStart = moment(dataRegions[0]?.start).subtract(12, 'h');
  const defaultTimeEnd = moment(dataRegions[0]?.end).add(5, 'h');
  const selectedItems = [];

  const selectItem = (id, e, time) => {
    setData({
      selectedRegion: dataRegions?.find((r) => r.id === id) || {},
      selectedTime: time,
    });
  };

  const getTimeDetails = () => {
    if (!selectedTime) {
      return (
        <Card
          sx={classes.backupDetailCard}
          elevation={0}
          style={{ marginBottom: 16 }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pt={1}
            pl={1}
            height="100%"
            alignItems="flex-start"
            width="100%"
            overflow="scroll"
          >
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              ml={1}
              mt={5}
            >
              {/* <BackupIcon width={80} darkBg={false} /> */}
              Backup
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              ml={2}
              textAlign="right"
              alignItems="flex-end"
              mr={1}
            >
              <Typography color="primary" variant="h5">
                Select a recovery time
              </Typography>
            </Box>
          </Box>
        </Card>
      );
    }

    const engine = avMachine.engine?.toUpperCase();
    const cloud = selectedRegion?.cloud.toUpperCase();

    return (
      <Box display="flex" flexDirection="row" alignItems="flex-end" mb={2}>
        <Card
          sx={{
            ...classes.backupDetailCard,
            marginRight: '20px',
            display: 'flex',
          }}
          elevation={0}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            flex="1 1 auto"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              pt={1}
              pr={3}
              alignItems="flex-start"
              overflow="scroll"
            >
              <Stack
                direction="column"
                ml={2}
                textAlign="left"
                alignItems="flex-start"
                mr={1}
              >
                <Typography color="primary" variant="h5">
                  Recovery time
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  my={1}
                >
                  {/* <ClockIcon width={16} /> */}
                  <Typography pl={1}>{pitr.format(DF_HUMAN)}</Typography>
                </Box>
              </Stack>
              <Stack direction="column" ml={0.5}>
                <Stack direction="row" alignItems="center">
                  {engine && (
                    <DbEngineLogo name={engine} sxClass={classes.engineLogo} />
                  )}
                  {cloud && <CloudLogo name={cloud} sxClass={classes[cloud]} />}
                </Stack>
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  ml={1}
                  mt={5}
                >
                  {/* <BackupIcon width={80} darkBg={false} /> */} Backup
                </Box>
              </Stack>
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
              p="5px 4px 5px 16px"
              alignItems="center"
              sx={classes.backupActions}
            >
              {getLabelValue(
                'Selected Regions',
                `${regionMap[selectedRegion?.region]}`,
                'caption',
                // 'primary',
              )}
            </Stack>
          </Stack>
        </Card>
      </Box>
    );
  };

  const getDbDetails = (databases) => {
    const details = databases ? (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {databases.map((d) => (
              <TableRow key={d.id}>
                <TableCell component="th" scope="row">
                  {d.name}
                </TableCell>
                {/* <TableCell align="right">{d.status}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : null;

    return (
      <Box display="flex" flexDirection="column" pb={2} pt={1} px={2}>
        {details}
      </Box>
    );
  };

  const pitr = moment(selectedTime);

  const setPitr = (time) => {
    if (time?.valueOf()) {
      setData({ selectedTime: time.valueOf() });
    }
  };

  const getTimeLineOpenWithValue = (maxDate, minDate) => {
    const maxDateYear = moment(maxDate).year();
    const minDateYear = moment(minDate).year();
    const maxDateMonth = moment(maxDate).month();
    const minDateMonth = moment(minDate).month();

    if (maxDateYear !== minDateYear) {
      return 'year';
    }
    if (maxDateMonth !== minDateMonth) {
      return 'month';
    }
    return 'day';
  };

  return (
    <Box display="flex" flexDirection="column" p={2} px={0}>
      <Stack alignSelf="flex-start" mb={3}>
        <DateTimePicker
          label="Select Recovery Time"
          value={pitr}
          onChange={setPitr}
          ampm={false}
          maxDateTime={selectedRegion?.end}
          minDateTime={selectedRegion?.start}
          inputFormat="MMM dd, yyyy HH:mm"
          openTo={getTimeLineOpenWithValue(
            selectedRegion?.end,
            selectedRegion?.start,
          )}
          views={['year', 'month', 'day', 'hours', 'minutes']}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </Stack>
      {isDataFlixRestore && (
        <Typography sx={fontStyles.fs20_fw400} py={2.5}>
          Pick a time to Restore your service and click Next
        </Typography>
      )}
      <Box
        px={3}
        pt={2}
        sx={{
          backgroundColor: '#FFFFFF',
          border: '1px solid #F0F0F0',
          boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25)',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h6" mb={2} sx={{ fontWeight: 600, fontSize: 20 }}>
          Regions and Availability Zones
        </Typography>
        {/* TODO: check this code */}
        {/* <Typography variant="subtitle1" mt={1} mb={3}>{`${
                regionMap[selectedRegion?.region] || ''
            }`}</Typography> */}
        <Box px={0}>
          <Timeline
            groups={cloudRegions}
            items={dataRegions}
            keys={Keys}
            itemHeightRatio={1}
            itemsSorted
            itemTouchSendsClick={false}
            canMove={false}
            canResize={false}
            dragSnap={1000}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            selected={selectedItems}
            onItemSelect={selectItem}
            onItemClick={selectItem}
            className="timeline"
          >
            <TimelineHeaders
              calendarHeaderStyle={
                {
                  // border: '1px solid black',
                }
              }
              style={{
                height: 50,
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: '1px solid #E6E6E6',
                borderRadius: '10px',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
              }}
            >
              <SidebarHeader>
                {({ getRootProps }) => (
                  <Box
                    {...getRootProps()}
                    height={48}
                    className="timeline-side-header-title"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      borderRight: '1px solid #E6E6E6',
                      borderBottomLeftRadius: '10px',
                      borderTopLeftRadius: '10px',
                    }}
                  >
                    Regions
                  </Box>
                )}
              </SidebarHeader>
              {/* <DateHeader unit="primaryHeader" /> */}
              <DateHeader />
            </TimelineHeaders>
            <TimelineMarkers>
              <TodayMarker>
                {({ styles }) => (
                  <div style={{ ...styles, backgroundColor: '#D90C0C99' }} />
                )}
              </TodayMarker>
              <CursorMarker>
                {({ styles, date }) => (
                  <div
                    style={{
                      ...styles,
                      backgroundColor: nowColor,
                      zIndex: 100,
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        left: -135,
                        bottom: 23,
                        width: 180,
                        background: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        padding: 5,
                        fontSize: 12,
                        borderRadius: 5,
                        zIndex: 85,
                        textAlign: 'center',
                      }}
                    >
                      {moment(date).format(DF_HUMAN)}
                    </div>
                  </div>
                )}
              </CursorMarker>
              {selectedTime ? (
                <CustomMarker date={selectedTime}>
                  {({ styles }) => (
                    <div
                      style={{
                        ...styles,
                        backgroundColor: selectedColor,
                        zIndex: 100,
                      }}
                    />
                  )}
                </CustomMarker>
              ) : (
                ''
              )}
            </TimelineMarkers>
          </Timeline>
        </Box>
        <Box display="flex" justifyContent="center" pb={1}>
          <Box
            sx={{
              width: 0,
              height: 0,
              borderRight: '12px solid transparent',
              borderLeft: '12px solid transparent',
              borderTop: '16px solid #11567F',
            }}
          />
        </Box>
        {/* <BackupDetailsForCRR
          onRestoreClickFromSnapshots={onRestoreClickNext}
          selectedTime={selectedTime}
          cloneFeature={cloneFeature}
          avMachine={avMachine}
          isDataFlixRestore={isDataFlixRestore}
          regionMap={regionMap}
          dmmId={dmmId}
          shouldVisible
          showDetails={showDetails}
        /> */}
      </Box>
      <Popover
        open={openDetails}
        anchorEl={detailsAnchorEl}
        onClose={() => showDetails(null, null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={2}
      >
        {getDbDetails(modalDatabases)}
      </Popover>
    </Box>
  );
};

export default ContinuousView;
