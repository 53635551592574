import { useContext } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { GenieRequestModal } from '../GenieRequestModal/GenieRequestModal';
import ExtendDurationModal from '../ExtendDurationModal';
import { Refresh } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import {
  GenieOptions,
  GenieStatus,
  GenieStatusConfig,
  InternalGenieActionsForStatus,
  TessellGenieTypeEnum,
  genieRequestButtonStatuses,
} from '../constant';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import VMConnectionDetailsModal from '../VMConnectionDetailsModal';
import moment from 'moment';
import { DF_HUMAN } from 'helpers/dateFormats';
import { useGenieOverview } from './useGenieOverview';
import { DetailsModal, ThreeDotMenu } from '@tessell/tessell-common-ui-library';
import { useGenieOverviewStyles } from './useGenieOvervieStyles';
import ConfirmationDialog from 'common/Modals/ConfirmationDialog';
import { GenieComputeDetailsAndAction } from './GenieComputeDetailsAndAction';
import { RequestGenieForCompute } from './GenieTypes';
import { GenieRefreshStatus } from './GenieRefreshStatus';
import { ForceRevokeModal } from './ForceRevoke/ForceRevokeModal';
import { AddComputesModal } from './AddComputesToRequest/AddComputesModal';
import { styles } from 'assets/scss/style-templates';

type GenieProps = {
  instances?: Array<{ label: string; value: string }>;
  handleRequest: any;
  refreshGenieStatus: (requestId: string) => void;
  genieStatusLoading: boolean;
  genieStatus: any;
  handleWithdraw: (requestId: string) => void;
  handleExtendDuration: (requestId: string, duration: number) => void;
  downloadGenieConfig: (requestId: string) => void;
  downloadGenieConfigForACompute: RequestGenieForCompute;
  approveRequest: (requestId: string) => void;
  declineRequest: (requestId: string) => void;
  hideInstanceSelection?: boolean;
  handleForceRevoke: (requestId: string, config: any) => void;
  handleAddComputes: (requestId: string, config: any) => void;
  serviceId?: string;
};

export const Genie = ({
  instances,
  handleRequest,
  refreshGenieStatus,
  genieStatusLoading,
  genieStatus = {},
  handleWithdraw,
  handleExtendDuration,
  downloadGenieConfig,
  downloadGenieConfigForACompute,
  approveRequest,
  declineRequest,
  hideInstanceSelection = false,
  handleForceRevoke,
  handleAddComputes,
  serviceId,
}: GenieProps) => {
  const classes = useGenieOverviewStyles();
  const {
    showRequestModal,
    setShowRequestModal,
    showDurationModal,
    setShowDurationModal,
    showDetailsModal,
    setShowDetailsModal,
    showConnectionDetailsModal,
    setShowConnectionDetailsModal,
    showAddComputesModal,
    setShowAddComputesModal,
    selectedGenieInfo,
    setSelectedGenieInfo,
    openWithdrawConfirmation,
    setOpenWithdrawConfirmation,
    detailsFields,
    handleMenuSelection,
    showForceRevokeModal,
    setShowForceRevokeModal,
  } = useGenieOverview({ approveRequest });

  const { hasPermission } = useContext(UserPermissionsContext);

  const getExpiryTimeAndConnectionInfo = (_genieInfo) => {
    return (
      <>
        {(_genieInfo?.status === GenieStatus.ENABLED ||
          _genieInfo?.status === GenieStatus.PARTIAL_ENABLED) && (
          <>
            <Typography
              fontSize="12px"
              pr={2}
              mr={2}
              borderRight={'1px solid #CCCCCC'}
            >
              Expires at:{' '}
              {moment(_genieInfo?.dateModified)
                .add(_genieInfo?.expiryInMinutes, 'minutes')
                .format(DF_HUMAN)}
            </Typography>
            <LoadingButton
              onClick={() => {
                setSelectedGenieInfo(_genieInfo);
                setShowConnectionDetailsModal(true);
              }}
              variant="outlined"
              color="primary"
              size="small"
              sx={{ ...classes.requestButton }}
              loading={false}
            >
              Connect
            </LoadingButton>
          </>
        )}
      </>
    );
  };

  const getActionButton = (_genieInfo) => {
    return (
      <>
        {hasPermission(PERMISSIONS.GENIE) &&
          genieRequestButtonStatuses.includes(_genieInfo?.status) && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ ...classes.requestButton }}
              onClick={() => {
                setShowRequestModal(true);
              }}
              size="small"
              disabled={genieStatusLoading}
            >
              {genieStatusLoading ? (
                <CircularProgress
                  thickness={4}
                  size={14}
                  sx={{ color: styles.color.monotoneLightLight }}
                />
              ) : (
                'Request Access'
              )}
            </Button>
          )}
      </>
    );
  };

  const getMenu = (_genieInfo) => {
    let _options = [];
    if (_genieInfo.genieType === TessellGenieTypeEnum.Customer) {
      if (_genieInfo?.status === GenieStatus.ENABLED) {
        [GenieOptions.VIEW_DETAILS, GenieOptions.WITHDRAW_ACCESS].forEach(
          (_op) => {
            _options.push(_op);
          },
        );
      }
      if (_genieInfo?.status === GenieStatus.REQUESTED) {
        _options = [GenieOptions.VIEW_DETAILS];
      }
    }
    if (_genieInfo.genieType === TessellGenieTypeEnum.Internal) {
      _options = InternalGenieActionsForStatus[_genieInfo?.status] || [];
      if (hideInstanceSelection) {
        if (
          _genieInfo?.status === GenieStatus.ENABLED ||
          _genieInfo?.status === GenieStatus.PARTIAL_ENABLED
        )
          _options = InternalGenieActionsForStatus[_genieInfo?.status].filter(
            (_op) => {
              return _op !== GenieOptions.ADD_COMPUTES;
            },
          );
      }
    }
    return (
      <>
        {_options?.length > 0 && (
          <ThreeDotMenu
            options={_options}
            handleOnSelect={(_option) => {
              handleMenuSelection(_genieInfo, _option);
            }}
          />
        )}
      </>
    );
  };

  const vmEnabledInstances = (type) => {
    if (
      [
        GenieStatus.ENABLED,
        GenieStatus.FAILED_ENABLE,
        GenieStatus.PARTIAL_ENABLED,
        GenieStatus.ENABLING,
      ].includes(genieStatus[type]?.status)
    ) {
      return (
        <Stack
          spacing={0.5}
          sx={{
            ...classes.instanceName,
          }}
        >
          {genieStatus[type]?.entities?.map((_e) => (
            <GenieComputeDetailsAndAction
              metadata={_e?.metadata}
              computeId={_e?.computeResourceId}
              requestId={genieStatus[TessellGenieTypeEnum.Internal]?.requestId}
              downloadGenieConfigForACompute={downloadGenieConfigForACompute}
              currentStatus={_e?.currentStatus}
            />
          ))}
        </Stack>
      );
    }
    return <></>;
  };

  const getStatusLabel = (type) => {
    const _config = GenieStatusConfig[genieStatus[type]?.status];
    return (
      <StatusLabel
        backgroundColor={_config?.backgroundColor}
        textColor={_config?.textColor}
        text={_config.text || genieStatus}
      />
    );
  };

  return (
    <>
      <Stack
        sx={{
          ...classes.genieOverviewContainer,
        }}
      >
        {/* For Customer */}
        <Stack
          sx={{
            ...classes.genieComponent,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignContent="center"
            >
              <Typography fontWeight="600" fontSize="14px">
                VM Access
              </Typography>
              <Typography fontSize="12px" color="#666666">
                Manage Access requests to VMs of your service
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              {getStatusLabel(TessellGenieTypeEnum.Customer)}
              {genieStatus[TessellGenieTypeEnum.Customer]?.requestId && (
                <LoadingButton
                  onClick={() => {
                    setSelectedGenieInfo(
                      genieStatus[TessellGenieTypeEnum.Customer],
                    );
                    refreshGenieStatus(
                      genieStatus[TessellGenieTypeEnum.Customer]?.requestId,
                    );
                  }}
                  size="small"
                  loading={
                    genieStatusLoading &&
                    selectedGenieInfo?.genieType ===
                      TessellGenieTypeEnum.Customer
                  }
                >
                  <Refresh />
                </LoadingButton>
              )}
              {genieStatus[TessellGenieTypeEnum.Customer].status ===
                GenieStatus.REQUESTED && (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      approveRequest(
                        genieStatus[TessellGenieTypeEnum.Customer]?.requestId,
                      );
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    color="error"
                    onClick={() => {
                      declineRequest(
                        genieStatus[TessellGenieTypeEnum.Customer]?.requestId,
                      );
                    }}
                  >
                    Decline
                  </Button>
                </>
              )}
              {getExpiryTimeAndConnectionInfo(
                genieStatus[TessellGenieTypeEnum.Customer],
              )}
              {getMenu(genieStatus[TessellGenieTypeEnum.Customer])}
            </Stack>
          </Stack>
          {vmEnabledInstances(TessellGenieTypeEnum.Customer)}
        </Stack>

        {/* For Tessell Support */}
        <Stack
          sx={{
            ...classes.genieComponent,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignContent="center"
            >
              <Typography fontWeight="600" fontSize="14px">
                VM Access for Tessell Support
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              {getStatusLabel(TessellGenieTypeEnum.Internal)}
              {genieStatus[TessellGenieTypeEnum.Internal]?.requestId && (
                <GenieRefreshStatus
                  refreshAction={() => {
                    setSelectedGenieInfo(
                      genieStatus[TessellGenieTypeEnum.Internal],
                    );
                    refreshGenieStatus(
                      genieStatus[TessellGenieTypeEnum.Internal]?.requestId,
                    );
                  }}
                  isLoading={
                    genieStatusLoading &&
                    selectedGenieInfo?.genieType ===
                      TessellGenieTypeEnum.Internal
                  }
                />
              )}
              {getExpiryTimeAndConnectionInfo(
                genieStatus[TessellGenieTypeEnum.Internal],
              )}
              {getActionButton(genieStatus[TessellGenieTypeEnum.Internal])}
              {getMenu(genieStatus[TessellGenieTypeEnum.Internal])}
            </Stack>
          </Stack>
          {vmEnabledInstances(TessellGenieTypeEnum.Internal)}
        </Stack>
      </Stack>

      <GenieRequestModal
        instances={instances}
        show={showRequestModal}
        setShowModal={setShowRequestModal}
        handleRequest={handleRequest}
        hideInstanceSelection={hideInstanceSelection}
      />

      <DetailsModal
        open={showDetailsModal}
        setOpen={setShowDetailsModal}
        fields={detailsFields}
      />

      <ConfirmationDialog
        title={`Withdraw VM ${
          selectedGenieInfo?.status === GenieStatus.REQUESTED
            ? 'request'
            : 'access'
        }?`}
        open={openWithdrawConfirmation}
        setOpen={setOpenWithdrawConfirmation}
        handleConfirm={() => {
          handleWithdraw(selectedGenieInfo?.requestId);
          setOpenWithdrawConfirmation(false);
        }}
        confirmBtnText="Yes, withdraw"
        confirmContent={
          <Typography>
            Are you sure you want to withdraw your VM{' '}
            {selectedGenieInfo?.status === GenieStatus.REQUESTED
              ? 'request'
              : 'access'}
            ?
          </Typography>
        }
      />

      <ExtendDurationModal
        open={showDurationModal}
        setOpen={setShowDurationModal}
        handleConfirm={(_duration) => {
          setShowDurationModal(false);
          handleExtendDuration(selectedGenieInfo?.requestId, _duration);
        }}
      />

      <VMConnectionDetailsModal
        open={showConnectionDetailsModal}
        setOpen={setShowConnectionDetailsModal}
        downloadGenieConfig={() => {
          downloadGenieConfig(selectedGenieInfo?.requestId);
        }}
      />
      <ForceRevokeModal
        open={showForceRevokeModal}
        setOpen={setShowForceRevokeModal}
        entities={genieStatus[TessellGenieTypeEnum.Internal]?.entities?.filter(
          (entity) => entity?.currentStatus === GenieStatus.ENABLED,
        )}
        requestId={genieStatus[TessellGenieTypeEnum.Internal]?.requestId}
        handleForceRevoke={handleForceRevoke}
      />
      <AddComputesModal
        open={showAddComputesModal}
        setOpen={setShowAddComputesModal}
        entitiesFromRequest={
          genieStatus[TessellGenieTypeEnum.Internal]?.entities
        }
        requestId={genieStatus[TessellGenieTypeEnum.Internal]?.requestId}
        handleAddComputes={handleAddComputes}
        serviceId={serviceId}
        instances={instances}
      />
    </>
  );
};
