export const legendStyles = {
  legendRow: {
    mainContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      py: '8px',
    },
    rowContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    textStyleValue: {
      color: ' #08080D',
      fontSize: '16px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
    },
    textStyleName: {
      color: '#08080D',
      fontSize: '16px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      textOverflow: 'ellipsis',
      maxWidth: '130px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    rowColorBox: {
      height: '16px',
      width: '16px',
      borderRadius: '5px',
      mr: '8px',
    },
  },
  legend: {
    mainContainer: {
      width: '80%',
      height: '100%',
    },
    headerContainer: {
      flexDirection: 'row',
      height: '16px',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'sticky',
      mb: '16px',
    },
    keyLabel: {
      color: '#666',
      fontSize: '12px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
    },
    valueLabel: {
      color: '#666',
      fontSize: '12px',
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
    },
    rowContainer: {
      width: '100%',
      overflow: 'scroll',
      maxHeight: '200px',
    },
  },
};
