/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */

import { useContext, useMemo } from 'react';
import { Stack } from '@mui/material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import UpdateMetadata from 'common/UpdateMetadata/UpdateMetadata';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { useDatabases } from './useDatabases';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import DatabasesColumnComponents from './ColumnComponents';
import { databaseColumns } from './constants';

interface DatabasesProps {
  dbServiceId?: string;
  service?: DatabaseServiceType;
}
export default function Databases(props: DatabasesProps) {
  const { dbServiceId, service } = props;

  const { hasPermission } = useContext(UserPermissionsContext);
  const {
    page,
    handlePaginationChange,
    databases,
    databasesIsLoading,
    databasesCountIsLoading,
    databasesCount,
    databasesPageCount,
    selectedDatabase,
    setSelectedDatabase,
    updateMetadataOpen,
    setUpdateMetadataOpen,
  } = useDatabases({ dbServiceId });

  const dbColumns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: databaseColumns.Name,
      headerName: 'Name',
      renderCell: (params) => {
        const { row } = params;
        return DatabasesColumnComponents(databaseColumns.Name, row);
      },
      width: 300,
    },
    {
      field: databaseColumns.Status,
      headerName: 'Status',
      renderCell: (params) => {
        const { row } = params;
        return DatabasesColumnComponents(databaseColumns.Status, row);
      },
      width: 150,
    },
    {
      field: databaseColumns.Created,
      headerName: 'Created',
      width: 280,
      renderCell: (params) => {
        const { row } = params;
        return DatabasesColumnComponents(databaseColumns.Created, row);
      },
    },
  ];

  const menuItemsProps = useMemo(
    () =>
      [
        hasPermission(PERMISSIONS.UPDATE_TENANT) && {
          label: 'Update Metadata',
          onClick: (row) => {
            setSelectedDatabase(row);
            setUpdateMetadataOpen(true);
          },
        },
      ].filter((a) => a),
    [],
  );

  return (
    <Stack sx={{ height: '50vh' }}>
      <DataGrid
        columns={dbColumns}
        menuItems={menuItemsProps}
        rows={databases}
        styleOverrides={{ hideShadow: true }}
        clickable={false}
        loading={databasesIsLoading || databasesCountIsLoading}
        rowHoverId="id"
        getRowId={(row) => `${row._id}`}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        itemsPerPage={ITEMS_PER_PAGE}
        pageNo={page}
        totalCount={databasesCount || 0}
        handlePaginationChange={handlePaginationChange}
        pageCount={databasesPageCount}
      />
      <UpdateMetadata
        open={updateMetadataOpen}
        setOpen={setUpdateMetadataOpen}
        entityType="databases"
        entityName={selectedDatabase?.name}
        tenantId={service?.tenantId}
        tenantDomain={service?.pgSource}
        entityId={selectedDatabase?.id}
        parentEntityId={service?._id}
      />
    </Stack>
  );
}
