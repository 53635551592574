export enum View {
  Card = 'Card',
  Table = 'Table',
}

export const FilterOptions = {
  BillingStatus: 'billing-status',
  Geolocation: 'geolocation',
  DeploymentType: 'deployment-type',
  Cloud: 'cloud-type',
  TenantPhase: 'tenant-phase',
};

export const CLOUDS = ['AWS', 'AZURE'];
