import { useMemo } from 'react';
import { useUsers } from './useUsers';
import { Stack, Typography, Chip, Button, Grid } from '@mui/material';
import { usersStyles } from './styles';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import {
  ChevronRight,
  Delete,
  ExpandMore,
  PersonAdd,
} from '@mui/icons-material';
import { CreateUserModal } from './CreateUser/CreateUsersModal';
import ConfirmationDialog from 'common/Modals/ConfirmationDialog';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { URLS, USERS_BASE_URL } from 'constants/URL';

export const Users = () => {
  const {
    usersList,
    usersIsLoading,
    openCreateUserModal,
    openDeleteConfirmation,
    deleteUserIsLoading,
    currentUser,
    userPersonaMap,
    getPersona,
    deleteUser,
    setOpenDeleteConfirmation,
    setOpenCreateUserModal,
    handleCreateUserSuccess,
    setCurrentUser,
  } = useUsers();

  const actionProps = useMemo(
    () => [
      {
        startIcon: <PersonAdd />,
        label: 'Create User',
        onClick: (e) => {
          setOpenCreateUserModal(true);
        },
      },
    ],
    [],
  );

  const menuItemProps = useMemo(
    () => [
      {
        icon: <Delete color="primary" />,
        label: 'Delete User',
        onClick: (userObject) => {
          setCurrentUser(userObject);
          setOpenDeleteConfirmation(true);
        },
        disabled: (userObject) => !userObject?.username,
      },
    ],
    [],
  );

  const userColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 200,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 200,
      },
      {
        field: 'persona',
        headerName: 'Persona',
        width: 280,
        renderCell: (params) => {
          const { row: data } = params;
          const { username } = data;
          return userPersonaMap[username] ? (
            <Grid container>
              {userPersonaMap[username]?.map((persona) => (
                <Chip label={persona} />
              ))}
            </Grid>
          ) : (
            <Stack alignContent="flex-start" width="120px">
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setCurrentUser(data);
                  getPersona(`${USERS_BASE_URL}/${username}/groups`);
                }}
              >
                Get Persona
              </Button>
            </Stack>
          );
        },
      },
      {
        field: 'username',
        headerName: 'Username',
        width: 280,
      },
    ],
    [userPersonaMap],
  );

  return (
    <Stack sx={usersStyles.mainComponent}>
      <DataGrid
        getRowId={(row) => {
          return row.username;
        }}
        columns={userColumns}
        rows={usersList}
        actions={actionProps}
        loading={usersIsLoading}
        menuItems={menuItemProps}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
      <CreateUserModal
        open={openCreateUserModal}
        setOpen={setOpenCreateUserModal}
        handleCreateUserSuccess={handleCreateUserSuccess}
      />
      <ConfirmationDialog
        title="Delete user"
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        handleConfirm={() => {
          deleteUser(currentUser);
          !deleteUserIsLoading && setOpenDeleteConfirmation(false);
        }}
        confirmContent={
          <Typography>
            Are you sure you want to delete user with email{' '}
            <Typography sx={usersStyles.emailText}>
              {currentUser?.email}
            </Typography>
          </Typography>
        }
        isLoading={deleteUserIsLoading}
      />
    </Stack>
  );
};
