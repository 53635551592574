import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { useStyles } from 'common/utils';
import { useAddUsers } from './useAddUsers';
import { adaptToAutocompleteOption } from '../groupResponseAdapter';
import { GroupStringConstants } from '../../constants';
import { adaptUserToAutocompleteOption } from '../../Common/helper';
import ChipGrid from 'common/custom-components/lib/components/ChipGrid';
import ConfirmationDialog from 'common/Modals/ConfirmationDialog';

type AddUsersModalProps = {
  open: boolean;
  setOpen: any;
  group: any;
};

const AddUsersModal = (props: AddUsersModalProps) => {
  const { open, setOpen, group } = props;
  const classes = useStyles();

  const {
    onClose,
    usersToAdd,
    addUsers,
    groupUsers,
    setUsersToAdd,
    usersAvailableToAdd,
    isAnyLoading,
    confirmOpen,
    setConfirmOpen,
    usersForAddConfirmation,
  } = useAddUsers({ group, open, setOpen });

  const DialogTitle = () => {
    return (
      <Typography variant="h5">
        {GroupStringConstants.addUsers} to {group?.name}
      </Typography>
    );
  };

  const DialogActions = () => {
    return (
      <>
        <Button variant="outlined" size="small" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isAnyLoading}
          variant="contained"
          onClick={() => setConfirmOpen(true)}
          size="small"
          disabled={usersToAdd?.length === 0}
        >
          Add
        </LoadingButton>
      </>
    );
  };

  const DialogContent = () => {
    return (
      <Stack width="500px" spacing={2} pt={2}>
        <Stack>
          <Typography variant="h6" mb={1}>
            Existing users
          </Typography>
          <ChipGrid array={groupUsers} label={'name'} fallback="email" />
        </Stack>
        <TessellAutocomplete
          label="Users to Add"
          value={usersToAdd}
          variant="outlined"
          size="small"
          onChange={(_val) => {
            setUsersToAdd(_val);
          }}
          fullWidth
          options={
            adaptToAutocompleteOption(
              usersAvailableToAdd,
              adaptUserToAutocompleteOption,
            ) || []
          }
          name="addUsers"
          multiple
          disabled={isAnyLoading}
        />
      </Stack>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <>
      <TessellModal
        customStyles={customStyles}
        renderFunctions={renderFunctions}
        maxWidth="sm"
        open={open}
        onClose={onClose}
      />
      <ConfirmationDialog
        title={GroupStringConstants.addUsers}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        handleConfirm={() => {
          addUsers();
          !isAnyLoading && setConfirmOpen(false);
        }}
        confirmContent={
          <Stack>
            <Typography mb={1}>
              Are you sure you want to add following user
              {usersToAdd?.length > 1 && 's'}:
            </Typography>
            <ChipGrid
              array={usersForAddConfirmation}
              label={'name'}
              fallback="email"
            />
          </Stack>
        }
        isLoading={isAnyLoading}
      />
    </>
  );
};
export default AddUsersModal;
