import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import {
  useLazyGetCall,
  useLazyGetCallWithURL,
  useLazyPostCall,
  useLazyPutCall,
} from 'common/api/useApiCall';
import { CREDITS_BASE_URL, URLS } from 'constants/URL';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { adaptCreditResponse } from '../creditDetails/adaptCreditResponse';
import { TenantContext } from 'common/TenantContainer/TenantContainer';
import { evaluateTenantName } from 'common/TenantContainer/evaluateTenantName';
import { OTPRequestType } from 'constants/URLEnums';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';

export const useTenantDetails = () => {
  const urlParams = useParams();
  const tenantId = urlParams?.tenantId;
  const navigate = useNavigate();
  const [credits, setCredits] = useState(0);
  const [openOTP, setOpenOTP] = useState(false);
  const [step, setStep] = useState(1);
  const [uuid, setUuid] = useState('');
  const [openAddCreditsModal, setOpenAddCreditsModal] = useState(false);
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);
  const [openGenieConfigUpdateModal, setOpenGenieConfigUpdateModal] =
    useState(false);

  const [silenceAlertsOpen, setSilenceAlertsOpen] = useState(false);

  const { addNotification } = useContext(NotificationContext);
  const { hasPermission } = useContext(UserPermissionsContext);

  const params = useParams();
  const ticketsURL = URLS.fetchTicketsSummaryByTenantId.replace('{}', tenantId);

  const {
    tenant: selectedTenant,
    tenantIsLoading,
    setCurrentTenantId,
    fetchTenantByID,
  } = useContext(TenantContext);

  useEffect(() => {
    if (tenantId) setCurrentTenantId(tenantId);
  }, [tenantId]);

  const { response: ticketsResponse, postData: fetchTickets } =
    useLazyGetCall(ticketsURL);

  const {
    response: sendOTPRes,
    isLoading: sendOTPIsLoading,
    postData: sendOTP,
  } = useLazyPostCall(`${URLS.otp}purpose=${OTPRequestType.ADD_CREDITS_OTP}`);

  const {
    response: addCreditsResponse,
    postData: addCreditsPostData,
    isLoading: addCreditsIsLoading,
  } = useLazyPutCall(
    `${CREDITS_BASE_URL}tenant-id=${selectedTenant?._id}&tenant-domain=${selectedTenant?.pgSource}`,
  );
  const {
    response: creditDetails,
    isLoading: isCreditDetailsLoading,
    postData: creditsPostData,
  } = useLazyGetCall(
    `${CREDITS_BASE_URL}tenant-id=${selectedTenant?._id}&tenant-domain=${selectedTenant?.pgSource}`,
    adaptCreditResponse,
  );

  const sendOTPCall = ({ comment, jiraId, credits }) => {
    const payload = {
      'tenant-id': selectedTenant?._id,
      'tenant-domain': selectedTenant?.domain,
      comment: comment,
      jiraId: jiraId,
      'credit-amount': credits,
      'tenant-name': evaluateTenantName({
        tenant: selectedTenant,
      }),
    };
    sendOTP(JSON.stringify(payload, null));
  };

  const handleToggleBillingSuccess = () => {
    setTimeout(() => fetchTenantByID(), 2000);
  };

  const onSubmit = (val) => {
    if (step === 1) {
      sendOTPCall({
        comment: val?.reason,
        jiraId: val?.jiraId,
        credits: credits,
      });
    } else if (step === 2) {
      const payload = {
        otp: val,
        'request-id': uuid,
        'credit-amount': credits,
      };
      addCreditsPostData(JSON.stringify(payload, null, 2));
    }
  };

  useEffect(() => {
    if (sendOTPRes?.uuid) {
      setUuid(sendOTPRes?.uuid);
      setStep(2);
    }
  }, [sendOTPRes]);

  useEffect(() => {
    if (params['*'] === '') {
      navigate('database-services');
    }
  }, [tenantId]);

  useEffect(() => {
    if (selectedTenant && hasPermission(PERMISSIONS.TICKET_VIEW))
      fetchTickets();
  }, [selectedTenant]);

  useEffect(() => {
    if (addCreditsResponse?.id) {
      addNotification({
        severity: 'success',
        message: `Credits added successfully.`,
      });
      creditsPostData();
    } else if (addCreditsResponse) {
      addNotification({
        severity: 'error',
        message:
          addCreditsResponse?.message ||
          'Something went wrong, please try again.',
      });
    }
    setOpenOTP(false);
    setStep(1);
    setCredits(0);
  }, [addCreditsResponse]);

  const onUpdateMetadataSuccess = () => {
    fetchTenantByID();
  };
  const handleOnSuccess = () => {
    setSilenceAlertsOpen(false);
  };

  return {
    selectedTenant,
    step,
    credits,
    uuid,
    openAddCreditsModal,
    openOTP,
    creditDetails,
    ticketsResponse,
    addCreditsIsLoading,
    sendOTPIsLoading,
    tenantIsLoading,
    isCreditDetailsLoading,
    silenceAlertsOpen,
    sendOTPCall,
    addCreditsPostData,
    navigate,
    fetchTenantByID,
    creditsPostData,
    setOpenOTP,
    setCredits,
    setOpenAddCreditsModal,
    onSubmit,
    handleToggleBillingSuccess,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    openGenieConfigUpdateModal,
    setOpenGenieConfigUpdateModal,
    onUpdateMetadataSuccess,
    setSilenceAlertsOpen,
    handleOnSuccess
  };
};
