import React, { useState, useMemo, useEffect } from 'react';
import { DOWNLOADS_BASE_URL, URLS } from 'constants/URL';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { useLazyGetCallWithURL, useGetCall } from 'common/api/useApiCall';
import { downloadAdapter } from './downloadAdapter';


export const useDownloads = () => {
  const [page, setPage] = useState(1);

  const {
    response: downloads,
    isLoading,
    postData: fetchDownloads,
  } = useLazyGetCallWithURL(downloadAdapter);

  const downloadsCountRes = useGetCall(URLS.fetchDownloadsCount);

  const downloadsCount = useMemo(() => {
    return downloadsCountRes?.response?.response?.count;
  }, [downloadsCountRes?.response]);

  const pageCount = useMemo(
    () => Math.ceil((downloadsCount || 0) / ITEMS_PER_PAGE),
    [downloadsCount],
  );

  useEffect(() => {
    let _url = `${DOWNLOADS_BASE_URL}?page-size=${ITEMS_PER_PAGE}&page-offset=${page - 1}`;
    fetchDownloads(`${_url}`);
  }, [page]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return {
    page,
    downloads,
    downloadsCount,
    isLoading,
    pageCount,
    handlePaginationChange,
  };
};
